import {
  LeftArrowBetweenIcon,
  OverLeftArrowIcon,
  OverLeftRightArrowIcon,
  OverRightArrowIcon,
  RightArrowBetweenIcon,
  UnderLeftArrowIcon,
  UnderLeftRightArrowIcon,
  UnderRightArrowIcon,
} from "../icons";
import { MathCommand } from "./types";

export const downArrow: MathCommand = {
  text: "↓",
  label: "down arrow",
  mathLiveCommand: "\\downarrow",
  latexCommand: "\\downarrow",
};
export const upArrow: MathCommand = {
  text: "↑",
  label: "up arrow",
  mathLiveCommand: "\\uparrow",
};
export const leftArrow: MathCommand = {
  text: "←",
  label: "left arrow",
  mathLiveCommand: "\\leftarrow",
};
export const rightArrow: MathCommand = {
  text: "→",
  label: "right arrow",
  mathLiveCommand: "\\rightarrow",
};
export const leftRightArrow: MathCommand = {
  text: "⟷",
  label: "left right arrow",
  mathLiveCommand: "\\longleftrightarrow",
};
export const equilibrium: MathCommand = {
  text: "⇌",
  label: "equilibrium",
  mathLiveCommand: "\\rightleftharpoons",
};
export const longLeftArrow: MathCommand = {
  text: "⟸",
  label: "long left arrow",
  mathLiveCommand: "\\Longleftarrow",
  tooltipLabel: "implies",
};
export const longRightArrow: MathCommand = {
  text: "⟹",
  label: "long right arrow",
  mathLiveCommand: "\\Longrightarrow",
  tooltipLabel: "implies",
};
export const longleftrightArrow: MathCommand = {
  text: "⟺",
  label: "if and only if",
  mathLiveCommand: "\\Longleftrightarrow",
};
export const overleftArrow: MathCommand = {
  renderChildren: () => <OverLeftArrowIcon />,
  label: "over left arrow",
  mathLiveCommand: "\\overleftarrow",
  tooltipLabel: "",
};
export const overleftrightArrow: MathCommand = {
  renderChildren: () => <OverLeftRightArrowIcon />,
  label: "over left right arrow",
  mathLiveCommand: "\\overleftrightarrow",
  tooltipLabel: "",
};
export const overrightArrow: MathCommand = {
  renderChildren: () => <OverRightArrowIcon />,
  label: "over right arrow",
  mathLiveCommand: "\\overrightarrow",
  tooltipLabel: "",
};
export const underleftArrow: MathCommand = {
  renderChildren: () => <UnderLeftArrowIcon />,
  label: "under left arrow",
  mathLiveCommand: "\\underleftarrow",
  tooltipLabel: "",
};
export const underleftrightArrow: MathCommand = {
  renderChildren: () => <UnderLeftRightArrowIcon />,
  label: "under left right arrow",
  mathLiveCommand: "\\underleftrightarrow",
  tooltipLabel: "",
};
export const underrightArrow: MathCommand = {
  renderChildren: () => <UnderRightArrowIcon />,
  label: "under right arrow",
  mathLiveCommand: "\\underrightarrow",
  tooltipLabel: "",
};
export const leftArrowBetween: MathCommand = {
  renderChildren: () => <LeftArrowBetweenIcon />,
  label: "left arrow between",
  mathLiveCommand: "\\xleftarrow[\\placeholder{}]{\\placeholder{}}",
  tooltip: false,
};
export const rightArrowBetween: MathCommand = {
  renderChildren: () => <RightArrowBetweenIcon />,
  label: "right arrow between",
  mathLiveCommand: "\\xrightarrow[\\placeholder{}]{\\placeholder{}}",
  tooltip: false,
};
