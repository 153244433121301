import { IconProps } from "./types";

export const VerticalBarIcon = (props: IconProps) => {
  return (
    <svg width="24" height="28" viewBox="0 0 24 28" {...props}>
      <path
        d="M3.85223 23.23C3.76823 23.398 3.64823 23.482 3.49223 23.482C3.33623 23.482 3.21623 23.398 3.13223 23.23V14.482L3.15023 5.734C3.27023 5.578 3.38423 5.5 3.49223 5.5C3.64823 5.5 3.76823 5.59 3.85223 5.77V23.23Z"
        fill="#3A517F"
      />
      <path
        d="M7 9.33333C7 9.14924 7.14924 9 7.33333 9H14.6667C14.8508 9 15 9.14924 15 9.33333V16.6667C15 16.8508 14.8508 17 14.6667 17H7.33333C7.14924 17 7 16.8508 7 16.6667V9.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 10V16H14V10H8ZM7.33333 9C7.14924 9 7 9.14924 7 9.33333V16.6667C7 16.8508 7.14924 17 7.33333 17H14.6667C14.8508 17 15 16.8508 15 16.6667V9.33333C15 9.14924 14.8508 9 14.6667 9H7.33333Z"
        fill="#7070A9"
      />
      <path
        d="M20.862 23.23C20.778 23.398 20.658 23.482 20.502 23.482C20.346 23.482 20.226 23.398 20.142 23.23V14.482L20.16 5.734C20.28 5.578 20.394 5.5 20.502 5.5C20.658 5.5 20.778 5.59 20.862 5.77V23.23Z"
        fill="#3A517F"
      />
    </svg>
  );
};
