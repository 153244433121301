export default (
  <>
    <path
      d="M12.7966 16.0403C12.5632 16.0403 12.3881 15.9694 12.2714 15.8277C12.1546 15.686 12.0379 15.3441 11.9212 14.8022L11.6711 13.6392L10.7832 14.8898C10.5247 15.2566 10.2829 15.5359 10.0578 15.7277C9.84107 15.9111 9.59513 16.0028 9.32 16.0028C9.15326 16.0028 9.01986 15.9569 8.91981 15.8652C8.81977 15.7652 8.76974 15.6318 8.76974 15.465C8.76974 15.315 8.81977 15.1899 8.91981 15.0899C9.01152 14.9898 9.12824 14.9398 9.26998 14.9398C9.3867 14.9398 9.49091 14.969 9.58262 15.0273C9.69101 15.094 9.79522 15.1274 9.89527 15.1274C10.062 15.1274 10.2538 14.9815 10.4705 14.6897L11.5835 13.139L11.2084 11.3256C11.15 11.0588 11.0625 10.8671 10.9458 10.7503C10.8374 10.6336 10.6706 10.5753 10.4455 10.5753C10.3371 10.5753 10.2329 10.5794 10.1329 10.5878C10.0412 10.5961 9.95363 10.6086 9.87025 10.6253L9.90777 10.3877C10.3997 10.3293 10.7957 10.2459 11.0958 10.1376C11.2376 10.0875 11.3584 10.0542 11.4585 10.0375C11.5669 10.0125 11.6502 10 11.7086 10C11.8587 10 11.9587 10.1209 12.0087 10.3627L12.3589 12.051C12.7091 11.5424 12.9967 11.1422 13.2218 10.8504C13.4469 10.5503 13.6053 10.3585 13.697 10.2751C13.7887 10.1834 13.8888 10.1209 13.9972 10.0875C14.1139 10.0459 14.2348 10.025 14.3598 10.025C14.7517 10.025 14.9476 10.1918 14.9476 10.5252C14.9476 10.6753 14.9018 10.7962 14.81 10.8879C14.7183 10.9796 14.6016 11.0255 14.4599 11.0255C14.3348 11.0255 14.2223 10.9963 14.1222 10.9379C14.0639 10.9046 14.0138 10.8837 13.9722 10.8754C13.9388 10.8671 13.9013 10.8629 13.8596 10.8629C13.7762 10.8629 13.6845 10.9046 13.5845 10.988C13.5094 11.0547 13.1301 11.5674 12.4464 12.5262L12.8216 14.2645C12.8633 14.4229 12.9008 14.5646 12.9342 14.6897C12.9675 14.8064 13.0009 14.9064 13.0342 14.9898C13.1093 15.1566 13.2176 15.2399 13.3594 15.2399C13.5928 15.2399 13.9263 14.9231 14.3598 14.2895L14.5349 14.4896C13.8513 15.5234 13.2718 16.0403 12.7966 16.0403Z"
      stroke="#5B6F98"
      strokeWidth="0.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50809 5.74344H14.8501V4.37568L8.54779 4.24891L8.27622 4.23462L4.8959 13.8777L3.55371 9.96993H0.94342V11.6541H2.28352L4.18149 16.9965H5.59118L9.50809 5.74344Z"
    />
  </>
);
