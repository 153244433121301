import {
  CadmusEditorContent,
  Content,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { Divider, typography } from "@vericus/cadmus-ui";
import styled from "styled-components";

interface Props {
  /** Prefix used to generate unique editor IDs */
  editorIdPrefix: string;
  /** Body editor contents */
  bodyContent: Content;
  /** References editor contents */
  referencesContent: Content;
  /** Optionally enable hanging indent in the references section */
  enableHangingIndent?: boolean;
}

export function SubmittedWork(props: Props) {
  const {
    editorIdPrefix,
    bodyContent,
    referencesContent,
    enableHangingIndent,
  } = props;

  const body = useCadmusEditor({
    editorId: `${editorIdPrefix}-body`,
    content: bodyContent,
    editable: false,
  });

  const references = useCadmusEditor({
    editorId: `${editorIdPrefix}-references`,
    content: referencesContent,
    editable: false,
    enableHangingIndent,
  });

  return (
    <Container>
      <CadmusEditorContent editor={body} />
      <Divider />
      <CadmusEditorContent editor={references} />
    </Container>
  );
}

const Container = styled.div`
  /* Sets displayTwo styles first child titles */
  .ProseMirror > h1:first-child {
    font-family: ${typography["displayTwo"].fontFamily};
    font-size: ${typography["displayTwo"].fontSize};
    font-weight: ${typography["displayTwo"].fontWeight};
    padding-top: ${typography["displayTwo"].paddingTop};
    padding-bottom: ${typography["displayTwo"].paddingBottom};
    line-height: ${typography["displayTwo"].lineHeight};
    letter-spacing: ${typography["displayTwo"].letterSpacing};
  }
`;
