import { Command, getNodeType, isNodeSelection } from "@tiptap/core";
import { Fragment, NodeType, Slice } from "@tiptap/pm/model";
import { Selection } from "@tiptap/pm/state";
import { canSplit } from "@tiptap/pm/transform";

type TypeAfter =
  | { type: NodeType; attrs: Record<string, any> }
  | null
  | undefined;
type TypesAfter = TypeAfter[];

/**
 * Build a command that splits a non-empty textblock at the top level
 * of a list item by also splitting that list item.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function splitListItem(
  listItemTypeOrName: string | NodeType,
  ignoreAttrs: string[] = ["checked"]
): Command {
  return ({ tr, dispatch, state }) => {
    const listItemType = getNodeType(listItemTypeOrName, state.schema);
    const { $from, $to } = tr.selection;

    if (
      // Don't apply to node selection where the selected node is a block (inline nodes might be okay)
      (isNodeSelection(tr.selection) && tr.selection.node.isBlock) ||
      // List items can only exists at a depth of 2 or greater
      $from.depth < 2 ||
      // Don't apply to a selection which spans multiple nodes.
      !$from.sameParent($to)
    ) {
      return false;
    }
    // Get the grandparent of the start to make sure that it has the same type
    // as the list item type.
    const grandParent = $from.node(-1);

    if (grandParent.type !== listItemType) {
      return false;
    }

    if (
      $from.parent.content.size === 0 &&
      $from.node(-1).childCount === $from.indexAfter(-1)
    ) {
      // In an empty block. If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.
      if (
        $from.depth === 2 ||
        $from.node(-3).type !== listItemType ||
        $from.index(-2) !== $from.node(-2).childCount - 1
      ) {
        return false;
      }

      if (dispatch) {
        const keepItem = $from.index(-1) > 0;
        let wrap = Fragment.empty;

        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (
          let depth = $from.depth - (keepItem ? 1 : 2);
          depth >= $from.depth - 3;
          depth--
        ) {
          wrap = Fragment.from($from.node(depth).copy(wrap));
        }

        const content =
          listItemType.contentMatch.defaultType?.createAndFill() || undefined;

        wrap = wrap.append(
          Fragment.from(listItemType.createAndFill(null, content) || undefined)
        );

        tr.replace(
          $from.before(keepItem ? undefined : -1),
          $from.after(-3),
          new Slice(wrap, keepItem ? 3 : 2, 2)
        );
        tr.setSelection(
          (tr.selection.constructor as typeof Selection).near(
            tr.doc.resolve($from.pos + (keepItem ? 3 : 2))
          )
        );
        dispatch(tr.scrollIntoView());
      }

      return true;
    }

    const listItemAttributes = Object.fromEntries(
      Object.entries(grandParent.attrs).filter(
        ([attr]) => !ignoreAttrs.includes(attr)
      )
    );

    // The content inside the list item (e.g. paragraph)
    const contentType =
      $to.pos === $from.end()
        ? grandParent.contentMatchAt(0).defaultType
        : null;
    const contentAttributes = { ...$from.node().attrs };

    tr.delete($from.pos, $to.pos);

    const types: TypesAfter = contentType
      ? [
          { type: listItemType, attrs: listItemAttributes },
          { type: contentType, attrs: contentAttributes },
        ]
      : [{ type: listItemType, attrs: listItemAttributes }];

    if (!canSplit(tr.doc, $from.pos, 2)) {
      // I can't use `canSplit(tr.doc, $from.pos, 2, types)` and I don't know why
      return false;
    }

    if (dispatch) {
      // @ts-expect-error TODO: types for `tr.split` need to be fixed in `@types/prosemirror-transform`
      dispatch(tr.split($from.pos, 2, types).scrollIntoView());
    }

    return true;
  };
}
