import { IconProps } from "./types";

export const RoundBracketIcon = (props: IconProps) => {
  return (
    <svg width="24" height="29" viewBox="0 0 24 29" {...props}>
      <path
        d="M5.99791 23.338C5.99791 23.446 5.88991 23.5 5.67391 23.5H5.43991C5.35591 23.428 5.18791 23.284 4.93591 23.068C2.77591 21.1 1.69591 18.244 1.69591 14.5C1.69591 13.036 1.86991 11.698 2.21791 10.486C2.76991 8.602 3.67591 7.084 4.93591 5.932C5.01991 5.86 5.11591 5.782 5.22391 5.698C5.33191 5.602 5.40391 5.536 5.43991 5.5H5.67391C5.81791 5.5 5.90791 5.518 5.94391 5.554C5.97991 5.59 5.99791 5.626 5.99791 5.662C5.99791 5.71 5.93191 5.8 5.79991 5.932C3.93991 7.9 3.00991 10.756 3.00991 14.5C3.00991 18.244 3.93991 21.1 5.79991 23.068C5.93191 23.2 5.99791 23.29 5.99791 23.338Z"
        fill="#3A517F"
      />
      <path
        d="M8 10.8333C8 10.6492 8.14924 10.5 8.33333 10.5H15.6667C15.8508 10.5 16 10.6492 16 10.8333V18.1667C16 18.3508 15.8508 18.5 15.6667 18.5H8.33333C8.14924 18.5 8 18.3508 8 18.1667V10.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5V17.5H15V11.5H9ZM8.33333 10.5C8.14924 10.5 8 10.6492 8 10.8333V18.1667C8 18.3508 8.14924 18.5 8.33333 18.5H15.6667C15.8508 18.5 16 18.3508 16 18.1667V10.8333C16 10.6492 15.8508 10.5 15.6667 10.5H8.33333Z"
        fill="#7070A9"
      />
      <path
        d="M18.08 5.518L18.152 5.5C18.212 5.5 18.272 5.5 18.332 5.5H18.548C18.632 5.572 18.8 5.716 19.052 5.932C21.212 7.9 22.292 10.756 22.292 14.5C22.292 15.976 22.118 17.308 21.77 18.496C21.218 20.392 20.312 21.916 19.052 23.068C18.968 23.14 18.872 23.218 18.764 23.302C18.656 23.398 18.584 23.464 18.548 23.5H18.332C18.188 23.5 18.092 23.488 18.044 23.464C18.008 23.44 17.99 23.38 17.99 23.284C18.002 23.272 18.068 23.194 18.188 23.05C20.048 21.118 20.978 18.268 20.978 14.5C20.978 10.732 20.048 7.882 18.188 5.95C18.068 5.806 18.002 5.728 17.99 5.716C17.99 5.62 18.02 5.554 18.08 5.518Z"
        fill="#3A517F"
      />
    </svg>
  );
};
