import { Node as PMNode } from "@tiptap/pm/model";
import { EditorState, Transaction } from "@tiptap/pm/state";
import { TableMap, isInTable, selectedRect } from "@tiptap/pm/tables";

import type { NodeWithPos } from "../../../core/types";
import type { BorderSide, BorderSideOption } from "../constants";
import { findTable } from "./find";
import { isBottomCell, isTopCell } from "./tableBorders";
import { CellAttributes, CellsMap } from "./types";

/**
 * Set cell's attributes merged with current cell's attributes
 */
export const setCellAttrs =
  (cell: NodeWithPos, attrs: CellAttributes) =>
  (tr: Transaction): Transaction => {
    if (cell) {
      tr.setNodeMarkup(
        cell.pos,
        undefined,
        Object.assign({}, cell.node.attrs, attrs)
      );
    }

    return tr;
  };

export const setNeighbourCellsBorder =
  (map: TableMap, cellPos: number, side: BorderSide, border: boolean) =>
  (tr: Transaction): Transaction => {
    const table = findTable(tr.selection);
    if (!table) return tr;
    let newTr = tr;
    let neighbourCellPos;
    const relativePos = cellPos - table.start;
    if (side === "left") {
      neighbourCellPos = map.nextCell(relativePos, "horiz", -1);
    } else if (side === "right") {
      neighbourCellPos = map.nextCell(relativePos, "horiz", 1);
    } else if (side === "bottom") {
      neighbourCellPos = map.nextCell(relativePos, "vert", 1);
    } else {
      neighbourCellPos = map.nextCell(relativePos, "vert", -1);
    }
    if (neighbourCellPos !== null) {
      const node = table.node.nodeAt(neighbourCellPos) as PMNode;
      const pos = table.start + neighbourCellPos;
      let newBorder;
      if (side === "left") {
        newBorder = {
          right: border,
        };
      } else if (side === "right") {
        newBorder = {
          left: border,
        };
      } else if (side === "bottom") {
        newBorder = {
          top: border,
        };
      } else {
        newBorder = {
          bottom: border,
        };
      }
      const newAttrs = {
        ...node.attrs,
        border: {
          ...node.attrs.border,
          ...newBorder,
        },
      };
      newTr = setCellAttrs({ node, pos }, newAttrs)(newTr);
    }
    return newTr;
  };

/**
 * Set table's cell on `index` of the cellsMap's border on the specified `side` to be on/off
 */
export const setCellBorder =
  (
    cellsMap: CellsMap,
    index: number,
    side: BorderSideOption,
    border: boolean
  ) =>
  (tr: Transaction): Transaction => {
    const table = findTable(tr.selection);
    if (!table) return tr;
    let newTr = tr;
    const { cells, map, rect } = cellsMap;
    const cell = cells[index];
    const { node, pos } = cell;
    let newBorder;
    if (side === "all") {
      newBorder = {
        left: border,
        right: border,
        top: border,
        bottom: border,
      };
    } else if (side === "top") {
      if (isTopCell(cell, table, map, rect)) {
        newBorder = {
          top: border,
        };
      }
    } else if (side === "bottom") {
      if (isBottomCell(cell, table, map, rect)) {
        newBorder = {
          bottom: border,
        };
      }
    } else if (side === "insideHorizontal" && rect) {
      if (isBottomCell(cell, table, map, rect)) {
        newBorder = {
          top: border,
        };
      } else {
        newBorder = {
          bottom: border,
        };
      }
    }
    if (newBorder) {
      const newAttrs = {
        ...node.attrs,
        border: {
          ...node.attrs.border,
          ...newBorder,
        },
      };
      newTr = setCellAttrs({ node, pos }, newAttrs)(newTr);
      if (side === "all") {
        newTr = setNeighbourCellsBorder(map, pos, "top", border)(newTr);
        newTr = setNeighbourCellsBorder(map, pos, "bottom", border)(newTr);
        newTr = setNeighbourCellsBorder(map, pos, "left", border)(newTr);
        newTr = setNeighbourCellsBorder(map, pos, "right", border)(newTr);
      } else if (side === "bottom") {
        newTr = setNeighbourCellsBorder(map, pos, "bottom", border)(newTr);
      } else if (side === "top") {
        newTr = setNeighbourCellsBorder(map, pos, "top", border)(newTr);
      }
    }
    return newTr;
  };
/**
 * Set selected cells background to `color`
 */
export const setCellsBackground =
  (color?: string) =>
  (state: EditorState, dispatch?: (tr: Transaction) => void): boolean => {
    if (!isInTable(state)) return false;
    let tr = state.tr;
    const rect = selectedRect(state);
    if (!rect) return false;
    const { map } = rect;
    for (let row = rect.top; row < rect.bottom; row++) {
      for (let col = rect.left; col < rect.right; col++) {
        const cellPos = map.map[row * map.width + col];
        const cell = rect.table.nodeAt(cellPos);
        if (cell) {
          tr = setCellAttrs(
            { pos: cellPos + rect.tableStart, node: cell },
            { backgroundColor: color }
          )(tr);
        }
      }
    }
    dispatch?.(tr);
    return true;
  };
