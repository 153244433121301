import { Extension } from "@tiptap/core";

import type { BorderSideOption } from "../constants";
import { findTable, getCellsMap, setCellBorder } from "../utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableCellBorder: {
      /**
       * Set selected cells' border on the specified side to true/false
       */
      setTableCellBorder: (
        side: BorderSideOption,
        border: boolean
      ) => ReturnType;
    };
  }
}

export interface TableCellBorderOptions {
  sides: BorderSideOption[];
}

/**
 * Editor Extension to add left, right, bottom & top border attribute to
 * table cell and table header cell
 */
export const TableCellBorder = Extension.create<TableCellBorderOptions>({
  name: "tableCellBorder",
  addOptions() {
    return {
      sides: ["all", "bottom", "insideHorizontal", "top"],
    };
  },
  addGlobalAttributes() {
    function parseBorder(element: HTMLElement) {
      // external paste which doesn't have "data-(.*)-border" attribute will be
      // treatet as having a border
      return {
        left: element.hasAttribute("data-left-border")
          ? element.getAttribute("data-left-border") === "true"
          : true,
        right: element.hasAttribute("data-right-border")
          ? element.getAttribute("data-right-border") === "true"
          : true,
        bottom: element.hasAttribute("data-bottom-border")
          ? element.getAttribute("data-bottom-border") === "true"
          : true,
        top: element.hasAttribute("data-top-border")
          ? element.getAttribute("data-top-border") === "true"
          : true,
      };
    }

    function renderBorder(attributes: Record<string, any>) {
      if (!attributes.border) return {};
      return {
        "data-left-border": attributes.border.left ? "true" : "false",
        "data-right-border": attributes.border.right ? "true" : "false",
        "data-bottom-border": attributes.border.bottom ? "true" : "false",
        "data-top-border": attributes.border.top ? "true" : "false",
      };
    }
    return [
      {
        types: ["tableCell", "tableHeader"],
        attributes: {
          border: {
            default: {
              left: true,
              right: true,
              top: true,
              bottom: true,
            },
            parseHTML: (element) => parseBorder(element),
            renderHTML: (attributes) => renderBorder(attributes),
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setTableCellBorder:
        (side: BorderSideOption, border: boolean) =>
        ({ state, dispatch }) => {
          if (!this.options.sides.includes(side)) return false;
          const table = findTable(state.selection);
          if (!table) return false;
          let { tr } = state;
          const cellsMap = getCellsMap(state.schema, state.selection);
          if (!cellsMap) return false;
          const { cells, height } = cellsMap;
          if (side === "insideHorizontal" && height < 2) return false;
          cells.map((_cell, index) => {
            tr = setCellBorder(cellsMap, index, side, border)(tr);
          });
          dispatch?.(tr);
          return true;
        },
    };
  },
});
