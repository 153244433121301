/**
 * Capture and expose build-time configuration for runtime usage.
 */

///////////////////////////////////////////////////////////////////////////////
// Pantheon                                                                 //
//////////////////////////////////////////////////////////////////////////////

export const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT!;
export const HERMES_ENDPOINT = import.meta.env.VITE_HERMES_ENDPOINT!;
export const SUGGESTION_ENDPOINT = import.meta.env.VITE_SUGGESTION_ENDPOINT!;
export const WS_ENDPOINT = import.meta.env.VITE_WS_ENDPOINT!;

//////////////////////////////////////////////////////////////////////////////
// Contentful                                                               //
//////////////////////////////////////////////////////////////////////////////

export const CONTENTFUL_ACCESS_TOKEN = import.meta.env
  .VITE_CONTENTFUL_ACCESS_TOKEN!;
export const CONTENTFUL_PREVIEW_ACCESS_TOKEN = import.meta.env
  .VITE_CONTENTFUL_PREVIEW_ACCESS_TOKEN!;
export const CONTENTFUL_PREVIEW = import.meta.env.VITE_CONTENTFUL_PREVIEW!;
export const CONTENTFUL_SPACE_ID = import.meta.env.VITE_CONTENTFUL_SPACE_ID!;

//////////////////////////////////////////////////////////////////////////////
// Sentry                                                                   //
//////////////////////////////////////////////////////////////////////////////

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN!;

//////////////////////////////////////////////////////////////////////////////
// Codex                                                                    //
//////////////////////////////////////////////////////////////////////////////

export const CODEX_ENDPOINT = import.meta.env.VITE_CODEX_ENDPOINT!;

//////////////////////////////////////////////////////////////////////////////
// Application Environment                                                  //
//////////////////////////////////////////////////////////////////////////////

export const DEV = import.meta.env.DEV!;
export const DEPLOY_ENV = parseDeployEnv();
export const RELEASE_HASH = import.meta.env.VITE_RELEASE_HASH!;

/** Deployment environment as a validated enum. */
export enum DeployEnv {
  LOCAL = "local",
  TEST = "test",
  STAGING = "staging",
  EXAM = "exam",
  PRODUCTION_AU = "production",
  PRODUCTION_UK = "uk",
  PRODUCTION_US = "us",
}

function parseDeployEnv(): DeployEnv {
  const rawDeployEnv = import.meta.env.VITE_DEPLOY_ENV;
  switch (rawDeployEnv) {
    // XXX Using `DeployEnv` during build time does not work with jest. Have to use the string values to match.
    case "local":
    case "test":
    case "staging":
    case "exam":
    case "production":
    case "uk":
    case "us":
      return rawDeployEnv as DeployEnv;
    default:
      throw new Error("Not a valid DEPLOY_ENV");
  }
}
