/**
 * Supported Special Indentation styles with "normal" being a default unstyled variant.
 */
export type SpecialIndentationStyle = "normal" | "firstLine" | "hanging";

/**
 * Indentation extension options.
 */
export type IndentationOptions = {
  // Node types that have Special Indentation
  specialTypes: string[];
  // Nodes which can have Level Indentation
  levelTypes: string[];
  // Allowed Special Indentation styles
  indentationStyles: SpecialIndentationStyle[];
  // Default Special Indentation style
  defaultIndentation: SpecialIndentationStyle;
  // Maxmimum level of Level Indentation on a node
  maxIndentation: number;
};

/**
 * Extension options passed when configuring Level Indentation.
 */
export interface LevelIndentationOption {
  // Node types that have Special Indentation
  types: string[];
  // Maxmimum level of Level Indentation on a node
  maxIndentation: number;
  defaultIndentation: SpecialIndentationStyle;
}

/**
 * Extension options passed when configuring Special Indentation.
 */
export interface SpecialIndentationOption {
  types: string[];
  indentationStyles: SpecialIndentationStyle[];
  defaultIndentation: SpecialIndentationStyle;
  renderStyles: SpecialIndentationStyle[];
}

/** Common extension options for a Level+Sepcial Indentation extension. */
export type MixIndentationOption = LevelIndentationOption &
  SpecialIndentationOption;

/**
 * Directional effect of a Tab key used to indent or un-indent.
 */
export enum TabDirection {
  FORWARD = 1,
  BACKWARD = -1,
  UNKNOWN = 0,
}
