import { Spacer, Text } from "@vericus/cadmus-ui";

import XPIllustration from "assets/submission/submission-experience.png";

import { RatingScale } from "./RatingScale";
import { FlexImg, FlexText, Padding, XPFlex } from "./styles";

interface Props {
  onUpdateScore: (score: number) => void;
}

/**
 * Survey of user experience using Cadmus. Students are asked to
 *  rate Cadmus sultProps placing it into 1 of 5 buckets starting
 *  from 'very poor' to 'excellent'.
 */
export function Rating({ onUpdateScore }: Props) {
  return (
    <Padding>
      <XPFlex>
        <FlexText>
          <Text kind="headingThree">
            How was your experience using Cadmus in this assignment?
          </Text>
          <Spacer spacing={27} />
          <RatingScale onUpdateScore={onUpdateScore} />
        </FlexText>
        <FlexImg
          src={XPIllustration}
          alt="An illustration of two people discussing something.
        It's unclear what they are talking about, but one person
        seems to like the other person's idea."
        />
      </XPFlex>
    </Padding>
  );
}
