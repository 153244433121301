export default (
  <>
    <path
      d="M0.466073 0.669948V0.672626C0.202715 0.723518 -0.00468088 0.983639 8.03613e-05 1.25594V18.7519C8.03613e-05 19.0559 0.274892 19.3333 0.575429 19.3333H15.424C15.7253 19.3333 16 19.056 16 18.7506V5.22679C15.9946 5.13766 15.9693 5.04956 15.9266 4.97159L11.5113 0.705685C11.454 0.68381 11.3927 0.671757 11.3312 0.669227H0.574995C0.539286 0.665804 0.501782 0.666525 0.466073 0.669948Z"
      fill="#5B6F98"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.53927 15.7036L8.44405 6.29325L9.46073 6.29635L7.55595 15.7067L6.53927 15.7036Z"
      fill="white"
    />
    <path
      d="M2.85608 10.9972L5.64989 13.0946V12.3608L3.83425 10.9972L5.64989 9.6336V8.90002L2.85608 10.9972Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
      strokeLinecap="square"
    />
    <path
      d="M13.1439 10.9972L10.3501 13.0946V12.3608L12.1657 10.9972L10.3501 9.6336V8.90002L13.1439 10.9972Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
      strokeLinecap="square"
    />
  </>
);
