import { IconProps } from "./types";

export const Vector2X2Icon = (props: IconProps) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" {...props}>
      <path
        d="M6.5 5.5749C6.5 5.54729 6.52239 5.5249 6.55 5.5249H10.45C10.4776 5.5249 10.5 5.54729 10.5 5.5749V9.4749C10.5 9.50252 10.4776 9.5249 10.45 9.5249H6.55C6.52239 9.5249 6.5 9.50252 6.5 9.4749V5.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55 4.5249H10.45C11.0299 4.5249 11.5 4.995 11.5 5.5749V9.4749C11.5 10.0548 11.0299 10.5249 10.45 10.5249H6.55C5.9701 10.5249 5.5 10.0548 5.5 9.4749V5.5749C5.5 4.995 5.9701 4.5249 6.55 4.5249ZM6.55 5.5249C6.52239 5.5249 6.5 5.54729 6.5 5.5749V9.4749C6.5 9.50252 6.52239 9.5249 6.55 9.5249H10.45C10.4776 9.5249 10.5 9.50252 10.5 9.4749V5.5749C10.5 5.54729 10.4776 5.5249 10.45 5.5249H6.55Z"
        fill="#7070A9"
      />
      <path
        d="M13.5 5.5749C13.5 5.54729 13.5224 5.5249 13.55 5.5249H17.45C17.4776 5.5249 17.5 5.54729 17.5 5.5749V9.4749C17.5 9.50252 17.4776 9.5249 17.45 9.5249H13.55C13.5224 9.5249 13.5 9.50252 13.5 9.4749V5.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.55 4.5249H17.45C18.0299 4.5249 18.5 4.995 18.5 5.5749V9.4749C18.5 10.0548 18.0299 10.5249 17.45 10.5249H13.55C12.9701 10.5249 12.5 10.0548 12.5 9.4749V5.5749C12.5 4.995 12.9701 4.5249 13.55 4.5249ZM13.55 5.5249C13.5224 5.5249 13.5 5.54729 13.5 5.5749V9.4749C13.5 9.50252 13.5224 9.5249 13.55 9.5249H17.45C17.4776 9.5249 17.5 9.50252 17.5 9.4749V5.5749C17.5 5.54729 17.4776 5.5249 17.45 5.5249H13.55Z"
        fill="#7070A9"
      />
      <path
        d="M6.5 12.5251C6.5 12.4975 6.52239 12.4751 6.55 12.4751H10.45C10.4776 12.4751 10.5 12.4975 10.5 12.5251V16.4251C10.5 16.4527 10.4776 16.4751 10.45 16.4751H6.55C6.52239 16.4751 6.5 16.4527 6.5 16.4251V12.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55 11.4751H10.45C11.0299 11.4751 11.5 11.9452 11.5 12.5251V16.4251C11.5 17.005 11.0299 17.4751 10.45 17.4751H6.55C5.9701 17.4751 5.5 17.005 5.5 16.4251V12.5251C5.5 11.9452 5.9701 11.4751 6.55 11.4751ZM6.55 12.4751C6.52239 12.4751 6.5 12.4975 6.5 12.5251V16.4251C6.5 16.4527 6.52239 16.4751 6.55 16.4751H10.45C10.4776 16.4751 10.5 16.4527 10.5 16.4251V12.5251C10.5 12.4975 10.4776 12.4751 10.45 12.4751H6.55Z"
        fill="#7070A9"
      />
      <path
        d="M13.5 12.5251C13.5 12.4975 13.5224 12.4751 13.55 12.4751H17.45C17.4776 12.4751 17.5 12.4975 17.5 12.5251V16.4251C17.5 16.4527 17.4776 16.4751 17.45 16.4751H13.55C13.5224 16.4751 13.5 16.4527 13.5 16.4251V12.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.55 11.4751H17.45C18.0299 11.4751 18.5 11.9452 18.5 12.5251V16.4251C18.5 17.005 18.0299 17.4751 17.45 17.4751H13.55C12.9701 17.4751 12.5 17.005 12.5 16.4251V12.5251C12.5 11.9452 12.9701 11.4751 13.55 11.4751ZM13.55 12.4751C13.5224 12.4751 13.5 12.4975 13.5 12.5251V16.4251C13.5 16.4527 13.5224 16.4751 13.55 16.4751H17.45C17.4776 16.4751 17.5 16.4527 17.5 16.4251V12.5251C17.5 12.4975 17.4776 12.4751 17.45 12.4751H13.55Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7558 5.53217C20.7558 4.89686 20.2408 4.38184 19.6055 4.38184V3.38184C20.7931 3.38184 21.7558 4.34457 21.7558 5.53217V16.4676C21.7558 17.6552 20.7931 18.6179 19.6055 18.6179V17.6179C20.2408 17.6179 20.7558 17.1029 20.7558 16.4676V5.53217Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24419 5.53217C3.24419 4.89686 3.75922 4.38184 4.39453 4.38184V3.38184C3.20693 3.38184 2.24419 4.34457 2.24419 5.53217V16.4676C2.24419 17.6552 3.20693 18.6179 4.39453 18.6179V17.6179C3.75922 17.6179 3.24419 17.1029 3.24419 16.4676V5.53217Z"
        fill="#3A517F"
      />
    </svg>
  );
};
