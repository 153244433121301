import { Node } from "@tiptap/core";

export interface BlockMathOptions {
  HTMLAttributes: Record<string, unknown>;
  /**
   * Render a Latex math equation as HTML markup.
   */
  convertLatexToMarkup: (latex: string) => string;
}

/**
 * Base Block level atomic math nodes to display equation
 */
export const BlockMathBase = Node.create<BlockMathOptions>({
  name: "blockMath",
  atom: true,
  selectable: true,
  draggable: true,
  group: "math_block",
  mathRole: "block",

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
      convertLatexToMarkup: (latex: string) => `<code>${latex}</code>`,
    };
  },

  addAttributes() {
    return {
      equation: {
        default: "",
        parseHTML: (element) => {
          if (element.hasAttribute("data-math-latex"))
            return element.getAttribute("data-math-latex");
          return element.textContent;
        },
        renderHTML: ({ equation }) => ({
          "data-math-latex": equation,
        }),
      },
      caption: {
        default: "",
        parseHTML: (element) => element.getAttribute("data-math-caption"),
        renderHTML: ({ caption }) => ({
          "data-math-caption": caption,
          style: `--math-caption: "${caption}";`,
        }),
      },
    };
  },
  renderText({ node }) {
    return `$$${node.attrs.equation}$$\n`;
  },
  parseHTML() {
    return [
      {
        tag: "math-field",
        context: "mathFigure/",
      },
      {
        tag: "div",
        getAttrs(node) {
          if (typeof node === "string") return false;
          const element = node as HTMLElement;
          if (element.hasAttribute("data-math-latex"))
            return {
              equation: element.getAttribute("data-math-latex"),
              caption: element.getAttribute("data-math-caption"),
            };
          return false;
        },
      },
    ];
  },
});
