import * as Sentry from "@sentry/react";
import {
  CadmusEditorContent,
  useCadmusEditor,
  useControlledEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { useManualPreset } from "@vericus/cadmus-manual";

import { useReferencesEditor } from "@/stores/editors/editorVars";

export function ReferencesEditorContent() {
  const editor = useReferencesEditor();
  const openManualPreset = useManualPreset();
  useControlledEditor(editor);
  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent
        editor={editor}
        onOpenManualPreset={openManualPreset}
      />
    </Sentry.ErrorBoundary>
  );
}

interface ReadOnlyProps {
  enableHangingIndent?: boolean;
}

export function ReferencesEditorContentReadOnly(props: ReadOnlyProps) {
  const { enableHangingIndent } = props;
  const references = useReferencesEditor();
  const content = references?.getHTML() ?? null;
  const editor = useCadmusEditor(
    {
      editorId: "references-readonly",
      content,
      editable: false,
      enableHangingIndent,
    },
    [content]
  );

  return <CadmusEditorContent editor={content ? editor : null} />;
}
