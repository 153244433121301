import { colors } from "@vericus/cadmus-ui";
import { opacify, transparentize } from "polished";

/**
 * Default applied color when alternate table color is selected
 */
export const defaultTableColor = transparentize(0.94, colors.blue500);

/**
 * A list of possible colors that can be applied to table / cells
 */
export const tableColors = [
  {
    button: opacify(0.19, colors.grey50),
    actual: colors.grey50,
  },
  {
    button: transparentize(0.75, colors.blue500),
    actual: defaultTableColor,
  },
  {
    button: transparentize(0.75, colors.green500),
    actual: transparentize(0.94, colors.green500),
  },
  {
    button: transparentize(0.75, colors.yellow500),
    actual: transparentize(0.94, colors.yellow500),
  },
  {
    button: transparentize(0.75, colors.red500),
    actual: transparentize(0.94, colors.red500),
  },
  {
    button: transparentize(0.75, colors.indigo500),
    actual: transparentize(0.94, colors.indigo500),
  },
];

export const maxWidth = 800;
export const cellMinWidth = 15;
export const cellDefaultWidth = 50;
export const borderSides = ["left", "right", "top", "bottom"] as const;
export const borderSideOptions = [
  ...borderSides,
  "insideHorizontal",
  "insideVertical",
  "inside",
  "outside",
  "all",
] as const;
export type BorderSide = typeof borderSides[number];
export type BorderSideOption = typeof borderSideOptions[number];
