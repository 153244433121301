import { useCallback, useEffect, useRef, useState } from "react";
import { Editor, posToDOMRect } from "@tiptap/core";
import { ControlButton, Input, levels } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { isValidURL } from "../../core/text";
import { FloatingMenuComponent, ShouldShow } from "../floating-menu";
import { openLink } from "./helpers";
import {
  DismissMenuAction,
  HyperlinkMenuPluginKey,
} from "./hyperlink-menu-plugin";

interface Props {
  editor: Editor;
  portalId: string;
}

/**
 * React component for the Hyperlink `BubbleMenu`.
 *
 * There will be only one such menu rendered at a time, and its display and
 * position will be controlled using a `BubbleMenu` plugin instance.
 */
export function HyperlinkMenuComponent(props: Props) {
  const { editor, portalId } = props;
  const shouldShow: ShouldShow = useCallback(({ state }) => {
    const hyperlink = HyperlinkMenuPluginKey.getState(state);
    return !!hyperlink;
  }, []);

  const getBoundingClientRect = useCallback(() => {
    const { state, view } = editor;
    const hyperlink = HyperlinkMenuPluginKey.getState(state);
    const { selection } = state;
    let { from, to } = selection;
    if (hyperlink) {
      from = hyperlink.from;
      to = hyperlink.to;
    }
    return posToDOMRect(view, from, to);
  }, [editor]);

  return (
    <FloatingMenuComponent
      pluginKey="hyperlinkFloatingMenu"
      portalId={portalId}
      editor={editor}
      shouldShow={shouldShow}
      getBoundingClientRect={getBoundingClientRect}
      isAsync={true}
    >
      <HyperlinkMenu editor={editor} />
    </FloatingMenuComponent>
  );
}

// Inner hyperlink edit and insertion menu component
export const HyperlinkMenu = ({ editor }: { editor: Editor }) => {
  const [inputHref, setInputHref] = useState("");
  const hyperlink = HyperlinkMenuPluginKey.getState(editor.state);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const href = hyperlink?.href ?? "";
    inputRef.current?.focus({ preventScroll: true });
    setInputHref(href);
  }, [setInputHref, hyperlink]);

  const onConfirm = useCallback(
    (href: string) => {
      editor
        .chain()
        .updateLink(href)
        .command(({ tr }) => {
          const action: DismissMenuAction = { type: "dismissMenu" };
          tr.setMeta(HyperlinkMenuPluginKey, action);
          return true;
        })
        .run();
    },
    [editor]
  );

  const onTrash = useCallback(() => {
    editor
      .chain()
      .unsetLink()
      .command(({ tr }) => {
        const action: DismissMenuAction = { type: "dismissMenu" };
        tr.setMeta(HyperlinkMenuPluginKey, action);
        return true;
      })
      .run();
  }, [editor]);

  return (
    <Container>
      <Input
        aria-label="link url"
        placeholder="Paste web address"
        type="url"
        fullWidth
        value={inputHref}
        onChange={(event) => setInputHref(event.target.value)}
        onKeyPress={(event) => {
          if (event.code === "Enter") {
            onConfirm(inputHref);
          }
        }}
        ref={inputRef}
      />
      <ControlButton
        aria-label="confirm url change"
        onClick={() => onConfirm(inputHref)}
        iconName="Tick"
      />
      <ControlButton
        aria-label="open in new tab"
        disabled={!isValidURL(inputHref)}
        onClick={() => openLink?.(inputHref)}
        iconName="Open"
      />
      <ControlButton
        aria-label="remove link"
        onClick={onTrash}
        iconName="Trash"
      />
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(3, min-content);
  grid-gap: 3px;
  padding: 8px;
  box-shadow: ${levels.two};
  background: white;
  border-radius: 3px;
  width: 450px;
  box-sizing: border-box;
`;
