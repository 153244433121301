import { IconProps } from "./types";

export const NaturalLogIcon = (props: IconProps) => {
  return (
    <svg width="28" height="20" viewBox="0 0 28 20" {...props}>
      <path
        d="M1.09666 6.768C1.10466 6.768 1.47266 6.752 2.20066 6.72L3.29266 6.672H3.36466V14.256H4.19266V15H4.08466C3.98066 14.976 3.49266 14.964 2.62066 14.964C1.75666 14.964 1.27266 14.976 1.16866 15H1.06066V14.256H1.88866V11.016C1.88866 8.856 1.88066 7.756 1.86466 7.716C1.82466 7.58 1.60866 7.512 1.21666 7.512H1.06066V6.768H1.09666ZM4.5927 9.696C6.0087 9.632 6.7207 9.6 6.7287 9.6H6.8007V10.116L6.8127 10.62C7.1167 10.116 7.6647 9.784 8.4567 9.624C8.5527 9.608 8.6967 9.6 8.8887 9.6C9.4487 9.6 9.8727 9.696 10.1607 9.888C10.4487 10.08 10.6367 10.4 10.7247 10.848C10.7407 10.936 10.7527 11.528 10.7607 12.624V14.256H11.5887V15H11.4807C11.3767 14.976 10.8767 14.964 9.9807 14.964C9.1007 14.964 8.6047 14.976 8.4927 15H8.3847V14.256H9.2127V12.672C9.2127 11.584 9.2087 11.012 9.2007 10.956C9.1767 10.7 9.1167 10.512 9.0207 10.392C8.9327 10.272 8.7807 10.212 8.5647 10.212C8.1567 10.212 7.8087 10.336 7.5207 10.584C7.2327 10.832 7.0487 11.144 6.9687 11.52C6.9527 11.592 6.9407 12.068 6.9327 12.948V14.256H7.7607V15H7.6527C7.5407 14.976 7.0447 14.964 6.1647 14.964C5.2767 14.964 4.7767 14.976 4.6647 15H4.5567V14.256H5.3847V12.48C5.3847 11.296 5.3767 10.684 5.3607 10.644C5.3207 10.508 5.1047 10.44 4.7127 10.44H4.5567V9.696H4.5927Z"
        fill="#3A517F"
      />
      <path
        d="M17.8213 9.05C17.8213 9.02239 17.8437 9 17.8713 9H21.7713C21.7989 9 21.8213 9.02239 21.8213 9.05V12.95C21.8213 12.9776 21.7989 13 21.7713 13H17.8713C17.8437 13 17.8213 12.9776 17.8213 12.95V9.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8713 8H21.7713C22.3512 8 22.8213 8.4701 22.8213 9.05V12.95C22.8213 13.5299 22.3512 14 21.7713 14H17.8713C17.2914 14 16.8213 13.5299 16.8213 12.95V9.05C16.8213 8.4701 17.2914 8 17.8713 8ZM17.8713 9C17.8437 9 17.8213 9.02239 17.8213 9.05V12.95C17.8213 12.9776 17.8437 13 17.8713 13H21.7713C21.7989 13 21.8213 12.9776 21.8213 12.95V9.05C21.8213 9.02239 21.7989 9 21.7713 9H17.8713Z"
        fill="#7070A9"
      />
      <path
        d="M22.6973 5.012H22.7213C22.7373 5.012 22.7533 5.012 22.7693 5.012C22.7853 5.004 22.8053 5 22.8293 5C22.8533 5 22.8773 5 22.9013 5H23.0813C23.8333 5.496 24.4533 6.176 24.9413 7.04C25.4293 7.904 25.7413 8.86 25.8773 9.908C25.9173 10.22 25.9373 10.58 25.9373 10.988C25.9373 11.404 25.9173 11.768 25.8773 12.08C25.6693 13.72 25.0653 15.076 24.0653 16.148C23.7213 16.5 23.3933 16.78 23.0813 16.988H22.9013C22.6933 16.988 22.5893 16.932 22.5893 16.82C22.5893 16.78 22.6453 16.7 22.7573 16.58C22.9973 16.324 23.1933 16.084 23.3453 15.86C24.1773 14.668 24.5933 13.044 24.5933 10.988C24.5933 8.804 24.1213 7.104 23.1773 5.888C22.9853 5.664 22.8453 5.504 22.7573 5.408C22.6613 5.304 22.6053 5.236 22.5893 5.204C22.5733 5.116 22.6093 5.052 22.6973 5.012Z"
        fill="#3A517F"
      />
      <path
        d="M13.4479 9.908C13.5839 8.86 13.8959 7.908 14.3839 7.052C14.8719 6.188 15.4919 5.504 16.2439 5H16.4239C16.5519 5 16.6279 5.008 16.6519 5.024C16.7479 5.072 16.7719 5.14 16.7239 5.228C16.7079 5.26 16.6519 5.324 16.5559 5.42C16.1799 5.836 15.8799 6.248 15.6559 6.656C15.0319 7.808 14.7199 9.256 14.7199 11C14.7199 13.496 15.3879 15.412 16.7239 16.748C16.7719 16.844 16.7479 16.916 16.6519 16.964C16.6279 16.98 16.5519 16.988 16.4239 16.988H16.2439C15.4919 16.492 14.8719 15.812 14.3839 14.948C13.8959 14.084 13.5839 13.128 13.4479 12.08C13.4079 11.768 13.3879 11.404 13.3879 10.988C13.3879 10.58 13.4079 10.22 13.4479 9.908Z"
        fill="#3A517F"
      />
    </svg>
  );
};
