export default (
  <>
    <path
      d="M14.5262 2.01123H1.47332C1.08255 2.01123 0.707861 2.16643 0.431528 2.44276C0.155195 2.71908 0 3.09378 0 3.48455V16.5374C0 16.9282 0.155202 17.3029 0.431528 17.5792C0.707854 17.8556 1.08255 18.0108 1.47332 18.0108H14.5262C14.917 18.0108 15.2917 17.8556 15.568 17.5792C15.8443 17.3029 15.9995 16.9282 15.9995 16.5374V3.48455C15.9995 3.09378 15.8443 2.71909 15.568 2.44276C15.2917 2.16643 14.917 2.01123 14.5262 2.01123Z"
      fill="#5B6F98"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.36426 13.8007L5.91619 8.80517C6.02354 8.65419 6.24634 8.65009 6.35917 8.79702L8.84478 12.0338L10.6722 10.6057C10.7924 10.5118 10.966 10.5334 11.0595 10.654L13.5001 13.8022L8.83559 13.8023V13.8007L3.00038 13.8007L2.36426 13.8007Z"
      fill="white"
    />
    <path
      d="M11.5013 7.20939C11.5013 8.09035 10.7871 8.80452 9.90616 8.80452C9.0252 8.80452 8.31104 8.09035 8.31104 7.20939C8.31104 6.32842 9.0252 5.61426 9.90616 5.61426C10.7871 5.61426 11.5013 6.32842 11.5013 7.20939Z"
      fill="white"
    />
  </>
);
