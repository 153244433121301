import { colors } from "@vericus/cadmus-ui";
import { css } from "styled-components";

const lightColors = {
  magenta100: "#B81466",
  red500: "#D92644",
  red300: "#4639DA",

  blue500: "#0066cc",
  blue800: "#0D2F73",

  black100: colors.black100,
  black200: "#0F172E",

  cyan500: "#00807F",

  pink500: "#CC00AA",
  yellow800: "#805500",
  grey500: "#5B6F98",

  background: "#f4f5f880",
  border: "#dee3ed",
  preDeleteBackground: colors.red100,
  preDeleteBorder: colors.red500,
  preDeleteGutter: colors.red200,
  selectedBackground: colors.blue200,
  gutterBackground: "#5973a60f",
  lineNumber: "#5b6f98",
  languageDisplay: "#5b6f98",
  cursor: "#00a3a3",
  activeLineBackground: "#fefefe",
  activeLineGutterBackground: "#f9fafb",
};

/** Light mode for `CodeBlock` nodes */
export const cadmusLightCodeBlockStyles = css`
  /* basic */
  .cm-s-cadmus-light {
    color: ${colors.black100};
    background-color: ${lightColors.background};
    border: solid 1px ${lightColors.border} !important;
  }

  .cm-s-cadmus-light.code-block-pre-delete {
    background-color: ${lightColors.preDeleteBackground};
    border: 1px solid ${lightColors.preDeleteBorder} !important;
    .CodeMirror-activeline-background {
      background-color: ${lightColors.preDeleteBackground};
    }
    .CodeMirror-linenumbers {
      background-color: ${lightColors.preDeleteGutter};
    }
    .CodeMirror-activeline-gutter {
      background-color: ${lightColors.preDeleteGutter};
    }
  }

  .cm-s-cadmus-light .CodeMirror-selected {
    color: ${lightColors.black200} !important;
    background: ${lightColors.selectedBackground} !important;
  }

  /** user select styling */
  .cm-s-cadmus-light .CodeMirror-focused .CodeMirror-selected,
  .cm-s-cadmus-light .CodeMirror-line::selection,
  .cm-s-cadmus-light .CodeMirror-line > span::selection,
  .cm-s-cadmus-light .CodeMirror-line > span > span::selection,
  .cm-s-cadmus-light .CodeMirror-line::-moz-selection,
  .cm-s-cadmus-light .CodeMirror-line > span::-moz-selection,
  .cm-s-cadmus-light .CodeMirror-line > span > span::-moz-selection {
    background: ${lightColors.selectedBackground};
  }

  .cm-s-cadmus-light .CodeMirror-gutter,
  .cm-s-cadmus-light .CodeMirror-gutters {
    border: none;
    background-color: ${lightColors.gutterBackground};
  }
  .cm-s-cadmus-light .CodeMirror-linenumber,
  .cm-s-cadmus-light .CodeMirror-linenumbers {
    color: ${lightColors.lineNumber} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-light .CodeMirror-lines {
    color: ${lightColors.black100} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-light .CodeMirror-cursor {
    border-left: 2px solid ${lightColors.cursor} !important;
  }

  .cm-s-cadmus-light .language-display {
    color: ${lightColors.languageDisplay} !important;
  }

  /* addon: edit/machingbrackets.js & addon: edit/matchtags.js */
  .cm-s-cadmus-light .CodeMirror-matchingbracket,
  .cm-s-cadmus-light .CodeMirror-matchingtag {
    border-bottom: 2px solid ${lightColors.grey500};
    color: ${lightColors.grey500} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-light .CodeMirror-nonmatchingbracket {
    border-bottom: 2px solid ${lightColors.red500};
    color: ${lightColors.red500} !important;
    background-color: transparent;
  }

  /* addon: fold/foldgutter.js */
  .cm-s-cadmus-light .CodeMirror-foldmarker,
  .cm-s-cadmus-light .CodeMirror-foldgutter,
  .cm-s-cadmus-light .CodeMirror-foldgutter-open,
  .cm-s-cadmus-light .CodeMirror-foldgutter-folded {
    border: none;
    text-shadow: none;
    color: ${lightColors.grey500} !important;
    background-color: transparent;
  }

  /* addon: selection/active-line.js */
  .cm-s-cadmus-light .CodeMirror-activeline-background {
    background-color: ${lightColors.activeLineBackground};
  }
  .cm-s-cadmus-light .CodeMirror-activeline-gutter {
    background-color: ${lightColors.activeLineGutterBackground};
  }

  /* basic syntaxs */
  .cm-s-cadmus-light span.cm-comment {
    color: ${lightColors.grey500};
  }
  .cm-s-cadmus-light span.cm-atom {
    color: ${lightColors.pink500};
  }
  .cm-s-cadmus-light span.cm-number,
  .cm-s-cadmus-light span.cm-attribute,
  .cm-s-cadmus-light span.cm-qualifier {
    color: ${lightColors.pink500};
  }
  .cm-s-cadmus-light span.cm-keyword,
  .cm-s-cadmus-light span.cm-operator {
    color: ${lightColors.pink500};
  }
  .cm-s-cadmus-light span.cm-string,
  .cm-s-cadmus-light span.cm-quote {
    color: ${lightColors.cyan500};
  }
  .cm-s-cadmus-light span.cm-meta {
    color: ${lightColors.pink500};
  }
  .cm-s-cadmus-light span.cm-variable-2,
  .cm-s-cadmus-light span.cm-variable-3,
  .cm-s-cadmus-light span.cm-variable {
    color: ${lightColors.blue800};
  }
  .cm-s-cadmus-light span.cm-tag {
    color: ${lightColors.blue500};
  }
  .cm-s-cadmus-light span.cm-def {
    color: ${lightColors.blue500};
  }
  .cm-s-cadmus-light span.cm-type,
  .cm-s-cadmus-light span.cm-punctuation {
    color: ${lightColors.black200};
  }
  .cm-s-cadmus-light span.cm-bracket {
    color: ${lightColors.grey500};
  }
  .cm-s-cadmus-light span.cm-builtin,
  .cm-s-cadmus-light span.cm-special {
    color: ${lightColors.magenta100};
  }
  .cm-s-cadmus-light span.cm-link {
    color: ${lightColors.blue800};
    border-bottom: solid 1px ${lightColors.blue800};
  }
  .cm-s-cadmus-light span.cm-error {
    color: ${lightColors.red500};
  }

  .cm-s-cadmus-light span.cm-header {
    color: ${lightColors.red500};
  }

  .cm-s-cadmus-light span.cm-em {
    font-style: italic;
  }

  .cm-s-cadmus-light span.cm-strong {
    font-style: bold;
  }

  .cm-s-cadmus-light span.cm-property {
    color: ${lightColors.yellow800};
  }
`;
