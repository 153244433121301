export default (
  <>
    <path
      d="M0.466073 0.669948V0.672626C0.202715 0.723518 -0.00468088 0.983639 8.03613e-05 1.25594V18.7519C8.03613e-05 19.0559 0.274892 19.3333 0.575429 19.3333H15.424C15.7253 19.3333 16 19.056 16 18.7506V5.22679C15.9946 5.13766 15.9693 5.04956 15.9266 4.97159L11.5113 0.705685C11.454 0.68381 11.3927 0.671757 11.3312 0.669227H0.574995C0.539286 0.665804 0.501782 0.666525 0.466073 0.669948Z"
      fill="#037945"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.20696 8.19487V14.7949H12.8033V8.19487H3.20696ZM3.00696 6.99487C2.45467 6.99487 2.00696 7.44259 2.00696 7.99487V14.9949C2.00696 15.5472 2.45467 15.9949 3.00696 15.9949H13.0033C13.5556 15.9949 14.0033 15.5472 14.0033 14.9949V7.99487C14.0033 7.44259 13.5556 6.99487 13.0033 6.99487H3.00696Z"
      fill="white"
    />
    <rect
      x="2.50085"
      y="9.73419"
      width="10.9983"
      height="1"
      rx="0.5"
      fill="white"
    />
    <rect
      x="5.6532"
      y="7"
      width="8.99996"
      height="1"
      rx="0.5"
      transform="rotate(90 5.6532 7)"
      fill="white"
    />
    <rect
      x="2.50085"
      y="12.2659"
      width="10.9983"
      height="1"
      rx="0.5"
      fill="white"
    />
  </>
);
