import { IconProps } from "./types";

export const IntegralIcon = (props: IconProps) => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" {...props}>
      <path
        d="M15 7.33333C15 7.14924 15.1492 7 15.3333 7H22.6667C22.8508 7 23 7.14924 23 7.33333V14.6667C23 14.8508 22.8508 15 22.6667 15H15.3333C15.1492 15 15 14.8508 15 14.6667V7.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8V14H22V8H16ZM15.3333 7C15.1492 7 15 7.14924 15 7.33333V14.6667C15 14.8508 15.1492 15 15.3333 15H22.6667C22.8508 15 23 14.8508 23 14.6667V7.33333C23 7.14924 22.8508 7 22.6667 7H15.3333Z"
        fill="#7070A9"
      />
      <path
        d="M1.85713 18.06C2.00646 17.9107 2.19313 17.836 2.41713 17.836C2.64113 17.836 2.82779 17.9107 2.97713 18.06C3.12646 18.22 3.20113 18.4067 3.20113 18.62C3.20113 18.94 3.07313 19.164 2.81713 19.292C2.80646 19.3027 2.77979 19.3133 2.73713 19.324C2.69446 19.3453 2.65179 19.3613 2.60913 19.372L2.56113 19.404C2.56113 19.4147 2.58779 19.4467 2.64113 19.5C2.70513 19.5533 2.75846 19.5907 2.80113 19.612C2.97179 19.74 3.17979 19.804 3.42513 19.804C3.62779 19.804 3.79846 19.756 3.93713 19.66C4.19313 19.5107 4.38513 19.2333 4.51313 18.828C4.69446 18.316 4.86513 16.956 5.02513 14.748C5.17446 12.7213 5.33446 11.0147 5.50513 9.628C5.78246 7.46267 5.94246 6.268 5.98513 6.044C6.25179 4.508 6.76913 3.48933 7.53713 2.988C7.80379 2.81733 8.09713 2.7 8.41713 2.636C8.43846 2.636 8.48646 2.636 8.56113 2.636C8.64646 2.62533 8.71579 2.62 8.76913 2.62C9.27046 2.652 9.68646 2.828 10.0171 3.148C10.3478 3.468 10.5131 3.87867 10.5131 4.38C10.5131 4.61467 10.4385 4.80667 10.2891 4.956C10.1398 5.10533 9.95313 5.18 9.72913 5.18C9.50513 5.18 9.31846 5.10533 9.16913 4.956C9.01979 4.796 8.94513 4.60933 8.94513 4.396C8.94513 4.076 9.07313 3.852 9.32913 3.724C9.33979 3.71333 9.36646 3.70267 9.40913 3.692C9.45179 3.67067 9.48913 3.64933 9.52113 3.628L9.58513 3.612C9.58513 3.55867 9.50513 3.48933 9.34513 3.404C9.16379 3.276 8.95579 3.212 8.72113 3.212C8.25179 3.212 7.91046 3.48933 7.69713 4.044C7.56913 4.35333 7.46779 4.78 7.39313 5.324C7.32913 5.85733 7.24379 6.83867 7.13713 8.268C6.98779 10.2733 6.82779 11.9747 6.65713 13.372C6.44379 15.2067 6.28379 16.4013 6.17713 16.956C5.86779 18.6627 5.25979 19.74 4.35313 20.188C4.07579 20.3267 3.77713 20.396 3.45713 20.396C2.68913 20.396 2.14513 20.0653 1.82513 19.404C1.69713 19.18 1.63313 18.924 1.63313 18.636C1.63313 18.4013 1.70779 18.2093 1.85713 18.06Z"
        fill="#3A517F"
      />
      <path d="M13 2.5H15V4.5H13V2.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.5H16V5.5H12V1.5ZM13 2.5V4.5H15V2.5H13Z"
        fill="#7070A9"
      />
      <path d="M13 17.5H15V19.5H13V17.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 16.5H16V20.5H12V16.5ZM13 17.5V19.5H15V17.5H13Z"
        fill="#7070A9"
      />
    </svg>
  );
};
