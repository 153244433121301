import { IconProps } from "./types";

export const VectorComma1X3Icon = (props: IconProps) => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.84966 7.53254C1.84966 6.89723 2.36469 6.3822 3 6.3822V5.3822C1.8124 5.3822 0.849661 6.34494 0.849661 7.53254V18.468C0.849661 19.6556 1.8124 20.6183 3 20.6183V19.6183C2.36468 19.6183 1.84966 19.1033 1.84966 18.468V7.53254Z"
        fill="#3A517F"
      />
      <path
        d="M4 11.0502C4 11.0226 4.02239 11.0002 4.05 11.0002H7.95C7.97761 11.0002 8 11.0226 8 11.0502V14.9502C8 14.9779 7.97761 15.0002 7.95 15.0002H4.05C4.02239 15.0002 4 14.9779 4 14.9502V11.0502Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.05 10.0002H7.95C8.5299 10.0002 9 10.4703 9 11.0502V14.9502C9 15.5301 8.5299 16.0002 7.95 16.0002H4.05C3.4701 16.0002 3 15.5301 3 14.9502V11.0502C3 10.4703 3.4701 10.0002 4.05 10.0002ZM4.05 11.0002C4.02239 11.0002 4 11.0226 4 11.0502V14.9502C4 14.9779 4.02239 15.0002 4.05 15.0002H7.95C7.97761 15.0002 8 14.9779 8 14.9502V11.0502C8 11.0226 7.97761 11.0002 7.95 11.0002H4.05Z"
        fill="#7070A9"
      />
      <path
        d="M9.152 16.2962C9.008 16.1522 8.936 15.9802 8.936 15.7802C8.936 15.5802 9 15.4082 9.128 15.2642C9.264 15.1202 9.436 15.0482 9.644 15.0482C9.948 15.0482 10.168 15.1762 10.304 15.4322C10.448 15.6882 10.52 16.0122 10.52 16.4042C10.52 16.5562 10.512 16.6962 10.496 16.8242C10.384 17.5362 10.092 18.1482 9.62 18.6602C9.508 18.7722 9.436 18.8282 9.404 18.8282C9.38 18.8282 9.336 18.7962 9.272 18.7322C9.208 18.6682 9.164 18.6202 9.14 18.5882C9.132 18.5482 9.172 18.4802 9.26 18.3842C9.756 17.8802 10.028 17.2642 10.076 16.5362V16.3922C10.068 16.3922 10.044 16.4042 10.004 16.4282C9.9 16.4762 9.792 16.5002 9.68 16.5002C9.472 16.5002 9.296 16.4322 9.152 16.2962Z"
        fill="#3A517F"
      />
      <path
        d="M12 11.0502C12 11.0226 12.0224 11.0002 12.05 11.0002H15.95C15.9776 11.0002 16 11.0226 16 11.0502V14.9502C16 14.9779 15.9776 15.0002 15.95 15.0002H12.05C12.0224 15.0002 12 14.9779 12 14.9502V11.0502Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 10.0002H15.95C16.5299 10.0002 17 10.4703 17 11.0502V14.9502C17 15.5301 16.5299 16.0002 15.95 16.0002H12.05C11.4701 16.0002 11 15.5301 11 14.9502V11.0502C11 10.4703 11.4701 10.0002 12.05 10.0002ZM12.05 11.0002C12.0224 11.0002 12 11.0226 12 11.0502V14.9502C12 14.9779 12.0224 15.0002 12.05 15.0002H15.95C15.9776 15.0002 16 14.9779 16 14.9502V11.0502C16 11.0226 15.9776 11.0002 15.95 11.0002H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M17.152 16.2962C17.008 16.1522 16.936 15.9802 16.936 15.7802C16.936 15.5802 17 15.4082 17.128 15.2642C17.264 15.1202 17.436 15.0482 17.644 15.0482C17.948 15.0482 18.168 15.1762 18.304 15.4322C18.448 15.6882 18.52 16.0122 18.52 16.4042C18.52 16.5562 18.512 16.6962 18.496 16.8242C18.384 17.5362 18.092 18.1482 17.62 18.6602C17.508 18.7722 17.436 18.8282 17.404 18.8282C17.38 18.8282 17.336 18.7962 17.272 18.7322C17.208 18.6682 17.164 18.6202 17.14 18.5882C17.132 18.5482 17.172 18.4802 17.26 18.3842C17.756 17.8802 18.028 17.2642 18.076 16.5362V16.3922C18.068 16.3922 18.044 16.4042 18.004 16.4282C17.9 16.4762 17.792 16.5002 17.68 16.5002C17.472 16.5002 17.296 16.4322 17.152 16.2962Z"
        fill="#3A517F"
      />
      <path
        d="M20 11.0502C20 11.0226 20.0224 11.0002 20.05 11.0002H23.95C23.9776 11.0002 24 11.0226 24 11.0502V14.9502C24 14.9779 23.9776 15.0002 23.95 15.0002H20.05C20.0224 15.0002 20 14.9779 20 14.9502V11.0502Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.05 10.0002H23.95C24.5299 10.0002 25 10.4703 25 11.0502V14.9502C25 15.5301 24.5299 16.0002 23.95 16.0002H20.05C19.4701 16.0002 19 15.5301 19 14.9502V11.0502C19 10.4703 19.4701 10.0002 20.05 10.0002ZM20.05 11.0002C20.0224 11.0002 20 11.0226 20 11.0502V14.9502C20 14.9779 20.0224 15.0002 20.05 15.0002H23.95C23.9776 15.0002 24 14.9779 24 14.9502V11.0502C24 11.0226 23.9776 11.0002 23.95 11.0002H20.05Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1503 7.53254C26.1503 6.89723 25.6353 6.3822 25 6.3822V5.3822C26.1876 5.3822 27.1503 6.34494 27.1503 7.53254V18.468C27.1503 19.6556 26.1876 20.6183 25 20.6183V19.6183C25.6353 19.6183 26.1503 19.1033 26.1503 18.468V7.53254Z"
        fill="#3A517F"
      />
    </svg>
  );
};
