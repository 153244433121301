export default (
  <>
    <path
      d="M0.466073 0.669765V0.672443C0.202715 0.723335 -0.00468088 0.983456 8.03613e-05 1.25576V18.7517C8.03613e-05 19.0558 0.274892 19.3331 0.575429 19.3331H15.424C15.7253 19.3331 16 19.0558 16 18.7504V5.22661C15.9946 5.13747 15.9693 5.04938 15.9266 4.9714L11.5113 0.705502C11.454 0.683627 11.3927 0.671574 11.3312 0.669044H0.574995C0.539286 0.665621 0.501782 0.666342 0.466073 0.669765Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.466073 0.669765C0.501782 0.666342 0.539286 0.665621 0.574995 0.669044H11.3312C11.3927 0.671574 11.454 0.683627 11.5113 0.705502L15.9266 4.9714C15.9693 5.04938 15.9946 5.13747 16 5.22661V18.7504C16 19.0558 15.7253 19.3331 15.424 19.3331H0.575429C0.274892 19.3331 8.03613e-05 19.0558 8.03613e-05 18.7517V1.25576C-0.00468088 0.983456 0.202715 0.723335 0.466073 0.672443V0.669765ZM15 5.46661V18.3331H1.00008V1.66904H11.0694L15 5.46661Z"
      fill="#5B6F98"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.68607 0.666883V0.666748H6.31396V0.666841L8.05205 0.666883V1.45544H7.26349V2.24399H8.05205V3.03254H7.26349V3.82109H8.05205V4.60965H7.26349V5.3982H8.05205V6.18675H7.26349V6.9753H8.05205V7.76386H7.26349V8.55241H8.05205V9.34096C7.18101 9.34096 6.47521 10.0506 6.47521 10.9178V14.0717C6.47521 14.939 7.18101 15.6486 8.05205 15.6486C8.92308 15.6486 9.62888 14.939 9.62888 14.0717V10.9178C9.62888 10.0506 8.92335 9.34096 8.05205 9.34096C8.48724 9.34096 8.8406 8.94655 8.8406 8.55241H8.05205V7.76386H8.8406V6.9753H8.05205V6.18675H8.8406V5.3982H8.05205V4.60965H8.8406V3.82109H8.05205V3.03254H8.8406V2.24399H8.05205V1.45544H8.8406V0.666883H9.68607ZM7.26403 10.9169C7.26403 10.4438 7.61739 10.1283 8.05259 10.1283C8.48778 10.1283 8.84114 10.4438 8.84114 10.9169C8.84114 11.3113 8.48778 11.7054 8.05259 11.7054C7.61739 11.7054 7.26403 11.311 7.26403 10.9169ZM7.26403 13.2822C7.26403 12.8092 7.61739 12.4937 8.05259 12.4937C8.48778 12.4937 8.84114 12.8092 8.84114 13.2822V14.0708C8.84114 14.4652 8.48778 14.8593 8.05259 14.8593C7.61739 14.8593 7.26403 14.4649 7.26403 14.0708V13.2822Z"
      fill="#5B6F98"
    />
  </>
);
