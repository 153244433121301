import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { debounce } from "ts-debounce";

import { tokeniseUniqueWords } from "../../core/text";
import { TelemetryOptions } from "./types";
import { canSpellCheckWithCadmus } from "../suggestion";

/**
 * Iterate through editor document, count words and also trigget a suggestion
 * request on unique words on the document
 */
export function processDocWords(
  editor: Editor,
  doc: PMNode,
  opts: TelemetryOptions
) {
  const [words, wordCount] = tokeniseUniqueWords(doc, 0, doc.content.size);

  // Set the Word Count
  editor.commands.setWordCount(wordCount);

  // Fire a save
  opts.onSave?.(opts.editorId);
  editor.storage.telemetry.pendingSave = false;

  // Send the words for server spelling suggestions if possible
  if (opts.suggestionClient && canSpellCheckWithCadmus(editor)) {
    opts.suggestionClient.requestSuggestions(words);
  }
}

export const debouncedProcessDocWords = debounce(processDocWords, 800);
