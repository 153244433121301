import { Icon } from "@vericus/cadmus-icons";
import { IconButton, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { tableColors } from "../constants";
import { ButtonGroups, GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

export interface FormatRowColorProps extends TableMenuItemComponentProps {
  isCell?: boolean;
  activeColor: string | null | undefined;
}

/**
 * Shared Table menu item for cell/table color
 */
export function FormatRowColor(props: FormatRowColorProps) {
  const { editor, isCell, activeColor } = props;
  const ColorButtons = tableColors.map(({ button, actual }, index) => (
    <ColorButton
      key={index}
      color={button}
      selected={activeColor === actual}
      onMouseDown={(e) => {
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        return isCell
          ? editor.chain().setCellsBackground(actual).focus().run()
          : editor.chain().setTableColor(actual).focus().run();
      }}
    />
  ));

  return (
    <FormatMenuItem>
      <ColorButtonGroups>{ColorButtons}</ColorButtonGroups>
      <FormatMenuButton>
        <GroupDivider />
        <UnsetButton
          aria-label="Remove alternate table background"
          onMouseDown={(e) => {
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            isCell
              ? editor.chain().setCellsBackground().focus().run()
              : editor.chain().toggleTableAlternateColor().focus().run();
          }}
        >
          <Icon iconName="Unset" />
        </UnsetButton>
      </FormatMenuButton>
    </FormatMenuItem>
  );
}

const UnsetButton = styled(IconButton)`
  width: 28px;
  height: 28px;
`;

const FormatMenuButton = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
`;

const FormatMenuItem = styled.div.attrs({
  role: "menuitem",
  tabindex: "0",
})`
  display: flex;
  height: 32px;
  flex-grow: 1;
  align-items: center;
  gap: 4px;
  margin: 0 8px;
  align-items: center;
  fill: ${colors.grey500};
  color: ${colors.grey500};
`;

const ColorButtonGroups = styled(ButtonGroups)`
  box-shadow: none;
  padding: 0px 4px;
  gap: 8px;
`;

// Readonly circular color button to select a color
const ColorButton = styled.button<{ color: string; selected: boolean }>`
  display: inline-flex;
  width: 16px;
  height: 16px;
  padding: 0px;

  border-radius: 50%;
  border: 4px solid transparent;
  background: ${(p) => p.color};
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: -7px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid
      ${(p) => (p.selected ? p.theme.text.accent : "transparent")};
  }

  &:disabled {
    opacity: 0.36;
  }

  &:hover {
    cursor: pointer;
  }
`;
