import styled from "styled-components";

import { Color, colors } from "./styles";

/**
 * The Desk layout:
 *
 * Desk is a flexible, full page layout module that can be used to create split
 * and single column layouts. There are 3 main regions of the UI, plus 2
 * additional sub-regions when creating a split layout.
 *
 *  - SHELF: A region reserved at the top of the screen for toolbars and
 *    navbars.
 *  - TABLE: A single flexible column for the main content. Children will be
 *    arranged using a standard block model.
 *  - SPLITTABLE: A multi-column split layout. Use with LEFT and RIGHT to create
 *    adjacent panels.
 *       - LEFT: A fixed width panel with preset size options.
 *       - RIGHT: A flexible panel that takes up remaining space not used by
 *         Left.
 *  - DRAWER: A region reserved at the bottom of the screen for page actions and
 *    notifications.
 *
 *    +----------------------------------+
 *    | Layout                           |
 *    |  +----------------------------+  |
 *    |  | Shelf                      |  |
 *    |  +----------------------------+  |
 *    |  +----------------------------+  |
 *    |  | Table                      |  |
 *    |  | +------------------------+ |  |
 *    |  | | Left   || Right        | |  |
 *    |  | |        ||              | |  |
 *    |  | |        ||              | |  |
 *    |  | |        ||              | |  |
 *    |  | +------------------------+ |  |
 *    |  +----------------------------+  |
 *    |  +----------------------------+  |
 *    |  | Drawer                     |  |
 *    |  +----------------------------+  |
 *    +----------------------------------+
 */

// A full width and full height flexed parent container.
// This provides the underlying structure of the entire layout.
// Children are positioned in a COLUMN
const Layout = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

// A fixed height area at the top of the screen, with a solid border underline
const Shelf = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  flex: none;
  border-bottom: 1px solid ${(p) => p.theme.divider};
  box-sizing: border-box;
  z-index: 2;
`;

// Main content area for a single column layout
const Table = styled.div`
  width: 100%;
  // height for firefox which should not cause issue on safari
  min-height: 0;
  height: 100%;
  flex: auto;

  overflow-y: scroll;
  display: block;
  scroll-behavior: smooth;

  position: relative;
  z-index: 1;
`;

// Main content area for a split layout
const SplitTable = styled.div`
  width: 100%;
  // height for firefox which should not cause issue on safari
  min-height: 0;
  height: 100%;
  flex: auto;

  overflow-y: hidden;
  display: flex;
  scroll-behavior: smooth;

  position: relative;
  z-index: 1;
`;

// Props for the split layout configuration
interface PanelProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Add a background color to a panel */
  background?: Color;
  /** Option to add panel controls along the right edge of a panel */
  track?: React.ReactNode;
}

/** Preset panel sizes for Left */
type LeftWidth = "closed" | "golden" | "half" | "inverseGolden" | "full";

interface LeftProps extends PanelProps {
  width?: LeftWidth;
}

// Helper function for controlling the panel width
const panelWidth = (width?: LeftWidth): number => {
  switch (width) {
    case "closed":
      return 2;
    case "golden":
      return 38;
    case "half":
      return 50;
    case "inverseGolden":
      return 62;
    case "full":
      return 100;
    default:
      return 38;
  }
};

// A FIXED width panel for the layout
const Left: React.FC<LeftProps> = (props) => {
  const { width, background, track, children, ...divProps } = props;
  return (
    <LeftContainer width={width} background={background} {...divProps}>
      <PanelContent>{children}</PanelContent>
      {track && <Track>{track}</Track>}
    </LeftContainer>
  );
};

// A FLEXIBLE panel that takes the remaining space not taken by `Left`
const Right: React.FC<PanelProps> = (props) => {
  const { background, track, children, ...divProps } = props;
  return (
    <RightContainer background={background} {...divProps}>
      <PanelContent>{children}</PanelContent>
      {track && <Track>{track}</Track>}
    </RightContainer>
  );
};

const LeftContainer = styled.div<LeftProps>`
  width: ${(p) => `${panelWidth(p.width)}%`};
  height: 100%;
  flex: none;

  border-right: ${(p) =>
    p.width === "full" ? undefined : `1px solid ${p.theme.divider}`};
  box-sizing: border-box;
  position: relative;
  transition: width 0.6s ease;
  overflow: hidden;

  background: ${(p) => (p.background ? colors[p.background] : "initial")};
`;

const RightContainer = styled.div<{ background?: Color }>`
  height: 100%;
  flex: auto;

  overflow: hidden;
  scroll-behavior: smooth;
  position: relative;

  background: ${(p) => (p.background ? colors[p.background] : "initial")};
`;

const PanelContent = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: 0;
  box-sizing: border-box;
`;

// An optional track positioned along the right edge of a panel. By default, the
// track will position all direct children along the bottom right edge of the
// panel. To custom position a child, you must extend the component and use
// absolute positioing

const Track = styled.div`
  /* Force zero width, use translation to position the track. This preseves
  pointer events over the scrollbar and track area */
  width: 0px;
  transform: translateX(-54px);
  position: absolute;
  right: 0;
  top: 0;

  height: 100%;
  padding: 18px 0;
  box-sizing: border-box;

  display: grid;
  align-content: end; /* Grid tracks align to end */
  grid-template-columns: 36px;
  grid-auto-rows: auto;
  grid-row-gap: 9px;

  z-index: 2;
`;

// A fixed height area at the bottom of the screen, with a solid top border
const Drawer = styled.div`
  width: 100%;
  height: 63px;
  flex: none;

  border-top: 1px solid ${(p) => p.theme.divider};
  box-sizing: border-box;

  position: relative;
  z-index: 3;
`;

// A full screen overlay - avoiding the need to navigate away a page
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background: ${(p) => p.theme.background.default};
  z-index: 4;
`;

export const Desk = {
  Layout,
  Shelf,
  Table,
  SplitTable,
  Left,
  Right,
  Track,
  Drawer,
  Modal,
};
