import { Fragment, NodeType, Node as PMNode } from "@tiptap/pm/model";

/**
 * Create Cell with a type(header or normal cell) with attrs & content
 */
export function createCell(
  cellType: NodeType,
  cellAttrs?: Record<string, any>,
  cellContent?: Fragment | PMNode | Array<PMNode>
): PMNode | null | undefined {
  if (cellContent) {
    return cellType.createChecked(cellAttrs, cellContent);
  }

  return cellType.createAndFill(cellAttrs);
}
