import { Caption } from "../../../caption";
import { findTableGroup } from "../../utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableCaption: {
      /**
       * Toggle Table's caption node on/off
       */
      toggleTableCaption: () => ReturnType;
      /**
       * Hide Table's caption node
       */
      hideTableCaption: () => ReturnType;
    };
  }
}

/**
 * Table caption Node extension which extend Caption Node extension
 * Restricted to table_block group instead of block so that it can only appear inside tableGroup
 */
export const TableCaption = Caption.extend({
  name: "tableCaption",
  tableRole: "table_caption",
  group: "table_block",
  isolating: true,
  addOptions() {
    return {
      ...this?.parent?.(),
      placeholderText: "Add table title...",
    };
  },
  addCommands() {
    return {
      toggleTableCaption:
        () =>
        ({ commands, state }) => {
          const tableGroup = findTableGroup(state.selection);
          if (tableGroup) {
            return commands.toggleShowText(tableGroup.pos, this.type.name);
          }
          return false;
        },
      hideTableCaption:
        () =>
        ({ commands, state }) => {
          const tableGroup = findTableGroup(state.selection);
          if (tableGroup) {
            return commands.hideText(tableGroup.pos, this.type.name);
          }
          return false;
        },
    };
  },
  parseHTML() {
    return [
      {
        tag: "figcaption",
        context: "tableGroup/",
        getAttrs(node) {
          if (typeof node === "string") return {};
          const element = node as HTMLElement;
          return {
            hideText:
              element.style.display === "none" ||
              element.getAttribute("data-cadmus-hidden") === "true",
          };
        },
      },
    ];
  },
});
