import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import {
  AssessmentType,
  useExperienceQuery,
  useUpdateExperienceMutation,
} from "@/generated/graphql";

import { ExamRating } from "./ExamRating";
import { ExamSurvey } from "./ExamSurvey";
import { Rating } from "./Rating";
import { Survey } from "./Survey";

export function StudentExperience() {
  const { workId, tenant, assessmentId } = useParams();
  const [showSurveyLink, setShowSurveyLink] = useState(false);

  const { data } = useExperienceQuery({
    variables: { workId: workId!, assessmentId: assessmentId! },
  });
  const experience = data?.work?.experience;
  const subjectId = data?.assessment?.subjectId;
  const isExam = data?.work?.sheet?.assessmentType === AssessmentType.Exam;

  // Mutation for the latest experience survey results
  const [updateScoreMutation] = useUpdateExperienceMutation({
    optimisticResponse: ({ workId, score }) => ({
      updateExperience: {
        id: workId,
        score,
        active: false,
        __typename: "Survey",
      },
    }),
  });

  const onUpdateScore = useCallback(
    (score: number) => {
      updateScoreMutation({
        variables: { workId: workId!, score },
      });
      setShowSurveyLink(true);
    },
    [workId, updateScoreMutation, setShowSurveyLink]
  );

  if (experience?.active) {
    return isExam ? (
      <ExamRating onUpdateScore={onUpdateScore} />
    ) : (
      <Rating onUpdateScore={onUpdateScore} />
    );
  }

  if (showSurveyLink) {
    if (isExam)
      return (
        <ExamSurvey
          href={`https://cadmus.io/survey/student/exam-experience?wid=${workId}&institution=${tenant}&sid=${subjectId}`}
          onDismiss={() => setShowSurveyLink(false)}
        />
      );
    return (
      <Survey
        href={`https://cadmus.io/survey/student/experience?wid=${workId}&institution=${tenant}&sid=${subjectId}`}
        onDismiss={() => setShowSurveyLink(false)}
      />
    );
  }

  return null;
}
