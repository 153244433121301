import {
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, LinkButton, Switch, colors } from "@vericus/cadmus-ui";
import { Selector } from "mathlive";
import styled from "styled-components";

import { MathView, MathViewRef } from "../MathView";
import { MathKeyboard } from "./MathKeyboard";
import { MathStyle } from "./MathStyle";

interface MathEditorProps {
  defaultValue?: string;
  onUpdateEquation: (equation: string) => void;
  onClose: () => void;
  onPreCancel: (show: boolean) => void;
}

type FocusElement = "textarea" | "mathlive";

/**
 * Component to edit and format a math equation through various input methods.
 *
 * The equation is rendered and edited using the MathLive web component
 * `math-live`. The equation state is managed by that web component. When the
 * editing actions are done and "confirmed", the `props.onUpdateEquation`
 * callback will be called with the latest value.
 *
 * The equation can also be editing directly using a LaTeX text input.
 */
export function MathEditor({
  defaultValue = "",
  onUpdateEquation,
  onClose,
  onPreCancel,
}: MathEditorProps) {
  const [showLatex, setShowLatex] = useState(false);
  const [currentFocus, setCurrentFocus] = useState<FocusElement>();
  const mathViewRef = useRef<MathViewRef>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onLatexFocus = useCallback(() => {
    setCurrentFocus("textarea");
  }, [setCurrentFocus]);
  const onMathViewFocus = useCallback(() => {
    setCurrentFocus("mathlive");
  }, [setCurrentFocus]);

  const executeCommand = useCallback(
    ({
      selector,
      latexCommand,
      mathLiveCommand,
    }: {
      selector: Selector;
      latexCommand?: unknown | undefined;
      mathLiveCommand: unknown | undefined;
    }) => {
      if (currentFocus === "mathlive") {
        mathViewRef.current?.executeCommand([
          selector,
          mathLiveCommand,
          { focus: true },
        ]);
      } else if (
        mathViewRef?.current &&
        textAreaRef?.current &&
        mathLiveCommand &&
        currentFocus === "textarea"
      ) {
        const command = latexCommand ?? mathLiveCommand;
        if (selector === "insert" && typeof command === "string") {
          const cursorStartPosition = textAreaRef.current.selectionStart;
          const cursorEndPosition = textAreaRef.current.selectionEnd;
          const textBeforeCursor = textAreaRef.current.value.substring(
            0,
            cursorStartPosition
          );
          const textAfterCursor = textAreaRef.current.value.substring(
            cursorEndPosition,
            textAreaRef.current.value.length
          );
          const newEquation = textBeforeCursor + command + textAfterCursor;
          textAreaRef.current.value = newEquation;
          textAreaRef.current.setSelectionRange(
            cursorStartPosition + command.length,
            cursorStartPosition + command.length
          );
          mathViewRef.current.setValue(newEquation, {
            suppressChangeNotifications: true,
          });
        }
      }
    },
    [currentFocus]
  );

  const onMathInput = useCallback(() => {
    if (mathViewRef.current) {
      const newEquation = mathViewRef.current.value;
      // Update latex textarea text on mathLive update
      if (textAreaRef.current) {
        textAreaRef.current.value = newEquation;
      }
    }
  }, []);

  const onTextAreaInput = useCallback<FormEventHandler<HTMLTextAreaElement>>(
    (e) => {
      const target = e.target as HTMLTextAreaElement;
      if (target.value && textAreaRef.current && mathViewRef.current) {
        const newEquation = target.value;
        // Update latex textarea text on mathLive update
        mathViewRef.current.setValue(newEquation, {
          suppressChangeNotifications: true,
        });
      }
    },
    []
  );
  // Update the value of mathView and latex text area on defaultValue update
  useEffect(() => {
    mathViewRef.current?.setValue(defaultValue);
    if (textAreaRef.current) {
      textAreaRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  // Populate initial text of the latex text area when thw show latex is turned on
  useEffect(() => {
    if (showLatex) {
      requestAnimationFrame(() => {
        if (textAreaRef.current && mathViewRef.current) {
          textAreaRef.current.value = mathViewRef.current.value;
          if (textAreaRef.current !== document.activeElement) {
            textAreaRef.current.focus();
          }
        }
      });
    } else {
      if (!mathViewRef.current?.hasFocus()) {
        mathViewRef.current?.focus();
      }
    }
  }, [showLatex]);

  useEffect(() => {
    if (
      currentFocus === "mathlive" &&
      !mathViewRef.current?.hasFocus() &&
      mathViewRef.current !== document.activeElement
    ) {
      mathViewRef.current?.focus();
    }
    if (
      currentFocus === "textarea" &&
      textAreaRef.current &&
      textAreaRef.current !== document.activeElement
    ) {
      textAreaRef.current.focus();
    }
  }, [currentFocus]);

  return (
    <EditorRoot>
      <MathStyle
        executeCommand={executeCommand}
        disabled={currentFocus !== "mathlive"}
      />
      <MathKeyboard executeCommand={executeCommand} />
      <StyledMathView
        defaultValue={defaultValue}
        onInput={onMathInput}
        ref={mathViewRef}
        plonkSound={undefined}
        keypressSound={undefined}
        onFocus={onMathViewFocus}
        virtualKeyboardMode="off"
        onVirtualKeyboardToggle={() => {
          mathViewRef.current?.executeCommand("hideVirtualKeyboard");
        }}
      />
      <Switch
        content="Show LaTeX"
        checked={showLatex}
        onChange={() => setShowLatex(!showLatex)}
      />
      {showLatex && (
        <LatexTextArea
          ref={textAreaRef}
          autoCapitalize="off"
          autoComplete="off"
          spellCheck="false"
          rows={2}
          autoCorrect="off"
          defaultValue={defaultValue}
          onInput={onTextAreaInput}
          onFocus={onLatexFocus}
          data-gramm="false"
          data-gramm_editor="false"
          data-enable-grammarly="false"
        />
      )}
      <Footer>
        <LinkButton
          onClick={() =>
            window.open(
              "https://support.cadmus.io/students/advanced-tips-equations",
              "_blank"
            )
          }
        >
          Advanced Tips
        </LinkButton>
        <ButtonWrapper>
          <Button
            onMouseEnter={() => onPreCancel(true)}
            onMouseLeave={() => onPreCancel(false)}
            onClick={onClose}
            kind="outline"
            flat
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              mathViewRef.current &&
                onUpdateEquation(mathViewRef.current.value);
            }}
            kind="solid"
            flat
          >
            {defaultValue === "" ? "Insert" : "Done"}
          </Button>
        </ButtonWrapper>
      </Footer>
    </EditorRoot>
  );
}

const LatexTextArea = styled.textarea`
  resize: vertical;
  font-family: ibm-plex-mono, ui-monospace, monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  border: 1px solid ${colors.grey300};
  border-radius: 3px;
  padding: 8px 16px;
  box-sizing: border-box;
  background: rgba(244, 245, 248, 0.5);
  &:focus {
    outline: 1.3px solid ${(p) => p.theme.primaryColor};
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const StyledMathView = styled(MathView)`
  width: 100%;
  border: 1px solid ${colors.grey300};
  border-radius: 3px;
  padding: 16px;
  box-sizing: border-box;
  background: rgba(244, 245, 248, 0.5);
  &:focus {
    outline: 1.3px solid ${(p) => p.theme.primaryColor};
  }
  /* see https://stackoverflow.com/a/68538644 */
  & ~ grammarly-extension {
    display: none;
  }
`;

const EditorRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 16px;
  gap: 12px;
  background: white;
  border-radius: 3px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid ${colors.grey300};
  width: 750px;
  box-shadow: 1px 2px 0px ${colors.grey50}, 0px 0px 4px ${colors.grey50},
    0px 5px 18px ${colors.grey50};
`;
