import { KeyboardEventHandler } from "react";
import styled from "styled-components";

import { Input, InputProps } from "../Input";
import { Color, colors } from "../styles";

export type FileType =
  | "archive"
  | "audio"
  | "document"
  | "image"
  | "media"
  | "pdf"
  | "presentation"
  | "spreadsheet"
  | "text"
  | "unknown"
  | "webpage";
export type Resource = FileType | "link";
export type FileIconName =
  | "ArchiveFile"
  | "AudioFile"
  | "DocumentFile"
  | "ImageFile"
  | "MediaFile"
  | "PdfFile"
  | "PresentationFile"
  | "SpreadsheetFile"
  | "TextFile"
  | "UnknownFile"
  | "WebpageFile";
export type ResourceIconName = FileIconName | "Link";

/**
 * Metadata associated with a file.
 */
export type FileInfo = {
  /**
   * The type of file from our classification of files (based on their extensions).
   */
  type: FileType;
  /**
   * The file's name
   */
  name: string;
  /**
   * Extensions are not compulsory in files.
   */
  extension?: string;
  /**
   * The formatted file size, e.g. 2.7MB, 3.56kb
   */
  size?: string;
};

export interface ResourceCardBaseProps {
  isEditingName?: boolean;
  nameBeingEdited?: string;
  onUpdateNameBeingEdited?: (newName: string) => void;
  onDoneEditing?: VoidFunction;
  viewOnly?: boolean;
  onCancelEditing?: VoidFunction;
  onDelete?: VoidFunction;
  onOpenInBrowser?: VoidFunction;
  onStartEditing?: VoidFunction;
}

export const resourceTypeToCardBackgroundColorMap: Record<Resource, Color> = {
  archive: "white200",
  audio: "indigo100",
  document: "blue100",
  image: "indigo100",
  link: "white200",
  media: "indigo100",
  pdf: "orange100",
  presentation: "orange100",
  spreadsheet: "green100",
  text: "blue100",
  unknown: "white200",
  webpage: "blue100",
};

export interface ResourceNameInputProps extends InputProps {
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
}

export const ResourceNameInput = ({
  onConfirm,
  onCancel,
  ...props
}: ResourceNameInputProps) => {
  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    switch (e.key) {
      case "Escape":
        onCancel?.();
        break;
      case "Enter":
        onConfirm?.();
        break;
    }
  };
  return <StyledInput {...props} onKeyDown={handleKeyPress} />;
};

const StyledInput = styled(Input)`
  flex-grow: 1;
  font-size: 14px;

  input {
    min-width: 20px;
    min-height: unset;
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid ${colors.grey200};
    border-radius: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
