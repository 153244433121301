import React, { forwardRef } from "react";
import styled from "styled-components";

import { Text } from "../Text";

interface MenuLabelProps extends React.HTMLAttributes<HTMLDivElement> {
  /** Subtitle content */
  subtitle?: string;
  /** Label content */
  children?: React.ReactNode;
}

/** Header child item in a parent Menu. */
export const MenuLabel = forwardRef<HTMLDivElement, MenuLabelProps>(
  ({ subtitle, children, ...divProps }, ref) => (
    <LabelRoot ref={ref} {...divProps} aria-disabled="true">
      {typeof children === "string" ? (
        <>
          <Text kind="headingSix" inline>
            {children}
          </Text>
          {subtitle && (
            <Text kind="system" inline>
              {subtitle}
            </Text>
          )}
        </>
      ) : (
        children
      )}
    </LabelRoot>
  )
);

const LabelRoot = styled.div`
  padding: 8px 16px;
  box-sizing: border-box;
`;
