import { maxWidth } from "../../table/constants";

/**  General fallback handler for HTML Paste */
export class ClipboardCleaner {
  private outHTML?: string;
  protected doc: Document;
  protected dom?: HTMLBodyElement;

  constructor(doc: Document) {
    this.doc = doc;
  }

  public getOutput() {
    this.dom = this.doc.getElementsByTagName("body")[0];
    this.iterateNode(this.dom);
    this._patchDocument();
    this.cleanDOM();
    this.outHTML = this.dom.innerHTML;
    return this.outHTML;
  }

  // generic patch document logic
  // to lift/convert element so that it match the expected
  // element to be parsed using ParseDOM
  private _patchDocument() {
    this.patchDocument();
  }

  // extra patching that can be implemented based on the cleaner type
  protected patchDocument() {
    // Convert inline images to block images
    const images = this.dom?.querySelectorAll("img");
    images?.forEach((img) => {
      const parent = img.parentElement;
      if (parent && parent.tagName.toLowerCase() !== "figure") {
        const figure = document.createElement("figure");
        figure.appendChild(img);
        if (parent.nextSibling && parent.parentNode) {
          parent.parentNode.insertBefore(figure, parent.nextSibling);
        } else {
          this.dom?.appendChild(figure);
        }
      }
    });
    // Update cell width of tables
    const tables = this.dom?.querySelectorAll("table");
    tables?.forEach((table) => {
      const trs = table.querySelectorAll("tr");
      trs?.forEach((tr) => {
        const cols = tr.querySelectorAll("td,th");
        if (cols) {
          const colNum =
            Array.from(cols).reduce((acc, col) => {
              const colSpan = col.getAttribute("colSpan") ?? "1";
              return acc + parseInt(colSpan, 10);
            }, 0) ?? 1;
          cols.forEach((col) => {
            const colSpan = col.getAttribute("colSpan") ?? "1";
            const colWidth = (maxWidth / colNum) * parseInt(colSpan, 10);
            col.setAttribute("colwidth", `${colWidth}`);
          });
        }
      });
    });
  }

  protected iterateNode(node: Node) {
    if ((node as HTMLElement).tagName === "P" && !node.firstChild) {
      node.parentNode?.removeChild(node);
      return;
    } else if (node.nodeType === Node.COMMENT_NODE) {
      node.parentNode?.removeChild(node);
      return;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      let childNode = node.firstChild;
      while (childNode) {
        const nextChildNode = childNode.nextSibling;
        this.iterateNode(childNode);
        childNode = nextChildNode;
      }
      node = this.convertNode(node);
    }
  }

  // Remove unused content
  protected cleanDOM() {}

  // Convert an existing node to a different node, if needed.
  protected convertNode(node: Node) {
    return node;
  }
}
