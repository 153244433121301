import { useCallback } from "react";
import { Editor } from "@tiptap/core";
import { NodeSelection } from "@tiptap/pm/state";

interface Props {
  /** TipTap editor instance. */
  editor: Editor;
  /** Node position getter function. */
  getPos: () => number;
}

/**
 * Create a callback to trigger the Math editor menu on the current node if its
 * a Math node.
 */
export function useMathMenuOpenCallback(props: Props) {
  const { editor, getPos } = props;

  return useCallback(() => {
    // console.log("Test");
    if (typeof getPos === "function") {
      const nodeSelection = new NodeSelection(
        editor.state.doc.resolve(getPos())
      );
      editor
        .chain()
        .focus(undefined, { scrollIntoView: false })
        .dismissMenu()
        .run();
      setTimeout(() => {
        editor
          .chain()
          .command(({ tr, dispatch }) => {
            dispatch?.(tr.setSelection(nodeSelection));
            return true;
          })
          .setMeta("focus", true)
          .focus()
          .setEquationMenuTarget({
            pos: getPos(),
            node: nodeSelection.node,
          })
          .run();
      }, 0);
    }
  }, [editor, getPos]);
}
