import { createSelector } from "@reduxjs/toolkit";
import { type RootState } from "@/data/store";
import { AssessmentType, ExamTiming } from "@/generated/graphql";

export const selectAssignment = (state: RootState) => state.assignment;

//////////////////////////////////////////////////////////////////////////////
// Exams                                                                    //
//////////////////////////////////////////////////////////////////////////////

/** Select if the current assessment is a exam. */
export const selectIsExam = createSelector(
  selectAssignment,
  (assn) => assn.type === AssessmentType.Exam
);

/** Select if the current assessment is an assignment. */
export const selectIsAssignment = createSelector(
  selectAssignment,
  (assn) => assn.type === AssessmentType.Assignment
);

/** Select configured exam type. */
export const selectExamTiming = createSelector(
  selectAssignment,
  (assn) => assn.examTiming
);

/** Select if current assginment is a Windowed Exam */
export const selectIsWindowExam = createSelector(
  selectIsExam,
  selectExamTiming,
  (isExam, timing) => isExam && timing === ExamTiming.Window
);

/** Select if current assginment is a Live Exam */
export const selectIsLiveExam = createSelector(
  selectIsExam,
  selectExamTiming,
  (isExam, timing) => isExam && timing === ExamTiming.Live
);

/** Select total number of minutes that the exam can go on for. */
export const selectExamTotalTime = createSelector(
  selectAssignment,
  (assn) => (assn.writingTime ?? 0) + (assn.readingTime ?? 0)
);

/** Select if the assignment has auto-submissions enabled. */
export const selectHasAutoSubmission = createSelector(
  selectAssignment,
  (assn) => assn.autoSubmission
);

/** Select sheet's referencing style */
export const selectReferencingStyle = createSelector(
  selectAssignment,
  (assn) => assn.referencingStyle
);

/** Select if the exam is closed for further submissions. */
export const selectExamIsOver = createSelector(
  selectAssignment,
  (assn) => assn.examIsOver
);

//////////////////////////////////////////////////////////////////////////////
// Assignments                                                              //
//////////////////////////////////////////////////////////////////////////////

/** Select if the current assignment is time limited. */
export const selectIsTimedAssignment = createSelector(
  selectAssignment,
  (assn) => assn.type === AssessmentType.Assignment && assn.timeLimit !== null
);

/** Select is late resubmissions are allowed in the current assignment. */
export const selectAllowLateResubmission = (state: RootState) =>
  !selectIsExam(state) && selectAssignment(state).allowLateResubmission;

/** Select if the assignment time limit is an extension. */
export const selectTimeLimitExtended = createSelector(
  selectAssignment,
  (assn) => assn.timeLimitExtended
);

export const selectLateSubmissionTimeLimit = createSelector(
  selectAssignment,
  (assn) => assn.lateSubmissionTimeLimit
);
