import styled from "styled-components";

/**
 * A utility component to create a horizontal rule.
 *
 * **1px** high block component with no padding or margin.
 */
export const Divider = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${p => p.theme.divider};
`;
