import { Editor } from "@tiptap/core";
import { exitCode } from "@tiptap/pm/commands";
import { redo, undo } from "@tiptap/pm/history";
import { Node as PMNode, Schema } from "@tiptap/pm/model";
import { Selection, TextSelection } from "@tiptap/pm/state";
import {
  EditorView,
  EditorView as PMEditorView,
  NodeView as PMNodeView,
  __parseFromClipboard,
} from "@tiptap/pm/view";
import CodeMirror from "codemirror";
import crelt from "crelt";

// Addons and Language Imports
import "./codemirror-imports";

import { copyEventHandler } from "../paste";
import { INDENT_SIZE } from "./constants";
import { getLanguageDisplay, getLanguageMode } from "./utils";

type GetPos = () => number;

class CodeBlockView implements PMNodeView {
  node: PMNode;
  editor: Editor;
  view: PMEditorView;
  schema: Schema;
  cm: CodeMirror.Editor;
  dom: HTMLElement;
  getPos: GetPos;
  // This flag (guard) is used to avoid an update loop between the `ProseMirror` and
  // `CodeMirror`. The update loop occurs when forwarding text change or
  // selection from one editor to the other. This is handled by setting the
  // update flag (guard) before forwarding changes and unsetting it once the changes are
  // done. Theses changes would ordinarily trigger other callbacks that listen
  // for changes, but becuase the guard is set, the callbacks don't perform any modification
  updating: boolean;
  incomingChanges: boolean;
  languageDisplay: HTMLElement;

  onCopy: (view: EditorView, event: Event) => boolean;

  constructor(node: PMNode, editor: Editor, getPos: GetPos) {
    this.node = node;
    this.editor = editor;
    this.view = editor.view;
    this.schema = node.type.schema;
    this.getPos = getPos;
    this.updating = false;
    this.incomingChanges = false;

    // Create a CodeMirror Instance
    this.cm = CodeMirror(null as unknown as HTMLElement, {
      value: node.textContent,
      lineNumbers: node.attrs.lineNumbersVisible,
      extraKeys: this.codeMirrorKeymap(),
      mode: getLanguageMode(node.attrs.language),
      theme: node.attrs.theme,
      tabSize: INDENT_SIZE,
      indentUnit: INDENT_SIZE,
      showCursorWhenSelecting: true,
      styleSelectedText: true,
      autoCloseBrackets: true,
      styleActiveLine: false,
      lineWrapping: true,
      viewportMargin: Infinity,
    });

    // CodeMirror's outer node should be the DOM representation
    this.dom = this.cm.getWrapperElement();

    // Display the currently selected language
    this.languageDisplay = crelt("div", {
      class: "language-display",
      style: `visibility: ${node.attrs.languageVisible ? "visible" : "hidden"}`,
    });
    crelt(this.dom, [this.languageDisplay]);
    this.languageDisplay.innerHTML = getLanguageDisplay(node.attrs.language);
    if (node.attrs.languageVisible) {
      this.dom.classList.remove("language-display-off");
    } else {
      this.dom.classList.add("language-display-off");
    }
    if (this.editor.isEditable) {
      this.dom.classList.remove("CodeMirror-Readonly");
    } else {
      this.dom.classList.add("CodeMirror-Readonly");
    }
    // CodeMirror needs to be in the DOM to properly initialize, so
    // schedule it to update itself.
    setTimeout(() => this.cm.refresh(), 20);

    // set readOnly option of codemirror based on whether the parent
    // prosemirror editor is editable or not
    this.cm.setOption("readOnly", !this.editor.isEditable);

    // Track whether changes have been made but not yet propagated
    this.cm.on("beforeChange", () => (this.incomingChanges = true));

    // Propagate updates from CodeMirror to ProseMirror
    this.cm.on("cursorActivity", () => {
      if (!this.updating && !this.incomingChanges) this.forwardSelection();
    });

    this.cm.on("changes", () => {
      if (!this.updating) {
        this.valueChanged();
        this.forwardSelection();
      }
      this.incomingChanges = false;
    });
    // Forwarding the selection on focus.
    // The forward selection check on focus inside it. without the setTimeout
    // this.cm.hasFocus() will still be false and selection is not forwarded on
    // first focus inside cm
    this.cm.on("focus", () => setTimeout(() => this.forwardSelection(true), 0));
    this.cm.on("blur", () => this.cm.setOption("styleActiveLine", false));
    this.onCopy = copyEventHandler(this.editor.storage.paste.editorId);

    /**
     * Buble up copy, cut, paste, dragStart & drop event to prosemirror editor
     */

    this.cm.on("copy", (_cm, event) => {
      this.onCopy(this.editor.view, event);
    });

    this.cm.on("cut", (_cm, event) => {
      this.onCopy(this.editor.view, event);
    });

    this.cm.on("dragstart", (_cm, event) => {
      this.onCopy(this.editor.view, event);
    });

    this.cm.on("paste", (_cm, event) => {
      this.onPaste(event, false);
      event.preventDefault();
    });

    this.cm.on("drop", (_cm, event) => {
      this.onPaste(event, false);
      event.preventDefault();
    });
  }

  // When the code editor is focused, we can keep the selection of the outer
  // editor (ProseMirror) synchronized with the inner one, so that any commands
  // executed on the outer editor see an accurate selection.
  forwardSelection(bypass = false) {
    if (!this.cm.hasFocus() && !bypass) return;
    this.cm.setOption("styleActiveLine", true);
    const state = this.view.state;
    const selection = this.asProseMirrorSelection(state.doc);
    this.editor
      .chain()
      .dismissMenu()
      .command(({ tr, dispatch }) => {
        dispatch?.(tr.setSelection(selection).setMeta("focus", true));
        return true;
      })
      .run();
  }

  // This helper function translates from a CodeMirror selection to a ProseMirror selection.
  // Because CodeMirror uses a line/column based indexing system, indexFromPos is used to convert
  // to an actual character index.
  asProseMirrorSelection(doc: PMNode) {
    const offset = this.getPos() + 1;
    const anchor = this.cm.indexFromPos(this.cm.getCursor("anchor")) + offset;
    const head = this.cm.indexFromPos(this.cm.getCursor("head")) + offset;
    return TextSelection.create(doc, anchor, head);
  }

  // When the actual content of the code editor is changed, the event handler registered
  // in the node view's constructor calls this method. It'll compare the code block node's
  // current value to the value in the editor, and dispatch a transaction if there is a difference.
  valueChanged() {
    const change = computeTextChange(this.node.textContent, this.cm.getValue());
    if (!change) return;
    const start = this.getPos() + 1;
    const tr = this.view.state.tr.replaceWith(
      start + change.from,
      start + change.to,
      change.text ? this.schema.text(change.text) : []
    );
    this.view.dispatch(tr);
  }

  // Keymap to ensure that the user is able to escape the editor, when near the
  // edge of it. Also handles the undo and redo commands
  codeMirrorKeymap() {
    const view = this.view;
    const mod = /Mac/.test(navigator.platform) ? "Cmd" : "Ctrl";
    return CodeMirror.normalizeKeyMap({
      Up: () => this.maybeEscape("line", -1),
      Left: () => this.maybeEscape("char", -1),
      Down: () => this.maybeEscape("line", 1),
      Right: () => this.maybeEscape("char", 1),
      "Ctrl-Enter": () => {
        if (exitCode(view.state, view.dispatch)) view.focus();
      },
      [`${mod}-Z`]: () => undo(view.state, view.dispatch),
      [`Shift-${mod}-Z`]: () => redo(view.state, view.dispatch),
      [`${mod}-Y`]: () => redo(view.state, view.dispatch),
    });
  }

  // helper function to determine if the keyboard input would escpae the codeblock
  maybeEscape(
    unit: "line" | "char",
    dir: 1 | -1
  ): null | typeof CodeMirror.Pass {
    const pos = this.cm.getCursor();
    if (
      this.cm.somethingSelected() ||
      pos.line != (dir < 0 ? this.cm.firstLine() : this.cm.lastLine()) ||
      (unit == "char" &&
        pos.ch != (dir < 0 ? 0 : this.cm.getLine(pos.line).length))
    ) {
      return CodeMirror.Pass;
    }
    this.editor
      .chain()
      .command(({ tr, dispatch }) => {
        const nextSelection = Selection.findFrom(
          tr.doc.resolve(this.getPos() + (dir < 0 ? 0 : this.node.nodeSize)),
          dir,
          false
        );
        if (nextSelection) {
          dispatch?.(tr.setSelection(nextSelection));
        }
        return true;
      })
      .focus()
      .run();
    return null;
  }

  update(node: PMNode) {
    if (node.type !== this.node.type) return false;
    this.node = node;

    // update codemirror options
    this.cm.setOption("readOnly", !this.editor.isEditable);
    this.cm.setOption("mode", getLanguageMode(node.attrs.language));
    this.cm.setOption("theme", node.attrs.theme);
    this.cm.setOption("lineNumbers", node.attrs.lineNumbersVisible);

    // handle change in text
    const change = computeTextChange(this.cm.getValue(), node.textContent);
    if (change) {
      this.updating = true;
      this.cm.replaceRange(
        change.text,
        this.cm.posFromIndex(change.from),
        this.cm.posFromIndex(change.to)
      );
      this.updating = false;
    }

    // update the displayed language and visibility
    this.languageDisplay.innerHTML = getLanguageDisplay(node.attrs.language);
    this.languageDisplay.style.visibility = node.attrs.languageVisible
      ? "visible"
      : "hidden";
    if (node.attrs.languageVisible) {
      this.dom.classList.remove("language-display-off");
    } else {
      this.dom.classList.add("language-display-off");
    }

    if (this.editor.isEditable) {
      this.dom.classList.remove("CodeMirror-Readonly");
    } else {
      this.dom.classList.add("CodeMirror-Readonly");
    }

    return true;
  }

  /**
   * PM Callback function to handle selection from the editor. Passes the
   * ProseMirror selection to a CodeMirror selection
   */
  setSelection(anchor: number, head: number) {
    this.cm.focus();
    this.updating = true;
    this.cm.setSelection(
      this.cm.posFromIndex(anchor),
      this.cm.posFromIndex(head)
    );
    this.updating = false;
  }

  selectNode() {
    this.dom.classList.add("ProseMirror-selectednode");
    this.cm.focus();
  }

  stopEvent(event: Event) {
    // bubble up copy & drag event so it got captured by prosemirror
    if (
      (event as DragEvent).dataTransfer ||
      (event as ClipboardEvent).clipboardData
    )
      return false;
    // Events that occur inside CodeMirror shouldn't
    // bubble up to ProseMirror.
    return true;
  }

  onPaste(event: ClipboardEvent | DragEvent, moved: boolean) {
    let text;
    let html;
    const dataTransfer = (event as DragEvent).dataTransfer;
    const clipboardData = (event as ClipboardEvent).clipboardData;
    if (dataTransfer) {
      html = dataTransfer.getData("text/html");
      text = dataTransfer.getData("text/plain");
    } else if (clipboardData) {
      html = clipboardData.getData("text/html");
      text = clipboardData.getData("text/plain");
    }
    const slice = __parseFromClipboard(
      this.editor.view,
      text ?? "",
      html ?? "",
      // @ts-ignore
      this.editor.view.shiftKey,
      this.editor.view.state.selection.$from
    );
    this.editor.commands.handlePaste(event, moved, slice, html, text);
    return true;
  }
}

/**
 * `computeTextChange` compare two strings and find the minimal change between
 * them.
 *
 * It iterates from the start and end of the strings, until it hits a
 * difference, and returns an object giving the change's start, end, and
 * replacement text, or `null` if there was no change.
 */
function computeTextChange(
  previousText: string,
  currentText: string
): { from: number; to: number; text: string } | null {
  // Exit early if the strings are identical.
  if (previousText === currentText) {
    return null;
  }

  // Keep track of where the change starts.
  let from = 0;

  // Track the end position of relative to the original value.
  let to = previousText.length;

  // Track the end position relative the the current value.
  let currentTo = currentText.length;

  // Step forwards from the starting point until a changed value is encountered
  // and store the index of that changed value.
  while (
    from < to &&
    previousText.charCodeAt(from) === currentText.charCodeAt(from)
  ) {
    ++from;
  }

  // Step backwards from the end of the text values until a character which
  // doesn't match is encoutered. Store the index where the change happened in
  // both the `previousText` and the `currentText`.
  while (
    to > from &&
    currentTo > from &&
    previousText.charCodeAt(to - 1) === currentText.charCodeAt(currentTo - 1)
  ) {
    to--;
    currentTo--;
  }

  return { from, to, text: currentText.slice(from, currentTo) };
}

export default CodeBlockView;
