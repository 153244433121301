export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4.5H3C2.72386 4.5 2.5 4.72386 2.5 5V15C2.5 15.2761 2.72386 15.5 3 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15V5C13.5 4.72386 13.2761 4.5 13 4.5ZM3 3C1.89543 3 1 3.89543 1 5V15C1 16.1046 1.89543 17 3 17H13C14.1046 17 15 16.1046 15 15V5C15 3.89543 14.1046 3 13 3H3Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.5V3H8.5V3.5H7.5ZM7.5 5.5V4.5H8.5V5.5H7.5ZM7.5 7.5V6.5H8.5V7.5H7.5ZM7.5 9.5V8.5H8.5V9.5H7.5ZM7.5 11.5V10.5H8.5V11.5H7.5ZM7.5 13.5V12.5H8.5V13.5H7.5ZM7.5 15.5V14.5H8.5V15.5H7.5ZM7.5 17V16.5H8.5V17H7.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 9.5L15 9.5L15 10.5L14.5 10.5L14.5 9.5ZM12.5 9.5L13.5 9.5L13.5 10.5L12.5 10.5L12.5 9.5ZM10.5 9.5L11.5 9.5L11.5 10.5L10.5 10.5L10.5 9.5ZM8.5 9.5L9.5 9.5L9.5 10.5L8.5 10.5L8.5 9.5ZM6.5 9.5L7.5 9.5L7.5 10.5L6.5 10.5L6.5 9.5ZM4.5 9.5L5.5 9.5L5.5 10.5L4.5 10.5L4.5 9.5ZM2.5 9.5L3.5 9.5L3.5 10.5L2.5 10.5L2.5 9.5ZM1 9.5L1.5 9.5L1.5 10.5L1 10.5L1 9.5Z"
    />
  </>
);
