import { NodeType, Schema } from "@tiptap/pm/model";

export function getMathNodeTypes(schema: Schema): { [key: string]: NodeType } {
  if (schema.cached.mathNodeTypes) {
    return schema.cached.mathNodeTypes;
  }
  const roles: { [key: string]: NodeType } = {};

  Object.keys(schema.nodes).forEach((type) => {
    const nodeType = schema.nodes[type];
    if (nodeType.spec.mathRole) {
      roles[nodeType.spec.mathRole] = nodeType;
    }
  });

  schema.cached.mathNodeTypes = roles;

  return roles;
}
