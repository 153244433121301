export default (
  <>
    <path
      d="M7.35047 4.73346C7.63915 4.23346 8.36083 4.23346 8.64951 4.73346L13.3505 12.8757C13.6391 13.3757 13.2783 14.0007 12.7009 14.0007H3.29904C2.72169 14.0007 2.36084 13.3757 2.64952 12.8757L7.35047 4.73346Z"
      fill="#D92644"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.45703 11.436H7.53857L7.4375 6.93604H8.5625L8.45703 11.436ZM7.4375 12.5675C7.4375 12.4037 7.48777 12.2679 7.58831 12.1601C7.68885 12.0524 7.82608 11.9985 8 11.9985C8.17391 11.9985 8.31114 12.0524 8.41168 12.1601C8.51222 12.2679 8.56249 12.4037 8.56249 12.5675C8.56249 12.7255 8.51358 12.8577 8.41576 12.964C8.31793 13.0703 8.17934 13.1235 8 13.1235C7.82065 13.1235 7.68206 13.0703 7.58424 12.964C7.48641 12.8577 7.4375 12.7255 7.4375 12.5675Z"
      fill="white"
    />
    <mask
      id="mask0_1222_30736"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="7"
      y="6"
      width="2"
      height="8"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45703 11.436H7.53857L7.4375 6.93604H8.5625L8.45703 11.436ZM7.4375 12.5675C7.4375 12.4037 7.48777 12.2679 7.58831 12.1601C7.68885 12.0524 7.82608 11.9985 8 11.9985C8.17391 11.9985 8.31114 12.0524 8.41168 12.1601C8.51222 12.2679 8.56249 12.4037 8.56249 12.5675C8.56249 12.7255 8.51358 12.8577 8.41576 12.964C8.31793 13.0703 8.17934 13.1235 8 13.1235C7.82065 13.1235 7.68206 13.0703 7.58424 12.964C7.48641 12.8577 7.4375 12.7255 7.4375 12.5675Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1222_30736)"></g>
  </>
);
