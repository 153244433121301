import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { SectionLabel, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useAppSelector } from "@/data/hooks";
import { selectIsExam } from "@/features/assignment";
import { selectHasAcceptedSubmissionDeclaration } from "@/features/work";

import { Modal } from "../Modal";
import {
  AcademicIntegrityAgreement,
  AcademicIntegrityAgreementProps,
} from "./AcademicIntegrityAgreement";

export interface AcademicIntegrityModalProps
  extends Pick<
    AcademicIntegrityAgreementProps,
    "isChecked" | "onUpdateAgreement" | "isExam"
  > {}

export const AcademicIntegrityModal = (
  agreementProps: AcademicIntegrityModalProps
) => {
  return (
    <Modal onClose={() => {}} withCloseButton={false} width={636}>
      <Container>
        <Text kind="headingOne" textAlign="center">
          Unable to submit
        </Text>
        <Spacer spacing={48} />
        <SectionLabel title="Academic integrity agreement" />
        <Spacer spacing={27} />
        <AcademicIntegrityAgreement
          {...agreementProps}
          showError={!agreementProps.isChecked}
        />
      </Container>
    </Modal>
  );
};

export interface ConnectedAcademicIntegrityModalProps {
  onAgree: VoidFunction;
}

/**
 * A blocking modal that will only
 */
export const ConnectedAcademicIntegrityModal =
  NiceModal.create<ConnectedAcademicIntegrityModalProps>(({ onAgree }) => {
    const isExam = useAppSelector(selectIsExam);
    const hasAcceptedSubmissionDeclaration = useAppSelector(
      selectHasAcceptedSubmissionDeclaration
    );
    const modal = useModal();
    if (!modal.visible) {
      return null;
    }

    return (
      <AcademicIntegrityModal
        isExam={isExam}
        isChecked={hasAcceptedSubmissionDeclaration}
        onUpdateAgreement={(hasAgreed: boolean) => {
          if (hasAgreed) {
            onAgree();
          }
        }}
      />
    );
  });

const Container = styled.div`
  padding: 76px 88px;
`;
