import { Node, mergeAttributes } from "@tiptap/core";
import { Decoration } from "@tiptap/pm/view";

import { PlaceholderStorage } from "../placeholder";

export interface CaptionOptions {
  HTMLAttributes: Record<string, any>;
  placeholderText: string;
}

export const Caption = Node.create<CaptionOptions>({
  name: "caption",

  addOptions() {
    return {
      HTMLAttributes: {},
      placeholderText: "Please enter your caption here...",
    };
  },
  content: "paragraph+",
  isolating: true,

  onCreate() {
    const { type, options, editor } = this;
    const { storage } = editor;
    const { placeholderText } = options;
    (storage.placeholder as PlaceholderStorage).placeholders?.set(this.name, {
      /**
       * Display the placeholder for caption if it has empty text content
       * and not in focus
       */
      check: (selection, node, pos) => {
        if (node.type.name !== type.name) return false;
        const nodeStart = pos;
        const nodeEnd = pos + node.nodeSize;
        const { from, to } = selection;
        if (
          (from >= nodeStart && from <= nodeEnd) ||
          (to <= nodeEnd && to >= nodeStart)
        )
          return false;
        if (node.textContent === "") return true;
        return false;
      },
      decoration: (node, pos) => {
        const child = node.firstChild;
        if (!child) return null;
        return Decoration.node(pos + 1, pos + 1 + child.nodeSize, {
          class: "empty-node",
          "data-placeholder-text": placeholderText,
        });
      },
    });
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "figcaption",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "figcaption",
      },
    ];
  },
});
