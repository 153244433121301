import { mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { NodeSelection } from "@tiptap/pm/state";
import { Decoration } from "@tiptap/pm/view";

import { htmlToDOMOutputSpec } from "../../../core/utilities/htmlToProsemirror";
import { findCodeBlockSelection } from "../../code-block/utils";
import { PlaceholderStorage } from "../../placeholder";
import { MathInlineNodeView } from "../components/MathInlineNodeView";
import { InlineMathBase } from "./inline-math-base";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    inlineMath: {
      /**
       * Insert a new `inlineMath` node.
       */
      insertInlineMath: () => ReturnType;
    };
  }
}

/**
 * Inline level atomic math nodes to display equation
 */
export const InlineMath = InlineMathBase.extend({
  onCreate() {
    const { type, editor } = this;
    const { storage } = editor;
    (storage.placeholder as PlaceholderStorage).placeholders.set(this.name, {
      check: (_selection, node, _pos) => {
        if (node.type.name !== type.name) return false;
        if (node.attrs.equation === "") return true;
        return false;
      },
      decoration: (node, pos) => {
        return Decoration.node(pos, pos + node.nodeSize, {
          class: "empty-math-node",
        });
      },
    });
  },
  renderHTML({ HTMLAttributes, node }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "inline-math-node",
      }),
      ...htmlToDOMOutputSpec(
        this.options.convertLatexToMarkup(node.attrs.equation)
      ),
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(MathInlineNodeView);
  },

  // addProseMirrorPlugins() {
  //   return [
  //     // Paste rule for markdown inline equation `$<equation>$`
  //     NodePasteRule(
  //       buildRegex(INLINE_MATH_REGEX, undefined, undefined, "gui"),
  //       (_node, match) => {
  //         if (match.input)
  //           // replace match range with inlineMath with the captured equation
  //           return this.type.create({
  //             equation: match[2].trim(),
  //           });
  //         return null;
  //       }
  //     ),
  //   ];
  // },

  // addInputRules() {
  //   return [
  //     // Input rule for markdown inline equation `$<equation>$`
  //     nodeInputRule({
  //       find: INLINE_MATH_REGEX,
  //       type: this.type,
  //       getAttributes(match) {
  //         if (match.input) {
  //           // replace match range with inlineMath with the captured equation
  //           return {
  //             equation: match[2].trim(),
  //           };
  //         }
  //       },
  //     }),
  //   ];
  // },

  addCommands() {
    return {
      // Insert a new inline math node.
      insertInlineMath:
        () =>
        ({ state, chain, dispatch }) => {
          if (findCodeBlockSelection(state.selection)) return false;
          if (dispatch) {
            return chain()
              .insertContent(`<math-field/>`)
              .focus()
              .command(({ tr, dispatch }) => {
                const { selection, doc } = tr;
                dispatch?.(
                  tr.setSelection(NodeSelection.create(doc, selection.from - 1))
                );
                return true;
              })
              .maybeOpenEquationMenu()
              .run();
          }
          return true;
        },
    };
  },
});
