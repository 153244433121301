import { Icon } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { typography } from "./styles/typography";

interface Props {
  /** A state to indicate selection */
  selected?: boolean;
  /** Event handler for close button click */
  onClose?: (event: React.SyntheticEvent) => void;
  /** React className */
  className?: string;
  children?: React.ReactNode;
}

/**
 * Chip used as a `PasteChip` in Editor
 */
export const Chip: React.FC<Props> = (props) => (
  <>
    <ChipContainer selected={props.selected} className={props.className}>
      {props.children}
      <CloseAction onMouseDown={props.onClose}>
        <Icon iconName="Close" />
      </CloseAction>
    </ChipContainer>

    <span>&#65279;</span>
  </>
);

const ChipContainer = styled.span<Props>`
  display: inline-flex;
  align-items: center;
  padding-left: 9px;
  padding-right: 2px;
  margin: 0 2px;
  box-sizing: border-box;
  height: 22px;
  border-radius: 12px;
  line-height: 24px;

  font-size: ${typography["system"].fontSize};
  letter-spacing: ${typography["system"].letterSpacing};
  white-space: nowrap;

  /* when selected, change background to CHROME selection blue */
  background: ${(p) => (p.selected ? "#B3D7FE" : p.theme.background.action2)};
  user-select: none; /* use selected prop instead */
`;

export const CloseAction = styled.button.attrs({
  "aria-label": "Close",
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 9px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  outline: none;

  background: ${(p) => p.theme.background.action2};

  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.background.action3};
  }
`;
