import { Content } from "@vericus/cadmus-editor-prosemirror";

/**
 * Interface for the JSON document representing an Aphrodite save.
 *
 * Currently the save document can be from the older Slate editors or from the
 * current Prosemirror Editors.
 *
 * The difference in the JSON structure is a bit subtle, with the new structure
 * adding a toplevel field `editor` with the value `prosemirror` moving forward.
 *
 * Use the type guards `isSaveFromProsemirrorEditors` and
 * `isSaveFromSlateEditors` to narrow the `WorkSave` enum.
 */
export interface WorkSaveDoc {
  editor?: "prosemirror";
  body: {
    state: string;
  };
  references: {
    state: string;
  };
  notes: {
    state: string;
  };
  // Other Metadata
  assessmentId: string;
  workId: string;
  sessionId: string | null;
  prevSaveId: string | null;
  prevVersionId: string | null;
}

export function isSaveFromProsemirrorEditors(editors: WorkSaveDoc): boolean {
  return editors.editor === "prosemirror";
}

export function isSaveFromSlateEditors(editors: WorkSaveDoc): boolean {
  return editors.editor === undefined;
}

/** Deserialised Editor contents */
export interface WorkEditors {
  body: Content;
  notes: Content;
  references: Content;
}
