import { IconProps } from "./types";

export const FractionIcon = (props: IconProps) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" {...props}>
      <path
        d="M9 3.33333C9 3.14924 9.14924 3 9.33333 3H16.6667C16.8508 3 17 3.14924 17 3.33333V10.6667C17 10.8508 16.8508 11 16.6667 11H9.33333C9.14924 11 9 10.8508 9 10.6667V3.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4V10H16V4H10ZM9.33333 3C9.14924 3 9 3.14924 9 3.33333V10.6667C9 10.8508 9.14924 11 9.33333 11H16.6667C16.8508 11 17 10.8508 17 10.6667V3.33333C17 3.14924 16.8508 3 16.6667 3H9.33333Z"
        fill="#7070A9"
      />
      <path d="M8 13H18" stroke="#3A517F" strokeWidth="0.5" />
      <path
        d="M9 15.3333C9 15.1492 9.14924 15 9.33333 15H16.6667C16.8508 15 17 15.1492 17 15.3333V22.6667C17 22.8508 16.8508 23 16.6667 23H9.33333C9.14924 23 9 22.8508 9 22.6667V15.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16V22H16V16H10ZM9.33333 15C9.14924 15 9 15.1492 9 15.3333V22.6667C9 22.8508 9.14924 23 9.33333 23H16.6667C16.8508 23 17 22.8508 17 22.6667V15.3333C17 15.1492 16.8508 15 16.6667 15H9.33333Z"
        fill="#7070A9"
      />
    </svg>
  );
};
