import styled from "styled-components";

import { LoadingQuill } from "../loading-quill";

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

/**
 *
 * A full page loading indicator. Uses a css animated spritesheet.
 */
export const LoadingPage = ({ message }: { message?: string }) => (
  <Container>
    <LoadingQuill message={message} />
  </Container>
);
