import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

const atomSelectionPluginKey = new PluginKey("atomSelection");

export const AtomSelection = Extension.create({
  name: "AtomSelection",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: atomSelectionPluginKey,
        props: {
          decorations(state) {
            const { doc, selection } = state;
            const decorations = [] as Decoration[];
            if (selection.empty) return DecorationSet.empty;
            const { from, to } = selection;
            doc.nodesBetween(from, to, (node, pos) => {
              if (node.isAtom || node.type.name === "image") {
                decorations.push(
                  Decoration.node(pos, pos + node.nodeSize, {
                    class: "ProseMirror-selectednode",
                  })
                );
              }
              return true;
            });
            return DecorationSet.create(doc, decorations);
          },
          handleClick: (view) => {
            if (!view.hasFocus()) {
              this.editor.commands.setMeta("focus", true);
            }
            return false;
          },
        },
      }),
    ];
  },
});
