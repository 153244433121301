import { IconProps } from "./types";

export const CeilingIcon = (props: IconProps) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" {...props}>
      <path
        d="M3.13395 5.788C3.18195 5.644 3.27795 5.548 3.42195 5.5H5.36595C6.66195 5.5 7.33395 5.512 7.38195 5.536C7.51395 5.608 7.57995 5.716 7.57995 5.86C7.57995 6.004 7.51395 6.112 7.38195 6.184C7.33395 6.208 6.73395 6.22 5.58195 6.22H3.85395V23.23C3.76995 23.398 3.66195 23.488 3.52995 23.5C3.38595 23.5 3.25395 23.404 3.13395 23.212V5.788Z"
        fill="#3A517F"
      />
      <path
        d="M9 10.8333C9 10.6492 9.14924 10.5 9.33333 10.5H16.6667C16.8508 10.5 17 10.6492 17 10.8333V18.1667C17 18.3508 16.8508 18.5 16.6667 18.5H9.33333C9.14924 18.5 9 18.3508 9 18.1667V10.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.5V17.5H16V11.5H10ZM9.33333 10.5C9.14924 10.5 9 10.6492 9 10.8333V18.1667C9 18.3508 9.14924 18.5 9.33333 18.5H16.6667C16.8508 18.5 17 18.3508 17 18.1667V10.8333C17 10.6492 16.8508 10.5 16.6667 10.5H9.33333Z"
        fill="#7070A9"
      />
      <path
        d="M22.842 23.23C22.758 23.398 22.65 23.488 22.518 23.5C22.374 23.5 22.242 23.404 22.122 23.212V6.22H20.394C19.242 6.22 18.648 6.208 18.612 6.184C18.468 6.112 18.396 5.998 18.396 5.842C18.396 5.71 18.468 5.608 18.612 5.536C18.648 5.512 19.326 5.5 20.646 5.5H22.608C22.728 5.596 22.806 5.686 22.842 5.77V23.23Z"
        fill="#3A517F"
      />
    </svg>
  );
};
