export default (
  <>
    <path
      d="M14.5266 2.01123H1.47336C1.08259 2.01123 0.707883 2.16644 0.431541 2.44277C0.155199 2.71911 0 3.09382 0 3.48459V16.5379C0 16.9286 0.155207 17.3033 0.431541 17.5797C0.707875 17.856 1.08259 18.0112 1.47336 18.0112H14.5266C14.9174 18.0112 15.2921 17.856 15.5685 17.5797C15.8448 17.3034 16 16.9286 16 16.5379V3.48459C16 3.09382 15.8448 2.71911 15.5685 2.44277C15.2921 2.16643 14.9174 2.01123 14.5266 2.01123Z"
      fill="#6355F6"
    />
    <path
      d="M10.7899 10.1921L6.48488 13.2097C6.26695 13.3637 5.89453 13.2559 5.89453 13.0327V6.98972C5.89453 6.76652 6.26695 6.65879 6.48488 6.81274C8.16608 7.9912 9.10866 8.65191 10.7899 9.83037C10.8574 9.8735 10.8963 9.94154 10.8945 10.0132C10.8926 10.0848 10.8505 10.1513 10.7807 10.1921L10.7899 10.1921Z"
      fill="white"
    />
  </>
);
