import { useCallback } from "react";
import { findChildren, posToDOMRect } from "@tiptap/core";
import styled from "styled-components";

import { FloatingMenuComponent, ShouldShow } from "../../floating-menu";
import {
  findTableGroup,
  getTableNodeTypes,
  isSelectionInsideTableGroup,
} from "../utils";
import { BlockMenu } from "./BlockMenu";
import { BorderMenu } from "./BorderMenu";
import { CellColorMenu } from "./CellColorMenu";
import { DeleteMenu } from "./DeleteMenu";
import { FormatMenu } from "./FormatMenu";
import { MergeSplitMenu } from "./MergeSplitMenu";
import { ButtonGroups } from "./styles";
import { TableMenuComponentProps } from "./types";

/**
 * Table Menu tippy component
 */
export function TableMenuComponent(props: TableMenuComponentProps) {
  const { editor, portalId } = props;
  const shouldShow: ShouldShow = useCallback(({ state }) => {
    return isSelectionInsideTableGroup(state.selection);
  }, []);
  const getBoundingClientRect = useCallback(() => {
    const { state } = editor.view;
    const { selection } = state;
    const tableGroup = findTableGroup(selection);
    // support for CellSelections
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));
    if (tableGroup) {
      const { table_caption: tableCaptionType } = getTableNodeTypes(
        state.schema
      );
      const tableCaption = findChildren(
        tableGroup.node,
        (node) => node.type === tableCaptionType
      )[0];
      const captionOffset =
        tableCaption && tableCaption.node.attrs.hideText
          ? tableCaption.node.nodeSize + 2
          : 0;
      return posToDOMRect(
        editor.view,
        tableGroup.start + captionOffset,
        tableGroup.pos + tableGroup.node.nodeSize
      );
    }
    return posToDOMRect(editor.view, from, to);
  }, [editor]);

  return (
    <FloatingMenuComponent
      pluginKey="tableFloatingMenu"
      editor={editor}
      shouldShow={shouldShow}
      getBoundingClientRect={getBoundingClientRect}
      portalId={portalId}
      isAsync={true}
    >
      <MenuWrapper>
        <ButtonGroups>
          <CellColorMenu editor={editor} />
          <BlockMenu editor={editor} />
          <FormatMenu editor={editor} />
          <MergeSplitMenu editor={editor} />
          <BorderMenu editor={editor} />
          <DeleteMenu editor={editor} />
        </ButtonGroups>
      </MenuWrapper>
    </FloatingMenuComponent>
  );
}

const MenuWrapper = styled.div`
  display: inline-flex;
  background: transparent;
  border: none;
  outline: none;
  width: 500px;
  justify-content: center;
  align-items: center;
`;
