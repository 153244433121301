import { useState } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { Button } from "../Button";
import { Input } from "./Input";

export interface ActionInputProps {
  /**
   * Value of the input field
   */
  value: string;

  /**
   * Callback for when the input changes
   * @param {string} value The current value
   */
  onChange: (value: string) => void;

  /**
   * Icon to display to complement the input field.
   */
  iconName: IconName;

  /**
   * Placeholder text for when there's no value in the input field
   */
  placeholder: string;

  /**
   * Width (in px) of how wide this component should be
   */
  width?: number;

  /**
   * Callback for when the action is pressed
   * @param {string} stagedInput value of the input when the action is pressed.
   */
  onAction: (stagedInput: string) => void;

  /**
   * The display name of the button
   */
  actionName?: string;

  /**
   * The input is immediately editable when `true`
   */
  autofocus?: boolean;

  /**
   * The error message.
   */
  error?: string;

  /**
   * Whether or not the action button is disabled.
   */
  disabled?: boolean;
}

export const ActionInput = ({
  value,
  onChange,
  iconName,
  placeholder,
  width,
  onAction,
  actionName,
  className,
  autofocus,
  error,
  disabled,
}: ActionInputProps & { className?: string }) => {
  const [editing, setEditing] = useState(!!autofocus);

  return (
    <Container
      width={width}
      showAction={!!actionName}
      editInvite={editing}
      className={className}
    >
      <Input
        value={value}
        data-testid="actionable-input"
        onChange={(event) => {
          const targetValue = event.target.value;
          onChange(targetValue);
        }}
        autoFocus={autofocus}
        iconName={iconName}
        placeholder={placeholder}
        fullWidth
        onFocus={() => {
          setEditing(true);
        }}
        errorMessage={error}
      />
      {editing && actionName && (
        <ActionButton
          kind="dark"
          size="sm"
          aria-label="action-button"
          onClick={() => {
            onAction(value);
          }}
          disabled={disabled}
        >
          {actionName}
        </ActionButton>
      )}
    </Container>
  );
};

const Container = styled.div<{
  width?: number;
  showAction: boolean;
  editInvite: boolean;
}>`
  position: relative;
  width: ${(p) => (p.width ? `${p.width}px` : `initial`)};

  border-radius: 2px;
  background-color: ${(p) => p.theme.background.default};

  & input {
    padding-right: calc(
      ${(p) => {
          return p.showAction ? "72px" : "0";
        }} + 12px + 12px
    );
  }

  input:hover {
    cursor: ${(p) => (!p.editInvite ? "pointer" : "initial")};
  }
`;

const ActionButton = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 4px;
`;
