import {
  CeilingIcon,
  ExponentialIcon,
  FloorIcon,
  InverseCosecIcon,
  InverseCosineIcon,
  InverseCotTanIcon,
  InverseSecIcon,
  InverseSineIcon,
  InverseTanIcon,
  Log10Icon,
  LogarithmIcon,
  NaturalLogIcon,
  PowerIcon,
  RootIcon,
  SquareIcon,
  SquareRootIcon,
  SubscriptIcon,
  SubscriptSuperscriptIcon,
  VerticalBarIcon,
} from "../icons";
import { MathCommand } from "./types";

export const square: MathCommand = {
  label: "square",
  renderChildren: () => <SquareIcon />,
  mathLiveCommand: "^2",
};
export const power: MathCommand = {
  label: "power",
  renderChildren: () => <PowerIcon />,
  mathLiveCommand: "^{\\placeholder{}}",
  tooltipCommand: "x^y",
};
export const squareRoot: MathCommand = {
  label: "square root",
  renderChildren: () => <SquareRootIcon />,
  mathLiveCommand: "\\sqrt",
  latexCommand: "\\sqrt{\\placeholder{}}",
  tooltipCommand: "\\sqrt",
};
export const root: MathCommand = {
  label: "root",
  renderChildren: () => <RootIcon />,
  mathLiveCommand: "\\sqrt[\\placeholder{}]{\\placeholder{}}",
  tooltipCommand: "\\sqrt[x]{y}",
};
export const subscript: MathCommand = {
  label: "subscript",
  renderChildren: () => <SubscriptIcon />,
  mathLiveCommand: "_{\\placeholder{}}",
  tooltipCommand: "x_{y}",
};
export const subscriptSuperscript: MathCommand = {
  label: "subscript superscript",
  renderChildren: () => <SubscriptSuperscriptIcon />,
  mathLiveCommand: "_{\\placeholder{}}^{\\placeholder{}}",
  tooltipCommand: "x_{y}^z",
};
export const exponential: MathCommand = {
  label: "exponential function",
  renderChildren: () => <ExponentialIcon />,
  mathLiveCommand: "\\exponentialE^{\\placeholder{}}",
  tooltipCommand: "\\exponentialE^{x}",
};
export const naturalLog: MathCommand = {
  label: "natural log",
  renderChildren: () => <NaturalLogIcon />,
  mathLiveCommand: "\\ln{\\placeholder{}",
  tooltipCommand: "\\ln",
};
export const logarithm: MathCommand = {
  label: "logarithm",
  renderChildren: () => <LogarithmIcon />,
  mathLiveCommand: "\\log_{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\log_{x}",
};
export const log10: MathCommand = {
  label: "log10",
  renderChildren: () => <Log10Icon />,
  mathLiveCommand: "\\log_{10}{\\placeholder{}}",
  tooltipCommand: "\\log_{10}",
};
export const sine: MathCommand = {
  label: "sine",
  text: "sin",
  mathLiveCommand: "\\sin",
  size: "small",
};
export const cosine: MathCommand = {
  label: "cosine",
  text: "cos",
  mathLiveCommand: "\\cos",
  size: "small",
};
export const tangent: MathCommand = {
  label: "tangent",
  text: "tan",
  mathLiveCommand: "\\tan",
  size: "small",
};
export const cotangent: MathCommand = {
  label: "cotangent",
  text: "cot",
  mathLiveCommand: "\\cot",
  size: "small",
};
export const secant: MathCommand = {
  label: "secant",
  text: "sec",
  mathLiveCommand: "\\sec",
  size: "small",
};
export const cosecant: MathCommand = {
  label: "cosecant",
  text: "csc",
  mathLiveCommand: "\\csc",
  size: "small",
};
export const inverseSine: MathCommand = {
  label: "inverse sine",
  renderChildren: () => <InverseSineIcon />,
  mathLiveCommand: "\\sin^{-1}",
};
export const inverseCosine: MathCommand = {
  label: "inverse cosine",
  renderChildren: () => <InverseCosineIcon />,
  mathLiveCommand: "\\cos^{-1}",
};
export const inverseTan: MathCommand = {
  label: "inverse tangent",
  renderChildren: () => <InverseTanIcon />,
  mathLiveCommand: "\\tan^{-1}",
};
export const inverseCotTan: MathCommand = {
  label: "inverse cotangent",
  renderChildren: () => <InverseCotTanIcon />,
  mathLiveCommand: "\\cot^{-1}",
};
export const inverseSec: MathCommand = {
  label: "inverse secant",
  renderChildren: () => <InverseSecIcon />,
  mathLiveCommand: "\\sec^{-1}",
};
export const inverseCosec: MathCommand = {
  label: "inverse cosecant",
  renderChildren: () => <InverseCosecIcon />,
  mathLiveCommand: "\\csc^{-1}",
};
export const sinh: MathCommand = {
  label: "sinh",
  text: "sinh",
  mathLiveCommand: "\\sinh",
  size: "small",
};
export const cosh: MathCommand = {
  label: "cosh",
  text: "cosh",
  mathLiveCommand: "\\cosh",
  size: "small",
};
export const tanh: MathCommand = {
  label: "tanh",
  text: "tanh",
  mathLiveCommand: "\\tanh",
  size: "small",
};
export const sech: MathCommand = {
  label: "sech",
  text: "sech",
  mathLiveCommand: "\\sech",
  size: "small",
};
export const csch: MathCommand = {
  label: "csch",
  text: "csch",
  mathLiveCommand: "\\csch",
  size: "small",
};
export const coth: MathCommand = {
  label: "coth",
  text: "coth",
  mathLiveCommand: "\\coth",
  size: "small",
};
export const degree: MathCommand = {
  label: "degree",
  text: "º",
  mathLiveCommand: "\\degree",
  size: "small",
};
export const verticalBars: MathCommand = {
  label: "vertical bars",
  renderChildren: () => <VerticalBarIcon />,
  mathLiveCommand: "\\left|{\\placeholder{}}\\right|",
  tooltipLabel: "vertical bars",
  tooltipCommand: "\\left|\\right|",
};
export const ceiling: MathCommand = {
  label: "ceiling function",
  renderChildren: () => <CeilingIcon />,
  mathLiveCommand: "\\lceil{\\placeholder{}}\\rceil",
  tooltipLabel: "ceiling function",
  tooltipCommand: "\\lceil\\rceil",
};
export const floor: MathCommand = {
  label: "floor function",
  renderChildren: () => <FloorIcon />,
  mathLiveCommand: "\\lfloor{\\placeholder{}}\\rfloor",
  tooltipLabel: "floor function",
  tooltipCommand: "\\lfloor\\rfloor",
};
export const verticalBar: MathCommand = {
  label: "vertical bar",
  text: "|",
  mathLiveCommand: "\\mid",
  tooltip: false,
};
export const lAngle: MathCommand = {
  label: "left angle bracket",
  text: "⟨",
  mathLiveCommand: "\\langle",
};
export const rAngle: MathCommand = {
  label: "right angle bracket",
  text: "⟩",
  mathLiveCommand: "\\rangle",
};
