import { ClipboardCleaner } from "./clipboard-cleaner";

export class MicrosoftWordClipboardCleaner extends ClipboardCleaner {
  // Remove unused content
  protected cleanDOM() {
    // Remove footnote list container with separator line
    const removableElements = this.dom?.querySelectorAll(
      'div[style*="mso-element:footnote-list"]'
    );

    removableElements?.forEach((el: Node) => el?.parentNode?.removeChild(el));
  }

  // Iterate over pasted nodes and their children
  protected iterateNode(node: Node) {
    const element = node as HTMLElement;
    if (element.tagName === "P" && !element.firstChild) {
      node.parentNode?.removeChild(node);
      return true;
    } else if (node.nodeType === Node.COMMENT_NODE) {
      if (node.textContent === "EndFragment") {
        // End of paste content. Remove all remaining sibling nodes.
        while (node) {
          const nextSibling = node.nextSibling as Node;
          node.parentNode?.removeChild(node);
          node = nextSibling;
        }
        return false;
      } else {
        node.parentNode?.removeChild(node);
        return true;
      }
    }
    if (node.nodeType === Node.ELEMENT_NODE) {
      let childNode = node.firstChild as Node | false;
      while (childNode) {
        const nextChildNode = childNode.nextSibling as Node;
        if (this.iterateNode(childNode)) {
          childNode = nextChildNode;
        } else {
          childNode = false;
        }
      }
      node = this.convertNode(node);
    }

    return true;
  }
}
