import styled from "styled-components";

import { levels } from "../styles";

interface MenuProps {
  /**
   * Width in pixels applied to the menu.
   * @default 200
   */
  width?: number;
}

export const Menu = styled.div<MenuProps>`
  padding: 8px 0px;
  margin: 0;

  min-width: 200px;
  width: ${(p) => (p.width ? `${p.width}px` : "232px")};
  max-height: 360px;

  border-radius: 2px;
  background-color: ${(p) => p.theme.background.default};
  box-sizing: border-box;
  box-shadow: ${levels.two};
  overflow-y: auto;
  z-index: 2;
`;
