import styled from "styled-components";

import { colors } from "../styles";
import { Text } from "../Text";

export interface FilterPillProps {
  /** Marks the pill as active/selected. */
  isActive?: boolean;
  text: string;
  /**
   * @default "large"
   */
  size?: "small" | "large";
  onClick: VoidFunction;
}

export const FilterPill = ({
  isActive,
  text,
  onClick,
  size,
}: FilterPillProps) => {
  return (
    <Container
      isActive={isActive}
      size={size}
      role="button"
      tabIndex={0}
      onClick={onClick}
      aria-current={isActive ?? false}
    >
      <StyledText kind={isActive ? "headingSix" : "system"} isActive={isActive}>
        {text}
      </StyledText>
    </Container>
  );
};

const StyledText = styled(Text)<Pick<FilterPillProps, "isActive">>`
  color: ${(p) => (p.isActive ? colors.white100 : colors.black100)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -2px; // our font is not vertically aligned
`;

const Container = styled.div<Pick<FilterPillProps, "isActive" | "size">>`
  background-color: ${(p) => (p.isActive ? colors.blue500 : "transparent")};
  border: 1px solid ${colors.grey400};
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.size === "small" ? "4px 16px" : "6px 24px")};
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  min-height: ${(p) => (p.size === "small" ? 36 : 41)}px;

  &:hover {
    background-color: ${(p) => !p.isActive && colors.grey50};
  }

  ${StyledText} {
    font-size: ${(p) => p.size === "small" && "12px"};
  }
`;
