import { Extension, KeyboardShortcutCommand } from "@tiptap/core";

import { parseSpecialIndent, renderSpecialIndent } from "./helpers";
import { SpecialIndentationOption } from "./types";

/**
 * Extension to setup indentation attributes for blocks that can have special
 * indentation like hanging indent and/or first line indentation
 */
export const SpecialIndentation = Extension.create<SpecialIndentationOption>({
  name: "specialIndentation",

  addOptions() {
    return {
      types: ["paragraph"],
      indentationStyles: ["normal", "firstLine"],
      renderStyles: ["normal", "firstLine", "hanging"],
      defaultIndentation: "normal",
    };
  },

  addStorage() {
    return {
      enabledStyles: this.options.indentationStyles,
    };
  },

  addKeyboardShortcuts() {
    const shortcuts = {} as {
      [key: string]: KeyboardShortcutCommand;
    };
    if (this.options.indentationStyles.includes("hanging")) {
      shortcuts["Mod-Alt-6"] = ({ editor }) =>
        editor.commands.toggleIndentStyle("hanging");
    }
    return shortcuts;
  },

  addGlobalAttributes() {
    if (this.options.types.length) {
      return [
        {
          types: this.options.types,
          attributes: {
            indentSpecial: {
              default: this.options.defaultIndentation,
              renderHTML: (attributes) =>
                renderSpecialIndent(
                  this.options.renderStyles,
                  this.options.defaultIndentation
                )(attributes),
              parseHTML: parseSpecialIndent(
                this.options.indentationStyles,
                this.options.defaultIndentation
              ),
            },
          },
        },
      ];
    }
    return [];
  },
});
