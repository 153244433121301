import React, { forwardRef } from "react";
import styled from "styled-components";

import { colors } from "./styles/colors";
import { Text } from "./Text";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Text label to display besides the Switch.
   * Has a higher priority than `children`.
   */
  content?: string;
  /**
   * Render any react node inside the Label wrapper.
   * Has a lower priority than `content`.
   */
  children?: React.ReactNode;

  /** Passes className to Label container */
  className?: string;
}

/**
 * A control to allow a user to select one or more options of a limited number
 * of choices
 *
 * To add a simple text string to a switchButton use the `content` prop.
 * For more complex components use the `children` prop.
 *
 * _props extends `input` attributes_
 */
export const Switch = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { content, className, children, ...inputProps } = props;
  return (
    <Label className={className} readOnly={inputProps.readOnly}>
      <HiddenInput type="checkbox" {...inputProps} ref={ref} />
      <SwitchSpan />
      {content ? (
        <Text kind="system" as="span">
          {content}
        </Text>
      ) : (
        children
      )}
    </Label>
  );
});

/* The label wraps the entire Switch */
const Label = styled.label<{ readOnly?: boolean }>`
  display: inline-flex;
  align-items: center;
  outline: 0;
  position: relative;
  pointer-events: ${(p) => (p.readOnly ? "none" : "all")};
`;

const SwitchSpan = styled.span`
  flex: none;
  display: inline-block;
  width: 28px;
  height: 16px;
  margin: 0px;
  margin-right: 8px;

  position: relative;

  background: ${colors.grey400};
  border-radius: 100px;

  cursor: pointer;

  /* The :after pseudo-element creates the sliding circle */
  &:after {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: block;
    transition: left 0.2s;
    background: #ffffff;
    box-shadow: 2px 0px 4px rgba(30, 27, 73, 0.12);
    position: absolute;
    top: 1px;
    left: 1px;
  }
`;

/* The native switchButton, which is hidden */
const HiddenInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  width: 36px; /* Same as Switch */
  height: 18px; /* Same as Switch */

  cursor: pointer;

  /* Apply active styles to Switch when unchecked */
  &:not(:checked) ~ ${SwitchSpan}:active {
    background: ${(p) => p.theme.background.action2};
  }

  &:not(disabled) {
    /* Apply focued styles to Switch */
    &:active ~ ${SwitchSpan}, &:focus ~ ${SwitchSpan} {
      background-color: ${(p) => p.theme.background.action3};
    }

    /* Apply checked styles to Switch */
    &:checked ~ ${SwitchSpan} {
      background: ${(p) => p.theme.text.accent};
      &:after {
        left: 13px;
      }
    }
  }

  /* Apply disabled styles to Switch and Text */
  &:disabled ~ ${SwitchSpan}, &:disabled ~ ${Text} {
    opacity: 0.36;
  }

  &:disabled ~ ${SwitchSpan} {
    cursor: not-allowed;
  }
`;
