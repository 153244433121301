import { Editor } from "@tiptap/core";
import { Transaction } from "@tiptap/pm/state";
import { liftTarget } from "@tiptap/pm/transform";
import { maybeJoinList } from "./maybeJoinList";
import { calculateItemRange, findParentItem } from "../helpers";
import { changeItemsType } from "./changeItemsType";
import { indentSiblingsOfItems } from "./indentSiblingsOfItems";
import { indentSiblingsOfList } from "./indentSiblingsOfList";

/**
 * A helper function to outdent selected list items.
 *
 * @beta
 *
 * ported from dedentList function from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-command-dedent.ts
 */
export function outdentList(editor: Editor, tr: Transaction): boolean {
  let range = calculateItemRange(editor, tr.selection);

  if (!range) return false;

  const findParentItemResult = findParentItem(
    editor,
    tr.selection.$from,
    range
  );
  if (!findParentItemResult) return false;

  const { parentItem, parentList } = findParentItemResult;

  range = indentSiblingsOfItems(tr, range);
  range = indentSiblingsOfList(tr, range);
  range = changeItemsType(editor, tr, range, parentList, parentItem);

  const target = liftTarget(range);

  if (typeof target !== "number") return true;

  tr.lift(range, target);

  range = calculateItemRange(editor, tr.selection);

  if (range) {
    maybeJoinList(editor, tr, tr.doc.resolve(range.end - 2));
  }

  return true;
}
