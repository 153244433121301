import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.16 14.569c.24.577-.637.94-.877.364L7.93 11.7a1.41 1.41 0 0 1-1.382-.884l-2.19-.03c-.624-.006-.613-.954.01-.947l2.145.028c.132-.42.455-.754.868-.902V6.114c0-.626.952-.626.952 0v2.851a1.41 1.41 0 0 1 .474 2.37l1.353 3.234ZM15.088 1.43c1.038 1.039 1.063 1.946.251 3.027a.741.741 0 0 1-1.035.148l-2.552-1.917c-.49-.367-.296-.837-.002-1.228a2.032 2.032 0 0 1 2.84-.404l.498.374ZM.925 1.3l.498-.36a2.034 2.034 0 0 1 2.832.454c.297.411.472.877-.023 1.229L1.646 4.494c-.488.354-.88.044-1.174-.36A2.035 2.035 0 0 1 .925 1.3Z"
      fill="#fff"
    />
    <circle cx={8} cy={10.444} r={7.372} stroke="#fff" strokeWidth={1.25} />
  </svg>
);

export default SvgComponent;
