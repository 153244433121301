import { RootState } from "@/data/store";

export function selectMaterialsWidth(state: RootState) {
  return state.materials.width;
}

export function selectMaterialsOpen(state: RootState) {
  return state.materials.open;
}

export function selectMaterialsTab(state: RootState) {
  return state.materials.tab;
}

export function selectMaterialsState(state: RootState) {
  return state.materials;
}
