import {
  addMinutes,
  differenceInCalendarDays,
  differenceInMinutes,
  format,
  formatDistanceStrict,
  isAfter,
  isBefore,
  isSameMinute,
  setSeconds,
} from "date-fns";

import { time } from "utils/serverTime";

const dateFormat = "EEE do MMM 'at' h:mm a";
const dateFormatSimple = "do MMM 'at' h:mm a";
const dateFormatFull = "EEEE do MMM 'at' h:mm a zzz";
const dayFormat = "EEE do MMM";

/** Current server synced JS Date. */
export function getCurrentDate() {
  return new Date(time());
}

/** Re-format a JS Date into a standard client format.  */
export function formatDate(date: Date): string {
  return format(date, dateFormat);
}

export function formatDateFull(date: Date): string {
  return format(date, dateFormatFull);
}

/** Re-format a JS Date into a simpler but standard client format. */
export function formatDateSimple(date: Date): string {
  return format(date, dateFormatSimple);
}

/** Pretty date format for just the Day display without time. */
export function formatDay(date: Date): string {
  return format(date, dayFormat);
}

/** Is NOW before the given Datetime?  */
export function isBeforeDate(date: Date): boolean {
  return isBefore(getCurrentDate(), date);
}

/**
 * Human readable datetime comparison between a given DateTime and a Due Date.
 */
export function dueDateComparison(datetime: Date, dueDate: Date) {
  if (differenceInMinutes(datetime, dueDate) <= 0) return "on time";

  // if difference is >= 30 days -> "x days late"
  const daysDifference = differenceInCalendarDays(datetime, dueDate);
  if (daysDifference >= 30) return `${daysDifference} days late`;

  return formatDistanceStrict(datetime, dueDate) + " late";
}

/**
 * Human readable hours and minutes duration given the minutes.
 */
export function hrAndMinDuration(
  minutes: number,
  format = { hr: " hour", min: " minute" },
  delimiter = " and "
) {
  if (minutes <= 0) return `0 mins`;

  const hr = Math.floor(minutes / 60);
  const hrUnit = format.hr + (hr > 1 ? "s" : "");
  const hrText = hr === 0 ? "" : `${hr}${hrUnit}`;

  const min = minutes % 60;
  const minUnit = format.min + (min > 1 ? "s" : "");
  const minText = min === 0 ? "" : `${min}${minUnit}`;

  const hrAndMin = hr !== 0 && min !== 0 ? delimiter : "";

  return `${hrText}${hrAndMin}${minText}`;
}

/**
 * Is the given `date` considered late when compared to `now`.
 * Late is when the minutes of `now` is > minutes of `dueDate`
 *
 * @param date Date to compare NOW with
 * @param now optionally fix NOW to some other Date
 */
export function isLate(date: Date, now: Date = getCurrentDate()) {
  if (isSameMinute(now, date)) return false;
  return isAfter(now, date);
}

/**
 * Compute minutes in a limitted time interval.
 *
 * @param start - start date of the limitted interval
 * @param end - end date of the limitted interval
 * @return number of minutes iff `start` is before `end`. Returns `null` if the
 *   interval is not positive.
 */
export function durationInMinutes(start: Date, end: Date): number | null {
  const duration = differenceInMinutes(end, start);
  if (duration < 0) return null;
  return duration;
}

/**
 * Check if the time limitted interval between `start` and `end` Dates is over
 * the alotted limit.
 *
 * @param start - start date of the limitted interval
 * @param end - end date of the interval
 * @param timeLimit - alotted time limit
 */
export function isOvertime(start: Date, end: Date, timeLimit: number): boolean {
  const duration = durationInMinutes(start, end);
  if (duration === null) return false;
  return duration > timeLimit;
}

/**
 * Get the end `Date` for a time limitted interval.
 *
 * @param start - start Date of the time limitted interval.
 * @param timeLimit - number of minutes in the interval
 */
export function getTimeLimitEndDate(start: Date, timeLimit: number): Date {
  return addMinutes(start, timeLimit);
}

/** Map a Maybe<string> to Maybe<Date>. */
export function coalesceDate(str: string | null): Date | null {
  return str ? new Date(str) : null;
}

/** Round up a given `Date` to the end of next minute. */
export function nextMinute(date: Date) {
  return addMinutes(setSeconds(date, 0), 1);
}
