import { Plugin, PluginKey } from "@tiptap/pm/state";

import { nodeWordCount, selectionWordCount } from "../../core/text";

export const WordCountPluginKey = new PluginKey<WordCountState>("wordCount");

/** Plugin state holding the total and selected word count. */
export interface WordCountState {
  editorId: string;
  total: number;
  selected: number;
}

export interface SetWordCountAction {
  type: "setWordCount";
  total: number;
}

export const WordCountPlugin = (editorId: string) =>
  new Plugin<WordCountState>({
    key: WordCountPluginKey,
    state: {
      init(_, editorState) {
        return {
          editorId,
          selected: selectionWordCount(editorState),
          total: nodeWordCount(editorState.doc),
        };
      },
      apply(tr, state, oldEditorState, newEditorState) {
        const meta = tr.getMeta(WordCountPluginKey) as
          | SetWordCountAction
          | undefined;

        // Update the selected word count if the old selection
        // is not the same as the old selection
        if (oldEditorState.selection !== newEditorState.selection) {
          state.selected = selectionWordCount(newEditorState);
        }
        // listen to meta update to the total word count
        if (meta?.type === "setWordCount" && typeof meta.total === "number") {
          state.total = meta.total;
        }

        return state;
      },
    },
  });
