import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export const MathDeleteIndicatorPluginKey =
  new PluginKey<MathDeleteIndicatorState>("MathDeleteIndicator");

export interface MathDeleteIndicatorState {
  /**
   * math node position that needs to be highlighted for deletion
   * set to -1 to remove the highlight
   */
  highlight: number;
}

/**
 * Plugin that shows visual indicator of math blocks that will get deleted
 * when user click on delete button
 */
export const MathDeleteIndicatorPlugin = () =>
  new Plugin<MathDeleteIndicatorState>({
    key: MathDeleteIndicatorPluginKey,
    state: {
      init() {
        return {
          highlight: -1,
        };
      },
      apply(tr, currentState) {
        const payload = tr.getMeta(MathDeleteIndicatorPluginKey) as
          | MathDeleteIndicatorState
          | undefined;
        if (payload) {
          return payload;
        }
        return currentState;
      },
    },
    props: {
      decorations(editorState) {
        const deleteIndicatorState = this.getState(editorState);
        if (
          deleteIndicatorState?.highlight &&
          deleteIndicatorState.highlight > -1
        ) {
          const decorations = [] as Decoration[];
          decorations.push(
            Decoration.node(
              deleteIndicatorState.highlight,
              deleteIndicatorState.highlight + 1,
              {
                class: "math-predelete",
              }
            )
          );
          return DecorationSet.create(editorState.doc, decorations);
        }
        return DecorationSet.empty;
      },
    },
  });
