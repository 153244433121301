import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { findTable, getCellsMap } from "../utils";

export const TableDeleteIndicatorPluginKey = new PluginKey(
  "TableDeleteIndicator"
);

export interface TableDeleteIndicatorState {
  highlight: boolean;
}

/**
 * Plugin that shows visual indicator of cells that will get deleted
 * when user click on delete button
 */
export const TableDeleteIndicatorPlugin = () =>
  new Plugin<TableDeleteIndicatorState>({
    key: TableDeleteIndicatorPluginKey,
    state: {
      init() {
        return {
          highlight: false,
        };
      },
      apply(tr, currentState) {
        const payload = tr.getMeta(TableDeleteIndicatorPluginKey) as
          | TableDeleteIndicatorState
          | undefined;
        if (payload) {
          return payload;
        }
        return currentState;
      },
    },
    props: {
      decorations(editorState) {
        const deleteIndicatorState = this.getState(editorState);
        if (deleteIndicatorState?.highlight) {
          const table = findTable(editorState.selection);
          const decorations = [] as Decoration[];
          if (table) {
            const cellsMap = getCellsMap(
              editorState.schema,
              editorState.selection
            );
            cellsMap?.cells?.forEach((cell) => {
              decorations.push(
                Decoration.node(cell.pos, cell.pos + cell.node.nodeSize, {
                  class: "cell-predelete",
                })
              );
            });
            return DecorationSet.create(editorState.doc, decorations);
          }
        }
        return DecorationSet.empty;
      },
    },
  });
