import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { findCodeBlockSelection } from "../utils";

/** `PluginKey` for `CodeBlockDeleteIndicator` */
export const CodeBlockDeleteIndicatorPluginKey =
  new PluginKey<CodeBlockDeleteIndicatorState>("CodeBlockDeleteIndicator");

/** State for the `CodeBlockDeleteIndicator` */
export interface CodeBlockDeleteIndicatorState {
  highlight: boolean;
}

/**
 * Plugin to style a `CodeBlock` to indicate that it is being deleted by adding a class to the
 * `CodeBlock`'s `NodeView`
 */
export const CodeBlockDeleteIndicatorPlugin = () =>
  new Plugin<CodeBlockDeleteIndicatorState>({
    key: CodeBlockDeleteIndicatorPluginKey,

    state: {
      init() {
        return {
          highlight: false,
        };
      },
      apply(tr, currentState) {
        const payload = tr.getMeta(CodeBlockDeleteIndicatorPluginKey) as
          | CodeBlockDeleteIndicatorState
          | undefined;
        if (payload) return payload;
        return currentState;
      },
    },

    props: {
      decorations(editorState) {
        const deleteIndicatorState = this.getState(editorState);

        if (deleteIndicatorState?.highlight) {
          const codeBlock = findCodeBlockSelection(editorState.selection);

          const decorations = [] as Decoration[];

          if (codeBlock) {
            decorations.push(
              Decoration.node(
                codeBlock.pos,
                codeBlock.pos + codeBlock.node.nodeSize,
                {
                  class: "code-block-pre-delete",
                }
              )
            );
          }

          return DecorationSet.create(editorState.doc, decorations);
        }

        return DecorationSet.empty;
      },
    },
  });
