import { createSlice } from "@reduxjs/toolkit";
import {
  AssessmentType,
  ExamTiming,
  RequirementsFragment,
  WorkSettingsFragment,
} from "@/generated/graphql";

import {
  getExamReadingTime,
  getExamWritingTime,
  getTimeLimit,
  updateSheet,
} from "./assignment-actions";

export interface AssignmentState {
  type: AssessmentType;
  examTiming: ExamTiming;
  autoSubmission: boolean;
  allowLateResubmission: boolean;
  readingTime: number | null;
  writingTime: number | null;
  timeLimit: number | null;
  timeLimitExtended: boolean;
  lateSubmissionTimeLimit: number | null;
  referencingStyle: string | null;
  /** Exam is closed and no more submissions are accepted. */
  examIsOver: boolean;
}

export const initialState: AssignmentState = {
  type: AssessmentType.Assignment,
  examTiming: ExamTiming.Live,
  autoSubmission: false,
  allowLateResubmission: false,
  readingTime: null,
  writingTime: null,
  timeLimit: null,
  timeLimitExtended: false,
  lateSubmissionTimeLimit: null,
  referencingStyle: null,
  examIsOver: false,
};

// Action payload for the updateSheet action.
export interface HydrateSheetPayload {
  workStartDate: string | null;
  settings: WorkSettingsFragment | null;
  requirements: RequirementsFragment | null;
}

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    markExamAsOver: (state) => {
      state.examIsOver = true;
    },
    onContinueExam: (state) => {
      state.examIsOver = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSheet, (state, action) => {
      const settings = action.payload.settings;
      const reqs = action.payload.requirements;

      if (reqs) {
        const isExam = reqs.assessmentType === AssessmentType.Exam;

        const [readingTime, _readingExt] = getExamReadingTime(action.payload);
        const [writingTime, _writingExt] = getExamWritingTime(action.payload);
        const [timeLimit, timeLimitExtended] = getTimeLimit(action.payload);

        state.type = reqs.assessmentType;
        state.examTiming = reqs.examTiming;
        state.readingTime = readingTime;
        state.writingTime = writingTime;
        state.timeLimit = timeLimit;
        state.timeLimitExtended = timeLimitExtended;
        state.referencingStyle = reqs.referencingStyle;
        state.allowLateResubmission = reqs.allowLateResubmission;

        if (isExam) {
          state.lateSubmissionTimeLimit = reqs.lateSubmissionTimeLimit;
          state.autoSubmission = reqs.enableExamAutoSubmission;
        }
      }

      const workTimeLimit = settings?.timeLimit ?? null;
      if (
        state.timeLimit !== null &&
        workTimeLimit !== null &&
        workTimeLimit > state.timeLimit
      ) {
        state.timeLimit = workTimeLimit;
        state.timeLimitExtended = true;
      }
    });
  },
});

export const { markExamAsOver, onContinueExam } = assignmentSlice.actions;

export const assignmentReducer = assignmentSlice.reducer;
