import { Button, Divider, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { ResultFragment } from "@/generated/graphql";

import feedbackAvailableImg from "../../assets/submission-feedback-available.png";
import { useResultLaunch } from "../../use-result-launch";
import {
  ActionContainer,
  ContentContainer,
  Illustration,
  InfoContainer,
} from "./styles";

export interface ExamFeedbackConfirmationProps {
  assessmentName: string;
  gradingService: string;
  latestResult: ResultFragment;
  userName: string;
}

export const ExamFeedbackConfirmation = ({
  assessmentName,
  gradingService,
  latestResult,
  userName,
}: ExamFeedbackConfirmationProps) => {
  const onViewFeedback = useResultLaunch(latestResult, gradingService);
  const { grade } = latestResult;

  let gradeInfo = "--";
  if (grade?.value !== undefined && grade?.max !== undefined) {
    gradeInfo = `${grade.value}/${grade.max}`;
  } else if (grade?.value !== undefined) {
    gradeInfo = grade.value.toString();
  } else if (grade?.max !== undefined) {
    gradeInfo = `?/${grade?.max}`;
  }

  return (
    <Container>
      <Text kind="displayTwo">Your feedback is available now</Text>

      <Divider />

      <ContentContainer>
        <InfoContainer>
          <div>
            <Text kind="headingFour">{assessmentName}</Text>
            <Text kind="label">Submitted by {userName}</Text>
          </div>

          <ActionContainer>
            <div>
              <Text kind="label" textAlign="center">
                Grade
              </Text>
              <Text kind="headingFour" textAlign="center">
                {gradeInfo}
              </Text>
            </div>

            <Button
              kind="dark"
              fullWidth
              disabled={!onViewFeedback}
              onClick={onViewFeedback}
            >
              View feedback
            </Button>
          </ActionContainer>
        </InfoContainer>

        <Illustration
          src={feedbackAvailableImg}
          alt="An illustration of a person enthutistically reading feedback comments from a document."
        />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
