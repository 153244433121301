import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  markExamAsOver,
  selectLateSubmissionTimeLimit,
} from "@/features/assignment";
import {
  acceptSubmissionDeclaration,
  closeSubmitPrompt,
  declineSubmissionDeclaration,
  selectCanSubmitLateExam,
  selectHasAcceptedSubmissionDeclaration,
  selectHasFinal,
  submit,
} from "@/features/work";
import { AcademicIntegrityAgreement } from "@/ui/shared/AcademicIntegrityPledge";
import { Modal } from "@/ui/shared/Modal";

/**
 * NiceModal connected TimedSubmitModal.
 */
export const ConnectedTimedSubmitModal = NiceModal.create(() => {
  const modal = useModal();
  const dispatch = useAppDispatch();

  const hasFinal = useAppSelector(selectHasFinal);
  const canSubmitLateExam = useAppSelector(selectCanSubmitLateExam);
  const extraSubmissionTime = useAppSelector(selectLateSubmissionTimeLimit);
  const hasAcceptedAgreement = useAppSelector(
    selectHasAcceptedSubmissionDeclaration
  );

  return (
    <TimedSubmitModal
      hasFinal={hasFinal}
      canSubmitLateExam={canSubmitLateExam}
      extraSubmissionTime={extraSubmissionTime}
      isAgreementChecked={hasAcceptedAgreement}
      onUpdateAgreement={(hasAgreed) => {
        if (hasAgreed) return dispatch(acceptSubmissionDeclaration());
        dispatch(declineSubmissionDeclaration());
      }}
      onSubmitNowClick={() => {
        dispatch(submit());
        modal.remove();
      }}
      onSubmitLateClick={() => {
        dispatch(closeSubmitPrompt());
        if (!canSubmitLateExam) {
          dispatch(markExamAsOver());
        }
        modal.remove();
      }}
    />
  );
});

export interface TimedSubmitModalProps {
  /**
   * There is a prior final submission.
   */
  hasFinal: boolean;
  /**
   * Can make a late submission in the current state.
   */
  canSubmitLateExam: boolean;
  /**
   * Number of minutes of late submission allowance. A `null` value means
   * un-bounded limit if `canSubmitLateExam` is true.
   */
  extraSubmissionTime: number | null;
  /**
   * Is the academic integrity agreement checked?.
   */
  isAgreementChecked: boolean;
  /**
   * Callback to update the integrity agreement state.
   */
  onUpdateAgreement: (checked: boolean) => void;
  /**
   * Callback when submit now action is invoked.
   */
  onSubmitNowClick: VoidFunction;
  /**
   * Callback when submit later action is invoked.
   */
  onSubmitLateClick: VoidFunction;
}

/**
 * Modal component which displays submission choices for timed assignments and
 * exams.
 */
export function TimedSubmitModal(props: TimedSubmitModalProps) {
  const {
    hasFinal,
    canSubmitLateExam,
    extraSubmissionTime,
    isAgreementChecked,
    onUpdateAgreement,
    onSubmitLateClick,
    onSubmitNowClick,
  } = props;

  let message = null;
  if (canSubmitLateExam && extraSubmissionTime) {
    message = (
      <>
        Submit what you have now, or continue working. <br />
        You can submit up to {extraSubmissionTime} minutes late, but you may
        incur a penalty. <br />
        After {extraSubmissionTime} minutes you will not be able to submit.
      </>
    );
  } else if (canSubmitLateExam) {
    message = (
      <>
        Submit what you have now, or continue working. <br />
        You can submit late, but you may incur a penalty.
      </>
    );
  } else if (!canSubmitLateExam && hasFinal) {
    message = (
      <>
        {"You’ve"} already made a submission for this exam. <br />
        Do you want to resubmit with your latest changes?
      </>
    );
  }

  return (
    <Modal
      onClose={() => {}}
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
      width={640}
    >
      <Root>
        <Text kind="headingOne">Time's up!</Text>
        <Text kind="paragraph">{message}</Text>
        <AcademicIntegrityAgreement
          withHeader
          withShortenedPledge
          isChecked={isAgreementChecked}
          onUpdateAgreement={onUpdateAgreement}
          showError={!isAgreementChecked}
        />
        <div>
          <ActionButton
            fullWidth
            kind="primary"
            disabled={!isAgreementChecked}
            size="lg"
            iconName="Send"
            iconPosition="right"
            onClick={onSubmitNowClick}
            title={"Submit exam now"}
            aria-label={"Submit exam now"}
          >
            Submit now
          </ActionButton>
          <Spacer spacing={16} />
          {canSubmitLateExam && (
            <LinkButton
              onClick={onSubmitLateClick}
              aria-label="Close modal and keep writing"
            >
              Continue working and submit late
            </LinkButton>
          )}
          {!canSubmitLateExam && hasFinal && (
            <ActionButton
              size="lg"
              title="Close modal and keep previous submission"
              aria-label="Close modal and keep previous submission"
              onClick={onSubmitLateClick}
            >
              No, Keep my previous submission
            </ActionButton>
          )}
        </div>
      </Root>
    </Modal>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;
  padding: 88px 77px;
  max-width: 636px;
`;

const ActionButton = styled(Button)`
  width: 336px;
`;
