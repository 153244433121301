import { IconProps } from "./types";

export const SquareIcon = (props: IconProps) => {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" {...props}>
      <path
        d="M2.93359 6.802C2.93359 6.52586 3.15745 6.302 3.43359 6.302H14.4336C14.7097 6.302 14.9336 6.52586 14.9336 6.802V17.802C14.9336 18.0781 14.7097 18.302 14.4336 18.302H3.43359C3.15745 18.302 2.93359 18.0781 2.93359 17.802V6.802Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93359 7.302V17.302H13.9336V7.302H3.93359ZM3.43359 6.302C3.15745 6.302 2.93359 6.52586 2.93359 6.802V17.802C2.93359 18.0781 3.15745 18.302 3.43359 18.302H14.4336C14.7097 18.302 14.9336 18.0781 14.9336 17.802V6.802C14.9336 6.52586 14.7097 6.302 14.4336 6.302H3.43359Z"
        fill="#7070A9"
      />
      <path
        d="M17.8985 4.10595C17.8985 4.34275 17.8281 4.53155 17.6873 4.67235C17.5529 4.80675 17.3737 4.87395 17.1497 4.87395C16.8425 4.87395 16.6153 4.74595 16.4681 4.48995C16.4169 4.39395 16.3913 4.26275 16.3913 4.09635C16.3913 3.85955 16.4393 3.67715 16.5353 3.54915C16.6761 3.29955 16.9065 3.10115 17.2265 2.95395C17.5465 2.80035 17.9113 2.72355 18.3209 2.72355C18.7689 2.72355 19.1657 2.77795 19.5113 2.88675C19.8953 3.04035 20.1865 3.23555 20.3849 3.47235C20.6601 3.79875 20.7977 4.17315 20.7977 4.59555C20.7977 5.02435 20.6153 5.42115 20.2505 5.78595C20.0777 5.95875 19.7833 6.19235 19.3673 6.48675C19.1881 6.60835 18.8585 6.85795 18.3785 7.23555L17.8505 7.65795H18.4649C19.3737 7.65795 19.8697 7.64195 19.9529 7.60995C20.0105 7.59075 20.0713 7.44355 20.1353 7.16835C20.1353 7.15555 20.1417 7.11395 20.1545 7.04355C20.1673 6.96675 20.1769 6.89635 20.1833 6.83235C20.1961 6.76835 20.2025 6.73315 20.2025 6.72675H20.7977V6.76515C20.7849 6.79075 20.7337 7.16195 20.6441 7.87875C20.5545 8.58915 20.5065 8.95075 20.5001 8.96355V9.00195H16.3817V8.76195C16.3817 8.59555 16.3849 8.50275 16.3913 8.48355C16.4041 8.46435 16.5673 8.29795 16.8809 7.98435C17.1945 7.66435 17.5337 7.31875 17.8985 6.94755C18.2633 6.57635 18.4969 6.32995 18.5993 6.20835C18.9065 5.85635 19.1177 5.51075 19.2329 5.17155C19.2969 4.98595 19.3289 4.79715 19.3289 4.60515C19.3289 4.22755 19.2105 3.92035 18.9737 3.68355C18.7433 3.44035 18.4361 3.31875 18.0521 3.31875C17.9305 3.31875 17.8505 3.32195 17.8121 3.32835C17.6137 3.37315 17.5145 3.40835 17.5145 3.43395C17.5337 3.45315 17.5593 3.47235 17.5913 3.49155C17.7961 3.62595 17.8985 3.83075 17.8985 4.10595Z"
        fill="#3A517F"
      />
    </svg>
  );
};
