import ImageCompressor from "compressorjs";
import { filetypeinfo } from "magic-bytes.js";
import isSvg from "is-svg";

/** Allowd image mime types. */
export const ALLOWED_MIME_TYPES = [
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
];

/**
 * Compress a file or blob.
 *
 * This step is performed on the browser before uploading the file to the server.
 *
 * @param file image file to compress
 * @return promise of a compressed blob
 */
export async function compressImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    new ImageCompressor(file, {
      maxWidth: 2048,
      success: (blobOrFile) => {
        if (isBlob(blobOrFile)) {
          return resolve(blobToFile(blobOrFile));
        }
        return resolve(blobOrFile);
      },
      error: reject,
    });
  });
}

function isBlob(file: File | Blob): file is Blob {
  return (
    (file as File).name === undefined &&
    (file as File).lastModified === undefined
  );
}

export function blobToFile(blob: Blob, options?: FilePropertyBag): File {
  return new File([blob], "someImage", options);
}

/**
 * Read an image blob and create a base64 encoded data URL string.
 *
 * @param file image file blob
 * @return promise of the image data as a `data: URL` string.
 */
export async function readImageFile(file: File): Promise<string> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(file);
  });
}

/**
 * Check if images file's mime type is accepted.
 *
 * @param mime extension
 * @return boolean
 */
export function isAllowedMimeType(mime: string): boolean {
  return ALLOWED_MIME_TYPES.includes(mime);
}

/**
 * Get an image file's mime type.
 */
export async function imageMimes(file: File): Promise<string[]> {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      if (reader.result) {
        const buffer = new Uint8Array(reader.result as ArrayBuffer);
        const filetypes = filetypeinfo(buffer);
        const svg = isSvg(new TextDecoder("utf-8").decode(buffer));
        if (svg) {
          resolve(["image/svg+xml"])
        } else if (filetypes.length > 0) {
          resolve(filetypes.map(({mime}) => mime ?? ""));
        } else {
          resolve([])
        }
      } else {
        resolve([]);
      }
    };
    reader.onerror = () => {
      reject(new Error("Error determining image mime type"));
    };
    reader.readAsArrayBuffer(file);
  });
}

const MAX_SIZE = 800;
const SIZE_STEP = 100;

export async function getImageWidth(file: File): Promise<number> {
  return new Promise((resolve) => {
    const img = new Image();
    const url = URL.createObjectURL(file);
    img.onload = () => {
      const roundedWidth =
        Math.round(img.naturalWidth / SIZE_STEP) * SIZE_STEP ?? SIZE_STEP;
      const width = roundedWidth > MAX_SIZE ? MAX_SIZE : roundedWidth;
      resolve(width);
    };
    img.src = url;
  });
}
