import { Button, Spacer, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import startImg from "@/assets/assessment/time-start.png";
import { formatDateSimple, hrAndMinDuration } from "@/utils/datetime";

interface Props {
  /**
   * Callback to start working.
   */
  onStartWork: () => void;
  /**
   * Number of minutes the student has to write the assignment.
   */
  timeLimit: number;
  /**
   * Date ISO8601 string for the final due date.
   */
  finalDate: string;
  /**
   * Conditionally disable the start button. @default false
   */
  disabled?: boolean;
  /**
   * Has the final due date already passed?. @default false
   */
  hasPassedFinalDue?: boolean;
  /**
   * If the student has limited time to submit. @default false
   */
  hasLimitedTime?: boolean;
}

/**
 * React component rendering a blocking CTA to start a timed assignment within
 * the Instruction Sheet pane.
 */
export function TimedStartPanel(props: Props) {
  const {
    onStartWork,
    timeLimit,
    finalDate,
    disabled = false,
    hasPassedFinalDue = false,
    hasLimitedTime = false,
  } = props;
  const startDisabled = disabled || hasPassedFinalDue;

  return (
    <FlexContainer>
      <FlexContent>
        <Img
          src={startImg}
          alt="An illustration of a woman opening a box. A small rocket ship is launching out of it."
        />
        <Text kind="displayTwo" textAlign="center">
          Ready to start?
        </Text>
        <Text kind="paragraph" textAlign="center">
          This assessment has a time limit of{" "}
          <strong>{hrAndMinDuration(timeLimit)}</strong>. You should complete it{" "}
          before <strong>{formatDateSimple(new Date(finalDate))}</strong>.
          You'll see the instructions as soon as you start the clock. Once it's
          started, the clock won’t stop. Are you ready?
        </Text>
        <Spacer spacing={27} />

        <Button
          kind="primary"
          onClick={onStartWork}
          disabled={startDisabled}
          title={
            startDisabled
              ? "disabled start assessment button. due date has passed."
              : "start assessment button"
          }
        >
          Start the clock
        </Button>
        {hasLimitedTime &&
          (hasPassedFinalDue ? <LateFinal /> : <LimitedTimeWarning />)}
      </FlexContent>
    </FlexContainer>
  );
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  min-width: 360px;
  box-sizing: border-box;
  background: ${colors.wine100};
`;

const FlexContent = styled.div`
  padding: 63px 45px 108px;
  box-sizing: border-box;
  max-width: 800px;
  min-width: 360px;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Img = styled.img`
  display: block;
  width: 100%;
  margin: auto;
`;

/**
 * A component to display to students when there is limited time to the due
 * date. Explains how late submission works with time limits.
 */
const LimitedTimeWarning = () => (
  <Box>
    <Text kind="headingFive">It’s close to the due date</Text>
    <Text kind="system">
      If you start now, your time limit will exceed the final due date and time.
      Here's what you need to know:
    </Text>
    <ul>
      <Text kind="system" as="li">
        You'll still have the <strong>full time limit</strong> to complete your
        work.
      </Text>
      <Text kind="system" as="li">
        You can submit after the final due date, however your submission will be
        marked <strong>late</strong> and penalties may apply.
      </Text>
      <Text kind="system" as="li">
        You can submit <strong>on time</strong> by clicking the submit button
        before the final due date.
      </Text>
      <Text kind="system" as="li">
        Contact you unit coordinator as you may be eligible for an{" "}
        <strong>extension</strong>.
      </Text>
    </ul>
  </Box>
);

/**
 * A component to display to students when the due date has passed and
 * submissions are no longer allowed.
 */
const LateFinal = () => (
  <Box>
    <Text kind="headingFive">The due date has passed</Text>
    <Text kind="system">
      The final due date has passed and submissions are no longer allowed.
      Contact you unit coordinator as you may be eligible for an{" "}
      <strong>extension</strong>.
    </Text>
  </Box>
);

const Box = styled.div`
  width: 100%;
  margin-top: 27px;
  padding: 27px;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.divider};
  border-radius: 5px;
  background: ${colors.red200};
`;
