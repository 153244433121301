import { IconProps } from "./types";

export const Vector4X4Icon = (props: IconProps) => {
  return (
    <svg width="28" height="22" viewBox="0 0 28 22" {...props}>
      <path d="M5.5 2.5H7.5V4.5H5.5V2.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1.5H8.5V5.5H4.5V1.5ZM5.5 2.5V4.5H7.5V2.5H5.5Z"
        fill="#7070A9"
      />
      <path d="M10.5 2.5H12.5V4.5H10.5V2.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 1.5H13.5V5.5H9.5V1.5ZM10.5 2.5V4.5H12.5V2.5H10.5Z"
        fill="#7070A9"
      />
      <path d="M15.5 2.5H17.5V4.5H15.5V2.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 1.5H18.5V5.5H14.5V1.5ZM15.5 2.5V4.5H17.5V2.5H15.5Z"
        fill="#7070A9"
      />
      <path d="M20.5 2.5H22.5V4.5H20.5V2.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 1.5H23.5V5.5H19.5V1.5ZM20.5 2.5V4.5H22.5V2.5H20.5Z"
        fill="#7070A9"
      />
      <path d="M5.5 7.5H7.5V9.5H5.5V7.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 6.5H8.5V10.5H4.5V6.5ZM5.5 7.5V9.5H7.5V7.5H5.5Z"
        fill="#7070A9"
      />
      <path d="M10.5 7.5H12.5V9.5H10.5V7.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 6.5H13.5V10.5H9.5V6.5ZM10.5 7.5V9.5H12.5V7.5H10.5Z"
        fill="#7070A9"
      />
      <path d="M15.5 7.5H17.5V9.5H15.5V7.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 6.5H18.5V10.5H14.5V6.5ZM15.5 7.5V9.5H17.5V7.5H15.5Z"
        fill="#7070A9"
      />
      <path d="M20.5 7.5H22.5V9.5H20.5V7.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 6.5H23.5V10.5H19.5V6.5ZM20.5 7.5V9.5H22.5V7.5H20.5Z"
        fill="#7070A9"
      />
      <path d="M5.5 12.5H7.5V14.5H5.5V12.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 11.5H8.5V15.5H4.5V11.5ZM5.5 12.5V14.5H7.5V12.5H5.5Z"
        fill="#7070A9"
      />
      <path d="M10.5 12.5H12.5V14.5H10.5V12.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 11.5H13.5V15.5H9.5V11.5ZM10.5 12.5V14.5H12.5V12.5H10.5Z"
        fill="#7070A9"
      />
      <path d="M15.5 12.5H17.5V14.5H15.5V12.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 11.5H18.5V15.5H14.5V11.5ZM15.5 12.5V14.5H17.5V12.5H15.5Z"
        fill="#7070A9"
      />
      <path d="M20.5 12.5H22.5V14.5H20.5V12.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 11.5H23.5V15.5H19.5V11.5ZM20.5 12.5V14.5H22.5V12.5H20.5Z"
        fill="#7070A9"
      />
      <path d="M5.5 17.5H7.5V19.5H5.5V17.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 16.5H8.5V20.5H4.5V16.5ZM5.5 17.5V19.5H7.5V17.5H5.5Z"
        fill="#7070A9"
      />
      <path d="M10.5 17.5H12.5V19.5H10.5V17.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 16.5H13.5V20.5H9.5V16.5ZM10.5 17.5V19.5H12.5V17.5H10.5Z"
        fill="#7070A9"
      />
      <path d="M15.5 17.5H17.5V19.5H15.5V17.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 16.5H18.5V20.5H14.5V16.5ZM15.5 17.5V19.5H17.5V17.5H15.5Z"
        fill="#7070A9"
      />
      <path d="M20.5 17.5H22.5V19.5H20.5V17.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 16.5H23.5V20.5H19.5V16.5ZM20.5 17.5V19.5H22.5V17.5H20.5Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.898 2.62009C25.898 2.26111 25.607 1.97009 25.248 1.97009V0.970093C26.1593 0.970093 26.898 1.70882 26.898 2.62009V19.38C26.898 20.2912 26.1593 21.03 25.248 21.03V20.03C25.607 20.03 25.898 19.739 25.898 19.38V2.62009Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.10195 2.62009C2.10195 2.26111 2.39297 1.97009 2.75195 1.97009V0.970093C1.84068 0.970093 1.10195 1.70882 1.10195 2.62009V19.3799C1.10195 20.2912 1.84068 21.0299 2.75195 21.0299V20.0299C2.39297 20.0299 2.10195 19.7389 2.10195 19.3799V2.62009Z"
        fill="#3A517F"
      />
    </svg>
  );
};
