export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4.5H3C2.72386 4.5 2.5 4.72386 2.5 5V15C2.5 15.2761 2.72386 15.5 3 15.5H13C13.2761 15.5 13.5 15.2761 13.5 15V5C13.5 4.72386 13.2761 4.5 13 4.5ZM3 3C1.89543 3 1 3.89543 1 5V15C1 16.1046 1.89543 17 3 17H13C14.1046 17 15 16.1046 15 15V5C15 3.89543 14.1046 3 13 3H3Z"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 17V3H8.5V17H7.5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 9.5L15 9.5L15 10.5L1 10.5L1 9.5Z"
    />
  </>
);
