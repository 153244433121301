import {
  ParagraphSkeleton,
  RequirementsSkeleton,
  SectionLabel,
  SectionSkeleton,
  Spacer,
  Text,
  colors,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

import {
  selectExamTotalTime,
  selectIsLiveExam,
  selectIsWindowExam,
} from "@/features/assignment";
import {
  selectExamEndDate,
  selectExamEndDatePassed,
  selectFinalDate,
  selectTimedWorkNotStarted,
  workStartDatePassed,
} from "@/features/timeline";
import {
  acceptSubmissionDeclaration,
  declineSubmissionDeclaration,
  selectExamNotOpen,
  selectHasAcceptedSubmissionDeclaration,
} from "@/features/work";
import { useRefetch } from "@/graphql/useRefetch";
import { AcademicIntegrityAgreement } from "@/ui/shared/AcademicIntegrityPledge";
import emptyImg from "assets/editing/instructions-empty.png";
import { useAppDispatch, useAppSelector } from "data/hooks";
import {
  AssessmentFragment,
  AssessmentType,
  useSheetQuery,
  useStartMutation,
} from "generated/graphql";
import { formatDateSimple } from "utils/datetime";

import { ConnectedRequirements } from "./ConnectedRequirements";
import { ExamStartPanel } from "./ExamStartPanel";
import { ResourceList } from "./ResourceList";
import { SheetContent } from "./SheetContent";
import { TimedStartPanel } from "./TimedStartPanel";

/** Props for Sheet */
interface Props {
  assessment: AssessmentFragment;
  workId: string;
}

/**
 * Displays blocking `StartPanel` for time limited
 *  assessment that is not yet started. Otherwise, displays
 *  latest instruction sheet.
 */
export function Sheet(props: Props) {
  const { assessment, workId } = props;

  // Querying the full Instruction Sheet with content.
  const {
    loading,
    error,
    data,
    refetch: _refetch,
  } = useSheetQuery({
    variables: { workId },
  });
  const [refetch, refetchLoading] = useRefetch(_refetch);
  const dispatch = useAppDispatch();

  // Work start mutation which will refetch the Sheet once the Work is started.
  const [startWork, { loading: mutationLoading }] = useStartMutation({
    variables: { workId },
    onCompleted: () => {
      refetch();
      dispatch(workStartDatePassed());
    },
  });

  const hasAgreedToSubmissionDeclaration = useAppSelector(
    selectHasAcceptedSubmissionDeclaration
  );
  const finalDate = useAppSelector(selectFinalDate);
  const isLiveExam = useAppSelector(selectIsLiveExam);
  const isWindowExam = useAppSelector(selectIsWindowExam);

  const workNotStarted = useAppSelector(selectTimedWorkNotStarted);
  const examNotOpen = useAppSelector(selectExamNotOpen);
  const examEndDate = useAppSelector(selectExamEndDate);
  const examEndDatePassed = useAppSelector(selectExamEndDatePassed);
  const examTotalMinutes = useAppSelector(selectExamTotalTime);

  // Show loading skeleton on the initial Query Loading, Mutation Loading, and
  // the eventual query refetch loading
  if (loading || mutationLoading || refetchLoading) {
    return <InstructionSkeleton assessmentName={assessment.name} />;
  }

  // IF no sheet has been released or could not be fetched
  if (error || !data?.work.sheet) return <EmptyInstructions />;

  // Sheet has been fetched
  const sheet = data.work.sheet;

  // Timed Assignment Start panel
  if (
    sheet.assessmentType === AssessmentType.Assignment &&
    finalDate &&
    sheet.timeLimit !== null &&
    workNotStarted
  ) {
    return (
      <TimedStartPanel
        onStartWork={() => startWork()}
        finalDate={finalDate}
        timeLimit={sheet.timeLimit}
        disabled={mutationLoading}
      />
    );
  }

  // Flags to control sheet panel rendering
  let showExamLock = false;
  let showSheet = false;

  if (isWindowExam && workNotStarted) {
    if (examNotOpen || examEndDatePassed) {
      showSheet = false;
      showExamLock = false;
    } else {
      showSheet = false;
      showExamLock = true;
    }
  } else if (isLiveExam && examNotOpen) {
    showSheet = false;
    showExamLock = false;
  } else {
    showSheet = true;
    showExamLock = false;
  }

  return (
    <>
      <Spacer spacing={63} />
      <InstructionsTitle kind="displayTwo">{assessment.name}</InstructionsTitle>
      <Spacer spacing={36} />
      <ConnectedRequirements
        referencingStyle={sheet.referencingStyle ?? undefined}
        wordLimit={sheet.wordLimit ?? undefined}
        countReferences={sheet.countReferences ?? undefined}
        weight={sheet.weight ?? undefined}
      />
      <Spacer spacing={45} />
      {examEndDate && showExamLock && (
        <ExamStartPanel
          onStartWork={() => startWork()}
          closeDate={examEndDate}
          totalMinutes={examTotalMinutes}
          disabled={mutationLoading}
        />
      )}
      {showSheet && (
        <>
          <AcademicIntegrityContainer>
            <SectionLabel title="Academic integrity agreement" />
            <AcademicIntegrityAgreement
              isChecked={hasAgreedToSubmissionDeclaration}
              isExam={sheet.assessmentType === AssessmentType.Exam}
              showError={!hasAgreedToSubmissionDeclaration}
              onUpdateAgreement={(hasAgreed) => {
                if (hasAgreed) return dispatch(acceptSubmissionDeclaration());
                return dispatch(declineSubmissionDeclaration());
              }}
            />
          </AcademicIntegrityContainer>

          <Spacer spacing={45} />
          {sheet.rawContent && (
            <>
              <InstructionLabel title="Task" />
              <SheetContent content={sheet.rawContent} />
            </>
          )}
          <Spacer spacing={48} />
          <ResourceList resources={sheet.resources} />
        </>
      )}
      <Spacer spacing={108} />
      <ReleaseDate>
        <Text kind="system" color="shade1">
          <strong>** These instructions</strong> were released on{" "}
          <NoWrap>{formatDateSimple(new Date(sheet.releaseTimestamp))}</NoWrap>
        </Text>
      </ReleaseDate>
      <Spacer spacing={180} />
    </>
  );
}

// A Skeleton loader representing the full Sheet components
const InstructionSkeleton = (props: { assessmentName: string }) => (
  <>
    <Spacer spacing={63} />
    <InstructionsTitle kind="displayTwo">
      {props.assessmentName}
    </InstructionsTitle>
    <Spacer spacing={45} />
    <ReqSkeleton />
    <Spacer spacing={45} />
    <TaskSkeleton />
  </>
);

const AcademicIntegrityContainer = styled.div`
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 34px;
  padding: 0px 45px;
  min-width: 450px;
`;

const InstructionsTitle = styled(Text)`
  max-width: 800px;
  margin: auto;
  padding-left: 45px;
  padding-right: 45px;
  padding-bottom: 18px;
  box-sizing: border-box;
  min-width: 450px;
`;

const ReleaseDate = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  padding: 0 45px;
  box-sizing: border-box;
  margin: auto;
  text-align: center;
`;

const InstructionLabel = styled(SectionLabel)`
  width: 100%;
  max-width: 800px;
  padding: 0 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -27px;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

// A skeleton for the requirements section during load
const ReqSkeleton = () => (
  <Padded>
    <SectionSkeleton />
    <Spacer spacing={27} />
    <RequirementsSkeleton />
  </Padded>
);

// A skeleton for the instruction section during load
const TaskSkeleton = () => (
  <Padded>
    <SectionSkeleton />
    <Spacer spacing={27} />
    <ParagraphSkeleton />
    <Spacer spacing={180} />
  </Padded>
);

const Padded = styled.div`
  padding: 0 45px;
  box-sizing: border-box;
  max-width: 800px;
  min-width: 360px;
  width: 100%;
  margin: auto;
`;

// Empty State
const EmptyInstructions = () => (
  <EmptyContainer>
    <Padded>
      <Img src={emptyImg} alt="An illustration of a man opeing an envelope" />
      <Spacer spacing={36} />
      <Text kind="paragraph" textAlign="center">
        Instructions haven’t been released. Check-in later to find everything
        you need to complete this assignment.
      </Text>
    </Padded>
  </EmptyContainer>
);

const EmptyContainer = styled.div`
  min-height: 100%;
  width: 100%;
  padding-top: 20vh;
  padding-bottom: 108px;
  box-sizing: border-box;
  background: ${colors.wine100};
`;

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: 450px;
  margin: auto;
`;
