import { SpecialIndentationStyle } from "../types";

function coalesceIndentStyle(
  enabledStyles: SpecialIndentationStyle[],
  defaultStyle: SpecialIndentationStyle
) {
  return (style: unknown) =>
    enabledStyles.find((enabledStyle) => enabledStyle === style)
      ? style
      : defaultStyle;
}

/**
 * Curried function to parseSpecialIndent from an element given a list
 * of enabled indentation styles and a default indentation style
 */
export function parseSpecialIndent(
  enabledStyles: SpecialIndentationStyle[],
  defaultStyle: SpecialIndentationStyle
) {
  const getIndentStyle = coalesceIndentStyle(enabledStyles, defaultStyle);
  return (element: HTMLElement) => {
    const { textIndent } = element.style;
    if (!textIndent) {
      const indentData = element.getAttribute("data-indent-special");
      return indentData
        ? getIndentStyle(indentData)
        : getIndentStyle(defaultStyle);
    }
    const indentSize = parseFloat(textIndent);
    if (indentSize > 0) {
      return getIndentStyle("firstLine");
    } else if (indentSize < 0) {
      return getIndentStyle("hanging");
    }
    return defaultStyle;
  };
}
