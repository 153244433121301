/**
 * Values for the `width` attribute of the image node
 */
export enum ImageWidth {
  FitToText = "fitToText",
  Full = "full",
  Original = "original",
  Custom = "custom",
}

/**
 * Values for the `imageAlign` attribute of the image node
 */
export enum ImageAlignment {
  Center = "center",
  Right = "right",
  Left = "left",
}

/**
 * Values for the `textAlign` attribute of the image node
 */
export enum TextAlignment {
  Center = "center",
  Right = "right",
  Left = "left",
}
