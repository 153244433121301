import { __GLOBAL_SESSION_ID } from "client/globals";
import { serialiseWorkEditors } from "@/stores/editors";

import { onCloudSave, onSubmit, onUnsubmit } from "./api";
import { RootState } from "@/data/store";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { getTotalWordCount, hasPendingSave } from "@/stores/editors";
import { selectIsExam } from "@/features/assignment";
import { readLatestSave } from "@/graphql/selectors";
import { SubmissionType } from "generated/graphql";

import {
  selectSaveError,
  selectWorkId,
  selectIsSubmitting,
  selectSubmitError,
  selectSubmitDate,
} from "./selectors";

//////////////////////////////////////////////////////////////////////////////
// Saving                                                                   //
//////////////////////////////////////////////////////////////////////////////

// Payload for the `cloudSave` action creator.
interface CloudSavePayload {
  /** Whether the action is triggered via an editor auto save. */
  isAutoSave: boolean;
}

/**
 * Async Cloud Saving request action using the `Save` graphql mutation.
 */
export const cloudSave = createAsyncThunk<
  any,
  CloudSavePayload,
  { state: RootState }
>(
  "work/save",
  async (_payload, thunkAPI) => {
    // Read the latest save information from the graphql cache
    const latestSave = readLatestSave();

    const state = thunkAPI.getState();
    const workId = state.work.workId;
    const assessmentId = state.work.assessmentId;

    if (!workId || !assessmentId) {
      throw new Error("Student Work not loaded into Redux");
    }

    const metadata = {
      workId,
      assessmentId,
      sessionId: __GLOBAL_SESSION_ID.current,
      prevSaveId: latestSave?.serverId ?? null,
      prevVersionId: latestSave?.version ?? null,
    };
    const doc = serialiseWorkEditors(metadata);

    if (doc) {
      const content = JSON.stringify(doc);
      return onCloudSave(workId, content, metadata);
    }
  },
  {
    // Skip AUTO Cloud Saving when a submission is in progress or has an error.
    condition: (arg, { getState }) => {
      if (arg.isAutoSave && selectSubmitError(getState())) {
        console.log("Skipping auto save due to submit error");
        return false;
      }
      if (arg.isAutoSave && selectIsSubmitting(getState())) {
        console.log("Skipping auto save due to submitting");
        return false;
      }
      return true;
    },
  }
);

//////////////////////////////////////////////////////////////////////////////
// Submission                                                               //
//////////////////////////////////////////////////////////////////////////////

export interface SubmitError {
  kind: "validation" | "network";
  title: string;
  detail?: string;
}

// Payload for action creator `submit`.
interface SubmitPayload {
  // Lock the submission date. @default NOW.
  submissionDate?: string;
  // Select a submission type. @default Final
  submissionType?: SubmissionType;
  // Trigger was an auto-submission. @default false.
  autoSubmission?: boolean;
  // Submission attempt number. @default 1.
  attempt?: number;
}

/**
 * Async Submit request action using the `Submit` graphql mutation.
 */
export const submit = createAsyncThunk<
  any,
  SubmitPayload | undefined,
  { state: RootState; rejectValue: SubmitError }
>(
  "work/submit",
  async (payload, thunkAPI) => {
    // If there are pending saves dispatch a cloud save and wait for it.
    // Auto-cloud-saving will be paused during submitting so we have to do it
    // here.
    const pendingSave = hasPendingSave();
    const saveError = selectSaveError(thunkAPI.getState());
    console.log("Pending Save?", pendingSave, "Save Error?", saveError);
    if (pendingSave || saveError) {
      await thunkAPI.dispatch(cloudSave({ isAutoSave: false }));
    }

    // Read the latest save information from the graphql cache
    const state = thunkAPI.getState();
    const workId = selectWorkId(state);
    const latestSave = readLatestSave();
    const saveId = latestSave?.serverId;

    if (!saveId || !workId) {
      return thunkAPI.rejectWithValue({
        kind: "validation",
        title: "Incomplete work",
      });
    }

    const totalWC = getTotalWordCount();
    if (totalWC < 20) {
      return thunkAPI.rejectWithValue({
        kind: "validation",
        title: "Incomplete work",
        detail: "You must have 20 words or more to submit.",
      });
    }

    const submittedAt = payload?.submissionDate || selectSubmitDate(state);
    const submissionType = payload?.submissionType
      ? payload.submissionType
      : SubmissionType.Final;

    return await onSubmit(workId, saveId, submissionType, submittedAt);
  },
  {
    condition: (_arg, { getState }) => {
      // Don't fire another submission attempt, if another is in progress
      if (selectIsSubmitting(getState())) {
        return false;
      }
      return true;
    },
  }
);

/**
 * Async Unsubmit request action using the `Unsubmit` graphql mutation.
 */
export const unsubmit = createAsyncThunk<
  any,
  UnsubmitPayload,
  { state: RootState; fulfilledMeta: { isExam: boolean } }
>("work/unsubmit", async (payload: UnsubmitPayload, thunkAPI) => {
  const { submissionId } = payload;
  const workId = selectWorkId(thunkAPI.getState());
  if (!workId) {
    throw new Error("Work is not loaded in Redux state");
  }
  const result = await onUnsubmit(workId, submissionId);
  return thunkAPI.fulfillWithValue(result, {
    isExam: selectIsExam(thunkAPI.getState()),
  });
});

// Action payload for `unsubmit`
interface UnsubmitPayload {
  submissionId: string;
}
