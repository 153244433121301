import { NodeRange, Fragment, Slice } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { ReplaceAroundStep } from "@tiptap/pm/transform";

/**
 * indent Sibling of list to become a sublist when a list item is
 * outdented
 * ported from https://github.com/remirror/remirror/blob/9014d98411459968cf5aaac8ec9cf01e8bcd1c85/packages/remirror__extension-list/src/list-command-dedent.ts
 */
export function indentSiblingsOfList(
  tr: Transaction,
  range: NodeRange
): NodeRange {
  const selectedList = range.parent;
  const lastSelectedItem = range.parent.child(range.endIndex - 1);

  const endOfSelectedList = range.end;
  const endOfParentListItem = range.$to.end(range.depth - 1);

  if (endOfSelectedList + 1 < endOfParentListItem) {
    // There are sibling nodes after the selected list, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        endOfSelectedList - 1,
        endOfParentListItem,
        endOfSelectedList + 1,
        endOfParentListItem,
        new Slice(
          Fragment.from(
            selectedList.type.create(null, lastSelectedItem.type.create(null))
          ),
          2,
          0
        ),
        0,
        true
      )
    );
    return new NodeRange(tr.selection.$from, tr.selection.$to, range.depth);
  }

  return range;
}
