import { Editor } from "@tiptap/core";
import { Fragment, Slice } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView, __serializeForClipboard } from "@tiptap/pm/view";

import { patchImages } from "../image-uploader";
import { PasteOrigin } from "./cleaner";
import { saveToClipboard } from "./global-clipboard";

/** Paste plugin configuration */
export interface PastePluginProps {
  /** Unique ID for the editor the plugin is operating in. */
  editorId: string;
  /** Whether to annotate pastes with paste chips or not.   */
  annotatePaste: boolean;
}

/** Severity level for some pasted content */
export enum PasteType {
  allowed = "allowed",
  highlighted = "highlighted",
  locked = "locked",
}

/**
 * Metadata on a processed paste event, which will be emitted as a transaction
 * metadata.
 */
export interface PasteMeta {
  origin: PasteOrigin | string;
  pasteType: PasteType;
  wordCount: number;
  internal: boolean;
  html?: string;
  text?: string;
}

// Paste plugin key
export const PastePluginKey = new PluginKey("paste");

/**
 * Create a paste handling plugin which cleans, records, and annotates external
 * pastes with the paste chip.
 *
 * ## Schema
 *
 * The plugin needs the `pasteChip` and `paragraph` nodes to be present in the
 * schema.
 *
 * ## HTML Cleaning
 *
 * The plugin cleans the incoming HTML strings before its parsed by ProseMirror
 * by providing a function to the `transformPastedHTML` editor prop. The HTML
 * cleaning has specific cases for Google Docs and Microsoft Word to perform
 * some form of HTML normalisation to make it easier for ProseMirror to parse.
 *
 * ## Copy handling
 *
 * To differenciate between internal and external pastes we need to maintain a
 * global clipboard. On a copy event, the plugin will serialise the copied
 * fragment and store it globally. Paste handlers can use this later. The global
 * clipboard stores HTML strings.
 *
 * ## Paste and Drop handling
 *
 * On paste events, the plugin will count the number of words in the parsed
 * slice and determine whether to append an educative paste chip node at the end
 * of the pasted slice. The paste chip is shown for word counts greater than 30
 * and the paste being of external origin.
 *
 * The origin of the paste can be found by the paste event type (drop events) or
 * by comparing the content with the global clipboard.
 *
 * @param props initialisation options
 * @return ProseMirror plugin binding copy, paste and drop events.
 */
export function PastePlugin(editor: Editor, props: PastePluginProps) {
  const { editorId } = props;
  const handleCopyEvents = copyEventHandler(editorId);
  return new Plugin({
    key: PastePluginKey,
    appendTransaction(transactions, _oldState, newState) {
      const pasteTransaction = transactions.find((tr) =>
        tr.getMeta(PastePluginKey)
      );
      if (pasteTransaction) {
        const pasteMeta = pasteTransaction.getMeta(PastePluginKey);
        let newTr;
        if (!pasteMeta.internal) {
          newTr = newTr ?? newState.tr;
          newTr = patchImages(editor, newTr);
        }
        if (newTr) return newTr;
      }
      return undefined;
    },
    props: {
      handleDrop: (_view, event, slice, moved) => {
        const html = (event as DragEvent).dataTransfer?.getData("text/html");
        const text = (event as DragEvent).dataTransfer?.getData("text/plain");

        editor
          .chain()
          .command(({ commands }) => {
            if (!moved) {
              commands.setDropSelection(event as MouseEvent);
            }
            return true;
          })
          .handlePaste(event, moved, slice, html, text)
          .command(({ tr, dispatch }) => {
            dispatch?.(tr.setMeta("uiEvent", "drop"));
            return true;
          })
          .run();
        // let default handler deal with the node(s) move
        if (moved) {
          return false;
        }
        return true;
      },

      // Handle direct paste events
      handlePaste(_view, event, slice) {
        const html = event.clipboardData?.getData("text/html");
        let text = event.clipboardData?.getData("text/plain");
        let pasteSlice = slice;
        const uriList = event.clipboardData?.getData("text/uri-list");
        // Links copied from iOS Safari share button only have the text/uri-list data type
        // ProseMirror don't do anything with this type so we want to make our own open slice
        // with url as text content so link is pasted inline
        if (uriList && !text && !html) {
          text = uriList;
          pasteSlice = new Slice(Fragment.from(editor.schema.text(text)), 1, 1);
        }

        editor
          .chain()
          .handlePaste(event, false, pasteSlice, html, text)
          .command(({ tr, dispatch }) => {
            dispatch?.(tr.setMeta("uiEvent", "paste"));
            return true;
          })
          .run();
        return true;
      },

      // Copy events
      handleDOMEvents: {
        dragstart: handleCopyEvents,
        copy: handleCopyEvents,
        cut: handleCopyEvents,
      },
    },
  });
}

// Create a prosemirror event handler for internal copy events in the editor.
// Copying content inside the editor should store it in the global clipboard for
// Cadmus editors.
export function copyEventHandler(editorId: string) {
  return (view: EditorView, _event: Event) => {
    const { selection } = view.state;
    if (selection.empty) return false;

    const slice = selection.content();
    const { dom, text } = __serializeForClipboard(view, slice);

    if (dom?.innerHTML && saveToClipboard) {
      saveToClipboard({
        origin: editorId,
        content: dom.innerHTML,
      });
    } else if (text && saveToClipboard) {
      saveToClipboard({
        origin: editorId,
        content: text,
      });
    }

    return false;
  };
}
