import { IconProps } from "./types";

export const SufixBracketIcon = (props: IconProps) => {
  return (
    <svg width="30" height="28" viewBox="0 0 30 28" {...props}>
      <path
        d="M1.91113 10.3333C1.91113 10.1492 2.06037 10 2.24447 10H9.5778C9.76189 10 9.91113 10.1492 9.91113 10.3333V17.6667C9.91113 17.8508 9.76189 18 9.5778 18H2.24447C2.06037 18 1.91113 17.8508 1.91113 17.6667V10.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.91113 11V17H8.91113V11H2.91113ZM2.24447 10C2.06037 10 1.91113 10.1492 1.91113 10.3333V17.6667C1.91113 17.8508 2.06037 18 2.24447 18H9.5778C9.76189 18 9.91113 17.8508 9.91113 17.6667V10.3333C9.91113 10.1492 9.76189 10 9.5778 10H2.24447Z"
        fill="#7070A9"
      />
      <path
        d="M15.9111 10.3333C15.9111 10.1492 16.0604 10 16.2445 10H23.5778C23.7619 10 23.9111 10.1492 23.9111 10.3333V17.6667C23.9111 17.8508 23.7619 18 23.5778 18H16.2445C16.0604 18 15.9111 17.8508 15.9111 17.6667V10.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9111 11V17H22.9111V11H16.9111ZM16.2445 10C16.0604 10 15.9111 10.1492 15.9111 10.3333V17.6667C15.9111 17.8508 16.0604 18 16.2445 18H23.5778C23.7619 18 23.9111 17.8508 23.9111 17.6667V10.3333C23.9111 10.1492 23.7619 10 23.5778 10H16.2445Z"
        fill="#7070A9"
      />
      <path
        d="M23.8266 5.518L23.8986 5.5C23.9586 5.5 24.0186 5.5 24.0786 5.5H24.2946C24.3786 5.572 24.5466 5.716 24.7986 5.932C26.9586 7.9 28.0386 10.756 28.0386 14.5C28.0386 15.976 27.8646 17.308 27.5166 18.496C26.9646 20.392 26.0586 21.916 24.7986 23.068C24.7146 23.14 24.6186 23.218 24.5106 23.302C24.4026 23.398 24.3306 23.464 24.2946 23.5H24.0786C23.9346 23.5 23.8386 23.488 23.7906 23.464C23.7546 23.44 23.7366 23.38 23.7366 23.284C23.7486 23.272 23.8146 23.194 23.9346 23.05C25.7946 21.118 26.7246 18.268 26.7246 14.5C26.7246 10.732 25.7946 7.882 23.9346 5.95C23.8146 5.806 23.7486 5.728 23.7366 5.716C23.7366 5.62 23.7666 5.554 23.8266 5.518Z"
        fill="#3A517F"
      />
      <path
        d="M15.9051 23.338C15.9051 23.446 15.7971 23.5 15.5811 23.5H15.3471C15.2631 23.428 15.0951 23.284 14.8431 23.068C12.6831 21.1 11.6031 18.244 11.6031 14.5C11.6031 13.036 11.7771 11.698 12.1251 10.486C12.6771 8.602 13.5831 7.084 14.8431 5.932C14.9271 5.86 15.0231 5.782 15.1311 5.698C15.2391 5.602 15.3111 5.536 15.3471 5.5H15.5811C15.7251 5.5 15.8151 5.518 15.8511 5.554C15.8871 5.59 15.9051 5.626 15.9051 5.662C15.9051 5.71 15.8391 5.8 15.7071 5.932C13.8471 7.9 12.9171 10.756 12.9171 14.5C12.9171 18.244 13.8471 21.1 15.7071 23.068C15.8391 23.2 15.9051 23.29 15.9051 23.338Z"
        fill="#3A517F"
      />
    </svg>
  );
};
