import { createSelector } from "@reduxjs/toolkit";

import {
  selectAllowLateResubmission,
  selectHasAutoSubmission,
  selectIsAssignment,
  selectIsExam,
  selectIsLiveExam,
  selectIsTimedAssignment,
  selectIsWindowExam,
} from "@/features/assignment";
import {
  selectActiveReadingDate,
  selectActiveWritingDate,
  selectExamLateSubmissionDatePassed,
  selectExamStartDatePassed,
  selectFinalDatePassed,
  selectTimedWorkNotStarted,
  selectWorkStartDatePassed,
  selectWritingDatePassed,
} from "@/features/timeline";
import { RootState } from "data/store";
import { getCurrentDate } from "@/utils/datetime";

/** Select the work slice state. */
export const selectWork = (state: RootState) => state.work;

/** Select the loaded work ID. */
export const selectWorkId = (state: RootState) => state.work.workId;

/** Select the loaded assessment ID. */
export const selectAssessmentId = (state: RootState) => state.work.assessmentId;

/** Select if the work session should be locked. */
export const selectSessionLock = (state: RootState) => state.work.sessionLock;

/** Select if the network connection is intact. */
export const selectIsConnected = createSelector(
  selectWork,
  (work) => work.connectionStatus
);

/** Select if the work has an existing final submission. */
export const selectHasFinal = createSelector(
  selectWork,
  (work) => work.hasFinal
);

/** Select if the work has an existing draft submission. */
export const selectHasDraft = createSelector(
  selectWork,
  (work) => work.hasDraft
);

/** Select if the work is currently saving changes. */
export const selectIsSaving = (state: RootState) =>
  selectWork(state).saveLoading;

/** Select if the saving process has an error. */
export const selectSaveError = (state: RootState) =>
  selectWork(state).saveError;

/** Select if the submission preview page should be open. */
export const selectShowSubmitPreview = createSelector(
  selectWork,
  (work) => work.submitPreview
);

/** Select if the submission prompt modal should be shown. */
export const selectShowSubmitPrompt = createSelector(
  selectWork,
  (work) => work.submitPromptOpened !== null
);

export const selectSubmitDate = createSelector(selectWork, (work) => {
  if (work.submitPromptOpened !== null) {
    return work.submitPromptOpened;
  }
  return getCurrentDate().toISOString();
});

export const selectHasAcceptedSubmissionDeclaration = createSelector(
  selectWork,
  ({ hasAcceptedSubmissionDeclaration }) => hasAcceptedSubmissionDeclaration
);

/** Select if a submission request is in-flight. */
export const selectIsSubmitting = createSelector(
  selectWork,
  (work) => work.submitLoading
);

/** Select if the submission attempts are failing with an error. */
export const selectSubmitError = createSelector(
  selectWork,
  (work) => work.submitError
);

export const selectSubmitNetworkError = createSelector(
  selectSubmitError,
  (error) => (error?.kind === "network" ? error : null)
);

export const selectSubmitValidationError = createSelector(
  selectSubmitError,
  (error) => (error?.kind === "validation" ? error : null)
);

/**
 * Selector for checking Cadmus submission rules for exams and assignments.
 */
export const selectCanSubmit = (state: RootState) => {
  // Cannot request submissions in error or locked states.
  if (selectSessionLock(state) || selectSaveError(state)) {
    return false;
  }

  const hasFinal = selectHasFinal(state);

  // Conditions for Exams
  if (selectIsExam(state)) {
    // Cannot submit before the exam is open
    if (!selectExamStartDatePassed(state)) {
      return false;
    }

    if (selectTimedWorkNotStarted(state)) {
      return false;
    }

    // Cannot request submissions during reading time.
    if (selectActiveReadingDate(state) !== null) {
      return false;
    }

    // Cannot submit after the writing/end date IF you have a submission
    if (selectWritingDatePassed(state) && !selectCanSubmitLateExam(state)) {
      return false;
    }
  }

  // Conditions for Timed assignments
  if (selectIsTimedAssignment(state)) {
    // Cannot submit before the work start date
    if (!selectWorkStartDatePassed(state)) {
      return false;
    }

    // No late re-submissions if not allowed
    if (
      selectWritingDatePassed(state) &&
      !selectCanSubmitLateAssignment(state)
    ) {
      return false;
    }
  }

  // Conditions for assignments
  if (selectFinalDatePassed(state) && hasFinal) {
    return false;
  }

  return true;
};

/**
 * Select if late exam submission can be made.
 */
export const selectCanSubmitLateExam = createSelector(
  selectIsExam,
  selectHasAutoSubmission,
  selectExamLateSubmissionDatePassed,
  selectHasFinal,
  (isExam, autoSubmission, lateSubmissionDatePassed, hasFinal) => {
    return isExam && !autoSubmission && !lateSubmissionDatePassed && !hasFinal;
  }
);

/**
 * Select if a late assignment submission can be made.
 */
export const selectCanSubmitLateAssignment = createSelector(
  selectIsAssignment,
  selectAllowLateResubmission,
  selectHasFinal,
  (isAssn, allowLateResubmission, hasFinal) => {
    if (!isAssn) return false;
    if (hasFinal && allowLateResubmission) return true;
    if (hasFinal) return false;
    return true;
  }
);

/**
 * Select if the student can continue editing.
 */
export const selectCanUnsubmit = (state: RootState) => {
  if (selectIsLiveExam(state)) {
    if (selectWritingDatePassed(state)) {
      return false;
    }
  }

  if (selectIsWindowExam(state)) {
    if (selectWritingDatePassed(state)) {
      return false;
    }
  }

  if (selectIsTimedAssignment(state)) {
    if (selectWritingDatePassed(state) || selectFinalDatePassed(state)) {
      if (!selectCanSubmitLateAssignment(state)) {
        return false;
      }
    }
  }

  if (selectFinalDatePassed(state) && !selectCanSubmitLateAssignment(state)) {
    return false;
  }

  return true;
};

/**
 * Select if the student can currently edit their work editors.
 */
export const selectCanEdit = (state: RootState) => {
  if (
    selectSessionLock(state) ||
    selectShowSubmitPrompt(state) ||
    selectSubmitError(state) ||
    selectIsSubmitting(state)
  ) {
    return false;
  }

  if (selectIsExam(state)) {
    if (selectTimedWorkNotStarted(state)) {
      return false;
    }

    if (selectActiveReadingDate(state) !== null) {
      return false;
    }

    if (selectActiveWritingDate(state) !== null) {
      return true;
    }

    if (selectCanSubmitLateExam(state)) {
      return true;
    }

    return false;
  }

  if (selectIsTimedAssignment(state)) {
    if (!selectWorkStartDatePassed(state)) {
      return false;
    }

    if (
      selectWritingDatePassed(state) &&
      !selectCanSubmitLateAssignment(state)
    ) {
      return false;
    }
  }

  return true;
};

/**
 * Select if the exam is not yet open.
 *
 * Returns false if the assignment is not an exam or if the open date has not
 * passed.
 */
export const selectExamNotOpen = createSelector(
  selectIsExam,
  selectExamStartDatePassed,
  (isExam, examStarted) => isExam && !examStarted
);
