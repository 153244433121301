import { SpecialIndentationStyle } from "../types";

/**
 * Curried function to parseLevelIndent an element given a max level
 * of indentation styles and a default indentation style
 */
export function parseLevelIndent(
  maxIndent: number,
  indentStyle: SpecialIndentationStyle = "normal"
) {
  return (element: HTMLElement) => {
    const { marginLeft, textIndent } = element.style;
    let indent = 0;
    const classNames =
      (element.className &&
        typeof element.className === "string" &&
        element.className.split(" ")) ||
      [];
    classNames.forEach((classname) => {
      const result = classname.match(
        /(.*)(indentation|indent|level)([^0-9]*)(\d+)(.*)$/
      );
      const indentation = result && result[4] && parseInt(result[4], 10);
      if (indentation && indentation > 1) {
        if (indentation - 1 <= maxIndent) {
          indent = indentation - 1;
        } else {
          indent = maxIndent;
        }
      }
    });

    if (!indent) {
      const indentData = element.getAttribute("data-indent");
      indent = indentData ? parseInt(indentData, 10) : 0;
    }
    if (marginLeft && !indent) {
      let indentOffset = 0;
      if (indentStyle !== "normal" && textIndent) {
        indentOffset = parseFloat(textIndent);
      }
      const indentation = Math.floor(
        (parseFloat(marginLeft) - indentOffset) / 27
      );
      if (indentation) {
        if (indentation <= maxIndent) {
          indent = indentation;
        } else {
          indent = maxIndent;
        }
      }
    }
    return indent;
  };
}
