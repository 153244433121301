import { Node as PMNode } from "@tiptap/pm/model";
import { TableMap } from "@tiptap/pm/tables";

/**
 * Get DOM heights of each rows of table Element to be used for positioning
 * Row insert button and row selection handle
 */
export const getRowHeights = (tableRef: HTMLTableElement): number[] => {
  const heights: number[] = [];
  if (tableRef.lastChild?.lastChild) {
    const rows = tableRef.lastChild?.lastChild?.childNodes;
    if (!rows) return heights;
    for (let i = 0, count = rows.length; i < count; i++) {
      const row = rows[i] as HTMLTableRowElement;
      if (i === 0 || i === rows.length - 1) {
        heights[i] = row.getBoundingClientRect().height + 1;
      } else {
        heights[i] = row.getBoundingClientRect().height;
      }
    }
  }
  return heights;
};

/**
 * Get DOM width of each collumn of table Element to be used for positioning
 * Column insert button and column selection handle
 */
export const getColWidths = (
  tableRef: HTMLTableElement,
  tableNode: PMNode
): number[] => {
  const widths: number[] = [];
  if (tableNode && tableRef.lastChild?.lastChild?.childNodes) {
    const map = TableMap.get(tableNode);
    const rows = tableRef.lastChild.lastChild.childNodes;
    if (!rows) return widths;
    for (let i = 0; i < map.width; i++) {
      const cells = map.cellsInRect({
        left: i,
        right: i + 1,
        top: 0,
        bottom: map.height,
      });
      const cell = cells.find((cellPos) => {
        const cellRect = map.findCell(cellPos);
        if (cellRect) {
          return cellRect.right - cellRect.left === 1;
        }
      });
      if (cell) {
        const rowIndex = map.findCell(cell)?.top;
        const row = rows[rowIndex] as HTMLTableRowElement;
        if (!row) continue;
        const cells = map.cellsInRect({
          left: 0,
          right: map.width,
          top: rowIndex,
          bottom: rowIndex + 1,
        });
        const colIndex = cells.findIndex((cellPos) => cellPos === cell);
        const columns = row.childNodes;
        const column = columns[colIndex] as HTMLTableCellElement;
        if (!column) continue;
        if (i === 0) {
          widths[i] = column.getBoundingClientRect().width + 1;
        } else {
          widths[i] = column.getBoundingClientRect().width;
        }
      }
    }
  }
  return widths;
};
