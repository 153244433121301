export const colors = {
  // black
  black100: "hsl(225, 15%, 27%)",
  black200: "hsl(225, 30%, 15%)",

  // white
  white100: "hsl(0, 0%, 100%)",
  white200: "hsl(220, 60%, 97%)",

  // grey
  grey50: "hsla(220, 30%, 50%, 0.06)",
  grey100: "hsla(220, 30%, 50%, 0.15)",
  grey200: "hsla(220, 30%, 50%, 0.27)",
  grey300: "hsla(220, 30%, 50%, 0.36)",
  grey400: "hsla(220, 30%, 50%, 0.54)",
  grey500: "hsl(220, 25%, 48%)",
  grey600: "hsl(220, 37%, 36%)",

  // red
  red100: "hsl(0, 60%, 98%)",
  red200: "hsl(0, 85%, 95%)",
  red500: "hsl(350, 70%, 50%)",
  red800: "hsl(350, 80%, 25%)",
  redA500: "hsl(350, 100%, 65%)",

  // orange
  orange100: "hsl(20, 60%, 97%)",
  orange200: "hsl(20, 87%, 94%)",
  orange500: "hsl(25, 85%, 50%)",
  orange800: "hsl(10, 40%, 30%)",
  orangeA500: "hsl(25, 100%, 65%)",

  // yellow
  yellow100: "hsl(50, 60%, 97%)",
  yellow200: "hsl(50, 87%, 94%)",
  yellow500: "hsl(44, 100%, 62%)",
  yellow800: "hsl(40, 100%, 25%)",
  yellowA500: "hsl(55, 90%, 70%)",

  // lime
  lime100: "hsl(85, 30%, 96%)",
  lime200: "hsl(90, 50%, 93%)",
  lime500: "hsl(90, 85%, 35%)",
  lime800: "hsl(80, 60%, 15%)",
  limeA500: "hsl(90, 50%, 65%)",

  // green
  green100: "hsl(130, 35%, 96%)",
  green200: "hsl(130, 46%, 92%)",
  green500: "hsl(135, 80%, 35%)",
  green800: "hsl(130, 90%, 15%)",
  greenA500: "hsl(130, 50%, 60%)",

  // teal
  teal100: "hsl(160, 40%, 96%)",
  teal200: "hsl(160, 46%, 92%)",
  teal500: "hsl(160, 90%, 30%)",
  teal800: "hsl(160, 80%, 20%)",
  tealA500: "hsl(160, 50%, 60%)",

  // cyan
  cyan100: "hsl(185, 40%, 96%)",
  cyan200: "hsl(180, 51%, 92%)",
  cyan500: "hsl(180, 100%, 32%)",
  cyan800: "hsl(180, 90%, 15%)",
  cyanA500: "hsl(180, 55%, 60%)",

  // blue
  blue100: "hsl(210, 55%, 96%)",
  blue200: "hsl(210, 61%, 92%)",
  blue500: "hsl(210, 100%, 45%)",
  blue800: "hsl(220, 80%, 25%)",
  blueA500: "hsl(200, 100%, 50%)",

  // indigo
  indigo100: "hsl(240, 40%, 97%)",
  indigo200: "hsl(240, 58%, 94%)",
  indigo500: "hsl(245, 90%, 65%)",
  indigo800: "hsl(240, 100%, 20%)",
  indigoA500: "hsl(235, 80%, 70%)",

  // purple
  purple100: "hsl(265, 30%, 97%)",
  purple200: "hsl(272, 48%, 94%)",
  purple500: "hsl(280, 100%, 50%)",
  purple800: "hsl(270, 100%, 30%)",
  purpleA500: "hsl(270, 80%, 70%)",

  // pink
  pink100: "hsl(310, 30%, 97%)",
  pink200: "hsl(310, 68%, 94%)",
  pink500: "hsl(310, 100%, 40%)",
  pink800: "hsl(300, 100%, 20%)",
  pinkA500: "hsl(310, 75%, 60%)",

  // wine
  wine100: "hsl(335, 30%, 97%)",
  wine200: "hsl(330, 50%, 93%)",
  wine500: "hsl(330, 80%, 40%)",
  wine800: "hsl(320, 100%, 20%)",
  wineA500: "hsl(340, 100%, 65%)",
};

export type Color = keyof typeof colors;
