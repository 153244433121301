import { mergeAttributes } from "@tiptap/core";

import {
  htmlToDOMOutputSpec,
  htmlparserConverter,
} from "../../../core/utilities/htmlToProsemirror";
import { BlockMathBase } from "./block-math-base";

/**
 * Server side Block level atomic math nodes to display equation
 */
export const BlockMathServer = BlockMathBase.extend({
  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
      convertLatexToMarkup: (latex: string) => latex,
    };
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "math-block-wrapper math-node",
      }),
      ...htmlToDOMOutputSpec(
        this.options.convertLatexToMarkup(node.attrs.equation),
        htmlparserConverter
      ),
      [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: "math-block-caption",
        }),
      ],
    ];
  },
});
