export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.61721 16.9241L2 16.001V16H2.5V17H2C1.86441 17 1.73512 16.973 1.61721 16.9241ZM13.5 17V16H14L14.3828 16.9241C14.2649 16.973 14.1356 17 14 17H13.5ZM15 4.5H14V4L14.9241 3.61721C14.973 3.73512 15 3.86441 15 4V4.5ZM2.5 3H2C1.86441 3 1.73512 3.02699 1.61721 3.07588L2 3.99896V4H2.5V3ZM1.07588 16.3828L1.99896 16H2L2 15.5H1V16C1 16.1356 1.02699 16.2649 1.07588 16.3828ZM1 14.5H2V13.5H1V14.5ZM1 12.5H2V11.5H1V12.5ZM1 10.5H2V9.5H1V10.5ZM1 8.5H2V7.5H1V8.5ZM1 6.5H2V5.5H1V6.5ZM1 4.5H2V4L1.07588 3.61721C1.02699 3.73512 1 3.86441 1 4V4.5ZM3.5 3V4H4.5V3H3.5ZM5.5 3V4H6.5V3H5.5ZM7.5 3V4H8.5V3H7.5ZM9.5 3V4H10.5V3H9.5ZM11.5 3V4H12.5V3H11.5ZM13.5 3V4H14L14.3828 3.07588C14.2649 3.02699 14.1356 3 14 3H13.5ZM15 5.5H14V6.5H15V5.5ZM15 7.5H14V8.5H15V7.5ZM15 9.5H14V10.5H15V9.5ZM15 11.5H14V12.5H15V11.5ZM15 13.5H14V14.5H15V13.5ZM15 15.5H14V16L14.9241 16.3828C14.973 16.2649 15 16.1356 15 16V15.5ZM12.5 17V16H11.5V17H12.5ZM10.5 17V16H9.5V17H10.5ZM8.5 17V16H7.5V17H8.5ZM6.5 17V16H5.5V17H6.5ZM4.5 17V16H3.5V17H4.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 3.5V3H8.5V3.5H7.5ZM7.5 5.5V4.5H8.5V5.5H7.5ZM7.5 7.5V6.5H8.5V7.5H7.5ZM7.5 9.5V8.5H8.5V9.5H7.5ZM7.5 11.5V10.5H8.5V11.5H7.5ZM7.5 13.5V12.5H8.5V13.5H7.5ZM7.5 15.5V14.5H8.5V15.5H7.5ZM7.5 17V16.5H8.5V17H7.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 9.5L15 9.5L15 10.5L14.5 10.5L14.5 9.5ZM12.5 9.5L13.5 9.5L13.5 10.5L12.5 10.5L12.5 9.5ZM10.5 9.5L11.5 9.5L11.5 10.5L10.5 10.5L10.5 9.5ZM8.5 9.5L9.5 9.5L9.5 10.5L8.5 10.5L8.5 9.5ZM6.5 9.5L7.5 9.5L7.5 10.5L6.5 10.5L6.5 9.5ZM4.5 9.5L5.5 9.5L5.5 10.5L4.5 10.5L4.5 9.5ZM2.5 9.5L3.5 9.5L3.5 10.5L2.5 10.5L2.5 9.5ZM1 9.5L1.5 9.5L1.5 10.5L1 10.5L1 9.5Z"
    />
    <path d="M15 3L15 17L13.5 17L13.5 3L15 3Z" />
  </>
);
