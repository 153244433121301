import { useState } from "react";
import { Icon } from "@vericus/cadmus-icons";
import {
  ControlButton,
  ControlButtonText,
  Dropdown,
  MenuDivider,
  Switch,
  Text,
  colors,
  levels,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

import {
  hasAlternateColor,
  hasHeaderColumn,
  hasHeaderRow,
  isSelectionInsideTable,
  tableColor,
} from "../utils";
import { FormatRowColor } from "./FormatRowColor";
import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table Menu Dropdown for header row/column & alternating row color
 */
export function FormatMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const [open, setOpen] = useState(false);
  if (!isSelectionInsideTable(editor.state.selection)) return null;
  return (
    <>
      <Dropdown
        open={open}
        position="bottom-right"
        content={<FormatDropDownContent editor={editor} />}
        onOuterClick={() => setOpen(false)}
      >
        <StyledControlButton
          withDownCaret
          iconName="PaintRoller"
          aria-label="Format Table"
          onClick={() => setOpen(!open)}
        >
          Format
        </StyledControlButton>
      </Dropdown>
      <GroupDivider />
    </>
  );
}

const StyledControlButton = styled(ControlButton)`
  ${ControlButtonText} {
    min-width: 50px;
    display: inline-flex;
    align-items: center;
    color: ${colors.grey500};
    font-weight: 600;
  }
`;

function FormatDropDownContent(props: TableMenuItemComponentProps) {
  const { editor } = props;
  return (
    <Menu>
      <FormatMenuItem key="HeaderRow" aria-label="toggle header row">
        <Icon iconName="HeaderRow" />
        <Text kind="system">Header Row</Text>
        <FormatMenuButton>
          <Switch
            onChange={() => editor.commands.toggleHeaderRow()}
            checked={hasHeaderRow(editor.state)}
          />
        </FormatMenuButton>
      </FormatMenuItem>
      <FormatMenuItem key="HeaderColumn" aria-label="toggle header column">
        <Icon iconName="HeaderColumn" />
        <Text kind="system">Header Column</Text>
        <FormatMenuButton>
          <Switch
            onChange={() => editor.commands.toggleHeaderColumn()}
            checked={hasHeaderColumn(editor.state)}
          />
        </FormatMenuButton>
      </FormatMenuItem>
      <MenuDivider />
      <FormatRowAlternateColor editor={editor} />
    </Menu>
  );
}

function FormatRowAlternateColor(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const alternate = hasAlternateColor(editor.state);
  const activeColor = tableColor(editor.state);
  return (
    <>
      <FormatMenuItem key="AlternateRow" aria-label="alternate row colour">
        <Icon iconName="Paint" />
        <Text kind="system">Alternating row colour</Text>
        <FormatMenuButton>
          <Switch
            checked={alternate}
            onChange={() => editor.commands.toggleTableAlternateColor()}
          />
        </FormatMenuButton>
      </FormatMenuItem>
      {alternate && (
        <FormatRowColor activeColor={activeColor} editor={editor} />
      )}
    </>
  );
}

const Menu = styled.div`
  width: 236px;
  background: white;
  padding: 9px 0px;
  margin: 0;
  max-height: 360px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.background.default};
  box-sizing: border-box;

  box-shadow: ${levels.three};
`;

const FormatMenuButton = styled.div`
  height: 100%;
  margin-left: auto;
  display: inline-flex;
  gap: 12px;
`;

const FormatMenuItem = styled.div.attrs({
  role: "menuitem",
  tabindex: "0",
})`
  display: flex;
  height: 32px;
  flex-grow: 1;
  align-items: center;
  gap: 12px;
  margin: 0 9px;
  justify-content: space-between;
  fill: ${colors.grey500};
  color: ${colors.grey500};
  ${Text} {
    color: ${colors.grey500};
  }
`;
