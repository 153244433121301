import styled from "styled-components";

import { colors } from "../styles";
import { Text } from "../Text";
import { DEFAULT_PALETTE } from "./constants";

export interface ColorPickerProps {
  /** Currently selected color to outline */
  value?: string;
  /** Handler for changing color */
  onChange?: (color: string) => void;
  /** Color picker menu heading */
  title?: string;
  /** Default color choice presented as a reset */
  defaultColor?: string;
  /** Label for the default color */
  defaultLabel?: string;
  /** Colour pallette */
  pallete?: string[];
  /** ID attached to the `title` for aria-labelledby use. */
  labelId?: string;
}

/**
 * Component for a swatch based Color Picker.
 */
export function ColorPicker(props: ColorPickerProps) {
  const {
    pallete = DEFAULT_PALETTE,
    value,
    onChange,
    title,
    defaultColor,
    defaultLabel,
    labelId,
  } = props;

  return (
    <PickerRoot>
      {title && (
        <Text kind="headingSix" as="span" id={labelId}>
          {title}
        </Text>
      )}
      <ColorButtons>
        {pallete.map((color, index) => (
          <ColorButton
            key={index}
            color={color}
            selected={value === color}
            onClick={() => onChange?.(color)}
          />
        ))}
      </ColorButtons>
      {defaultColor && (
        <>
          <PickerDivider />
          <DefaultItem onClick={() => onChange?.(defaultColor)}>
            <ColorButton
              color={defaultColor}
              selected={value === defaultColor}
            />
            {defaultLabel && (
              <>
                <DefaultText>{defaultLabel}</DefaultText>
                <DefaultNote>(default)</DefaultNote>
              </>
            )}
          </DefaultItem>
        </>
      )}
    </PickerRoot>
  );
}

const PickerRoot = styled.div`
  width: 200px;
  padding: 8px 12px;
  margin: 0;
  box-sizing: border-box;
`;

const PickerDivider = styled.div`
  display: block;
  height: 1px;
  margin: 16px 0px;
  background: ${(p) => p.theme.divider};
  border: none;
`;

const ColorButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 6px;
`;

const ColorButton = styled.button<{ color: string; selected?: boolean }>`
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  box-sizing: content-box;

  cursor: pointer;

  border: 3.2px solid
    ${(p) => (p.color === "transparent" ? colors.grey200 : "white")};
  background: ${(p) => p.color};
  outline: ${(p) => (p.selected ? `1px solid ${p.theme.text.accent}` : "none")};

  &:hover,
  &:focus,
  &:active {
    border-color: #e6eaf2;
  }
`;

const DefaultItem = styled.div`
  display: flex;
  align-items: center;
`;

const DefaultText = styled.span`
  margin: 6px;
`;

const DefaultNote = styled.span`
  color: ${(p) => p.theme.text.shade2};
  font-weight: 400;
  font-style: italic;
`;
