import { ClipboardCleaner } from "./clipboard-cleaner";

export class GoogleDocsClipboardCleaner extends ClipboardCleaner {
  // Convert an existing node to a different node, if needed.
  protected convertNode(node: Node) {
    const element = node as HTMLElement;
    // Replace  nodes with other nodes to not change the number of child nodes
    // <b style="font-weight:normal;">...</b> => <span>...</span>
    if (element.tagName === "B" && element.style.fontWeight === "normal") {
      return this.neutralizeInlineNode(node);
    }
    return node;
  }

  // Replace any type of inline node with a span node.

  private neutralizeInlineNode(node: Node) {
    const newNode = document.createElement("span");
    while (node.firstChild) {
      newNode.appendChild(node.firstChild);
    }
    node.parentNode?.replaceChild(newNode, node);

    return newNode as Node;
  }
}
