import { IconProps } from "./types";

export const PowerIcon = (props: IconProps) => {
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" {...props}>
      <path
        d="M5 8.5C5 8.22386 5.22386 8 5.5 8H16.5C16.7761 8 17 8.22386 17 8.5V19.5C17 19.7761 16.7761 20 16.5 20H5.5C5.22386 20 5 19.7761 5 19.5V8.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9V19H16V9H6ZM5.5 8C5.22386 8 5 8.22386 5 8.5V19.5C5 19.7761 5.22386 20 5.5 20H16.5C16.7761 20 17 19.7761 17 19.5V8.5C17 8.22386 16.7761 8 16.5 8H5.5Z"
        fill="#7070A9"
      />
      <path
        d="M19 4.05C19 4.02239 19.0224 4 19.05 4H22.95C22.9776 4 23 4.02239 23 4.05V7.95C23 7.97761 22.9776 8 22.95 8H19.05C19.0224 8 19 7.97761 19 7.95V4.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 3H22.95C23.5299 3 24 3.4701 24 4.05V7.95C24 8.5299 23.5299 9 22.95 9H19.05C18.4701 9 18 8.5299 18 7.95V4.05C18 3.4701 18.4701 3 19.05 3ZM19.05 4C19.0224 4 19 4.02239 19 4.05V7.95C19 7.97761 19.0224 8 19.05 8H22.95C22.9776 8 23 7.97761 23 7.95V4.05C23 4.02239 22.9776 4 22.95 4H19.05Z"
        fill="#7070A9"
      />
    </svg>
  );
};
