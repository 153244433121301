import { Extension, textblockTypeInputRule } from "@tiptap/core";
import { Plugin } from "@tiptap/pm/state";
import { keyName } from "w3c-keyname";

/**
 * Extension to add global custom input rules and keyboard shortcuts
 * that are not set inside the tiptap's default extensions.
 */
export const Shortcuts = Extension.create({
  name: "shortcuts",
  addKeyboardShortcuts() {
    return {
      "Mod-/": ({ editor }) => editor.commands.setParagraph(),
      "Mod-Alt-5": ({ editor }) => editor.commands.toggleBlockquote(),
      "Mod-Alt-s": ({ editor }) => editor.commands.toggleStrike(),
    };
  },
  addInputRules() {
    return [
      // Paragraph text input rule
      textblockTypeInputRule({
        find: new RegExp("^/\\s$"),
        type: this.editor.schema.node("paragraph").type,
      }),
    ];
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleKeyDown(_view, event) {
            const name = keyName(event);
            if (name === "Tab") {
              event.stopPropagation();
              event.preventDefault();
            }
            return false;
          },
        },
      }),
    ];
  },
});
