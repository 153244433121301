/**
 * Render data-indent attribute based on the `indentLevel` node attrs
 * and given `maxIndentation`.
 */
export function renderLevelIndent(maxIndentation: number) {
  return (attributes: Record<string, any>) => {
    const level = attributes.indentLevel ?? 0;
    const indentLevel = level < maxIndentation ? level : maxIndentation - 1;
    return {
      "data-indent": indentLevel,
    };
  };
}
