import { AppStartListening } from "@/data/listenerMiddleware";

import {
  examEndDatePassed,
  examLateSubmissionDatePassed,
  examStartDatePassed,
  finalDatePassed,
  readingDatePassed,
  writingDatePassed,
} from "@/features/timeline";

import { createDateListener } from "./create-date-listener";

export function startTimelineDateListeners(startListening: AppStartListening) {
  // Notify when the exam start date passes
  createDateListener(
    (timeline) => timeline.examStartDate,
    (listenerAPI) => listenerAPI.dispatch(examStartDatePassed())
  )(startListening);

  // Notify when reading date passes
  createDateListener(
    (timeline) => timeline.readingDate,
    (listenerAPI) => listenerAPI.dispatch(readingDatePassed())
  )(startListening);

  // Notify when the exam end date passes
  createDateListener(
    (timeline) => timeline.examEndDate,
    (listenerAPI) => listenerAPI.dispatch(examEndDatePassed())
  )(startListening);

  // Notify when writing date passes
  createDateListener(
    (timeline) => timeline.writingDate,
    (listenerAPI) => listenerAPI.dispatch(writingDatePassed())
  )(startListening);

  // Notify when the final due date passes
  createDateListener(
    (timeline) => timeline.finalDate,
    (listenerAPI) => listenerAPI.dispatch(finalDatePassed())
  )(startListening);

  // Notify when the exam's late submission allowed date passes
  createDateListener(
    (timeline) => timeline.examLateSubmissionDate,
    (listenerAPI) => listenerAPI.dispatch(examLateSubmissionDatePassed())
  )(startListening);
}
