import * as Sentry from "@sentry/react";
import {
  CadmusEditorContent,
  useCadmusEditor,
  useControlledEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { useManualPreset } from "@vericus/cadmus-manual";

import { useBodyEditor } from "@/stores/editors/editorVars";

export function BodyEditorContent() {
  const editor = useBodyEditor();
  const openManualPreset = useManualPreset() as any;
  useControlledEditor(editor);
  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent
        editor={editor}
        onOpenManualPreset={openManualPreset}
      />
    </Sentry.ErrorBoundary>
  );
}

export function BodyEditorContentReadOnly() {
  const body = useBodyEditor();
  const content = body?.getHTML() ?? null;
  const editor = useCadmusEditor(
    {
      editorId: "body-readonly",
      content,
      editable: false,
    },
    [content]
  );
  return <CadmusEditorContent editor={editor} />;
}
