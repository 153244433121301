import { Icon, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useAppSelector } from "@/data/hooks";

import { selectIsConnected, selectSaveError } from "../../work-slice";

export function ConnectedConnectionIndicator() {
  const saveError = useAppSelector(selectSaveError);
  const connected = useAppSelector(selectIsConnected);
  return <ConnectionIndicator connected={connected && !saveError} />;
}

interface Props {
  connected: boolean;
}

/**
 * Network connection indicator showing the connection level between Cadmus
 * servers and the client.
 */
export function ConnectionIndicator(props: Props) {
  const { connected } = props;
  return (
    <Root connected={connected}>
      <Icon
        iconName={connected ? "Wifi" : "WifiDisabled"}
        style={{ height: 21 }}
      />
    </Root>
  );
}

const Root = styled.div<{ connected: boolean }>`
  height: 21px;
  padding: 4px;
  margin-top: 2px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  fill: ${(p) => (p.connected ? colors.grey500 : colors.grey400)};
`;
