export enum PasteOrigin {
  DOCS = "Google Docs",
  WORD = "Microsoft Word",
  VSCODE = "Visual Studio Code",
  PROSEMIRROR = "prosemirror",
  UNKNOWN = "Unknown",
}

export function htmlPasteOrigin(doc: Document): PasteOrigin {
  // For Google Docs
  const body = doc.getElementsByTagName("body")[0];
  const html = doc.getElementsByTagName("html")[0];
  const firstB = body.querySelector("b");
  const firstElement = body.firstElementChild;
  if (html.hasAttribute("xmlns:w")) {
    return PasteOrigin.WORD;
  } else if (firstB?.id?.startsWith("docs-internal-guid")) {
    return PasteOrigin.DOCS;
  } else if (firstElement?.hasAttribute("data-pm-slice")) {
    return PasteOrigin.PROSEMIRROR;
  } else {
    return PasteOrigin.UNKNOWN;
  }
}
