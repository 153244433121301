import {
  BracketedFractionIcon,
  CoproductIcon,
  DDotIcon,
  DerivativeIcon,
  DotIcon,
  DoubleIntegralIcon,
  FractionIcon,
  IntegralIcon,
  IntersectionIcon,
  LimitIcon,
  NiceFractionIcon,
  NotElementOfIcon,
  PartialDerivativeIcon,
  ProductIcon,
  SumIcon,
  TripleIntegralIcon,
  UnionIcon,
} from "../icons";
import { MathCommand } from "./types";

export const plus: MathCommand = {
  text: "+",
  label: "plus",
  mathLiveCommand: "+",
};
export const minus: MathCommand = {
  text: "-",
  label: "minus",
  mathLiveCommand: "-",
};
export const multiply: MathCommand = {
  text: "×",
  label: "multiply",
  mathLiveCommand: "\\times",
};
export const divide: MathCommand = {
  text: "÷",
  label: "divide",
  mathLiveCommand: "\\div",
};
export const plusOrMinus: MathCommand = {
  text: "±",
  label: "plus or minus",
  mathLiveCommand: "\\pm",
};
export const minusOrPlus: MathCommand = {
  text: "∓",
  label: "minus or plus",
  mathLiveCommand: "\\mp",
};
export const dotProduct: MathCommand = {
  text: "⋅",
  label: "dot product",
  mathLiveCommand: "\\cdot",
};
export const fraction: MathCommand = {
  label: "fraction",
  renderChildren: () => <FractionIcon />,
  mathLiveCommand: "\\frac{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\frac{x}{y}",
};
export const niceFraction: MathCommand = {
  label: "nice fraction",
  renderChildren: () => <NiceFractionIcon />,
  mathLiveCommand: "\\nicefrac{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\nicefrac{x}{y}",
  tooltipLabel: "fraction",
};
export const bracketedFraction: MathCommand = {
  label: "bracketed fraction",
  renderChildren: () => <BracketedFractionIcon />,
  mathLiveCommand: "\\left(\\frac{\\placeholder{}}{\\placeholder{}}\\right)",
  tooltip: false,
};
export const limitFunction: MathCommand = {
  label: "limit function",
  renderChildren: () => <LimitIcon />,
  mathLiveCommand: "\\lim_{\\placeholder{}\\to \\placeholder{}}",
  tooltipCommand: "\\lim_{x \\to y}",
};
export const derivativeFunction: MathCommand = {
  label: "derivative function",
  renderChildren: () => <DerivativeIcon />,
  mathLiveCommand: "\\frac{\\differentialD\\placeholder{}}{\\differentialD x}",
  tooltipCommand: "\\frac{dy}{dx}",
  tooltipLabel: "derivative",
};
export const partialDerivativeFunction: MathCommand = {
  label: "partial derivative function",
  renderChildren: () => <PartialDerivativeIcon />,
  mathLiveCommand: "\\pdiff{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\pdiff{}{}",
  tooltipLabel: "partial derivative",
};
export const derivativeSymbols: MathCommand = {
  label: "derivative symbols",
  text: "∂",
  mathLiveCommand: "\\partial",
  tooltipLabel: "derivative",
};
export const integral: MathCommand = {
  label: "integral",
  renderChildren: () => <IntegralIcon />,
  mathLiveCommand: "\\int_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\int",
};
export const doubleIntegral: MathCommand = {
  label: "double integral",
  renderChildren: () => <DoubleIntegralIcon />,
  mathLiveCommand:
    "\\iint_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\iint",
};
export const tripleIntegral: MathCommand = {
  label: "triple integral",
  renderChildren: () => <TripleIntegralIcon />,
  mathLiveCommand:
    "\\iiint_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\iiint",
};
export const contourIntegral: MathCommand = {
  label: "contour integral",
  text: "∮",
  mathLiveCommand: "\\oint",
  kind: "size1",
};
export const del: MathCommand = {
  label: "del",
  text: "∇",
  mathLiveCommand: "\\nabla",
};
export const setDifference: MathCommand = {
  label: "set difference",
  text: "∖",
  mathLiveCommand: "\\setminus",
};
export const dot: MathCommand = {
  label: "dot",
  renderChildren: () => <DotIcon />,
  mathLiveCommand: "\\dot{\\placeholder{}}",
  tooltipLabel: "derivative",
  tooltipCommand: "\\dot",
};
export const ddot: MathCommand = {
  label: "ddot",
  renderChildren: () => <DDotIcon />,
  mathLiveCommand: "\\ddot{\\placeholder{}}",
  tooltipLabel: "partial derivative",
  tooltipCommand: "\\ddot",
};
export const summation: MathCommand = {
  label: "summation",
  renderChildren: () => <SumIcon />,
  mathLiveCommand: "\\sum_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\sum",
};
export const coproduct: MathCommand = {
  label: "coproduct",
  renderChildren: () => <CoproductIcon />,
  mathLiveCommand:
    "\\coprod_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\coprod",
};
export const product: MathCommand = {
  label: "product",
  renderChildren: () => <ProductIcon />,
  mathLiveCommand:
    "\\prod_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\prod",
};
export const union: MathCommand = {
  label: "union",
  renderChildren: () => <UnionIcon />,
  mathLiveCommand:
    "\\bigcup_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\bigcup",
};
export const intersection: MathCommand = {
  label: "intersection",
  renderChildren: () => <IntersectionIcon />,
  mathLiveCommand:
    "\\bigcap_{\\placeholder{}}^{\\placeholder{}}{\\placeholder{}}",
  tooltipCommand: "\\bigcap",
};
export const unionSymbol: MathCommand = {
  label: "union symbol",
  text: "∪",
  mathLiveCommand: "\\cup",
  tooltipLabel: "union",
};
export const intersectionSymbol: MathCommand = {
  label: "intersection symbol",
  text: "∩",
  mathLiveCommand: "\\cap",
  tooltipLabel: "intersection",
};
export const subset: MathCommand = {
  label: "subset",
  text: "⊂",
  mathLiveCommand: "\\subset",
  kind: "ams",
};
export const superset: MathCommand = {
  label: "superset",
  text: "⊃",
  mathLiveCommand: "\\supset",
  kind: "ams",
};
export const subsetEq: MathCommand = {
  label: "subset;equal to",
  text: "⊆",
  mathLiveCommand: "\\subseteq",
  kind: "ams",
};
export const supersetEq: MathCommand = {
  label: "superset;equal to",
  text: "⊇",
  mathLiveCommand: "\\supseteq",
  kind: "ams",
};
export const subsetNotEq: MathCommand = {
  label: "subset of;not equal to",
  text: "⊊",
  mathLiveCommand: "\\subsetneq",
  kind: "ams",
};
export const supersetNotEq: MathCommand = {
  label: "superset of;not equal to",
  text: "⊋",
  mathLiveCommand: "\\supsetneq",
  kind: "ams",
};
export const notSuperset: MathCommand = {
  label: "not superset",
  text: "⊉",
  mathLiveCommand: "\\nsupseteq",
  kind: "ams",
};
export const notSubset: MathCommand = {
  label: "not subset",
  text: "⊈",
  mathLiveCommand: "\\nsubseteq",
  kind: "ams",
};
export const disjointUnion: MathCommand = {
  label: "disjoint union",
  text: "⊔",
  mathLiveCommand: "\\sqcup",
  kind: "ams",
};
export const or: MathCommand = {
  label: "or",
  text: "∨",
  mathLiveCommand: "\\lor",
  kind: "ams",
};
export const and: MathCommand = {
  label: "and",
  text: "∧",
  mathLiveCommand: "\\land",
  kind: "ams",
};
export const elementOf: MathCommand = {
  label: "element of",
  text: "∈",
  mathLiveCommand: "\\in",
  kind: "ams",
};
export const notElementOf: MathCommand = {
  label: "not an element of",
  renderChildren: () => <NotElementOfIcon />,
  mathLiveCommand: "\\notin",
};
export const symmetricDifference: MathCommand = {
  label: "symmetric difference",
  text: "⊖",
  mathLiveCommand: "\\ominus",
  kind: "ams",
};
export const directSum: MathCommand = {
  label: "direct sum",
  text: "⊕",
  mathLiveCommand: "\\oplus",
  kind: "ams",
};
export const tensorProduct: MathCommand = {
  label: "tensor product",
  text: "⊗",
  mathLiveCommand: "\\otimes",
  kind: "ams",
};
export const emptySet: MathCommand = {
  label: "empty set",
  text: "∅",
  mathLiveCommand: "\\varnothing",
  kind: "ams",
};
