import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Alert, Button, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { LoadingQuill } from "@/components/loading-quill";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import {
  clearError,
  selectIsSubmitting,
  selectSubmitNetworkError,
  selectSubmitValidationError,
} from "@/features/work";
import { Modal } from "@/ui/shared/Modal";

interface Props {
  /**
   * Callback to manually retry the current submit request, if allowed.
   */
  onRetry?: () => void;

  /**
   * The modal is for an un-submission request.
   */
  isUnsubmit?: boolean;

  /**
   * The modal is triggered via an auto-submission.
   */
  autoSubmission?: boolean;
}

/**
 * Stateful Modal which tracks the submit async action.
 */
export const SubmitStatusModal = NiceModal.create((props: Props) => {
  const modal = useModal();

  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector(selectIsSubmitting);
  const networkErr = useAppSelector(selectSubmitNetworkError);
  const validationErr = useAppSelector(selectSubmitValidationError);
  const onDismissError = () => {
    dispatch(clearError());
    modal.hide();
  };

  return (
    <SubmitStatusModalContent
      {...props}
      visible={modal.visible}
      isSubmitting={isSubmitting}
      hasNetworkError={!!networkErr}
      hasValidationError={!!validationErr}
      onDismissError={onDismissError}
    />
  );
});

// Inner modal props
interface ModalProps extends Props {
  /** Modal visibility state. */
  visible: boolean;
  /** Submit request is in-flight. */
  isSubmitting: boolean;
  /**
   * The modal is triggered via an auto-submission.
   */
  autoSubmission?: boolean;
  /** Submit request has a network error. */
  hasNetworkError?: boolean;
  /** Submit request failed due to client-side validation */
  hasValidationError?: boolean;
  /**
   * Callback to dismiss submission errors.
   */
  onDismissError?: () => void;
}

// Inner modal content
export function SubmitStatusModalContent(props: ModalProps) {
  const {
    onRetry,
    visible,
    isSubmitting,
    autoSubmission = false,
    hasNetworkError = false,
    hasValidationError = false,
    isUnsubmit = false,
    onDismissError,
  } = props;

  const loadingMessage = isUnsubmit ? "Preparing..." : "Submitting...";

  let content = null;

  if (hasNetworkError && autoSubmission) {
    content = (
      <Root>
        <Text kind="headingOne">Time's up!</Text>
        <Alert severity="warning" title="Your writing time has ended">
          Please keep this window open. Your work will be auto submitted once
          you are reconnected to the internet. Contact your teacher if you’re
          unable to connect to the internet again.
        </Alert>
      </Root>
    );
  } else if (hasNetworkError) {
    content = (
      <Root>
        <Text kind="headingOne">Unable to submit</Text>
        <Alert severity="error" title="Network disruption">
          Please check your internet connection. Your work will be auto
          submitted once you are reconnected to the internet.
        </Alert>
        <div>
          <SubmitButton
            kind="primary"
            size="lg"
            iconName="Send"
            iconPosition="right"
            disabled={!onRetry || isSubmitting}
            onClick={onRetry}
          >
            {!onRetry ? "Retrying submission..." : "Retry now"}
          </SubmitButton>
        </div>
      </Root>
    );
  } else if (hasValidationError) {
    content = (
      <Root>
        <Text kind="headingOne">Unable to submit</Text>
        <Alert severity="warning" title="Incomplete Work">
          You must have 20 words or more to submit
        </Alert>
        <div>
          {onDismissError && (
            <SubmitButton
              kind="primary"
              size="lg"
              disabled={isSubmitting}
              onClick={onDismissError}
            >
              Continue Working
            </SubmitButton>
          )}
        </div>
      </Root>
    );
  } else {
    content = (
      <Root>
        <LoadingQuill message={loadingMessage} />
      </Root>
    );
  }

  return (
    <Modal
      visible={visible}
      onClose={() => {}}
      withCloseButton={false}
      closeOnEscape={false}
      closeOnClickOutside={false}
    >
      {content}
    </Modal>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 32px;

  padding: 88px 48px;
  box-sizing: border-box;
  width: 636px;
  max-width: 98vw;
  max-height: 80vh;
  min-height: 438px;
`;

const SubmitButton = styled(Button)`
  width: 336px;
`;
