export default (
  <>
    <path
      d="M0.466073 0.669948V0.672626C0.202715 0.723518 -0.00468088 0.983639 8.03613e-05 1.25594V18.7519C8.03613e-05 19.0559 0.274892 19.3333 0.575429 19.3333H15.424C15.7253 19.3333 16 19.056 16 18.7506V5.22679C15.9946 5.13766 15.9693 5.04956 15.9266 4.97159L11.5113 0.705685C11.454 0.68381 11.3927 0.671757 11.3312 0.669227H0.574995C0.539286 0.665804 0.501782 0.666525 0.466073 0.669948Z"
      fill="#035AC4"
    />
    <rect
      x="2.50085"
      y="5.79492"
      width="10.9983"
      height="1.2"
      rx="0.6"
      fill="white"
    />
    <rect
      x="2.50085"
      y="8.79492"
      width="10.9983"
      height="1.2"
      rx="0.6"
      fill="white"
    />
    <rect
      x="2.50085"
      y="11.7949"
      width="10.9983"
      height="1.2"
      rx="0.6"
      fill="white"
    />
    <rect
      x="2.50085"
      y="14.7949"
      width="6.00303"
      height="1.2"
      rx="0.6"
      fill="white"
    />
  </>
);
