import { SectionLabel, Spacer, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { ResourceFragment } from "generated/graphql";

import { ResourceListItem } from "./ResourceListItem";

interface ResourcesReadOnlyProps {
  resources: ResourceFragment[];
}

/**
 * React component rendering the resources as part of the Instruction Sheet
 * pane.
 */
export function ResourceList({ resources }: ResourcesReadOnlyProps) {
  if (resources.length === 0) {
    return null;
  }
  return (
    <Container>
      <SectionLabel title="Resources" />
      <Spacer spacing={27} />
      <CardList>
        {resources.map((resource) => (
          <ResourceListItem key={resource.id} resource={resource}>
            {Boolean(resource.notes) && (
              <PaddedText kind="system">{resource.notes}</PaddedText>
            )}
          </ResourceListItem>
        ))}
      </CardList>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  margin: auto;
  padding: 0 45px;
  box-sizing: border-box;
`;

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const PaddedText = styled(Text)`
  padding: 12px 20px;
  color: ${colors.black100};
  background-color: ${colors.white100};
  overflow: hidden;
  overflow-wrap: break-word;
`;
