import { Extension, findChildren, getNodeType } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockTextVisibility: {
      /**
       * set hideText attribute of a block that can have it to true which parent is at parentNodePos
       */
      hideText: (parentNodePos: number, blockType: string) => ReturnType;
      /**
       * set hideText attribute of block that can have it to false which parent is at parentNodePos
       */
      showText: (parentNodePos: number, blockType: string) => ReturnType;
      /**
       * toggle hideText attribute of block that can have it which parent is at parentNodePos
       */
      toggleShowText: (parentNodePos: number, blockType: string) => ReturnType;
    };
  }
}

export interface BlockTextVisibilityOptions {
  types: string[];
}

/**
 * Editor Extension to hide Block(s) without deleting the Block(s)
 * Mainly used for Caption like node such as tableCaption
 */
export const BlockTextVisibility = Extension.create({
  name: "blockTextVisibility",
  addOptions() {
    return {
      types: [],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          hideText: {
            default: null,
            parseHTML: (element) =>
              element.style.display === "none" ||
              element.getAttribute("data-cadmus-hidden") === "true",
            renderHTML: (attributes) => {
              if (attributes.hideText) {
                return {
                  style: `display: none;`,
                  "data-cadmus-hidden": true,
                  contenteditable: false,
                };
              }
              return {};
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      hideText:
        (parentNodePos, blockType) =>
        ({ tr, state }) => {
          const { doc } = state;
          const parentNode = doc.nodeAt(parentNodePos);
          const nodeType = getNodeType(blockType, state.schema);
          if (parentNode && nodeType) {
            const nodePos = findChildren(
              parentNode,
              (node) => node.type === nodeType
            )[0];
            if (nodePos) {
              tr.setNodeMarkup(
                parentNodePos + nodePos.pos + 1,
                undefined,
                Object.assign({}, nodePos.node.attrs, {
                  hideText: true,
                })
              );
              if (nodePos.node.attrs.hideText) return false;
              return true;
            }
          }
          return false;
        },
      showText:
        (parentNodePos, blockType) =>
        ({ tr, state }) => {
          const { doc } = state;
          const parentNode = doc.nodeAt(parentNodePos);
          const nodeType = getNodeType(blockType, state.schema);
          if (parentNode && nodeType) {
            const nodePos = findChildren(
              parentNode,
              (node) => node.type === nodeType
            )[0];
            if (nodePos) {
              tr.setNodeMarkup(
                parentNodePos + nodePos.pos + 1,
                undefined,
                Object.assign({}, nodePos.node.attrs, {
                  hideText: false,
                })
              );
              return true;
            }
          }
          return false;
        },
      toggleShowText:
        (parentNodePos, blockType) =>
        ({ tr, state }) => {
          const { doc } = state;
          const parentNode = doc.nodeAt(parentNodePos);
          const nodeType = getNodeType(blockType, state.schema);
          if (parentNode && nodeType) {
            const nodePos = findChildren(
              parentNode,
              (node) => node.type === nodeType
            )[0];
            if (nodePos) {
              tr.setNodeMarkup(
                parentNodePos + nodePos.pos + 1,
                undefined,
                Object.assign({}, nodePos.node.attrs, {
                  hideText: !nodePos.node.attrs.hideText,
                })
              );
              return true;
            }
          }
          return false;
        },
    };
  },
});
