import { useEffect, useReducer } from "react";
import { DependencyList } from "react";
import { Content, Editor, useEditor } from "@tiptap/react";

import CadmusKit, { CadmusKitOptions } from "../extensions/cadmus-kit";

/** All the options needed to configure the Cadmus Editor. */
export interface CadmusEditorOptions extends CadmusKitOptions {
  content: Content;
  editable?: boolean;
  autofocus?: boolean;
}

/**
 * Cadmus specific wrapper for `useEditor` to initialise a TipTap `Editor`
 * instance in a React hook.
 *
 * @see https://www.tiptap.dev/api/editor
 *
 * @param opts all options needed to configure the Cadmus extensions and the editor.
 */
export function useCadmusEditor(
  opts: CadmusEditorOptions,
  deps: DependencyList = []
): Editor | null {
  const { content, editable, autofocus, ...other } = opts;
  return useEditor(
    {
      extensions: [CadmusKit.configure(other)],
      content,
      editable: editable ?? true,
      autofocus: autofocus ?? false,
    },
    deps
  );
}

/**
 * Initialise a Cadmus Editor with pre-configured extensions and settings. This
 * is the non-hooks version of `useCadmusEditor`.
 */
export function createCadmusEditor(opts: CadmusEditorOptions): Editor {
  const { content, editable, autofocus, ...other } = opts;
  return new Editor({
    extensions: [CadmusKit.configure(other)],
    content,
    editable: editable ?? true,
    autofocus: autofocus ?? false,
    parseOptions: {
      preserveWhitespace: "full",
    },
  });
}

/**
 * A Hook which updates on every transaction in the `editor`.
 *
 * Use this if the given `editor` instance is initialised outside a React tree,
 * but the subsequent React tree must re-render on state changes within the
 * `editor`.
 */
export function useControlledEditor(editor: Editor | null) {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (editor) {
      editor.on("transaction", forceUpdate);
      return () => {
        editor.off("transaction", forceUpdate);
      };
    }
    return undefined;
  });
  return editor;
}

function useForceUpdate() {
  return useReducer(() => ({}), {})[1] as () => void;
}
