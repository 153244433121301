import { IconProps } from "./types";

export const ExponentialIcon = (props: IconProps) => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" {...props}>
      <path
        d="M8.44153 10.722C8.98753 10.3233 9.65053 10.124 10.4305 10.124C10.7945 10.124 11.0112 10.1283 11.0805 10.137C11.7999 10.241 12.3502 10.5227 12.7315 10.982C13.1215 11.4413 13.3165 12.061 13.3165 12.841C13.3165 12.9883 13.2732 13.088 13.1865 13.14C13.1519 13.1573 12.4715 13.166 11.1455 13.166H9.16953V13.283C9.16953 13.959 9.30386 14.4617 9.57253 14.791C9.8412 15.1117 10.2745 15.298 10.8725 15.35C10.9245 15.3587 10.9982 15.363 11.0935 15.363C11.4142 15.363 11.7002 15.2807 11.9515 15.116C12.2115 14.9513 12.3935 14.7303 12.4975 14.453C12.5409 14.3577 12.5842 14.3013 12.6275 14.284C12.6709 14.258 12.7662 14.245 12.9135 14.245C13.1822 14.245 13.3165 14.3143 13.3165 14.453C13.3165 14.4963 13.3122 14.5267 13.3035 14.544C13.1649 15.0033 12.8702 15.3673 12.4195 15.636C11.8995 15.9307 11.3232 16.078 10.6905 16.078C9.75453 16.078 8.99186 15.8527 8.40253 15.402C7.82186 14.9427 7.46653 14.3187 7.33653 13.53C7.3192 13.3913 7.31053 13.2397 7.31053 13.075C7.31053 12.061 7.68753 11.2767 8.44153 10.722ZM11.8735 12.412C11.7782 11.32 11.3319 10.774 10.5345 10.774C10.3005 10.774 10.0795 10.8303 9.87153 10.943C9.6722 11.047 9.52486 11.1857 9.42953 11.359C9.29086 11.5843 9.20853 11.9223 9.18253 12.373V12.542H11.8735V12.412Z"
        fill="#3A517F"
      />
      <path
        d="M15.25 6.05C15.25 6.02239 15.2724 6 15.3 6H19.2C19.2276 6 19.25 6.02239 19.25 6.05V9.95C19.25 9.97761 19.2276 10 19.2 10H15.3C15.2724 10 15.25 9.97761 15.25 9.95V6.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3 5H19.2C19.7799 5 20.25 5.4701 20.25 6.05V9.95C20.25 10.5299 19.7799 11 19.2 11H15.3C14.7201 11 14.25 10.5299 14.25 9.95V6.05C14.25 5.4701 14.7201 5 15.3 5ZM15.3 6C15.2724 6 15.25 6.02239 15.25 6.05V9.95C15.25 9.97761 15.2724 10 15.3 10H19.2C19.2276 10 19.25 9.97761 19.25 9.95V6.05C19.25 6.02239 19.2276 6 19.2 6H15.3Z"
        fill="#7070A9"
      />
    </svg>
  );
};
