import styled, { ThemedStyledProps, css } from "styled-components";

import { TooltipPosition, tooltip } from "./mixins/Tooltip";
import { CuiTheme } from "./styles";
import { FocusAnimation, easeOutCirc } from "./styles/animations";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Make the icon a solid colour */
  solid?: boolean;

  /** Selected state */
  selected?: boolean;

  /**
   * Button shape
   * @default 'circle'
   */
  shape?: "circle" | "square";

  /**
   * Tooltip position
   * @default bottom
   */
  tooltipPosition?: TooltipPosition;

  /**
   * adds left margin in px
   * @default 0
   */
  marginLeft?: number;

  /**
   * adds right margin in px
   * @default 0
   */
  marginRight?: number;
}

/**
 * A flat icon button with no text.
 *
 * IconButtons are typically used for secondary or tertiary actions — or where
 * subtly, discretion and space saving measures are required in the UI.
 *
 * **Note:** Not to be confused with a `ControlButton`, which is a special
 * variant of the IconButton, designed specifically for an editor toolbar.
 *
 * _props extends `button` attributes_
 */
export const IconButton = styled.button<Props>`
  width: 36px;
  height: 36px;
  border-radius: ${(p) => (p.shape === "square" ? "2px" : "50%")};

  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : 0)};
  margin-right: ${(p) => (p.marginRight ? `${p.marginRight}px` : 0)};

  /* prevent button from growing or shrinking when flexed by a parent */
  flex: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  position: relative;

  /* A mixin to inject button fill styles */
  ${buttonFill}

  border: none;
  background: transparent;

  cursor: pointer;
  outline: 0;
  transition: background 0.2s ${easeOutCirc}, fill 0.2s ${easeOutCirc};

  &:not(:disabled) {
    /* The :before pseudo-element creates a focus and ping outline*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-radius: ${(p) => (p.shape === "square" ? "2px" : "50%")};
      width: 100%;
      height: 100%;
    }

    &:focus:before {
      ${FocusAnimation};
    }

    &:hover,
    &:focus {
      background: ${(p) => p.theme.background.action1};
    }

    /* Darken the background on-cLick */
    &:active,
    &[aria-expanded="true"] {
      background: ${(p) => p.theme.background.action2};
      transition: background 0.1s ${easeOutCirc};
    }
  }

  &:disabled {
    opacity: 0.54;
  }

  /** Adding an :after psuedo element as tooltip with ariaLabel */
  ${(p) => p["aria-label"] && tooltip(p["aria-label"], p.tooltipPosition)}
`;

function buttonFill(props: ThemedStyledProps<Props, CuiTheme>) {
  let fill = props.theme.text.shade1;
  if (props.solid) {
    fill = props.theme.text.heading;
  } else if (props.selected) {
    fill = props.theme.text.accent;
  }
  return css`
    fill: ${fill};
  `;
}
