type Ref<T> = { current: T | null };

/**
 * Global tenant name reference, set on first page load, from the URL.  There
 * can only be ONE constant tenant per client instance.
 */
export const __GLOBAL_TENANT: Ref<string> = {
  current: null,
};

/**
 * Global session ID attached to events. This is parsed from the graphql
 * response to the WorkQuery (the main first-load query).
 * This is refreshed on every page load and is very temporal.
 */
export const __GLOBAL_SESSION_ID: Ref<string> = {
  current: null,
};

/** Global Phoenix in-memory token */
export const __GLOBAL_AUTH_TOKEN: Ref<string> = {
  current: null,
};

/** Global Work ID. */
export const __GLOBAL_WORK_ID: Ref<string> = {
  current: null,
};

/** Global Assessment ID. */
export const __GLOBAL_ASSESSMENT_ID: Ref<string> = {
  current: null,
};
