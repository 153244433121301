import { Editor } from "@tiptap/core";
import { NodeSelection, Plugin } from "@tiptap/pm/state";

import { insertImages } from "../image-uploader";
import { getImageSelection } from "./getImageSelection";

export const ImagePlugin = (editor: Editor) =>
  new Plugin({
    //  Selects image node when selection is inside image node and image has no caption
    appendTransaction: (_transactions, _oldState, newState) => {
      const sel = getImageSelection(newState);
      if (!sel) return null;

      const { node, from } = sel;
      if (node.attrs.hasCaption === false) {
        const nodeSelection = NodeSelection.create(newState.doc, from);
        return newState.tr.setSelection(nodeSelection);
      }

      return null;
    },
    props: {
      handleDOMEvents: {
        paste(view, event): boolean {
          if (!event.clipboardData || !isPastedFile(event)) return false;
          if (event.clipboardData?.getData("text/html")) return false;

          const files = Array.from(event.clipboardData.items)
            .map((dt) => dt.getAsFile())
            .filter((file): file is File => file !== null);

          if (files.length === 0) return false;

          const { tr } = view.state;
          if (!tr.selection.empty) {
            tr.deleteSelection();
          }

          const pos = tr.selection.from;
          const images = files
            .filter((file) => /image/i.test(file.type))
            .map((file) => [file, ""]) as Array<[File, string | undefined]>;
          if (images.length === 0) return false;
          // process files
          insertImages(editor, images, pos);

          return true;
        },
        drop(view, event): boolean {
          if (!isDroppedFile(event)) return false;
          if (event.dataTransfer?.getData("text/html")) return false;

          // filter to only include image files
          const images = getDataTransferFiles(event)
            .filter((file) => /image/i.test(file.type))
            .map((file) => [file, ""]) as Array<[File, string | undefined]>;
          if (images.length === 0) {
            return false;
          }
          // grab the position in the document for the cursor
          const result = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });

          if (result) {
            // process files
            event.preventDefault();
            insertImages(editor, images, result.pos);
            return true;
          }
          return false;
        },
      },
    },
  });

function isPastedFile(event: ClipboardEvent): boolean {
  const { clipboardData } = event;
  if (!clipboardData) return false;
  return clipboardData.types.indexOf("Files") !== -1;
}

function isDroppedFile(event: DragEvent): boolean {
  const { dataTransfer } = event;
  if (!dataTransfer) return false;
  return Array.from(dataTransfer.types).indexOf("Files") !== -1;
}

function getDataTransferFiles(event: any): File[] {
  let dataTransferItemsList = [];

  if (event.dataTransfer) {
    const dt = event.dataTransfer;
    if (dt.files && dt.files.length) {
      dataTransferItemsList = dt.files;
    } else if (dt.items && dt.items.length) {
      // During the drag even the dataTransfer.files is null
      // but Chrome implements some drag store, which is accesible via dataTransfer.items
      dataTransferItemsList = dt.items;
    }
  } else if (event.target && event.target.files) {
    dataTransferItemsList = event.target.files;
  }
  // Convert from DataTransferItemsList to the native Array
  return Array.prototype.slice.call(dataTransferItemsList);
}
