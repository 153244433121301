import { IconProps } from "./types";

export const NotElementOfIcon = (props: IconProps) => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" {...props}>
      <path d="M16.6627 5.68C16.7227 5.56 16.8307 5.5 16.9867 5.5C17.0827 5.512 17.1667 5.554 17.2387 5.626C17.3107 5.698 17.3407 5.776 17.3287 5.86C17.3287 5.92 16.2847 8.818 14.1967 14.554C12.1207 20.278 11.0527 23.2 10.9927 23.32C10.9207 23.44 10.8247 23.5 10.7047 23.5C10.6087 23.5 10.5247 23.464 10.4527 23.392C10.3807 23.332 10.3447 23.254 10.3447 23.158C10.3447 23.038 11.3887 20.11 13.4767 14.374C15.5647 8.626 16.6267 5.728 16.6627 5.68Z" />
      <path d="M9.3457 14.5C9.3457 13.072 9.8257 11.884 10.7857 10.936C11.7577 9.98803 12.9337 9.44203 14.3137 9.29803C14.3257 9.29803 14.4277 9.29803 14.6197 9.29803C14.8117 9.28603 15.0637 9.28003 15.3757 9.28003C15.6877 9.28003 15.9877 9.28003 16.2757 9.28003H18.0577C18.2377 9.36403 18.3277 9.48403 18.3277 9.64003C18.3277 9.76003 18.2497 9.87403 18.0937 9.98203L16.2217 10C14.8897 10.012 14.0677 10.048 13.7557 10.108C12.8557 10.336 12.0877 10.744 11.4517 11.332C10.8157 11.908 10.3897 12.61 10.1737 13.438C10.1017 13.726 10.0657 13.948 10.0657 14.104V14.14H18.0577C18.2377 14.236 18.3277 14.356 18.3277 14.5C18.3277 14.644 18.2377 14.764 18.0577 14.86H10.0657V14.896C10.0657 15.052 10.1017 15.274 10.1737 15.562C10.3897 16.378 10.8157 17.086 11.4517 17.686C12.0997 18.286 12.8617 18.682 13.7377 18.874C14.0617 18.946 14.8897 18.988 16.2217 19H18.0937C18.2497 19.108 18.3277 19.228 18.3277 19.36C18.3277 19.516 18.2377 19.636 18.0577 19.72H16.3117C15.0037 19.72 14.2057 19.696 13.9177 19.648C12.8137 19.468 11.8417 19 11.0017 18.244C10.1737 17.488 9.6517 16.588 9.4357 15.544C9.3757 15.268 9.3457 14.92 9.3457 14.5Z" />
    </svg>
  );
};
