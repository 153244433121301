import { Code as TipTapCode } from "@tiptap/extension-code";
import { MarkType } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";

/**
 * `Code` extension to handle the inline code mark for the editor.
 *
 * The extension extends TipTap's `Code` extension and adds left and right
 * arrow key handlers to assist with exiting the mark
 */
export const Code = TipTapCode.extend({
  excludes: "link",
  priority: 101, // needs to be greater than other inline marks
  addKeyboardShortcuts() {
    return {
      ArrowLeft: () => {
        const state = this.editor.state;
        const { from, to } = state.selection;

        // Create a space to the left if a code mark exists on the right of cursor
        // and there is no code mark under the cursor and there are no
        // nodes to the left.
        if (from >= 1 && from === to) {
          const codeOnRight = isCodeMarkPresent(
            state,
            from + 1,
            to + 1,
            this.type
          );

          const noCodeUnderCursor = !isCodeMarkPresent(
            state,
            from,
            to,
            this.type
          );

          let nothingOnLeft = true;
          state.doc.nodesBetween(from - 1, to - 1, (node) => {
            if (node) nothingOnLeft = false;
          });

          if (codeOnRight && noCodeUnderCursor && nothingOnLeft) {
            return this.editor
              .chain()
              .unsetCode()
              .insertContent(" ")
              .setTextSelection(from - 1)
              .run();
          }
        }

        return false;
      },

      ArrowRight: () => {
        const state = this.editor.state;
        const { from, to } = state.selection;

        // Create a space to the right if a code mark exists on the left of cursor
        // and there is no code mark under the cursor and there are no
        // nodes to the right.
        if (from > 1 && from === to) {
          const codeOnLeft = isCodeMarkPresent(
            state,
            from - 1,
            to - 1,
            this.type
          );

          const noCodeUnderCursor = !isCodeMarkPresent(
            state,
            from,
            to,
            this.type
          );

          let nothingOnRight = true;
          state.doc.nodesBetween(from + 1, to + 1, (node) => {
            if (node) nothingOnRight = false;
          });

          if (codeOnLeft && noCodeUnderCursor && nothingOnRight) {
            return this.editor.chain().unsetCode().insertContent(" ").run();
          }
        }

        return false;
      },
    };
  },
});

/** Checks if a code mark is present on the given range */
const isCodeMarkPresent = (
  state: EditorState,
  from: number,
  to: number,
  type: MarkType
) => {
  let flag = false;
  state.doc.nodesBetween(from, to, (node) => {
    const code = node.marks.find((markItem) => markItem.type === type);
    if (code) flag = true;
  });
  return flag;
};
