import { Editor, findChildren } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";

import { blobToFile } from "./file-utils";
import {
  addErrorPlaceholder,
  setImageUploaderTransactionMeta,
} from "./image-uploader-plugin";
import { insertImages } from "./insertImages";

type QueuedImage = [src: string, pos: number, textContent?: string];

/**
 * Patch images coming from paste by converting them to image
 * upload widget if fetchable & supported mime type otherwise
 * display an image upload error widget
 */
export function patchImages(editor: Editor, tr: Transaction) {
  // get image API base setting from image uploader extension
  const { imageAPIBase } =
    editor.extensionManager.extensions.find(
      (ext) => ext.name === "imageUploader"
    )?.options ?? {};
  // Check if image is hosted by Cadmus
  const isInvalidImage = (node: Node) => {
    if (node.type.name === "image") {
      return !new RegExp(`^${imageAPIBase}`).test(node.attrs.src);
    }
    return false;
  };
  let invalidImages;
  const queuedImages: QueuedImage[] = [];
  // iterate through all images which are not hosted in Cadmus
  // delete them from the pasted content and queue them to be
  // uploaded and inserted at the right position
  while (
    (invalidImages = findChildren(tr.doc, isInvalidImage)) &&
    invalidImages.length !== 0
  ) {
    const firstImage = invalidImages[0];
    const { node, pos } = firstImage;
    const { src } = node.attrs;
    const { textContent } = node;
    // don't try to upload img with `file://` as src
    if (src && !/^file/.test(src)) {
      queuedImages.push([src, pos, textContent]);
    }
    tr.delete(pos, pos + node.nodeSize);
  }
  if (queuedImages.length) {
    setTimeout(() => {
      queuedImages.forEach(([src, pos, textContent]) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
          canvas.width = image.naturalWidth;
          canvas.height = image.naturalHeight;
          context?.drawImage(image, 0, 0);

          canvas.toBlob((blob: Blob | null) => {
            if (blob) {
              const file = blobToFile(blob, {
                type: "image/png",
              });
              insertImages(editor, [[file, textContent]], pos);
            } else {
              setImageUploaderTransactionMeta(editor, [
                addErrorPlaceholder({ id: {}, pos }),
              ]);
            }
          });
        };
        image.onerror = () => {
          setImageUploaderTransactionMeta(editor, [
            addErrorPlaceholder({ id: {}, pos }),
          ]);
        };
        image.src = src;
      });
    }, 0);
  }
  return tr;
}
