export default (
  <>
    <path d="M4.27793 8.51127H6.24668V10.2613H4.27793V8.51127Z" />
    <path d="M6.24668 11.8363H4.27793V13.5863H6.24668V11.8363Z" />
    <path d="M8.01855 8.51127H9.9873V10.2613H8.01855V8.51127Z" />
    <path d="M9.9873 11.8363H8.01855V13.5863H9.9873V11.8363Z" />
    <path d="M11.7592 8.51127H13.7279V10.2613H11.7592V8.51127Z" />
    <path d="M13.7279 11.8363H11.7592V13.5863H13.7279V11.8363Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49043 5.44877H2.12793C1.57565 5.44877 1.12793 5.89649 1.12793 6.44877V16.6488H16.8779V6.44877C16.8779 5.89649 16.4302 5.44877 15.8779 5.44877H14.5154V3.34877H12.9404V5.44877H5.06543V3.34877H3.49043V5.44877ZM2.70293 6.84877V15.2488H15.3029V6.84877H2.70293Z"
    />
  </>
);
