import { IconProps } from "./types";

export const Vector2X3Icon = (props: IconProps) => {
  return (
    <svg width="28" height="24" viewBox="0 0 28 24" {...props}>
      <path
        d="M5 6.5749C5 6.54729 5.02239 6.5249 5.05 6.5249H8.95C8.97761 6.5249 9 6.54729 9 6.5749V10.4749C9 10.5025 8.97761 10.5249 8.95 10.5249H5.05C5.02239 10.5249 5 10.5025 5 10.4749V6.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05 5.5249H8.95C9.5299 5.5249 10 5.995 10 6.5749V10.4749C10 11.0548 9.5299 11.5249 8.95 11.5249H5.05C4.4701 11.5249 4 11.0548 4 10.4749V6.5749C4 5.995 4.4701 5.5249 5.05 5.5249ZM5.05 6.5249C5.02239 6.5249 5 6.54729 5 6.5749V10.4749C5 10.5025 5.02239 10.5249 5.05 10.5249H8.95C8.97761 10.5249 9 10.5025 9 10.4749V6.5749C9 6.54729 8.97761 6.5249 8.95 6.5249H5.05Z"
        fill="#7070A9"
      />
      <path
        d="M12 6.5749C12 6.54729 12.0224 6.5249 12.05 6.5249H15.95C15.9776 6.5249 16 6.54729 16 6.5749V10.4749C16 10.5025 15.9776 10.5249 15.95 10.5249H12.05C12.0224 10.5249 12 10.5025 12 10.4749V6.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 5.5249H15.95C16.5299 5.5249 17 5.995 17 6.5749V10.4749C17 11.0548 16.5299 11.5249 15.95 11.5249H12.05C11.4701 11.5249 11 11.0548 11 10.4749V6.5749C11 5.995 11.4701 5.5249 12.05 5.5249ZM12.05 6.5249C12.0224 6.5249 12 6.54729 12 6.5749V10.4749C12 10.5025 12.0224 10.5249 12.05 10.5249H15.95C15.9776 10.5249 16 10.5025 16 10.4749V6.5749C16 6.54729 15.9776 6.5249 15.95 6.5249H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M19 6.5749C19 6.54729 19.0224 6.5249 19.05 6.5249H22.95C22.9776 6.5249 23 6.54729 23 6.5749V10.4749C23 10.5025 22.9776 10.5249 22.95 10.5249H19.05C19.0224 10.5249 19 10.5025 19 10.4749V6.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 5.5249H22.95C23.5299 5.5249 24 5.995 24 6.5749V10.4749C24 11.0548 23.5299 11.5249 22.95 11.5249H19.05C18.4701 11.5249 18 11.0548 18 10.4749V6.5749C18 5.995 18.4701 5.5249 19.05 5.5249ZM19.05 6.5249C19.0224 6.5249 19 6.54729 19 6.5749V10.4749C19 10.5025 19.0224 10.5249 19.05 10.5249H22.95C22.9776 10.5249 23 10.5025 23 10.4749V6.5749C23 6.54729 22.9776 6.5249 22.95 6.5249H19.05Z"
        fill="#7070A9"
      />
      <path
        d="M5 13.5251C5 13.4975 5.02239 13.4751 5.05 13.4751H8.95C8.97761 13.4751 9 13.4975 9 13.5251V17.4251C9 17.4527 8.97761 17.4751 8.95 17.4751H5.05C5.02239 17.4751 5 17.4527 5 17.4251V13.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05 12.4751H8.95C9.5299 12.4751 10 12.9452 10 13.5251V17.4251C10 18.005 9.5299 18.4751 8.95 18.4751H5.05C4.4701 18.4751 4 18.005 4 17.4251V13.5251C4 12.9452 4.4701 12.4751 5.05 12.4751ZM5.05 13.4751C5.02239 13.4751 5 13.4975 5 13.5251V17.4251C5 17.4527 5.02239 17.4751 5.05 17.4751H8.95C8.97761 17.4751 9 17.4527 9 17.4251V13.5251C9 13.4975 8.97761 13.4751 8.95 13.4751H5.05Z"
        fill="#7070A9"
      />
      <path
        d="M12 13.5251C12 13.4975 12.0224 13.4751 12.05 13.4751H15.95C15.9776 13.4751 16 13.4975 16 13.5251V17.4251C16 17.4527 15.9776 17.4751 15.95 17.4751H12.05C12.0224 17.4751 12 17.4527 12 17.4251V13.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 12.4751H15.95C16.5299 12.4751 17 12.9452 17 13.5251V17.4251C17 18.005 16.5299 18.4751 15.95 18.4751H12.05C11.4701 18.4751 11 18.005 11 17.4251V13.5251C11 12.9452 11.4701 12.4751 12.05 12.4751ZM12.05 13.4751C12.0224 13.4751 12 13.4975 12 13.5251V17.4251C12 17.4527 12.0224 17.4751 12.05 17.4751H15.95C15.9776 17.4751 16 17.4527 16 17.4251V13.5251C16 13.4975 15.9776 13.4751 15.95 13.4751H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M19 13.5251C19 13.4975 19.0224 13.4751 19.05 13.4751H22.95C22.9776 13.4751 23 13.4975 23 13.5251V17.4251C23 17.4527 22.9776 17.4751 22.95 17.4751H19.05C19.0224 17.4751 19 17.4527 19 17.4251V13.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 12.4751H22.95C23.5299 12.4751 24 12.9452 24 13.5251V17.4251C24 18.005 23.5299 18.4751 22.95 18.4751H19.05C18.4701 18.4751 18 18.005 18 17.4251V13.5251C18 12.9452 18.4701 12.4751 19.05 12.4751ZM19.05 13.4751C19.0224 13.4751 19 13.4975 19 13.5251V17.4251C19 17.4527 19.0224 17.4751 19.05 17.4751H22.95C22.9776 17.4751 23 17.4527 23 17.4251V13.5251C23 13.4975 22.9776 13.4751 22.95 13.4751H19.05Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2558 6.53217C26.2558 5.89686 25.7408 5.38184 25.1055 5.38184V4.38184C26.2931 4.38184 27.2558 5.34457 27.2558 6.53217V17.4676C27.2558 18.6552 26.2931 19.6179 25.1055 19.6179V18.6179C25.7408 18.6179 26.2558 18.1029 26.2558 17.4676V6.53217Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74419 6.53217C1.74419 5.89686 2.25922 5.38184 2.89453 5.38184V4.38184C1.70693 4.38184 0.744193 5.34457 0.744193 6.53217V17.4676C0.744193 18.6552 1.70693 19.6179 2.89453 19.6179V18.6179C2.25922 18.6179 1.74419 18.1029 1.74419 17.4676V6.53217Z"
        fill="#3A517F"
      />
    </svg>
  );
};
