import { IconProps } from "./types";

export const Vector3X3Icon = (props: IconProps) => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" {...props}>
      <path
        d="M5 4.15449C5 4.12688 5.02239 4.10449 5.05 4.10449H8.95C8.97761 4.10449 9 4.12688 9 4.15449V8.05449C9 8.08211 8.97761 8.10449 8.95 8.10449H5.05C5.02239 8.10449 5 8.08211 5 8.05449V4.15449Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05 3.10449H8.95C9.5299 3.10449 10 3.57459 10 4.15449V8.05449C10 8.63439 9.5299 9.10449 8.95 9.10449H5.05C4.4701 9.10449 4 8.63439 4 8.05449V4.15449C4 3.57459 4.4701 3.10449 5.05 3.10449ZM5.05 4.10449C5.02239 4.10449 5 4.12688 5 4.15449V8.05449C5 8.08211 5.02239 8.10449 5.05 8.10449H8.95C8.97761 8.10449 9 8.08211 9 8.05449V4.15449C9 4.12688 8.97761 4.10449 8.95 4.10449H5.05Z"
        fill="#7070A9"
      />
      <path
        d="M12 4.15449C12 4.12688 12.0224 4.10449 12.05 4.10449H15.95C15.9776 4.10449 16 4.12688 16 4.15449V8.05449C16 8.08211 15.9776 8.10449 15.95 8.10449H12.05C12.0224 8.10449 12 8.08211 12 8.05449V4.15449Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 3.10449H15.95C16.5299 3.10449 17 3.57459 17 4.15449V8.05449C17 8.63439 16.5299 9.10449 15.95 9.10449H12.05C11.4701 9.10449 11 8.63439 11 8.05449V4.15449C11 3.57459 11.4701 3.10449 12.05 3.10449ZM12.05 4.10449C12.0224 4.10449 12 4.12688 12 4.15449V8.05449C12 8.08211 12.0224 8.10449 12.05 8.10449H15.95C15.9776 8.10449 16 8.08211 16 8.05449V4.15449C16 4.12688 15.9776 4.10449 15.95 4.10449H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M19 4.15449C19 4.12688 19.0224 4.10449 19.05 4.10449H22.95C22.9776 4.10449 23 4.12688 23 4.15449V8.05449C23 8.08211 22.9776 8.10449 22.95 8.10449H19.05C19.0224 8.10449 19 8.08211 19 8.05449V4.15449Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 3.10449H22.95C23.5299 3.10449 24 3.57459 24 4.15449V8.05449C24 8.63439 23.5299 9.10449 22.95 9.10449H19.05C18.4701 9.10449 18 8.63439 18 8.05449V4.15449C18 3.57459 18.4701 3.10449 19.05 3.10449ZM19.05 4.10449C19.0224 4.10449 19 4.12688 19 4.15449V8.05449C19 8.08211 19.0224 8.10449 19.05 8.10449H22.95C22.9776 8.10449 23 8.08211 23 8.05449V4.15449C23 4.12688 22.9776 4.10449 22.95 4.10449H19.05Z"
        fill="#7070A9"
      />
      <path
        d="M5 11.1047C5 11.0771 5.02239 11.0547 5.05 11.0547H8.95C8.97761 11.0547 9 11.0771 9 11.1047V15.0047C9 15.0323 8.97761 15.0547 8.95 15.0547H5.05C5.02239 15.0547 5 15.0323 5 15.0047V11.1047Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05 10.0547H8.95C9.5299 10.0547 10 10.5248 10 11.1047V15.0047C10 15.5846 9.5299 16.0547 8.95 16.0547H5.05C4.4701 16.0547 4 15.5846 4 15.0047V11.1047C4 10.5248 4.4701 10.0547 5.05 10.0547ZM5.05 11.0547C5.02239 11.0547 5 11.0771 5 11.1047V15.0047C5 15.0323 5.02239 15.0547 5.05 15.0547H8.95C8.97761 15.0547 9 15.0323 9 15.0047V11.1047C9 11.0771 8.97761 11.0547 8.95 11.0547H5.05Z"
        fill="#7070A9"
      />
      <path
        d="M12 11.1047C12 11.0771 12.0224 11.0547 12.05 11.0547H15.95C15.9776 11.0547 16 11.0771 16 11.1047V15.0047C16 15.0323 15.9776 15.0547 15.95 15.0547H12.05C12.0224 15.0547 12 15.0323 12 15.0047V11.1047Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 10.0547H15.95C16.5299 10.0547 17 10.5248 17 11.1047V15.0047C17 15.5846 16.5299 16.0547 15.95 16.0547H12.05C11.4701 16.0547 11 15.5846 11 15.0047V11.1047C11 10.5248 11.4701 10.0547 12.05 10.0547ZM12.05 11.0547C12.0224 11.0547 12 11.0771 12 11.1047V15.0047C12 15.0323 12.0224 15.0547 12.05 15.0547H15.95C15.9776 15.0547 16 15.0323 16 15.0047V11.1047C16 11.0771 15.9776 11.0547 15.95 11.0547H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M19 11.1047C19 11.0771 19.0224 11.0547 19.05 11.0547H22.95C22.9776 11.0547 23 11.0771 23 11.1047V15.0047C23 15.0323 22.9776 15.0547 22.95 15.0547H19.05C19.0224 15.0547 19 15.0323 19 15.0047V11.1047Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 10.0547H22.95C23.5299 10.0547 24 10.5248 24 11.1047V15.0047C24 15.5846 23.5299 16.0547 22.95 16.0547H19.05C18.4701 16.0547 18 15.5846 18 15.0047V11.1047C18 10.5248 18.4701 10.0547 19.05 10.0547ZM19.05 11.0547C19.0224 11.0547 19 11.0771 19 11.1047V15.0047C19 15.0323 19.0224 15.0547 19.05 15.0547H22.95C22.9776 15.0547 23 15.0323 23 15.0047V11.1047C23 11.0771 22.9776 11.0547 22.95 11.0547H19.05Z"
        fill="#7070A9"
      />
      <path
        d="M5 18.0549C5 18.0273 5.02239 18.0049 5.05 18.0049H8.95C8.97761 18.0049 9 18.0273 9 18.0549V21.9549C9 21.9825 8.97761 22.0049 8.95 22.0049H5.05C5.02239 22.0049 5 21.9825 5 21.9549V18.0549Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.05 17.0049H8.95C9.5299 17.0049 10 17.475 10 18.0549V21.9549C10 22.5348 9.5299 23.0049 8.95 23.0049H5.05C4.4701 23.0049 4 22.5348 4 21.9549V18.0549C4 17.475 4.4701 17.0049 5.05 17.0049ZM5.05 18.0049C5.02239 18.0049 5 18.0273 5 18.0549V21.9549C5 21.9825 5.02239 22.0049 5.05 22.0049H8.95C8.97761 22.0049 9 21.9825 9 21.9549V18.0549C9 18.0273 8.97761 18.0049 8.95 18.0049H5.05Z"
        fill="#7070A9"
      />
      <path
        d="M12 18.0549C12 18.0273 12.0224 18.0049 12.05 18.0049H15.95C15.9776 18.0049 16 18.0273 16 18.0549V21.9549C16 21.9825 15.9776 22.0049 15.95 22.0049H12.05C12.0224 22.0049 12 21.9825 12 21.9549V18.0549Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.05 17.0049H15.95C16.5299 17.0049 17 17.475 17 18.0549V21.9549C17 22.5348 16.5299 23.0049 15.95 23.0049H12.05C11.4701 23.0049 11 22.5348 11 21.9549V18.0549C11 17.475 11.4701 17.0049 12.05 17.0049ZM12.05 18.0049C12.0224 18.0049 12 18.0273 12 18.0549V21.9549C12 21.9825 12.0224 22.0049 12.05 22.0049H15.95C15.9776 22.0049 16 21.9825 16 21.9549V18.0549C16 18.0273 15.9776 18.0049 15.95 18.0049H12.05Z"
        fill="#7070A9"
      />
      <path
        d="M19 18.0549C19 18.0273 19.0224 18.0049 19.05 18.0049H22.95C22.9776 18.0049 23 18.0273 23 18.0549V21.9549C23 21.9825 22.9776 22.0049 22.95 22.0049H19.05C19.0224 22.0049 19 21.9825 19 21.9549V18.0549Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.05 17.0049H22.95C23.5299 17.0049 24 17.475 24 18.0549V21.9549C24 22.5348 23.5299 23.0049 22.95 23.0049H19.05C18.4701 23.0049 18 22.5348 18 21.9549V18.0549C18 17.475 18.4701 17.0049 19.05 17.0049ZM19.05 18.0049C19.0224 18.0049 19 18.0273 19 18.0549V21.9549C19 21.9825 19.0224 22.0049 19.05 22.0049H22.95C22.9776 22.0049 23 21.9825 23 21.9549V18.0549C23 18.0273 22.9776 18.0049 22.95 18.0049H19.05Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.277 4.15C26.277 3.79101 25.9859 3.5 25.627 3.5V2.5C26.5382 2.5 27.277 3.23873 27.277 4.15V21.9592C27.277 22.8705 26.5382 23.6092 25.627 23.6092V22.6092C25.9859 22.6092 26.277 22.3182 26.277 21.9592V4.15Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.72305 4.15C1.72305 3.79101 2.01406 3.5 2.37305 3.5V2.5C1.46178 2.5 0.723047 3.23873 0.723047 4.15V21.9592C0.723047 22.8705 1.46178 23.6092 2.37305 23.6092V22.6092C2.01406 22.6092 1.72305 22.3182 1.72305 21.9592V4.15Z"
        fill="#3A517F"
      />
    </svg>
  );
};
