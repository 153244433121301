import { Editor } from "@tiptap/core";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

/**
 * Predicate indicating if the FloatingMenu should be shown in any given view
 * update.
 */
export type ShouldShow = (props: {
  editor: Editor;
  view: EditorView;
  state: EditorState;
  oldState?: EditorState;
  from: number;
  to: number;
}) => boolean;

// Options for the `FloatingMenuOpenStatePlugin`
export interface FloatingMenuPluginOptions {
  /**
   * PluginKey of the floating menu plugin that register the `PluginView`
   * that triggers the `open` state of the floating menu
   */
  pluginKey: PluginKey | string;
  /**
   * Mounted editor to do commands on / get state from
   */
  editor: Editor;
  /**
   * Check whether the floating menu should be shown or not
   */
  shouldShow: ShouldShow;
  /**
   * Whether the floating menu's state can be updated when the editor's state
   * doesn't change
   * @default false
   */
  isAsync?: boolean;
  /**
   * Setter function to update the menu open state.
   */
  setOpen: (open: boolean) => void;
}

/**
 * Prosemirror Plugin that watches the view using a `PluginView` to update the
 * open state for the menu.
 */
export const FloatingMenuOpenStatePlugin = (
  options: FloatingMenuPluginOptions
) =>
  new Plugin({
    key:
      typeof options.pluginKey === "string"
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: () => ({
      update: (view, oldState) => {
        const { isAsync, shouldShow, editor, setOpen } = options;
        const { state, composing } = view;
        const { doc, selection } = state;
        const isSame =
          oldState && oldState.doc.eq(doc) && oldState.selection.eq(selection);
        if (!isAsync && (composing || isSame)) {
          return;
        }
        // support for CellSelections
        const { ranges } = selection;
        const from = Math.min(...ranges.map((range) => range.$from.pos));
        const to = Math.max(...ranges.map((range) => range.$to.pos));
        const nextShow = shouldShow({
          editor,
          view,
          state,
          oldState,
          from,
          to,
        });
        setOpen(nextShow);
      },
      destroy: () => {
        options.setOpen(false);
      },
    }),
  });
