import { RefObject, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import useResizeObserver from "use-resize-observer/polyfilled.js";

/**
 * Watch an element's dimensions using a `ResizeObserver` but with a debounced update.
 *
 * @param ref React ref for the element to watch.
 * @param debounceDelay debounce delay on the size update trigger
 * @return `width` and `height` dimensions
 */
export function useDebouncedResizeObserver(
  ref: RefObject<HTMLElement>,
  debounceDelay = 1000
): [number, number] {
  const [size, setSize] = useState<ObservedSize>({
    width: undefined,
    height: undefined,
  });

  // Debounced resize handler
  const onResize = useDebouncedCallback<ResizeHandler>((args) => {
    setSize(args);
  }, debounceDelay);

  // Connect a DOM ResizeObserver on the given `ref` element
  useResizeObserver({ ref, onResize });

  return [size.width ?? 926, size.height ?? 36];
}

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};

type ResizeHandler = (size: ObservedSize) => void;
