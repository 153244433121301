import { IconProps } from "./types";

export const TildeAboveIcon = (props: IconProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
      <path
        d="M18.2397 3.394C18.4077 3.394 18.4917 3.592 18.4917 3.988C18.4917 4.204 18.4677 4.432 18.4197 4.672C18.3237 5.272 18.1137 5.812 17.7897 6.292C17.4777 6.76 17.0997 7.102 16.6557 7.318C16.2957 7.498 15.9477 7.588 15.6117 7.588H15.4677C15.1317 7.588 14.8317 7.54 14.5677 7.444C14.0157 7.252 13.2837 6.754 12.3717 5.95C11.7237 5.386 11.2557 5.02 10.9677 4.852C10.4637 4.552 9.97766 4.402 9.50966 4.402C8.96966 4.402 8.49566 4.558 8.08766 4.87C7.69166 5.182 7.39766 5.566 7.20566 6.022C7.09766 6.322 7.02566 6.664 6.98966 7.048C6.96566 7.42 6.88166 7.606 6.73766 7.606C6.56966 7.606 6.48566 7.408 6.48566 7.012C6.48566 6.88 6.49166 6.772 6.50366 6.688C6.59966 5.8 6.88166 5.062 7.34966 4.474C7.81766 3.886 8.40566 3.538 9.11366 3.43C9.23366 3.406 9.35966 3.394 9.49166 3.394C9.71966 3.394 9.90566 3.412 10.0497 3.448C10.3617 3.52 10.6797 3.646 11.0037 3.826C11.3277 4.006 11.5857 4.174 11.7777 4.33C11.9697 4.486 12.2457 4.726 12.6057 5.05C13.2537 5.614 13.7217 5.98 14.0097 6.148C14.5137 6.448 14.9997 6.598 15.4677 6.598C15.9957 6.598 16.4637 6.448 16.8717 6.148C17.1957 5.92 17.4597 5.602 17.6637 5.194C17.8797 4.774 17.9877 4.348 17.9877 3.916C17.9877 3.772 18.0117 3.652 18.0597 3.556C18.1197 3.448 18.1797 3.394 18.2397 3.394Z"
        fill="#3A517F"
      />
      <path
        d="M7 9.49707C7 9.22093 7.22386 8.99707 7.5 8.99707H18.5C18.7761 8.99707 19 9.22093 19 9.49707V20.4971C19 20.7732 18.7761 20.9971 18.5 20.9971H7.5C7.22386 20.9971 7 20.7732 7 20.4971V9.49707Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.99707V19.9971H18V9.99707H8ZM7.5 8.99707C7.22386 8.99707 7 9.22093 7 9.49707V20.4971C7 20.7732 7.22386 20.9971 7.5 20.9971H18.5C18.7761 20.9971 19 20.7732 19 20.4971V9.49707C19 9.22093 18.7761 8.99707 18.5 8.99707H7.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
