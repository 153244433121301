import { ColorPickerMenu, colors } from "@vericus/cadmus-ui";
import { Selector } from "mathlive";
import styled from "styled-components";
import tinycolor from "tinycolor2";

export interface MathStyleProps {
  disabled: boolean;
  executeCommand: (args: {
    selector: Selector;
    mathLiveCommand: unknown;
  }) => void;
}

/**
 * Formatting menus for the math equation.
 */
export function MathStyle(props: MathStyleProps) {
  const { disabled, executeCommand } = props;

  const onColorChange = (color: string) => {
    if (!disabled) {
      executeCommand({
        selector: "applyStyle",
        mathLiveCommand: { color: tinycolor(color).toRgbString() },
      });
    }
  };

  const onHighlightChange = (color: string) => {
    if (!disabled) {
      executeCommand({
        selector: "applyStyle",
        mathLiveCommand: { backgroundColor: color },
      });
    }
  };

  return (
    <MathStyleRoot>
      <ColorPickerMenu
        title="Text colour"
        aria-label="Text colour"
        pallete={[
          colors.red500,
          colors.orange500,
          colors.yellow500,
          colors.green500,
          colors.cyan500,
          colors.blue500,
          colors.indigo500,
          colors.purple500,
          colors.pink500,
          "#730D1E",
          "#0D2F73",
          "#5B6F98",
          "#5973A65C",
          "#0F172E",
        ]}
        disabled={disabled}
        onChange={onColorChange}
        placement="bottom-end"
        autoFocus={false}
      />
      <ColorPickerMenu
        title="Highlight colour"
        aria-label="Highlight colour"
        pallete={[
          "#FF4D6A",
          "#F7C243",
          "#F7EC6E",
          "#BCE06E",
          "#70E292",
          "#78D1FA",
          "#7580F0",
          "#AF91F8",
          "#F1A4F4",
          "#5973A626",
        ]}
        iconName="Highlight"
        disabled={disabled}
        onChange={onHighlightChange}
        placement="bottom-end"
        autoFocus={false}
      />
    </MathStyleRoot>
  );
}

const MathStyleRoot = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 4px;
`;
