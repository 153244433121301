export enum CodeBlockTheme {
  Dark = "cadmus-dark",
  Light = "cadmus-light",
}

export enum CodeBlockLanguage {
  // Javascript Like
  Javascript = "javascript",
  Typescript = "typescript",
  Json = "json",

  //   C Like
  C = "c",
  CPP = "c++",
  CSharp = "c#",
  ObjectiveC = "objective-c",
  Java = "java",
  Scala = "scala",
  Kotlin = "kotlin",

  // Others
  Elixir = "elixir",
  Haskell = "haskell",
  HTML = "html",
  CSS = "css",
  R = "r",
  Python = "python",
  Yaml = "yaml",
  Markdown = "markdown",
  Go = "go",
  Shell = "shell",
  Matlab = "matlab",
  SQL = "sql",

  // Plain
  PlainText = "plain",
}

export const LANGUAGE_CONFIG = {
  [CodeBlockLanguage.C]: { label: "C", mode: "text/x-csrc", markdown: ["c"] },
  [CodeBlockLanguage.CPP]: {
    label: "C++",
    mode: "text/x-c++src",
    markdown: ["c++", "cpp", "cplusplus"],
  },
  [CodeBlockLanguage.CSharp]: {
    label: "C#",
    mode: "text/x-csharp",
    markdown: ["cs", "csharp"],
  },
  [CodeBlockLanguage.Java]: {
    label: "Java",
    mode: "text/x-java",
    markdown: ["java"],
  },
  [CodeBlockLanguage.Scala]: {
    label: "Scala",
    mode: "text/x-scala",
    markdown: ["scala"],
  },
  [CodeBlockLanguage.Kotlin]: {
    label: "Kotlin",
    mode: "text/x-kotlin",
    markdown: ["kotlin", "kt"],
  },
  [CodeBlockLanguage.ObjectiveC]: {
    label: "Objective C",
    mode: "text/x-objectivec",
    markdown: ["objc", "objectivec"],
  },
  [CodeBlockLanguage.Javascript]: {
    label: "JavaScript",
    mode: "javascript",
    markdown: ["coffeescript", "ecmascript", "javascript", "js", "node"],
  },
  [CodeBlockLanguage.Typescript]: {
    label: "TypeScript",
    mode: "text/typescript",
    markdown: ["ts", "typescript"],
  },
  [CodeBlockLanguage.Json]: {
    label: "JSON",
    mode: "application/json",
    markdown: ["json"],
  },
  [CodeBlockLanguage.HTML]: {
    label: "HTML",
    mode: "text/html",
    markdown: ["html", "xhtml", "xml"],
  },
  [CodeBlockLanguage.CSS]: {
    label: "CSS",
    mode: "text/css",
    markdown: ["css", "less", "sass", "scss", "styl", "stylus"],
  },
  [CodeBlockLanguage.R]: { label: "R", mode: "r", markdown: ["r"] },
  [CodeBlockLanguage.Python]: {
    label: "Python",
    mode: "python",
    markdown: ["py", "python"],
  },
  [CodeBlockLanguage.Yaml]: {
    label: "YAML",
    mode: "yaml",
    markdown: ["yaml", "yml"],
  },
  [CodeBlockLanguage.Markdown]: {
    label: "Markdown",
    mode: "markdown",
    markdown: ["markdown"],
  },
  [CodeBlockLanguage.Go]: { label: "Go", mode: "go", markdown: ["go"] },
  [CodeBlockLanguage.Shell]: {
    label: "Shell",
    mode: "text/x-sh",
    markdown: ["bash", "sh", "shell", "zsh"],
  },
  [CodeBlockLanguage.Matlab]: {
    label: "Matlab",
    mode: "text/x-octave",
    markdown: ["matlab", "Matlab"],
  },
  [CodeBlockLanguage.SQL]: {
    label: "SQL",
    mode: "text/x-sql",
    markdown: ["sql", "SQL"],
  },
  [CodeBlockLanguage.Elixir]: {
    label: "Elixir",
    mode: "elixir",
    markdown: ["elixir", "ex", "exs"],
  },
  [CodeBlockLanguage.Haskell]: {
    label: "Haskell",
    mode: "haskell",
    markdown: ["haskell", "hs"],
  },
  [CodeBlockLanguage.PlainText]: {
    label: "Plain Text",
    mode: "plain",
    markdown: [""],
  },
};

export enum ArrowDirection {
  Left = "left",
  Right = "right",
  Down = "down",
  Up = "up",
}
