import { IconProps } from "./types";

export const OverLeftRightArrowIcon = (props: IconProps) => {
  return (
    <svg width="28" height="30" viewBox="0 0 28 30" {...props}>
      <path
        d="M3.29438 9.22H24.7004C24.0377 8.72533 23.5244 8.128 23.1604 7.428C22.927 6.98 22.773 6.53667 22.6984 6.098C22.6984 6.07933 22.6937 6.06067 22.6844 6.042C22.6844 6.02333 22.6844 6.00933 22.6844 6C22.6844 5.89733 22.7777 5.846 22.9644 5.846C23.0764 5.846 23.1464 5.86 23.1744 5.888C23.2117 5.90667 23.2397 5.958 23.2584 6.042C23.2864 6.22867 23.3377 6.42 23.4124 6.616C23.6177 7.24133 23.9584 7.80133 24.4344 8.296C24.9104 8.78133 25.4657 9.13133 26.1004 9.346C26.1844 9.374 26.2264 9.42533 26.2264 9.5C26.2264 9.57467 26.1844 9.626 26.1004 9.654C25.4657 9.86867 24.9104 10.2233 24.4344 10.718C23.9584 11.2033 23.6177 11.7587 23.4124 12.384C23.347 12.58 23.2957 12.7667 23.2584 12.944C23.2397 13.0373 23.2117 13.0933 23.1744 13.112C23.1464 13.14 23.0764 13.154 22.9644 13.154C22.7684 13.154 22.6704 13.1027 22.6704 13C22.6704 12.972 22.6984 12.8553 22.7544 12.65C23.0437 11.4833 23.6924 10.5267 24.7004 9.78H3.29438L3.33638 9.822C4.29771 10.5593 4.92771 11.502 5.22638 12.65C5.28238 12.8553 5.31038 12.972 5.31038 13C5.31038 13.1027 5.21238 13.154 5.01638 13.154C4.90438 13.154 4.82971 13.14 4.79238 13.112C4.76438 13.0933 4.74104 13.0373 4.72238 12.944C4.70371 12.8787 4.68504 12.7993 4.66638 12.706C4.49838 12.0247 4.17638 11.418 3.70038 10.886C3.23371 10.354 2.66904 9.95733 2.00638 9.696C1.86638 9.64 1.78238 9.58867 1.75438 9.542C1.75438 9.42067 1.83838 9.34133 2.00638 9.304C2.66904 9.04267 3.23371 8.646 3.70038 8.114C4.17638 7.582 4.49838 6.97533 4.66638 6.294C4.67571 6.266 4.68504 6.224 4.69438 6.168C4.70371 6.112 4.70838 6.07467 4.70838 6.056C4.71771 6.03733 4.72704 6.00933 4.73638 5.972C4.75504 5.93467 4.77371 5.91133 4.79238 5.902C4.81104 5.88333 4.83904 5.86933 4.87638 5.86C4.91371 5.85067 4.96038 5.846 5.01638 5.846C5.21238 5.846 5.31038 5.89733 5.31038 6C5.31038 6.028 5.28238 6.14467 5.22638 6.35C4.94638 7.46067 4.31638 8.40333 3.33638 9.178L3.29438 9.22Z"
        fill="#3A517F"
      />
      <path
        d="M8 14.7949C8 14.5188 8.22386 14.2949 8.5 14.2949H19.5C19.7761 14.2949 20 14.5188 20 14.7949V25.7949C20 26.0711 19.7761 26.2949 19.5 26.2949H8.5C8.22386 26.2949 8 26.0711 8 25.7949V14.7949Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15.2949V25.2949H19V15.2949H9ZM8.5 14.2949C8.22386 14.2949 8 14.5188 8 14.7949V25.7949C8 26.0711 8.22386 26.2949 8.5 26.2949H19.5C19.7761 26.2949 20 26.0711 20 25.7949V14.7949C20 14.5188 19.7761 14.2949 19.5 14.2949H8.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
