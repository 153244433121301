import {
  MutableRefObject,
  RefObject,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { TableMap } from "@tiptap/pm/tables";
import useResizeObserver from "use-resize-observer/polyfilled.js";

import {
  TableDeleteIndicatorPluginKey,
  columnResizingPluginKey,
} from "../plugins";
import {
  findTable,
  getColWidths,
  getRowHeights,
  isColumnSelected,
  isRowSelected,
  isTableSelected,
} from "../utils";

export interface UseTableControlProps {
  editor: Editor;
  tableRef: RefObject<HTMLTableElement | null>;
  node: PMNode;
  colNum: number;
  getPos: () => number;
}

// Handler for the EvenEmitter transaction events
type TransactionHandler = ({
  transaction,
}: {
  transaction: Transaction;
}) => void;

const useComponentBoundingRect = (
  target: MutableRefObject<HTMLTableElement | null>
) => {
  const [boundingRect, setBoundingRect] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (target.current) {
      setBoundingRect(target.current.getBoundingClientRect());
    }
  }, [target]);

  // Where the magic happens
  useResizeObserver({
    ref: target.current,
    onResize: () =>
      target.current &&
      setBoundingRect(target.current?.getBoundingClientRect()),
  });
  return boundingRect;
};

/**
 * React hooks to position, show/hide, highlight table controls
 * for insert/delete row(s)/column(s) and row(s)/column(s)
 * selection handle
 */
export const useTableControl = (props: UseTableControlProps) => {
  const { editor, node, tableRef, colNum, getPos } = props;
  const [colWidths, setColWidths] = useState([] as number[]);
  const [rowHeights, setRowHeights] = useState<number[]>();
  const [isResizing, setIsResizing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<number[]>([]);
  const [isSelected, setIsSelected] = useState(false);
  const [predelete, setPredelete] = useState(false);
  const tableRect = useComponentBoundingRect(tableRef);

  // re-calculate position of table control(s) based on the bouding
  // rect of table element
  useEffect(() => {
    if (tableRef.current) {
      setRowHeights(getRowHeights(tableRef.current));
      setColWidths(getColWidths(tableRef.current, node));
    }
  }, [tableRef, node, tableRect, colNum, isResizing]);

  // Listen to editor transaction to figure out if cell(s) need to be decorated
  // with selection / pre-deletion state
  useEffect(() => {
    const handleResize: TransactionHandler = ({ transaction }) => {
      const pos = getPos();
      const { selection } = transaction;
      const resizingMeta = transaction.getMeta(columnResizingPluginKey);
      if (resizingMeta) {
        const resizingState = columnResizingPluginKey.getState(editor.state);
        setIsResizing(!!resizingState?.dragging);
      }
      const predeleteMeta = transaction.getMeta(TableDeleteIndicatorPluginKey);
      if (predeleteMeta) {
        setPredelete(!!predeleteMeta.highlight);
      }
      const table = findTable(transaction.selection);
      if (table?.pos === pos && table?.node) {
        setIsEditing(true);
        const map = TableMap.get(table.node);
        const newSelectedRows = [];
        const newSelectedColumns = [];
        for (let row = 0; row < map.height; row++) {
          const rowSelected = isRowSelected(row)(selection);
          if (rowSelected) {
            newSelectedRows.push(row);
          }
        }
        for (let col = 0; col < map.width; col++) {
          const colSelected = isColumnSelected(col)(selection);
          if (colSelected) {
            newSelectedColumns.push(col);
          }
        }
        setSelectedRows(newSelectedRows);
        setSelectedColumns(newSelectedColumns);
        setIsSelected(isTableSelected(selection));
      } else {
        setIsEditing(false);
        setSelectedRows([]);
        setSelectedColumns([]);
        setIsSelected(false);
      }
    };
    editor.on("transaction", handleResize);
    return () => {
      editor?.off("transaction", handleResize);
    };
  }, [editor, node, tableRef, getPos]);
  return {
    colWidths,
    rowHeights,
    tableRect,
    isResizing,
    isEditing,
    isSelected,
    selectedColumns,
    selectedRows,
    predelete,
  };
};
