import { colors } from "./colors";

/** Default theme specification. */
export const defaultTheme = {
  primaryColor: colors.blue500,
  gradient: {
    start: "#1560FB",
    end: "#60BEFB",
  },
  text: {
    accent: colors.blue500,
    main: colors.black100,
    heading: colors.black200,
    shade1: colors.grey500,
    shade2: colors.grey400,
  },
  background: {
    default: colors.white100,
    action1: colors.grey50,
    action2: colors.grey100,
    action3: colors.grey200,
  },
  divider: colors.grey300,
  success: colors.teal500,
  fail: "#DB0025",
  link: "#006FDE",
};

/** Theme interface. */
export type CuiTheme = typeof defaultTheme;

/** Select a CUI theme. */
export function createCuiTheme(overrides: Partial<CuiTheme> = {}): CuiTheme {
  const theme = defaultTheme;
  return { ...theme, ...overrides };
}
