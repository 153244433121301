import { forwardRef } from "react";
import { Icon } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { colors } from "../styles";
import { Text } from "../Text";
import { EditableActions, EditingActions, ViewOnlyActions } from "./Actions";
import {
  ResourceCardBaseProps,
  ResourceNameInput,
  resourceTypeToCardBackgroundColorMap,
} from "./shared";

export interface LinkCardProps extends ResourceCardBaseProps {
  onCopyLink?: VoidFunction;
  linkTitle: string;
  viewOnly?: boolean;
}

export const LinkCard = forwardRef<HTMLDivElement, LinkCardProps>(
  (
    {
      linkTitle,
      nameBeingEdited,
      onUpdateNameBeingEdited,
      onDoneEditing,
      onCancelEditing,
      onCopyLink,
      onDelete,
      onOpenInBrowser,
      onStartEditing,
      isEditingName,
      viewOnly,
    },
    ref
  ) => {
    const backgroundColor =
      colors[resourceTypeToCardBackgroundColorMap["link"]];
    const onCardClick = viewOnly ? onOpenInBrowser : undefined;
    const shouldShowEditingActions =
      isEditingName && onUpdateNameBeingEdited && onDelete && onCancelEditing;
    const shouldShowViewOnlyActions =
      viewOnly && (onCopyLink || onOpenInBrowser);
    const shouldShowEditableActions =
      !viewOnly && onOpenInBrowser && onStartEditing;
    return (
      <Container
        ref={ref}
        backgroundColor={backgroundColor}
        clickable={!!viewOnly}
        onClick={onCardClick}
      >
        <UnshrinkableIcon iconName="Link" />
        <LinkNameContainer>
          {isEditingName ? (
            <ResourceNameInput
              fullWidth
              value={nameBeingEdited}
              onCancel={onCancelEditing}
              onConfirm={onDoneEditing}
              onChange={(e) => onUpdateNameBeingEdited?.(e.target.value)}
            />
          ) : (
            <TrimmedLinkName kind="headingSix">{linkTitle}</TrimmedLinkName>
          )}
        </LinkNameContainer>

        <ActionsContainer absolute={!isEditingName}>
          {shouldShowEditingActions && (
            <EditingActions
              onDone={onDoneEditing}
              onDelete={onDelete}
              onCancel={onCancelEditing}
            />
          )}
          {!isEditingName && (
            <OnCardHover>
              {shouldShowViewOnlyActions && (
                <ViewOnlyActions
                  onCopyLink={onCopyLink}
                  onOpenInBrowser={onOpenInBrowser}
                />
              )}
              {shouldShowEditableActions && (
                <EditableActions
                  onCopyLink={onCopyLink}
                  onOpenInBrowser={onOpenInBrowser}
                  onEdit={onStartEditing}
                />
              )}
            </OnCardHover>
          )}
        </ActionsContainer>
      </Container>
    );
  }
);

type ContainerProps = {
  backgroundColor: string;
  clickable: boolean;
};
const Container = styled.div<ContainerProps>`
  align-items: center;
  background-color: ${(p) => p.backgroundColor};
  box-sizing: border-box;
  cursor: ${(p) => p.clickable && "pointer"};
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 12px;
  min-height: 53px;
  min-width: 170px;
  padding: 8px 16px;
  position: relative;
  width: 100%;
`;

const TrimmedLinkName = styled(Text)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: ${(p) => p.theme.link};
  cursor: pointer;
  display: -webkit-box;
  max-width: 100%;
  overflow: hidden;
  text-decoration: underline;
`;

const UnshrinkableIcon = styled(Icon)`
  flex-shrink: 0;
`;

const LinkNameContainer = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  overflow: hidden;
  color: black;
`;

const ActionsContainer = styled.div<{ absolute: boolean }>`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: ${(p) => p.absolute && "absolute"};
  right: ${(p) => p.absolute && "8px"};
  top: ${(p) => p.absolute && "8px"};
`;

const OnCardHover = styled.div`
  display: none;
  ${Container}:hover & {
    display: block;
  }
`;
