import { useCallback } from "react";
import {
  ControlButton,
  DropdownMenu,
  MenuItem,
  MenuLabel,
} from "@vericus/cadmus-ui";
import styled, { css } from "styled-components";

import {
  MaterialsWidth,
  selectMaterialsOpen,
  selectMaterialsWidth,
  setWidth,
  toggle,
} from "../materials-slice";
import { useAppDispatch, useAppSelector } from "data/hooks";

// Positioning div for the Close button.
const CloseAction = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 18px;
  z-index: 100;
  pointer-events: all;
  transition: all 0.3s ease;

  ${(p) =>
    !p.isOpen &&
    css`
      transform: translateX(40px);
      background: white;
      box-shadow: 0px 0px 1px rgba(71, 92, 133, 0.36),
        0px 1px 3px rgba(71, 92, 133, 0.15),
        1px 4px 7px -6px rgba(71, 92, 133, 0.06);
      border-radius: 4px;
    `}
`;

/**
 * Displays the 2 control icon buttons on the right edge of the Materials pane.
 *
 * The top-right control icon is a close icon button to close a open Materials
 * pane.
 *
 * The bottom-right control icon opens a width selection menu for re-sizing the
 * Materials pane.
 */
export function MaterialsControl() {
  const dispatch = useAppDispatch();

  // Current width
  const materialsWidth = useAppSelector(selectMaterialsWidth);
  const isOpen = useAppSelector(selectMaterialsOpen);

  // Handle menu selection
  const handleSelect = useCallback(
    (width: MaterialsWidth) => {
      dispatch(setWidth(width));
    },
    [dispatch]
  );

  return (
    <>
      <CloseAction isOpen={isOpen}>
        <ControlButton
          aria-label={!isOpen ? `Open instructions` : `Close instructions`}
          iconName={!isOpen ? `Sidebar` : `Close`}
          onClick={() => dispatch(toggle())}
          tooltipPosition="right"
        />
      </CloseAction>
      {isOpen && (
        <DropdownMenu
          control={
            <ControlButton
              iconName="Grow"
              aria-label="Panel Size"
              tooltipPosition="left"
            />
          }
        >
          <MenuLabel>Panel Size</MenuLabel>
          <MenuItem
            selected={materialsWidth === "golden"}
            onClick={() => handleSelect("golden")}
            content="Golden ratio"
          />
          <MenuItem
            selected={materialsWidth === "half"}
            onClick={() => handleSelect("half")}
            content="Half way"
          />
          <MenuItem
            selected={materialsWidth === "inverseGolden"}
            onClick={() => handleSelect("inverseGolden")}
            content="Inverse golden"
          />
        </DropdownMenu>
      )}
    </>
  );
}
