import { Extension } from "@tiptap/core";

export interface BlockBackgroundOptions {
  types: string[];
}

export const BlockBackground = Extension.create<BlockBackgroundOptions>({
  name: "BlockBackground",
  addOptions() {
    return {
      types: [],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          backgroundColor: {
            default: null,
            parseHTML: (element) =>
              element.style.backgroundColor ||
              element.getAttribute("data-cadmus-background-color"),
            renderHTML: (attributes) => {
              if (attributes.backgroundColor) {
                return {
                  style: `--background-color: ${attributes.backgroundColor};`,
                  "data-cadmus-background-color": attributes.backgroundColor,
                };
              }
              return {};
            },
          },
        },
      },
    ];
  },
});
