import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import { getMathNodeTypes } from "./utils";

/**
 * Describes the node and pos in the document which is the target of math block clicks
 */
export interface MathMenuTarget {
  node: PMNode;
  pos: number;
}

/** Plugin key for `MathMenuPlugin` */
export const MathMenuPluginKey = new PluginKey<MathMenuTarget | null>(
  "mathMenu"
);

/** Transaction metadata action to trigger a math menu on a target. */
export interface TriggerMenuAction {
  type: "triggerMenu";
  target: MathMenuTarget;
}

/** Transaction metadata action to dismiss any existing math menu. */
export interface DismissMenuAction {
  type: "dismissMenu";
}

export type MathMenuAction = TriggerMenuAction | DismissMenuAction;

/**
 * Plugin to render a floating menu for math creation and edit on math blocks.
 *
 * This plugin store the state of the clicked math node which MathMenu used
 * to control whether it needs to be opened or closed through the Transaction metadata
 * actions under the `MathMenuPluginKey`:
 *
 *   - `TriggerMenuAction` will open the menu with the given `node.attrs.equation` as the input contents.
 *
 *   - `DismissMenuAction` will unset the plugin state removing any menu.
 */
export const MathMenuPlugin = ({ editor }: { editor: Editor }) =>
  new Plugin<MathMenuTarget | null>({
    key: MathMenuPluginKey,
    state: {
      init() {
        return null;
      },
      apply(tr, state) {
        const payload = tr.getMeta(MathMenuPluginKey) as
          | MathMenuAction
          | undefined;
        switch (payload?.type) {
          case "triggerMenu":
            return payload.target;
          case "dismissMenu":
            return null;
          default: {
            return state;
          }
        }
      },
    },
    props: {
      handleClick(view, pos) {
        const { state } = view;
        const pluginState = this.getState(state);
        const node = state.doc.nodeAt(pos);
        const mathTypes = getMathNodeTypes(editor.schema);
        if (
          node &&
          [mathTypes.inline.name, mathTypes.block.name].includes(node.type.name)
        ) {
          editor
            .chain()
            .dismissMenu()
            .focus()
            .setNodeSelection(pos)
            .setEquationMenuTarget({
              node,
              pos,
            })
            .run();
          return true;
        } else if (pluginState) {
          editor.commands.setEquationMenuTarget();
        }
        // have to return false to keep the normal selection behaviour if it's not math block clicks
        return false;
      },
    },
  });
