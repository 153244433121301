import { Editor } from "@tiptap/core";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import crel from "crelt";

import mediaError from "./media-error.png";

export const ImageUploaderPluginKey = new PluginKey<DecorationSet>(
  "imageUploader"
);

/**
 * Create an Image upload management plugin.
 *
 * This plugin renders and manages placeholder widgets which track the process
 * of uploading and inserting one or more images in the document.
 *
 * It renders the following kinds of widgets/decorations acting as intermediate
 * placeholders:
 *
 *   1. `AddImageWidget` - a faded image of the same size with a loader for
 *        while the image is being uploaded
 *   2. `ErrorWidget` - when there is an error in the upload process
 *
 * The placeholders widgets/decorations are managed through discting action
 * steps, these are represented via the variant `UploadPlaceholderAction`. These
 * actions are:
 *
 *   1. `AddImagePlaceholder` - action to add a new image placeholder
 *   2. `AddErrorPlaceholder` - action to render a new error placeholder
 *   3. `RemovePlaceholder` - action to remove any existing placeholder of both kinds.
 *
 * The above actions are read from the Transaction meta set for this plugin. It
 * is expected that the Transaction meta is an Array of the above actions as
 * multiple image uploads are supported. Therefore it is better to bulk manage
 * the placeholders too.
 *
 * The placeholder widgets are stored in the plugin state using the standard
 * `DecorationSet`. Each widget uses an `id` object reference (unique in
 * comparison) to be easily referencible in the actions. This reference is
 * stored in the widget specification object.
 */
export const ImageUploaderPlugin = () =>
  new Plugin<DecorationSet>({
    key: ImageUploaderPluginKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(tr, decorations) {
        // Adjust decoration positions to changes made by the transaction
        decorations = decorations.map(tr.mapping, tr.doc);

        // Pull all meta events added to this transaction to communicate with
        // this plugin
        const actions: UploadPlaceholderAction[] | undefined = tr.getMeta(
          ImageUploaderPluginKey
        );

        actions?.forEach((action) => {
          switch (action.type) {
            case "addImage": {
              const deco = AddImageWidget(action);
              decorations = decorations.add(tr.doc, [deco]);
              break;
            }
            case "addError": {
              const deco = ErrorWidget(action);
              decorations = decorations.add(tr.doc, [deco]);
              break;
            }
            case "removePlaceholder": {
              const { id } = action;
              const decos = decorations.find(
                undefined,
                undefined,
                (spec) => spec.id === id
              );
              decorations = decorations.remove(decos);
              break;
            }
          }
        });

        return decorations;
      },
    },
    props: {
      decorations(editorState) {
        return this.getState(editorState);
      },
    },
  });

//////////////////////////////////////////////////////////////////////////////
// Widgets                                                                  //
//////////////////////////////////////////////////////////////////////////////

// Create a Decoration widget showing an upload error
const ErrorWidget = (payload: AddErrorPlaceholder): Decoration => {
  const { id, pos, message, side } = payload;
  return Decoration.widget(
    pos,
    (view) => {
      const onCancel = () => {
        const meta: UploadPlaceholderAction[] = [
          { type: "removePlaceholder", id },
        ];
        const tr = view.state.tr.setMeta(ImageUploaderPluginKey, meta);
        view.dispatch(tr);
      };
      const widget = crel(
        "figure",
        {
          class: "image-uploader-error",
          contenteditable: "false",
        },
        [
          crel("img", {
            src: mediaError,
            draggable: "false",
            contenteditable: "false",
          }),
          crel(
            "p",
            {
              contenteditable: "false",
            },
            message
              ? [message]
              : [
                  `There was an error while uploading this image.`,
                  crel("br"),
                  `Please check your internet connection and try again.`,
                ]
          ),
          crel("button", { class: "cui-LinkButton", onclick: onCancel }, [
            crel("span", ["Cancel"]),
          ]),
        ]
      );
      return widget;
    },
    {
      id,
      side,
    }
  );
};

// Create a Decoration widget for upload placeholder
const AddImageWidget = (payload: AddImagePlaceholder): Decoration => {
  const { id, pos, src, width, side } = payload;
  const img = crel("img", {
    src,
    dragable: "false",
  });
  img.style.maxWidth = `${width}px`;
  const loader = crel("span", {
    "data-loading": "true",
  });
  const figure = crel(
    "figure",
    {
      "data-loading": "true",
      dragable: "false",
      contenteditable: "false",
    },
    [img, loader]
  );
  return Decoration.widget(pos, () => figure, {
    id,
    src,
    width,
    side,
  });
};

//////////////////////////////////////////////////////////////////////////////
// Meta events API                                                          //
//////////////////////////////////////////////////////////////////////////////

export function addErrorPlaceholder(
  attrs: Omit<AddErrorPlaceholder, "type">
): AddErrorPlaceholder {
  return { type: "addError", ...attrs };
}

export function addImagePlaceholder(
  attrs: Omit<AddImagePlaceholder, "type">
): AddImagePlaceholder {
  return { type: "addImage", ...attrs };
}

export function removePlaceholder(id: object): RemovePlaceholder {
  return { type: "removePlaceholder", id };
}

/**
 * Dispatch a new Transaction with the metadata for the `ImageUploaderPlugin` set.
 *
 * The metadata is an array of `UploadPlaceholderAction`s which dictate how the
 * plugin will manipulate the existing state of placeholder widgets in the
 * document.
 */
export function setImageUploaderTransactionMeta(
  editor: Editor,
  meta: UploadPlaceholderAction[]
) {
  editor.commands.command(({ tr }) => {
    tr.setMeta(ImageUploaderPluginKey, meta);
    return true;
  });
}

/**
 * Find an image upload placeholder with a specific `id` if it still exists in
 * the body.
 *
 * @param state current EditorState to search in
 * @param id some comparable reference to an existing placeholder instance
 * @return position of the placeholder widget if found
 */
export function findUploadPlaceholder(
  state: EditorState,
  id: object
): number | null {
  const decos = ImageUploaderPluginKey.getState(state);
  if (!decos) return null;
  const found = decos.find(undefined, undefined, (spec) => spec.id === id);
  return found.length ? found[0].from : null;
}

/**
 * Filter exiting placeholder widgets which were placed at the given position.
 *
 * @param state current EditorState to look in
 * @param pos document position the widgets were placed in
 * @return the Decoration if found
 */
export function findPlaceholdersAtPos(state: EditorState, pos: number) {
  const decos = ImageUploaderPluginKey.getState(state);
  if (!decos) return null;
  return decos
    .find(undefined, undefined, () => true)
    .filter((deco) => deco.from === pos);
}

//////////////////////////////////////////////////////////////////////////////
// Meta events                                                              //
//////////////////////////////////////////////////////////////////////////////

/**
 * Various placeholder widget management actions. A placeholder widget is
 * referenced by the `id` fields common to all the variant constructors.
 *
 * A list of these actions are normally attached to a Transaction metadata so
 * that the `ImageUploader` picks it up and executes them on its state of
 * existing placeholder widgets.
 */
export type UploadPlaceholderAction =
  | AddImagePlaceholder
  | AddErrorPlaceholder
  | RemovePlaceholder;

/**
 * Upload event signalling an image is being uploaded and added to the doc.
 * Renders an temporary image loading placeholder widget at the given position.
 */
export interface AddImagePlaceholder {
  type: "addImage";
  /** Unique ID reference */
  id: object;
  /** Position to add an image in the document */
  pos: number;
  /** Temporary image source to display in placeholder */
  src: string;
  /** Calculated original image width */
  width: number;
  /** Choose side of the position the placholder will be placed: Negative or Positive */
  side?: number;
}

/**
 * Signals an error in the image uploading or adding process.
 * Renders an error placeholder placeholder at the given position.
 */
export interface AddErrorPlaceholder {
  type: "addError";
  /** Unique ID reference */
  id: object;
  /** Position to add an image in the document */
  pos: number;
  /** Optional user displayed error message */
  message?: string;
  /** Choose side of the position the placholder will be placed: Negative or Positive */
  side?: number;
}

/**
 * Triggers removal of an existing placeholder widget of any kind.
 */
export interface RemovePlaceholder {
  type: "removePlaceholder";
  /** Unique ID reference to an existing placeholder */
  id: object;
}
