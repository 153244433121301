import { Fragment, Node as PMNode } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";

import { wordRegexMatch } from "./tokeniser";

/**
 * Get total word count across all text nodes in the given document.
 */
export function nodeWordCount(topNode: PMNode | Fragment): number {
  return textWordCount(textBetween(topNode, " "));
}

/**
 * Get text inside node/fragment separated by "blockSeparator" excluding
 * nodes which have attrs.hideText
 * @param {PMNode | Fragment} node [top most node or fragment]
 * @param {string} blockSeparator [separator used for in between blocks]
 * @param {number} from [start position of the node]
 * @param {number} to [end position of the node]
 */
export function textBetween(
  node: PMNode | Fragment,
  blockSeparator: string,
  from?: number,
  to?: number
) {
  const nodeSize = node instanceof PMNode ? node.content.size : node.size;
  // set topBoundary to `to` or `nodeSize`
  const topBoundary = to ?? nodeSize;
  // set bottomBoundary to `from` or `0`
  const bottomBoundary = from ?? 0;
  let text = "",
    separated = true;
  const leafText = " ";
  node.nodesBetween(
    bottomBoundary,
    topBoundary,
    (node, pos) => {
      if (node.attrs.hideText) return false;
      if (node.isText) {
        // slice the text from the bottomBoundary to the topBoundary
        text +=
          node?.text?.slice(
            Math.max(bottomBoundary, pos) - pos,
            topBoundary - pos
          ) ?? "";
        separated = !blockSeparator;
      } else if (node.isLeaf && leafText) {
        text += leafText;
        separated = !blockSeparator;
      } else if (!separated && node.isBlock) {
        text += blockSeparator;
        separated = true;
      }
      return true;
    },
    0
  );
  return text;
}

/**
 * Get word count for currently selected text nodes in the given `state`.
 */
export function selectionWordCount(state: EditorState) {
  const { doc, selection } = state;
  if (selection) {
    const { from, to } = selection;
    const text = textBetween(doc, " ", from, to);
    return textWordCount(text);
  }
  return 0;
}

/** Plain text word count. */
export function textWordCount(text: string): number {
  const match = wordRegexMatch(text.trim());
  return match ? match.length : 0;
}
