import { IconProps } from "./types";

export const OverbarIcon = (props: IconProps) => {
  return (
    <svg width="20" height="26" viewBox="0 0 20 26" {...props}>
      <path
        d="M2 4H18"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M4 7.5C4 7.22386 4.22386 7 4.5 7H15.5C15.7761 7 16 7.22386 16 7.5V18.5C16 18.7761 15.7761 19 15.5 19H4.5C4.22386 19 4 18.7761 4 18.5V7.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8V18H15V8H5ZM4.5 7C4.22386 7 4 7.22386 4 7.5V18.5C4 18.7761 4.22386 19 4.5 19H15.5C15.7761 19 16 18.7761 16 18.5V7.5C16 7.22386 15.7761 7 15.5 7H4.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
