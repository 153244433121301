import { Icon, IconName } from "@vericus/cadmus-icons";
import styled, { css } from "styled-components";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Different variants of the button */
  kind?: "primary";
  /** Add a Cadmus Icon by the content */
  iconName?: IconName;
}

/**
 * ControlAction are full height Call-to-Action `button` that is used
 * exclusively in the Toolbar area.
 */
export const ControlAction = (props: Props) => {
  const { kind, iconName, children } = props;
  return (
    <ButtonContainer kind={kind} {...props}>
      <ButtonText>{children}</ButtonText>
      {iconName && <ButtonIcon iconName={iconName} />}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button<Props>`
  padding: 6px 18px;
  margin: 0;
  height: 100%;

  display: inline-flex;
  align-items: center;

  font-family: inherit;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.5px;

  position: relative;

  border: none;
  box-sizing: border-box;
  cursor: pointer;

  ${(p) => background(p.kind)}

  outline: 0;

  &:not(:disabled) {
    /* The :after pseudo-element creates a performant box shadow change on hover */
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: ${(p) => p.theme.background.action3};
    }

    &:hover:after,
    &:focus:after {
      opacity: 0.36;
    }

    &:active:after {
      opacity: 0.54;
    }
  }

  &:disabled {
    opacity: 0.54;
  }
`;

const ButtonText = styled.span`
  position: relative;
  white-space: nowrap;
  z-index: 2;
`;

const ButtonIcon = styled(Icon)`
  margin-left: 9px;
  font-size: 16px; /* Increases icon size */
`;

// A mixin to inject button background color
const background = (kind?: "primary") => {
  if (kind === "primary") {
    return css`
      fill: white;
      color: white;
      background-image: linear-gradient(
        45deg,
        ${(p) => p.theme.gradient.start} 0%,
        ${(p) => p.theme.gradient.end} 100%
      );
    `;
  }

  return css`
    fill: ${(p) => p.theme.text.main};
    background-color: transparent;
  `;
};
