import styled from "styled-components";

import { Input, Menu, MenuItem, Text } from "..";

const MIN_WIDTH = 200;

export interface SearchListItem {
  value: string;
  label: string;
  description?: string;
}

interface Props {
  /**
   * The search query value
   */
  queryValue: string;

  /**
   * Callback function for when the search query value has changed
   * @param {string} newQuery the new value of the search query
   */
  onQueryValueChange: (newQuery: string) => void;

  /**
   * All the available items to display and that are searchable
   */
  items: SearchListItem[];

  /**
   * Width (in px) the of the component
   */
  width?: number;

  /**
   * The placeholder text for when there is no search query inputted yet.
   */
  placeholder?: string;

  /**
   * Callback function for when an item is selected from the menu list.
   * @param {SearchListItem} searchListItem the item that was just selected.
   */
  onSelect: (searchListItem: SearchListItem) => void;
}

export const SearchList = ({
  queryValue,
  onQueryValueChange,
  width,
  items,
  placeholder,
  onSelect,
}: Props) => {
  const filtered = items.filter((item) =>
    item.value.toLowerCase().includes(queryValue.toLowerCase())
  );

  return (
    <SearchListContainer width={width}>
      <StyledInput
        aria-label={"search-list"}
        fullWidth
        placeholder={placeholder}
        value={queryValue}
        onChange={(event) => onQueryValueChange(event.target.value)}
        iconName="Search"
        clearable={queryValue.length > 0}
      />
      <SearchMenu width={width ? width : MIN_WIDTH}>
        {filtered.length > 0 &&
          filtered.map((item) => {
            const onClick = () => {
              onSelect(item);
            };

            return (
              <SearchMenuItem onClick={onClick} key={item.value}>
                <SearchMenuItemContent>
                  <Text kind="system" as="span" inline>
                    {item.label}
                  </Text>
                  {item.description && (
                    <Text kind="subtle" inline>
                      {item.description}
                    </Text>
                  )}
                </SearchMenuItemContent>
              </SearchMenuItem>
            );
          })}
        {filtered.length == 0 && (
          <NoResultContainer>
            <p>No groups found</p>
            <NoResultClearFilter onClick={() => onQueryValueChange("")}>
              Clear search
            </NoResultClearFilter>
          </NoResultContainer>
        )}
      </SearchMenu>
    </SearchListContainer>
  );
};

const SearchMenuItem = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;
  gap: initial;
`;

const SearchMenu = styled(Menu)`
  padding: 0;
  box-shadow: none;
`;

const NoResultClearFilter = styled.a`
  color: ${(p) => p.theme.text.accent};

  &:hover {
    cursor: pointer;
  }
`;

const SearchMenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 4px;
`;

const StyledInput = styled(Input)`
  display: block;
  margin: 20px 12px;
  width: auto;
`;

const NoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 250px;
`;

const SearchListContainer = styled.div<{ width?: number }>`
  width: ${(p) => (p.width ? `${p.width}px` : `${MIN_WIDTH}px`)};
  min-height: 286px;

  border-radius: 2px;
  background-color: ${(p) => p.theme.background.default};
  overflow-y: auto;
  z-index: 2;
`;
