import {
  TableHeader as TiptapTableHeader,
  TableHeaderOptions as TiptapTableHeaderOptions,
} from "@tiptap/extension-table-header";

import { cellDefaultWidth } from "../../constants";

export interface TableHeaderOptions extends TiptapTableHeaderOptions {
  defaultColWidth?: number;
}

export const TableHeader = TiptapTableHeader.extend<TableHeaderOptions>({
  addOptions() {
    return {
      ...(this.parent?.() ?? {}),
      defaultColWidth: cellDefaultWidth,
    };
  },
  addAttributes() {
    return {
      ...(this.parent?.() ?? {}),
      colwidth: {
        default: [this.options.defaultColWidth],
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth");
          const value = colwidth
            ? [parseInt(colwidth, 10)]
            : [this.options.defaultColWidth];

          return value;
        },
      },
    };
  },
});
