import { css, keyframes } from "styled-components";

import { colors } from "./colors";

//
// TIMING FUNCTIONS
// -----------------------------------------------------------------------------

/** Is snappy and responsive. Good for component interactivity like hover or
 * focus. Feels immediate to a user.
 */
export const easeOutCirc = "cubic-bezier(0.075, 0.82, 0.165, 1)";

/** Is soft and cinematic. Good for entry reveals.
 * Provides time for users to see cause and effect.
 */
export const easeOutQuart = "cubic-bezier(0.165, 0.84, 0.44, 1)";

/** Starts off slow and accelerates fast. Good for drawing attention to a focal
 * point. Used for focus animations.
 */
export const easeInExpo = "cubic-bezier(0.95, 0.05, 0.795, 0.035)";

//
// KEYFRAMES
// -----------------------------------------------------------------------------

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeInTop = keyframes`
  from {
    opacity: 0;
    transform: translateY(-18px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(18px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const ping = keyframes`
  from {
    box-shadow: 0 0 0 0px rgba(133,146,173,0.36);
    opacity: 1;
  }
  to {
    box-shadow: 0 0 0 9px rgba(133,146,173,0.36);
    opacity: 0;
  }
`;

const refocus = keyframes`
  from {
    box-shadow: 0 0 0 9px ${colors.blueA500};
    opacity: 0;
  }
  to {
    box-shadow: 0 0 0 3px ${colors.blueA500};
    opacity: 0.54;
  }
`;

//
// COMPLETE ANIMATIONS
// -----------------------------------------------------------------------------

/** A slow, fade in from the top reveal which lasts 0.6s
 */
export const FadeInTop = css`
  animation: ${fadeInTop} 0.6s ${easeOutQuart};
`;

/** A slow, fade in from the left reveal which lasts 0.6s
 */
export const FadeInLeft = css`
  animation: ${fadeInLeft} 0.6s ${easeOutQuart};
`;

/** A fast and snappy fade in reveal with no translation
 */
export const FadeIn = css`
  animation: ${fadeIn} 0.3s ${easeOutCirc};
`;

/** A focus ring that pings outwards, then retracts back inwards after some
 * time.
 */
export const FocusAnimation = css`
  animation: ${ping} 0.6s ${easeOutCirc},
    ${refocus} 0.4s 0.2s ${easeInExpo} forwards;
`;
