import { Extension } from "@tiptap/core";

import {
  ImageUploaderPlugin,
  ImageUploaderPluginKey,
  UploadPlaceholderAction,
  removePlaceholder,
} from "./image-uploader-plugin";

export interface ImageUploaderOptions {
  imageAPIBase: string;
  tenant: string;
  assessmentId: string;
  workId?: string;
  userRole: string;
  userId: string;
}

export const ImageUploader = Extension.create<ImageUploaderOptions>({
  name: "imageUploader",

  addProseMirrorPlugins() {
    return [ImageUploaderPlugin()];
  },
  // add keyboard shortcut to remove image widget around the selection
  // when backspace/delete key is pressed
  addKeyboardShortcuts() {
    const handleBackspace = () =>
      this.editor.commands.command(({ tr, state }) => {
        const { selection } = state;
        const { from, to } = selection;
        const decos = ImageUploaderPluginKey.getState(state);
        if (!decos) return false;
        const actions: UploadPlaceholderAction[] = [];
        decos
          .find(undefined, undefined, () => true)
          .forEach((deco) => {
            if (!deco.spec.src && deco.from >= from - 1 && deco.from <= to) {
              actions.push(removePlaceholder(deco.spec.id));
            }
          });
        if (actions.length) {
          tr.setMeta(ImageUploaderPluginKey, actions);
        }
        return false;
      });
    const handleDelete = () =>
      this.editor.commands.command(({ tr, state }) => {
        const { selection } = state;
        const { from, to } = selection;
        const decos = ImageUploaderPluginKey.getState(state);
        if (!decos) return false;
        const actions: UploadPlaceholderAction[] = [];
        decos
          .find(undefined, undefined, () => true)
          .forEach((deco) => {
            if (!deco.spec.src && deco.from >= from && deco.from <= to + 1) {
              actions.push(removePlaceholder(deco.spec.id));
            }
          });
        if (actions.length) {
          tr.setMeta(ImageUploaderPluginKey, actions);
        }
        return false;
      });
    return {
      Backspace: () => handleBackspace(),
      "Mod-Backspace": () => handleBackspace(),
      Delete: () => handleDelete(),
      "Mod-Delete": () => handleDelete(),
    };
  },
});
