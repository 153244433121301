import { findParentNode } from "@tiptap/core";
import { Selection } from "@tiptap/pm/state";

import type { ContentNodeWithPos } from "../../core/types";
import { CodeBlockLanguage, LANGUAGE_CONFIG } from "./types";

/** Get the display name for the given `CodeBlockLanguage` */
export const getLanguageDisplay = (language: CodeBlockLanguage) => {
  return LANGUAGE_CONFIG[language]["label"];
};

/**
 * Get the `CodeMirror` language mode for a given `CodeBlockLanguage` to create
 * a mapping from language options to `CodeMirror` modes
 */
export const getLanguageMode = (language: CodeBlockLanguage) => {
  return LANGUAGE_CONFIG[language as CodeBlockLanguage]["mode"];
};

/**
 * Finds the parent `codeBlock` node from a given selection, if the selection is
 * in a `codeBlock`
 */
export const findCodeBlockSelection = (
  selection: Selection
): ContentNodeWithPos | undefined =>
  findParentNode((node) => node.type.name == "codeBlock")(selection);

/** Parses a language name written in markdown syntax to a `CodeBlockLanguage` */
export const parseMarkdownLanguage = (lang: string) => {
  for (const [key, value] of Object.entries(LANGUAGE_CONFIG)) {
    if (value.markdown.includes(lang)) return key;
  }
  return CodeBlockLanguage.PlainText;
};
