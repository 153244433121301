import styled from "styled-components";

import { Input } from ".";

interface Props extends React.ComponentProps<typeof Input> {}

/**
 * A simple extension of the `Input` component, designed specficially for
 * search.
 *
 * Includes a **permanent** search icon.
 *
 * _props extends `input` attributes_
 */
export const Search: React.FC<Props> = (props) => (
  <Container>
    <Input type="search" iconName="Search" fullWidth {...props} />
  </Container>
);

// A container that wraps the SearchInput and SearchIcon
const Container = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
`;
