import { Editor, isList } from "@tiptap/core";
import { Node as PMNode, ResolvedPos, NodeRange } from "@tiptap/pm/model";

import { isListItem } from "./isListItem";

/**
 * Try to find the previous item. Indent can only works if we can find this item.
 *
 * It may be the previous list item in the same list (in this case, `previousItem`
 * will be equal to `selectedList`), or it may be the last list item in the
 * previous list.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-command-indent.ts
 */
export function findPreviousItem(
  editor: Editor,
  selectedList: PMNode,
  $from: ResolvedPos,
  range: NodeRange
) {
  let previousItem: PMNode | undefined;
  let previousList: PMNode | undefined;
  let previousItemStart: number;
  let previousListStart: number;

  const { doc } = $from;
  if (range.startIndex >= 1) {
    previousItem = selectedList.child(range.startIndex - 1);
    previousList = selectedList;
    previousListStart = doc.resolve(range.start).start(range.depth);
    previousItemStart = previousListStart + 1;

    for (let i = 0; i < range.startIndex - 1; i++) {
      previousItemStart += previousList.child(i).nodeSize;
    }
  } else {
    const listIndex = $from.index(range.depth - 1);

    if (listIndex >= 1) {
      const listParent = $from.node(range.depth - 1);
      const listParentStart = $from.start(range.depth - 1);
      previousList = listParent.child(listIndex - 1);

      if (
        !previousItem ||
        !isList(previousItem.type.name, editor.extensionManager.extensions)
      ) {
        return false;
      }

      previousListStart = listParentStart + 1;

      for (let i = 0; i < listIndex - 1; i++) {
        previousListStart += listParent.child(i).nodeSize;
      }

      previousItem = previousList.child(previousList.childCount - 1);

      previousItemStart =
        previousListStart + previousList.nodeSize - previousItem.nodeSize - 1;

      if (
        !isListItem(previousItem.type.name, editor.extensionManager.extensions)
      ) {
        return false;
      }
    } else {
      return false;
    }
  }

  return {
    previousItem,
    previousList,
    previousItemStart,
    previousListStart,
  };
}
