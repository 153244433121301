import { IconProps } from "./types";

export const UnionIcon = (props: IconProps) => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" {...props}>
      <path
        d="M22 11.3333C22 11.1492 22.1492 11 22.3333 11H29.6667C29.8508 11 30 11.1492 30 11.3333V18.6667C30 18.8508 29.8508 19 29.6667 19H22.3333C22.1492 19 22 18.8508 22 18.6667V11.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12V18H29V12H23ZM22.3333 11C22.1492 11 22 11.1492 22 11.3333V18.6667C22 18.8508 22.1492 19 22.3333 19H29.6667C29.8508 19 30 18.8508 30 18.6667V11.3333C30 11.1492 29.8508 11 29.6667 11H22.3333Z"
        fill="#7070A9"
      />
      <path
        d="M6.20788 7.5C6.33588 7.5 6.44254 7.532 6.52788 7.596C6.62388 7.64933 6.69321 7.70267 6.73588 7.756C6.77854 7.80933 6.83188 7.89467 6.89588 8.012V13.18L6.91188 18.332L6.94388 18.54C7.10388 19.4893 7.52521 20.2893 8.20788 20.94C8.89054 21.5907 9.73321 21.9853 10.7359 22.124C10.9065 22.1453 11.1039 22.156 11.3279 22.156C12.5972 22.156 13.6852 21.7027 14.5919 20.796C15.1892 20.1773 15.5625 19.4253 15.7119 18.54L15.7439 18.332C15.7439 17.18 15.7492 15.4573 15.7599 13.164C15.7705 10.8707 15.7759 9.15333 15.7759 8.012C15.9145 7.67067 16.1279 7.5 16.4159 7.5C16.7572 7.5 16.9759 7.66 17.0719 7.98C17.0932 8.044 17.1039 9.76133 17.1039 13.132C17.1039 16.5453 17.0985 18.2893 17.0879 18.364C16.9812 19.6653 16.4692 20.7907 15.5519 21.74C14.7519 22.54 13.8239 23.068 12.7679 23.324C12.3412 23.4307 11.8612 23.484 11.3279 23.484C10.7945 23.484 10.3199 23.4307 9.90388 23.324C8.74121 23.0467 7.75454 22.4547 6.94388 21.548C6.13321 20.652 5.67454 19.5907 5.56788 18.364C5.55721 18.2893 5.55188 16.5453 5.55188 13.132C5.55188 9.76133 5.56254 8.044 5.58388 7.98C5.65854 7.66 5.86654 7.5 6.20788 7.5Z"
        fill="#3A517F"
      />
      <path d="M20 6.5H22V8.5H20V6.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5.5H23V9.5H19V5.5ZM20 6.5V8.5H22V6.5H20Z"
        fill="#7070A9"
      />
      <path d="M20 21.5H22V23.5H20V21.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 20.5H23V24.5H19V20.5ZM20 21.5V23.5H22V21.5H20Z"
        fill="#7070A9"
      />
    </svg>
  );
};
