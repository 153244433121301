import styled from "styled-components";

import { FocusAnimation } from "../styles/animations";
import { colors } from "../styles/colors";
import { Text } from "../Text";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Text label to display besides the Checkbox.
   * Has a higher priority than `children`.
   */
  content?: string;

  /**
   * Render any react node inside the Label wrapper.
   * Has a lower priority than `content`.
   */
  children?: React.ReactNode;

  /** Passes className to Label container */
  className?: string;

  /**
   * Alias for indeterminate state. Set the checkbox initial state to be mixed.
   * Note: You could see this as a variant of the checked==true state.
   * If you have checked==false, mixed will be ignored
   */
  mixed?: boolean;
}

/**
 * A control to allow a user to select one or more options of a limited number
 * of choices
 *
 * To add a simple text string to a checkbox use the `content` prop.
 * For more complex components use the `children` prop.
 *
 * _props extends `input` attributes_
 */
export const Checkbox = (props: Props) => {
  const { content, className, children, mixed, checked, ...inputProps } = props;

  const ariaChecked = checked ? (mixed ? "mixed" : true) : false;

  return (
    <Label className={className} readOnly={props.readOnly}>
      <HiddenInput
        type="checkbox"
        aria-checked={ariaChecked}
        checked={checked}
        {...inputProps}
      />
      <Box />
      {content ? (
        <Text kind="system" as="span">
          {content}
        </Text>
      ) : (
        children
      )}
    </Label>
  );
};

/* The label wraps the entire checkbox. */
const Label = styled.label<Partial<Props>>`
  display: inline-flex;
  align-items: center;

  outline: 0;

  position: relative;
  pointer-events: ${(p) => (p.readOnly ? "none" : "all")};
`;

/* The styled checkbox */
const Box = styled.span`
  flex: none;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: 6px 9px 6px 0;

  position: relative;

  box-sizing: border-box;
  border: 1px solid ${colors.grey300};
  background: white;
  border-radius: 3px;

  cursor: pointer;

  /* The :before pseudo-element creates the focus and ping outline */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    width: 100%;
    height: 100%;
  }
`;

/* The native checkbox, which is hidden */
const HiddenInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;

  width: 18px; /* Same as Box */
  height: 18px; /* Same as Box */

  cursor: pointer;

  /* Apply hover styles to Box when unchecked */
  &:not(:checked):not([aria-checked="mixed"]) ~ ${Box}:hover {
    background: ${colors.grey50};
  }

  /* Apply active styles to Box when unchecked */
  &:not(:checked) ~ ${Box}:active {
    background: ${colors.grey100};
  }

  &:not(disabled) {
    /* Apply focued styles to Box */
    &:focus ~ ${Box} {
      border-color: ${colors.grey200};
    }

    /* Apply focued styles to Box's focus pseudo-element */
    &:focus ~ ${Box}:before {
      ${FocusAnimation};
    }

    /* Apply checked styles to Box */
    &:checked ~ ${Box} {
      background: ${colors.black200};
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpolygon fill='%23FFFFFF' points='6.535 9.065 4.48 7.34 3 8.808 6.535 12 13 5.468 11.52 4'/%3E%3C/svg%3E");
    }

    /* Apply indeterminate style to Box */
    &[aria-checked="mixed"] ~ ${Box} {
      background: ${colors.black200};
      //Super handy https://yoksel.github.io/url-encoder/
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23FFF' d='M3,11 L13,11 C13.5522847,11 14,11.4477153 14,12 C14,12.5522847 13.5522847,13 13,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z'%3E%3C/path%3E%3C/svg%3E");
      background-position-y: -4px;
      background-repeat: no-repeat;
    }
  }

  /* Apply disabled styles to Box and Text */
  &:disabled ~ ${Box}, &:disabled ~ ${Text} {
    opacity: 0.36;
  }

  &:disabled ~ ${Box} {
    cursor: not-allowed;
  }
  &:read-only {
    pointer-events: none;
  }
`;
