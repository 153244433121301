export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4.02032H15V5.02032H7V4.02032ZM7 9.01016H15V10.0102H7V9.01016ZM7 14H15V15H7V14ZM4 7.79531H2.54766V3.74375L1 4.16172V2.94375L3.75547 2H4V7.79531Z"
    />
    <path d="M5.28571 17H1.12292V16.1063L3.04043 14.1077C3.51298 13.5741 3.74925 13.1503 3.74925 12.8362C3.74925 12.5816 3.69325 12.3879 3.58126 12.2552C3.46927 12.1225 3.30675 12.0562 3.09369 12.0562C2.88336 12.0562 2.71265 12.1449 2.58153 12.3223C2.45042 12.4997 2.38487 12.7211 2.38487 12.9865H1C1 12.6236 1.0915 12.2884 1.27452 11.981C1.45753 11.6737 1.71155 11.4333 2.0366 11.26C2.36165 11.0867 2.72494 11 3.12647 11C3.7711 11 4.26755 11.1476 4.61581 11.4428C4.96408 11.738 5.13821 12.1618 5.13821 12.7143C5.13821 12.9472 5.09451 13.174 5.0071 13.3947C4.91969 13.6154 4.7838 13.847 4.59943 14.0894C4.41505 14.3318 4.118 14.6561 3.70828 15.0623L2.93799 15.9438H5.28571V17Z" />
  </>
);
