import { ReactNode, cloneElement, useMemo, useState } from "react";
import {
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import styled from "styled-components";

import { Card } from "../Card";
import { colors, levels } from "../styles";
import { mergeRefs } from "../utils/mergeRefs";

export interface Props {
  /**
   * Tooltip content.
   */
  label: ReactNode;

  /**
   * Any element that should trigger tooltip.
   */
  children: JSX.Element;

  /**
   * Tooltip card padding.
   * @default "md"
   */
  size?: "sm" | "md";

  /*
   * Tooltip opened state controlled via clicks instead of hover and focus.
   * @default false
   */
  openOnClick?: boolean;

  /*
   * Tooltip id to bind aria-describedby
   */
  tooltipId?: string;

  /**
   * Tooltip width in px or auto.
   * @default 200
   */
  width?: number | "auto";

  /**
   * Tooltip initial preferred placement
   * @default "top"
   */
  placement?: Placement;

  /**
   * Tooltip delay
   * @default undefined
   */
  delay?: number | Partial<{ open: number; close: number }> | undefined;
}

/**
 * Renders tooltip at given element on hover, focus events, or in a controlled event.
 */
export const Tooltip = ({
  children,
  label,
  width = 200,
  size = "md",
  placement = "top",
  tooltipId,
  openOnClick,
  delay,
}: Props) => {
  const [open, setOpen] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [offset(5), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const isClickControlled = openOnClick === true;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { enabled: !isClickControlled, delay }),
    useFocus(context, { enabled: !isClickControlled }),
    useClick(context, { enabled: isClickControlled }),
    useRole(context, { role: "tooltip" }),
    useDismiss(context),
  ]);

  // Preserve the consumer's ref
  // eslint-disable-next-line
  const childrenRef = (children as any).ref;
  const ref = useMemo(
    () => mergeRefs([reference, childrenRef]),
    [reference, childrenRef]
  );

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {open && (
        <div
          {...getFloatingProps({
            ref: floating,
            id: tooltipId,
            className: "Tooltip",
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            },
          })}
        >
          <TooltipCard width={width} size={size}>
            {label}
          </TooltipCard>
        </div>
      )}
    </>
  );
};

const TooltipCard = styled(Card)<{ width: number | "auto"; size: "sm" | "md" }>`
  position: relative;
  z-index: 12;
  min-width: fit-content;
  width: ${(p) => (p.width === "auto" ? "initial" : `${p.width}px`)};
  padding: ${(p) => (p.size === "sm" ? "2px 4px" : "12px")};
  border-radius: 4px;
  border: 1px solid ${colors.white200};
  box-shadow: ${levels.three};
`;
