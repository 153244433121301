import { Button, Colored, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useCountdown } from "@/features/timeline/use-countdown";
import { ExamTiming } from "@/generated/graphql";
import { formatDateFull } from "@/utils/datetime";

import standingByLockIllustration from "../../assets/standing-by-lock-illustration.png";

interface Props {
  /** Current exam type **/
  examTiming: ExamTiming;
  /** Exam open date as an ISO8601 date string */
  openDate: string;
  /** Exam close date as an ISO8601 date string */
  closeDate: string | null;
  /** Total writing time allotted to the student */
  totalTime: number;
}

/**
 * React component rendering a lock screen for displaying a wait message until
 * the exam open date.
 */
export function ExamLock(props: Props) {
  const { examTiming, openDate, closeDate, totalTime } = props;

  const { message, passed } = useCountdown(openDate);

  let bodyElement = null;
  if (examTiming === ExamTiming.Live) {
    bodyElement = (
      <>
        <Text kind="headingFour">It looks like you're early</Text>
        <Text kind="headingFour">
          Your exam is scheduled to start on <br />
          <Colored color="accent">{formatDateFull(new Date(openDate))}</Colored>
        </Text>
      </>
    );
  } else if (examTiming === ExamTiming.Window) {
    bodyElement = (
      <>
        <Text kind="headingFive">It looks like you're early</Text>
        <Text kind="headingFive">
          Your exam will be open from: <br />
          <Colored color="accent">
            {formatDateFull(new Date(openDate))}
          </Colored>{" "}
          {closeDate && (
            <Colored color="accent">
              until <br />
              {formatDateFull(new Date(closeDate))}
            </Colored>
          )}{" "}
        </Text>
        <Text kind="headingFive">
          Once you're ready to start between those times, you will have <br />
          <Colored color="accent">{totalTime} minutes</Colored> to complete it
          in one sitting
        </Text>
      </>
    );
  }

  return (
    <Root>
      <Container>
        <Illustration
          src={standingByLockIllustration}
          alt="A person looking at a big lock in a browswer window, they seem to be waiting it to become unlocked"
        />
        <Message>{bodyElement}</Message>
        {!passed && (
          <Callout>
            <span>⏳</span>
            <Text kind="system">{message} to go</Text>
          </Callout>
        )}
        <RefreshButton
          kind="primary"
          onClick={() => {
            window.location?.reload?.();
          }}
        >
          Refresh Page
        </RefreshButton>
      </Container>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(89, 115, 166, 0.06);
  padding: 40px 0px;
  box-sizing: border-box;
`;

const Container = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
`;

const Callout = styled.div`
  background: ${colors.orange200};
  border-radius: 8px;
  padding: 4px 16px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const RefreshButton = styled(Button)`
  width: 180px;
  margin-top: 16px;
`;

const Illustration = styled.img`
  width: 254px;
`;
