import { Selector } from "mathlive";

import { ButtonRootProps } from "../MathKeyboardKey";

export const KEY_CATEGORIES = [
  "Popular",
  "Functions",
  "Operations",
  "Greek",
  "Relations",
  "Arrows",
  "Matrices",
  "Misc",
] as const;

export type KeyCategory = typeof KEY_CATEGORIES[number];
export type Command = Selector | [Selector, ...string[]];
export interface MathCommand extends ButtonRootProps {
  /**
   * Use as react's key and label of the tooltip
   * if not overwritten by `tooltipLabel`
   */
  label: string;
  /**
   * Render the text inside the button,
   * provide at least this or `renderChildren`
   */
  text?: string;
  /**
   * Render a more complex button content
   * rather then just a simple text using `text`
   * props
   */
  renderChildren?: () => JSX.Element | string;
  /**
   * Control whether tooltip should be shown or
   * not for the keyboard button.
   * @@default true
   */
  tooltip?: boolean;
  /**
   * Provide a custom text that is not the same as `label`
   * to be used when there is several keyboard that need
   * to show the same tooltip's label
   */
  tooltipLabel?: string;
  /**
   * Command to be inserted when button is pressed
   * when mathlive field is in focus
   */
  mathLiveCommand: string;
  /**
   * Command to be inserted when button is pressed
   * when latex textarea field is in focus.
   * defaulted to the mathLiveCommand if not provided
   */
  latexCommand?: string;
  /**
   * Provide a custom text that is not the same as `mathLiveCommand`
   * to be shown in the tooltip
   */
  tooltipCommand?: string;
}

export type MathKeys = Record<KeyCategory, MathCommand[]>;
