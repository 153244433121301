import { useCallback, useEffect, useRef, useState } from "react";
import {
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  autoUpdate,
  offset,
  safePolygon,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useHover,
  useInteractions,
} from "@floating-ui/react-dom-interactions";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import styled from "styled-components";

import { MathView, MathViewRef } from "../MathView";
import { useMathMenuOpenCallback } from "../useMathMenuOpenCallback";
import { CaptionInput } from "./CaptionInput";
import { OptionsMenu } from "./OptionsMenu";

export const MathBlockNodeView = (props: NodeViewProps) => {
  const caption = props.node.attrs.caption ?? "";
  const equation: string | undefined = props.node.attrs.equation;

  if (!props.editor.isEditable) {
    return (
      <NodeViewWrapper>
        <BlockRoot readOnly>
          <MathView
            defaultMode="math"
            readOnly
            defaultValue={equation}
            plonkSound={undefined}
            keypressSound={undefined}
          />
        </BlockRoot>
        {caption !== "" ? <Caption>{caption}</Caption> : null}
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper>
      <BlockMathHoverFloatingMenu {...props} />
      {caption !== "" ? <Caption>{caption}</Caption> : null}
    </NodeViewWrapper>
  );
};

const Caption = styled.div`
  position: absolute;
  right: 8px;
  top: 0;
  display: inline-flex;
  height: 100%;
  align-items: center;
  z-index: 400;
  font-size: 14px;
  line-height: 21px;
  pointer-events: none;
`;

const BlockMathHoverFloatingMenu = (props: NodeViewProps) => {
  const { editor, node, getPos } = props;

  const caption = node.attrs.caption ?? "";
  const equation: string | undefined = node.attrs.equation;
  const openMenu = useMathMenuOpenCallback({ editor, getPos });

  const mathViewRef = useRef<MathViewRef>(null);

  // Track hover state to show the options menu
  const [hovered, setHovered] = useState(false);
  // Track open state for the options menu
  const [innerOpen, setInnerOpen] = useState(false);
  // Track show state for the reference caption input
  const [captionEdit, setCaptionEdit] = useState(false);

  const onCaptionConfirm = useCallback(
    (newCaption: string) => {
      setCaptionEdit(false);
      editor.commands.updateEquationCaption(newCaption);
    },
    [editor, setCaptionEdit]
  );

  const nodeId = useFloatingNodeId();
  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: "right-start",
    middleware: [offset({ mainAxis: 0, alignmentAxis: -4 })],
    onOpenChange: setHovered,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: {
        open: 0,
        close: 1000,
      },
      handleClose: safePolygon(),
    }),
    useDismiss(context, { ancestorScroll: true, referencePointerDown: true }),
  ]);

  // Synchronise the MathView's math-field's value with the ProseMirror node
  // attribute
  useEffect(() => {
    mathViewRef.current?.setValue(equation, {
      suppressChangeNotifications: true,
    });
  }, [mathViewRef, equation]);

  /* console.log("Hovered", hovered); */

  return (
    <>
      <BlockRoot
        {...getReferenceProps({
          ref: reference,
        })}
        hovered={hovered || captionEdit}
      >
        <MathView
          defaultMode="math"
          ref={mathViewRef}
          readOnly
          defaultValue={equation}
          onFocus={openMenu}
          plonkSound={undefined}
          keypressSound={undefined}
        />
      </BlockRoot>
      <FloatingTree>
        <FloatingNode id={nodeId}>
          <FloatingPortal>
            {(hovered || innerOpen) && !captionEdit && (
              <div
                {...getFloatingProps({
                  ref: floating,
                  style: {
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    zIndex: 200,
                  },
                })}
              >
                <OptionsMenu
                  {...props}
                  setCaptionInputOpen={setCaptionEdit}
                  onOpenChange={setInnerOpen}
                />
              </div>
            )}
          </FloatingPortal>
        </FloatingNode>
      </FloatingTree>
      {captionEdit && (
        <CaptionInput
          caption={caption}
          onInputOpenChange={setCaptionEdit}
          onConfirm={onCaptionConfirm}
          editor={props.editor}
          getPos={props.getPos}
        />
      )}
    </>
  );
};

const BlockRoot = styled.div<{ hovered?: boolean; readOnly?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0px;

  background: ${(p) => (p.hovered ? "rgba(244, 245, 248, 0.5)" : "inherit")};

  --selection-background-color: transparent;
  cursor: pointer;

  &:hover,
  &.ProseMirror-selectednode {
    background: rgba(244, 245, 248, 0.5);
  }

  /* mathlive is not responsiveness, so we need to restrict the width */
  width: 100%;
  overflow: hidden;
`;
