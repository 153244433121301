import { Command } from "@tiptap/core";
import { SpecialIndentationStyle } from "../types";

/**
 * Create a `Command` to reset any Special Indentation style on the current blocks.
 *
 * @param styleableNodes Node names which can have Special Indentation
 * @param defaultStyle the style that is reset to
 */
export function unsetIndentStyle(
  stylableNodes: string[],
  defaultStyle: SpecialIndentationStyle
): Command {
  return ({ tr, dispatch, state }) => {
    let can = false;
    tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;
      state.doc.nodesBetween(from, to, (node, pos, parent) => {
        if (parent === state.doc && stylableNodes.includes(node.type.name)) {
          can = true;
          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            indentSpecial: defaultStyle,
          });
        }
      });
    });
    if (can) {
      dispatch?.(tr);
    }
    return can;
  };
}
