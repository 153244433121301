import { ReactNode, forwardRef, useEffect } from "react";
import { IconButton, colors } from "@vericus/cadmus-ui";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";

export interface ModalProps {
  /**
   * Modal content.
   */
  children: ReactNode;

  /**
   * Called when the modal is closed.
   */
  onClose: () => void;

  /**
   * Modal visibility state.
   * @default true
   */
  visible?: boolean;

  /**
   * Determines whether close button should be rendered.
   * @default true
   */
  withCloseButton?: boolean;

  /**
   * Determines whether overlay should be rendered.
   * @default true
   */
  withOverlay?: boolean;

  /**
   * Determines whether onClose should be called when user presses escape key.
   * @default true
   */
  closeOnEscape?: boolean;

  /**
   * Determines whether the modal should be closed when user clicks on the overlay.
   * @default true
   */
  closeOnClickOutside?: boolean;

  /**
   * Custom text color for the close SVG icon.
   * @default "black100"
   */
  closeButtonColor?: string;

  /**
   * Width of the modal in pixels.
   */
  width?: number;

  /**
   * CSS class name passed to the root.
   */
  className?: string;
}

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const {
    onClose,
    closeOnEscape = true,
    closeOnClickOutside = true,
    withCloseButton = true,
    withOverlay = true,
    closeButtonColor = colors.black100,
    visible = true,
  } = props;

  // Attach ESC handler
  useEffect(() => {
    if (closeOnEscape) {
      const onEscape = (event: KeyboardEvent) => {
        if (event.code === "Escape") {
          onClose();
        }
      };
      window.addEventListener("keydown", onEscape);
      return () => window.removeEventListener("keydown", onEscape);
    }
  }, [onClose, closeOnEscape]);

  return (
    <AnimatePresence>
      {visible && (
        <Background key="modal" className={props.className} ref={ref}>
          {withOverlay && (
            <Overlay
              transition={{ duration: 0.5, ease: "easeOut" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              onClick={closeOnClickOutside ? onClose : undefined}
            />
          )}
          <ModalCard
            width={props.width}
            initial={{ opacity: 0.75, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            exit={{ opacity: 0, y: 10 }}
          >
            {withCloseButton && (
              <CloseAction>
                <IconButton
                  onClick={onClose}
                  aria-label="Close dialog"
                  tooltipPosition="left"
                >
                  <SVG
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 12.6L6.30001 4.89995C5.91341 4.51335 5.28661 4.51335 4.90001 4.89995C4.51341 5.28655 4.51341 5.91335 4.90001 6.29995L12.6 14L4.90001 21.7C4.51341 22.0865 4.51341 22.7134 4.90001 23.1C5.28661 23.4865 5.91341 23.4865 6.30001 23.1L14 15.4L21.7 23.1C22.0866 23.4865 22.7134 23.4865 23.1 23.1C23.4866 22.7134 23.4866 22.0865 23.1 21.7L15.4 14L23.1 6.29995C23.4866 5.91335 23.4866 5.28655 23.1 4.89995C22.7134 4.51335 22.0866 4.51335 21.7 4.89995L14 12.6Z"
                      fill={closeButtonColor}
                    />
                  </SVG>
                </IconButton>
              </CloseAction>
            )}
            {props.children}
          </ModalCard>
        </Background>
      )}
    </AnimatePresence>
  );
});

const SVG = styled.svg`
  cursor: pointer;
`;

const Background = styled(motion.div)`
  position: fixed;
  display: flex;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const ModalCard = styled(motion.div)<{ width?: number }>`
  position: relative;
  z-index: 0;
  width: ${(p) => (p.width ? `${p.width}px` : `fit-content`)};

  margin: auto;

  border-radius: 2px;
  background-color: ${(p) => p.theme.background.default};
  box-shadow: 1px 2px 0px rgba(26, 31, 48, 0.06),
    0px 8px 40px rgba(89, 115, 166, 0.32);
  box-sizing: border-box;
`;

// Dimmed background overlay with `onClose` click
const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background: ${(p) => p.theme.background.default};

  background: rgba(89, 115, 166, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Positioning div for the Close button.
const CloseAction = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;
