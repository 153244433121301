import { Node, mergeAttributes } from "@tiptap/core";
import { Decoration } from "@tiptap/pm/view";

import { PlaceholderStorage } from "../../../placeholder";
import { findTableGroup } from "../../utils";

export interface TableFootnoteOptions {
  HTMLAttributes: Record<string, unknown>;
  placeholderText: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableFootnote: {
      /**
       * Toggle Table's footnote node on/off
       */
      toggleTableFootnote: () => ReturnType;
      /**
       * Hide Table's footnote node
       */
      hideTableFootnote: () => ReturnType;
    };
  }
}

export const TableFootnote = Node.create<TableFootnoteOptions>({
  name: "tableFootnote",

  addOptions() {
    return {
      HTMLAttributes: {
        "data-table-footer": "true",
      },
      placeholderText: "Add table notes and footer...",
    };
  },

  tableRole: "table_footnote",
  content: "paragraph+",
  isolating: true,

  onCreate() {
    const { type, options, editor } = this;
    const { storage } = editor;
    const { placeholderText } = options;
    (storage.placeholder as PlaceholderStorage).placeholders.set(this.name, {
      /**
       * Display the placeholder for table footnote if it has empty text content
       * and not in focus
       */
      check: (selection, node, pos) => {
        if (node.type.name !== type.name) return false;
        const nodeStart = pos;
        const nodeEnd = pos + node.nodeSize;
        const { from, to } = selection;
        if (
          (from >= nodeStart && from <= nodeEnd) ||
          (to <= nodeEnd && to >= nodeStart)
        )
          return false;
        if (node.textContent === "") return true;
        return false;
      },
      decoration: (node, pos) => {
        const child = node.firstChild;
        if (!child) return null;
        return Decoration.node(pos + 1, pos + 1 + child.nodeSize, {
          class: "empty-node",
          "data-placeholder-text": placeholderText,
        });
      },
    });
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "div",
        getAttrs(node) {
          if (typeof node === "string") return false;
          const dom = node as HTMLElement;
          if (dom.getAttribute("data-table-footer"))
            return {
              hideText:
                dom.style.display === "none" ||
                dom.getAttribute("data-cadmus-hidden") === "true",
            };
          return false;
        },
        context: "tableGroup",
      },
    ];
  },
  addCommands() {
    return {
      toggleTableFootnote:
        () =>
        ({ commands, state }) => {
          const tableGroup = findTableGroup(state.selection);
          if (tableGroup) {
            return commands.toggleShowText(tableGroup.pos, this.type.name);
          }
          return false;
        },
      hideTableFootnote:
        () =>
        ({ commands, state }) => {
          const tableGroup = findTableGroup(state.selection);
          if (tableGroup) {
            return commands.hideText(tableGroup.pos, this.type.name);
          }
          return false;
        },
    };
  },
});
