import { IconProps } from "./types";

export const SumIcon = (props: IconProps) => {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" {...props}>
      <path
        d="M21 12.3333C21 12.1492 21.1492 12 21.3333 12H28.6667C28.8508 12 29 12.1492 29 12.3333V19.6667C29 19.8508 28.8508 20 28.6667 20H21.3333C21.1492 20 21 19.8508 21 19.6667V12.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 13V19H28V13H22ZM21.3333 12C21.1492 12 21 12.1492 21 12.3333V19.6667C21 19.8508 21.1492 20 21.3333 20H28.6667C28.8508 20 29 19.8508 29 19.6667V12.3333C29 12.1492 28.8508 12 28.6667 12H21.3333Z"
        fill="#7070A9"
      />
      <path
        d="M4.08538 8.032C4.11738 8.01067 6.40004 8 10.9334 8H17.7174L18.3734 9.76C18.48 10.048 18.592 10.3573 18.7094 10.688C18.8374 11.0187 18.928 11.264 18.9814 11.424C19.0347 11.5733 19.072 11.68 19.0934 11.744H18.7734L18.4534 11.728C18.2187 11.1093 17.84 10.5707 17.3174 10.112C16.8054 9.64267 16.2134 9.30133 15.5414 9.088C14.9547 8.88533 14.1174 8.74667 13.0294 8.672C12.7094 8.65067 11.104 8.64 8.21338 8.64C6.76271 8.64 6.03738 8.64533 6.03738 8.656C6.06938 8.688 6.94938 9.89333 8.67738 12.272C10.4054 14.64 11.2747 15.8347 11.2854 15.856C11.3494 15.952 11.36 16.032 11.3174 16.096L5.49338 22.992C6.61338 23.0027 7.74938 23.008 8.90138 23.008C9.41338 23.008 9.96271 23.008 10.5494 23.008C11.136 23.008 11.6267 23.0027 12.0214 22.992C12.416 22.992 12.6347 22.992 12.6774 22.992C13.808 22.9493 14.7094 22.832 15.3814 22.64C16.576 22.2987 17.4774 21.648 18.0854 20.688C18.192 20.5173 18.3147 20.256 18.4534 19.904H19.0934C18.9334 20.3627 18.704 21.0453 18.4054 21.952C18.1067 22.8587 17.8774 23.536 17.7174 23.984L10.9334 24C6.41071 24 4.13338 23.9893 4.10138 23.968C4.03738 23.9467 4.00538 23.8987 4.00538 23.824C4.00538 23.792 4.02138 23.7547 4.05338 23.712C4.06404 23.6907 4.56004 23.0987 5.54138 21.936C6.52271 20.7733 7.50404 19.6107 8.48538 18.448L9.95738 16.704C9.95738 16.6933 8.96538 15.328 6.98138 12.608L4.02138 8.528L4.00538 8.352C4.00538 8.18133 4.03204 8.07467 4.08538 8.032Z"
        fill="#3A517F"
      />
      <path d="M10 3.5H12V5.5H10V3.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.5H13V6.5H9V2.5ZM10 3.5V5.5H12V3.5H10Z"
        fill="#7070A9"
      />
      <path d="M10 27H12V29H10V27Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 26H13V30H9V26ZM10 27V29H12V27H10Z"
        fill="#7070A9"
      />
    </svg>
  );
};
