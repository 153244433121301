import React, { cloneElement, useCallback, useState } from "react";
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useId,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";

interface Props {
  /**
   * Render Popover contents with props for a11y and modal management.
   */
  render: (data: {
    close: () => void;
    labelId: string;
    descriptionId: string;
  }) => React.ReactNode;

  /**
   * React element used as the Popover target.
   */
  children: JSX.Element;

  /**
   * Select starting placement.
   * @default "bottom-start"
   */
  placement?: Placement;

  /**
   * Set main axis offset in pixels
   * @default 6(px)
   */
  mainAxisOffset?: number;

  /**
   * Temporarily stop dismiss interactions if required.
   */
  preventDismiss?: boolean;

  /**
   * Receive the popover open state on a callback.
   */
  onOpenChange?: (open: boolean) => void;
  /**
   * Auto focus to the popover children upon opening
   * @default true
   */
  autoFocus?: boolean;
}

/**
 * Render content in a modal Popover, supporting nested floating menus/popovers
 * inside it.
 */
export const Popover = (props: Props) => {
  const parentId = useFloatingParentNodeId();

  if (parentId == null) {
    return (
      <FloatingTree>
        <PopoverComponent {...props} />
      </FloatingTree>
    );
  }

  return <PopoverComponent {...props} />;
};

const PopoverComponent = ({
  autoFocus = true,
  children,
  render,
  placement,
  preventDismiss,
  onOpenChange,
  mainAxisOffset = 6,
}: Props) => {
  const [open, setOpen] = useState(false);
  const internalOpenChange = useCallback(
    (newOpen: boolean) => {
      onOpenChange?.(newOpen);
      setOpen(newOpen);
    },
    [setOpen, onOpenChange]
  );
  const nodeId = useFloatingNodeId();
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: internalOpenChange,
    nodeId,
    middleware: [offset(mainAxisOffset), flip(), shift()],
    placement: placement ?? "bottom-start",
    whileElementsMounted: autoUpdate,
  });

  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      enabled: !preventDismiss,
    }),
  ]);

  const content = (
    <div
      {...getFloatingProps({
        className: "Popover",
        ref: floating,
        style: {
          position: strategy,
          top: y ?? "",
          left: x ?? "",
        },
        "aria-labelledby": labelId,
        "aria-describedby": descriptionId,
      })}
    >
      {render({
        labelId,
        descriptionId,
        close: () => {
          internalOpenChange(false);
        },
      })}
    </div>
  );

  return (
    <FloatingNode id={nodeId}>
      {cloneElement(
        children,
        getReferenceProps({ ref: reference, ...children.props })
      )}
      <FloatingPortal>
        {open &&
          (autoFocus ? (
            <FloatingFocusManager context={context}>
              {content}
            </FloatingFocusManager>
          ) : (
            <>{content}</>
          ))}
      </FloatingPortal>
    </FloatingNode>
  );
};
