import { ReactNode } from "react";
import { Icon } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { colors } from "./styles";

export interface ErrorMessageProps {
  children: ReactNode;
  fullWidth?: boolean;
  /**
   * @default "absolute"
   */
  position?: "relative" | "absolute";
  /**
   * @default "12px"
   */
  textSize?: string;
}

const defaultTextSize = "12px";

export const ErrorMessage = ({
  fullWidth,
  children,
  position = "absolute",
  textSize = defaultTextSize,
}: ErrorMessageProps) => {
  return (
    <ErrorContainer
      fullWidth={fullWidth}
      position={position}
      textSize={textSize}
      data-error="true"
    >
      <div style={{ marginTop: 1, flexShrink: 0 }}>
        <Icon iconName="Warning" />
      </div>

      <div aria-live="assertive">{children}</div>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div<
  Pick<ErrorMessageProps, "fullWidth" | "position" | "textSize">
>`
  position: ${(p) => p.position};
  top: 100%;
  align-items: flex-start;
  background-color: ${colors.red200};
  box-sizing: border-box;
  font-weight: inherit;
  color: ${(p) => p.theme.fail};
  font-size: ${(p) => p.textSize};
  display: flex;
  gap: 6px;
  min-width: 180px;
  padding: 5px 10px;
  width: ${(p) => (p.fullWidth ? "100%" : "180px")};
`;
