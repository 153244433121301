import React, { forwardRef } from "react";
import { Icon, IconName } from "@vericus/cadmus-icons";
import styled, { css } from "styled-components";

import { easeOutQuart } from "./styles/animations";
import { Color, colors } from "./styles/colors";
import { typography } from "./styles/typography";

export type LinkButtonKind = "solid" | "primary" | "error";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * React children
   */
  children?: React.ReactNode;

  /** Changes color depending on kind */
  kind?: LinkButtonKind;

  /** Add a Cadmus Icon by its name */
  iconName?: IconName;

  /** Use one of Cadmus' colors. Takes precedence over `kind` */
  color?: Color;

  /** When using inline set to true to inherit parent font styles */
  inline?: boolean;

  /**
   * adds left margin in px
   * @default 0
   */
  marginLeft?: number;

  /**
   * adds right margin in px
   * @default 0
   */
  marginRight?: number;
}

/**
 * A text button, styled as a link.
 *
 * LinkButtons can be used on their own, or inline with `Text`. You can
 * add an *optional* Cadmus `Icon`, which will be positioned left of the
 * button text.
 *
 * LinkButtons are typically used for secondary actions, or where actions are
 * regularly repeated.
 *
 * _props extends `button` attributes_
 *
 */
export const LinkButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { kind, iconName, color, inline, children, ...buttonProps } = props;
  return (
    <Container ref={ref} inline={inline} {...buttonProps}>
      {iconName && <LinkButtonIcon iconName={iconName} />}
      <LinkButtonText kind={kind} color={color}>
        {children}
      </LinkButtonText>
    </Container>
  );
});

// Helper to switch the base text color and underline color according to its  type.
function linkColor(kind?: LinkButtonKind, color?: Color) {
  if (color) {
    return css`
      color: ${colors[color]};
      fill: ${colors[color]};
      background-image: linear-gradient(
          to right,
          ${colors[color]} 0%,
          ${colors[color]} 100%
        ),
        linear-gradient(
          to right,
          ${(p) => p.theme.text.shade2} 0%,
          ${(p) => p.theme.text.shade2} 50%,
          transparent 50%,
          transparent 100%
        );
    `;
  }
  switch (kind) {
    case "solid":
      return css`
        color: ${(p) => p.theme.text.main};
        fill: ${(p) => p.theme.text.main};
        background-image: linear-gradient(
            to right,
            ${(p) => p.theme.text.main} 0%,
            ${(p) => p.theme.text.main} 100%
          ),
          linear-gradient(
            to right,
            ${(p) => p.theme.text.shade2} 0%,
            ${(p) => p.theme.text.shade2} 50%,
            transparent 50%,
            transparent 100%
          );
      `;
    case "primary":
      return css`
        color: ${(p) => p.theme.text.accent};
        fill: ${(p) => p.theme.text.accent};
        background-image: linear-gradient(
            to right,
            ${(p) => p.theme.text.accent} 0%,
            ${(p) => p.theme.text.accent} 100%
          ),
          linear-gradient(
            to right,
            ${(p) => p.theme.text.shade2} 0%,
            ${(p) => p.theme.text.shade2} 50%,
            transparent 50%,
            transparent 100%
          );
      `;
    case "error":
      return css`
        color: ${colors.red500};
        fill: ${colors.red500};
        background-image: linear-gradient(
            to right,
            ${(p) => p.theme.fail} 0%,
            ${(p) => p.theme.fail} 100%
          ),
          linear-gradient(
            to right,
            ${(p) => p.theme.text.shade2} 0%,
            ${(p) => p.theme.text.shade2} 50%,
            transparent 50%,
            transparent 100%
          );
      `;
    default:
      return css`
        color: ${(p) => p.theme.text.shade1};
        fill: ${(p) => p.theme.text.shade1};
        background-image: linear-gradient(
            to right,
            ${(p) => p.theme.text.shade1} 0%,
            ${(p) => p.theme.text.shade1} 100%
          ),
          linear-gradient(
            to right,
            ${(p) => p.theme.text.shade2} 0%,
            ${(p) => p.theme.text.shade2} 50%,
            transparent 50%,
            transparent 100%
          );
      `;
  }
}

// The icon, with additional spacing
const LinkButtonIcon = styled(Icon)`
  margin-right: 0.3em;
`;

// The text for the LinkButton
export const LinkButtonText = styled.span<Props>`
  ${(p) => linkColor(p.kind, p.color)};

  display: inline;

  background-size: 0% 1px, 2px 1px;
  background-repeat: no-repeat, repeat-x;
  background-position: bottom left;

  transform: translateZ(0);
  will-change: background;
  transition: background 0.3s ${easeOutQuart};
`;

// The button itself
const Container = styled.button<Props>`
  /* Apply typography styles based on inline inheritance or system default */
  font-size: ${(p) => (p.inline ? "inherit" : typography["system"].fontSize)};
  letter-spacing: ${(p) =>
    p.inline ? "inherit" : typography["system"].letterSpacing};
  font-weight: ${(p) => (p.inline ? "inherit" : "initial")};
  line-height: ${(p) =>
    p.inline ? "inherit" : typography["system"].lineHeight};

  text-align: left;
  background: transparent;
  text-decoration: none;
  border: none;
  padding: 0;
  outline: 0;

  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : 0)};
  margin-right: ${(p) => (p.marginRight ? `${p.marginRight}px` : 0)};

  position: relative;
  cursor: pointer;

  /* On hover, animate the underline of the LinkButtonText */

  &:not(:disabled) {
    &:hover > ${LinkButtonText} {
      background-size: 100% 1px, 2px 1px;
    }

    &:active > ${LinkButtonText}, &:focus > ${LinkButtonText} {
      background-size: 100% 2px, 2px 1px;
    }
  }

  &:disabled {
    opacity: 0.54;
    cursor: not-allowed;
  }
`;
