import type { AppStartListening } from "@/data/listenerMiddleware";
import { isAnyOf } from "@reduxjs/toolkit";
import { addMinutes, isAfter } from "date-fns";
import { toast } from "react-hot-toast";

import { selectHasAutoSubmission } from "@/features/assignment";
import {
  selectActiveWritingDate,
  toasts,
  examStartDatePassed,
  readingDatePassed,
  selectActiveReadingDate,
  workStartDatePassed,
  TimelineState,
} from "@/features/timeline";

import { createDateListener } from "./create-date-listener";

import { getCurrentDate } from "@/utils/datetime";

export function startTimelineToastListeners(startListening: AppStartListening) {
  startListening({
    matcher: isAnyOf(
      examStartDatePassed,
      workStartDatePassed,
      readingDatePassed
    ),
    effect: async (_, listenerAPI) => {
      const state = listenerAPI.getState();

      const readingDate = selectActiveReadingDate(state);
      if (readingDate) {
        toasts.reading(readingDate);
      }

      const writingDate = selectActiveWritingDate(state);
      if (writingDate) {
        toast.dismiss();
        toasts.writing(writingDate);
      }
    },
  });

  // Notify when close to writingDate for submission
  createDateListener(selectSubmissionReminderPoint, (listenerAPI) => {
    const writingDate = selectActiveWritingDate(listenerAPI.getState());
    const autoSubmission = selectHasAutoSubmission(listenerAPI.getState());

    if (writingDate) {
      toasts.submitReminder({
        submissionDate: writingDate,
        autoSubmission,
      });
    }
  })(startListening);

  // Notify when close to lateSubmissionDate
  createDateListener(selectLateSubmissionReminderPoint, () => {
    toasts.lateSubmitReminder();
  })(startListening);
}

/**
 * Select a Timeline date at which a submission reminder should be sent.
 */
const selectSubmissionReminderPoint = (timeline: TimelineState) => {
  const writingDate = timeline.writingDate.date;
  if (writingDate) {
    const reminderDate = addMinutes(new Date(writingDate), -10);
    return {
      date: reminderDate.toISOString(),
      passed: isAfter(getCurrentDate(), reminderDate),
      extended: false,
    };
  } else {
    return {
      date: null,
      passed: true,
      extended: false,
    };
  }
};

const selectLateSubmissionReminderPoint = (timeline: TimelineState) => {
  const lateSubmissionDate = timeline.examLateSubmissionDate.date;

  if (lateSubmissionDate) {
    const reminderDate = addMinutes(new Date(lateSubmissionDate), -1);
    return {
      date: reminderDate.toISOString(),
      passed: isAfter(getCurrentDate(), reminderDate),
      extended: false,
    };
  } else {
    return {
      date: null,
      passed: true,
      extended: false,
    };
  }
};
