/**
 * adopted from https://discuss.prosemirror.net/t/adding-div-with-foreign-html-content/2027/6
 */
import type { DOMOutputSpec } from "@tiptap/pm/model";

import { domParserConverter } from "./domParserConverter";

const defaultSpec: DOMOutputSpec = ["p", {}, ""];

/**
 * Convert an HTML string into ProseMirror's DOMOutputSpec,
 * but always wrap result in an Array for destructuring.
 */
export function htmlToDOMOutputSpec(
  html?: string | null,
  parser = domParserConverter
) {
  if (html == null || html === "") {
    return [defaultSpec] as DOMOutputSpec[];
  }
  return parser(html);
}
