import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 12.6L6.30001 4.89995C5.91341 4.51335 5.28661 4.51335 4.90001 4.89995C4.51341 5.28655 4.51341 5.91335 4.90001 6.29995L12.6 14L4.90001 21.7C4.51341 22.0865 4.51341 22.7134 4.90001 23.1C5.28661 23.4865 5.91341 23.4865 6.30001 23.1L14 15.4L21.7 23.1C22.0866 23.4865 22.7134 23.4865 23.1 23.1C23.4866 22.7134 23.4866 22.0865 23.1 21.7L15.4 14L23.1 6.29995C23.4866 5.91335 23.4866 5.28655 23.1 4.89995C22.7134 4.51335 22.0866 4.51335 21.7 4.89995L14 12.6Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
