export default (
  <>
    <path
      d="M0.466073 0.669948V0.672626C0.202715 0.723518 -0.00468088 0.983639 8.03613e-05 1.25594V18.7519C8.03613e-05 19.0559 0.274892 19.3333 0.575429 19.3333H15.424C15.7253 19.3333 16 19.056 16 18.7506V5.22679C15.9946 5.13766 15.9693 5.04956 15.9266 4.97159L11.5113 0.705685C11.454 0.68381 11.3927 0.671757 11.3312 0.669227H0.574995C0.539286 0.665804 0.501782 0.666525 0.466073 0.669948Z"
      fill="#CB4A32"
    />
    <path
      d="M8.21936 5.99756V10.8182H13.0403C12.8572 8.2431 10.7938 6.18019 8.21936 5.99756Z"
      fill="white"
    />
    <path
      d="M7.04984 7.08911C4.75989 7.27817 2.95972 9.19596 2.95972 11.5351C2.95972 13.9999 4.95741 15.9976 7.4222 15.9976C9.76185 15.9976 11.6796 14.1979 11.8687 11.9075L7.04984 11.9077V7.08911Z"
      fill="white"
      fillOpacity="0.75"
    />
  </>
);
