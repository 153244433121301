import { Command } from "@tiptap/core";

/**
 * Create a `Command` to decrease level indentation of blocks in current selection until 0.
 *
 * @param intentableNodes node names which are capabale of level indentation
 */
export function decreaseIndent(indentableNodes: string[]): Command {
  return ({ tr, dispatch, state }) => {
    let can = false;
    tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;
      state.doc.nodesBetween(from, to, (node, pos, parent) => {
        const $pos = state.doc.resolve(pos);
        if (
          parent === state.doc &&
          indentableNodes.includes(node.type.name) &&
          node.attrs.indentLevel > 0 &&
          $pos.depth <= 1
        ) {
          can = true;
          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            indentLevel: node.attrs.indentLevel - 1,
          });
        }
      });
    });
    if (can) {
      dispatch?.(tr);
    }
    return can;
  };
}
