import { IconProps } from "./types";

export const NotExistsIcon = (props: IconProps) => {
  return (
    <svg width="27" height="28" viewBox="0 0 27 28" {...props}>
      <path d="M9.75858 9.228C9.59058 9.144 9.50658 9.024 9.50658 8.868C9.50658 8.712 9.59058 8.592 9.75858 8.508H17.2646C17.3846 8.604 17.4626 8.694 17.4986 8.778V20.73C17.4746 20.79 17.3966 20.874 17.2646 20.982L13.5206 21H9.75858C9.59058 20.916 9.50658 20.796 9.50658 20.64C9.50658 20.484 9.59058 20.364 9.75858 20.28H16.7786V15.114H10.0106C9.84258 15.03 9.75858 14.91 9.75858 14.754C9.75858 14.598 9.84258 14.478 10.0106 14.394H16.7786V9.228H9.75858Z" />
      <path d="M16.3256 5.96491C16.3856 5.84491 16.4936 5.78491 16.6496 5.78491C16.7456 5.79691 16.8296 5.83891 16.9016 5.91091C16.9736 5.98291 17.0036 6.06091 16.9916 6.14491C16.9916 6.20491 15.9476 9.10291 13.8596 14.8389C11.7836 20.5629 10.7156 23.4849 10.6556 23.6049C10.5836 23.7249 10.4876 23.7849 10.3676 23.7849C10.2716 23.7849 10.1876 23.7489 10.1156 23.6769C10.0436 23.6169 10.0076 23.5389 10.0076 23.4429C10.0076 23.3229 11.0516 20.3949 13.1396 14.6589C15.2276 8.91091 16.2896 6.01291 16.3256 5.96491Z" />
    </svg>
  );
};
