import { Extension } from "@tiptap/core";
import { CellSelection, TableMap, selectedRect } from "@tiptap/pm/tables";

import { defaultTableColor, tableColors } from "../constants";
import { findTable } from "../utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    tableBackgroundStyle: {
      /**
       * Toggle alternate table color on/off with the specified `color`
       */
      toggleTableAlternateColor: (color?: string) => ReturnType;
      /**
       * Set table background color to the specified `color`
       */
      setTableColor: (color: string) => ReturnType;
    };
  }
}

/**
 * Editor Extension to add alternate table row background capabilities
 */
export const TableBackgroundAlternate = Extension.create({
  name: "TableBackgroundAlternate",
  addGlobalAttributes() {
    return [
      {
        types: ["table"],
        attributes: {
          alternateBackground: {
            default: false,
            parseHTML: (element) =>
              element.getAttribute("data-table-alternate-background") ===
              "true",
            renderHTML: (attributes) => {
              return {
                "data-table-alternate-background":
                  attributes.alternateBackground ? "true" : "false",
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      toggleTableAlternateColor:
        (color = defaultTableColor) =>
        ({ state, tr, dispatch }) => {
          const selection = state.selection;
          const table = findTable(selection);
          if (!table) return false;
          const { pos, node } = table;
          const { attrs } = node;
          const currAlternate = attrs.alternateBackground;
          const newAttrs = {
            alternateBackground: !currAlternate,
            backgroundColor: currAlternate ? undefined : color,
          };
          const rect = selectedRect(state);
          const map = TableMap.get(node);
          tr.setNodeMarkup(pos, undefined, {
            ...attrs,
            ...newAttrs,
          }).setSelection(
            // @ts-ignore
            new CellSelection(
              tr.doc.resolve(
                map.positionAt(rect.top, rect.left, node) + table.start
              ),
              tr.doc.resolve(
                map.positionAt(rect.bottom - 1, rect.right - 1, node) +
                  table.start
              )
            )
          );
          dispatch?.(tr);
          return true;
        },
      setTableColor:
        (color: string) =>
        ({ state, tr, dispatch }) => {
          const selection = state.selection;
          const table = findTable(selection);
          if (!table) return false;
          const { pos, node } = table;
          const { attrs } = node;
          const currAlternate = attrs.alternateBackground;
          if (!currAlternate) return false;
          const newColor = tableColors.find(
            ({ actual }) => actual === color
          )?.actual;
          if (!newColor) return false;
          const newAttrs = {
            backgroundColor: newColor,
          };
          const rect = selectedRect(state);
          const map = TableMap.get(node);
          tr.setNodeMarkup(pos, undefined, {
            ...attrs,
            ...newAttrs,
          }).setSelection(
            // @ts-ignore
            new CellSelection(
              tr.doc.resolve(
                map.positionAt(rect.top, rect.left, node) + table.start
              ),
              tr.doc.resolve(
                map.positionAt(rect.bottom - 1, rect.right - 1, node) +
                  table.start
              )
            )
          );

          dispatch?.(tr);
          return true;
        },
    };
  },
});
