import { IconProps } from "./types";

export const NotEqualIcon = (props: IconProps) => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" {...props}>
      <path d="M8.00317 12.754C8.00317 12.598 8.08717 12.478 8.25517 12.394H19.7212C19.9012 12.478 19.9912 12.598 19.9912 12.754C19.9912 12.886 19.9072 13 19.7392 13.096L14.0152 13.114H8.29117C8.09917 13.054 8.00317 12.934 8.00317 12.754ZM8.00317 16.246C8.00317 16.066 8.09917 15.946 8.29117 15.886H19.7392C19.7512 15.898 19.7752 15.922 19.8112 15.958C19.8592 15.982 19.8892 16.006 19.9012 16.03C19.9252 16.042 19.9432 16.072 19.9552 16.12C19.9792 16.156 19.9912 16.198 19.9912 16.246C19.9912 16.402 19.9012 16.522 19.7212 16.606H8.25517C8.08717 16.522 8.00317 16.402 8.00317 16.246Z" />
      <path d="M16.826 5.68C16.886 5.56 16.9941 5.5 17.15 5.5C17.2461 5.512 17.33 5.554 17.402 5.626C17.474 5.698 17.5041 5.776 17.4921 5.86C17.4921 5.92 16.4481 8.818 14.36 14.554C12.284 20.278 11.216 23.2 11.156 23.32C11.084 23.44 10.988 23.5 10.868 23.5C10.772 23.5 10.688 23.464 10.616 23.392C10.544 23.332 10.508 23.254 10.508 23.158C10.508 23.038 11.552 20.11 13.64 14.374C15.728 8.626 16.79 5.728 16.826 5.68Z" />
    </svg>
  );
};
