export default (
  <>
    <path d="M15.7491 8.12873C15.8344 8.21406 15.9181 8.30045 16 8.38786L14.8923 9.49556C14.8109 9.40776 14.7275 9.32113 14.6421 9.23574C10.9738 5.56742 5.02624 5.56742 1.35791 9.23574C1.27252 9.32113 1.18912 9.40776 1.1077 9.49556L0 8.38786C0.081931 8.30045 0.165563 8.21406 0.250897 8.12873C4.53061 3.84902 11.4694 3.84902 15.7491 8.12873Z" />
    <path d="M13.5351 10.3428C13.6205 10.4282 13.7036 10.5152 13.7843 10.6035L12.6758 11.712C12.5962 11.6228 12.5136 11.5354 12.4281 11.4498C9.98251 9.00422 6.01749 9.00422 3.57194 11.4498C3.48636 11.5354 3.40377 11.6228 3.32418 11.712L2.21571 10.6035C2.2964 10.5152 2.37947 10.4282 2.46493 10.3428C5.52186 7.28582 10.4781 7.28582 13.5351 10.3428Z" />
    <path d="M11.5664 12.8214C11.4886 12.7309 11.4068 12.6426 11.321 12.5568C9.48688 10.7226 6.51312 10.7226 4.67896 12.5568C4.59316 12.6426 4.51138 12.7309 4.43361 12.8214L5.54551 13.9333C5.61958 13.8401 5.69973 13.75 5.78597 13.6638C7.00875 12.441 8.99126 12.441 10.214 13.6638C10.3003 13.75 10.3804 13.8401 10.4545 13.9333L11.5664 12.8214Z" />
    <path d="M8 16.3878L9.3325 15.0553C9.26994 14.9542 9.19478 14.8586 9.10702 14.7708C8.49563 14.1594 7.50438 14.1594 6.89299 14.7708C6.80523 14.8586 6.73007 14.9542 6.6675 15.0553L8 16.3878Z" />
    <path
      d="M9.21875 14.2793H9.58428L9.59363 13.9139L9.87488 2.91388L9.88471 2.5293H9.5H6.5H6.1157L6.12511 2.91348L6.39464 13.9135L6.40361 14.2793H6.76953H9.21875Z"
      fill="#D92644"
      stroke="white"
      strokeWidth="0.75"
    />
    <path
      d="M6.53659 13.9776C6.1722 14.3682 6 14.8568 6 15.4049C6 15.939 6.16928 16.4161 6.52336 16.801C6.90092 17.2113 7.41811 17.3877 8 17.3877C8.58189 17.3877 9.09908 17.2113 9.47664 16.801C9.83072 16.4161 10 15.939 10 15.4049C10 14.8568 9.82779 14.3682 9.46341 13.9776C9.08367 13.5706 8.57524 13.3877 8 13.3877C7.42476 13.3877 6.91633 13.5706 6.53659 13.9776Z"
      fill="#D92644"
      stroke="white"
    />
  </>
);
