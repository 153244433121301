import React from "react";
import { Text, Tooltip, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

export interface ButtonRootProps {
  /**
   * Set KaTeX font for the rendered text
   * @default "main"
   */
  kind?: "main" | "math" | "ams" | "script" | "size1" | undefined;
  /**
   * Set font size for the rendered text
   * @default "normal"
   */
  size?: "normal" | "small" | undefined;
}

interface MathKeyboardKeyProps
  extends React.HTMLAttributes<HTMLButtonElement>,
    ButtonRootProps {
  tooltipLabel?: string;
  tooltipCommand?: string;
  tooltip?: boolean;
}

export function MathKeyboardKey(props: MathKeyboardKeyProps) {
  const { tooltip = true, tooltipLabel, tooltipCommand, ...rest } = props;
  if (!tooltip) {
    return <ButtonRoot {...rest} />;
  }
  return (
    <Tooltip
      size="sm"
      width="auto"
      delay={{ open: 300 }}
      label={
        <MathTooltip kind="subtle" as="span">
          {tooltipLabel}
          {tooltipCommand && tooltipCommand !== "" ? (
            <MathTooltipCommand>{tooltipCommand}</MathTooltipCommand>
          ) : null}
        </MathTooltip>
      }
    >
      <ButtonRoot {...rest} />
    </Tooltip>
  );
}

const MathTooltip = styled(Text)`
  display: inline-flex;
  align-items: center;
  gap: 2px;
`;

const MathTooltipCommand = styled.span`
  background-color: ${colors.grey100};
  border-radius: 2px;
  padding: 2px 6px;
`;

const ButtonRoot = styled.button<ButtonRootProps>`
  width: 36px;
  height: 36px;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px;
  border: none;
  outline: 0;
  position: relative;
  cursor: pointer;

  background: ${colors.white200};
  border: 0.75px solid ${colors.grey200};
  fill: rgba(58, 81, 127, 1);
  color: rgba(58, 81, 127, 1);

  font-family: ${(p) => getFontFamily(p)};
  font-style: normal;
  font-weight: ${(p) => (p.size === "small" ? "700" : "400")};
  font-size: ${(p) => (p.size === "small" ? "12px" : "18px")};
  line-height: ${(p) => (p.size === "small" ? "18px" : "26px")};
  letter-spacing: -0.3px;

  &:not(:disabled) {
    &:hover {
      background: #e6eaf2;
    }
    &:active {
      outline: 1.3px solid ${(p) => p.theme.primaryColor};
    }
  }
`;

function getFontFamily(p: ButtonRootProps) {
  switch (p.kind) {
    case "math":
      return "KaTeX_Math";
    case "ams":
      return "KaTeX_AMS";
    case "script":
      return "KaTeX_Script";
    case "size1":
      return "KaTeX_Size1";
    case "main":
    default:
      return "KaTeX_Main";
  }
}
