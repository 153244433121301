import { useMemo } from "react";
import { Alert, Button, Text, colors } from "@vericus/cadmus-ui";
import { differenceInMinutes } from "date-fns";
import styled from "styled-components";

import { getCurrentDate } from "@/utils/datetime";

interface Props {
  /**
   * Callback to start working.
   */
  onStartWork: () => void;
  /**
   * Datetime when no more writing is possible.
   */
  closeDate: string;
  /**
   * Total number of minutes a student has to do their exam.
   */
  totalMinutes: number;
  /**
   * Disable the start button.
   * @default false
   */
  disabled?: boolean;
}

/**
 * React component rendering a blocking CTA to start the exam within the
 * Instruction Sheet pane.
 */
export function ExamStartPanel(props: Props) {
  const { disabled = false, closeDate, totalMinutes, onStartWork } = props;

  const limitedTime = useMemo(() => {
    const close = new Date(closeDate);
    const remaining = differenceInMinutes(close, getCurrentDate());
    if (remaining < totalMinutes) {
      return `${remaining} minutes`;
    }
    return null;
  }, [closeDate, totalMinutes]);

  return (
    <Root>
      <Box>
        <Text kind="headingThree" textAlign="center">
          Ready to start?
        </Text>
        {limitedTime ? (
          <Alert severity="warning">
            You have {limitedTime} to submit before the end of this exam
          </Alert>
        ) : (
          <Text kind="paragraph" textAlign="center">
            You will not be able to stop the clock once you begin.
          </Text>
        )}
        <StartButton
          kind="primary"
          size="lg"
          onClick={onStartWork}
          disabled={disabled}
        >
          Start Exam
        </StartButton>
      </Box>
    </Root>
  );
}

const StartButton = styled(Button)`
  width: 220px;
  margin-top: 16px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  padding: 40px;
  box-sizing: border-box;
  background: ${colors.grey50};
`;

const Root = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  margin: auto;
  padding: 0 45px;
  box-sizing: border-box;
`;
