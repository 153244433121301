import { Extension, findChildren } from "@tiptap/core";
import { TableRow } from "@tiptap/extension-table-row";
import { Fragment, NodeType, Node as PMNode, Slice } from "@tiptap/pm/model";
import { TextSelection } from "@tiptap/pm/state";
import {
  CellSelection,
  TableMap,
  __clipCells,
  __insertCells,
  __pastedCells,
  addColumn,
  addRow,
  isInTable,
  selectionCell,
} from "@tiptap/pm/tables";
import { Transform } from "@tiptap/pm/transform";

import { findCodeBlockSelection } from "../code-block/utils";
import { cellDefaultWidth, cellMinWidth } from "./constants";
import {
  TableBackgroundAlternate,
  TableCaption,
  TableCell,
  TableCellBorder,
  TableFootnote,
  TableGroup,
  TableHeader,
  Table as TableNode,
} from "./extensions";
import {
  TableControlPluginKey,
  TableDeleteIndicatorPlugin,
  TableDeleteIndicatorPluginKey,
  TableEnsureGroupPlugin,
} from "./plugins";
import {
  createTable,
  findCellClosestToPos,
  findTable,
  findTableCaption,
  findTableFootnote,
  findTableGroup,
  getTableNodeTypes,
  isSelectionIncludeTable,
  isTableSelected,
  selectColumn,
  selectRow,
  selectTable,
  setCellsBackground,
} from "./utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    captionedTable: {
      /**
       * Insert table with caption and footnote, unlike the
       * `insertTable` from @tiptap/extensions-table which
       * only insert table node
       */
      insertTableWithCaption: (options?: {
        rows?: number;
        cols?: number;
        withHeaderRow?: boolean;
      }) => ReturnType;
      /**
       * Select row on `rowIndex`
       */
      selectRow: (rowIndex: number) => ReturnType;
      /**
       * Select column on `columnIndex`
       */
      selectColumn: (columnIndex: number) => ReturnType;
      /**
       * Select table
       */
      selectTable: () => ReturnType;
      /**
       * Select cell text contents.
       */
      selectCellContent: () => ReturnType;
      /**
       * Select Table Caption
       */
      selectTableCaption: () => ReturnType;
      /**
       * Select Table Footnote
       */
      selectTableFootnote: () => ReturnType;
      /**
       * Set selected cells background color to `color`
       */
      setCellsBackground: (color?: string) => ReturnType;
      /**
       * Add new column at `columnIndex`
       */
      addColumnAt: (columnIndex: number) => ReturnType;
      /**
       * Add new row at `rowIndex`
       */
      addRowAt: (rowIndex: number) => ReturnType;
      /**
       * Delete selected row(s), column(s), or table
       */
      deleteSelectedTable: () => ReturnType;
      /**
       * Show/hide visual indicator on which row(s), column(s) or table will be deleted
       */
      predeleteHighlightTable: (show: boolean) => ReturnType;
      /**
       * Show/hide visual indicator on where row will be inserted
       */
      preinsertRow: (rowIndex?: number) => ReturnType;
      /**
       * Show/hide visual indicator on where column will be inserted
       */
      preinsertCol: (colIndex?: number) => ReturnType;
      /**
       * Add paste chip after table group on paste
       */
      addTablePasteChip: () => ReturnType;
      /**
       * Handle table paste merging if table is pasted into a table
       */
      handleTablePaste: (slice: Slice) => ReturnType;
    };
  }
}

/**
 *
 */
export const Table = Extension.create({
  name: "cadmusTable",
  addExtensions() {
    return [
      TableCaption,
      TableCell.configure({
        defaultColWidth: cellDefaultWidth,
      }),
      TableFootnote,
      TableGroup,
      TableHeader.configure({
        defaultColWidth: cellDefaultWidth,
      }),
      TableNode.configure({
        resizable: true,
        lastColumnResizable: true,
        handleWidth: 5,
        cellMinWidth: cellMinWidth,
        allowTableNodeSelection: true,
      }),
      TableRow,
      TableBackgroundAlternate,
      TableCellBorder,
    ];
  },

  addProseMirrorPlugins() {
    return [TableDeleteIndicatorPlugin(), TableEnsureGroupPlugin()];
  },

  addCommands() {
    return {
      insertTableWithCaption:
        ({ rows = 2, cols = 2, withHeaderRow = false } = {}) =>
        ({ chain, dispatch, state }) => {
          if (
            findCodeBlockSelection(state.selection) ||
            findTableGroup(state.selection)
          )
            return false;

          if (dispatch) {
            return chain()
              .command(({ tr, dispatch, state }) => {
                const node = createTable(
                  state.schema,
                  rows,
                  cols,
                  withHeaderRow
                );
                const offset = tr.selection.anchor + 1;
                tr.replaceSelectionWith(node)
                  .scrollIntoView()
                  // set selection inside table group
                  .setSelection(TextSelection.near(tr.doc.resolve(offset)));
                dispatch?.(tr);
                return true;
              })
              .command(({ tr }) => {
                const tableGroup = findTableGroup(tr.selection);
                if (tableGroup?.node) {
                  const predicate = (node: PMNode) =>
                    node.type.spec.tableRole &&
                    /cell/i.test(node.type.spec.tableRole);
                  const firstCell = findChildren(tableGroup.node, predicate)[0];
                  if (firstCell?.node) {
                    // set selection to first cell
                    tr.setSelection(
                      TextSelection.near(
                        tr.doc.resolve(tableGroup.start + firstCell.pos)
                      )
                    );
                    dispatch?.(tr);
                  }
                }
                return true;
              })
              .run();
          }

          return true;
        },
      selectRow:
        (rowIndex: number) =>
        ({ tr, dispatch }) => {
          dispatch?.(selectRow(rowIndex)(tr));
          return true;
        },
      selectColumn:
        (columnIndex: number) =>
        ({ tr, dispatch }) => {
          dispatch?.(selectColumn(columnIndex)(tr));
          return true;
        },
      selectTable:
        () =>
        ({ tr, dispatch }) => {
          dispatch?.(selectTable(tr));
          return true;
        },
      selectCellContent:
        () =>
        ({ tr, dispatch }) => {
          const table = findTable(tr.selection);
          if (!table) return false;
          if (tr.selection instanceof CellSelection) return false;
          const cell = findCellClosestToPos(tr.selection.$from);
          if (!cell) return false;

          if (dispatch) {
            tr.setSelection(
              TextSelection.between(
                tr.doc.resolve(cell.start),
                tr.doc.resolve(cell.start + cell.node.nodeSize)
              )
            );
          }

          return true;
        },
      selectTableCaption:
        () =>
        ({ tr, commands }) => {
          const tableCaption = findTableCaption(tr.selection);
          if (!tableCaption) return false;
          commands.setTextSelection({
            from: tableCaption.pos,
            to: tableCaption.pos + tableCaption.node.nodeSize,
          });
          return true;
        },
      selectTableFootnote:
        () =>
        ({ tr, commands }) => {
          const tableFootnote = findTableFootnote(tr.selection);
          if (!tableFootnote) return false;
          commands.setTextSelection({
            from: tableFootnote.pos,
            to: tableFootnote.pos + tableFootnote.node.nodeSize,
          });
          return true;
        },
      setCellsBackground:
        (color?: string) =>
        ({ state, dispatch }) => {
          return setCellsBackground(color)(state, dispatch);
        },
      addColumnAt:
        (columnIndex: number) =>
        ({ state, tr, dispatch }) => {
          const table = findTable(state.selection);
          if (!table) return false;
          const map = TableMap.get(table.node);
          if (!map) return false;
          dispatch?.(
            addColumn(
              tr,
              {
                tableStart: table.start,
                map,
                table: table.node,
                left: 0,
                right: map.width - 1,
                top: 0,
                bottom: map.height - 1,
              },
              columnIndex
            )
          );
          return true;
        },
      addRowAt:
        (rowIndex: number) =>
        ({ state, tr, dispatch }) => {
          const table = findTable(state.selection);
          if (!table) return false;
          const map = TableMap.get(table.node);
          if (!map) return false;
          dispatch?.(
            addRow(
              tr,
              {
                tableStart: table.start,
                map,
                table: table.node,
                left: 0,
                right: map.width - 1,
                top: 0,
                bottom: map.height - 1,
              },
              rowIndex
            )
          );
          return true;
        },
      deleteSelectedTable:
        () =>
        ({ chain }) => {
          return chain()
            .first(({ commands }) => [
              commands.deleteColumn,
              commands.deleteRow,
            ])
            .command(({ tr, dispatch }) => {
              const table = findTable(tr.selection);
              if (!table) return false;
              if (isTableSelected(tr.selection)) {
                const tableGroup = findTableGroup(tr.selection);
                if (!tableGroup) return false;
                dispatch?.(
                  tr.delete(
                    tableGroup.pos,
                    tableGroup.pos + tableGroup.node.nodeSize
                  )
                );
              }
              return true;
            })
            .command(({ commands }) => commands.predeleteHighlightTable(false))
            .run();
        },
      predeleteHighlightTable:
        (show: boolean) =>
        ({ tr, dispatch }) => {
          dispatch?.(
            tr.setMeta(TableDeleteIndicatorPluginKey, {
              highlight: show,
            })
          );
          return true;
        },
      preinsertCol:
        (colIndex?: number) =>
        ({ tr, dispatch }) => {
          dispatch?.(
            tr.setMeta(TableControlPluginKey, {
              col: colIndex !== undefined ? colIndex : null,
              row: null,
            })
          );
          return true;
        },
      preinsertRow:
        (rowIndex?: number) =>
        ({ tr, dispatch }) => {
          dispatch?.(
            tr.setMeta(TableControlPluginKey, {
              row: rowIndex !== undefined ? rowIndex : null,
              col: null,
            })
          );
          return true;
        },
      addTablePasteChip:
        () =>
        ({ commands }) =>
          commands.addPasteChipAfterPredicate(findTableGroup),
      handleTablePaste:
        (slice: Slice) =>
        ({ state, dispatch }) => {
          const inTable = isInTable(state);
          if (!inTable) return false;
          let cells = __pastedCells(slice);
          const sel = state.selection;
          if (sel instanceof CellSelection) {
            if (!cells)
              cells = {
                width: 1,
                height: 1,
                rows: [
                  Fragment.from(
                    fitSlice(getTableNodeTypes(state.schema).cell, slice)
                  ),
                ],
              };
            const table = sel.$anchorCell.node(-1),
              start = sel.$anchorCell.start(-1);
            const rect = TableMap.get(table).rectBetween(
              sel.$anchorCell.pos - start,
              sel.$headCell.pos - start
            );
            cells = __clipCells(
              cells,
              rect.right - rect.left,
              rect.bottom - rect.top
            );
            __insertCells(state, dispatch, start, rect, cells);
          } else if (cells) {
            const $cell = selectionCell(state);
            if (!$cell) return false;
            const start = $cell.start(-1);
            __insertCells(
              state,
              dispatch,
              start,
              TableMap.get($cell.node(-1)).findCell($cell.pos - start),
              cells
            );
            return true;
          } else {
            return false;
          }
          return true;
        },
    };
  },
  addKeyboardShortcuts() {
    const handleTableDelete = () =>
      this.editor.commands.command(({ state, dispatch, tr }) => {
        const tableGroup = findTableGroup(state.selection);
        if (
          tableGroup?.node &&
          (isSelectionIncludeTable(state) || isTableSelected(state.selection))
        ) {
          dispatch?.(
            tr.delete(tableGroup.pos, tableGroup.pos + tableGroup.node.nodeSize)
          );
          return true;
        }
        return false;
      });
    return {
      "Mod-a": () =>
        this.editor.commands.first(({ commands }) => [
          () => commands.selectCellContent(),
          () => commands.selectTableCaption(),
          () => commands.selectTableFootnote(),
        ]),
      Backspace: handleTableDelete,
      "Mod-Backspace": handleTableDelete,
      "Shift-Backspace": handleTableDelete,
      Delete: handleTableDelete,
      "Mod-Delete": handleTableDelete,
    };
  },
});

function fitSlice(nodeType: NodeType, slice: Slice) {
  const node = nodeType.createAndFill() as PMNode;
  const tr = new Transform(node).replace(0, node.content.size, slice);
  return tr.doc;
}
