import { Fragment, Node as PMNode, NodeType } from "@tiptap/pm/model";
import { Transaction, Selection } from "@tiptap/pm/state";

type FindProsemirrorNodeResult = {
  pos: number;
  start: number;
  depth: number;
  node: PMNode;
};

/**
 * Change a bullet list into a task list or vice versa. These lists use different type of list items,
 * so you need to use this function to not only change the list type but also change the list item type.
 * ported from https://github.com/remirror/remirror/blob/9014d98411/packages/remirror__extension-list/src/list-commands.ts
 **/
export function deepChangeListType(
  tr: Transaction,
  foundList: FindProsemirrorNodeResult,
  listType: NodeType,
  itemType: NodeType
): boolean {
  const oldList = foundList.node;
  const $start = tr.doc.resolve(foundList.start);
  const listParent = $start.node(-1);
  const indexBefore = $start.index(-1);

  if (!listParent) {
    return false;
  }

  if (
    !listParent.canReplace(
      indexBefore,
      indexBefore + 1,
      Fragment.from(listType.create())
    )
  ) {
    return false;
  }

  const newItems: PMNode[] = [];

  for (let index = 0; index < oldList.childCount; index++) {
    const oldItem = oldList.child(index);

    if (!itemType.validContent(oldItem.content)) {
      return false;
    }

    const newItem = itemType.createChecked(null, oldItem.content);
    newItems.push(newItem);
  }

  const newList = listType.createChecked(null, newItems);

  const start = foundList.pos;
  const end = start + oldList.nodeSize;
  const from = tr.selection.from;

  tr.replaceRangeWith(start, end, newList);
  tr.setSelection(
    (tr.selection.constructor as typeof Selection).near(tr.doc.resolve(from))
  );
  return true;
}
