import { forwardRef } from "react";
import styled from "styled-components";

import { colors } from "../styles";
import { Text } from "../Text";
import { EditableActions, EditingActions, ViewOnlyActions } from "./Actions";
import { IconWithBackground } from "./IconWithBackground";
import {
  FileInfo,
  ResourceCardBaseProps,
  ResourceNameInput,
  resourceTypeToCardBackgroundColorMap,
} from "./shared";

export interface FileCardProps extends ResourceCardBaseProps {
  fileInfo: FileInfo;
  onDownload?: VoidFunction;
  isUploading?: boolean;
}

export const FileCard = forwardRef<HTMLDivElement, FileCardProps>(
  (
    {
      fileInfo,
      nameBeingEdited,
      isEditingName,
      onUpdateNameBeingEdited,
      onCancelEditing,
      onDelete,
      onDownload,
      onDoneEditing,
      onOpenInBrowser,
      onStartEditing,
      viewOnly,
      isUploading,
    },
    ref
  ) => {
    const { name, type } = fileInfo;
    const backgroundColor = colors[resourceTypeToCardBackgroundColorMap[type]];
    const onCardClick = viewOnly ? onOpenInBrowser : undefined;
    const shouldShowEditingActions =
      isEditingName &&
      !isUploading &&
      onUpdateNameBeingEdited &&
      onDelete &&
      onCancelEditing;
    const shouldShowViewOnlyActions =
      viewOnly && !isUploading && (onDownload || onOpenInBrowser);
    const shouldShowEditableActions =
      !viewOnly && !isUploading && onOpenInBrowser && onStartEditing;
    return (
      <Container
        ref={ref}
        backgroundColor={backgroundColor}
        isUploading={isUploading}
        onClick={isUploading ? undefined : onCardClick}
      >
        <IconWithBackground fileType={type} />
        <InfoContainer>
          {isEditingName ? (
            <ResourceNameInput
              fullWidth
              value={nameBeingEdited}
              onCancel={onCancelEditing}
              onConfirm={onDoneEditing}
              onChange={(e) => onUpdateNameBeingEdited?.(e.target.value)}
            />
          ) : (
            <TrimmedFileTitle kind="headingSix">{name}</TrimmedFileTitle>
          )}
          <FileDetails fileInfo={fileInfo} isUploading={isUploading} />
        </InfoContainer>
        <ActionsContainer absolute={!isEditingName}>
          {shouldShowEditingActions && (
            <EditingActions
              onDone={onDoneEditing}
              onDelete={onDelete}
              onCancel={onCancelEditing}
            />
          )}
          {!isEditingName && (
            <OnCardHover>
              {shouldShowViewOnlyActions && (
                <ViewOnlyActions
                  onDownload={onDownload}
                  onOpenInBrowser={() => onOpenInBrowser && onOpenInBrowser()}
                />
              )}
              {shouldShowEditableActions && (
                <EditableActions
                  onOpenInBrowser={onOpenInBrowser}
                  onEdit={onStartEditing}
                />
              )}
            </OnCardHover>
          )}
        </ActionsContainer>
      </Container>
    );
  }
);

interface FileDetailProps
  extends Pick<FileCardProps, "isUploading" | "fileInfo"> {}

const FileDetails = ({ isUploading, fileInfo }: FileDetailProps) => {
  const details = isUploading ? "Uploading…" : formatFileInfo(fileInfo);
  return (
    <Text kind="subtle" textTransform="uppercase">
      {details}
    </Text>
  );
};

export function formatFileInfo({ size, extension }: FileInfo): string {
  if (!(extension || size)) {
    return "";
  }
  if (extension && size) {
    return `${extension} · ${size}`;
  }
  return `${extension ?? ""}${size ?? ""}`;
}

type ContainerProps = {
  backgroundColor: string;
  isUploading?: boolean;
};
const Container = styled.div<ContainerProps>`
  align-items: flex-start;
  background-color: ${(p) => p.backgroundColor};
  box-sizing: border-box;
  cursor: ${(p) => !p.isUploading && "pointer"};
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  min-width: 170px;
  opacity: ${(p) => p.isUploading && 0.7};
  padding: 16px;
  position: relative;
  width: 100%;
`;

const TrimmedFileTitle = styled(Text)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  overflow: hidden;
  margin-top: -0.5em;
  padding-bottom: 0;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  overflow: hidden;
`;

const ActionsContainer = styled.div<{ absolute: boolean }>`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: ${(p) => p.absolute && "absolute"};
  right: ${(p) => p.absolute && "14px"};
  top: ${(p) => p.absolute && "12px"};
`;

const OnCardHover = styled.div`
  display: none;
  ${Container}:hover & {
    display: block;
  }
`;
