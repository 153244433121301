import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { submit, unsubmit } from "@/features/work";
import { SubmissionType } from "@/generated/graphql";

/** Materials redux state */
export interface MaterialsState {
  /** Open state of the Materials pane */
  open: boolean;
  /** Selected width for the left pane */
  width: MaterialsWidth;
  /** Currently selected tab to render in the panes */
  tab: MaterialsTab;
}

/**
 * Width of the left pane in Materials.
 *
 * This is valid in the layout where the Materials left pane has controls to
 * toggle between the width values. These width names correspond to a width
 * percentage:
 *
 *   - golden is 38%
 *   - half is 50%
 *   - inverseGolden is 100 - 38 = 62%
 */
export type MaterialsWidth = "golden" | "half" | "inverseGolden";

/**
 * Various selectable tabs to determine the contents of the Materials panes.
 * Materials sit adjacent to the main Student Work.
 */
export enum MaterialsTab {
  Instructions = "instructions",
  Notes = "notes",
  Draft = "draft",
  Final = "final",
}

const initialState: MaterialsState = {
  open: true,
  width: "golden",
  tab: MaterialsTab.Instructions,
};

export const materialsSlice = createSlice({
  name: "materials",
  initialState,
  reducers: {
    setWidth: (state, action: PayloadAction<MaterialsWidth>) => {
      state.open = true;
      state.width = action.payload;
    },
    setTab: (state, action: PayloadAction<MaterialsTab>) => {
      state.open = true;
      state.tab = action.payload;
    },
    open: (state) => {
      state.open = true;
    },
    close: (state) => {
      state.open = true;
    },
    toggle: (state) => {
      state.open = !state.open;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submit.fulfilled, (state, action) => {
      state.open = true;
      if (action.meta.arg?.submissionType === SubmissionType.Draft) {
        state.tab = MaterialsTab.Draft;
      } else {
        state.tab = MaterialsTab.Final;
      }
    });

    builder.addCase(unsubmit.fulfilled, (state, action) => {
      state.open = true;
      if (action.meta.isExam) {
        state.tab = MaterialsTab.Instructions;
      }
    });
  },
});

// Export Actions
export const { setWidth, setTab, open, close, toggle } = materialsSlice.actions;

// Export Reducer
export const materialsReducer = materialsSlice.reducer;
