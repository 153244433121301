import {
  Highlight as TiptapHighlight,
  inputRegex,
  pasteRegex,
} from "@tiptap/extension-highlight";
import type { HighlightOptions as TiptapHighlightOptions } from "@tiptap/extension-highlight";
import { markInputRule, markPasteRule } from "@tiptap/core";
import tinycolor from "tinycolor2";
import { HIGHLIGHT_COLORS } from "@vericus/cadmus-ui";

interface HighlightOptions extends TiptapHighlightOptions {
  highlightColors: string[];
  defaultHighlightColor: string;
}

export const Highlight = TiptapHighlight.extend<HighlightOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      multicolor: true,
      HTMLAttributes: {},
      defaultHighlightColor: HIGHLIGHT_COLORS[2],
      highlightColors: HIGHLIGHT_COLORS,
    };
  },

  addAttributes() {
    if (!this.options.multicolor) {
      return {};
    }

    const highlightColors = this.options.highlightColors.map((c) =>
      tinycolor(c).toHexString()
    );
    const defaultHighlightColor = tinycolor(
      this.options.defaultHighlightColor
    ).toHexString();

    const getColor = (color?: string) => {
      return color && highlightColors.includes(tinycolor(color).toHexString())
        ? color
        : defaultHighlightColor;
    };

    return {
      color: {
        default: null,
        parseHTML: (element) => {
          const color =
            element.getAttribute("data-color") || element.style.backgroundColor;
          if (color || element.tagName.toLowerCase() === "mark") {
            return getColor(color);
          }
          // if it doesn't have color and not a mark tag then
          // continue parsing through textStyle extension
          return false;
        },
        renderHTML: (attributes) => {
          if (!attributes.color) {
            return {};
          }

          return {
            "data-color": getColor(attributes.color),
            style: `background-color: ${tinycolor(getColor(attributes.color))
              .setAlpha(0.54)
              .toHex8String()}`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "mark",
      },
      // this is to make highlight color parsed correctly instead
      // of being parsed as textStyle
      {
        tag: "span",
        priority: 51,
        getAttrs: (element) => {
          const hasStyles = (element as HTMLElement).hasAttribute("style");
          if (!hasStyles) return false;
          const color = (element as HTMLElement).style.backgroundColor;
          if (!color) return false;

          const highlightColors = this.options.highlightColors.map((c) =>
            tinycolor(c).toHexString()
          );

          return highlightColors.includes(tinycolor(color).toHexString())
            ? {}
            : false;
        },
      },
    ];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-h": () =>
        this.editor.commands.toggleHighlight({
          color: this.options.defaultHighlightColor,
        }),
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
        getAttributes: () => ({
          color: this.options.defaultHighlightColor,
        }),
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: this.type,
        getAttributes: () => ({
          color: this.options.defaultHighlightColor,
        }),
      }),
    ];
  },
});
