import { Button, Colored, Divider, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { formatDateFull } from "@/utils/datetime";

import examImg from "../../assets/submission-exam.png";
import {
  ActionContainer,
  ContentContainer,
  Illustration,
  InfoContainer,
} from "./styles";

interface ExamProps {
  assessmentName: string;
  userName: string;
  submittedAt: string;
  userEmail?: string;
  /** Number of minutes remaining for the student to continue writing. */
  writingTimeRemaining?: number;
  /** Optional callback to continue editing if available */
  onContinueEditing?: () => void;
}

export function ExamSubmissionConfirmation(props: ExamProps) {
  const { writingTimeRemaining = 0, onContinueEditing } = props;
  const canContinueWriting = writingTimeRemaining > 0 && onContinueEditing;
  return (
    <Container>
      <LeftContainer>
        <Text kind="displayTwo">
          Nice work, your exam <br />
          has been submitted
        </Text>
        <Divider />
        <ContentContainer>
          <InfoContainer>
            <div>
              <Text kind="headingFour">{props.assessmentName}</Text>
              <Text kind="label">Submitted by {props.userName}</Text>
            </div>

            <div>
              <Text kind="paragraph">
                On {formatDateFull(new Date(props.submittedAt))}
              </Text>
              {props.userEmail && (
                <Text kind="paragraph">
                  A confirmation of your submission has been sent to:{" "}
                  <Colored color="blue500">{props.userEmail}</Colored>
                </Text>
              )}
            </div>

            {canContinueWriting && (
              <ActionContainer>
                <Text kind="system">
                  You have {writingTimeRemaining} minutes of writing time
                  remaining
                </Text>
                <Button onClick={onContinueEditing} iconName="Edit" kind="dark">
                  Continue Working
                </Button>
              </ActionContainer>
            )}
          </InfoContainer>
        </ContentContainer>
      </LeftContainer>
      <Illustration
        src={examImg}
        alt="An illustration of a person giving a thumbs up while holding a laptop with one arm."
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
