import { CellSelection } from "@tiptap/pm/tables";
import { ControlButton } from "@vericus/cadmus-ui";

import { isSelectionType, isTableSelected } from "../utils";
import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table selection Delete menu item. on Hover, it will show
 * a visual guide on which row(s)/column(s)/table will be removed
 */
export function DeleteMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const { selection } = editor.state;
  const isCellSelection = isSelectionType(selection, "cell");
  if (!isCellSelection) return null;
  const cellSelection = selection as unknown as CellSelection;
  const canDelete =
    cellSelection.isRowSelection() ||
    cellSelection.isColSelection() ||
    isTableSelected(editor.state.selection);
  if (!canDelete) return null;

  return (
    <>
      <ControlButton
        aria-label="Delete"
        iconName="Trash"
        onClick={() => editor.chain().deleteSelectedTable().focus().run()}
        onMouseEnter={() => editor.commands.predeleteHighlightTable(true)}
        onMouseLeave={() => editor.commands.predeleteHighlightTable(false)}
      />
      <GroupDivider />
    </>
  );
}
