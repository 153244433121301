import styled from "styled-components";

import { Color, colors } from "./styles/colors";
import { CuiTheme } from "./styles/theme";

interface ColorProps {
  /** Applies one of the Cadmus colors | theme text colors */
  color: Color | keyof CuiTheme["text"];
}

/**
 * Colored is a simple utility component to change the color of a span of text.
 *
 * You can choose any colour from the complete Cadmus **color library**, or you
 * can use one of the **theme colors**.
 */
export const Colored = styled.span<ColorProps>`
  color: ${(p) =>
    p.color in colors
      ? colors[p.color as Color]
      : p.theme.text[p.color as keyof CuiTheme["text"]]};
`;
