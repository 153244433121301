import styled from "styled-components";

/** Divider child item in a parent Menu. */
export const MenuDivider = styled.div.attrs({ "aria-disabled": true })`
  display: block;
  height: 1px;
  margin: 8px 16px;
  background: ${(p) => p.theme.divider};
  border: none;
`;
