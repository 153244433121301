import { IconProps } from "./types";

export const FloorIcon = (props: IconProps) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" {...props}>
      <path
        d="M3.42195 23.5C3.27795 23.452 3.18195 23.356 3.13395 23.212V5.788C3.25395 5.596 3.38595 5.5 3.52995 5.5C3.66195 5.512 3.76995 5.602 3.85395 5.77V22.78H5.58195C6.73395 22.78 7.33395 22.792 7.38195 22.816C7.51395 22.888 7.57995 22.996 7.57995 23.14C7.57995 23.284 7.51395 23.392 7.38195 23.464C7.33395 23.488 6.66195 23.5 5.36595 23.5H3.42195Z"
        fill="#3A517F"
      />
      <path
        d="M9 10.8333C9 10.6492 9.14924 10.5 9.33333 10.5H16.6667C16.8508 10.5 17 10.6492 17 10.8333V18.1667C17 18.3508 16.8508 18.5 16.6667 18.5H9.33333C9.14924 18.5 9 18.3508 9 18.1667V10.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.5V17.5H16V11.5H10ZM9.33333 10.5C9.14924 10.5 9 10.6492 9 10.8333V18.1667C9 18.3508 9.14924 18.5 9.33333 18.5H16.6667C16.8508 18.5 17 18.3508 17 18.1667V10.8333C17 10.6492 16.8508 10.5 16.6667 10.5H9.33333Z"
        fill="#7070A9"
      />
      <path
        d="M22.518 5.5C22.65 5.512 22.758 5.602 22.842 5.77V23.23C22.818 23.29 22.74 23.374 22.608 23.482L20.646 23.5C19.59 23.5 18.978 23.494 18.81 23.482C18.654 23.482 18.546 23.452 18.486 23.392C18.426 23.332 18.396 23.254 18.396 23.158C18.396 23.002 18.468 22.888 18.612 22.816C18.648 22.792 19.242 22.78 20.394 22.78H22.122V5.788C22.242 5.596 22.374 5.5 22.518 5.5Z"
        fill="#3A517F"
      />
    </svg>
  );
};
