import { IconProps } from "./types";

export const UnderRightArrowIcon = (props: IconProps) => {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" {...props}>
      <path
        d="M1.12 23.5187C0.970667 23.4441 0.896 23.3374 0.896 23.1987C0.896 23.0601 0.970667 22.9534 1.12 22.8787H13.36C12.6027 22.3134 12.016 21.6307 11.6 20.8307C11.3333 20.3187 11.1573 19.8121 11.072 19.3107C11.072 19.2894 11.0667 19.2681 11.056 19.2467C11.056 19.2254 11.056 19.2094 11.056 19.1987C11.056 19.0814 11.1627 19.0227 11.376 19.0227C11.504 19.0227 11.584 19.0387 11.616 19.0707C11.6587 19.0921 11.6907 19.1507 11.712 19.2467C11.744 19.4601 11.8027 19.6787 11.888 19.9027C12.1227 20.6174 12.512 21.2574 13.056 21.8227C13.6 22.3774 14.2347 22.7774 14.96 23.0227C15.056 23.0547 15.104 23.1134 15.104 23.1987C15.104 23.2841 15.056 23.3427 14.96 23.3747C14.2347 23.6201 13.6 24.0254 13.056 24.5907C12.512 25.1454 12.1227 25.7801 11.888 26.4947C11.8133 26.7187 11.7547 26.9321 11.712 27.1347C11.6907 27.2414 11.6587 27.3054 11.616 27.3267C11.584 27.3587 11.504 27.3747 11.376 27.3747C11.152 27.3747 11.04 27.3161 11.04 27.1987C11.04 27.1667 11.072 27.0334 11.136 26.7987C11.4667 25.4654 12.208 24.3721 13.36 23.5187H1.12Z"
        fill="#3A517F"
      />
      <path
        d="M2 6.5C2 6.22386 2.22386 6 2.5 6H13.5C13.7761 6 14 6.22386 14 6.5V17.5C14 17.7761 13.7761 18 13.5 18H2.5C2.22386 18 2 17.7761 2 17.5V6.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7V17H13V7H3ZM2.5 6C2.22386 6 2 6.22386 2 6.5V17.5C2 17.7761 2.22386 18 2.5 18H13.5C13.7761 18 14 17.7761 14 17.5V6.5C14 6.22386 13.7761 6 13.5 6H2.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
