import { IconProps } from "./types";

export const LeftArrowBetweenIcon = (props: IconProps) => {
  return (
    <svg width="21" height="30" viewBox="0 0 21 30" {...props}>
      <path
        d="M11.2671 15.26C11.3871 15.324 11.4471 15.404 11.4471 15.5C11.4471 15.596 11.3871 15.676 11.2671 15.74H2.09914L2.13514 15.776C2.95914 16.408 3.49914 17.216 3.75514 18.2C3.80314 18.376 3.82714 18.476 3.82714 18.5C3.82714 18.588 3.74314 18.632 3.57514 18.632C3.47914 18.632 3.41514 18.62 3.38314 18.596C3.35914 18.58 3.33914 18.532 3.32314 18.452C3.30714 18.396 3.29114 18.328 3.27514 18.248C3.13114 17.664 2.85514 17.144 2.44714 16.688C2.04714 16.232 1.56314 15.892 0.995141 15.668C0.875141 15.62 0.803141 15.576 0.779141 15.536C0.779141 15.432 0.851141 15.364 0.995141 15.332C1.56314 15.108 2.04714 14.768 2.44714 14.312C2.85514 13.856 3.13114 13.336 3.27514 12.752C3.28314 12.728 3.29114 12.692 3.29914 12.644C3.30714 12.596 3.31114 12.564 3.31114 12.548C3.31914 12.532 3.32714 12.508 3.33514 12.476C3.35114 12.444 3.36714 12.424 3.38314 12.416C3.39914 12.4 3.42314 12.388 3.45514 12.38C3.48714 12.372 3.52714 12.368 3.57514 12.368C3.74314 12.368 3.82714 12.412 3.82714 12.5C3.82714 12.524 3.80314 12.624 3.75514 12.8C3.51514 13.752 2.97514 14.56 2.13514 15.224L2.09914 15.26H11.2671Z"
        fill="#3A517F"
      />
      <path
        d="M19.8814 15.7527C20.014 15.7527 20.1214 15.6452 20.1214 15.5127C20.1214 15.3801 20.014 15.2727 19.8814 15.2727V15.7527ZM10.2822 15.7527H19.8814V15.2727H10.2822V15.7527Z"
        fill="#3A517F"
      />
      <path
        d="M13.7188 18.8333C13.7188 18.6492 13.5695 18.5 13.3854 18.5H6.05208C5.86799 18.5 5.71875 18.6492 5.71875 18.8333V26.1667C5.71875 26.3508 5.86799 26.5 6.05208 26.5H13.3854C13.5695 26.5 13.7188 26.3508 13.7188 26.1667V18.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7188 19.5V25.5H6.71875V19.5H12.7188ZM13.3854 18.5C13.5695 18.5 13.7188 18.6492 13.7188 18.8333V26.1667C13.7188 26.3508 13.5695 26.5 13.3854 26.5H6.05208C5.86799 26.5 5.71875 26.3508 5.71875 26.1667V18.8333C5.71875 18.6492 5.86799 18.5 6.05208 18.5H13.3854Z"
        fill="#7070A9"
      />
      <path
        d="M13.7188 3.83333C13.7188 3.64924 13.5695 3.5 13.3854 3.5H6.05208C5.86799 3.5 5.71875 3.64924 5.71875 3.83333V11.1667C5.71875 11.3508 5.86799 11.5 6.05208 11.5H13.3854C13.5695 11.5 13.7188 11.3508 13.7188 11.1667V3.83333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7188 4.5V10.5H6.71875V4.5H12.7188ZM13.3854 3.5C13.5695 3.5 13.7188 3.64924 13.7188 3.83333V11.1667C13.7188 11.3508 13.5695 11.5 13.3854 11.5H6.05208C5.86799 11.5 5.71875 11.3508 5.71875 11.1667V3.83333C5.71875 3.64924 5.86799 3.5 6.05208 3.5H13.3854Z"
        fill="#7070A9"
      />
    </svg>
  );
};
