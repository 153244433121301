import { IconProps } from "./types";

export const RightArrowBetweenIcon = (props: IconProps) => {
  return (
    <svg width="21" height="30" viewBox="0 0 21 30" {...props}>
      <path
        d="M9.73286 15.26C9.61286 15.324 9.55286 15.404 9.55286 15.5C9.55286 15.596 9.61286 15.676 9.73286 15.74H18.9009L18.8649 15.776C18.0409 16.408 17.5009 17.216 17.2449 18.2C17.1969 18.376 17.1729 18.476 17.1729 18.5C17.1729 18.588 17.2569 18.632 17.4249 18.632C17.5209 18.632 17.5849 18.62 17.6169 18.596C17.6409 18.58 17.6609 18.532 17.6769 18.452C17.6929 18.396 17.7089 18.328 17.7249 18.248C17.8689 17.664 18.1449 17.144 18.5529 16.688C18.9529 16.232 19.4369 15.892 20.0049 15.668C20.1249 15.62 20.1969 15.576 20.2209 15.536C20.2209 15.432 20.1489 15.364 20.0049 15.332C19.4369 15.108 18.9529 14.768 18.5529 14.312C18.1449 13.856 17.8689 13.336 17.7249 12.752C17.7169 12.728 17.7089 12.692 17.7009 12.644C17.6929 12.596 17.6889 12.564 17.6889 12.548C17.6809 12.532 17.6729 12.508 17.6649 12.476C17.6489 12.444 17.6329 12.424 17.6169 12.416C17.6009 12.4 17.5769 12.388 17.5449 12.38C17.5129 12.372 17.4729 12.368 17.4249 12.368C17.2569 12.368 17.1729 12.412 17.1729 12.5C17.1729 12.524 17.1969 12.624 17.2449 12.8C17.4849 13.752 18.0249 14.56 18.8649 15.224L18.9009 15.26H9.73286Z"
        fill="#3A517F"
      />
      <path
        d="M1.11857 15.7527C0.986024 15.7527 0.878572 15.6452 0.878572 15.5127C0.878572 15.3801 0.986024 15.2727 1.11857 15.2727V15.7527ZM10.7178 15.7527H1.11857V15.2727H10.7178V15.7527Z"
        fill="#3A517F"
      />
      <path
        d="M14.7188 18.8333C14.7188 18.6492 14.5695 18.5 14.3854 18.5H7.05208C6.86799 18.5 6.71875 18.6492 6.71875 18.8333V26.1667C6.71875 26.3508 6.86799 26.5 7.05208 26.5H14.3854C14.5695 26.5 14.7188 26.3508 14.7188 26.1667V18.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7188 19.5V25.5H7.71875V19.5H13.7188ZM14.3854 18.5C14.5695 18.5 14.7188 18.6492 14.7188 18.8333V26.1667C14.7188 26.3508 14.5695 26.5 14.3854 26.5H7.05208C6.86799 26.5 6.71875 26.3508 6.71875 26.1667V18.8333C6.71875 18.6492 6.86799 18.5 7.05208 18.5H14.3854Z"
        fill="#7070A9"
      />
      <path
        d="M14.7188 3.83333C14.7188 3.64924 14.5695 3.5 14.3854 3.5H7.05208C6.86799 3.5 6.71875 3.64924 6.71875 3.83333V11.1667C6.71875 11.3508 6.86799 11.5 7.05208 11.5H14.3854C14.5695 11.5 14.7188 11.3508 14.7188 11.1667V3.83333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7188 4.5V10.5H7.71875V4.5H13.7188ZM14.3854 3.5C14.5695 3.5 14.7188 3.64924 14.7188 3.83333V11.1667C14.7188 11.3508 14.5695 11.5 14.3854 11.5H7.05208C6.86799 11.5 6.71875 11.3508 6.71875 11.1667V3.83333C6.71875 3.64924 6.86799 3.5 7.05208 3.5H14.3854Z"
        fill="#7070A9"
      />
    </svg>
  );
};
