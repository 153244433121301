import {
    findParentNode, getNodeType, InputRule,
    inputRulesPlugin, mergeAttributes, Node, wrappingInputRule
} from "@tiptap/core";
import { isListItem, wrapSelectedItems } from "../list";

export interface OrderedListOptions {
  HTMLAttributes: Record<string, unknown>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    orderedList: {
      /**
       * Toggle an ordered list
       */
      toggleOrderedList: () => ReturnType;
    };
  }
}

export const inputRegex = /^(\d+)\.\s$/;

export const OrderedList = Node.create<OrderedListOptions>({
  name: "orderedList",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block list",

  content: "listItem+",

  addAttributes() {
    return {
      start: {
        default: 1,
        parseHTML: (element) => {
          return element.hasAttribute("start")
            ? parseInt(element.getAttribute("start") || "", 10)
            : 1;
        },
        keepOnSplit: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "ol",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const { start, ...attributesWithoutStart } = HTMLAttributes;

    return start === 1
      ? [
          "ol",
          mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart),
          0,
        ]
      : ["ol", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleOrderedList:
        () =>
        ({ commands }) => {
          return commands.toggleCadmusList(
            this.type,
            getNodeType("listItem", this.editor.schema)
          );
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-7": () => this.editor.commands.toggleOrderedList(),
    };
  },

  addProseMirrorPlugins() {
    return [
      inputRulesPlugin({
        editor: this.editor,
        rules: [
          new InputRule({
            find: inputRegex,
            handler: ({ state, match, range }) => {
              const { tr } = state;
              tr.deleteRange(range.from, range.to);
              const listType = this.type;
              const listItemType = getNodeType("listItem", this.editor.schema);
              const canUpdate = wrapSelectedItems({
                listType,
                itemType: listItemType,
                tr,
                editor: this.editor,
              });
              const start = +match[1] > 1 ? +match[1] : 1;
              if (!canUpdate) {
                const $cursor = tr.doc.resolve(range.from);
                if ($cursor.depth > 2) {
                  const maybeListItem = $cursor.node($cursor.depth - 1);
                  if (
                    !isListItem(
                      maybeListItem.type.name,
                      this.editor.extensionManager.extensions
                    )
                  ) {
                    return null;
                  }
                } else {
                  return null;
                }
              }
              const found = findParentNode((node) => node.type === this.type)(
                tr.selection
              );
              if (found) {
                if (start !== 1) {
                  tr.setNodeMarkup(found.pos, undefined, { start });
                } else {
                  tr.setNodeMarkup(found.pos, undefined, {});
                }
              }
            },
          }),
          wrappingInputRule({
            find: inputRegex,
            type: this.type,
            getAttributes: (match) => {
              if (+match[1] > 1) {
                return { start: +match[1] };
              }
              return;
            },
            joinPredicate: (match, node) =>
              node.childCount + node.attrs.start === +match[1],
          }),
        ],
      }),
    ];
  },
});
