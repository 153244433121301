import { ChangeSet } from "@tiptap/pm/changeset";
import { Transaction } from "@tiptap/pm/state";

import { wordRegexMatch } from "../../core/text";
import { HistoryMeta, HistoryPluginKey } from "../history";
import { PasteMeta, PastePluginKey } from "../paste";
import { PasteOrigin } from "../paste/cleaner";
import { TelemetryOptions } from "./types";

export function emitTransactionEvents(
  transaction: Transaction,
  opts: TelemetryOptions
) {
  const historyMeta = transaction.getMeta(HistoryPluginKey) as
    | HistoryMeta
    | undefined;

  const pasteMeta = transaction.getMeta(PastePluginKey) as
    | PasteMeta
    | undefined;

  if (pasteMeta || historyMeta) {
    // Wrapping inside setTimeout to prevent lock up of UI
    setTimeout(() => {
      // Get words added and deleted through prosemirror-changeset
      const { before: oldDoc, doc } = transaction;
      let changeset = ChangeSet.create(oldDoc);
      let deletedWords = 0;
      let addedWords = 0;
      changeset = changeset.addSteps(doc, transaction.mapping.maps, undefined);
      changeset.changes.forEach((change) => {
        const { deleted, fromA, toA, fromB, toB, inserted } = change;
        if (deleted) {
          deletedWords +=
            wordRegexMatch(oldDoc.textBetween(fromA, toA, "\n"))?.length ?? 0;
        }
        if (inserted) {
          addedWords +=
            wordRegexMatch(doc.textBetween(fromB, toB, "\n"))?.length ?? 0;
        }
      });

      if (pasteMeta) {
        opts.onEditorEvent?.({
          type: "paste",
          payload: {
            ...pasteMeta,
            origin:
              pasteMeta.origin === PasteOrigin.PROSEMIRROR &&
              !pasteMeta.internal
                ? PasteOrigin.UNKNOWN
                : pasteMeta.origin,
            editorId: opts.editorId,
            addedWords,
            deletedWords,
          },
        });
      }
      if (historyMeta) {
        opts.onEditorEvent?.({
          type: "history",
          payload: {
            historyType: historyMeta.type,
            addedWords,
            deletedWords,
            editorId: opts.editorId,
          },
        });
      }
    });
  }
}
