import { colors } from "@vericus/cadmus-ui";
import { css } from "styled-components";

const darkColors = {
  red500: "#ff4d87",
  orange500: "#FF974D",
  blue300: "#86D7FF",
  pink500: "#FF4D87",
  green300: "#A6D279",
  yellow500: "#FFCC3E",
  cyan500: "#61D1D1",
  grey500: "#9DADCA",
  white100: "#FFFFFF",
  white500: "#E8E8F7",
  background: "#0f172e",
  border: "#0f172e",
  preDeleteBackground: "#371932",
  preDeleteBorder: colors.red500,
  preDeleteGutter: "#482d4a",
  selectedBackground: colors.grey200,
  gutterBackground: "#242f4b",
  lineNumber: "#f2f5fc",
  languageDisplay: "#9dadca",
  cursor: "#61d1d1",
  activeLineBackground: "#1b2338",
  activeLineGutterBackground: "#2c3852",
};

/** Dark theme for `CodeBlock` nodes */
export const cadmusDarkCodeBlockStyles = css`
  /* basic */
  .cm-s-cadmus-dark {
    color: ${darkColors.white100};
    background-color: ${darkColors.background};
    border: solid 1px ${darkColors.border} !important;
  }

  .cm-s-cadmus-dark.code-block-pre-delete {
    background-color: ${darkColors.preDeleteBackground};
    border: 1px solid ${darkColors.preDeleteBorder} !important;
    .CodeMirror-activeline-background {
      background-color: ${darkColors.preDeleteBackground};
    }
    .CodeMirror-linenumbers {
      background-color: ${darkColors.preDeleteGutter};
    }
    .CodeMirror-activeline-gutter {
      background-color: ${darkColors.preDeleteGutter};
    }
  }

  .cm-s-cadmus-dark .CodeMirror-selected {
    color: ${darkColors.white100} !important;
    background: ${darkColors.selectedBackground} !important;
  }

  /** user select styling */
  .cm-s-cadmus-dark .CodeMirror-focused .CodeMirror-selected,
  .cm-s-cadmus-dark .CodeMirror-line::selection,
  .cm-s-cadmus-dark .CodeMirror-line > span::selection,
  .cm-s-cadmus-dark .CodeMirror-line > span > span::selection,
  .cm-s-cadmus-dark .CodeMirror-line::-moz-selection,
  .cm-s-cadmus-dark .CodeMirror-line > span::-moz-selection,
  .cm-s-cadmus-dark .CodeMirror-line > span > span::-moz-selection {
    background: ${darkColors.selectedBackground};
  }

  .cm-s-cadmus-dark .CodeMirror-gutter,
  .cm-s-cadmus-dark .CodeMirror-gutters {
    border: none;
    background-color: ${darkColors.gutterBackground};
  }
  .cm-s-cadmus-dark .CodeMirror-linenumber,
  .cm-s-cadmus-dark .CodeMirror-linenumbers {
    color: ${darkColors.lineNumber} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-dark .CodeMirror-lines {
    color: ${darkColors.white500} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-dark .CodeMirror-cursor {
    border-left: 2px solid ${darkColors.cursor} !important;
  }

  .cm-s-cadmus-dark .language-display {
    color: ${darkColors.languageDisplay} !important;
  }

  /* addon: edit/machingbrackets.js & addon: edit/matchtags.js */
  .cm-s-cadmus-dark .CodeMirror-matchingbracket,
  .cm-s-cadmus-dark .CodeMirror-matchingtag {
    border-bottom: 2px solid ${darkColors.white500};
    color: ${darkColors.white500} !important;
    background-color: transparent;
  }
  .cm-s-cadmus-dark .CodeMirror-nonmatchingbracket {
    border-bottom: 2px solid ${darkColors.red500};
    color: ${darkColors.red500} !important;
    background-color: transparent;
  }

  /* addon: fold/foldgutter.js */
  .cm-s-cadmus-dark .CodeMirror-foldmarker,
  .cm-s-cadmus-dark .CodeMirror-foldgutter,
  .cm-s-cadmus-dark .CodeMirror-foldgutter-open,
  .cm-s-cadmus-dark .CodeMirror-foldgutter-folded {
    border: none;
    text-shadow: none;
    color: ${darkColors.grey500} !important;
    background-color: transparent;
  }

  /* addon: selection/active-line.js */
  .cm-s-cadmus-dark .CodeMirror-activeline-background {
    background-color: ${darkColors.activeLineBackground};
  }
  .cm-s-cadmus-dark .CodeMirror-activeline-gutter {
    background-color: ${darkColors.activeLineGutterBackground};
  }

  /* basic syntax */
  .cm-s-cadmus-dark span.cm-comment {
    color: ${darkColors.grey500};
  }
  .cm-s-cadmus-dark span.cm-atom {
    color: ${darkColors.pink500};
  }
  .cm-s-cadmus-dark span.cm-number,
  .cm-s-cadmus-dark span.cm-attribute,
  .cm-s-cadmus-dark span.cm-qualifier {
    color: ${darkColors.yellow500};
  }
  .cm-s-cadmus-dark span.cm-keyword,
  .cm-s-cadmus-dark span.cm-operator {
    color: ${darkColors.pink500};
  }
  .cm-s-cadmus-dark span.cm-string,
  .cm-s-cadmus-dark span.cm-quote {
    color: ${darkColors.green300};
  }
  .cm-s-cadmus-dark span.cm-meta {
    color: ${darkColors.pink500};
  }
  .cm-s-cadmus-dark span.cm-variable-2,
  .cm-s-cadmus-dark span.cm-variable-3,
  .cm-s-cadmus-dark span.cm-variable {
    color: ${darkColors.white500};
  }
  .cm-s-cadmus-dark span.cm-tag {
    color: ${darkColors.blue300};
  }
  .cm-s-cadmus-dark span.cm-def {
    color: ${darkColors.cyan500};
  }
  .cm-s-cadmus-dark span.cm-type,
  .cm-s-cadmus-dark span.cm-punctuation {
    color: ${darkColors.white500};
  }
  .cm-s-cadmus-dark span.cm-bracket {
    color: ${darkColors.white500};
  }
  .cm-s-cadmus-dark span.cm-builtin,
  .cm-s-cadmus-dark span.cm-special {
    color: ${darkColors.orange500};
  }
  .cm-s-cadmus-dark span.cm-link {
    color: ${darkColors.pink500};
    border-bottom: solid 1px ${darkColors.pink500};
  }
  .cm-s-cadmus-dark span.cm-error {
    color: ${darkColors.red500};
  }

  .cm-s-cadmus-dark span.cm-header {
    color: ${darkColors.red500};
  }

  .cm-s-cadmus-dark span.cm-em {
    font-style: italic;
  }

  .cm-s-cadmus-dark span.cm-strong {
    font-style: bold;
  }

  .cm-s-cadmus-dark span.cm-property {
    color: ${darkColors.orange500};
  }
`;
