import * as Sentry from "@sentry/react";
import {
  CadmusEditorContent,
  useCadmusEditor,
  useControlledEditor,
} from "@vericus/cadmus-editor-prosemirror";
import { useManualPreset } from "@vericus/cadmus-manual";

import { useNotesEditor } from "@/stores/editors/editorVars";

export function NotesEditorContent() {
  const editor = useNotesEditor();
  const openManualPreset = useManualPreset();
  useControlledEditor(editor);
  return (
    <Sentry.ErrorBoundary>
      <CadmusEditorContent
        editor={editor}
        onOpenManualPreset={openManualPreset}
      />
    </Sentry.ErrorBoundary>
  );
}

export function NotesEditorContentReadOnly() {
  const notes = useNotesEditor();
  const content = notes?.getHTML() ?? null;
  const editor = useCadmusEditor(
    {
      editorId: "notes-readonly",
      content,
      editable: false,
    },
    [content]
  );
  return <CadmusEditorContent editor={editor} />;
}
