import { IconProps } from "./types";

export const SubscriptIcon = (props: IconProps) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" {...props}>
      <path
        d="M20 20.05C20 20.0224 20.0224 20 20.05 20H23.95C23.9776 20 24 20.0224 24 20.05V23.95C24 23.9776 23.9776 24 23.95 24H20.05C20.0224 24 20 23.9776 20 23.95V20.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.05 19H23.95C24.5299 19 25 19.4701 25 20.05V23.95C25 24.5299 24.5299 25 23.95 25H20.05C19.4701 25 19 24.5299 19 23.95V20.05C19 19.4701 19.4701 19 20.05 19ZM20.05 20C20.0224 20 20 20.0224 20 20.05V23.95C20 23.9776 20.0224 24 20.05 24H23.95C23.9776 24 24 23.9776 24 23.95V20.05C24 20.0224 23.9776 20 23.95 20H20.05Z"
        fill="#7070A9"
      />
      <path
        d="M6 9.5C6 9.22386 6.22386 9 6.5 9H17.5C17.7761 9 18 9.22386 18 9.5V20.5C18 20.7761 17.7761 21 17.5 21H6.5C6.22386 21 6 20.7761 6 20.5V9.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 10V20H17V10H7ZM6.5 9C6.22386 9 6 9.22386 6 9.5V20.5C6 20.7761 6.22386 21 6.5 21H17.5C17.7761 21 18 20.7761 18 20.5V9.5C18 9.22386 17.7761 9 17.5 9H6.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
