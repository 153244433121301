import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { Plugin, PluginKey, Transaction } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { processTextBetween, wordRegexMatchAll } from "../../core/text";
import { SuggestionClient } from "../../suggestion-client";
import {
  canSpellCheckWithCadmus,
  getNodeBoundaries,
  rangeFromTransaction,
} from "./helpers";

/** String constant enum of the different sources of spellchecking. */
export type SpellCheckSource = "cadmus" | "browser" | null;

/** Plugin creation options for the `SuggestionDecorationPlugin`. */
export interface SuggestionDecorationPluginProps {
  editor: Editor;
  client: SuggestionClient;
}

/** Reference key for the `SuggestionDecorationPlugin`. */
export const SuggestionDecorationPluginKey = new PluginKey<DecorationSet>(
  "suggestionDecoration"
);

/**
 * Transaction meta action to ignore a word from suggestion decorations.
 */
export interface IgnoreWordAction {
  type: "ignoreWord";
  word: string;
}

/**
 * Transaction meta action to refresh suggestion decorations on the full doc.
 */
export interface RefreshSuggestionsAction {
  type: "refreshSuggestions";
}

/**
 * Create a new `SuggestionDecorationPlugin` to manage suggestion decorations in
 * a document.
 *
 * A suggestion decoration is an inline decoration which marks a word as a
 * misspelling with the replacement candidates stored in the decoration `spec`
 * object. See the type `SuggestionDecorationSpec`.
 *
 * On every transaction, any updated text nodes are re-processed (tokenised and
 * looked up using the `SuggestionClient`) to update the decoration positions or
 * add new decorations.
 *
 * It is expected that some other out-of-bound process is reading the entire
 * document and sending it to the server for spelling suggestions.
 *
 * ## Transaction Meta Actions
 *
 * The plugin watches out for the following transaction meta actions payloads:
 *
 *   - `IgnoreWordAction` filters out any existing decorations for a given
 *     `word`.
 *   - `RefreshSuggestionsAction` triggers a full document refresh of suggestion
 *     decorations.
 *
 */
export const SuggestionDecorationPlugin = (
  props: SuggestionDecorationPluginProps
) =>
  new Plugin<DecorationSet>({
    key: SuggestionDecorationPluginKey,
    state: {
      init() {
        return DecorationSet.empty;
      },
      apply(tr, decoSet) {
        // Handle any actions provided in the Transaction meta
        const payload = tr.getMeta(SuggestionDecorationPluginKey) as
          | IgnoreWordAction
          | RefreshSuggestionsAction
          | undefined;

        switch (payload?.type) {
          case "ignoreWord":
            // Filter out decorations that are to be ignored
            return handleIgnoreWordAction(decoSet, payload.word);
          case "refreshSuggestions":
            // Check for full document suggestion refresh requests
            return handleRefreshSuggestionsAction(tr, decoSet, props.client);
          default:
            // Tokenise and lookup words in the  updated text nodes
            return processUpdatedNodes(tr, decoSet, props.client);
        }
      },
    },
    props: {
      decorations(state) {
        return canSpellCheckWithCadmus(props.editor)
          ? this.getState(state)
          : undefined;
      },
    },
  });

/** State stored in the decoration spec. */
export interface SuggestionDecorationSpec {
  phrase: string;
  candidates: string[];
}

/**
 * Suggestion Decoration which add suggestion class and also phrase and candidates
 * as the decoration specification data.
 */
function SuggestionDecoration(
  from: number,
  to: number,
  phrase: string,
  candidates: string[]
) {
  return Decoration.inline(
    from,
    to,
    {
      class: "suggestion",
    },
    {
      phrase,
      candidates,
    }
  );
}

/**
 * Go through updated textNode on a transaction and re-decorate them with
 * suggestions if words on those blocks require suggestion
 */
function processUpdatedNodes(
  tr: Transaction,
  decoSet: DecorationSet,
  client: SuggestionClient
) {
  if (tr.docChanged) {
    decoSet = decoSet.map(tr.mapping, tr.doc);
    const { to, from = 0 } = rangeFromTransaction(tr);
    const { start, end } = getNodeBoundaries(tr.doc, from, to);

    const outdatedDecos = decoSet.find(start, end);

    // remove decorations within transaction range
    decoSet = decoSet.remove(outdatedDecos);

    // check misspelled words on block that has changes
    const newDecos = getSuggestionDecorations(
      tr.doc,
      start,
      end,
      decoSet,
      client
    );

    return decoSet.add(tr.doc, newDecos);
  }
  return decoSet;
}

/**
 * Handle transaction meta action event `ignoreWord`. Any existing decorations
 * for the ignored word will be removed from the given set.
 */
function handleIgnoreWordAction(decoSet: DecorationSet, word: string) {
  // removes decos with `spec.phrase` === `ignoreWord`
  const invalidDeco = decoSet.find(
    undefined,
    undefined,
    (spec) => spec.phrase === word
  );
  return decoSet.remove(invalidDeco);
}

/**
 * Handle transaction metadata event for `refreshSuggestions`. The entire
 * document will be considered for decorating misspelled words when this event
 * exists on the transaction metadata.
 */
function handleRefreshSuggestionsAction(
  tr: Transaction,
  decoSet: DecorationSet,
  client: SuggestionClient
) {
  const newDecos = getSuggestionDecorations(
    tr.doc,
    0,
    tr.doc.content.size,
    decoSet,
    client
  );
  return decoSet.add(tr.doc, newDecos);
}

/**
 * Create new decorations for mispelled words in a `doc` node between the given
 * range.
 *
 * The `doc` node is tokenised as word chunks, finding misspelled words using
 * the `client` lookup. If a word is misspelled and is currently not decorated,
 * a new `Decoration` will be created for the word on its position.
 *
 * @param doc node to start iterating over
 * @param from start position
 * @param to end position
 * @param existing existing suggestion decorations in the document
 * @param client Suggestions Client to use to lookup spelling.
 */
function getSuggestionDecorations(
  doc: PMNode,
  from: number,
  to: number,
  existing: DecorationSet,
  client: SuggestionClient
) {
  const decorations: Decoration[] = [];
  processTextBetween(
    doc,
    from,
    to,
    (text, textFrom) => {
      applySuggestionDecorations(text, textFrom, existing, client, decorations);
    },
    ignoreNodeForSuggestions
  );
  return decorations;
}

// Find and add sggestion decorations on a chunk of text.
// XXX MUTATION The `collection` array is mutated (pushed).
function applySuggestionDecorations(
  text: string,
  start: number,
  exiting: DecorationSet,
  client: SuggestionClient,
  collection: Decoration[]
) {
  for (const match of wordRegexMatchAll(text ?? "")) {
    const matchText = match[0];
    const matchIndex = match.index;
    const matchLength = matchText.length;
    if (matchText && matchIndex !== undefined) {
      const from = start + matchIndex;
      const to = start + matchIndex + matchLength;
      const candidates = client.lookup(matchText);
      if (candidates?.length) {
        if (
          exiting.find(from, to, ({ phrase }) => phrase === matchText)
            .length === 0
        ) {
          collection.push(
            SuggestionDecoration(from, to, matchText, candidates)
          );
        }
      }
    }
  }
}

const MARKS_TO_IGNORE_FOR_SUGGESTION = [
  "link",
  "superscript",
  "subscript",
  "code",
];

const NODES_TO_IGNORE_FOR_SUGGESTION = [
  "inlineMath",
  "pasteChip",
  "blockMath",
  "codeBlock",
];

// Predicate for nodes which should not be decorated with suggestions.
function ignoreNodeForSuggestions(node: PMNode): boolean {
  return (
    node.marks.some((mark) =>
      MARKS_TO_IGNORE_FOR_SUGGESTION.includes(mark.type.name)
    ) || NODES_TO_IGNORE_FOR_SUGGESTION.includes(node.type.name)
  );
}
