/**
 * A function which inserts an :after pseudo element as a tooltip.
 * Unless Specified, the tooltip positions itself centrally
 * and below its parent element.
 *
 * For an example of use, see Tooltip.stories.tsx
 */

import { css } from "styled-components";

import { colors, levels } from "../styles";

/** the available tooltip positions relative to its content */
export type TooltipPosition = "bottom" | "top" | "left" | "right";

/** return css styles to position the tooltip */
const positionStyles = (position: TooltipPosition) => {
  switch (position) {
    case "bottom": {
      return css`
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 3px;
      `;
    }
    case "top": {
      return css`
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 3px;
      `;
    }
    case "left": {
      return css`
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 3px;
      `;
    }
    case "right": {
      return css`
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 3px;
      `;
    }
  }
};

/** main mixin */
export const tooltip = (
  tooltip: string,
  position: TooltipPosition = "bottom"
) => css`
  &:after {
    content: "${tooltip}";

    /* calculate position */
    position: absolute;
    ${positionStyles(position)};

    z-index: 10;

    max-height: 42px;
    padding: 3px 9px;
    font-size: 12px;

    border-radius: 3px;
    box-shadow: ${levels.two};
    line-height: 18px;
    pointer-events: none;
    background: white;
    color: ${colors.black200};

    white-space: nowrap;

    display: none;
  }

  &:hover:after {
    display: block;
  }
`;
