import { Extension } from "@tiptap/core";
import Bold from "@tiptap/extension-bold";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import Italic from "@tiptap/extension-italic";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { Plugin } from "@tiptap/pm/state";

import { SuggestionClient } from "../../suggestion-client";
import AtomSelection from "../atom-selection";
import BlockBackground from "../block-background";
import BlockTextVisibility from "../block-text-visibility";
import BlockQuote from "../blockquote";
import BulletList from "../bullet-list";
import { Code } from "../code";
import { CodeBlock } from "../code-block";
import Document from "../document";
import EditorPlaceholder from "../editor-placeholder";
import Emoji from "../emoji";
import EmptyImageNode from "../empty-image-node";
import { FloatingMenuExtension } from "../floating-menu";
import Highlight from "../highlight";
import History from "../history";
import Hyperlink from "../hyperlink";
import Image from "../image";
import ImageUploader from "../image-uploader";
import Indentation from "../indentation";
import List from "../list";
import ListItem from "../list-item";
import { MathExtension } from "../math";
import OrderedList from "../ordered-list";
import Paste from "../paste";
import PasteChip from "../paste-chip";
import Placeholder from "../placeholder";
import Shortcuts from "../shortcuts";
import SpaceFix from "../space-fix";
import Subscript from "../subscript";
import Suggestion from "../suggestion";
import Superscript from "../superscript";
import Table from "../table";
import TaskItem from "../task-item";
import TaskList from "../task-list";
import Telemetry, { EditorEvent } from "../telemetry";
import TextColor from "../text-color";
import TrailingNode from "../trailing-node";

export interface CadmusKitOptions {
  /**
   * Unique App wide ID for the current `Editor`.
   */
  editorId: string;
  /**
   * Callback for notifying when a save should occur after the transaction
   * debounce interval.
   */
  onSave?: (editorId: string) => void;
  /**
   * Callback receiving telemetry events in the Editor
   */
  onEditorEvent?: (event: EditorEvent) => void;
  /**
   * Callback to handle opening link
   */
  onOpenLink?: (href: string) => void;
  /**
   * Shared suggestions API client for Cadmus spellcheck.
   *
   * This is needed to configure the `Suggestion` extension.
   */
  suggestionClient?: SuggestionClient;
  /**
   * Pantheon API base endpoint for the media upload and access services.
   *
   * This is needed if the `ImageUploader` extension is to be used.
   *
   * Examples:
   *   `http://localhost:4006/api/work_media`,
   *   `http://api.cadmus.io/cadmus/api/work_media`
   */
  imageAPIBase?: string;
  /**
   * Cadmus tenant name.
   * This is needed if the `ImageUploader` extension is to be used.
   */
  tenant?: string;
  /**
   * Cadmus assessment ID.
   * This is needed if the `ImageUploader` extension is to be used.
   */
  assessmentId?: string;
  /**
   * Cadmus Work ID
   * This is needed if the `ImageUploader` extension is to be used.
   */
  workId?: string;
  /**
   * Cadmus User role.
   * This is needed if the `ImageUploader` extension is to be used.
   */
  userRole?: string;
  /**
   * Cadmus User ID
   * This is needed if the `ImageUploader` extension is to be used.
   */
  userId?: string;
  /**
   * Disable Cadmus suggestions based spell check. Defaults to false. When
   * Cadmus spellcheck is enabled (default), the browser spellcheck is disabled
   * (default).
   */
  disableCadmusSpellCheck?: boolean;
  /**
   * Enable Browser spellcheck. Defaults to false. Enabling browser spellcheck
   * will disable cadmus spellcheck.
   */
  enableBrowserSpellCheck?: boolean;
  /**
   * Enable hanging indent special indentation for all viable blocks.
   * Defaults to being disabled.
   */
  enableHangingIndent?: boolean;
  /**
   * Enable paste chip annotations on incoming external pastes.
   * Defaults to being disabled.
   */
  enablePasteAnnotations?: boolean;
  /**
   * Plain text placeholder for the first heading.
   */
  titlePlaceholder?: string;
  /**
   * Plain text placeholder in the content area.
   */
  contentPlaceholder?: string;
  /**
   * Rich custom referencing help placeholder in the content area.
   */
  referencesPlaceholder?: boolean;
  /**
   * Minimum number of blocks inside the editor
   * @default 1
   */
  minBlocks?: number;
}

export const CadmusKit = Extension.create<CadmusKitOptions>({
  name: "cadmusKit",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          attributes: () => {
            return {
              role: "textbox",
              "aria-multiline": "true",
              translate: "no",
              id: `cadmus-${this.options.editorId}`,
            };
          },
        },
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-a": () => this.editor.commands.selectAll(),
    };
  },

  addExtensions() {
    const extensions = [
      Document,
      Heading.configure({
        levels: [1, 2, 3, 4],
      }),
      HardBreak,
      Paragraph,
      Bold,
      Italic,
      Strike,
      Text,
      AtomSelection,
      Placeholder,
      FloatingMenuExtension,
      MathExtension,
      Table,
      List,
      BlockQuote,
      EditorPlaceholder.configure({
        titlePlaceholder: this.options.titlePlaceholder,
        contentPlaceholder: this.options.contentPlaceholder,
        referencesPlaceholder: this.options.referencesPlaceholder,
      }),
      Emoji,
      EmptyImageNode,
      ListItem,
      BulletList,
      OrderedList,
      this.options.onOpenLink
        ? Hyperlink.configure({
            onOpenLink: this.options.onOpenLink,
          })
        : Hyperlink,
      this.options.enableHangingIndent
        ? Indentation.configure({
            specialTypes: ["paragraph"],
            levelTypes: [],
            indentationStyles: ["hanging"],
            defaultIndentation: "hanging",
          })
        : Indentation,
      Superscript,
      Subscript,
      TextAlign.configure({
        types: ["paragraph", "heading", "image"],
      }),
      Underline,
      Highlight,
      TextStyle,
      TextColor,
      TaskItem,
      TaskList,
      PasteChip,
      Image,
      TrailingNode.configure({
        minBlocks: this.options.minBlocks ?? 1,
      }),
      SpaceFix,
      Shortcuts,
      History,
      BlockTextVisibility.configure({
        types: ["tableCaption", "tableFootnote"],
      }),
      BlockBackground.configure({
        types: ["table", "tableCell", "tableHeader"],
      }),
      Paste.configure({
        editorId: this.options.editorId,
        annotatePaste: this.options.enablePasteAnnotations ?? false,
      }),
      Telemetry.configure({
        editorId: this.options.editorId,
        onSave: this.options.onSave,
        suggestionClient: this.options.suggestionClient,
        onEditorEvent: this.options.onEditorEvent,
      }),
      CodeBlock,
      Code,
    ];

    // Configure and add the Suggestion extension if the client is provided
    const { suggestionClient } = this.options;

    if (suggestionClient) {
      const suggestion = Suggestion.configure({
        client: suggestionClient,
        spellCheckSource: this.options.enableBrowserSpellCheck
          ? "browser"
          : this.options.disableCadmusSpellCheck
          ? null
          : "cadmus",
      });
      extensions.push(suggestion);
    }

    // Configure and add the ImageUploader extension only if the user attributes and endpoint is provided.
    const { imageAPIBase, tenant, assessmentId, workId, userRole, userId } =
      this.options;

    if (imageAPIBase && tenant && assessmentId && userRole && userId) {
      const imageUploader = ImageUploader.configure({
        imageAPIBase,
        tenant,
        assessmentId,
        workId,
        userRole,
        userId,
      });
      extensions.push(imageUploader);
    }

    return extensions;
  },
});
