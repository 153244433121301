import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 27px;
  width: 100%;
  max-width: 1360px;
  padding-left: 45px;
  padding-right: 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;
