export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7.5H15V12.5H1V7.5ZM2 8.5V11.5H14V8.5H2Z"
      fill="#C3CDDF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 11.5H15V15C15 15.8284 14.3284 16.5 13.5 16.5H2.5C1.67157 16.5 1 15.8284 1 15V11.5ZM2 12.5V15C2 15.2761 2.22386 15.5 2.5 15.5H13.5C13.7761 15.5 14 15.2761 14 15V12.5H2Z"
      fill="#C3CDDF"
    />
    <path d="M1.5 5C1.5 4.44772 1.94772 4 2.5 4H13.5C14.0523 4 14.5 4.44772 14.5 5V8H1.5V5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 5C1 4.17157 1.67157 3.5 2.5 3.5H13.5C14.3284 3.5 15 4.17157 15 5V8.5H1V5ZM2.5 4.5C2.22386 4.5 2 4.72386 2 5V7.5H14V5C14 4.72386 13.7761 4.5 13.5 4.5H2.5Z"
    />
  </>
);
