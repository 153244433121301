import React from "react";
import styled, { css } from "styled-components";

import { colors } from "../styles";
import { Text } from "../Text";

interface Props {
  /**
   * Severity of the Alert to define the icon and color.
   * @default "info"
   */
  severity?: "info" | "warning" | "error";
  /**
   * Alert title.
   */
  title?: React.ReactNode;
  /**
   * Alert contents.
   */
  children?: React.ReactNode;
}

/**
 * Attract user attention with important static message with many severity
 * levels.
 */
export const Alert = (props: Props) => {
  const { severity = "info", children, title } = props;

  return (
    <AlertRoot severity={severity}>
      <SeverityIcon>
        {severity === "warning" ? (
          <WarningIcon />
        ) : severity === "error" ? (
          <ErrorIcon />
        ) : null}
      </SeverityIcon>
      <div>
        <Text kind="paragraph" bold>
          {title}
        </Text>
        <Text kind="paragraph">{children}</Text>
      </div>
    </AlertRoot>
  );
};

const ErrorIcon = () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      d="M7.13397 1.81641C7.51887 1.14974 8.48112 1.14974 8.86602 1.81641L15.134 12.6728C15.5189 13.3395 15.0378 14.1728 14.2679 14.1728H1.73205C0.962251 14.1728 0.481125 13.3395 0.866025 12.6728L7.13397 1.81641Z"
      fill="#DB0025"
    />
    <path d="M8.79995 4.96875V10.6354H7.19995V4.96875H8.79995Z" fill="white" />
    <path d="M8.79995 12.9688V11.6354H7.19995V12.9688H8.79995Z" fill="white" />
  </svg>
);

const WarningIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 8.14453C0 3.72625 3.58172 0.144531 8 0.144531C12.4183 0.144531 16 3.72625 16 8.14453C16 12.5628 12.4183 16.1445 8 16.1445C3.58172 16.1445 0 12.5628 0 8.14453Z"
      fill="#EC6D13"
    />
    <path d="M9 3.14453V10.2279H7V3.14453H9Z" fill="white" />
    <path d="M9 13.1445V11.4779H7V13.1445H9Z" fill="white" />
  </svg>
);

const AlertRoot = styled.div<Pick<Props, "severity">>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 10px;
  padding: 16px 20px;

  box-shadow: 0px 0px 4px 0px #5b6f981f, 1px 2px 0px 0px #1a1f300f,
    4px 0px 0px 0px #d92644 inset;

  border: 1px solid transparent;
  border-width: 1px 1px 1px 3px;

  ${(p) =>
    p.severity === "error"
      ? css`
          background: ${colors.red100};
          border-color: ${colors.red500};
        `
      : p.severity === "warning"
      ? css`
          background: ${colors.yellow100};
          border-color: ${colors.orange500};
        `
      : undefined}
`;

const SeverityIcon = styled.div`
  min-width: 20px;
  height: 20px;
  margin-top: 2px;
  line-height: 1.5;
`;
