import { createGlobalStyle } from "styled-components";

import { CuiTheme } from "../styles";
import normalise from "./normalise";

/**
 * Creates a global style
 */
export const CssBaseline = createGlobalStyle<{ theme: CuiTheme }>`
 ${normalise};

 html {
    font-family: Acumin-Pro, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, "Noto Sans", "Noto Sans CJK", "Noto Color Emoji", sans-serif;
    font-size: 16px;
    color: ${(props) => props.theme.text.main}; 
    fill: ${(props) => props.theme.text.main}; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
  }

  body {
    padding: 0;
    margin: 0;
  }
`;
