import * as Sentry from "@sentry/react";

import { startSubmitListeners } from "@/stores/listeners/submit-listeners";
import { startTimelineListeners } from "@/stores/listeners/timeline-listeners";
import { startSaveListeners } from "@/stores/listeners/save-listeners";
import { startExamListeners } from "@/stores/listeners/exam-listeners";

import { listenerMiddleware, startAppListening } from "./listenerMiddleware";
import { setupDOMListeners } from "./setup-dom-listeners";
import { rootReducer } from "./root-reducer";
import { configureStore } from "@reduxjs/toolkit";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

// Attach listeners
startTimelineListeners(startAppListening);
startSubmitListeners(startAppListening);
startSaveListeners(startAppListening);
startExamListeners(startAppListening);

const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

setupDOMListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
