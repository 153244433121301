import { LinkButton, Spacer, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

interface Props {
  message: string;
  description?: string;
  action?: string;
  onAction?: VoidFunction;
}

/**
 * React component for custom Aphrodite toasts.
 */
export function ToastContent(props: Props) {
  const { message, description, action, onAction } = props;
  return (
    <Root>
      <Text kind="headingFive" color="inherit">
        {message}
        {action && (
          <ActionLink inline color="blueA500" onClick={onAction}>
            {action}
          </ActionLink>
        )}
      </Text>
      {description && (
        <>
          <Text kind="system" color="inherit">
            {description}
          </Text>
          <Spacer spacing={8} />
        </>
      )}
    </Root>
  );
}

const Root = styled.div`
  color: ${colors.white100};
`;

const ActionLink = styled(LinkButton)`
  margin-left: 16px;
`;
