import { isList, Editor } from "@tiptap/core";
import { ResolvedPos, Node as PMNode } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { canJoin } from "@tiptap/pm/transform";

/**
 * go through list/list item nodes before and after current selection
 * or given ResolvedPos $pos and see if it can be joined with list/list
 * item on that position
 */
export function maybeJoinList(
  editor: Editor,
  tr: Transaction,
  $pos?: ResolvedPos
): boolean {
  const $from = $pos || tr.selection.$from;

  let joinable: number[] = [];
  let index: number;
  let parent: PMNode;
  let before: PMNode | null | undefined;
  let after: PMNode | null | undefined;
  for (let depth = $from.depth; depth >= 0; depth--) {
    parent = $from.node(depth);

    // join backward
    index = $from.index(depth);
    before = parent.maybeChild(index - 1);
    after = parent.maybeChild(index);

    if (
      before &&
      after &&
      before.type.name === after.type.name &&
      isList(before.type.name, editor.extensionManager.extensions)
    ) {
      const pos = $from.before(depth + 1);
      joinable.push(pos);
    }

    // join forward
    index = $from.indexAfter(depth);
    before = parent.maybeChild(index - 1);
    after = parent.maybeChild(index);

    if (
      before &&
      after &&
      before.type.name === after.type.name &&
      isList(before.type.name, editor.extensionManager.extensions)
    ) {
      const pos = $from.after(depth + 1);
      joinable.push(pos);
    }
  }

  // sort `joinable` reversely
  joinable = [...new Set(joinable)].sort((a, b) => b - a);
  let updated = false;

  for (const pos of joinable) {
    if (canJoin(tr.doc, pos)) {
      tr.join(pos);
      updated = true;
    }
  }

  return updated;
}
