import {
  Extension,
  ParentConfig,
  callOrReturn,
  findParentNode,
  getExtensionField,
  isNodeSelection,
} from "@tiptap/core";
import { MathfieldElement, convertLatexToMarkup } from "mathlive";
import { NodeSelection, Selection } from "@tiptap/pm/state";

import {
  MathDeleteIndicatorPlugin,
  MathDeleteIndicatorPluginKey,
} from "./math-delete-indicator-plugin";
import {
  MathMenuAction,
  MathMenuPlugin,
  MathMenuPluginKey,
  MathMenuTarget,
} from "./math-menu-plugin";
import { BlockMath } from "./nodes/block-math";
import { InlineMath } from "./nodes/inline-math";
import { MathFigure } from "./nodes/math-figure";
import { getMathNodeTypes } from "./utils";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    math: {
      /**
       * Update `node.attrs.equation` of a selected math block to `newEquation`
       * and close the MathMenu
       */
      updateEquation: (newEquation: string) => ReturnType;
      /**
       * Dispath transaction meta action to `MathMenuPluginKey` to
       * open/close `MathMenu`
       */
      setEquationMenuTarget: (target?: MathMenuTarget) => ReturnType;
      /**
       * Open equation menu if a math block is selected
       * mainly used for `Enter` keyboard shortcut
       */
      maybeOpenEquationMenu: () => ReturnType;
      /**
       * Highlight equation that will get deleted if delete button is clicked
       */
      predeleteHighlightEquation: (pos: number, show: boolean) => ReturnType;
      /**
       * Delete equation block under selection if no pos is given otherwise,
       * delete equation block at pos
       */
      deleteEquation: (pos?: number) => ReturnType;
    };
  }

  interface NodeConfig<Options, Storage> {
    /**
     * Math Role
     */
    mathRole?:
      | string
      | ((this: {
          name: string;
          options: Options;
          storage: Storage;
          parent: ParentConfig<NodeConfig<Options>>["mathRole"];
        }) => string);
  }
}

export const MathExtension = Extension.create({
  name: "math",
  addExtensions: () => [
    MathFigure,
    BlockMath.configure({
      convertLatexToMarkup: (latex) => {
        return convertLatexToMarkup(latex, { mathstyle: "displaystyle" });
      },
    }),
    InlineMath.configure({
      convertLatexToMarkup: (latex) => {
        return convertLatexToMarkup(latex, { mathstyle: "textstyle" });
      },
    }),
  ],
  addProseMirrorPlugins() {
    return [
      MathMenuPlugin({ editor: this.editor }),
      MathDeleteIndicatorPlugin(),
    ];
  },
  onBeforeCreate() {
    // Initialize MathfieldElement so that all mathlive components gets rendered properly
    new MathfieldElement();
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.maybeOpenEquationMenu(),
      Delete: () => this.editor.commands.deleteEquation(),
    };
  },

  extendNodeSchema(extension) {
    const context = {
      name: extension.name,
      options: extension.options,
      storage: extension.storage,
    };

    return {
      mathRole: callOrReturn(getExtensionField(extension, "mathRole", context)),
    };
  },
  addCommands() {
    return {
      updateEquation:
        (newEquation: string) =>
        ({ editor, state, chain }) => {
          if (!isNodeSelection(state.selection)) {
            return false;
          }
          const mathTypes = getMathNodeTypes(editor.schema);
          const node = state.selection.node;
          if (
            ![mathTypes.inline.name, mathTypes.block.name].includes(
              node.type.name
            )
          )
            return false;

          return chain()
            .updateAttributes(node.type, {
              equation: newEquation,
            })
            .dismissMenu()
            .focus()
            .command(({ tr, dispatch }) => {
              const { selection } = tr;
              const { from } = selection;
              const nextSelection = Selection.findFrom(
                tr.doc.resolve(from + 1),
                1,
                true
              );
              if (nextSelection) {
                dispatch?.(tr.setSelection(nextSelection));
              }
              return true;
            })
            .run();
        },
      setEquationMenuTarget:
        (target) =>
        ({ tr, dispatch }) => {
          const payload: MathMenuAction = target
            ? { type: "triggerMenu", target }
            : { type: "dismissMenu" };
          dispatch?.(tr.setMeta(MathMenuPluginKey, payload));
          return true;
        },
      maybeOpenEquationMenu:
        () =>
        ({ editor, state, commands }) => {
          const { selection } = state;
          const mathTypes = getMathNodeTypes(editor.schema);
          if (isNodeSelection(selection)) {
            const { node } = selection;
            if (
              [mathTypes.inline.name, mathTypes.block.name].includes(
                node.type.name
              )
            ) {
              commands.setEquationMenuTarget({
                node,
                pos: selection.from,
              });
              return true;
            }
          }
          return false;
        },
      predeleteHighlightEquation:
        (pos, show) =>
        ({ tr, dispatch }) => {
          dispatch?.(
            tr.setMeta(MathDeleteIndicatorPluginKey, {
              highlight: show ? pos : -1,
            })
          );
          return true;
        },
      deleteEquation:
        (pos?: number) =>
        ({ editor, chain }) => {
          let chainedCommands = chain();
          const mathTypes = getMathNodeTypes(editor.schema);
          let nodePos;
          if (pos) {
            nodePos = pos;
            chainedCommands = chainedCommands.command(({ tr, dispatch }) => {
              const node = tr.doc.nodeAt(pos);
              if (!node) return false;
              if (node.type.name === mathTypes.block.name) {
                const nodeSelection = new NodeSelection(tr.doc.resolve(pos));
                dispatch?.(tr.setSelection(nodeSelection));
              }
              return true;
            });
          }
          return chainedCommands
            .command(({ tr, dispatch }) => {
              const { selection } = tr;
              if (!isNodeSelection(selection)) {
                if (pos === undefined) {
                  return false;
                } else {
                  const node = tr.doc.nodeAt(pos);
                  if (!node) return false;
                  if (
                    [mathTypes.figure.name, mathTypes.inline.name].includes(
                      node.type.name
                    )
                  ) {
                    dispatch?.(tr.delete(pos, pos + node.nodeSize));
                    return true;
                  }
                  return false;
                }
              }
              if (
                selection.node.type.name === mathTypes.inline.name ||
                selection.node.type.name === mathTypes.figure.name
              ) {
                dispatch?.(tr.delete(selection.from, selection.to));
              } else {
                const mathRoot = findParentNode(
                  (node) => node.type.name === mathTypes.figure.name
                )(selection);
                nodePos = selection.from;
                if (mathRoot) {
                  dispatch?.(
                    tr.delete(
                      mathRoot.pos,
                      mathRoot.pos + mathRoot.node.nodeSize
                    )
                  );
                }
              }
              return true;
            })
            .predeleteHighlightEquation(nodePos ?? 0, false)
            .run();
        },
    };
  },
});
