import { IconProps } from "./types";

export const IntersectionIcon = (props: IconProps) => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" {...props}>
      <path
        d="M22 11.3333C22 11.1492 22.1492 11 22.3333 11H29.6667C29.8508 11 30 11.1492 30 11.3333V18.6667C30 18.8508 29.8508 19 29.6667 19H22.3333C22.1492 19 22 18.8508 22 18.6667V11.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12V18H29V12H23ZM22.3333 11C22.1492 11 22 11.1492 22 11.3333V18.6667C22 18.8508 22.1492 19 22.3333 19H29.6667C29.8508 19 30 18.8508 30 18.6667V11.3333C30 11.1492 29.8508 11 29.6667 11H22.3333Z"
        fill="#7070A9"
      />
      <path
        d="M6.89588 22.972C6.74654 23.2387 6.60254 23.3987 6.46388 23.452C6.38921 23.484 6.30921 23.5 6.22388 23.5C5.91454 23.5 5.70121 23.34 5.58388 23.02C5.56254 22.956 5.55188 21.2387 5.55188 17.868C5.55188 14.4547 5.55721 12.7053 5.56788 12.62C5.69588 11.2227 6.24521 10.06 7.21588 9.132C8.19721 8.204 9.36521 7.67067 10.7199 7.532C10.7732 7.52133 10.8479 7.516 10.9439 7.516C10.9865 7.49467 11.1465 7.49467 11.4239 7.516C11.9039 7.516 12.3839 7.57467 12.8639 7.692C13.9092 7.96933 14.8052 8.492 15.5519 9.26C16.4692 10.2093 16.9812 11.3293 17.0879 12.62C17.0985 12.7053 17.1039 14.4547 17.1039 17.868C17.1039 21.2387 17.0932 22.956 17.0719 23.02C16.9759 23.3293 16.7625 23.484 16.4319 23.484C16.1119 23.484 15.8932 23.3133 15.7759 22.972C15.7759 21.8307 15.7705 20.1133 15.7599 17.82C15.7492 15.5267 15.7439 13.804 15.7439 12.652L15.7119 12.444C15.5625 11.5693 15.1892 10.8227 14.5919 10.204C13.6852 9.29733 12.5972 8.844 11.3279 8.844C10.6132 8.844 9.92521 9.00933 9.26388 9.34C8.65588 9.63867 8.14388 10.06 7.72788 10.604C7.32254 11.1373 7.06121 11.7507 6.94388 12.444L6.91188 12.652L6.89588 17.82V22.972Z"
        fill="#3A517F"
      />
      <path d="M20 6.5H22V8.5H20V6.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5.5H23V9.5H19V5.5ZM20 6.5V8.5H22V6.5H20Z"
        fill="#7070A9"
      />
      <path d="M20 21.5H22V23.5H20V21.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 20.5H23V24.5H19V20.5ZM20 21.5V23.5H22V21.5H20Z"
        fill="#7070A9"
      />
    </svg>
  );
};
