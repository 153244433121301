import { Tab, Tabs } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { CadmusLogo } from "@/components/cadmus-logo";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { selectIsExam } from "@/features/assignment";
import {
  selectActiveReadingDate,
  selectExamStartDatePassed,
} from "@/features/timeline";
import { selectHasDraft, selectHasFinal } from "@/features/work";

import {
  MaterialsTab,
  selectMaterialsState,
  setTab,
} from "../../materials-slice";

/**
 * Materials TabBar component which can display the choice of all the panes
 * which are rendered in the MaterialsPane (selected by the enum
 * `MaterialsTab`).
 *
 * The open state, selected tab, and callbacks are pulled from the
 * contextual Preferences.
 */
export const TabBar = () => {
  const dispatch = useAppDispatch();
  const hasDraft = useAppSelector(selectHasDraft);
  const hasFinal = useAppSelector(selectHasFinal);
  const { tab } = useAppSelector(selectMaterialsState);

  const isExam = useAppSelector(selectIsExam);
  const isReadingTime = useAppSelector(selectActiveReadingDate) !== null;
  const examStarted = useAppSelector(selectExamStartDatePassed) && isExam;

  return (
    <TabContainer>
      <CadmusLogo />
      <Tabs>
        <Tab
          kind="primary"
          onClick={() => dispatch(setTab(MaterialsTab.Instructions))}
          selected={tab === MaterialsTab.Instructions}
          aria-label="Open instructions tab"
        >
          Instructions
        </Tab>
        {!isReadingTime && examStarted && (
          <Tab
            kind="primary"
            onClick={() => dispatch(setTab(MaterialsTab.Notes))}
            selected={tab === MaterialsTab.Notes}
            aria-label="Open notes tab"
          >
            Notes
          </Tab>
        )}
        {!isExam && (hasDraft || tab === MaterialsTab.Draft) ? (
          <Tab
            kind="primary"
            onClick={() => dispatch(setTab(MaterialsTab.Draft))}
            selected={tab === MaterialsTab.Draft}
            aria-label="Open draft submission tab"
          >
            Draft
          </Tab>
        ) : null}
        {!isExam && (hasFinal || tab === MaterialsTab.Final) ? (
          <Tab
            kind="primary"
            onClick={() => dispatch(setTab(MaterialsTab.Final))}
            selected={tab === MaterialsTab.Final}
            aria-label="Open final submission tab"
          >
            Final
          </Tab>
        ) : null}
      </Tabs>
    </TabContainer>
  );
};

const TabContainer = styled.div`
  height: 100%;
  display: inline-flex;
  align-items: center;
`;
