import { Node, mergeAttributes, textblockTypeInputRule } from "@tiptap/core";
import { Selection } from "@tiptap/pm/state";

import {
  CODEBLOCK_MARKDOWN_INPUT_REGEX,
  MARKDOWN_CODEBLOCK_REGEX,
} from "../../core/text";
import { PasteHandler } from "../paste";
import { findTableGroup } from "../table/utils";
import CodeBlockView from "./code-block-node-view";
import {
  CodeBlockDeleteIndicatorPlugin,
  CodeBlockDeleteIndicatorPluginKey,
  CodeBlockPasteMeta,
  CodeBlockPastePlugin,
  CodeBlockPastePluginKey,
} from "./plugins";
import { ArrowDirection, CodeBlockLanguage, CodeBlockTheme } from "./types";
import { findCodeBlockSelection, parseMarkdownLanguage } from "./utils";

export interface CodeBlockOptions {
  /** A list of attributes to be rendered */
  HTMLAttributes: Record<string, unknown>;

  /** The theme for all codeBlocks in the editor */
  theme: CodeBlockTheme;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    codeblock: {
      insertCodeBlock: (options?: {
        theme?: CodeBlockTheme;
        language?: CodeBlockLanguage;
        position?: number;
      }) => ReturnType;

      /** Set the language for the codeBlock */
      setCodeBlockLanguage: (lang: CodeBlockLanguage) => ReturnType;

      /** Changes the theme for all codeBlock */
      setCodeBlockTheme: (theme: CodeBlockTheme) => ReturnType;

      /** Sets the visibility of line numbers */
      setLineNumbersVisibility: (visibility: boolean) => ReturnType;

      /** Sets visibility of active language */
      setActiveLanguageVisibility: (visibililty: boolean) => ReturnType;

      /** Delete the selected codeBlock node */
      deleteCodeBlock: () => ReturnType;

      /** Apply pre-delete styles to the selected codeBlock before it is being deleted*/
      preDeleteHighlightCodeBlock: (state: boolean) => ReturnType;

      /** Handles arrow key behaviour near a codeBlock  */
      handleCodeBlockArrow: (dir: ArrowDirection) => ReturnType;

      /**
       * Set CodeBlockPaste metadata for `languange` to parsed languange from the vscode paste metadata
       * and `source` to vscode if it's a paste from vscode
       **/
      handleVSCodePasteMetadata: PasteHandler<ReturnType>;

      /**
       * Set CodeBlockPaste metadata for `languange` to parsed language from markdown codeblock's language syntax
       * and `source` to markdown if it's a markdown text paste
       **/
      handleMarkdownPasteMetadata: PasteHandler<ReturnType>;

      /**
       * Set CodeBlockPaste metadata for `isInsideCobeblock` to true if the active selection is
       * inside codeblock
       **/
      handleCodeBlockPasteMetadata: PasteHandler<ReturnType>;

      /**
       * Add CodeBlock paste metadata. used by `handleVSCodePasteMetadata`
       * `handleMarkdownPasteMetadata` & `handleCodeBlockPasteMetadata`
       **/
      setCodeBlockPasteMeta: (meta: CodeBlockPasteMeta) => ReturnType;

      /**
       * Check if it's a vscode paste through checking `CodeBlockPasteMeta` and the parse languge
       * and insert a codeBlock block with the parsed language and text
       */
      handleVSCodePaste: (text?: string) => ReturnType;

      /**
       * Check if it's a markdown paste through checking `CodeBlockPasteMeta` and the parse languge
       * and insert a codeBlock block with the parsed language and text
       */
      handleMarkdownPaste: (text?: string) => ReturnType;
      /**
       * Add paste chip after codeBlock on paste
       */
      addCodeBlockPasteChip: () => ReturnType;
    };
  }
}

export const CodeBlock = Node.create<CodeBlockOptions>({
  name: "codeBlock",

  content: "text*",
  draggable: true,
  marks: "",
  code: true,
  defining: true,
  isolating: true,

  addOptions() {
    return {
      /** Default HTMLAttributes to be rendered */
      HTMLAttributes: {},

      /** Default `CodeBlockTheme` */
      theme: CodeBlockTheme.Light,
    };
  },

  addAttributes() {
    return {
      language: {
        default: CodeBlockLanguage.PlainText,
        parseHTML: (element) => {
          // The attribute may exists on the `code` tag
          const lang = element.children[0]
            ? element.children[0].getAttribute("data-code-block-lang")
            : null;
          return lang;
        },
        renderHTML: (attributes) => ({
          "data-code-block-lang": attributes.language,
        }),
      },

      lineNumbersVisible: {
        default: true,
      },

      languageVisible: {
        default: true,
      },

      theme: {
        default:
          typeof window !== undefined &&
          typeof window.matchMedia === "function" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
            ? CodeBlockTheme.Dark
            : CodeBlockTheme.Light,

        parseHTML: (element) => {
          // Extract attribute from the `code` tag
          const theme = element.children[0]
            ? element.children[0].getAttribute("data-code-block-theme")
            : null;
          return theme;
        },
        renderHTML: (attributes) => ({
          "data-code-block-theme": attributes.theme,
        }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "pre",
        preserveWhitespace: "full",
      },
    ];
  },

  addKeyboardShortcuts() {
    return {
      ArrowLeft: () =>
        this.editor.commands.handleCodeBlockArrow(ArrowDirection.Left),
      ArrowRight: () =>
        this.editor.commands.handleCodeBlockArrow(ArrowDirection.Right),
      ArrowUp: () =>
        this.editor.commands.handleCodeBlockArrow(ArrowDirection.Up),
      ArrowDown: () =>
        this.editor.commands.handleCodeBlockArrow(ArrowDirection.Down),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "pre",
      ["code", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0],
    ];
  },

  addNodeView() {
    return ({ node, editor, getPos }) => {
      return new CodeBlockView(node, editor, getPos as () => number);
    };
  },

  addProseMirrorPlugins() {
    return [
      // Update the view to indicate that the selected `CodeBlock` is being deleted
      CodeBlockDeleteIndicatorPlugin(),
      // Handle Codeblock Paste Meta
      CodeBlockPastePlugin(),
    ];
  },

  addCommands() {
    return {
      insertCodeBlock:
        (options) =>
        ({ tr, dispatch, state, commands }) => {
          if (findTableGroup(state.selection)) return false;
          const { selection } = tr;
          const { $from } = selection;

          // Do nothing if already in a `CodeBlock` node
          const existingCodeBlock = findCodeBlockSelection(state.selection);
          if (existingCodeBlock) {
            if (dispatch) {
              commands.setTextSelection($from.pos);
              return true;
            }
          }

          const node = this.type.create(options);
          if (options && options.position !== undefined) {
            if (dispatch) {
              tr.insert(options.position, node);
              commands.setTextSelection(options.position);
            }
          } else {
            const { selection } = tr;

            if (dispatch) {
              const content = state.doc.textBetween(
                selection.from,
                selection.to
              );
              tr.replaceRangeWith(selection.from, selection.to, node);
              tr.insertText(content, selection.from);
              commands.setTextSelection(selection.from + 2);
            }
          }
          return true;
        },

      setCodeBlockTheme:
        (theme) =>
        ({ state, chain }) => {
          const { selection } = state;
          const sel = findCodeBlockSelection(selection);

          if (!sel) return false;

          const { pos } = sel;

          return chain()
            .setNodeSelection(pos)
            .updateAttributes(this.name, { theme: theme })
            .setTextSelection(selection)
            .run();
        },

      setCodeBlockLanguage:
        (lang) =>
        ({ state, chain }) => {
          const { selection } = state;

          const sel = findCodeBlockSelection(selection);

          if (!sel) return false;

          const { pos } = sel;
          return chain()
            .setNodeSelection(pos)
            .updateAttributes(this.name, { language: lang })
            .setTextSelection(selection)
            .run();
        },

      setActiveLanguageVisibility:
        (visibility) =>
        ({ state, chain }) => {
          const { selection } = state;

          const sel = findCodeBlockSelection(selection);

          if (!sel) return false;

          const { pos } = sel;
          return chain()
            .setNodeSelection(pos)
            .updateAttributes(this.name, { languageVisible: visibility })
            .setTextSelection(selection)
            .run();
        },

      setLineNumbersVisibility:
        (visibility) =>
        ({ state, chain }) => {
          const { selection } = state;

          const sel = findCodeBlockSelection(selection);

          if (!sel) return false;

          const { pos } = sel;
          return chain()
            .setNodeSelection(pos)
            .updateAttributes(this.name, { lineNumbersVisible: visibility })
            .setTextSelection(selection)
            .run();
        },

      deleteCodeBlock:
        () =>
        ({ chain, state }) => {
          const { selection } = state;
          const sel = findCodeBlockSelection(selection);

          if (sel) {
            chain()
              .preDeleteHighlightCodeBlock(false)
              .deleteRange({
                from: sel.pos,
                to: sel.pos + sel.node.nodeSize,
              })
              .focus()
              .run();
          }

          return true;
        },

      preDeleteHighlightCodeBlock:
        (state) =>
        ({ tr, dispatch }) => {
          dispatch?.(
            tr.setMeta(CodeBlockDeleteIndicatorPluginKey, { highlight: state })
          );
          return true;
        },

      handleCodeBlockArrow:
        (dir) =>
        ({ state, dispatch, view }) => {
          // Find the nearest leaf node position
          if (state.selection.empty && view.endOfTextblock(dir)) {
            const { $head } = state.selection;
            const searchBias =
              dir == ArrowDirection.Left || dir == ArrowDirection.Up ? -1 : 1;
            if ($head.node().type.name === "doc") return false;
            const position = searchBias > 0 ? $head.after() : $head.before();
            const nextPos = Selection.findFrom(
              state.doc.resolve(position),
              searchBias,
              true
            );

            // If `CodeBlock` is found, dispatch transaction to set selection in `CodeBlock`
            if (
              nextPos?.$head &&
              nextPos.$head.parent.type.name == "codeBlock"
            ) {
              dispatch?.(state.tr.setSelection(nextPos));
              return true;
            }
          }
          // Arrow key behaviour not handled
          return false;
        },

      handleVSCodePasteMetadata:
        (event, moved, _slice, _html, _text) =>
        ({ commands }) => {
          if (moved) return false;
          let vscode;
          if ((event as ClipboardEvent).clipboardData) {
            vscode = (event as ClipboardEvent).clipboardData?.getData(
              "vscode-editor-data"
            );
          } else {
            vscode = (event as DragEvent).dataTransfer?.getData(
              "vscode-editor-data"
            );
          }
          const vscodeData = vscode ? JSON.parse(vscode) : undefined;
          const language = vscodeData?.mode;
          if (!language) return false;
          return commands.setCodeBlockPasteMeta({
            language: parseMarkdownLanguage(language),
            source: "vscode",
          });
        },

      handleCodeBlockPasteMetadata:
        (_event, moved, slice, _html, _text) =>
        ({ commands, state }) => {
          if (moved) return false;
          const codeblock = findCodeBlockSelection(state.selection);
          if (!codeblock?.node) return false;
          if (slice.content.firstChild?.type === this.type) return false;
          return commands.setCodeBlockPasteMeta({
            insideCodeBlock: true,
          });
        },

      setCodeBlockPasteMeta:
        (meta) =>
        ({ tr, dispatch }) => {
          dispatch?.(tr.setMeta(CodeBlockPastePluginKey, meta));
          return true;
        },

      handleVSCodePaste:
        (text) =>
        ({ tr, dispatch, state }) => {
          if (!text) return false;
          const codeBlockPasteMeta = tr.getMeta(CodeBlockPastePluginKey) as
            | CodeBlockPasteMeta
            | undefined;
          if (codeBlockPasteMeta?.source === "vscode") {
            const codeBlockNode = this.type.createChecked(
              {
                language: codeBlockPasteMeta.language,
              },
              state.schema.text(text)
            );
            tr.replaceSelectionWith(codeBlockNode).scrollIntoView();
            dispatch?.(tr);
            return true;
          }
          return false;
        },

      handleMarkdownPasteMetadata:
        (_event, _moved, _slice, _html, text) =>
        ({ commands }) => {
          if (!text) return false;
          if (MARKDOWN_CODEBLOCK_REGEX.test(text)) {
            const matches = text.match(MARKDOWN_CODEBLOCK_REGEX);
            if (!matches || matches.length < 3) return false;
            const language = parseMarkdownLanguage(matches[1]);
            return commands.setCodeBlockPasteMeta({
              language,
              source: "markdown",
            });
          }
          return false;
        },
      handleMarkdownPaste:
        (text?: string) =>
        ({ tr, dispatch, state }) => {
          if (!text) return false;
          const codeBlockPasteMeta = tr.getMeta(CodeBlockPastePluginKey) as
            | CodeBlockPasteMeta
            | undefined;
          if (
            codeBlockPasteMeta?.source === "markdown" &&
            MARKDOWN_CODEBLOCK_REGEX.test(text)
          ) {
            const matches = text.match(MARKDOWN_CODEBLOCK_REGEX);
            if (!matches || matches.length < 3) return false;
            const content = matches[2];
            const codeBlockNode = this.type.createChecked(
              {
                language: codeBlockPasteMeta.language,
              },
              state.schema.text(content)
            );
            tr.replaceSelectionWith(codeBlockNode).scrollIntoView();
            dispatch?.(tr);
            return true;
          }
          return false;
        },
      addCodeBlockPasteChip:
        () =>
        ({ commands }) =>
          commands.addPasteChipAfterPredicate(findCodeBlockSelection),
    };
  },

  addInputRules() {
    return [
      /** BackTick based input parsing */
      textblockTypeInputRule({
        find: CODEBLOCK_MARKDOWN_INPUT_REGEX,
        type: this.type,
        getAttributes: (match) => ({
          language: parseMarkdownLanguage(match[1]),
        }),
      }),
    ];
  },
});
