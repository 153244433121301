import styled from "styled-components";

/**
 * A wrapper container to centrally position a component.
 *
 * By default, the Block provides you with:
 *
 *   - A total maximum width of 1270px,
 *   - In-built horizontal padding (45px on the left and right),
 *   - Horizonal center positioning
 */

export const Block = styled.div`
  display: block;
  width: 100%;
  max-width: 1360px;
  padding-left: 45px;
  padding-right: 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;
