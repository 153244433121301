import { Content } from "@vericus/cadmus-editor-prosemirror";
import { convertSlateValueString } from "@vericus/cadmus-editor-save-transformer";

import { SaveFragment } from "generated/graphql";

import { WorkEditors, WorkSaveDoc, isSaveFromSlateEditors } from "./types";

/**
 * Deserialse and parse a server fetched Save document into the current
 * Prosemirror Editors based save document.
 *
 * If the save was from the older Slate editors based Aphrodite, the editor
 * contents are parsed and converted to a semantic HTML string that Prosmirror
 * can parse.
 */
export function deserialiseWorkEditors(save: SaveFragment): WorkEditors | null {
  try {
    const doc: WorkSaveDoc = JSON.parse(save.content);
    if (isSaveFromSlateEditors(doc)) {
      // Run HTML conversion on all editors
      return {
        body: convertSlateValueString(doc.body.state),
        notes: convertSlateValueString(doc.notes.state),
        references: convertSlateValueString(doc.references.state),
      };
    } else {
      return {
        body: JSON.parse(doc.body.state),
        notes: JSON.parse(doc.notes.state),
        references: JSON.parse(doc.references.state),
      };
    }
  } catch (e) {
    console.error("Error parsing save", e);
    return null;
  }
}

/**
 * Deserialise unkown Editor contents into a Prosemirror editor content.
 *
 * If the given `content` is already a Prosemirror document value, it is
 * returned as-is. Otherwise, a HTML conversion is attempted.
 */
export function deserialiseEditor(content: string): Content {
  try {
    const value = JSON.parse(content);
    // Check if the content is already a Prosemirror JSON string
    if (value["type"] === "doc") {
      return value;
    }
    // Otherwise run Slate editor to HTML conversion
    return convertSlateValueString(content);
  } catch (e) {
    console.error("Unkown editor contents", e);
    return null;
  }
}
