import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "@tiptap/core";

import {
  FloatingMenuOpenStatePlugin,
  FloatingMenuPluginOptions,
} from "./floating-menu-plugin";

export interface UseFloatingMenuOpenStateProps
  extends Omit<FloatingMenuPluginOptions, "setOpen"> {
  onClose?: (editor: Editor) => void;
}

/**
 * React hook tracking FloatingMenu open state.
 *
 * The hook uses a render effect to register a Prosemirror `PluginView` plugin
 * with an `Editor`, which updates the open state along with the view updates.
 * Each view update applies `options.shouldShow` to keep the menu open state in
 * sync.
 *
 * @returns React `useState<boolean>()` return type.
 */
export function useFloatingMenuOpenState(
  props: UseFloatingMenuOpenStateProps
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const { pluginKey, editor, shouldShow, isAsync, onClose } = props;
  const [open, setOpen] = useState(false);
  const openRef = useRef<boolean>(open);

  useEffect(() => {
    editor.registerPlugin(
      FloatingMenuOpenStatePlugin({
        editor,
        pluginKey,
        setOpen,
        shouldShow,
        isAsync,
      })
    );
    return () => {
      editor.unregisterPlugin(pluginKey);
    };
  }, [editor, pluginKey, shouldShow, isAsync, setOpen]);

  useEffect(() => {
    if (openRef.current !== open && !open) {
      onClose?.(editor);
    }
    openRef.current = open;
  }, [open, onClose, editor]);

  return [open, setOpen];
}
