import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";

/**
 * Disable all forms of Cadmus and Browser spell check. This is useful Grammarly
 * should be the sole spell checker.
 */
export function disableSpellCheck(editor: Editor): boolean {
  return editor.commands.setSpellCheckSource(null);
}

/**
 * Switch to using Cadmus suggestions in the editor.
 */
export function enableCadmusSpellCheck(editor: Editor): boolean {
  return editor.commands.setSpellCheckSource("cadmus");
}

/**
 * Check if Cadmus spellcheck suggestions are enabled on the Editor attributes.
 */
export function canSpellCheckWithCadmus(editor: Editor): boolean {
  return !!editor.view.someProp("attributes", (attributes) => {
    const attrs =
      typeof attributes === "object" ? attributes : attributes(editor.state);
    return attrs["data-cadmus-spellcheck"] === "true";
  });
}

/**
 * Check if Browser spellcheck is enabled on the Editor attributes.
 */
export function canSpellCheckWithBrowser(editor: Editor): boolean {
  return !!editor.view.someProp("attributes", (attributes) => {
    const attrs =
      typeof attributes === "object" ? attributes : attributes(editor.state);
    return attrs["spellcheck"] === "true";
  });
}

/**
 * Create the Editor element HTML attributes which control spellchecking
 * behaviours.
 */
export function spellCheckAttrs(source: "cadmus" | "browser" | null) {
  // Defaults to Cadmus spellcheck over Browser spellcheck
  let browser = "false";
  let cadmus = "false";

  if (source === "cadmus") {
    cadmus = "true";
    browser = "false";
  }

  if (source === "browser") {
    cadmus = "false";
    browser = "true";
  }

  return {
    spellcheck: browser,
    "data-cadmus-spellcheck": cadmus,
  };
}

type DocRange = {
  from?: number;
  to?: number;
};

export function rangeFromTransaction(tr: Transaction): DocRange {
  let from, to;
  for (let i = 0; i < tr.steps.length; i++) {
    const step = tr.steps[i] as any;
    const stepMapping = step.getMap();
    // new Position after step
    const stepFrom = stepMapping.map(step.from || step.pos, -1);
    const stepTo = stepMapping.map(step.to || step.pos, 1);
    if (from) {
      from = Math.min(stepMapping.map(from, -1), stepFrom);
    } else {
      from = stepFrom;
    }

    if (to) {
      to = Math.max(stepMapping.map(to, 1), stepTo);
    } else {
      to = stepTo;
    }
  }
  return {
    from,
    to,
  };
}

/**
 * Get the start position of the Block prior to the from selection as start and
 * the end position of the Block where the to selection is as end
 */
export function getNodeBoundaries(
  doc: PMNode,
  from?: number,
  to?: number
): { start: number; end: number } {
  const docSize = doc.content.size;
  const nodeBeforeFrom = doc.childBefore(from && docSize > from ? from : 0);
  const nodeBeforeTo = doc.childBefore(to && docSize > to ? to : docSize);
  const start = nodeBeforeFrom.offset;
  const end = nodeBeforeTo.node?.nodeSize
    ? nodeBeforeTo.offset + nodeBeforeTo.node?.nodeSize
    : docSize;
  return { start, end };
}
