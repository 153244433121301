// The minimum permissible size for an image that is being resized
// Defined as a design choice to prevent extremely small images
export const MIN_CUSTOM_WIDTH = 160;

// The actual text width being used by the editor text.
// Calculated by subtracting padding of 45px each side from a width of 800px
// as defined in CadmusEditorContent.ts
export const TEXT_WIDTH = 710;

// The maximum size that can be achieved using free resizing
// Chosen to be the same as `TEXT_WIDTH` as a design decision
export const MAX_CUSTOM_WIDTH = TEXT_WIDTH;

// The default custom width size for the imageNode. The default width type `fitToText`
// and the corresponding image size is `TEXT_WIDTH`
export const DEFAULT_CUSTOM_WIDTH = TEXT_WIDTH;
