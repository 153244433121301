import { colors, typography } from "@vericus/cadmus-ui";
import styled, { css, keyframes } from "styled-components";

import { codeBlockStyles } from "../extensions/code-block/styles";
import { codeStyles } from "../extensions/code/styles";
import { mathStyles } from "../extensions/math/styles";
import { tableStyles } from "../extensions/table/styles";

const MAX_INDENT = 8;

const blockIndentStyles = () =>
  Array.from(Array(MAX_INDENT + 1).keys()).map(
    (indent) => css`
      & > *[data-indent="${indent}"] {
        padding-left: ${45 + indent * 27}px;
      }

      & > *[data-indent-special="hanging"] {
        &[data-indent="${indent}"] {
          padding-left: ${45 + (indent + 1) * 27}px;
        }
      }
    `
  );

export const indentation = css`
  ${blockIndentStyles}
  tab-size: 27px;
  & > *[data-indent-special="hanging"] {
    text-indent: -27px;
  }
  & > *[data-indent-special="firstLine"] {
    text-indent: 27px;
  }
  & > *[data-indent-special="normal"] {
    text-indent: 0px;
  }
`;

const blocks = css`
  & > .ProseMirror-gapcursor {
    max-width: 800px;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: 45px;
    padding-right: 45px;
    box-sizing: border-box;
    display: inline-block;
  }
  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > blockquote,
  & > p,
  & > ul,
  & > ol,
  & > hr,
  & > pre {
    padding-right: 45px;
    padding-left: 45px;
    color: ${(p) => p.theme.text.main};
    box-sizing: border-box;
    max-width: 800px;
    min-width: 360px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
  }

  a,
  a:any-link {
    color: ${(p) => p.theme.text.accent};
  }

  .selected-link {
    color: ${(p) => p.theme.text.accent};
    text-decoration: underline;
  }

  .outer-placeholder {
    white-space: nowrap;
    color: ${(p) => p.theme.text.shade1};
  }

  .inner-placeholder {
    position: absolute;
    left: 0;
    white-space: pre-wrap;
    width: 100%;
    text-indent: initial;
    max-height: 270px;
    overflow: hidden;
    padding: 0 45px;
    box-sizing: border-box;
  }

  p {
    font-weight: ${typography.paragraph.fontWeight};
    font-size: ${typography.paragraph.fontSize};
    line-height: ${typography.paragraph.lineHeight};
    letter-spacing: ${typography.paragraph.letterSpacing};
    padding-top: ${typography.paragraph.paddingTop};
    padding-bottom: ${typography.paragraph.paddingBottom};
    min-height: calc(
      ${typography.paragraph.fontSize} * ${typography.paragraph.lineHeight}
    );
    margin-block-start: 0;
    margin-block-end: 0;
  }

  h1 {
    color: ${(p) => p.theme.text.heading};
    font-weight: ${typography.headingOne.fontWeight};
    font-size: ${typography.headingOne.fontSize};
    line-height: ${typography.headingOne.lineHeight};
    letter-spacing: ${typography.headingOne.letterSpacing};
    padding-top: ${typography.headingOne.paddingTop};
    padding-bottom: ${typography.headingOne.paddingBottom};
    min-height: calc(
      ${typography.headingOne.fontSize} * ${typography.headingOne.lineHeight}
    );
  }

  h2 {
    color: ${(p) => p.theme.text.heading};
    font-weight: ${typography.headingTwo.fontWeight};
    font-size: ${typography.headingTwo.fontSize};
    line-height: ${typography.headingTwo.lineHeight};
    letter-spacing: ${typography.headingTwo.letterSpacing};
    padding-top: ${typography.headingTwo.paddingTop};
    padding-bottom: ${typography.headingTwo.paddingBottom};
    min-height: calc(
      ${typography.headingTwo.fontSize} * ${typography.headingTwo.lineHeight}
    );
  }

  h3 {
    color: ${(p) => p.theme.text.heading};
    font-weight: ${typography.headingThree.fontWeight};
    font-size: ${typography.headingThree.fontSize};
    line-height: ${typography.headingThree.lineHeight};
    letter-spacing: ${typography.headingThree.letterSpacing};
    padding-top: ${typography.headingThree.paddingTop};
    padding-bottom: ${typography.headingThree.paddingBottom};
    min-height: calc(
      ${typography.headingThree.fontSize} *
        ${typography.headingThree.lineHeight}
    );
  }

  h4 {
    color: ${(p) => p.theme.text.heading};
    font-weight: ${typography.headingFour.fontWeight};
    font-size: ${typography.headingFour.fontSize};
    line-height: ${typography.headingFour.lineHeight};
    letter-spacing: ${typography.headingFour.letterSpacing};
    padding-top: ${typography.headingFour.paddingTop};
    padding-bottom: ${typography.headingFour.paddingBottom};
    min-height: calc(
      ${typography.headingFour.fontSize} * ${typography.headingFour.lineHeight}
    );
  }

  blockquote {
    max-width: 800px;
    min-width: 360px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 45px;
    padding-right: 45px;
    box-sizing: border-box;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: relative;
    & > * {
      margin: 0;
      padding: 0;
      padding-left: 36px;
      border-left: 1px solid ${(p) => p.theme.text.shade1};
      color: ${(p) => p.theme.text.shade1};
    }
  }

  pre {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  hr {
    border: 0;
    height: 1px;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: ${(p) => p.theme.divider};
  }

  li {
    font-weight: ${typography.paragraph.fontWeight};
    font-size: ${typography.paragraph.fontSize};
    line-height: ${typography.paragraph.lineHeight};
    letter-spacing: ${typography.paragraph.letterSpacing};
    padding-top: ${typography.paragraph.paddingTop};
    padding-bottom: ${typography.paragraph.paddingBottom};

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    padding-inline-start: 40px;
  }

  ul[data-type="taskList"] {
    padding-inline-start: 18px;
  }

  /* First level OLs and ULs are padded */
  & > ol,
  & > ul {
    padding-inline-start: 81px;
  }
  & > ul[data-type="taskList"] {
    padding-inline-start: 63px;
  }

  ol {
    list-style-type: decimal;
    /* Editor layout styles */
    max-width: 800px;
    min-width: 360px;
    padding-right: 45px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: block;

    /* UnorderedList styles */
    margin-top: 0;
    margin-bottom: 0;
  }

  ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol {
    list-style-type: lower-roman;
  }
  ol ol ol ol {
    list-style-type: decimal;
  }
  ol ol ol ol ol {
    list-style-type: lower-alpha;
  }
  ol ol ol ol ol ol {
    list-style-type: lower-roman;
  }
  ol ol ol ol ol ol ol {
    list-style-type: decimal;
  }
  ol ol ol ol ol ol ol ol {
    list-style-type: lower-alpha;
  }

  ul {
    list-style-type: disc;
    /* Editor layout styles */
    max-width: 800px;
    min-width: 360px;
    padding-right: 45px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    display: block;
    /* UnorderedList styles */
    margin-top: 0;
    margin-bottom: 0;
  }

  ul[data-type="taskList"] {
    list-style: none;

    & > li[data-checked="true"] {
      & > div > p:first-child {
        text-decoration: line-through;
      }
    }

    & > li {
      display: flex;
      margin-top: 9px;

      & > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        align-self: baseline;
      }
      & > div {
        max-width: 800px;
        min-width: 360px;
        &:not(ul[data-type="task-list"], li, label, input) {
          margin: 0;
        }
      }
    }
  }

  ul ul {
    list-style-type: circle;
  }
  ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul {
    list-style-type: disc;
  }
  ul ul ul ul ul {
    list-style-type: circle;
  }
  ul ul ul ul ul ul {
    list-style-type: square;
  }
  ul ul ul ul ul ul ul {
    list-style-type: disc;
  }
  ul ul ul ul ul ul ul ul {
    list-style-type: circle;
  }
`;

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const loader = css`
  display: block;
  width: 18px;
  height: 18px;
  border-right: 2px solid ${(p) => p.theme.text.shade1};
  border-top: 2px solid ${(p) => p.theme.background.action3};
  border-bottom: 2px solid ${(p) => p.theme.background.action3};
  border-left: 2px solid ${(p) => p.theme.background.action3};
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 9px);
  left: calc(50% - 9px);
  transform: translate(-50%, -50%);
  animation: ${spinner} 1s linear infinite;
`;

const mediaStyles = css`
  figure {
    display: block;
    margin: 1em auto;
    padding: 0;
    position: relative;
    max-width: 800px;

    figcaption {
      border: 0;
      display: block;
      font-size: 14px;
      line-height: 24px;
      width: 100%;
      max-width: 800px;
      margin: 1em auto 0;
      padding: 0 45px;
      box-sizing: border-box;
      /* for absolute positioning of ::before placeholder */
      position: relative;
      outline: none;
      background: none;
      resize: none;
      user-select: text;

      &.no-caption {
        margin: 0;
        padding: 0;
        font-size: 0;
        /* Resize handles are difficult to hit without this*/
        line-height: 0;
      }
    }

    img:not(.ProseMirror-separator) {
      display: block;
      box-sizing: border-box;
      min-width: 160px;
      width: 100%;
      min-height: 63px;
      object-fit: contain;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;

      &[data-image-uploading="true"] {
        opacity: 0.36;
      }
    }

    span[data-loading="true"] {
      ${loader}
    }

    &.ProseMirror-selectednode {
      img {
        outline: 1.5px solid ${(p) => p.theme.text.accent};
        outline-offset: 3px;
      }
      outline: none;
    }
  }

  figure[data-loading="true"] {
    img {
      opacity: 0.36;
    }
  }

  :not(.ProseMirror-focused) > figure.ProseMirror-selectednode > img {
    outline: 3px solid ${(p) => p.theme.background.action3};
    outline-offset: 2px;
  }

  figure[data-empty-node] {
    figcaption::before {
      content: "Add a caption...";
      color: ${(p) => p.theme.text.shade1};
      /* place the text behind text cursor */
      z-index: -1;
    }
  }

  .img-wrapper {
    position: relative;
    display: block;
    width: 710px; /** 800px - 45px * 2 horizontal padding */
    min-width: 160px;
    max-width: 100%;

    &[data-image-width="full"] {
      width: 800px;
      object-fit: cover;
    }

    &[data-image-width="fitToText"] {
      width: 710px;
      object-fit: contain;
    }

    &[data-image-width="original"] {
      object-fit: contain;
    }

    &[data-image-width="custom"] {
      object-fit: cover;
    }

    &[data-image-align="left"] {
      margin: 0px auto 0px 45px;
    }

    &[data-image-align="right"] {
      margin: 0px 45px 0px auto;
    }

    &[data-image-align="center"] {
      margin: 0px auto;
    }
  }

  .resize-point {
    width: 9px;
    height: 9px;
    background: white;
    border: 1.5px solid ${(p) => p.theme.text.accent};
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
  }

  .resize-point--ne {
    left: -7px;
    top: -7px;
    cursor: nwse-resize; /*resize-point cursor*/
  }

  .resize-point--nw {
    right: -7px;
    top: -7px;
    cursor: nesw-resize;
  }

  .resize-point--sw {
    left: -7px;
    bottom: -7px;
    cursor: nesw-resize;
    background: white;
  }

  .resize-point--se {
    right: -7px;
    bottom: -7px;
    cursor: nwse-resize;
  }
`;

const widgetStyles = css`
  .ProseMirror-widget.image-uploader-error {
    width: 100%;
    padding: 45px;
    box-sizing: border-box;
    max-width: 710px;
    min-height: 99px;
    margin: 1em auto;
    text-align: center;
    background: ${(p) => p.theme.background.action1};

    & > p {
      margin: 18px 0;
    }

    & > img {
      width: 100%;
      max-width: 540px;
    }
  }

  .cui-LinkButton {
    /* Apply typography styles based on inline inheritance or system default */
    font-size: ${typography["system"].fontSize};
    letter-spacing: ${typography["system"].letterSpacing};
    font-weight: initial;
    line-height: ${typography["system"].lineHeight};

    text-align: left;
    background: transparent;
    text-decoration: none;
    border: none;
    padding: 0;
    outline: 0;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    cursor: pointer;
  }

  .cui-LinkButton > span {
    display: inline;

    color: ${(p) => p.theme.text.accent};
    fill: ${(p) => p.theme.text.accent};
    background-image: linear-gradient(
        to right,
        ${(p) => p.theme.text.accent} 0%,
        ${(p) => p.theme.text.accent} 100%
      ),
      linear-gradient(
        to right,
        ${(p) => p.theme.text.shade2} 0%,
        ${(p) => p.theme.text.shade2} 50%,
        transparent 50%,
        transparent 100%
      );

    background-size: 0% 1px, 2px 1px;
    background-repeat: no-repeat, repeat-x;
    background-position: bottom left;

    transform: translateZ(0);
    will-change: background;
    transition: background 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  /* On hover, animate the underline of the LinkButtonText */
  .cui-LinkButton:hover > span {
    background-size: 100% 1px, 2px 1px;
  }
  .cui-LinkButton:active > span,
  .cui-LinkButton:focus > span {
    background-size: 100% 2px, 2px 1px;
  }
`;

const suggestionStyles = css`
  .suggestion {
    text-decoration: solid underline red;
    :hover {
      background: pink;
    }
  }
`;

const blink = keyframes`
  to {
    visibility: hidden;
  }
`;

export const CadmusEditorWrapper = styled.div`
  .ProseMirror {
    --table-background-color: ${colors.blue200};
    position: relative;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
    outline: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    min-width: 450px;
    &:focus {
      outline: none;
    }

    ${codeBlockStyles}

    ${codeStyles}

    ${indentation}

    ${blocks}

    ${tableStyles}

    ${mediaStyles}

    ${mathStyles}

    ${widgetStyles}

    ${suggestionStyles}

    &:not([data-suggestable="false"]) {
      ${suggestionStyles}
    }

    .empty-node {
      &::before {
        content: attr(data-placeholder-text);
        display: inline-block;
        pointer-events: none;
        color: ${(p) => p.theme.text.shade1};
      }
    }

    [data-emoji-text] {
      margin-right: 0.5em;
    }

    [data-cadmus-pasted] {
      display: inline-flex;
      align-items: center;
      vertical-align: middle;
      gap: 3px;
      padding-left: 9px;
      padding-right: 2px;
      margin: 0 2px;
      box-sizing: border-box;
      height: 24px;
      border-radius: 12px;
      line-height: 24px;
      text-indent: 0;

      font-size: ${typography["system"].fontSize};
      letter-spacing: ${typography["system"].letterSpacing};
      white-space: nowrap;

      color: ${(p) => p.theme.text.shade1};
      background: ${(p) => p.theme.background.action2};

      .icon-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: none;
        background: transparent;

        cursor: pointer;

        &:hover {
          background: ${(p) => p.theme.background.action3};
        }

        span {
          color: ${(p) => p.theme.text.shade1};
          fill: ${(p) => p.theme.text.shade1};
        }
      }
    }
    &[contenteditable="false"] {
      [data-cadmus-pasted] {
        display: none;
      }
      figure[data-empty-node="true"] {
        figcaption {
          display: none;
        }
      }
    }
  }

  img.ProseMirror-separator {
    display: inline !important;
    border: none !important;
    margin: 0 !important;
  }

  .ProseMirror-hideselection *::selection {
    background: transparent;
  }
  .ProseMirror-hideselection *::-moz-selection {
    background: transparent;
  }
  .ProseMirror-hideselection {
    caret-color: transparent;
  }
  .ProseMirror-selectednode {
    outline: 2px solid #b3d7fe;
  }
  .ProseMirror-gapcursor {
    display: none;
    pointer-events: none;
    position: absolute;
    margin: 0;
  }
  .ProseMirror-gapcursor:after {
    content: "";
    display: block;
    position: absolute;
    top: -2px;
    width: 20px;
    border-top: 1px solid black;
    animation: ${blink} 1.1s steps(2, start) infinite;
  }
  .ProseMirror-focused .ProseMirror-gapcursor {
    display: block;
  }
  &::after {
    content: "";
    display: block;
    margin-top: 108px;
    border-top: 1px solid transparent;
    width: 100%;
  }
  &::before {
    content: "";
    display: block;
    margin-bottom: 63px;
    border-bottom: 1px solid transparent;
    width: 100%;
  }
  li::marker {
    font-variant-numeric: normal;
  }

  .resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
`;
