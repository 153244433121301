/**
 * The cutoff width defines the breakpoints at which each of the collapse groups
 * will change — relative to the total width of the container.
 *
 * In order to work, you must ensure that the total width of the fully expanded
 * controlbar is LESS THAN the breakpoints defined — otherwise the breakpoints
 * can never be reached.
 *
 */
export const CUTOFF_WIDTHS = {
  flow: 0,
  more: 710,
  style: 880,
  alignment: 1020,
  list: 1060,
  attachment: 1020,
  color: 900,
};

/**
 * Viable Foreground text colors.
 */
export const TEXT_COLORS = [
  "#e81a17",
  "#ff6600",
  "#ffce00",
  "#774835",
  "#d900b5",
  "#00a854",
  "#00a3a3",
  "#007138",
  "#0066ff",
  "#aa00ff",
  "#5100f5",
  "#004183",
  "#f5f5f5",
  "#bdccd4",
  "#7f8c8d",
];

/** Default text color is as close to black as possible. */
export const DEFAULT_TEXT_COLOR = "#282A30";

/**
 * Viable Background highlight colors.
 */
export const HIGHLIGHT_COLORS = [
  "#ff003c",
  "#ffc200",
  "#ffee00",
  "#ffa969",
  "#ff9df9",
  "#b2e35a",
  "#2ee689",
  "#29cccc",
  "#57d3ff",
  "#b68cff",
  "#6565ff",
  "#4ca2ff",
  "#fafafa",
  "#dfeaf0",
  "#b0c5c7",
];

/** Default background color is transparent. */
export const DEFAULT_HIGHLIGHT_COLOR = "transparent";
