import { createSelector } from "@reduxjs/toolkit";
import { Spacer } from "@vericus/cadmus-ui";
import { isAfter } from "date-fns";
import styled from "styled-components";

import { useAppSelector } from "@/data/hooks";
import { DraftConfirmation, FinalConfirmation } from "@/features/submission";
import { selectTimeline } from "@/features/timeline";
import {
  SubmissionType,
  useLatestResultQuery,
  useOnResultUpdatedSubscription,
} from "@/generated/graphql";
import { coalesceDate, durationInMinutes, isLate } from "@/utils/datetime";

import { SpeedGraderView, TurnitinView } from "./GradebookViews";

interface Props {
  /** Work ID */
  workId: string;
  /** Submission type for the submission the result needs to be fetched. */
  submissionType: SubmissionType;
  /** Submission time */
  submittedAt: Date;
  /** User email address used for sending email receipts. */
  email: string | null;
  /** Is the student allowed to view the similarity score? */
  canViewReport: boolean;
  /** Can another submission be made? */
  canResubmit: boolean;
  /** Feedback return date for the current submission */
  returnDate: Date;
  /** Grading service selected in the sheet requriements */
  gradingService?: string;
}

const selectResultDates = createSelector(selectTimeline, (timeline) => ({
  draftDate: coalesceDate(timeline.draftDate.date),
  finalDate: coalesceDate(timeline.finalDate.date),
  startDate: coalesceDate(timeline.workStartDate.date),
  endDate: coalesceDate(timeline.writingDate.date),
}));

/**
 * Renders successful Submission outcomes in the form of a confirmation receipt,
 * the data present in Pantheon's gradebook and any data received from the
 * various integrations like Turnitin and SpeedGrader.
 *
 * The latest gradebook `Result` is queried from Pantheon and also subscribed
 * to for reactive updates.
 *
 * Whenever possible, students can launch into the external integration for
 * Similarity and Grades using the rendered action buttons.
 */
export function Result(props: Props) {
  const {
    submissionType,
    submittedAt,
    email,
    workId,
    canViewReport,
    returnDate,
    canResubmit,
    gradingService,
  } = props;

  // Query for the latest gradebook result
  const { data } = useLatestResultQuery({
    variables: {
      workId,
      type: submissionType,
    },
  });

  // Subscribe to gradebook result updates
  useOnResultUpdatedSubscription({ variables: { workId } });

  const latestResult = data?.latestResult;

  const { startDate, draftDate, finalDate, endDate } =
    useAppSelector(selectResultDates);

  // Was the submission late?
  let late = false;
  // Was the submission made in overtime in a time limited assessment?
  let overtime = false;
  // Minutes spent in a time limited assessment (if it is one)
  let duration: number | null = null;

  switch (submissionType) {
    case SubmissionType.Final: {
      if (startDate !== null && endDate !== null) {
        late = finalDate ? isLate(finalDate, submittedAt) : false;
        duration = durationInMinutes(startDate, submittedAt);
        overtime = isAfter(submittedAt, endDate);
      } else {
        late = finalDate ? isLate(finalDate, submittedAt) : false;
        duration = null;
        overtime = false;
      }
      break;
    }
    case SubmissionType.Draft: {
      late = draftDate ? isLate(draftDate, submittedAt) : false;
      duration = null;
      overtime = false;
      break;
    }
  }

  // Props for the <TurnitinView /> or <SpeedGraderView /> components
  const viewProps = {
    submissionType,
    returnDate,
    canViewReport,
    canResubmit,
  };

  return (
    <Container>
      {submissionType === SubmissionType.Final && (
        <FinalConfirmation
          email={email}
          submittedAt={submittedAt}
          late={late}
          overtime={overtime}
          duration={duration}
        />
      )}
      {submissionType === SubmissionType.Draft && (
        <DraftConfirmation
          email={email}
          submittedAt={submittedAt}
          late={late}
        />
      )}
      <Spacer spacing={45} />
      {latestResult && gradingService === "turnitin" && (
        <TurnitinView {...viewProps} result={latestResult} />
      )}
      {latestResult && gradingService === "speedgrader" && (
        <SpeedGraderView {...viewProps} result={latestResult} />
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 100%;

  width: 100%;
  min-width: 400px;
  max-width: 800px;
  padding: 72px 45px;
  margin: auto;
  box-sizing: border-box;
  text-align: center;
`;
