import { IconProps } from "./types";

export const BracketedFractionIcon = (props: IconProps) => {
  return (
    <svg width="26" height="29" viewBox="0 0 26 29" {...props}>
      <path
        d="M5.99791 23.338C5.99791 23.446 5.88991 23.5 5.67391 23.5H5.43991C5.35591 23.428 5.18791 23.284 4.93591 23.068C2.77591 21.1 1.69591 18.244 1.69591 14.5C1.69591 13.036 1.86991 11.698 2.21791 10.486C2.76991 8.602 3.67591 7.084 4.93591 5.932C5.01991 5.86 5.11591 5.782 5.22391 5.698C5.33191 5.602 5.40391 5.536 5.43991 5.5H5.67391C5.81791 5.5 5.90791 5.518 5.94391 5.554C5.97991 5.59 5.99791 5.626 5.99791 5.662C5.99791 5.71 5.93191 5.8 5.79991 5.932C3.93991 7.9 3.00991 10.756 3.00991 14.5C3.00991 18.244 3.93991 21.1 5.79991 23.068C5.93191 23.2 5.99791 23.29 5.99791 23.338Z"
        fill="#3A517F"
      />
      <path
        d="M9 3.33333C9 3.14924 9.14924 3 9.33333 3H16.6667C16.8508 3 17 3.14924 17 3.33333V10.6667C17 10.8508 16.8508 11 16.6667 11H9.33333C9.14924 11 9 10.8508 9 10.6667V3.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 4V10H16V4H10ZM9.33333 3C9.14924 3 9 3.14924 9 3.33333V10.6667C9 10.8508 9.14924 11 9.33333 11H16.6667C16.8508 11 17 10.8508 17 10.6667V3.33333C17 3.14924 16.8508 3 16.6667 3H9.33333Z"
        fill="#7070A9"
      />
      <path d="M8 13H18" stroke="#3A517F" strokeWidth="0.5" />
      <path
        d="M9 15.3333C9 15.1492 9.14924 15 9.33333 15H16.6667C16.8508 15 17 15.1492 17 15.3333V22.6667C17 22.8508 16.8508 23 16.6667 23H9.33333C9.14924 23 9 22.8508 9 22.6667V15.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16V22H16V16H10ZM9.33333 15C9.14924 15 9 15.1492 9 15.3333V22.6667C9 22.8508 9.14924 23 9.33333 23H16.6667C16.8508 23 17 22.8508 17 22.6667V15.3333C17 15.1492 16.8508 15 16.6667 15H9.33333Z"
        fill="#7070A9"
      />
      <path
        d="M20.08 5.518L20.152 5.5C20.212 5.5 20.272 5.5 20.332 5.5H20.548C20.632 5.572 20.8 5.716 21.052 5.932C23.212 7.9 24.292 10.756 24.292 14.5C24.292 15.976 24.118 17.308 23.77 18.496C23.218 20.392 22.312 21.916 21.052 23.068C20.968 23.14 20.872 23.218 20.764 23.302C20.656 23.398 20.584 23.464 20.548 23.5H20.332C20.188 23.5 20.092 23.488 20.044 23.464C20.008 23.44 19.99 23.38 19.99 23.284C20.002 23.272 20.068 23.194 20.188 23.05C22.048 21.118 22.978 18.268 22.978 14.5C22.978 10.732 22.048 7.882 20.188 5.95C20.068 5.806 20.002 5.728 19.99 5.716C19.99 5.62 20.02 5.554 20.08 5.518Z"
        fill="#3A517F"
      />
    </svg>
  );
};
