import { MathCommand } from "./types";

export const lowerAlpha: MathCommand = {
  renderChildren: () => <i>α</i>,
  label: "alpha",
  mathLiveCommand: "\\alpha",
  kind: "math",
};
export const lowerBeta: MathCommand = {
  renderChildren: () => <i>β</i>,
  label: "beta",
  mathLiveCommand: "\\beta",
  kind: "math",
};
export const lowerGamma: MathCommand = {
  renderChildren: () => <i>γ</i>,
  label: "gamma",
  mathLiveCommand: "\\gamma",
  kind: "math",
};
export const lowerDelta: MathCommand = {
  renderChildren: () => <i>δ</i>,
  label: "delta",
  mathLiveCommand: "\\delta",
  kind: "math",
};
export const lowerEpsilon: MathCommand = {
  renderChildren: () => <i>ϵ</i>,
  label: "epsilon",
  mathLiveCommand: "\\epsilon",
  kind: "math",
};
export const lowerZeta: MathCommand = {
  renderChildren: () => <i>ζ</i>,
  label: "zeta",
  mathLiveCommand: "\\zeta",
  kind: "math",
};
export const lowerEta: MathCommand = {
  renderChildren: () => <i>η</i>,
  label: "eta",
  mathLiveCommand: "\\eta",
  kind: "math",
};
export const lowerTheta: MathCommand = {
  renderChildren: () => <i>θ</i>,
  label: "theta",
  mathLiveCommand: "\\theta",
  kind: "math",
};
export const lowerIota: MathCommand = {
  renderChildren: () => <i>ι</i>,
  label: "iota",
  mathLiveCommand: "\\iota",
  kind: "math",
};
export const lowerKappa: MathCommand = {
  renderChildren: () => <i>κ</i>,
  label: "kappa",
  mathLiveCommand: "\\kappa",
  kind: "math",
};
export const lowerLambda: MathCommand = {
  renderChildren: () => <i>λ</i>,
  label: "lambda",
  mathLiveCommand: "\\lambda",
  kind: "math",
};
export const lowerMu: MathCommand = {
  renderChildren: () => <i>μ</i>,
  label: "mu",
  mathLiveCommand: "\\mu",
  kind: "math",
};
export const lowerNu: MathCommand = {
  renderChildren: () => <i>ν</i>,
  label: "nu",
  mathLiveCommand: "\\nu",
  kind: "math",
};
export const lowerXi: MathCommand = {
  renderChildren: () => <i>ξ</i>,
  label: "xi",
  mathLiveCommand: "\\xi",
  kind: "math",
};
export const lowerOmicron: MathCommand = {
  renderChildren: () => <i>ο</i>,
  label: "omicron",
  mathLiveCommand: "\\omicron",
  kind: "math",
};
export const lowerPi: MathCommand = {
  renderChildren: () => <i>π</i>,
  label: "pi",
  mathLiveCommand: "\\pi",
  kind: "math",
};
export const lowerRho: MathCommand = {
  renderChildren: () => <i>ρ</i>,
  label: "rho",
  mathLiveCommand: "\\rho",
  kind: "math",
};
export const lowerSigma: MathCommand = {
  renderChildren: () => <i>σ</i>,
  label: "sigma",
  mathLiveCommand: "\\sigma",
  kind: "math",
};
export const lowerTau: MathCommand = {
  renderChildren: () => <i>τ</i>,
  label: "tau",
  mathLiveCommand: "\\tau",
  kind: "math",
};
export const lowerUpsilon: MathCommand = {
  renderChildren: () => <i>υ</i>,
  label: "upsilon",
  mathLiveCommand: "\\upsilon",
  kind: "math",
};
export const lowerPhi: MathCommand = {
  renderChildren: () => <i>ϕ</i>,
  label: "phi",
  mathLiveCommand: "\\phi",
  kind: "math",
};
export const lowerChi: MathCommand = {
  renderChildren: () => <i>χ</i>,
  label: "chi",
  mathLiveCommand: "\\chi",
  kind: "math",
};
export const lowerPsi: MathCommand = {
  renderChildren: () => <i>ψ</i>,
  label: "psi",
  mathLiveCommand: "\\psi",
  kind: "math",
};
export const lowerOmega: MathCommand = {
  renderChildren: () => <i>ω</i>,
  label: "omega",
  mathLiveCommand: "\\omega",
  kind: "math",
};
export const upperAlpha: MathCommand = {
  text: "A",
  label: "Alpha",
  mathLiveCommand: "\\Alpha",
};
export const upperBeta: MathCommand = {
  text: "B",
  label: "Beta",
  mathLiveCommand: "\\Beta",
};
export const upperGamma: MathCommand = {
  text: "Γ",
  label: "Gamma",
  mathLiveCommand: "\\Gamma",
};
export const upperDelta: MathCommand = {
  text: "Δ",
  label: "Delta",
  mathLiveCommand: "\\Delta",
};
export const upperEpsilon: MathCommand = {
  text: "E",
  label: "Epsilon",
  mathLiveCommand: "\\Epsilon",
};
export const upperZeta: MathCommand = {
  text: "Z",
  label: "Zeta",
  mathLiveCommand: "\\Zeta",
};
export const upperEta: MathCommand = {
  text: "H",
  label: "Eta",
  mathLiveCommand: "\\Eta",
};
export const upperTheta: MathCommand = {
  text: "Θ",
  label: "Theta",
  mathLiveCommand: "\\Theta",
};
export const upperIota: MathCommand = {
  text: "I",
  label: "Iota",
  mathLiveCommand: "\\Iota",
};
export const upperKappa: MathCommand = {
  text: "K",
  label: "Kappa",
  mathLiveCommand: "\\Kappa",
};
export const upperLambda: MathCommand = {
  text: "Λ",
  label: "Lambda",
  mathLiveCommand: "\\Lambda",
};
export const upperMu: MathCommand = {
  text: "M",
  label: "Mu",
  mathLiveCommand: "\\Mu",
};
export const upperNu: MathCommand = {
  text: "N",
  label: "Nu",
  mathLiveCommand: "\\Nu",
};
export const upperXi: MathCommand = {
  text: "Ξ",
  label: "Xi",
  mathLiveCommand: "\\Xi",
};
export const upperOmicron: MathCommand = {
  text: "O",
  label: "Omicron",
  mathLiveCommand: "\\Omicron",
};
export const upperPi: MathCommand = {
  text: "Π",
  label: "Pi",
  mathLiveCommand: "\\Pi",
};
export const upperRho: MathCommand = {
  text: "P",
  label: "Rho",
  mathLiveCommand: "\\Rho",
};
export const upperSigma: MathCommand = {
  text: "Σ",
  label: "Sigma",
  mathLiveCommand: "\\Sigma",
};
export const upperTau: MathCommand = {
  text: "T",
  label: "Tau",
  mathLiveCommand: "\\Tau",
};
export const upperUpsilon: MathCommand = {
  text: "Υ",
  label: "Upsilon",
  mathLiveCommand: "\\Upsilon",
};
export const upperPhi: MathCommand = {
  text: "Φ",
  label: "Phi",
  mathLiveCommand: "\\Phi",
};
export const upperChi: MathCommand = {
  text: "X",
  label: "Chi",
  mathLiveCommand: "\\Chi",
};
export const upperPsi: MathCommand = {
  text: "Ψ",
  label: "Psi",
  mathLiveCommand: "\\Psi",
};
export const upperOmega: MathCommand = {
  text: "Ω",
  label: "Omega",
  mathLiveCommand: "\\Omega",
};
export const varKappa: MathCommand = {
  renderChildren: () => <i>ϰ</i>,
  label: "varkappa",
  mathLiveCommand: "\\varkappa",
  kind: "math",
};
export const varTheta: MathCommand = {
  renderChildren: () => <i>ϑ</i>,
  label: "vartheta",
  mathLiveCommand: "\\vartheta",
  kind: "math",
};
export const varPi: MathCommand = {
  renderChildren: () => <i>ϖ</i>,
  label: "varpi",
  mathLiveCommand: "\\varpi",
  kind: "math",
};
export const varRho: MathCommand = {
  renderChildren: () => <i>ϱ</i>,
  label: "varrho",
  mathLiveCommand: "\\varrho",
  kind: "math",
};
export const varSigma: MathCommand = {
  renderChildren: () => <i>ς</i>,
  label: "varsigma",
  mathLiveCommand: "\\varsigma",
  kind: "math",
};
export const varPhi: MathCommand = {
  renderChildren: () => <i>φ</i>,
  label: "varphi",
  mathLiveCommand: "\\varphi",
  kind: "math",
};
