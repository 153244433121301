import { IconProps } from "./types";

export const UnderbarIcon = (props: IconProps) => {
  return (
    <svg width="18" height="26" viewBox="0 0 18 26" {...props}>
      <path
        d="M17 22L1 22"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M3 7.5C3 7.22386 3.22386 7 3.5 7H14.5C14.7761 7 15 7.22386 15 7.5V18.5C15 18.7761 14.7761 19 14.5 19H3.5C3.22386 19 3 18.7761 3 18.5V7.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 8V18H14V8H4ZM3.5 7C3.22386 7 3 7.22386 3 7.5V18.5C3 18.7761 3.22386 19 3.5 19H14.5C14.7761 19 15 18.7761 15 18.5V7.5C15 7.22386 14.7761 7 14.5 7H3.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
