import React, { forwardRef } from "react";
import styled from "styled-components";

import { colors } from "./styles";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * React children
   */
  children?: React.ReactNode;
}

/**
 * A text button, styled as a link.
 *
 * LinkButtons can be used on their own, or inline with `Text`.
 *
 * LinkButtons are typically used for secondary actions, or where actions are
 * regularly repeated.
 *
 * _props extends `button` attributes_
 *
 */
export const LinkButtonV2 = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { children, ...buttonProps } = props;
    return (
      <Button ref={ref} {...buttonProps}>
        <ContentContainer>{children}</ContentContainer>
      </Button>
    );
  }
);

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  color: ${colors.blue500};
`;

const ContentContainer = styled.span``;
