import { Command } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";

import { SpecialIndentationStyle, TabDirection } from "../types";

/**
 * Create a `Command` to set the Special indentation style for blocks in current
 * selection.
 *
 * @param enabledStyles allowed Special Indentation styles
 * @param styleableNodes Node names which can have Special Indentation
 * @param style Special Indentation style to set
 * @param direction direction of the Tab key which determines whether to toggle
 *   forward or backwards through the available Special Indentation styles.
 */
export function setIndentStyle(
  enabledStyles: SpecialIndentationStyle[],
  stylableNodes: string[],
  style: SpecialIndentationStyle,
  direction: TabDirection
): Command {
  return ({ tr, dispatch, state }) => {
    // The style is not allowed
    if (!enabledStyles.includes(style)) return false;

    let can = false;
    tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;
      state.doc.nodesBetween(from, to, (node, pos, parent) => {
        const $pos = state.doc.resolve(pos);
        if (
          parent === state.doc &&
          canSetIndentStyle(stylableNodes, node, style, direction) &&
          $pos.depth <= 1
        ) {
          can = true;
          tr.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            indentSpecial: style,
          });
        }
      });
    });

    if (can) {
      dispatch?.(tr);
    }

    return can;
  };
}

// Determine cyclic order of the Special Indentation styles
function indentationOrder(style: SpecialIndentationStyle): number {
  switch (style) {
    case "hanging":
      return 0;
    case "normal":
      return 1;
    case "firstLine":
      return 2;
  }
}

// Check if the `style` can be aplied to the `node`
function canSetIndentStyle(
  stylableNodes: string[],
  node: PMNode,
  style: SpecialIndentationStyle,
  direction: TabDirection
) {
  // If the node can have no Special Indentation styles
  if (!stylableNodes.includes(node.type.name)) return false;

  // If the Tab key was not used to select styles
  if (direction === TabDirection.UNKNOWN) {
    return node.attrs["indentSpecial"] !== style;
  }

  // Moving forward through the possible styles
  if (direction === TabDirection.FORWARD) {
    return (
      indentationOrder(node.attrs["indentSpecial"]) + 1 ===
      indentationOrder(style)
    );
  }
  // Moving backwards through the possible styles
  return (
    indentationOrder(node.attrs["indentSpecial"]) - 1 ===
    indentationOrder(style)
  );
}
