import styled from "styled-components";

import { Text } from "../Text";

interface MenuHeaderProps {
  /** Label content */
  text: string;
  /** Extension classnames */
  className?: string;
}

const HeaderContainer = styled.span.attrs({ "aria-disabled": "true" })`
  display: block;
  padding: 8px 16px;
  height: 36px;
  box-sizing: border-box;
`;

/** Header child item in a parent Menu. */
export const MenuHeader = (props: MenuHeaderProps) => (
  <HeaderContainer className={props.className}>
    <Text kind="headingSix" as="span">
      {props.text}
    </Text>
  </HeaderContainer>
);
