import { colors, typography } from "@vericus/cadmus-ui";
import { css } from "styled-components";

// Table content max width === 800px - 45px padding each side
const MAX_WIDTH = 710;

export const tableStyles = css`
  figure[data-table-group] {
    color: ${(p) => p.theme.text.main};
    box-sizing: border-box;
    position: relative;
    max-width: 800px;
    min-width: 360px;
    padding-right: 45px;
    padding-left: 45px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 36px;
    margin-bottom: 36px;

    .table-group-wrapper {
      display: flex;
      flex-direction: column;
    }
    figcaption {
      padding: unset;
    }
  }

  .tableWrapper {
    display: flex;
    justify-content: center;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    border: 10px;
    max-width: ${MAX_WIDTH}px;
    clear: both;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;

    p,
    li {
      font-size: 14px; /* Table has smaller font size */
    }

    td,
    th {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    ul,
    ol {
      min-width: unset;
      max-width: unset;
      padding-right: unset;
      padding-inline-start: 20px;
    }

    &[data-table-alternate-background="true"] {
      th {
        background-color: var(--background-color);
        filter: contrast(0.9);
      }
      tr:nth-child(even) > td {
        background-color: transparent;
      }
      tr:nth-child(odd) > td {
        background-color: var(--background-color);
      }
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: -1px;
      bottom: -1px;
      width: 4px;
      z-index: 9999;
      background-color: ${colors.indigo500};
    }

    .col-insert-handle {
      position: absolute;
      left: -2px;
      top: 0;
      bottom: 0;
      width: 4px;
      z-index: 9999;
      background-color: ${colors.indigo500};
    }

    .row-insert-handle {
      position: absolute;
      right: 0;
      left: 0;
      top: -2px;
      width: 100%;
      height: 4px;
      z-index: 9999;
      background-color: ${colors.indigo500};
    }

    .selectedCell:not(.cell-predelete) {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        box-shadow: 0 0 0 1px ${colors.indigo500};
        background: #6355f610;
      }
    }

    .cell-predelete {
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: "";
        box-shadow: 0 0 0 1px ${colors.red500};
        background: #d9264408;
      }
    }
  }

  /** Table cell borders when editable */
  &[contenteditable="true"] {
    td:not(.ProseMirror-widget),
    th:not(.ProseMirror-widget) {
      word-break: break-word;
      border-color: #c3cddf;

      &:not([data-left-border="true"]) {
        border-left-color: ${colors.grey50};
      }
      &:not([data-right-border="true"]) {
        border-right-color: ${colors.grey50};
      }
      &:not([data-top-border="true"]) {
        border-top-color: ${colors.grey50};
      }
      &:not([data-bottom-border="true"]) {
        border-bottom-color: ${colors.grey50};
      }

      /** APA thick black borders when either top or bottom border is selected */
      &[data-top-border="true"][data-left-border="false"][data-right-border="false"] {
        border-top: 1.25px solid black;
      }
      &[data-bottom-border="true"][data-left-border="false"][data-right-border="false"] {
        border-bottom: 1.25px solid black;
      }
    }
  }

  /** Table cell borders when readonly */
  &[contenteditable="false"] {
    td:not(.ProseMirror-widget),
    th:not(.ProseMirror-widget) {
      border-color: #c3cddf;

      &:not([data-left-border="true"]) {
        border-left-color: transparent;
      }
      &:not([data-right-border="true"]) {
        border-right-color: transparent;
      }
      &:not([data-top-border="true"]) {
        border-top-color: transparent;
      }
      &:not([data-bottom-border="true"]) {
        border-bottom-color: transparent;
      }

      /** APA thick black borders when either top or bottom border is selected */
      &[data-top-border="true"][data-left-border="false"][data-right-border="false"] {
        border-top: 1.25px solid black;
      }
      &[data-bottom-border="true"][data-left-border="false"][data-right-border="false"] {
        border-bottom: 1.25px solid black;
      }
    }
  }

  td,
  th {
    padding-left: 8px;
    padding-right: 8px;
    position: relative;
    border: 1px solid;
    text-align: inherit;

    &[data-cadmus-background-color],
    &[data-cadmus-background-color] {
      background-color: var(--background-color) !important;
    }
  }

  div[data-table-footer="true"] {
    width: 100%;
    max-width: ${MAX_WIDTH}px;
    box-sizing: border-box;
    position: relative;
    margin-top: 16px;
    p {
      font-weight: ${typography.subtle.fontWeight};
      font-size: ${typography.subtle.fontSize};
      line-height: ${typography.subtle.lineHeight};
      letter-spacing: ${typography.subtle.letterSpacing};
      padding-top: ${typography.subtle.paddingTop};
      padding-bottom: ${typography.subtle.paddingBottom};
      min-height: calc(
        ${typography.subtle.fontSize} * ${typography.subtle.lineHeight}
      );
    }
  }

  th {
    background-color: ${colors.grey50};
    font-weight: 600;
    p,
    h1,
    h2,
    h3,
    h4 {
      font-weight: inherit;
    }
  }
`;
