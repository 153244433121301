import { IconProps } from "./types";

export const RootIcon = (props: IconProps) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" {...props}>
      <path
        d="M15 13.4167C15 13.1865 15.1865 13 15.4167 13H24.5833C24.8135 13 25 13.1865 25 13.4167V22.5833C25 22.8135 24.8135 23 24.5833 23H15.4167C15.1865 23 15 22.8135 15 22.5833V13.4167Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 14V22H24V14H16ZM15.4167 13C15.1865 13 15 13.1865 15 13.4167V22.5833C15 22.8135 15.1865 23 15.4167 23H24.5833C24.8135 23 25 22.8135 25 22.5833V13.4167C25 13.1865 24.8135 13 24.5833 13H15.4167Z"
        fill="#7070A9"
      />
      <path
        d="M6.28174 6.99775C6.28174 6.97014 6.30412 6.94775 6.33174 6.94775H10.2317C10.2594 6.94775 10.2817 6.97014 10.2817 6.99775V10.8978C10.2817 10.9254 10.2594 10.9478 10.2317 10.9478H6.33174C6.30412 10.9478 6.28174 10.9254 6.28174 10.8978V6.99775Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.33174 5.94775H10.2317C10.8116 5.94775 11.2817 6.41786 11.2817 6.99775V10.8978C11.2817 11.4777 10.8116 11.9478 10.2317 11.9478H6.33174C5.75184 11.9478 5.28174 11.4777 5.28174 10.8978V6.99775C5.28174 6.41785 5.75184 5.94775 6.33174 5.94775ZM6.33174 6.94775C6.30412 6.94775 6.28174 6.97014 6.28174 6.99775V10.8978C6.28174 10.9254 6.30412 10.9478 6.33174 10.9478H10.2317C10.2594 10.9478 10.2817 10.9254 10.2817 10.8978V6.99775C10.2817 6.97014 10.2594 6.94775 10.2317 6.94775H6.33174Z"
        fill="#7070A9"
      />
      <path
        d="M16.2937 4.6C16.3897 4.6 16.4677 4.636 16.5277 4.708C16.5877 4.768 16.6237 4.84 16.6357 4.924V5.032L12.4417 13.726L8.21174 22.474C8.16374 22.558 8.04974 22.6 7.86974 22.6C7.76174 22.6 7.68974 22.582 7.65374 22.546L4.16174 14.95L2.99174 15.796C2.93174 15.796 2.84774 15.748 2.73974 15.652C2.63174 15.544 2.57774 15.454 2.57774 15.382C2.57774 15.334 2.76974 15.154 3.15374 14.842C3.54974 14.53 3.95174 14.224 4.35974 13.924C4.77974 13.612 4.99574 13.45 5.00774 13.438C5.03174 13.414 5.06174 13.402 5.09774 13.402C5.18174 13.402 5.25374 13.462 5.31374 13.582L8.42774 20.386C8.43974 20.386 9.06374 19.108 10.2997 16.552C11.5357 13.996 12.7717 11.422 14.0077 8.83C15.2557 6.238 15.8977 4.912 15.9337 4.852C16.0177 4.684 16.1377 4.6 16.2937 4.6Z"
        fill="#3A517F"
      />
      <path
        d="M16.2939 4.94775H24.7061"
        stroke="#3A517F"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
    </svg>
  );
};
