import { SaveMetadataInput } from "generated/graphql";

import { bodyVar, notesVar, referencesVar } from "./editorVars";
import { WorkSaveDoc } from "./types";

export function serialiseWorkEditors(
  metadata: SaveMetadataInput
): WorkSaveDoc | null {
  const body = bodyVar();
  const notes = notesVar();
  const references = referencesVar();

  if (body && notes && references) {
    const document: WorkSaveDoc = {
      editor: "prosemirror",
      body: {
        state: JSON.stringify(body.getJSON()),
      },
      notes: {
        state: JSON.stringify(notes.getJSON()),
      },
      references: {
        state: JSON.stringify(references.getJSON()),
      },
      ...metadata,
    };
    return document;
  }

  return null;
}
