import { InputRule, mergeAttributes, Node } from "@tiptap/core";
import EMOJI_REGEX from "emojibase-regex";
import { NodePasteRule } from "../../paste-rules";

export const Emoji = Node.create({
  name: "emoji",
  inline: true,
  group: "inline",
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      text: {
        default: "",
        renderHTML: (attributes) => ({
          "data-emoji-text": attributes.text,
        }),
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: "span[data-emoji-text]",
        getAttrs: (domNode) => {
          if (domNode instanceof Element) {
            const text = domNode.getAttribute("data-emoji-text");
            if (text) {
              return { text };
            }
          }
          return false;
        },
      },
      {
        tag: "img[data-emoji-ch]",
        getAttrs: (domNode) => {
          if (domNode instanceof Element) {
            const text = domNode.getAttribute("data-emoji-ch");
            if (text) {
              return { text };
            }
          }
          return false;
        },
      },
      // handle paste from dropbox paper
      {
        tag: "img",
        getAttrs: (domNode) => {
          const dom = domNode as HTMLImageElement;
          const { src } = dom;
          // paper's emoji link name is emoji hexcode therefore parse the link name
          const emojiRegex =
            /https:\/\/paper.dropboxstatic.com\/static\/img\/ace\/emoji\/(.*).png/;
          const maybeEmoji = src.match(emojiRegex);
          // not paper's emoji so let the next parser parse it
          if (maybeEmoji?.length !== 2) return false;
          const emojiCode = maybeEmoji[1];
          // emojiCode is hex value of a character so parse into an integer and
          // create a character from the codePoint
          let emojiChar = "";

          try {
            emojiChar = String.fromCodePoint(parseInt(`0x${emojiCode}`, 16));
          } catch (_ex) {
            return false;
          }
          return {
            text: emojiChar,
          };
        },
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    const { text } = node.attrs;
    const attrs = mergeAttributes(HTMLAttributes, {
      contentEditable: "false",
    });
    return ["span", attrs, text];
  },
  addProseMirrorPlugins() {
    return [
      NodePasteRule(new RegExp(EMOJI_REGEX, "g"), (_node, match) => {
        const emojiText = match[0];
        return this.type.create({ text: emojiText });
      }),
    ];
  },
  addInputRules() {
    return [
      new InputRule({
        find: EMOJI_REGEX,
        handler: ({ state, match, range }) => {
          const { tr, doc } = state;
          const { from, to } = range;
          const emojiText = match[0];
          const start = doc.resolve(from).start() + (match.index ?? 0);
          tr.replaceWith(start, to, this.type.create({ text: emojiText }));
        },
      }),
    ];
  },
});
