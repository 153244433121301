import { useCallback } from "react";

import { API_ENDPOINT } from "@/config";
import { handleResourceViewEvent } from "client/events";
import { __GLOBAL_TENANT } from "client/globals";
import { ResourceFragment } from "generated/graphql";

// Handle resource open in a new window and log the RESOURCE_VIEW event.
export const useOpenResource = (resource: ResourceFragment) => {
  const open = useCallback(() => {
    const url = getResourceUrl(resource.id);
    const win = window.open(url, "_blank");
    if (win && win.focus && typeof win.focus === "function") {
      win.focus();
    }
    handleResourceViewEvent(resource);
  }, [resource]);
  return open;
};

/** Generate the resource access URL. */
export const getResourceUrl = (resourceId: string) =>
  `${API_ENDPOINT}/api/resource/${resourceId}?role=student&tenant=${__GLOBAL_TENANT.current}`;

/** e.g. "psyc-presentation" => "psyc-presentation.pptx" */
export const addExtension = (filename: string, extension: string): string => {
  const shouldAddExtension = !filename.endsWith(extension);
  return shouldAddExtension ? `${filename}.${extension}` : filename;
};

/** e.g. "psyc-presentation.pptx" => "psyc-presentation" */
export const removeExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return filename;
  }
  return filename.slice(0, lastDotIndex);
};

/** Gets an extension from a filename. e.g.:
 * "presentation.pptx" => "pptx"
 */
export const getExtension = (filename: string): string | undefined => {
  if (!filename.includes(".")) {
    return;
  }
  if (filename.lastIndexOf(".") === filename.length - 1) {
    return;
  }
  const splitName = filename.split(".");
  const extension = splitName[splitName.length - 1];
  return extension;
};

export const isLink = (resource: ResourceFragment) => !!resource.url;

/**
 * Formats the number of bytes to something like 2.10KB, 4.90MB, etc.
 * @param size number of bytes
 * @returns
 */
export const formatFileSize = (size: number): string => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const formattedBytes = (size / Math.pow(1024, i)).toFixed(2);
  const unit = units[i];
  return `${formattedBytes}${unit}`;
};
