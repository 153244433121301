import { useState } from "react";
import { Tab, Tabs, colors } from "@vericus/cadmus-ui";
import { Selector } from "mathlive";
import styled from "styled-components";

import { KEY_CATEGORIES, KeyCategory, keys } from "./keys";
import { MathKeyboardKey } from "./MathKeyboardKey";

interface MathKeyboardProps {
  executeCommand: ({
    selector,
    latexCommand,
    mathLiveCommand,
  }: {
    selector: Selector;
    latexCommand?: unknown | undefined;
    mathLiveCommand: unknown;
  }) => void;
}

export function MathKeyboard({ executeCommand }: MathKeyboardProps) {
  const [category, setCategory] = useState<KeyCategory>(KEY_CATEGORIES[0]);
  const categoryCommands = keys[category];

  return (
    <>
      <StyledTabs>
        {KEY_CATEGORIES.map((name) => {
          return (
            <StyledTab
              key={name}
              selected={category === name}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setCategory(name);
              }}
              kind="primary"
            >
              {name}
            </StyledTab>
          );
        })}
      </StyledTabs>
      <KeyboardContainer>
        <KeyboardKeysContainer>
          {categoryCommands?.map?.(
            ({
              label,
              text,
              renderChildren,
              mathLiveCommand,
              latexCommand,
              tooltip,
              tooltipCommand,
              tooltipLabel,
              kind,
              size,
            }) => (
              <MathKeyboardKey
                key={label}
                tooltip={tooltip}
                tooltipCommand={tooltipCommand ?? mathLiveCommand}
                tooltipLabel={tooltipLabel ?? label}
                kind={kind}
                size={size}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  executeCommand({
                    selector: "insert",
                    mathLiveCommand,
                    latexCommand,
                  });
                }}
              >
                {renderChildren ? renderChildren() : text}
              </MathKeyboardKey>
            )
          )}
        </KeyboardKeysContainer>
      </KeyboardContainer>
    </>
  );
}

const StyledTabs = styled(Tabs)`
  width: 100%;
`;

const StyledTab = styled(Tab)`
  min-width: unset;
  flex: 1 1 0px;
  color: ${(p) => (p.selected ? undefined : colors.grey500)};
  font-weight: ${(p) => (p.selected ? 600 : "normal")};
`;

// min-height container wrapping the keyboard as the flex rows can't be set to have equal heights.
const KeyboardContainer = styled.div`
  min-height: 116px;
`;

const KeyboardKeysContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
