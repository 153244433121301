import { useCallback, useEffect, useMemo, useState } from "react";
import * as Comlink from "comlink";
import { getCurrentDate } from "@/utils/datetime";
import { CountdownProxy } from "./countdown-proxy";
import { CountdownMessage, newCountdownMessage } from "./countdown-message";
import { isAfter, differenceInMilliseconds } from "date-fns";

/**
 * Create a countdown message to an end date.
 *
 * @param end ISO8601 date string until which the countdown ticks.
 * @returns a formatted message describing the distance to the end date.
 */
export function useCountdown(end: string): CountdownMessage {
  const milliseconds = useMemo(() => intervalInMs(new Date(end)), [end]);
  const [message, setMessage] = useState<CountdownMessage>(() =>
    newCountdownMessage(milliseconds)
  );

  // Stable callback to set the Countdown message state.
  const onMessage = useCallback(
    (msg: CountdownMessage) => {
      setMessage(msg);
    },
    [setMessage]
  );

  useEffect(() => {
    // Async setup or update of the current poller instance Proxy
    async function connect({ signal }: { signal: AbortSignal }) {
      if (signal.aborted || milliseconds < 0) {
        return undefined;
      }

      const callbackProxy = Comlink.proxy(onMessage);
      const instance = await new CountdownProxy(callbackProxy, milliseconds);

      if (!signal.aborted) {
        await instance.tick();
      }

      signal.onabort = async () => {
        await instance.stop();
      };
    }

    // Connect or update the timer state
    const controller = new AbortController();
    connect({ signal: controller.signal });

    return () => {
      controller.abort();
    };
  }, [milliseconds, onMessage]);

  return message;
}

// Get milliseconds between now and the `endDate`. Returns -1 if the `endDate`
// is in the past.
function intervalInMs(endDate: Date) {
  const now = getCurrentDate();
  if (isAfter(now, endDate)) return -1;
  return differenceInMilliseconds(endDate, now);
}
