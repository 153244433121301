import { useRef } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { ColorPickerMenu } from "../ColorPicker";
import { ControlButton } from "../ControlButton";
import { useDebouncedResizeObserver } from "../utils/useDebouncedResizeObserver";
import {
  CUTOFF_WIDTHS,
  DEFAULT_HIGHLIGHT_COLOR,
  DEFAULT_TEXT_COLOR,
  HIGHLIGHT_COLORS,
  TEXT_COLORS,
} from "./constants";
import { ControlGroup } from "./ControlGroup";
import { ControlParagraph, Props as ParagraphProps } from "./ControlParagraph";

interface ControlbarProps {
  // flow
  undo?: ControlButtonProps;
  redo?: ControlButtonProps;
  // paragraph dropdown
  paragraph?: ParagraphProps;
  // styles
  bold?: ControlButtonProps;
  italic?: ControlButtonProps;
  underline?: ControlButtonProps;
  strikethrough?: ControlButtonProps;
  superscript?: ControlButtonProps;
  subscript?: ControlButtonProps;
  // reference controlbar is up to here
  // alignments
  leftAlign?: ControlButtonProps;
  centerAlign?: ControlButtonProps;
  rightAlign?: ControlButtonProps;
  justifyAlign?: ControlButtonProps;
  // lists
  orderedList?: ControlButtonProps;
  unorderedList?: ControlButtonProps;
  checkList?: ControlButtonProps;
  // indentations
  indent?: ControlButtonProps;
  outdent?: ControlButtonProps;
  firstLineIndent?: ControlButtonProps;
  hangingIndent?: ControlButtonProps;
  normalIndent?: ControlButtonProps;
  // table
  table?: ControlButtonProps;
  // image
  image?: ControlButtonProps;
  // link
  link?: ControlButtonProps;
  // colors
  textColor?: ColorPickerControlProps;
  highlightColor?: ColorPickerControlProps;

  // CSS classname
  className?: string;

  // CodeBlock
  codeBlock?: ControlButtonProps;
  code?: ControlButtonProps;

  // Math
  blockMath?: ControlButtonProps;
  inlineMath?: ControlButtonProps;
}

/** Plugin configuration props for a plugin which will use the `ColorPicker` */
interface ColorPickerControlProps {
  /** Currently selected color to outline */
  activeColor: string;
  /** Handler for changing color */
  onChange?: (color: string) => void;
  /** Disbale the entire target button */
  disabled?: boolean;
}

export const DEBOUNCED_RENDER_TIME = 1000;

export function Controlbar(props: ControlbarProps) {
  const {
    undo,
    redo,
    paragraph,
    bold,
    italic,
    underline,
    strikethrough,
    leftAlign,
    centerAlign,
    rightAlign,
    justifyAlign,
    orderedList,
    unorderedList,
    checkList,
    indent,
    outdent,
    firstLineIndent,
    hangingIndent,
    normalIndent,
    image,
    table,
    textColor,
    highlightColor,
    superscript,
    subscript,
    className,
    link,
    codeBlock,
    code,
    blockMath,
    inlineMath,
  } = props;

  const ref = useRef<HTMLElement>(null);
  const [controlbarWidth] = useDebouncedResizeObserver(
    ref,
    DEBOUNCED_RENDER_TIME
  );

  // Determine if entire button groups should be disabled

  const flowDisabled = [undo, redo, paragraph].every(
    (member) => member === undefined || member.disabled
  );
  const styleDisabled = [bold, italic, underline, strikethrough].every(
    (member) => member === undefined || member.disabled
  );
  const alignmentDisabled = [
    leftAlign,
    centerAlign,
    rightAlign,
    justifyAlign,
  ].every((member) => member === undefined || member.disabled);
  const listDisabled = [orderedList, unorderedList, checkList].every(
    (member) => member === undefined || member.disabled
  );
  const indentationDisabled = [
    indent,
    outdent,
    firstLineIndent,
    normalIndent,
    hangingIndent,
  ].every((member) => member === undefined || member.disabled);
  const attachmentDisabled = [image].every(
    (member) => member === undefined || member.disabled
  );
  const colorDisabled = [textColor, highlightColor].every(
    (member) => member === undefined || member.disabled
  );
  const codeDisabled = [code, codeBlock].every(
    (member) => member === undefined || member.disabled
  );
  const equationDisabled = [inlineMath, blockMath].every(
    (member) => member === undefined || member.disabled
  );

  return (
    <Container ref={ref} className={className}>
      {ref.current && (
        <ControlGroup
          iconName="More"
          wrap={CUTOFF_WIDTHS.more > controlbarWidth}
          aria-label={"More"}
          disabled={false}
          aria-disabled="false"
        >
          <ControlGroup
            iconName="Indent"
            wrap={CUTOFF_WIDTHS.flow > controlbarWidth}
            aria-label={"Flow"}
            disabled={flowDisabled}
            aria-disabled={flowDisabled ? "true" : "false"}
          >
            {renderControlButton(undo, "Undo", "Undo")}
            {renderControlButton(redo, "Redo", "Redo")}

            {paragraph && (
              <>
                <Divider />
                <ControlParagraph {...paragraph} />
              </>
            )}
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="Bold"
            wrap={CUTOFF_WIDTHS.style > controlbarWidth}
            aria-label={"Style"}
            disabled={styleDisabled}
            aria-disabled={styleDisabled ? "true" : "false"}
          >
            {renderControlButton(bold, "Bold", "Bold")}
            {renderControlButton(italic, "Italic", "Italic")}
            {renderControlButton(underline, "Underline", "Underline")}
            {renderControlButton(
              strikethrough,
              "Strikethrough",
              "Strikethrough"
            )}
            {renderControlButton(superscript, "Superscript", "Superscript")}
            {renderControlButton(subscript, "Subscript", "Subscript")}
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="Color"
            wrap={CUTOFF_WIDTHS.color > controlbarWidth}
            aria-label={"Color"}
            disabled={colorDisabled}
            aria-disabled={colorDisabled ? "true" : "false"}
          >
            <ColorPickerMenu
              value={textColor ? textColor.activeColor : DEFAULT_TEXT_COLOR}
              onChange={textColor ? textColor.onChange : undefined}
              disabled={textColor ? textColor.disabled : true}
              title={"Text colour"}
              aria-label={"Text colour"}
              defaultColor={DEFAULT_TEXT_COLOR}
              defaultLabel="Black"
              pallete={TEXT_COLORS}
              iconName="Color"
              tooltipPosition="top"
            />
            <ColorPickerMenu
              value={
                highlightColor
                  ? highlightColor.activeColor
                  : DEFAULT_HIGHLIGHT_COLOR
              }
              onChange={highlightColor ? highlightColor.onChange : undefined}
              disabled={highlightColor ? highlightColor.disabled : true}
              title={"Highlight colour"}
              aria-label={"Highlight colour"}
              defaultColor={DEFAULT_HIGHLIGHT_COLOR}
              defaultLabel="None"
              pallete={HIGHLIGHT_COLORS}
              iconName="Highlight"
              tooltipPosition="top"
            />
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="LeftAlign"
            wrap={CUTOFF_WIDTHS.alignment > controlbarWidth}
            aria-label={"Alignment"}
            disabled={alignmentDisabled}
            aria-disabled={alignmentDisabled ? "true" : "false"}
          >
            {renderControlButton(leftAlign, "Left align", "LeftAlign")}
            {renderControlButton(centerAlign, "Center align", "CenterAlign")}
            {renderControlButton(rightAlign, "Right align", "RightAlign")}
            {renderControlButton(justifyAlign, "Justify align", "Justify")}
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="NumberList"
            wrap={CUTOFF_WIDTHS.list > controlbarWidth}
            aria-label={"List"}
            disabled={listDisabled}
            aria-disabled={listDisabled ? "true" : "false"}
          >
            {renderControlButton(orderedList, "Ordered list", "NumberList")}
            {renderControlButton(unorderedList, "Unordered list", "BulletList")}
            {renderControlButton(checkList, "Check list", "CheckList")}
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="HangingIndent"
            wrap
            aria-label={"Indentation"}
            disabled={indentationDisabled}
            aria-disabled={indentationDisabled ? "true" : "false"}
          >
            {renderControlButton(
              firstLineIndent,
              "First line indent",
              "FirstLine"
            )}
            {renderControlButton(
              hangingIndent,
              "Hanging indent",
              "HangingIndent"
            )}
            {renderControlButton(normalIndent, "Reset special indent", "Unset")}
            <Divider />
            {renderControlButton(indent, "Increase indent", "Indent")}
            {renderControlButton(outdent, "Decrease indent", "Outdent")}
          </ControlGroup>
          <Divider />
          <ControlGroup
            iconName="Table"
            wrap={CUTOFF_WIDTHS.attachment > controlbarWidth}
            aria-label={"Table"}
            disabled={table?.disabled}
          >
            {renderControlButton(table, "Insert table", "Table")}
          </ControlGroup>

          <ControlGroup
            iconName="Link"
            wrap={CUTOFF_WIDTHS.attachment > controlbarWidth}
            aria-label={"Link"}
            disabled={link?.disabled}
            aria-disabled={link?.disabled ? "true" : "false"}
          >
            {renderControlButton(link, "Insert link", "Link")}
          </ControlGroup>

          <ControlGroup
            iconName="Image"
            wrap={CUTOFF_WIDTHS.attachment > controlbarWidth}
            aria-label={"Image"}
            disabled={attachmentDisabled}
            aria-disabled={attachmentDisabled ? "true" : "false"}
          >
            {renderControlButton(image, "Insert image", "Image")}
          </ControlGroup>
          <ControlGroup
            iconName="Code"
            wrap={true}
            aria-label={"Insert code"}
            disabled={codeDisabled}
            aria-disabled={codeDisabled ? "true" : "false"}
          >
            {renderControlButton(
              codeBlock,
              "Insert Code Block (```)",
              "CodeBlock"
            )}
            {renderControlButton(code, "Insert Inline Code (`)", "Code")}
          </ControlGroup>
          <ControlGroup
            iconName="Equation"
            wrap={true}
            aria-label={"Insert Equation"}
            disabled={equationDisabled}
            aria-disabled={equationDisabled ? "true" : "false"}
          >
            {renderControlButton(blockMath, "Block Equation", "Equation")}
            {renderControlButton(
              inlineMath,
              "Inline Equation",
              "EquationInline"
            )}
          </ControlGroup>
        </ControlGroup>
      )}
    </Container>
  );
}

const Container = styled.span`
  display: flex;
  flex: auto;
  width: 100%;
`;

const Divider = styled.div`
  display: inline-flex;
  width: 1px;
  height: 20px;
  background: rgba(89, 115, 166, 0.15);
  margin: 7px 2px;
`;

/* Render a ControlButton for the input plugin */
function renderControlButton(
  plugin: ControlButtonProps | undefined,
  title: string,
  iconName: IconName
) {
  if (plugin) {
    const { onClick, active, disabled } = plugin;
    return (
      <ControlButton
        aria-label={title}
        selected={active}
        disabled={disabled}
        onClick={onClick}
        iconName={iconName}
        tooltipPosition="top"
      />
    );
  }

  return (
    <ControlButton
      aria-label={title}
      iconName={iconName}
      disabled
      tooltipPosition="top"
    />
  );
}

interface ControlButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  active: boolean;
  disabled?: boolean;
}
