import { useEffect, useRef } from "react";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";

import { MathViewRef } from "../MathView";
import { useMathMenuOpenCallback } from "../useMathMenuOpenCallback";
import { InlineMathView } from "./styles";

export const MathInlineNodeView = (props: NodeViewProps) => {
  if (!props.editor.isEditable) {
    const equation: string | undefined = props.node.attrs.equation;
    return (
      <NodeViewWrapper as="span">
        <InlineMathView
          defaultMode="inline-math"
          readOnly
          defaultValue={equation}
          plonkSound={undefined}
          keypressSound={undefined}
        />
      </NodeViewWrapper>
    );
  }

  return <MathInlineNodeViewEditable {...props} />;
};

const MathInlineNodeViewEditable = (props: NodeViewProps) => {
  const { editor, getPos, node } = props;

  const equation: string | undefined = node.attrs.equation;
  const openMenu = useMathMenuOpenCallback({ editor, getPos });

  const ref = useRef<MathViewRef>(null);

  // Synchronise the MathView's math-field's value with the ProseMirror node
  // attribute
  useEffect(() => {
    ref.current?.setValue(equation, {
      suppressChangeNotifications: true,
    });
  }, [equation]);

  return (
    <NodeViewWrapper as="span">
      <InlineMathView
        ref={ref}
        defaultMode="inline-math"
        readOnly
        defaultValue={equation}
        onFocus={openMenu}
        plonkSound={undefined}
        keypressSound={undefined}
      />
    </NodeViewWrapper>
  );
};
