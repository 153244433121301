import { onOnline, onOffline } from "@/data/setup-dom-listeners";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { cloudSave, SubmitError, submit, unsubmit } from "./actions";

/**
 * General Work work state.
 */
export interface WorkState {
  /** Loaded Assessment ID */
  assessmentId: string | null;
  /** Loaded Work ID */
  workId: string | null;
  /** Blanket work lock on the current session to prevent Save inconsistencies. */
  sessionLock: boolean;
  /** Network connection status */
  connectionStatus: boolean;
  /** Work has a draft submission. */
  hasDraft: boolean;
  /** Work has a final submission. */
  hasFinal: boolean;
  /** A save request is in flight. */
  saveLoading: boolean;
  /** The last save request has errored out. */
  saveError: boolean;
  /** Number of attempts the save request queue is at. Starts with 0. */
  saveAttempt: number;
  /**
   * Whether the student has accepted the academic integrity
   * submission declaration.
   */
  hasAcceptedSubmissionDeclaration: boolean;
  /** A submit request is in flight. */
  submitLoading: boolean;
  /** The last submit request has errored out. */
  submitError: SubmitError | null;
  /** Show a submission preview page. */
  submitPreview: boolean;
  /** Show a timed submit prompt locking the submission date. */
  submitPromptOpened: string | null;
}

const initialState: WorkState = {
  assessmentId: null,
  workId: null,
  hasDraft: false,
  hasFinal: false,
  saveLoading: false,
  saveError: false,
  saveAttempt: 0,
  sessionLock: false,
  connectionStatus: true,
  hasAcceptedSubmissionDeclaration: true,
  submitPreview: false,
  submitPromptOpened: null,
  submitLoading: false,
  submitError: null,
};

export const workSlice = createSlice({
  name: "work",
  initialState,
  reducers: {
    hydrateWork: (state, action: PayloadAction<HydrateWorkPayload>) => {
      state.assessmentId = action.payload.assessmentId;
      state.workId = action.payload.workId;
      state.hasDraft = action.payload.hasDraft;
      state.hasFinal = action.payload.hasFinal;
    },
    clearSaveErrors: (state) => {
      state.saveError = false;
    },
    /** Lock the current work session. */
    lockWorkSession: (state) => {
      state.sessionLock = true;
    },
    /** Accept the academic integrity submission declaration. */
    acceptSubmissionDeclaration: (state) => {
      state.hasAcceptedSubmissionDeclaration = true;
    },
    /** Decline the academic integrity submisison declaration. */
    declineSubmissionDeclaration: (state) => {
      state.hasAcceptedSubmissionDeclaration = false;
    },
    /**
     * Open the timed submit prompt which lets you submit on time or continue
     * submitting late.
     *
     * The Payload is an ISO8601 Date string.
     */
    openSubmitPrompt: (state, action: PayloadAction<string>) => {
      state.submitPromptOpened = action.payload;
    },
    /**
     * Close the timed submit prompt.
     */
    closeSubmitPrompt: (state) => {
      state.submitPromptOpened = null;
    },
    /** Clear all kinds of error states. */
    clearError: (state) => {
      state.submitError = null;
      state.submitLoading = false;
    },
    /** Trigger the submission preview state. */
    openPreview: (state) => {
      state.submitPreview = true;
    },
    /** Hide the submission preview state. */
    hidePreview: (state) => {
      state.submitPreview = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(onOnline, (state) => {
      state.connectionStatus = true;
    });

    builder.addCase(onOffline, (state) => {
      state.connectionStatus = false;
    });

    // Cloud save async thunk action
    builder.addCase(cloudSave.pending, (state) => {
      state.saveLoading = true;
      state.saveAttempt += 1;
    });

    builder.addCase(cloudSave.fulfilled, (state) => {
      state.saveLoading = false;
      state.saveError = false;
      state.connectionStatus = true;
      state.saveAttempt = 0;
    });

    builder.addCase(cloudSave.rejected, (state) => {
      state.saveLoading = false;
      state.saveError = true;
      state.connectionStatus = false;
    });

    // Submission actions

    // Submission async thunk progress
    builder.addCase(submit.pending, (state) => {
      state.submitLoading = true;
    });

    builder.addCase(submit.fulfilled, (state) => {
      state.submitLoading = false;
      state.submitPreview = false;
      state.submitError = null;
      state.submitPromptOpened = null;
    });

    builder.addCase(submit.rejected, (state, action) => {
      state.submitLoading = false;
      state.submitPromptOpened = null;

      if (action.payload) {
        // If the rejection payload is our `SubmitError` use it,
        state.submitError = action.payload;
      } else {
        // All other forms of Error (the default `SerializedError`) is
        // considered a network error.
        state.submitError = {
          kind: "network",
          title: action.error.name ?? "Network disruption",
          detail: action.error.message,
        };
      }
    });

    // Unsubmission async thunk progress
    builder.addCase(unsubmit.pending, (state) => {
      state.submitLoading = true;
    });

    builder.addCase(unsubmit.fulfilled, (state) => {
      state.submitLoading = false;
    });

    builder.addCase(unsubmit.rejected, (state) => {
      state.submitLoading = false;
      state.submitError = {
        kind: "network",
        title: "Network disruption",
      };
    });
  },
});

interface HydrateWorkPayload {
  assessmentId: string;
  workId: string;
  hasDraft: boolean;
  hasFinal: boolean;
}

// Export Actions
export const {
  hydrateWork,
  lockWorkSession,
  clearSaveErrors,
  openPreview,
  hidePreview,
  openSubmitPrompt,
  closeSubmitPrompt,
  acceptSubmissionDeclaration,
  declineSubmissionDeclaration,
  clearError,
} = workSlice.actions;

// Export Reducer
export const workReducer = workSlice.reducer;
