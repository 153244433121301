// Adopted from https://github.com/arnog/react-mathlive
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { MathfieldElement } from "mathlive";

import { MathViewProps } from "./types";
import { filterConfig, useEventRegistration, useUpdateOptions } from "./utils";

/**
 * React component wrapper for the `math-field` web component. This component
 * provides a API layer on top of the internal web-component to use it like a
 * controlled input.
 */
export const MathView = forwardRef<MathfieldElement, MathViewProps>(
  (props, ref) => {
    const [value, setValue] = useState(props.defaultValue || props.value);
    const _ref = useRef<MathfieldElement>(null);

    useImperativeHandle<MathfieldElement | null, MathfieldElement | null>(
      ref,
      () => _ref.current,
      [_ref]
    );

    const [config, passProps] = useMemo(() => filterConfig(props), [props]);
    useEventRegistration(_ref, props);
    useUpdateOptions(_ref, config);
    useEffect(() => {
      _ref.current?.setValue(props.value);
    }, [props.value]);

    return (
      <math-field
        {...passProps}
        onChange={
          props.defaultValue ? () => setValue(_ref.current?.value) : undefined
        }
        ref={_ref}
        style={{ fontSize: 18, whiteSpace: "normal" }}
      >
        {value}
      </math-field>
    );
  }
);
