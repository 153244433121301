import { Extension } from "@tiptap/core";
import {
  SuggestionDecorationPlugin,
  SuggestionDecorationPluginKey,
  RefreshSuggestionsAction,
  IgnoreWordAction,
  SpellCheckSource,
} from "./suggestion-decoration-plugin";
import {
  SuggestionMenuPluginKey,
  SuggestionMenuPlugin,
  DismissMenuAction,
} from "./suggestion-menu-plugin";
import { spellCheckAttrs } from "./helpers";
import { SuggestionClient } from "../../suggestion-client";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    suggestion: {
      /**
       * Ignore spelling suggestions for a word.
       */
      ignoreSuggestion: (word: string) => ReturnType;
      /**
       * Replace text between from and to with the accepted suggestion candidate
       */
      acceptSuggestion: (
        from: number,
        to: number,
        candidate: string
      ) => ReturnType;
      /**
       * Refresh suggestion decorations on the full document.
       */
      refreshSuggestions: () => ReturnType;

      /**
       * Spellcheck source to use or disable it entirely.
       */
      setSpellCheckSource: (source: SpellCheckSource) => ReturnType;
    };
  }
}

/** Suggestion extension initialisation options */
interface SuggestionOptions {
  /** Cadmus suggest API client interface. */
  client: SuggestionClient;
  /** Spellcheck source to use or disable it entirely. */
  spellCheckSource: SpellCheckSource;
}

/**
 * Cadmus spelling suggestions extension.
 *
 * It uses a `SuggestionClient` instance to request server spelling suggestions
 * on the editor contents and adds decorations on misspelled words. The
 * decorations contains state (via decoration spec object) which is used to
 * render a right-click context menu (via `SuggestionMenuPlugin`) for selecting
 * the correct replacement candidate via user action.
 *
 * See `SuggestionDecorationPlugin` for more details on the decoration
 * management and state.
 *
 * Both `SuggestionDecorationPlugin` and `SuggestionMenuPlugin` listen for
 * Transaction metadata actions to update their state.
 *
 * This extension triggers the event when the whole document is tokenised and
 * sent to server for spelling suggestions. This request is further optimised by
 * the `SuggestionClient` which doesn't re-process previously known words. This
 * processing is triggered on:
 *
 *   1. Editor creation.
 *   2. Every transaction in a debounced fashion.
 *
 */
export const Suggestion = Extension.create<SuggestionOptions>({
  name: "suggestion",

  onBeforeCreate() {
    this.editor.setOptions({
      editorProps: {
        attributes: {
          ...this.editor.options.editorProps.attributes,
          ...spellCheckAttrs(this.options.spellCheckSource),
        },
      },
    });
  },

  /**
   * Upon creation of the editor instance, initialised word count &
   * suggestion without debouncing it.
   */
  onCreate() {
    // Callbacks for certain events on the SuggestionClient event emitter
    const onNewSuggestions = () => this.editor.commands.refreshSuggestions();
    // Attach callbacks on SuggestionClient events
    this.options.client.on("newSuggestions", onNewSuggestions);
    // Ensure the same callbacks are detached on editor destruction
    this.editor.on("destroy", () => {
      this.options.client.off("newSuggestions", onNewSuggestions);
    });
  },

  addCommands() {
    return {
      // Ignore a word from current and future spelling suggestions
      ignoreSuggestion:
        (word: string) =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            // Remove existing decorations with the `word`
            tr.setMeta(SuggestionDecorationPluginKey, <IgnoreWordAction>{
              type: "ignoreWord",
              word,
            });
            // Close the suggestions menu
            tr.setMeta(SuggestionMenuPluginKey, <DismissMenuAction>{
              type: "dismissMenu",
            });
            this.options.client.ignoreWord(word);
          }
          return true;
        },

      // Refresh suggestion decorations on the full document
      refreshSuggestions:
        () =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            tr.setMeta(SuggestionDecorationPluginKey, <
              RefreshSuggestionsAction
            >{
              type: "refreshSuggestions",
            });
          }
          return true;
        },

      // Accept a spelling suggestion replacing the given range with the
      // candidate.
      acceptSuggestion:
        (from: number, to: number, candidate: string) =>
        ({ tr, dispatch }) => {
          if (dispatch) {
            tr.insertText(candidate, from, to);
            tr.setMeta(SuggestionMenuPluginKey, <DismissMenuAction>{
              type: "dismissMenu",
            });
          }
          return true;
        },

      setSpellCheckSource:
        (source: SpellCheckSource) =>
        ({ view }) => {
          const attributes = {
            ...view.props.attributes,
            ...spellCheckAttrs(source),
          };
          view.setProps({ attributes });
          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      SuggestionDecorationPlugin({
        client: this.options.client,
        editor: this.editor,
      }),
      SuggestionMenuPlugin({ editor: this.editor }),
    ];
  },
});
