import { Desk, Tab, Tabs, Text, colors } from "@vericus/cadmus-ui";
import { isAfter } from "date-fns";
import styled from "styled-components";

import { CadmusLogo } from "@/components/cadmus-logo";
import { LoadingQuill } from "@/components/loading-quill";
import { useAppDispatch, useAppSelector } from "@/data/hooks";
import { onContinueExam } from "@/features/assignment/assignment-slice";
import { selectWritingMinutesRemaining } from "@/features/timeline";
import { selectCanUnsubmit, unsubmit } from "@/features/work";
import {
  AssessmentFragment,
  SheetFragment,
  SubmissionMetadataFragment,
  UserFragment,
  useLatestResultQuery,
} from "@/generated/graphql";
import { StudentExperience } from "@/ui/work/StudentExperience";

import {
  ExamFeedbackConfirmation,
  ExamSubmissionConfirmation,
} from "../../components/confirmation";

interface ExamSummaryPageProps {
  workId: string;
  /** Final submission metadata. */
  submission: SubmissionMetadataFragment;
  /** Student owner of the work */
  student: UserFragment;
  /** Assessment for the work */
  assessment: AssessmentFragment;
  sheet: SheetFragment | null;
}

export function ExamSummaryPage({
  workId,
  submission,
  assessment,
  student,
  sheet,
}: ExamSummaryPageProps) {
  const dispatch = useAppDispatch();
  const canUnsubmit = useAppSelector(selectCanUnsubmit);
  const writingMinutesRemaining = useAppSelector(selectWritingMinutesRemaining);
  const isAfterFeedbackReturn =
    sheet?.returnDate && isAfter(new Date(), new Date(sheet.returnDate));
  const { data: latestResultData, loading: loadingLatestResult } =
    useLatestResultQuery({ variables: { workId, type: submission.type } });

  if (isAfterFeedbackReturn && (loadingLatestResult || !latestResultData)) {
    // if we should be showing the feedback page but we still don't have its data
    // show a loading indicator
    return <LoadingQuill />;
  }

  return (
    <Desk.Layout>
      <Desk.Shelf>
        <ShelfContainer>
          <CadmusLogo />
          <Tabs>
            <Tab kind="primary" selected>
              Summary
            </Tab>
          </Tabs>
          <ActionContainer>
            <ActionText kind="system" color="shade1">
              You have completed this exam
            </ActionText>
          </ActionContainer>
        </ShelfContainer>
      </Desk.Shelf>
      <PageContainer
        backgroundColor={
          isAfterFeedbackReturn ? colors.blue200 : colors.green200
        }
      >
        <MessageContainer>
          {isAfterFeedbackReturn ? (
            <ExamFeedbackConfirmation
              assessmentName={assessment.name}
              userName={student.fullName}
              latestResult={latestResultData?.latestResult!}
              gradingService={sheet.gradingService}
            />
          ) : (
            <ExamSubmissionConfirmation
              assessmentName={assessment.name}
              userName={student.fullName}
              submittedAt={submission.submittedAt}
              userEmail={student.email ?? undefined}
              writingTimeRemaining={
                canUnsubmit ? writingMinutesRemaining : undefined
              }
              onContinueEditing={() => {
                dispatch(unsubmit({ submissionId: submission.id }));
                dispatch(onContinueExam());
              }}
            />
          )}
        </MessageContainer>
        <StudentExperienceContainer>
          <StudentExperience />
        </StudentExperienceContainer>
      </PageContainer>
    </Desk.Layout>
  );
}

const StudentExperienceContainer = styled.div`
  margin-top: 36px;
  margin-left: 114px;
  margin-bottom: 100px;
`;

const ShelfContainer = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-left: 18px;
`;

const ActionContainer = styled.div`
  flex: auto;
  height: 100%;

  display: flex;
  align-items: center;
  overflow: hidden;
`;

const ActionText = styled(Text)`
  flex: auto;
  overflow: hidden;

  margin-right: 9px;
  white-space: nowrap;
  text-overflow: ellipsis;

  text-align: right;
`;

const PageContainer = styled.div<{ backgroundColor: string }>`
  background: ${(p) => p.backgroundColor};
  overflow: auto;
  padding: 80px 150px;
  flex: auto;
`;

const MessageContainer = styled.div`
  background: white;
  border-radius: 4px;
  padding: 64px 114px;
  min-width: 980px;
  max-width: 1160px;
  margin: auto;
  box-sizing: border-box;
`;
