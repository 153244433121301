import { IconProps } from "./types";

export const Vector2X1Icon = (props: IconProps) => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" {...props}>
      <path
        d="M11.0957 5.5749C11.0957 5.54729 11.1181 5.5249 11.1457 5.5249H15.0457C15.0733 5.5249 15.0957 5.54729 15.0957 5.5749V9.4749C15.0957 9.50252 15.0733 9.5249 15.0457 9.5249H11.1457C11.1181 9.5249 11.0957 9.50252 11.0957 9.4749V5.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1457 4.5249H15.0457C15.6256 4.5249 16.0957 4.995 16.0957 5.5749V9.4749C16.0957 10.0548 15.6256 10.5249 15.0457 10.5249H11.1457C10.5658 10.5249 10.0957 10.0548 10.0957 9.4749V5.5749C10.0957 4.995 10.5658 4.5249 11.1457 4.5249ZM11.1457 5.5249C11.1181 5.5249 11.0957 5.54729 11.0957 5.5749V9.4749C11.0957 9.50252 11.1181 9.5249 11.1457 9.5249H15.0457C15.0733 9.5249 15.0957 9.50252 15.0957 9.4749V5.5749C15.0957 5.54729 15.0733 5.5249 15.0457 5.5249H11.1457Z"
        fill="#7070A9"
      />
      <path
        d="M11.0957 12.5251C11.0957 12.4975 11.1181 12.4751 11.1457 12.4751H15.0457C15.0733 12.4751 15.0957 12.4975 15.0957 12.5251V16.4251C15.0957 16.4527 15.0733 16.4751 15.0457 16.4751H11.1457C11.1181 16.4751 11.0957 16.4527 11.0957 16.4251V12.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1457 11.4751H15.0457C15.6256 11.4751 16.0957 11.9452 16.0957 12.5251V16.4251C16.0957 17.005 15.6256 17.4751 15.0457 17.4751H11.1457C10.5658 17.4751 10.0957 17.005 10.0957 16.4251V12.5251C10.0957 11.9452 10.5658 11.4751 11.1457 11.4751ZM11.1457 12.4751C11.1181 12.4751 11.0957 12.4975 11.0957 12.5251V16.4251C11.0957 16.4527 11.1181 16.4751 11.1457 16.4751H15.0457C15.0733 16.4751 15.0957 16.4527 15.0957 16.4251V12.5251C15.0957 12.4975 15.0733 12.4751 15.0457 12.4751H11.1457Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9482 5.53217C18.9482 4.89686 18.4332 4.38184 17.7979 4.38184V3.38184C18.9855 3.38184 19.9482 4.34457 19.9482 5.53217V16.4676C19.9482 17.6552 18.9854 18.6179 17.7979 18.6179V17.6179C18.4332 17.6179 18.9482 17.1029 18.9482 16.4676V5.53217Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24419 5.53217C7.24419 4.89686 7.75922 4.38184 8.39453 4.38184V3.38184C7.20693 3.38184 6.24419 4.34457 6.24419 5.53217V16.4676C6.24419 17.6552 7.20693 18.6179 8.39453 18.6179V17.6179C7.75922 17.6179 7.24419 17.1029 7.24419 16.4676V5.53217Z"
        fill="#3A517F"
      />
    </svg>
  );
};
