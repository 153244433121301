import { IconProps } from "./types";

export const OverLeftArrowIcon = (props: IconProps) => {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" {...props}>
      <path
        d="M14.864 8.68C15.024 8.76533 15.104 8.872 15.104 9C15.104 9.128 15.024 9.23467 14.864 9.32H2.64L2.688 9.368C3.78667 10.2107 4.50667 11.288 4.848 12.6C4.912 12.8347 4.944 12.968 4.944 13C4.944 13.1173 4.832 13.176 4.608 13.176C4.48 13.176 4.39467 13.16 4.352 13.128C4.32 13.1067 4.29333 13.0427 4.272 12.936C4.25067 12.8613 4.22933 12.7707 4.208 12.664C4.016 11.8853 3.648 11.192 3.104 10.584C2.57067 9.976 1.92533 9.52267 1.168 9.224C1.008 9.16 0.912 9.10133 0.88 9.048C0.88 8.90933 0.976 8.81867 1.168 8.776C1.92533 8.47733 2.57067 8.024 3.104 7.416C3.648 6.808 4.016 6.11467 4.208 5.336C4.21867 5.304 4.22933 5.256 4.24 5.192C4.25067 5.128 4.256 5.08533 4.256 5.064C4.26667 5.04267 4.27733 5.01067 4.288 4.968C4.30933 4.92533 4.33067 4.89867 4.352 4.888C4.37333 4.86667 4.40533 4.85067 4.448 4.84C4.49067 4.82933 4.544 4.824 4.608 4.824C4.832 4.824 4.944 4.88267 4.944 5C4.944 5.032 4.912 5.16533 4.848 5.4C4.528 6.66933 3.808 7.74667 2.688 8.632L2.64 8.68H14.864Z"
        fill="#3A517F"
      />
      <path
        d="M2 14.7949C2 14.5187 2.22386 14.2949 2.5 14.2949H13.5C13.7761 14.2949 14 14.5187 14 14.7949V25.7949C14 26.071 13.7761 26.2949 13.5 26.2949H2.5C2.22386 26.2949 2 26.071 2 25.7949V14.7949Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 15.2949V25.2949H13V15.2949H3ZM2.5 14.2949C2.22386 14.2949 2 14.5187 2 14.7949V25.7949C2 26.071 2.22386 26.2949 2.5 26.2949H13.5C13.7761 26.2949 14 26.071 14 25.7949V14.7949C14 14.5187 13.7761 14.2949 13.5 14.2949H2.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
