import {
    getNodeType, InputRule,
    inputRulesPlugin, mergeAttributes, Node, wrappingInputRule
} from "@tiptap/core";
import { isListItem, wrapSelectedItems } from "../list";

export interface BulletListOptions {
  HTMLAttributes: Record<string, unknown>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    bulletList: {
      /**
       * Toggle a bullet list
       */
      toggleBulletList: () => ReturnType;
    };
  }
}

export const inputRegex = /^\s*([-+*])\s$/;

export const BulletList = Node.create<BulletListOptions>({
  name: "bulletList",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block list",

  content: "listItem+",

  parseHTML() {
    return [{ tag: "ul" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "ul",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      toggleBulletList:
        () =>
        ({ commands }) => {
          return commands.toggleCadmusList(
            this.type,
            getNodeType("listItem", this.editor.schema)
          );
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-8": () => this.editor.commands.toggleBulletList(),
    };
  },

  addProseMirrorPlugins() {
    return [
      inputRulesPlugin({
        editor: this.editor,
        rules: [
          wrappingInputRule({
            find: inputRegex,
            type: this.type,
          }),
          new InputRule({
            find: inputRegex,
            handler: ({ state, range }) => {
              const { tr } = state;
              tr.deleteRange(range.from, range.to);
              const canUpdate = wrapSelectedItems({
                listType: this.type,
                itemType: getNodeType("listItem", this.editor.schema),
                tr,
                editor: this.editor,
              });

              if (!canUpdate) {
                const $cursor = tr.doc.resolve(range.from);
                if ($cursor.depth > 2) {
                  const maybeListItem = $cursor.node($cursor.depth - 1);
                  if (
                    !isListItem(
                      maybeListItem.type.name,
                      this.editor.extensionManager.extensions
                    )
                  ) {
                    return null;
                  }
                } else {
                  return null;
                }
              }
            },
          }),
        ],
      }),
    ];
  },
});
