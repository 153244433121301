import { IconProps } from "./types";

export const OverRightArrowIcon = (props: IconProps) => {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" {...props}>
      <path
        d="M1.12 9.32C0.970667 9.24533 0.896 9.13867 0.896 9C0.896 8.86133 0.970667 8.75467 1.12 8.68H13.36C12.6027 8.11467 12.016 7.432 11.6 6.632C11.3333 6.12 11.1573 5.61333 11.072 5.112C11.072 5.09067 11.0667 5.06933 11.056 5.048C11.056 5.02667 11.056 5.01067 11.056 5C11.056 4.88267 11.1627 4.824 11.376 4.824C11.504 4.824 11.584 4.84 11.616 4.872C11.6587 4.89333 11.6907 4.952 11.712 5.048C11.744 5.26133 11.8027 5.48 11.888 5.704C12.1227 6.41867 12.512 7.05867 13.056 7.624C13.6 8.17867 14.2347 8.57867 14.96 8.824C15.056 8.856 15.104 8.91467 15.104 9C15.104 9.08533 15.056 9.144 14.96 9.176C14.2347 9.42133 13.6 9.82667 13.056 10.392C12.512 10.9467 12.1227 11.5813 11.888 12.296C11.8133 12.52 11.7547 12.7333 11.712 12.936C11.6907 13.0427 11.6587 13.1067 11.616 13.128C11.584 13.16 11.504 13.176 11.376 13.176C11.152 13.176 11.04 13.1173 11.04 13C11.04 12.968 11.072 12.8347 11.136 12.6C11.4667 11.2667 12.208 10.1733 13.36 9.32H1.12Z"
        fill="#3A517F"
      />
      <path
        d="M2 14.7949C2 14.5187 2.22386 14.2949 2.5 14.2949H13.5C13.7761 14.2949 14 14.5187 14 14.7949V25.7949C14 26.071 13.7761 26.2949 13.5 26.2949H2.5C2.22386 26.2949 2 26.071 2 25.7949V14.7949Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 15.2949V25.2949H13V15.2949H3ZM2.5 14.2949C2.22386 14.2949 2 14.5187 2 14.7949V25.7949C2 26.071 2.22386 26.2949 2.5 26.2949H13.5C13.7761 26.2949 14 26.071 14 25.7949V14.7949C14 14.5187 13.7761 14.2949 13.5 14.2949H2.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
