import styled from "styled-components";

import AddCol from "./iconPaths/AddCol";
import AddGroup from "./iconPaths/AddGroup";
import AddRow from "./iconPaths/AddRow";
import AddTag from "./iconPaths/AddTag";
import Alert from "./iconPaths/Alert";
import ArchiveFile from "./iconPaths/ArchiveFile";
import Attach from "./iconPaths/Attach";
import AudioFile from "./iconPaths/AudioFile";
import Bold from "./iconPaths/Bold";
import Book from "./iconPaths/Book";
import BorderAll from "./iconPaths/BorderAll";
import BorderBottom from "./iconPaths/BorderBottom";
import BorderInside from "./iconPaths/BorderInside";
import BorderInsideHorizontal from "./iconPaths/BorderInsideHorizontal";
import BorderInsideVertical from "./iconPaths/BorderInsideVertical";
import BorderLeft from "./iconPaths/BorderLeft";
import BorderNone from "./iconPaths/BorderNone";
import BorderOutside from "./iconPaths/BorderOutside";
import BorderRight from "./iconPaths/BorderRight";
import BorderTop from "./iconPaths/BorderTop";
import BulletList from "./iconPaths/BulletList";
import Cadmus from "./iconPaths/Cadmus";
import Calendar from "./iconPaths/Calendar";
import CalendarCheck from "./iconPaths/CalendarCheck";
import Caption from "./iconPaths/Caption";
import CenterAlign from "./iconPaths/CenterAlign";
import Check from "./iconPaths/Check";
import CheckList from "./iconPaths/CheckList";
import Close from "./iconPaths/Close";
import CloseSide from "./iconPaths/CloseSide";
import Cloud from "./iconPaths/Cloud";
import CloudStrike from "./iconPaths/CloudStrike";
import CloudTick from "./iconPaths/CloudTick";
import Code from "./iconPaths/Code";
import CodeBlock from "./iconPaths/CodeBlock";
import CodeBlockLanguageCaption from "./iconPaths/CodeBlockLanguageCaption";
import CodeBlockLineNumbers from "./iconPaths/CodeBlockLineNumbers";
import Color from "./iconPaths/Color";
import Comment from "./iconPaths/Comment";
import Copy from "./iconPaths/Copy";
import DeleteCol from "./iconPaths/DeleteCol";
import DeleteRow from "./iconPaths/DeleteRow";
import DocumentFile from "./iconPaths/DocumentFile";
import Down from "./iconPaths/Down";
import DownArrowhead from "./iconPaths/DownArrowhead";
import DownCaret from "./iconPaths/DownCaret";
import Download from "./iconPaths/Download";
import Edit from "./iconPaths/Edit";
import Equation from "./iconPaths/Equation";
import EquationInline from "./iconPaths/EquationInline";
import Expand from "./iconPaths/Expand";
import Eye from "./iconPaths/Eye";
import File from "./iconPaths/File";
import Filter from "./iconPaths/Filter";
import FirstLine from "./iconPaths/FirstLine";
import Footer from "./iconPaths/Footer";
import Fullscreen from "./iconPaths/Fullscreen";
import Grade from "./iconPaths/Grade";
import Graph from "./iconPaths/Graph";
import Grow from "./iconPaths/Grow";
import H1 from "./iconPaths/H1";
import H2 from "./iconPaths/H2";
import H3 from "./iconPaths/H3";
import H4 from "./iconPaths/H4";
import Handle from "./iconPaths/Handle";
import HangingIndent from "./iconPaths/HangingIndent";
import Happy from "./iconPaths/Happy";
import HeaderColumn from "./iconPaths/HeaderColumn";
import HeaderRow from "./iconPaths/HeaderRow";
import Hide from "./iconPaths/Hide";
import Highlight from "./iconPaths/Highlight";
import Home from "./iconPaths/Home";
import Image from "./iconPaths/Image";
import ImageAlignCenter from "./iconPaths/ImageAlignCenter";
import ImageAlignLeft from "./iconPaths/ImageAlignLeft";
import ImageAlignRight from "./iconPaths/ImageAlignRight";
import ImageCover from "./iconPaths/ImageCover";
import ImageFile from "./iconPaths/ImageFile";
import ImageFit from "./iconPaths/ImageFit";
import ImageOriginal from "./iconPaths/ImageOriginal";
import Indent from "./iconPaths/Indent";
import Italic from "./iconPaths/Italic";
import Justify from "./iconPaths/Justify";
import Left from "./iconPaths/Left";
import LeftAlign from "./iconPaths/LeftAlign";
import LeftArrowhead from "./iconPaths/LeftArrowhead";
import LeftCaret from "./iconPaths/LeftCaret";
import Line from "./iconPaths/Line";
import Link from "./iconPaths/Link";
import Lock from "./iconPaths/Lock";
import Mail from "./iconPaths/Mail";
import MediaFile from "./iconPaths/MediaFile";
import MergeCells from "./iconPaths/MergeCells";
import Minus from "./iconPaths/Minus";
import More from "./iconPaths/More";
import Night from "./iconPaths/Night";
import Notification from "./iconPaths/Notification";
import NumberList from "./iconPaths/NumberList";
import Open from "./iconPaths/Open";
import Outdent from "./iconPaths/Outdent";
import Paint from "./iconPaths/Paint";
import PaintRoller from "./iconPaths/PaintRoller";
import Paragraph from "./iconPaths/Paragraph";
import PdfFile from "./iconPaths/PdfFile";
import Person from "./iconPaths/Person";
import Plus from "./iconPaths/Plus";
import PresentationFile from "./iconPaths/PresentationFile";
import Print from "./iconPaths/Print";
import Question from "./iconPaths/Question";
import QuoteClose from "./iconPaths/QuoteClose";
import QuoteOpen from "./iconPaths/QuoteOpen";
import Redo from "./iconPaths/Redo";
import Reset from "./iconPaths/Reset";
import Right from "./iconPaths/Right";
import RightAlign from "./iconPaths/RightAlign";
import RightArrowhead from "./iconPaths/RightArrowhead";
import RightCaret from "./iconPaths/RightCaret";
import Sad from "./iconPaths/Sad";
import Search from "./iconPaths/Search";
import Section from "./iconPaths/Section";
import Select from "./iconPaths/Select";
import Send from "./iconPaths/Send";
import Settings from "./iconPaths/Settings";
import Show from "./iconPaths/Show";
import Shrink from "./iconPaths/Shrink";
import Sidebar from "./iconPaths/Sidebar";
import Sort from "./iconPaths/Sort";
import SplitCells from "./iconPaths/SplitCells";
import SpreadsheetFile from "./iconPaths/SpreadsheetFile";
import Star from "./iconPaths/Star";
import StarOutline from "./iconPaths/StarOutline";
import Strikethrough from "./iconPaths/Strikethrough";
import Student from "./iconPaths/Student";
import Style from "./iconPaths/Style";
import Subscript from "./iconPaths/Subscript";
import SunDarkLight from "./iconPaths/SunDarkLight";
import SunLightDark from "./iconPaths/SunLightDark";
import Superscript from "./iconPaths/Superscript";
import Table from "./iconPaths/Table";
import Tag from "./iconPaths/Tag";
import Teacher from "./iconPaths/Teacher";
import Template from "./iconPaths/Template";
import TextFile from "./iconPaths/TextFile";
import ThumbsDown from "./iconPaths/ThumbsDown";
import ThumbsUp from "./iconPaths/ThumbsUp";
import Tick from "./iconPaths/Tick";
import Time from "./iconPaths/Time";
import Trash from "./iconPaths/Trash";
import Turnitin from "./iconPaths/Turnitin";
import Underline from "./iconPaths/Underline";
import Undo from "./iconPaths/Undo";
import UnknownFile from "./iconPaths/UnknownFile";
import Unset from "./iconPaths/Unset";
import Up from "./iconPaths/Up";
import UpArrowhead from "./iconPaths/UpArrowhead";
import UpCaret from "./iconPaths/UpCaret";
import Upload from "./iconPaths/Upload";
import Warning from "./iconPaths/Warning";
import WebpageFile from "./iconPaths/WebpageFile";
import Wifi from "./iconPaths/Wifi";
import WifiDisabled from "./iconPaths/WifiDisabled";

export const iconList = {
  AddCol,
  AddGroup,
  AddRow,
  AddTag,
  Alert,
  ArchiveFile,
  Attach,
  AudioFile,
  Bold,
  Book,
  BorderAll,
  BorderBottom,
  BorderInside,
  BorderInsideHorizontal,
  BorderInsideVertical,
  BorderLeft,
  BorderNone,
  BorderOutside,
  BorderRight,
  BorderTop,
  BulletList,
  Cadmus,
  Calendar,
  CalendarCheck,
  Caption,
  CenterAlign,
  Check,
  CheckList,
  Close,
  CloseSide,
  Cloud,
  CloudStrike,
  CloudTick,
  Code,
  CodeBlock,
  CodeBlockLanguageCaption,
  CodeBlockLineNumbers,
  Color,
  Comment,
  Copy,
  DeleteCol,
  DeleteRow,
  DocumentFile,
  Down,
  DownArrowhead,
  DownCaret,
  Download,
  Edit,
  Equation,
  EquationInline,
  Expand,
  Eye,
  File,
  Filter,
  FirstLine,
  Footer,
  Fullscreen,
  Grade,
  Graph,
  Grow,
  H1,
  H2,
  H3,
  H4,
  Handle,
  HangingIndent,
  Happy,
  HeaderColumn,
  HeaderRow,
  Hide,
  Highlight,
  Home,
  Image,
  ImageAlignCenter,
  ImageAlignLeft,
  ImageAlignRight,
  ImageCover,
  ImageFile,
  ImageFit,
  ImageOriginal,
  Indent,
  Italic,
  Justify,
  Left,
  LeftAlign,
  LeftArrowhead,
  LeftCaret,
  Line,
  Link,
  Lock,
  Mail,
  MediaFile,
  MergeCells,
  Minus,
  More,
  Night,
  Notification,
  NumberList,
  Open,
  Outdent,
  Paint,
  PaintRoller,
  Paragraph,
  PdfFile,
  Person,
  Plus,
  PresentationFile,
  Print,
  Question,
  QuoteClose,
  QuoteOpen,
  Redo,
  Reset,
  Right,
  RightAlign,
  RightArrowhead,
  RightCaret,
  Sad,
  Search,
  Section,
  Select,
  Send,
  Settings,
  Show,
  Shrink,
  Sidebar,
  Sort,
  SplitCells,
  SpreadsheetFile,
  Star,
  StarOutline,
  Strikethrough,
  Student,
  Style,
  Subscript,
  SunDarkLight,
  SunLightDark,
  Superscript,
  Table,
  Tag,
  Teacher,
  Template,
  TextFile,
  ThumbsDown,
  ThumbsUp,
  Tick,
  Time,
  Trash,
  Turnitin,
  Underline,
  Undo,
  UnknownFile,
  Unset,
  Up,
  UpArrowhead,
  UpCaret,
  Upload,
  Warning,
  WebpageFile,
  Wifi,
  WifiDisabled,
};

/**
 * @deprecated since version 5.3.0. Use `IconName`
 */
export type IconList = keyof typeof iconList;

/** A unique name for a Cadmus Icon */
export type IconName = keyof typeof iconList;

export interface Props extends React.HTMLAttributes<SVGSVGElement> {
  /**
   * The name of the Cadmus icon to render
   */
  iconName: IconName;
  /**
   * Provides a human-readable title for the element that contains it.
   * https://www.w3.org/TR/SVG-access/#Equivalent
   */
  titleAccess?: string;
}

/**
 * Renders a **Cadmus Icon**
 *
 * Cadmus icons are built with SVG, meaning they are infinitely scalable. They
 * can be used inline with text, and will scale to an inherited font size. They
 * can also be used independently (for example, in buttons).
 *
 */
export const Icon = (props: Props) => {
  const { iconName, titleAccess, ...other } = props;
  return (
    <SVG
      focusable="false"
      viewBox="0 0 16 21"
      aria-hidden={titleAccess ? "false" : "true"}
      width="1em"
      height="100%"
      {...other}
    >
      {titleAccess ? <title>{titleAccess}</title> : null}
      {iconList[iconName]}
    </SVG>
  );
};

const SVG = styled.svg`
  display: inline-flex;
  vertical-align: text-bottom;
`;
