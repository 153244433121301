import React, { forwardRef } from "react";
import styled from "styled-components";

import { Icon } from "../Icon";
import { colors, easeOutCirc } from "../styles";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * Active selection state.
   * @default false
   */
  selected?: boolean;
  /**
   * Button disabled state.
   * @default false
   */
  disabled?: boolean;
  /**
   * React children.
   */
  children?: React.ReactNode;
}

/**
 * A button designed to be used as a `Dropdown` **Target**.
 *
 * It is an **inline-block** element meaning it will **not** vertically stack.
 *
 * _props extends `button` attributes_
 */
export const SelectButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { children, disabled, selected, ...buttonProps } = props;
    return (
      <SelectButtonRoot
        selected={selected}
        disabled={disabled}
        {...buttonProps}
        ref={ref}
      >
        <SelectText>{children}</SelectText>
        <Icon iconName="DownCaret" />
      </SelectButtonRoot>
    );
  }
);

const SelectButtonRoot = styled.button<Props>`
  min-width: 180px;
  height: 36px;

  padding: 8px 12px;

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${colors.grey300};
  border-radius: 3px;
  box-sizing: border-box;

  background: transparent;
  color: ${(p) => (p.selected ? p.theme.text.accent : p.theme.text.main)};
  fill: ${(p) => (p.selected ? p.theme.text.accent : p.theme.text.shade1)};

  outline: 0;
  position: relative;

  cursor: pointer;

  transition: background 0.2s ${easeOutCirc};

  &:not(:disabled) {
    &:hover,
    &:focus {
      background: ${(p) => p.theme.background.action1};
    }

    &:active {
      background: ${(p) => p.theme.background.action2};
    }
  }

  &:disabled {
    opacity: 0.54;
    text-decoration: line-through;
  }
`;

const SelectText = styled.span`
  padding-right: 3px;

  /* Reset the base font styles to system */
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.3px;

  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
