import { DOMOutputSpec } from "@tiptap/pm/model";

import { HTMLToProsemirrorConverter } from "./types";

function namedNodeMapToRecord(attributes: NamedNodeMap) {
  const output: Record<string, string> = {};
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    output[attribute.name] = attribute.value;
  }
  return output;
}

const convert = (nodes: NodeList) => {
  const output: DOMOutputSpec[] = [];
  if (nodes.length === 0) {
    return output;
  }

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    node.normalize();

    if (node.nodeType === Node.TEXT_NODE && node.textContent) {
      output.push(node.textContent);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const attributes = (node as Element).attributes;
      output.push([
        node.nodeName.toLowerCase(),
        namedNodeMapToRecord(attributes),
        ...convert(node.childNodes),
      ]);
    }
  }
  return output;
};

export const domParserConverter: HTMLToProsemirrorConverter = (html) => {
  const domParser = new DOMParser();
  return convert(
    domParser.parseFromString(html, "text/html").querySelectorAll("body")[0]
      .childNodes
  );
};
