import { Extension } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    floatingMenu: {
      /**
       * Dismiss equation & hyperlink floating menu
       */
      dismissMenu: () => ReturnType;
    };
  }
}
/**
 * Tiptap extension that exposes command to dismiss floating menus
 * like equation & hyperlink.
 */
export const FloatingMenuExtension = Extension.create({
  name: "floatingMenu",
  addCommands() {
    return {
      dismissMenu:
        () =>
        ({ chain }) =>
          chain().setEquationMenuTarget().setHyperlinkMenuTarget().run(),
    };
  },
});
