import { Editor, isList } from "@tiptap/core";
import { Fragment, Node as PMNode } from "@tiptap/pm/model";
import { TextSelection, Transaction } from "@tiptap/pm/state";
import {
  calculateItemRange,
  findPreviousItem,
  sliceSelectedItems,
} from "../helpers";

/**
 * A helper function to indent selected list items.
 *
 * @beta
 * ported from https://github.com/remirror/remirror/blob/9014d98411459968cf5aaac8ec9cf01e8bcd1c85/packages/remirror__extension-list/src/list-command-indent.ts
 */
export function indentList(editor: Editor, tr: Transaction): boolean {
  const { selection, doc } = tr;
  const { $from, $to } = selection;
  const range = calculateItemRange(editor, selection);

  if (!range) return false;

  const selectedList: PMNode = doc.resolve(range.start).node();

  if (!isList(selectedList.type.name, editor.extensionManager.extensions))
    return false;

  const findPreviousItemResult = findPreviousItem(
    editor,
    selectedList,
    $from,
    range
  );

  if (!findPreviousItemResult) return false;

  const { previousItem, previousList, previousItemStart } =
    findPreviousItemResult;

  const { selectedSlice, unselectedSlice } = sliceSelectedItems(
    doc,
    $to,
    range
  );

  const newPreviousItemContent: Fragment = previousItem.content
    .append(Fragment.fromArray([selectedList.copy(selectedSlice.content)]))
    .append(unselectedSlice ? unselectedSlice.content : Fragment.empty);

  tr.deleteRange(range.start, range.end);

  const previousItemEnd = previousItemStart + previousItem.nodeSize - 2; // Note: nodeSize = end - start + 2
  const newPreviousItem = previousItem.copy(newPreviousItemContent);

  newPreviousItem.check();

  tr.replaceRangeWith(
    previousItemStart - 1,
    previousItemEnd + 1,
    newPreviousItem
  );

  tr.setSelection(
    previousList === selectedList
      ? TextSelection.create(tr.doc, $from.pos, $to.pos)
      : TextSelection.create(tr.doc, $from.pos - 2, $to.pos - 2)
  );

  return true;
}
