import { Fragment, Node as ProsemirrorNode, Schema } from "@tiptap/pm/model";

import { createCell } from "./createCell";
import { getTableNodeTypes } from "./getTableNodeTypes";

/**
 * Create table node with the tableGroup wrapper with caption and footer
 */
export function createTable(
  schema: Schema,
  rowsCount: number,
  colsCount: number,
  withHeaderRow: boolean,
  cellContent?: Fragment | ProsemirrorNode | Array<ProsemirrorNode>
): ProsemirrorNode {
  const types = getTableNodeTypes(schema);
  const headerCells = [];
  const cells = [];
  const tableGroups = [];

  for (let index = 0; index < colsCount; index += 1) {
    const cell = createCell(
      types.cell,
      { colwidth: [710 / colsCount] },
      cellContent
    );

    if (cell) {
      cells.push(cell);
    }

    if (withHeaderRow) {
      const headerCell = createCell(
        types.header_cell,
        { colwidth: [710 / colsCount] },
        cellContent
      );

      if (headerCell) {
        headerCells.push(headerCell);
      }
    }
  }

  const rows = [];

  for (let index = 0; index < rowsCount; index += 1) {
    rows.push(
      types.row.createChecked(
        null,
        withHeaderRow && index === 0 ? headerCells : cells
      )
    );
  }

  const table = types.table.createChecked(null, rows);
  const caption = types.table_caption.createAndFill();
  const tableFootnote = types.table_footnote.createAndFill({
    hideText: true,
  });
  if (caption) {
    tableGroups.push(caption);
  }
  if (table) {
    tableGroups.push(table);
  }
  if (tableFootnote) {
    tableGroups.push(tableFootnote);
  }
  return types.table_group.createChecked(null, tableGroups);
}
