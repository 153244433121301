import "core-js/features/array/flat";
import "core-js/features/string/match-all";
import "mathlive/dist/mathlive-static.css";

import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import NiceModal from "@ebay/nice-modal-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  CssBaseline,
  colors,
  createCuiTheme,
  defaultTheme,
} from "@vericus/cadmus-ui";
import { ThemeProvider } from "styled-components";

import { LoadingPage } from "@/components/loading-page";
import apolloClient from "client/apollo";
import reduxStore from "data/store";
import { CrashError } from "ui/errors";

import { DEPLOY_ENV, DEV, RELEASE_HASH } from "./config";
import { routes } from "./routes";

if (!DEV) {
  Sentry.init({
    dsn: "https://e70e3efc596d45ee9bb542412703621f@o67497.ingest.sentry.io/242192",
    environment: DEPLOY_ENV,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllInputs: true,
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.8,
    release: RELEASE_HASH,
  });
}

// Theme from CUI
const theme = createCuiTheme({
  primaryColor: colors.indigo500,
  text: {
    ...defaultTheme.text,
    accent: colors.indigo500,
  },
  gradient: {
    start: "#6565FF",
    end: "#9660fc",
  },
});

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Sentry.ErrorBoundary fallback={<CrashError />}>
        <NiceModal.Provider>
          <Provider store={reduxStore}>
            <ApolloProvider client={apolloClient}>
              <>
                <CssBaseline />
                <BrowserRouter>
                  <Suspense fallback={<LoadingPage />}>{routes}</Suspense>
                </BrowserRouter>
              </>
            </ApolloProvider>
          </Provider>
        </NiceModal.Provider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  </StrictMode>
);
