import { useMemo } from "react";

import { API_ENDPOINT } from "@/config";
import { __GLOBAL_TENANT } from "client/globals";
import { ResultFragment } from "generated/graphql";

const HERA_API_URL = `${API_ENDPOINT}/api/hera`;

type PartialResult = Pick<
  ResultFragment,
  "id" | "heraResultId" | "canvasResultUrl"
>;

/**
 * Create a callback to launch into the give grading service via Pantheon.
 */
export function useResultLaunch(
  result: PartialResult,
  gradingService?: string
): VoidFunction | undefined {
  const isReady =
    gradingService === "speedgrader"
      ? result.canvasResultUrl !== null
      : result.heraResultId !== null;
  const endpoint = gradingService === "speedgrader" ? "speedgrader" : "result";
  const resultId = result.id;

  return useMemo(() => {
    if (isReady) {
      const url = createHeraURL(resultId, endpoint);
      return () => {
        const win = window.open(url.href, "_blank");
        if (win) {
          win.focus();
        }
      };
    }
    return undefined;
  }, [resultId, isReady, endpoint]);
}

function createHeraURL(resultId: string, endpoint: string) {
  const url = new URL(`${HERA_API_URL}/${endpoint}`);
  url.searchParams.append("role", "student");
  url.searchParams.append("result_id", resultId);
  if (__GLOBAL_TENANT.current) {
    url.searchParams.append("tenant", __GLOBAL_TENANT.current);
  }
  return url;
}
