import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import XPIllustration from "assets/submission/submission-experience.png";

import { FlexImg, FlexText, Padding, XPFlex } from "./styles";

interface Props {
  /** Survey redirect URL */
  href: string;
  /** Callback to dismiss interactions */
  onDismiss: () => void;
}

/**
 * Link to further survey. Students are redirected to a new tab.
 */
export const Survey = (props: Props) => (
  <Padding>
    <XPFlex>
      <FlexText>
        <Text kind="headingThree">
          How was your experience using Cadmus in this assignment?
        </Text>
        <Text kind="system">
          Thanks for the feedback! If you have a spare couple of minutes, we'd
          love to ask some follow up questions. Your answers help determine what
          features we'll build in the future.
        </Text>
        <Spacer spacing={27} />
        <Anchored href={props.href} newTab>
          <TakeSurveyButton
            marginRight={18}
            kind="dark"
            onClick={props.onDismiss}
          >
            Take 2 min survey
          </TakeSurveyButton>
        </Anchored>
        <Button onClick={props.onDismiss}>No thanks</Button>
      </FlexText>
      <FlexImg
        src={XPIllustration}
        alt="An illustration of two people discussing something.
        It's unclear what they are talking about, but one person
        seems to like the other person's idea."
      />
    </XPFlex>
  </Padding>
);

const TakeSurveyButton = styled(Button)`
  margin-bottom: 18px;
`;
