import styled from "styled-components";

import { colors } from "./styles";
import { ColorVariant, FocusAnimation, SizeVariant } from "./styles";
import { Text } from "./Text";

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Text label to display besides the Radio.
   * Has a higher priority than `children`.
   */
  content?: string;

  /**
   * Render any react node inside the Label wrapper.
   * Has a lower priority than `content`.
   */
  children?: React.ReactNode;

  /** Passes className to Label container */
  className?: string;

  /**
   * Configures the background color for the radio button.
   * @default "black"
   **/
  colorVariant?: Extract<ColorVariant, "black" | "blue">;

  /**
   * The size of the radio button.
   * @default "md"
   */
  sizeVariant?: Extract<SizeVariant, "md" | "lg">;
}

/**
 * A control to allow a user to select only one option from a limited number
 * of choices
 *
 * To add a simple text string to a radio use the `content` prop.
 * For more complex components use the `children` prop.
 *
 * _props extends `input` attributes_
 */
export const Radio: React.FC<RadioProps> = ({
  content,
  className,
  children,
  sizeVariant = "md",
  colorVariant = "black",
  ...inputProps
}) => {
  return (
    <Label className={className}>
      <HiddenInput
        type="radio"
        sizeVariant={sizeVariant}
        colorVariant={colorVariant}
        {...inputProps}
      />
      <Box sizeVariant={sizeVariant} />
      {content ? (
        <Text kind="system" as="span">
          {content}
        </Text>
      ) : (
        children
      )}
    </Label>
  );
};

/* The label wraps the entire radio. */
const Label = styled.label<Partial<RadioProps>>`
  margin-right: 18px;
  display: inline-flex;
  align-items: center;

  outline: 0;

  position: relative;
`;

/* The styled radio */
const Box = styled.span<Required<Pick<RadioProps, "sizeVariant">>>`
  flex: none;
  display: inline-block;
  width: ${(p) => (p.sizeVariant === "lg" ? 24 : 18)}px;
  height: ${(p) => (p.sizeVariant === "lg" ? 24 : 18)}px;
  margin: 6px 9px 6px 0;

  position: relative;

  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.text.shade2};
  background: white;
  border-radius: 50%;

  cursor: pointer;

  /* The :before pseudo-element creates a focus and ping outline */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

const mediumCheckedInnerCircle = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='4' fill='%23FFFFFF'/%3E%3C/svg%3E");`;
const largeCheckedInnerCircle = `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle cx='11' cy='11' r='5' fill='%23FFFFFF'/%3E%3C/svg%3E");`;

/* The native radio, which is hidden */
const HiddenInput = styled.input<
  Required<Pick<RadioProps, "sizeVariant" | "colorVariant">>
>`
  position: absolute;
  z-index: -1;
  opacity: 0;

  width: ${(p) => (p.sizeVariant === "lg" ? 24 : 18)}px; /* Same as Box */
  height: ${(p) => (p.sizeVariant === "lg" ? 24 : 18)}px; /* Same as Box */

  cursor: pointer;

  /* Apply hover styles to Box when unchecked */
  &:not(:checked) ~ ${Box}:hover {
    background: ${(p) => p.theme.background.action1};
  }

  /* Apply active styles to Box when unchecked */
  &:not(:checked) ~ ${Box}:active {
    background: ${(p) => p.theme.background.action2};
  }

  &:not(disabled) {
    /* Apply focued styles to Box */
    &:focus ~ ${Box} {
      border-color: ${(p) => p.theme.text.shade2};
    }

    /* Apply focued styles to Box's focus pseudo-element */
    &:focus ~ ${Box}:before {
      ${FocusAnimation};
    }

    /* Apply checked styles to Box */
    &:checked ~ ${Box} {
      background: ${(p) =>
        p.colorVariant === "blue" ? colors.blue500 : colors.black200};
      background-image: ${(p) =>
        p.sizeVariant === "lg"
          ? largeCheckedInnerCircle
          : mediumCheckedInnerCircle};
    }
  }

  /* Apply disabled styles to Box and Text */
  &:disabled ~ ${Box}, &:disabled ~ ${Text} {
    opacity: 0.36;
  }

  &:disabled ~ ${Box} {
    cursor: not-allowed;
  }
`;
