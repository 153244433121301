import {
  InputRule,
  Mark,
  getMarkAttributes,
  mergeAttributes,
} from "@tiptap/core";

import { BASE_URL_REGEX, buildRegex, isValidURL } from "../../core/text";
import { NodePasteRule } from "../../paste-rules";
import { openLink } from "./helpers";
import {
  HyperlinkMenuAction,
  HyperlinkMenuPlugin,
  HyperlinkMenuPluginKey,
  HyperlinkMenuTarget,
} from "./hyperlink-menu-plugin";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    hyperlink: {
      /**
       * Set a link mark
       */
      setLink: (href: string) => ReturnType;
      /**
       * Unset a link mark
       */
      unsetLink: () => ReturnType;
      /**
       * Toggle a link mark on a selection via a bubble menu
       */
      toggleLink: () => ReturnType;
      /**
       * Update `href` attribute of a `link` mark inside range and possibly
       * delete an invalid `link` mark when the `href` is invalid.
       *
       * Similar to `toggleLink` but does not require a menu for `href` insertion.
       */
      updateLink: (href: string) => ReturnType;
      setHyperlinkMenuTarget: (target?: HyperlinkMenuTarget) => ReturnType;
    };
  }
}

/** Extension options for `Hyperlink` */
export interface HyperLinkOptions {
  /**
   * A list of HTML attributes to be rendered.
   */
  HTMLAttributes: Record<string, any>;
  /**
   * Callback to open a link with the `href` value.
   */
  onOpenLink: (href: string) => void;
}

/**
 * Cadmus Hyperlink extension for `link` marks and their management.
 *
 * The `link` marks are rendered as HTML `a` tags with `href`.
 *
 * New hyperlinks can be created on a text selection using the `toggleLink`
 * command. When creating new links a bubble menu with input and action buttons
 * would open on the selection range. If a valid URL is entered and confirmed,
 * the range becomes a link mark.
 *
 * The extension also adds extra input rules and paste rules in the form of:
 *
 *   - Markdown style link input & paste rule.
 *   - URL input and paste rule
 *   - Keyboard shortcut to mark text as link. e.g. `CMD-k`
 */
export const Hyperlink = Mark.create<HyperLinkOptions>({
  name: "link",

  priority: 1000,

  inclusive: false,

  // Hyperlinks should not be combined with other inline marks
  excludes: "_",

  addOptions() {
    return {
      HTMLAttributes: {
        target: "_blank",
        rel: "noopener noreferrer nofollow",
        ["data-cadmus-link"]: "true",
      },
      // Callback to open a link with `href` value. Defaults to opening the href
      // in a new tab.
      onOpenLink: openLink,
    };
  },

  addAttributes() {
    return {
      href: {
        default: null,
      },
      target: {
        default: this.options.HTMLAttributes.target,
      },
    };
  },

  parseHTML() {
    return [{ tag: "a[href]:not([href *= 'javascript:' i])" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setLink:
        (href) =>
        ({ commands }) => {
          return commands.setMark(this.name, { href });
        },

      unsetLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name, { extendEmptyMarkRange: true });
        },

      // Toggle a link mark on a text selection. Links are inserted via the
      // bubble menu if they don't exist on the current selection range.
      toggleLink:
        () =>
        ({ commands, state }) => {
          const { selection, doc } = state;
          const { from, to } = selection;
          const text = doc.textBetween(from, to);
          const href = isValidURL(text) ? text : "";
          const attrs = getMarkAttributes(state, this.name);

          // No existing mark, trigger a menu on the range
          if (attrs.href === undefined) {
            if (selection.empty) {
              return false;
            }

            // Trigger the menu on the
            return commands.setHyperlinkMenuTarget({ href, from, to });
          }

          // Otherwise update the existing mark
          return commands.toggleMark(
            this.name,
            { href },
            {
              extendEmptyMarkRange: true,
            }
          );
        },

      // Update existing link mark, removing it if the updated URL is invalid.
      updateLink:
        (href: string) =>
        ({ state, dispatch, commands, chain }) => {
          if (dispatch) {
            const attrs = getMarkAttributes(state, this.name);
            const isValid = isValidURL(href);

            // Existing mark should be updated or removed if the link is invalid
            if (attrs.href !== undefined) {
              if (isValid) {
                return chain()
                  .extendMarkRange(this.name, attrs)
                  .updateAttributes(this.name, { href })
                  .run();
              } else {
                return chain()
                  .extendMarkRange(this.name, attrs)
                  .unsetLink()
                  .run();
              }
            }

            // Or insert a new mark if the link is valid
            if (isValid) {
              return commands.setLink(href);
            }
          }

          return true;
        },
      setHyperlinkMenuTarget:
        (target) =>
        ({ tr, dispatch }) => {
          const payload: HyperlinkMenuAction = target
            ? { type: "triggerMenu", target }
            : { type: "dismissMenu" };
          dispatch?.(tr.setMeta(HyperlinkMenuPluginKey, payload));
          return true;
        },
    };
  },

  addInputRules() {
    return [
      // Input rule for markdown style link
      new InputRule({
        find: buildRegex(BASE_URL_REGEX, "\\[(.*)\\]\\((", ")\\)$", "ui"),
        handler: ({ state, match, range }) => {
          const { from, to } = range;
          const { tr } = state;
          const text = match[1];
          const href = /^https?/.test(match[2])
            ? match[2]
            : `https://${match[2]}`;
          const mark = this.type.create({
            href,
          });
          tr.replaceWith(from, to, this.editor.schema.text(text, [mark]));
        },
      }),
      // Input rule for text link
      new InputRule({
        find: buildRegex(BASE_URL_REGEX, "", "$", "ui"),
        handler: ({ state, match, range }) => {
          const { tr } = state;
          const { from, to } = range;
          const href = /^https?/.test(match[0])
            ? match[0]
            : `https://${match[0]}`;
          const mark = this.type.create({
            href,
          });
          tr.replaceWith(from, to, this.editor.schema.text(match[0], [mark]));
        },
      }),
    ];
  },

  addProseMirrorPlugins() {
    return [
      // Paste rule for markdown style link
      NodePasteRule(
        buildRegex(BASE_URL_REGEX, "\\[(.*)\\]\\((", ")\\)", "gui"),
        (_node, match) => {
          const text = match[1];
          const href = /^https?/.test(match[2])
            ? match[2]
            : `https://${match[2]}`;
          const mark = this.type.create({
            href,
          });
          return this.editor.schema.text(text, [mark]);
        }
      ),
      // Paste rule for text link
      NodePasteRule(
        buildRegex(BASE_URL_REGEX, "", "", "gui"),
        (_node, match) => {
          const href = /^https?/.test(match[0])
            ? match[0]
            : `https://${match[0]}`;
          const mark = this.type.create({
            href,
          });
          return this.editor.schema.text(match[0], [mark]);
        }
      ),
      HyperlinkMenuPlugin({
        onOpenLink: this.options.onOpenLink,
        editor: this.editor,
      }),
    ];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-k": () => this.editor.commands.toggleLink(),
    };
  },
});
