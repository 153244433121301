import React, {
  Children,
  Dispatch,
  ReactNode,
  SetStateAction,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactIs from "react-is";
import {
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  Placement,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListNavigation,
  useRole,
} from "@floating-ui/react-dom-interactions";

import { ControlButton } from "../ControlButton";
import { mergeRefs } from "../utils/mergeRefs";
import { Menu } from "./Menu";

interface Props {
  /** <MenuItem /> and <Divider /> components only, children are passed to MenuBody component  */
  children: React.ReactNode;

  /** React button element that will be used as menu control */
  control?: React.ReactElement;

  /** Optionally do not close menu on item click */
  preventCloseOnClick?: boolean;

  /**
   * Width in pixels applied to the menu.
   * @default 200
   */
  width?: number;

  /**
   * Set main axis offset in pixels
   * @default 6(px)
   */
  mainAxisOffset?: number;

  /**
   * Manually control the nested behaviour of the menues/
   */
  nested?: boolean;

  /**
   * Optional footer rendered outside the interactable children.
   */
  footer?: React.ReactNode;

  /**
   * Temporarily stop dismiss interactions if required.
   */
  preventDismiss?: boolean;

  /** Menu button aria-label and title props */
  menuButtonLabel?: string;

  /** ARIA ID for aria-labelledby attribute on menu control. */
  labelId?: string;

  /** ARIA ID for aria-describedby attribute on menu control. */
  descriptionId?: string;

  /**
   * Default initial placement.
   * @default "bottom-start"
   */
  placement?: Placement;

  /**
   * Controlled open state
   */
  open?: boolean;

  /**
   * Controlled dropdown onChange handler
   */
  onChange?: Dispatch<SetStateAction<boolean>>;
}

/**
 * Nestable dropdown menu with keyboard interactions and more.
 */
export const DropdownMenu = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const parentId = useFloatingParentNodeId();

    if (parentId == null) {
      return (
        <FloatingTree>
          <DropdownMenuComponent {...props} ref={ref} />
        </FloatingTree>
      );
    }

    return <DropdownMenuComponent {...props} ref={ref} />;
  }
);

const DropdownMenuComponent = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      control,
      children,
      preventCloseOnClick,
      footer,
      width,
      preventDismiss,
      menuButtonLabel,
      labelId,
      descriptionId,
      placement,
      mainAxisOffset = 6,
    } = props;

    const [openInternal, setOpenInternal] = useState(false);
    const open = props.open ?? openInternal;
    const setOpen = props.onChange ?? setOpenInternal;
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [allowHover, setAllowHover] = useState(false);

    const listItemsRef = useRef<Array<HTMLButtonElement | null>>([]);

    const tree = useFloatingTree();
    const nodeId = useFloatingNodeId();
    const parentId = useFloatingParentNodeId();
    const nested = props.nested ?? parentId != null;

    const { x, y, reference, floating, strategy, context, refs } =
      useFloating<HTMLButtonElement>({
        open,
        onOpenChange: setOpen,
        middleware: [
          offset({
            mainAxis: nested ? 4 : mainAxisOffset,
            alignmentAxis: nested ? -16 : 0,
          }),
          flip(),
          shift(),
        ],
        placement: placement
          ? placement
          : nested
          ? "right-start"
          : "bottom-start",
        nodeId,
        whileElementsMounted: autoUpdate,
      });

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([
        useHover(context, {
          handleClose: safePolygon({ restMs: 25 }),
          enabled: nested && allowHover,
          delay: { open: 75 },
        }),
        useClick(context, {
          toggle: !nested,
          pointerDown: true,
          ignoreMouse: nested,
        }),
        useRole(context, { role: "menu" }),
        useDismiss(context, {
          enabled: !preventDismiss,
        }),
        useListNavigation(context, {
          listRef: listItemsRef,
          activeIndex,
          nested,
          onNavigate: setActiveIndex,
        }),
      ]);

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.

    useEffect(() => {
      function onTreeClick() {
        setOpen(false);

        if (parentId === null) {
          refs.reference.current?.focus();
        }
      }

      tree?.events.on("click", onTreeClick);
      return () => {
        tree?.events.off("click", onTreeClick);
      };
    }, [parentId, refs.reference, setOpen, tree?.events]);

    // Determine if "hover" logic can run based on the modality of input. This
    // prevents unwanted focus synchronization as menus open and close with
    // keyboard navigation and the cursor is resting on the menu.
    useEffect(() => {
      function onPointerMove() {
        setAllowHover(true);
      }

      function onKeyDown() {
        setAllowHover(false);
      }

      window.addEventListener("pointermove", onPointerMove, {
        once: true,
        capture: true,
      });
      window.addEventListener("keydown", onKeyDown, true);
      return () => {
        window.removeEventListener("pointermove", onPointerMove, {
          capture: true,
        });
        window.removeEventListener("keydown", onKeyDown, true);
      };
    }, [allowHover]);

    // Menu control button element
    const mergedReferenceRef = useMemo(
      () => mergeRefs([ref, reference]),
      [reference, ref]
    );

    const controlProps = getReferenceProps({
      ref: mergedReferenceRef,
      onClick(event) {
        event.stopPropagation();
        (event.currentTarget as HTMLButtonElement).focus();
      },
      ...(nested
        ? {
            role: "menuitem",
            active: true,
            onKeyDown(event) {
              // Prevent more than one menu from being open.
              if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                setOpen(false);
              }
            },
          }
        : {}),
    });
    const menuControl = control ? (
      cloneElement(control, controlProps)
    ) : (
      <ControlButton {...controlProps} iconName="More" />
    );

    return (
      <FloatingNode id={nodeId}>
        {menuControl}
        <FloatingPortal>
          {open && (
            <FloatingFocusManager
              context={context}
              preventTabbing
              modal={!nested}
              // Touch-based screen readers will be able to navigate back to the
              // reference and click it to dismiss the menu without clicking an item.
              // This acts as a touch-based `Esc` key. A visually-hidden dismiss button
              // is an alternative.
              order={["reference", "content"]}
            >
              <Menu
                {...getFloatingProps({
                  ref: floating,
                  width,
                  style: {
                    position: strategy,
                    top: y ?? "",
                    left: x ?? "",
                    // Prevent focus events from firing on a nested submenu before
                    // it's been positioned
                    visibility: allowHover && x == null ? "hidden" : undefined,
                  },
                  role: "button",
                  "aria-haspopup": "menu",
                  title: menuButtonLabel,
                  "aria-label": menuButtonLabel,
                  "aria-labelledby": labelId,
                  "aria-describedby": descriptionId,
                })}
              >
                {Children.toArray(children)
                  // Unpack the fragment, if it is a React.Fragment
                  .flatMap((child) =>
                    ReactIs.isFragment(child) ? child.props.children : [child]
                  )
                  .map((child: ReactNode, index) => {
                    return (
                      isValidElement(child) &&
                      cloneElement(
                        child,
                        getItemProps({
                          key: index,
                          role: "menuitem",
                          ref(node: HTMLButtonElement) {
                            listItemsRef.current[index] = node;
                          },
                          onClick: (event) => {
                            if (!preventCloseOnClick) {
                              tree?.events.emit("click");
                            }
                            child.props?.onClick?.(event);
                          },
                          // By default `focusItemOnHover` uses `mousemove` to sync focus,
                          // but when a menu closes we want this to sync it on `enter`
                          // even if the cursor didn't move. NB: Safari does not sync in
                          // this case.
                          onPointerEnter() {
                            if (allowHover) {
                              setActiveIndex(index);
                            }
                          },
                        })
                      )
                    );
                  })}
                {footer}
              </Menu>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </FloatingNode>
    );
  }
);
