import { Colored, Text } from "@vericus/cadmus-ui";

import { formatDateSimple, hrAndMinDuration } from "@/utils/datetime";

import finalImg from "../../assets/submission-final.png";
import { Illustration } from "./styles";

interface Props {
  /** User email if available */
  email: string | null;

  /** Submission time */
  submittedAt: Date;

  /** Submission where submission time is after due date */
  late: boolean;

  /** Number of minutes for a time limited assessment or exam. */
  duration: number | null;

  /** Submission where duration > time limit */
  overtime: boolean;
}

export function FinalConfirmation(props: Props) {
  const { submittedAt, late, email, duration, overtime } = props;
  const submissionText = formatDateSimple(submittedAt);

  const durationText =
    duration !== null ? (
      <>
        . You spent a total of{" "}
        <strong>
          {hrAndMinDuration(duration, { hr: "hr", min: "min" }, " ")}
          {overtime ? " (overtime)" : ""}
        </strong>
      </>
    ) : (
      ""
    );

  return (
    <>
      <Illustration
        src={finalImg}
        alt="An illustration of a boy dunking a basketball."
      />
      <Text kind="displayTwo" textAlign="center">
        You successfully <br /> submitted a final
      </Text>
      <Text kind="paragraph" textAlign="center">
        You submitted a final on{" "}
        <strong>
          {submissionText}
          {late ? " (late)" : ""}
        </strong>
        {durationText}. We emailed confirmation of your submission to:{" "}
        <Colored color="shade1">
          <em>{email}</em>
        </Colored>
      </Text>
    </>
  );
}
