import styled from "styled-components";

import { levels } from "./styles";

/**
 * A simple container with preset shadow styles.
 */
export const Card = styled.div`
  display: inline-block;
  background: white;
  min-width: 200px;
  padding: 8px 0px;
  margin: 0;

  /* Reset the base font styles to system */
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  letter-spacing: 0.3px;
  text-indent: 0px;

  overflow-y: auto;
  max-height: 360px;
  border-radius: 2px;
  background-color: ${(p) => p.theme.background.default};
  box-sizing: border-box;
  box-shadow: ${levels.two};
`;
