export default (
  <>
    <path
      d="M6.6045 16.0403C6.37106 16.0403 6.19598 15.9694 6.07926 15.8277C5.96254 15.686 5.84582 15.3441 5.7291 14.8022L5.47898 13.6392L4.59107 14.8898C4.33262 15.2566 4.09084 15.5359 3.86573 15.7277C3.64897 15.9111 3.40302 16.0028 3.12789 16.0028C2.96115 16.0028 2.82775 15.9569 2.72771 15.8652C2.62766 15.7652 2.57764 15.6318 2.57764 15.465C2.57764 15.315 2.62766 15.1899 2.72771 15.0899C2.81942 14.9898 2.93614 14.9398 3.07787 14.9398C3.19459 14.9398 3.2988 14.969 3.39051 15.0273C3.4989 15.094 3.60311 15.1274 3.70316 15.1274C3.8699 15.1274 4.06166 14.9815 4.27842 14.6897L5.39144 13.139L5.01627 11.3256C4.95791 11.0588 4.87037 10.8671 4.75364 10.7503C4.64526 10.6336 4.47852 10.5753 4.25341 10.5753C4.14503 10.5753 4.04081 10.5794 3.94077 10.5878C3.84906 10.5961 3.76152 10.6086 3.67815 10.6253L3.71566 10.3877C4.20756 10.3293 4.60358 10.2459 4.90371 10.1376C5.04545 10.0875 5.16634 10.0542 5.26638 10.0375C5.37477 10.0125 5.45814 10 5.5165 10C5.66657 10 5.76661 10.1209 5.81664 10.3627L6.1668 12.051C6.51696 11.5424 6.8046 11.1422 7.0297 10.8504C7.2548 10.5503 7.41321 10.3585 7.50492 10.2751C7.59663 10.1834 7.69668 10.1209 7.80506 10.0875C7.92178 10.0459 8.04267 10.025 8.16773 10.025C8.55958 10.025 8.7555 10.1918 8.7555 10.5252C8.7555 10.6753 8.70964 10.7962 8.61794 10.8879C8.52623 10.9796 8.40951 11.0255 8.26777 11.0255C8.14271 11.0255 8.03016 10.9963 7.93012 10.9379C7.87176 10.9046 7.82173 10.8837 7.78005 10.8754C7.7467 10.8671 7.70918 10.8629 7.6675 10.8629C7.58412 10.8629 7.49241 10.9046 7.39237 10.988C7.31733 11.0547 6.93799 11.5674 6.25434 12.5262L6.62951 14.2645C6.6712 14.4229 6.70872 14.5646 6.74207 14.6897C6.77541 14.8064 6.80876 14.9064 6.84211 14.9898C6.91715 15.1566 7.02553 15.2399 7.16726 15.2399C7.4007 15.2399 7.73419 14.9231 8.16773 14.2895L8.34281 14.4896C7.65916 15.5234 7.07972 16.0403 6.6045 16.0403Z"
      stroke="#5B6F98"
      strokeWidth="0.6"
    />
    <path
      d="M11.9465 16.8062H10.9867C10.8542 16.8062 10.7467 16.6992 10.7467 16.5672C10.7467 16.4354 10.8542 16.3284 10.9867 16.3284H11.9465C12.079 16.3284 12.1865 16.2213 12.1865 16.0894V6.05443C12.1864 5.92251 12.0789 5.81548 11.9465 5.81548H10.9867C10.8542 5.81548 10.7467 5.70844 10.7467 5.5766C10.7467 5.44468 10.8542 5.33765 10.9867 5.33765H11.9465C12.3434 5.33765 12.6664 5.65924 12.6664 6.05443V16.0894C12.6664 16.4846 12.3434 16.8062 11.9465 16.8062Z"
      stroke="#5B6F98"
      strokeWidth="0.65"
    />
    <path
      d="M13.8213 16.8062H12.8614C12.4645 16.8062 12.1415 16.4846 12.1415 16.0894V6.05443C12.1415 5.65924 12.4645 5.33765 12.8614 5.33765H13.8213C13.9537 5.33765 14.0612 5.44468 14.0612 5.5766C14.0612 5.70844 13.9537 5.81548 13.8213 5.81548H12.8614C12.7289 5.81548 12.6214 5.92251 12.6214 6.05443V16.0894C12.6214 16.2213 12.7289 16.3284 12.8614 16.3284H13.8213C13.9537 16.3284 14.0612 16.4354 14.0612 16.5673C14.0612 16.6992 13.9537 16.8062 13.8213 16.8062Z"
      stroke="#5B6F98"
      strokeWidth="0.65"
    />
    <path
      d="M11.6551 11.072H13.1551"
      stroke="#5B6F98"
      strokeWidth="0.75"
      strokeLinecap="round"
    />
  </>
);
