import { IconProps } from "./types";

export const NiceFractionIcon = (props: IconProps) => {
  return (
    <svg width="22" height="26" viewBox="0 0 22 26" {...props}>
      <path
        d="M0.662109 5.33333C0.662109 5.14924 0.811348 5 0.995443 5H8.32878C8.51287 5 8.66211 5.14924 8.66211 5.33333V12.6667C8.66211 12.8508 8.51287 13 8.32878 13H0.995443C0.811348 13 0.662109 12.8508 0.662109 12.6667V5.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66211 6V12H7.66211V6H1.66211ZM0.995443 5C0.811348 5 0.662109 5.14924 0.662109 5.33333V12.6667C0.662109 12.8508 0.811348 13 0.995443 13H8.32878C8.51287 13 8.66211 12.8508 8.66211 12.6667V5.33333C8.66211 5.14924 8.51287 5 8.32878 5H0.995443Z"
        fill="#7070A9"
      />
      <path
        d="M13.3379 13.3333C13.3379 13.1492 13.4871 13 13.6712 13H21.0046C21.1887 13 21.3379 13.1492 21.3379 13.3333V20.6667C21.3379 20.8508 21.1887 21 21.0046 21H13.6712C13.4871 21 13.3379 20.8508 13.3379 20.6667V13.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3379 14V20H20.3379V14H14.3379ZM13.6712 13C13.4871 13 13.3379 13.1492 13.3379 13.3333V20.6667C13.3379 20.8508 13.4871 21 13.6712 21H21.0046C21.1887 21 21.3379 20.8508 21.3379 20.6667V13.3333C21.3379 13.1492 21.1887 13 21.0046 13H13.6712Z"
        fill="#7070A9"
      />
      <path
        d="M6.01789 18L15.5579 5.148H16.9799L7.43989 18H6.01789Z"
        fill="#3B404F"
      />
    </svg>
  );
};
