import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  PreciseDateTime: string;
  ReleaseType: any;
};

/**
 * Gradebook result access summary describing the last access to a unique
 * external service via the result.
 */
export type AccessSummary = {
  __typename?: 'AccessSummary';
  accessCount: Scalars['Int'];
  lastAccess: Scalars['PreciseDateTime'];
  service: GradebookService;
};

/**
 * A Cadmus Assessment
 *
 * Contains information available to all roles. Wraps some Subject information
 * and the latest Instruction sheet.
 */
export type Assessment = {
  __typename?: 'Assessment';
  /** Predicate checking if at least 1 save has been made in the Assessment. */
  hasSave: Scalars['Boolean'];
  /** Predicate checking if at least 1 submission has been made in the Assessment. */
  hasSubmission: Scalars['Boolean'];
  id: Scalars['ID'];
  /** All the LTI 1.1 and 1.3 services recorded for this Assessment. */
  ltiServices: Array<LtiService>;
  /** Insitutions LMS Tool Provider used to launch this Assessment. */
  ltiTool: InstitutionTool;
  name: Scalars['String'];
  subject: Subject;
  subjectId: Scalars['ID'];
  /** @deprecated Use the subject name from subject.name instead */
  subjectName: Scalars['String'];
};


/**
 * A Cadmus Assessment
 *
 * Contains information available to all roles. Wraps some Subject information
 * and the latest Instruction sheet.
 */
export type AssessmentSubjectArgs = {
  assessmentId: InputMaybe<Scalars['ID']>;
};

/** Cadmus assessments can be assignments or exams. */
export enum AssessmentType {
  Assignment = 'ASSIGNMENT',
  Exam = 'EXAM'
}

/** Return type for cancelRelease mutation. */
export type CancelReleasePayload = {
  __typename?: 'CancelReleasePayload';
  /** Latest state of the task. */
  task: Task;
};

/**
 * Query namespace for invoking the Canvas REST API operations via OAuth2
 * authorization.
 */
export type CanvasApi = {
  __typename?: 'CanvasApi';
  /** Canvas Provider authorization URL */
  authorizeUrl: Scalars['String'];
  authorized: Scalars['Boolean'];
  sections: Array<CanvasSection>;
};


/**
 * Query namespace for invoking the Canvas REST API operations via OAuth2
 * authorization.
 */
export type CanvasApiAuthorizeUrlArgs = {
  redirectTo: InputMaybe<Scalars['String']>;
};

/**
 * Canvas Section object, directly mapped from the Canvas REST API.
 *
 * See https://canvas.instructure.com/doc/api/sections.html.
 */
export type CanvasSection = {
  __typename?: 'CanvasSection';
  courseId: Scalars['ID'];
  /** Canvas Section ID. */
  id: Scalars['ID'];
  name: Scalars['String'];
  sisCourseId: Maybe<Scalars['ID']>;
  students: Maybe<Array<CanvasUser>>;
  totalStudents: Scalars['Int'];
};

/**
 * Canvas User object, directly mapped from the Canvas REST API.
 *
 * See https://canvas.instructure.com/doc/api/users.html#User.
 */
export type CanvasUser = {
  __typename?: 'CanvasUser';
  /** Canvas User ID. */
  id: Scalars['ID'];
  /** Canvas User full name. */
  name: Scalars['String'];
  /** Canvas SIS User ID. */
  sisUserId: Maybe<Scalars['ID']>;
};

/** Cadmus Class information for an Assessment. */
export type Class = {
  __typename?: 'Class';
  /** List of all groups and their members */
  groups: Array<Group>;
  /** Class assessment ID */
  id: Scalars['ID'];
  /** List of student enrollments in a class, including deleted enrollments. */
  students: Array<Enrollment>;
};

/** A full Class list with works and stats. */
export type ClassList = {
  __typename?: 'ClassList';
  /** List of Enrollments in a Class */
  enrollments: Array<Enrollment>;
  id: Scalars['ID'];
};

/** Enum for the different Class List tab contexts. */
export enum ClassTab {
  /** Drafts tab */
  Drafts = 'DRAFTS',
  /** Finals tab */
  Finals = 'FINALS',
  /** Students or Progress tab */
  Students = 'STUDENTS'
}

/** Custom params captured for a user during LTI launches. */
export type CustomParams = {
  __typename?: 'CustomParams';
  canvasAssignmentId: Maybe<Scalars['ID']>;
  canvasSubmissionId: Maybe<Scalars['ID']>;
  canvasUserId: Maybe<Scalars['ID']>;
  lisPersonSourcedid: Maybe<Scalars['String']>;
};

export type DeletedMember = {
  __typename?: 'DeletedMember';
  groupId: Scalars['ID'];
  id: Scalars['ID'];
};

/** All possible Disciplines in Cadmus */
export enum Discipline {
  ArchitectureBuilding = 'ARCHITECTURE_BUILDING',
  Business = 'BUSINESS',
  ComputingIt = 'COMPUTING_IT',
  Design = 'DESIGN',
  Education = 'EDUCATION',
  Engineering = 'ENGINEERING',
  HumanitiesLanguages = 'HUMANITIES_LANGUAGES',
  Law = 'LAW',
  Mathematics = 'MATHEMATICS',
  MediaCommunication = 'MEDIA_COMMUNICATION',
  MedicineHealth = 'MEDICINE_HEALTH',
  PerformingFineArts = 'PERFORMING_FINE_ARTS',
  Science = 'SCIENCE',
  SocialScience = 'SOCIAL_SCIENCE'
}

/** Different kinds of client-side Editors */
export enum EditorType {
  Prosemirror = 'PROSEMIRROR',
  Slate = 'SLATE'
}

/** A class Enrollment. */
export type Enrollment = {
  __typename?: 'Enrollment';
  /** LTI Custom params */
  customParams: CustomParams;
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Source LMS' SIS ID for the enrolled user */
  sisUserId: Maybe<Scalars['String']>;
  /** Tags for the enrollment */
  tags: Array<Tag>;
  /** Enrolled user */
  user: User;
  /** Work started under the Enrollment, if any */
  work: Maybe<Work>;
  workSettings: Maybe<WorkSettings>;
};

/** Type of examination time windowing strategy. */
export enum ExamTiming {
  Live = 'LIVE',
  Window = 'WINDOW'
}

/**
 * Grade information for a Result
 *
 * A grade stored as a floating point numeric value between 0 and 1. For display
 * however, this value has to be mulitplied by the latest released maximum grade
 * point requirements. The display is also string formatted with 2-digit padding
 * and 2-decimal point accuracy.
 */
export type Grade = {
  __typename?: 'Grade';
  /** Formatted display of a grade. */
  display: Scalars['String'];
  /** Maximum score possible for the grade. */
  max: Scalars['Int'];
  /** Actual 0-1 grade value. */
  value: Scalars['Float'];
};

/** Known service integrations into the Cadmus Gradebook result */
export enum GradebookService {
  Speedgrader = 'SPEEDGRADER',
  Turnitin = 'TURNITIN'
}

export type Group = {
  __typename?: 'Group';
  code: Maybe<Scalars['String']>;
  groupSet: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  members: Array<User>;
  name: Scalars['String'];
};

export type GroupInput = {
  code: InputMaybe<Scalars['String']>;
  groupSet: InputMaybe<Scalars['String']>;
  members: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

/** Input object for updating students' group within an assessmnet context. */
export type GroupMembershipInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GroupUpdateInput = {
  addedMembers: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  removedMembers: InputMaybe<Array<Scalars['ID']>>;
};

/** Cadmus Institution. */
export type Institution = {
  __typename?: 'Institution';
  id: Scalars['ID'];
  name: Scalars['String'];
  tenant: Scalars['String'];
  timezone: Scalars['String'];
};

/** Institution LMS Tool Provider information. */
export type InstitutionTool = {
  __typename?: 'InstitutionTool';
  familyCode: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

/** Status information on a background Job */
export type Job = {
  __typename?: 'Job';
  assessmentId: Scalars['ID'];
  conflict: Scalars['Boolean'];
  id: Scalars['ID'];
  insertedAt: Scalars['DateTime'];
  /** Final result URL if available */
  resultUrl: Maybe<Scalars['String']>;
};

/** An LTI Service like AGS or NRPS. */
export type LtiService = {
  __typename?: 'LtiService';
  id: Scalars['ID'];
  /** LTI Advantage (V2) services have OAuth2 token issuers */
  issuer: Maybe<Scalars['String']>;
  name: Scalars['String'];
  version: Scalars['String'];
};

/**
 * A Mailing List representation which can be active or in-active depending on
 * the `subscribed` field.
 */
export type MailingList = {
  __typename?: 'MailingList';
  id: Scalars['String'];
  members: Array<Scalars['String']>;
  subscribed: Scalars['Boolean'];
};

/**
 * This is a result object for when the user (Teacher) has succesfully onboarded to Athena.
 *
 * It returns the entities that were modified.
 */
export type OnboardingPayload = {
  __typename?: 'OnboardingPayload';
  subject: Maybe<Subject>;
  userSettings: Maybe<UserSettings>;
};

/** A past instruction, which includes information about each assessment and its related, latest released instructions. */
export type PastInstructionSheet = {
  __typename?: 'PastInstructionSheet';
  assessment: Assessment;
  sheet: Sheet;
};

/** Assessment Task requirements. */
export type Requirements = {
  __typename?: 'Requirements';
  /** Allow late re-submissions or not. */
  allowLateResubmission: Scalars['Boolean'];
  /** If anonymous marking is selected. */
  anonymousMarking: Maybe<Scalars['Boolean']>;
  assessmentType: AssessmentType;
  countReferences: Scalars['Boolean'];
  /** Drafting due date with Institution timezone */
  draftDueDate: Maybe<Scalars['PreciseDateTime']>;
  /**
   * Are students allowed to view draft similarity scores/reports.
   *
   * 0 - Never
   * 1 - Only After the final due date
   * 2 - Always
   */
  draftSViewReports: Scalars['Int'];
  /** Final due date with Institution timezone. */
  dueDate: Maybe<Scalars['PreciseDateTime']>;
  enableExamAutoSubmission: Scalars['Boolean'];
  /** Enable or disable PDF attachment in submission receipts. */
  enableSubmissionPdf: Scalars['Boolean'];
  examEndDate: Maybe<Scalars['PreciseDateTime']>;
  examReadingTime: Maybe<Scalars['Int']>;
  examStartDate: Maybe<Scalars['PreciseDateTime']>;
  examTiming: ExamTiming;
  examType: Array<Scalars['String']>;
  examWritingTime: Maybe<Scalars['Int']>;
  /** Selected grading integration. Currently either `turnitin` or `speedgrader`. */
  gradingService: Scalars['String'];
  /**
   * Has the set of requirements been altered by a user?
   *
   * This checks whether we have saved any requirements against the assessment
   */
  hasChanged: Scalars['Boolean'];
  id: Scalars['ID'];
  lateSubmissionTimeLimit: Maybe<Scalars['Int']>;
  /** Maximum grade points. */
  maxGrade: Scalars['Int'];
  noDraft: Scalars['Boolean'];
  noExamReadingTime: Scalars['Boolean'];
  noLimit: Scalars['Boolean'];
  noReferencing: Scalars['Boolean'];
  /** Do students need to work in a limited time window */
  noTimeLimit: Scalars['Boolean'];
  noWeight: Scalars['Boolean'];
  referencingStyle: Maybe<Scalars['String']>;
  /** Final grade and feedback return date date with Institution timezone. */
  returnDate: Maybe<Scalars['PreciseDateTime']>;
  /**
   * Are students allowed to view final similarity scores/reports.
   *
   * 0 - Never
   * 1 - Only After the final due date
   * 2 - Always
   */
  sViewReports: Scalars['Int'];
  /** Allowed working time in minutes */
  timeLimit: Maybe<Scalars['Int']>;
  weight: Maybe<Scalars['Float']>;
  wordLimit: Maybe<Scalars['Int']>;
};

export type RequirementsInput = {
  allowLateResubmission: InputMaybe<Scalars['Boolean']>;
  anonymousMarking: InputMaybe<Scalars['Boolean']>;
  assessmentType: InputMaybe<AssessmentType>;
  countReferences: InputMaybe<Scalars['Boolean']>;
  draftDueDate: InputMaybe<Scalars['DateTime']>;
  draftSViewReports: InputMaybe<Scalars['Int']>;
  dueDate: InputMaybe<Scalars['DateTime']>;
  enableExamAutoSubmission: InputMaybe<Scalars['Boolean']>;
  enableSubmissionPdf: InputMaybe<Scalars['Boolean']>;
  examEndDate: InputMaybe<Scalars['PreciseDateTime']>;
  examReadingTime: InputMaybe<Scalars['Int']>;
  examStartDate: InputMaybe<Scalars['PreciseDateTime']>;
  examTiming: InputMaybe<ExamTiming>;
  examType: InputMaybe<Array<Scalars['String']>>;
  examWritingTime: InputMaybe<Scalars['Int']>;
  gradingService: InputMaybe<Scalars['String']>;
  lateSubmissionTimeLimit: InputMaybe<Scalars['Int']>;
  maxGrade: InputMaybe<Scalars['Int']>;
  noDraft: InputMaybe<Scalars['Boolean']>;
  noExamReadingTime: InputMaybe<Scalars['Boolean']>;
  noLimit: InputMaybe<Scalars['Boolean']>;
  noReferencing: InputMaybe<Scalars['Boolean']>;
  noTimeLimit: InputMaybe<Scalars['Boolean']>;
  noWeight: InputMaybe<Scalars['Boolean']>;
  referencingStyle: InputMaybe<Scalars['String']>;
  returnDate: InputMaybe<Scalars['DateTime']>;
  sViewReports: InputMaybe<Scalars['Int']>;
  timeLimit: InputMaybe<Scalars['Int']>;
  weight: InputMaybe<Scalars['Float']>;
  wordLimit: InputMaybe<Scalars['Int']>;
};

/** A Task resource. */
export type Resource = {
  __typename?: 'Resource';
  /** File size in bytes */
  fileSize: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invalidated: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  /** Additional notes on this resource */
  notes: Maybe<Scalars['String']>;
  order: Maybe<Scalars['Int']>;
  url: Maybe<Scalars['String']>;
};

/** A Gradebook Result. */
export type Result = {
  __typename?: 'Result';
  /** Canvas LTI API url for a successfully recorded submission in Canvas. */
  canvasResultUrl: Maybe<Scalars['String']>;
  /**
   * Find the last access summary for launching this result with the configured
   * external grading service.
   */
  feedbackAccess: Maybe<AccessSummary>;
  /** Grade information on the Result. */
  grade: Maybe<Grade>;
  /** Connection to Hera for a successfully recorded submission in Hera and Turnitin. */
  heraResultId: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** Is the hera connection complete? */
  isReady: Scalars['Boolean'];
  /** Similarity information on the Result. */
  similarity: Maybe<Similarity>;
  /** Type of the Submission that the Result belongs to. */
  submissionType: SubmissionType;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Add a tag to an enrollment */
  addTag: Tag;
  /** Add a tag to multiple enrollments. */
  bulkAddTag: Array<Tag>;
  /** Remove a tag from multiple enrollments. */
  bulkRemoveTag: Scalars['Boolean'];
  /** Cancel an existing Instruction Sheet release. */
  cancelRelease: CancelReleasePayload;
  /**
   * Entry point for the user informing Cadmus about their onboaring information. This will:
   * - Update the subject_role for the user:subject
   * - Update the assessment's subject's discipline.
   *
   * If the discipline is not present, the current subject's discipline will remain.
   */
  captureOnboardingInput: OnboardingPayload;
  captureUserpilotPreference: SetUserpilotPreferencePayload;
  /**
   * Copy Assessment accessories, which include resources, state and
   * settings from another assessment.
   */
  copyAssessment: Task;
  /** Create a new group. */
  createGroup: Group;
  /** Create multiple groups. */
  createGroups: Array<Group>;
  /** Create a new Link/Url Resource. */
  createResource: Resource;
  /** Task content save. */
  createTaskSave: SaveState;
  /** Delete an existing group. */
  deleteGroup: Scalars['Boolean'];
  /** Delete a member from a group */
  deleteGroupMember: Maybe<DeletedMember>;
  /** Delete multiple groups. */
  deleteGroups: Maybe<Scalars['Int']>;
  /** Invalidate an existing Link/Url Resource. */
  deleteResource: Resource;
  /** Generate a Submission's pdf presigned url for download. */
  downloadSubmission: Scalars['String'];
  /**
   * Generate a pre-signed download URL for the given submissions in the
   * Assessment.
   */
  downloadSubmissions: Scalars['String'];
  /** Change Task status to editing. */
  editTask: Task;
  forceSubmissions: Array<Maybe<Submission>>;
  /** Generate Assessment and Student reports. */
  generateReport: Job;
  /** Release the given Task, returning a new latest Instruction Sheet. */
  releaseTask: Sheet;
  /** Remove a tag from an enrollment */
  removeTag: Scalars['Boolean'];
  /** Cloud save for a Work. */
  save: Save;
  /** Mark start of a work with time limit. */
  start: Work;
  /** Draft or final Submission. */
  submit: Submission;
  /** Swap the order of two Resources. */
  swapResource: Scalars['Boolean'];
  /**
   * Pass all existing final grades to the source LMS of an Assessment.
   *
   * This process happens asynchronously in the background, while the mutation
   * returns immediately.
   */
  syncGrades: Maybe<Scalars['Boolean']>;
  /**
   * Perform a Roster Sync with the source LMS of an Assesment.
   *
   * Returns the list of new enrollments created, if any. The roster sync will
   * fail if the source LMS does not support it or has not provided service
   * params to sync with.
   */
  syncRoster: Array<Maybe<Enrollment>>;
  /** Unsubmission, which is really to allow Re-submissions. */
  unsubmit: Submission;
  /** Update an Assessment name. */
  updateAssessmentName: Assessment;
  /** Update the cadmus experience survey score. */
  updateExperience: Survey;
  /** Update an existing group. */
  updateGroup: Group;
  /**
   * Bulk update user memberships across multiple existing groups. Returns the
   * new complete list of an Assessment's marking groups.
   */
  updateGroupMemberships: Array<Group>;
  /** Updates the existing Insights Mailing List for the given assessment. */
  updateInsightsMailingList: MailingList;
  /** Partial Requirements update. */
  updateRequirements: Requirements;
  /** Update an existing Link/Url Resource. */
  updateResource: Resource;
  /** Batch update student settings. */
  updateStudentSettings: Array<Maybe<WorkSettings>>;
  /** Update certain user fields. */
  updateUser: User;
  /** Update a Work's settings. */
  updateWorkSettings: WorkSettings;
};


export type RootMutationTypeAddTagArgs = {
  column: InputMaybe<Scalars['Int']>;
  enrollmentId: Scalars['ID'];
  tab: InputMaybe<ClassTab>;
  text: Scalars['String'];
};


export type RootMutationTypeBulkAddTagArgs = {
  enrollmentIds: Array<Scalars['ID']>;
  tab: ClassTab;
  text: Scalars['String'];
};


export type RootMutationTypeBulkRemoveTagArgs = {
  enrollmentIds: Array<Scalars['ID']>;
  tab: ClassTab;
  text: Scalars['String'];
};


export type RootMutationTypeCancelReleaseArgs = {
  assessmentId: Scalars['ID'];
  sheetId: Scalars['ID'];
};


export type RootMutationTypeCaptureOnboardingInputArgs = {
  discipline: InputMaybe<Discipline>;
  subjectId: Scalars['ID'];
  subjectRole: InputMaybe<SubjectRole>;
};


export type RootMutationTypeCaptureUserpilotPreferenceArgs = {
  showUserpilotOnboarding: InputMaybe<Scalars['Boolean']>;
};


export type RootMutationTypeCopyAssessmentArgs = {
  destinationAssessmentId: Scalars['ID'];
  sourceInstructionSheetId: Scalars['ID'];
};


export type RootMutationTypeCreateGroupArgs = {
  assessmentId: Scalars['ID'];
  group: GroupInput;
};


export type RootMutationTypeCreateGroupsArgs = {
  assessmentId: Scalars['ID'];
  groups: Array<GroupInput>;
};


export type RootMutationTypeCreateResourceArgs = {
  assessmentId: Scalars['ID'];
  fileSize: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  notes: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};


export type RootMutationTypeCreateTaskSaveArgs = {
  assessmentId: Scalars['ID'];
  content: Scalars['String'];
  editor: InputMaybe<EditorType>;
};


export type RootMutationTypeDeleteGroupArgs = {
  assessmentId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type RootMutationTypeDeleteGroupMemberArgs = {
  assessmentId: Scalars['ID'];
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootMutationTypeDeleteGroupsArgs = {
  assessmentId: Scalars['ID'];
  groupIds: InputMaybe<Array<Scalars['ID']>>;
};


export type RootMutationTypeDeleteResourceArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDownloadSubmissionArgs = {
  submissionId: Scalars['ID'];
  workId: Scalars['ID'];
};


export type RootMutationTypeDownloadSubmissionsArgs = {
  assessmentId: Scalars['ID'];
  submissions: Array<SubmissionDetailInput>;
};


export type RootMutationTypeEditTaskArgs = {
  assessmentId: Scalars['ID'];
};


export type RootMutationTypeForceSubmissionsArgs = {
  assessmentId: Scalars['ID'];
  type: SubmissionType;
  workIds: Array<InputMaybe<Scalars['ID']>>;
};


export type RootMutationTypeGenerateReportArgs = {
  assessmentId: Scalars['ID'];
  kind: Scalars['String'];
  workId: InputMaybe<Scalars['ID']>;
};


export type RootMutationTypeReleaseTaskArgs = {
  assessmentId: Scalars['ID'];
  releaseType: InputMaybe<Scalars['ReleaseType']>;
  saveId: Scalars['ID'];
  scheduleTimestamp: InputMaybe<Scalars['DateTime']>;
};


export type RootMutationTypeRemoveTagArgs = {
  column: InputMaybe<Scalars['Int']>;
  enrollmentId: Scalars['ID'];
  tab: InputMaybe<ClassTab>;
  text: Scalars['String'];
};


export type RootMutationTypeSaveArgs = {
  content: Scalars['String'];
  dispatchedAt: Scalars['DateTime'];
  editor: InputMaybe<EditorType>;
  metadata: InputMaybe<SaveMetadataInput>;
  workId: Scalars['ID'];
};


export type RootMutationTypeStartArgs = {
  workId: Scalars['ID'];
};


export type RootMutationTypeSubmitArgs = {
  acceptedDeclaration: Scalars['Boolean'];
  editor: InputMaybe<EditorType>;
  saveId: Scalars['ID'];
  sessionId: InputMaybe<Scalars['String']>;
  submittedAt: InputMaybe<Scalars['DateTime']>;
  type: SubmissionType;
  workId: Scalars['ID'];
};


export type RootMutationTypeSwapResourceArgs = {
  firstId: Scalars['ID'];
  secondId: Scalars['ID'];
};


export type RootMutationTypeSyncGradesArgs = {
  assessmentId: Scalars['ID'];
};


export type RootMutationTypeSyncRosterArgs = {
  assessmentId: Scalars['ID'];
};


export type RootMutationTypeUnsubmitArgs = {
  submissionId: Scalars['ID'];
  workId: Scalars['ID'];
};


export type RootMutationTypeUpdateAssessmentNameArgs = {
  assessmentId: Scalars['ID'];
  name: Scalars['String'];
};


export type RootMutationTypeUpdateExperienceArgs = {
  score: Scalars['Int'];
  workId: Scalars['ID'];
};


export type RootMutationTypeUpdateGroupArgs = {
  assessmentId: Scalars['ID'];
  group: GroupUpdateInput;
};


export type RootMutationTypeUpdateGroupMembershipsArgs = {
  assessmentId: Scalars['ID'];
  memberships: Array<GroupMembershipInput>;
};


export type RootMutationTypeUpdateInsightsMailingListArgs = {
  assessmentId: Scalars['ID'];
  members: Array<Scalars['String']>;
  subscribed: Scalars['Boolean'];
};


export type RootMutationTypeUpdateRequirementsArgs = {
  assessmentId: Scalars['ID'];
  requirements: RequirementsInput;
};


export type RootMutationTypeUpdateResourceArgs = {
  id: Scalars['ID'];
  name: InputMaybe<Scalars['String']>;
  notes: InputMaybe<Scalars['String']>;
  url: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeUpdateStudentSettingsArgs = {
  assessmentId: Scalars['ID'];
  settings: Array<InputMaybe<StudentSettingsInput>>;
};


export type RootMutationTypeUpdateUserArgs = {
  firstName: InputMaybe<Scalars['String']>;
  lastName: InputMaybe<Scalars['String']>;
  onboardingSeen: InputMaybe<Scalars['String']>;
};


export type RootMutationTypeUpdateWorkSettingsArgs = {
  assessmentId: Scalars['ID'];
  dueDate: InputMaybe<Scalars['DateTime']>;
  enrollmentId: Scalars['ID'];
  examDeferred: InputMaybe<Scalars['Boolean']>;
  examEndDate: InputMaybe<Scalars['DateTime']>;
  examReadingTime: InputMaybe<Scalars['Int']>;
  examStartDate: InputMaybe<Scalars['DateTime']>;
  examWritingTime: InputMaybe<Scalars['Int']>;
  timeLimit: InputMaybe<Scalars['Int']>;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** A single assessment. */
  assessment: Assessment;
  /** Access the Canvas REST API as the current user via OAuth2 interface. */
  canvasApi: CanvasApi;
  /** Complete Class information for an Assessment's cohort. */
  class: Class;
  /**
   * A full Class list for the given Assessment.
   * @deprecated Use `class` query field instead
   */
  classList: ClassList;
  /** Future scheduled Instruction sheet, if any */
  futureSheet: Maybe<Sheet>;
  /**
   * Groups for assessment.
   * @deprecated Use `class` query field instead
   */
  groups: Array<Group>;
  /**
   * Assessment's latest Insights mailing list consisting of teacher emails who
   * want to receive Insight updates via email.
   */
  insightsMailingList: MailingList;
  /** Current user Institution. */
  institution: Institution;
  /** Latest current released Instruction sheet, if any */
  instructionSheet: Maybe<Sheet>;
  /**
   * Latest Gradebook result for a particular submission type.
   *
   * This field always tries to resolve to the most up to date information, and
   * can also query Hera (and in turn Turnitin) to get it.
   */
  latestResult: Result;
  /** Current logged in User profile */
  me: User;
  /** Past instruction sheets that the user has been involved in. */
  pastInstructionSheets: Array<PastInstructionSheet>;
  /**
   * A unique ID for the current graphql request.
   *
   * Uses the Plug assigned response `x-request-id`.
   *
   * DEPRECATED usage: Use `sessionId` for tagging events with a Session ID
   * instead of this field.
   */
  requestId: Maybe<Scalars['String']>;
  /** Token to authenticate the cadmus client with other cadmus client services. */
  serviceToken: Scalars['String'];
  /** Current Session ID tied to the user's authenticated session. */
  sessionId: Scalars['String'];
  /** A single assessment task. */
  task: Task;
  /** Token for Current User token authentication with Phoenix channels. */
  token: Scalars['String'];
  /** Get the current user:subject settings. This will include settings that are set on a global scope. */
  userSettings: Maybe<UserSettings>;
  /** A single Work. */
  work: Work;
  workSheet: Maybe<Sheet>;
};


export type RootQueryTypeAssessmentArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeCanvasApiArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeClassArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeClassListArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeFutureSheetArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeGroupsArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeInsightsMailingListArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeInstructionSheetArgs = {
  assessmentId: Scalars['ID'];
  sheetId: InputMaybe<Scalars['ID']>;
};


export type RootQueryTypeLatestResultArgs = {
  submissionType: SubmissionType;
  workId: Scalars['ID'];
};


export type RootQueryTypeTaskArgs = {
  assessmentId: Scalars['ID'];
};


export type RootQueryTypeUserSettingsArgs = {
  assessmentId: InputMaybe<Scalars['ID']>;
};


export type RootQueryTypeWorkArgs = {
  workId: Scalars['ID'];
};


export type RootQueryTypeWorkSheetArgs = {
  assessmentId: Scalars['ID'];
  workId: Scalars['ID'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Notification on number of enrollments updated */
  enrollmentsUpdated: Scalars['Int'];
  /** Future sheet release schedule timestamp */
  futureReleaseSchedule: Maybe<Scalars['PreciseDateTime']>;
  /** At least one submission has been made in the Assessment. */
  hasSubmission: Scalars['Boolean'];
  /** A Gradebook Result update, in an Assessment. */
  resultUpdated: Result;
  /** New Instruction Sheet release. */
  sheetReleased: Sheet;
  /** Update for a Gradebook result for a particular Work only. */
  workResultUpdated: Result;
  /** Update for a Work Settings. */
  workSettingsUpdated: WorkSettings;
  /** Update for work started */
  workStarted: Work;
  /** A Work has been updated. Usually it's the settings that update for a work. */
  workUpdated: Work;
};


export type RootSubscriptionTypeEnrollmentsUpdatedArgs = {
  assessmentId: Scalars['ID'];
};


export type RootSubscriptionTypeFutureReleaseScheduleArgs = {
  assessmentId: Scalars['ID'];
};


export type RootSubscriptionTypeHasSubmissionArgs = {
  assessmentId: Scalars['ID'];
};


export type RootSubscriptionTypeResultUpdatedArgs = {
  assessmentId: Scalars['ID'];
};


export type RootSubscriptionTypeSheetReleasedArgs = {
  assessmentId: Scalars['ID'];
  workId: InputMaybe<Scalars['ID']>;
};


export type RootSubscriptionTypeWorkResultUpdatedArgs = {
  workId: Scalars['ID'];
};


export type RootSubscriptionTypeWorkSettingsUpdatedArgs = {
  workId: Scalars['ID'];
};


export type RootSubscriptionTypeWorkStartedArgs = {
  workId: Scalars['ID'];
};


export type RootSubscriptionTypeWorkUpdatedArgs = {
  workId: Scalars['ID'];
};

/** A Save for a work. */
export type Save = {
  __typename?: 'Save';
  /** Fetched contents of the embedded S3 Store reference. */
  content: Scalars['String'];
  dispatchedAt: Scalars['PreciseDateTime'];
  id: Scalars['ID'];
  /**
   * Database ID of the persisted save.
   *
   * This always reflects the actual table primary key of the Save, even when the
   * the `:id` value is some constant string. Sometimes the `:id` field has to
   * resolve to a constant string to make the client side cache always hit.
   */
  serverId: Scalars['ID'];
  /** S3 objet version ID. */
  version: Scalars['String'];
};

export type SaveMetadataInput = {
  assessmentId: Scalars['String'];
  prevSaveId: InputMaybe<Scalars['String']>;
  prevVersionId: InputMaybe<Scalars['String']>;
  sessionId: InputMaybe<Scalars['String']>;
  workId: Scalars['String'];
};

/** Latest Task content save. */
export type SaveState = {
  __typename?: 'SaveState';
  /**
   * Content field from raw binary downloaded from the `Cadmus.Cloud.Store`
   * reference stored in the parent `TaskState`.
   * @deprecated content will no longer be supported in order to do editor agnostic content
   */
  content: Maybe<Scalars['String']>;
  /** Latest Task save ID. */
  id: Scalars['ID'];
  /**
   * Raw binary downloaded from the `Cadmus.Cloud.Store`
   * reference stored in the parent `TaskState`.
   */
  rawContent: Maybe<Scalars['String']>;
  /** The persisted TaskState server ID */
  serverId: Scalars['ID'];
  updatedAt: Scalars['PreciseDateTime'];
  version: Scalars['Int'];
};

/**
 * This is the return object for when the user sets/updates their Userpilot preferences.
 *
 * It returns the entity that was modified (user_settings).
 */
export type SetUserpilotPreferencePayload = {
  __typename?: 'SetUserpilotPreferencePayload';
  userSettings: Maybe<UserSettings>;
};

/** Latest Instruction sheet for an Assessment. */
export type Sheet = {
  __typename?: 'Sheet';
  /** Allow late re-submissions or not. */
  allowLateResubmission: Scalars['Boolean'];
  /** If anonymous marking is enabled */
  anonymousMarking: Maybe<Scalars['Boolean']>;
  /** Assessment Type */
  assessmentType: AssessmentType;
  /**
   * Serialised Editor contents of the Task body.
   * @deprecated content will no longer be supported in order to do editor agnostic content
   */
  content: Maybe<Scalars['String']>;
  countReferences: Maybe<Scalars['Boolean']>;
  /**
   * Type of submissions currently being accepted.
   *
   * This can never be `nil` as the `:sheet` should always have a Final due date.
   *
   * Note that the comparison with the draft due date allows a grace period of 1
   * hour.
   */
  currentSubmissionType: SubmissionType;
  /** Draft submission due date if drafting is enabled */
  draftDueDate: Maybe<Scalars['PreciseDateTime']>;
  /** Latest setting value for draft_s_view_reports. */
  draftSViewReports: Scalars['Int'];
  /** Final Submission due date. */
  dueDate: Scalars['PreciseDateTime'];
  enableExamAutoSubmission: Scalars['Boolean'];
  /** Enable or disable PDF attachment in submission receipts. */
  enableSubmissionPdf: Scalars['Boolean'];
  examEndDate: Maybe<Scalars['PreciseDateTime']>;
  examReadingTime: Maybe<Scalars['Int']>;
  examStartDate: Maybe<Scalars['PreciseDateTime']>;
  examTiming: ExamTiming;
  examType: Array<Scalars['String']>;
  examWritingTime: Maybe<Scalars['Int']>;
  /** Selected grading integration. Either `turnitin` or `speedgrader` currently. */
  gradingService: Scalars['String'];
  /**
   * Task ID for which this sheet is released.
   *
   * NOTE: this is the Task ID and not the actual sheet ID which changes from
   * sheet to sheet. Use `serverId` for the actual sheet ID. This was done for
   * automatic cache replacement in apollo client for new sheets.
   */
  id: Scalars['String'];
  lateSubmissionTimeLimit: Maybe<Scalars['Int']>;
  /** Maximum grade points. */
  maxGrade: Scalars['Int'];
  /** When the next sheet, if any, will be released. */
  nextReleaseTimestamp: Maybe<Scalars['PreciseDateTime']>;
  /** Serialised Editor contents of the Task body with other embedded metadata */
  rawContent: Maybe<Scalars['String']>;
  referencingStyle: Maybe<Scalars['String']>;
  /** When the sheet was released. */
  releaseTimestamp: Scalars['PreciseDateTime'];
  /** List of released resources. */
  resources: Array<Resource>;
  /** Feedback return date. */
  returnDate: Scalars['PreciseDateTime'];
  /** Latest setting value for s_view_reports. */
  sViewReports: Scalars['Int'];
  /** Actual TaskHistory primary ID. */
  serverId: Scalars['ID'];
  timeLimit: Maybe<Scalars['Int']>;
  /** Task save version number from which the Sheet was created. */
  version: Scalars['Int'];
  weight: Maybe<Scalars['Float']>;
  wordLimit: Maybe<Scalars['Int']>;
};

/**
 * Similarity information for a Result.
 *
 * Currently a Turnitin similarity score is available in the gradebook. This
 * score is a floating point percentage value.
 */
export type Similarity = {
  __typename?: 'Similarity';
  /** Formatted display of a Similarity score. */
  display: Scalars['String'];
  /** Similarity label color based on the score. */
  label: Scalars['String'];
  /** Similarity score numeric value between 0-100. */
  value: Scalars['Float'];
};

/** All possible Task release states. */
export enum Status {
  Editing = 'EDITING',
  Released = 'RELEASED'
}

/** Input object for updating a student's settings within an assessment context. */
export type StudentSettingsInput = {
  dueDate: InputMaybe<Scalars['DateTime']>;
  examDeferred: InputMaybe<Scalars['Boolean']>;
  examEndDate: InputMaybe<Scalars['DateTime']>;
  examReadingTime: InputMaybe<Scalars['Int']>;
  examStartDate: InputMaybe<Scalars['DateTime']>;
  examWritingTime: InputMaybe<Scalars['Int']>;
  studentId: Scalars['ID'];
  timeLimit: InputMaybe<Scalars['Int']>;
};

/**
 * A Cadmus Subject
 *
 * Contains general information about the assessments tied this subject.
 *
 * - Name: comes from the LMS
 * - Discipline: currently gathered from Athena, user input as part of onboarding.
 */
export type Subject = {
  __typename?: 'Subject';
  discipline: Maybe<Discipline>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum SubjectRole {
  FacultyLeader = 'FACULTY_LEADER',
  LearningDesigner = 'LEARNING_DESIGNER',
  LearningTechnologist = 'LEARNING_TECHNOLOGIST',
  Lecturer = 'LECTURER',
  Marker = 'MARKER',
  Other = 'OTHER',
  SubjectCoordinator = 'SUBJECT_COORDINATOR',
  Tutor = 'TUTOR',
  UniversityLeader = 'UNIVERSITY_LEADER'
}

/** A Final or Draft Submission. */
export type Submission = {
  __typename?: 'Submission';
  forceSubmitted: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** Gradebook result on the submission. */
  result: Result;
  /** Save attached to the submission. */
  save: Save;
  submittedAt: Scalars['PreciseDateTime'];
  /** Type of Submission. */
  type: SubmissionType;
  unsubmittedAt: Maybe<Scalars['PreciseDateTime']>;
  /** Human readable comparison against the given Due Date. */
  when: Scalars['String'];
};


/** A Final or Draft Submission. */
export type SubmissionWhenArgs = {
  dueDate: InputMaybe<Scalars['DateTime']>;
};

/**
 * Input to submision details where `id` is the submission id and `name` is what
 * the submission filename will be inside the archive file generated by
 * :download_submissions mutation
 */
export type SubmissionDetailInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** Types of submissions the client can make. */
export enum SubmissionType {
  Draft = 'DRAFT',
  Final = 'FINAL'
}

/** A recorded Survey response. */
export type Survey = {
  __typename?: 'Survey';
  /** Is the survey open for accepting new answers? */
  active: Scalars['Boolean'];
  answer: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  score: Maybe<Scalars['Int']>;
};

/** A Tag attached to an Enrollment */
export type Tag = {
  __typename?: 'Tag';
  /** @deprecated Use an explicit `tab` instead of column */
  column: Scalars['Int'];
  id: Scalars['ID'];
  tab: ClassTab;
  text: Scalars['String'];
};

/**
 * An Assessment Task
 *
 * Represents the latest Task state, which is always changing as it is being
 * edited by the teacher client. Wraps Task save state, Task Requirements, and
 * Task Resources.
 *
 * Developer notes:
 *
 * If resolved by the mutation `copy_assessment`, this Task represents resulting task
 * and its related entities (such as resources, task state and settings) that
 * were copied.
 */
export type Task = {
  __typename?: 'Task';
  /** Internal ID of the assessment task */
  id: Scalars['ID'];
  /** Latest Task requirement values. */
  requirements: Requirements;
  /** Latest Task resources. */
  resources: Array<Resource>;
  /** Latest Task save state. */
  saveState: SaveState;
  /** Task release state */
  status: Status;
};

/** Cadmus User profile */
export type User = {
  __typename?: 'User';
  email: Maybe<Scalars['String']>;
  /** User family name to be used for sorting. */
  familyName: Scalars['String'];
  /**
   * Combines the LMS given name and any user set first name.
   * @deprecated Use `name`, `givenName`, and `familyName`.
   */
  firstName: Scalars['String'];
  /**
   * Combines the LMS user names with any user set attributes.
   * @deprecated Use `name`, `givenName`, and `familyName`.
   */
  fullName: Scalars['String'];
  /** User given name to be used as a short display name. */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /**
   * Combines the LMS last name and any user set last name.
   * @deprecated Use `name`, `givenName`, and `familyName`.
   */
  lastName: Scalars['String'];
  /** Full name, parsed from the LMS request which created the user. */
  name: Scalars['String'];
  /** Has the user seen the latest onboarding manual screens? */
  onboardingSeen: Scalars['Boolean'];
};

/** A set of settings/properties that belong to a user. */
export type UserSettings = {
  __typename?: 'UserSettings';
  /** ID of the user_settings entity */
  id: Scalars['ID'];
  /** The user's intent to show onboarding related materials from Userpilot */
  showUserpilotOnboarding: Maybe<Scalars['Boolean']>;
  /** The subject_role from the user_settings object */
  subjectRole: Maybe<SubjectRole>;
};

/** A Student's Work with submissions and saves. */
export type Work = {
  __typename?: 'Work';
  /** Latest draft submission. */
  draft: Maybe<Submission>;
  /**
   * Individual final due date for this work if one has been set. Will override
   * the assessment final due date.
   */
  dueDate: Maybe<Scalars['PreciseDateTime']>;
  /** Student's Cadmus experience survey response related to this work */
  experience: Survey;
  /** Latest final submission */
  final: Maybe<Submission>;
  id: Scalars['ID'];
  /**
   * Last save timestamp if there is a last save.
   *
   * NOTE: This field is optimised for batch loading
   */
  lastSaveTime: Maybe<Scalars['PreciseDateTime']>;
  /**
   * Latest save for the Work, if any.
   *
   * NOTE: This field is not optimised for batch loading on multiple works.
   */
  latestSave: Maybe<Save>;
  /** work settings */
  settings: Maybe<WorkSettings>;
  /** Student sheet */
  sheet: Maybe<Sheet>;
  /** work start date */
  startDate: Maybe<Scalars['PreciseDateTime']>;
  /** Student who owns the work */
  student: User;
  updatedAt: Maybe<Scalars['PreciseDateTime']>;
};

/** Settings for a work */
export type WorkSettings = {
  __typename?: 'WorkSettings';
  assessmentId: Scalars['ID'];
  dueDate: Maybe<Scalars['PreciseDateTime']>;
  examDeferred: Maybe<Scalars['Boolean']>;
  examEndDate: Maybe<Scalars['DateTime']>;
  examReadingTime: Maybe<Scalars['Int']>;
  examStartDate: Maybe<Scalars['DateTime']>;
  examWritingTime: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  studentId: Scalars['ID'];
  timeLimit: Maybe<Scalars['Int']>;
};

export type UpdateUserMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  onboardingSeen: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'RootMutationType', updateUser: { __typename?: 'User', id: string, email: string | null, fullName: string, firstName: string, lastName: string, onboardingSeen: boolean } };

export type UserFragment = { __typename?: 'User', id: string, email: string | null, fullName: string, firstName: string, lastName: string, onboardingSeen: boolean };

export type AssessmentFragment = { __typename?: 'Assessment', id: string, name: string, subjectName: string };

export type DueDatesQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type DueDatesQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, startDate: string | null, settings: { __typename?: 'WorkSettings', id: string, dueDate: string | null, timeLimit: number | null } | null, sheet: { __typename?: 'Sheet', id: string, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, nextReleaseTimestamp: string | null, releaseTimestamp: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean } | null } };

export type OnFutureReleaseScheduleSubscriptionVariables = Exact<{
  assessmentId: Scalars['ID'];
}>;


export type OnFutureReleaseScheduleSubscription = { __typename?: 'RootSubscriptionType', futureReleaseSchedule: string | null };

export type RequirementsFragment = { __typename?: 'Sheet', id: string, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, nextReleaseTimestamp: string | null, releaseTimestamp: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean };

export type ResourceFragment = { __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null };

export type SheetFragment = { __typename?: 'Sheet', id: string, rawContent: string | null, releaseTimestamp: string, nextReleaseTimestamp: string | null, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean, resources: Array<{ __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null }> };

export type SheetQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type SheetQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, student: { __typename?: 'User', id: string, email: string | null, fullName: string, firstName: string, lastName: string, onboardingSeen: boolean }, sheet: { __typename?: 'Sheet', id: string, rawContent: string | null, releaseTimestamp: string, nextReleaseTimestamp: string | null, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean, resources: Array<{ __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null }> } | null } };

export type OnSheetReleasedSubscriptionVariables = Exact<{
  assessmentId: Scalars['ID'];
  workId: Scalars['ID'];
}>;


export type OnSheetReleasedSubscription = { __typename?: 'RootSubscriptionType', sheetReleased: { __typename?: 'Sheet', id: string, rawContent: string | null, releaseTimestamp: string, nextReleaseTimestamp: string | null, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean, resources: Array<{ __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null }> } };

export type DraftSubmissionQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type DraftSubmissionQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, submission: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType, save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } } | null } };

export type UpdateExperienceMutationVariables = Exact<{
  workId: Scalars['ID'];
  score: Scalars['Int'];
}>;


export type UpdateExperienceMutation = { __typename?: 'RootMutationType', updateExperience: { __typename?: 'Survey', id: string, score: number | null, active: boolean } };

export type ExperienceQueryVariables = Exact<{
  workId: Scalars['ID'];
  assessmentId: Scalars['ID'];
}>;


export type ExperienceQuery = { __typename?: 'RootQueryType', assessment: { __typename?: 'Assessment', id: string, subjectId: string }, work: { __typename?: 'Work', id: string, experience: { __typename?: 'Survey', id: string, score: number | null, active: boolean }, sheet: { __typename?: 'Sheet', assessmentType: AssessmentType } | null } };

export type FinalSubmissionQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type FinalSubmissionQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, submission: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType, save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } } | null } };

export type LatestResultQueryVariables = Exact<{
  workId: Scalars['ID'];
  type: SubmissionType;
}>;


export type LatestResultQuery = { __typename?: 'RootQueryType', latestResult: { __typename?: 'Result', id: string, heraResultId: number | null, canvasResultUrl: string | null, grade: { __typename?: 'Grade', display: string, max: number, value: number } | null, similarity: { __typename?: 'Similarity', display: string, label: string, value: number } | null } };

export type OnResultUpdatedSubscriptionVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type OnResultUpdatedSubscription = { __typename?: 'RootSubscriptionType', workResultUpdated: { __typename?: 'Result', id: string, heraResultId: number | null, canvasResultUrl: string | null, grade: { __typename?: 'Grade', display: string, max: number, value: number } | null, similarity: { __typename?: 'Similarity', display: string, label: string, value: number } | null } };

export type LatestSaveQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type LatestSaveQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', latestSave: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } | null } };

export type RequirementsQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type RequirementsQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, sheet: { __typename?: 'Sheet', id: string, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, nextReleaseTimestamp: string | null, releaseTimestamp: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean } | null } };

export type ResultFragment = { __typename?: 'Result', id: string, heraResultId: number | null, canvasResultUrl: string | null, grade: { __typename?: 'Grade', display: string, max: number, value: number } | null, similarity: { __typename?: 'Similarity', display: string, label: string, value: number } | null };

export type SaveFragment = { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string };

export type SaveMutationVariables = Exact<{
  workId: Scalars['ID'];
  content: Scalars['String'];
  dispatchedAt: Scalars['DateTime'];
  metadata: InputMaybe<SaveMetadataInput>;
  editor: InputMaybe<EditorType>;
}>;


export type SaveMutation = { __typename?: 'RootMutationType', save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } };

export type StartMutationVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type StartMutation = { __typename?: 'RootMutationType', start: { __typename?: 'Work', startDate: string | null } };

export type SubmissionFragment = { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType, save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } };

export type SubmissionMetadataFragment = { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType };

export type SubmitMutationVariables = Exact<{
  workId: Scalars['ID'];
  saveId: Scalars['ID'];
  type: SubmissionType;
  submittedAt: Scalars['DateTime'];
  acceptedDeclaration: Scalars['Boolean'];
  sessionId: InputMaybe<Scalars['String']>;
  editor: InputMaybe<EditorType>;
}>;


export type SubmitMutation = { __typename?: 'RootMutationType', submit: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType, save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } } };

export type UnsubmitMutationVariables = Exact<{
  workId: Scalars['ID'];
  submissionId: Scalars['ID'];
}>;


export type UnsubmitMutation = { __typename?: 'RootMutationType', unsubmit: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType, save: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } } };

export type WorkDueDateQueryVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type WorkDueDateQuery = { __typename?: 'RootQueryType', work: { __typename?: 'Work', id: string, dueDate: string | null } };

export type WorkQueryVariables = Exact<{
  assessmentId: Scalars['ID'];
  workId: Scalars['ID'];
}>;


export type WorkQuery = { __typename?: 'RootQueryType', token: string, sessionId: string, assessment: { __typename?: 'Assessment', id: string, name: string, subjectName: string }, work: { __typename?: 'Work', id: string, startDate: string | null, student: { __typename?: 'User', id: string, email: string | null, fullName: string, firstName: string, lastName: string, onboardingSeen: boolean }, latestSave: { __typename?: 'Save', id: string, serverId: string, version: string, content: string, dispatchedAt: string } | null, draft: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType } | null, final: { __typename?: 'Submission', id: string, submittedAt: string, unsubmittedAt: string | null, type: SubmissionType } | null, settings: { __typename?: 'WorkSettings', id: string, dueDate: string | null, timeLimit: number | null, examStartDate: string | null, examEndDate: string | null, examReadingTime: number | null, examWritingTime: number | null, examDeferred: boolean | null } | null, sheet: { __typename?: 'Sheet', id: string, rawContent: string | null, releaseTimestamp: string, nextReleaseTimestamp: string | null, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean, resources: Array<{ __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null }> } | null } };

export type WorkSettingsFragment = { __typename?: 'WorkSettings', id: string, dueDate: string | null, timeLimit: number | null, examStartDate: string | null, examEndDate: string | null, examReadingTime: number | null, examWritingTime: number | null, examDeferred: boolean | null };

export type OnWorkSettingsUpdatedSubscriptionVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type OnWorkSettingsUpdatedSubscription = { __typename?: 'RootSubscriptionType', workSettingsUpdated: { __typename?: 'WorkSettings', id: string, dueDate: string | null, timeLimit: number | null, examStartDate: string | null, examEndDate: string | null, examReadingTime: number | null, examWritingTime: number | null, examDeferred: boolean | null } };

export type OnWorkStartedSubscriptionVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type OnWorkStartedSubscription = { __typename?: 'RootSubscriptionType', workStarted: { __typename?: 'Work', id: string, startDate: string | null, sheet: { __typename?: 'Sheet', id: string, rawContent: string | null, releaseTimestamp: string, nextReleaseTimestamp: string | null, assessmentType: AssessmentType, examTiming: ExamTiming, examReadingTime: number | null, examWritingTime: number | null, examStartDate: string | null, examEndDate: string | null, timeLimit: number | null, draftDueDate: string | null, dueDate: string, returnDate: string, allowLateResubmission: boolean, maxGrade: number, wordLimit: number | null, weight: number | null, referencingStyle: string | null, sViewReports: number, draftSViewReports: number, countReferences: boolean | null, gradingService: string, lateSubmissionTimeLimit: number | null, enableExamAutoSubmission: boolean, resources: Array<{ __typename?: 'Resource', id: string, name: string | null, url: string | null, notes: string | null, fileSize: number | null }> } | null } };

export type OnWorkUpdatedSubscriptionVariables = Exact<{
  workId: Scalars['ID'];
}>;


export type OnWorkUpdatedSubscription = { __typename?: 'RootSubscriptionType', workUpdated: { __typename?: 'Work', startDate: string | null } };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  fullName
  firstName
  lastName
  onboardingSeen
}
    `;
export const AssessmentFragmentDoc = gql`
    fragment Assessment on Assessment {
  id
  name
  subjectName
}
    `;
export const RequirementsFragmentDoc = gql`
    fragment Requirements on Sheet {
  id
  assessmentType
  examTiming
  examReadingTime
  examWritingTime
  examStartDate
  examEndDate
  timeLimit
  draftDueDate
  dueDate
  returnDate
  nextReleaseTimestamp
  releaseTimestamp
  allowLateResubmission
  maxGrade
  wordLimit
  weight
  referencingStyle
  sViewReports
  draftSViewReports
  countReferences
  gradingService
  lateSubmissionTimeLimit
  enableExamAutoSubmission
}
    `;
export const ResourceFragmentDoc = gql`
    fragment Resource on Resource {
  id
  name
  url
  notes
  fileSize
}
    `;
export const SheetFragmentDoc = gql`
    fragment Sheet on Sheet {
  id
  ...Requirements
  rawContent
  releaseTimestamp
  nextReleaseTimestamp
  resources {
    ...Resource
  }
}
    ${RequirementsFragmentDoc}
${ResourceFragmentDoc}`;
export const ResultFragmentDoc = gql`
    fragment Result on Result {
  id
  heraResultId
  canvasResultUrl
  grade {
    display
    max
    value
  }
  similarity {
    display
    label
    value
  }
}
    `;
export const SaveFragmentDoc = gql`
    fragment Save on Save {
  id
  serverId
  version
  content
  dispatchedAt
}
    `;
export const SubmissionFragmentDoc = gql`
    fragment Submission on Submission {
  id
  submittedAt
  unsubmittedAt
  type
  save {
    ...Save
  }
}
    ${SaveFragmentDoc}`;
export const SubmissionMetadataFragmentDoc = gql`
    fragment SubmissionMetadata on Submission {
  id
  submittedAt
  unsubmittedAt
  type
}
    `;
export const WorkSettingsFragmentDoc = gql`
    fragment WorkSettings on WorkSettings {
  id
  dueDate
  timeLimit
  examStartDate
  examEndDate
  examReadingTime
  examWritingTime
  examDeferred
}
    `;
export const UpdateUserDocument = gql`
    mutation UpdateUser($firstName: String!, $lastName: String!, $onboardingSeen: String!) {
  updateUser(
    firstName: $firstName
    lastName: $lastName
    onboardingSeen: $onboardingSeen
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      onboardingSeen: // value for 'onboardingSeen'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DueDatesDocument = gql`
    query DueDates($workId: ID!) {
  work(workId: $workId) {
    id
    startDate
    settings {
      id
      dueDate
      timeLimit
    }
    sheet {
      ...Requirements
    }
  }
}
    ${RequirementsFragmentDoc}`;

/**
 * __useDueDatesQuery__
 *
 * To run a query within a React component, call `useDueDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDueDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDueDatesQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useDueDatesQuery(baseOptions: Apollo.QueryHookOptions<DueDatesQuery, DueDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DueDatesQuery, DueDatesQueryVariables>(DueDatesDocument, options);
      }
export function useDueDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DueDatesQuery, DueDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DueDatesQuery, DueDatesQueryVariables>(DueDatesDocument, options);
        }
export type DueDatesQueryHookResult = ReturnType<typeof useDueDatesQuery>;
export type DueDatesLazyQueryHookResult = ReturnType<typeof useDueDatesLazyQuery>;
export type DueDatesQueryResult = Apollo.QueryResult<DueDatesQuery, DueDatesQueryVariables>;
export const OnFutureReleaseScheduleDocument = gql`
    subscription onFutureReleaseSchedule($assessmentId: ID!) {
  futureReleaseSchedule(assessmentId: $assessmentId)
}
    `;

/**
 * __useOnFutureReleaseScheduleSubscription__
 *
 * To run a query within a React component, call `useOnFutureReleaseScheduleSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnFutureReleaseScheduleSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnFutureReleaseScheduleSubscription({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useOnFutureReleaseScheduleSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnFutureReleaseScheduleSubscription, OnFutureReleaseScheduleSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnFutureReleaseScheduleSubscription, OnFutureReleaseScheduleSubscriptionVariables>(OnFutureReleaseScheduleDocument, options);
      }
export type OnFutureReleaseScheduleSubscriptionHookResult = ReturnType<typeof useOnFutureReleaseScheduleSubscription>;
export type OnFutureReleaseScheduleSubscriptionResult = Apollo.SubscriptionResult<OnFutureReleaseScheduleSubscription>;
export const SheetDocument = gql`
    query Sheet($workId: ID!) {
  work(workId: $workId) {
    id
    student {
      ...User
    }
    sheet {
      ...Sheet
    }
  }
}
    ${UserFragmentDoc}
${SheetFragmentDoc}`;

/**
 * __useSheetQuery__
 *
 * To run a query within a React component, call `useSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSheetQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useSheetQuery(baseOptions: Apollo.QueryHookOptions<SheetQuery, SheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SheetQuery, SheetQueryVariables>(SheetDocument, options);
      }
export function useSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SheetQuery, SheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SheetQuery, SheetQueryVariables>(SheetDocument, options);
        }
export type SheetQueryHookResult = ReturnType<typeof useSheetQuery>;
export type SheetLazyQueryHookResult = ReturnType<typeof useSheetLazyQuery>;
export type SheetQueryResult = Apollo.QueryResult<SheetQuery, SheetQueryVariables>;
export const OnSheetReleasedDocument = gql`
    subscription onSheetReleased($assessmentId: ID!, $workId: ID!) {
  sheetReleased(assessmentId: $assessmentId, workId: $workId) {
    ...Sheet
  }
}
    ${SheetFragmentDoc}`;

/**
 * __useOnSheetReleasedSubscription__
 *
 * To run a query within a React component, call `useOnSheetReleasedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSheetReleasedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSheetReleasedSubscription({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useOnSheetReleasedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnSheetReleasedSubscription, OnSheetReleasedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnSheetReleasedSubscription, OnSheetReleasedSubscriptionVariables>(OnSheetReleasedDocument, options);
      }
export type OnSheetReleasedSubscriptionHookResult = ReturnType<typeof useOnSheetReleasedSubscription>;
export type OnSheetReleasedSubscriptionResult = Apollo.SubscriptionResult<OnSheetReleasedSubscription>;
export const DraftSubmissionDocument = gql`
    query DraftSubmission($workId: ID!) {
  work(workId: $workId) {
    id
    submission: draft {
      ...Submission
    }
  }
}
    ${SubmissionFragmentDoc}`;

/**
 * __useDraftSubmissionQuery__
 *
 * To run a query within a React component, call `useDraftSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftSubmissionQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useDraftSubmissionQuery(baseOptions: Apollo.QueryHookOptions<DraftSubmissionQuery, DraftSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftSubmissionQuery, DraftSubmissionQueryVariables>(DraftSubmissionDocument, options);
      }
export function useDraftSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftSubmissionQuery, DraftSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftSubmissionQuery, DraftSubmissionQueryVariables>(DraftSubmissionDocument, options);
        }
export type DraftSubmissionQueryHookResult = ReturnType<typeof useDraftSubmissionQuery>;
export type DraftSubmissionLazyQueryHookResult = ReturnType<typeof useDraftSubmissionLazyQuery>;
export type DraftSubmissionQueryResult = Apollo.QueryResult<DraftSubmissionQuery, DraftSubmissionQueryVariables>;
export const UpdateExperienceDocument = gql`
    mutation UpdateExperience($workId: ID!, $score: Int!) {
  updateExperience(workId: $workId, score: $score) {
    id
    score
    active
  }
}
    `;
export type UpdateExperienceMutationFn = Apollo.MutationFunction<UpdateExperienceMutation, UpdateExperienceMutationVariables>;

/**
 * __useUpdateExperienceMutation__
 *
 * To run a mutation, you first call `useUpdateExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperienceMutation, { data, loading, error }] = useUpdateExperienceMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *      score: // value for 'score'
 *   },
 * });
 */
export function useUpdateExperienceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExperienceMutation, UpdateExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExperienceMutation, UpdateExperienceMutationVariables>(UpdateExperienceDocument, options);
      }
export type UpdateExperienceMutationHookResult = ReturnType<typeof useUpdateExperienceMutation>;
export type UpdateExperienceMutationResult = Apollo.MutationResult<UpdateExperienceMutation>;
export type UpdateExperienceMutationOptions = Apollo.BaseMutationOptions<UpdateExperienceMutation, UpdateExperienceMutationVariables>;
export const ExperienceDocument = gql`
    query Experience($workId: ID!, $assessmentId: ID!) {
  assessment(assessmentId: $assessmentId) {
    id
    subjectId
  }
  work(workId: $workId) {
    id
    experience {
      id
      score
      active
    }
    sheet {
      assessmentType
    }
  }
}
    `;

/**
 * __useExperienceQuery__
 *
 * To run a query within a React component, call `useExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *      assessmentId: // value for 'assessmentId'
 *   },
 * });
 */
export function useExperienceQuery(baseOptions: Apollo.QueryHookOptions<ExperienceQuery, ExperienceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, options);
      }
export function useExperienceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExperienceQuery, ExperienceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExperienceQuery, ExperienceQueryVariables>(ExperienceDocument, options);
        }
export type ExperienceQueryHookResult = ReturnType<typeof useExperienceQuery>;
export type ExperienceLazyQueryHookResult = ReturnType<typeof useExperienceLazyQuery>;
export type ExperienceQueryResult = Apollo.QueryResult<ExperienceQuery, ExperienceQueryVariables>;
export const FinalSubmissionDocument = gql`
    query FinalSubmission($workId: ID!) {
  work(workId: $workId) {
    id
    submission: final {
      ...Submission
    }
  }
}
    ${SubmissionFragmentDoc}`;

/**
 * __useFinalSubmissionQuery__
 *
 * To run a query within a React component, call `useFinalSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinalSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinalSubmissionQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useFinalSubmissionQuery(baseOptions: Apollo.QueryHookOptions<FinalSubmissionQuery, FinalSubmissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinalSubmissionQuery, FinalSubmissionQueryVariables>(FinalSubmissionDocument, options);
      }
export function useFinalSubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinalSubmissionQuery, FinalSubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinalSubmissionQuery, FinalSubmissionQueryVariables>(FinalSubmissionDocument, options);
        }
export type FinalSubmissionQueryHookResult = ReturnType<typeof useFinalSubmissionQuery>;
export type FinalSubmissionLazyQueryHookResult = ReturnType<typeof useFinalSubmissionLazyQuery>;
export type FinalSubmissionQueryResult = Apollo.QueryResult<FinalSubmissionQuery, FinalSubmissionQueryVariables>;
export const LatestResultDocument = gql`
    query LatestResult($workId: ID!, $type: SubmissionType!) {
  latestResult(workId: $workId, submissionType: $type) {
    ...Result
  }
}
    ${ResultFragmentDoc}`;

/**
 * __useLatestResultQuery__
 *
 * To run a query within a React component, call `useLatestResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestResultQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLatestResultQuery(baseOptions: Apollo.QueryHookOptions<LatestResultQuery, LatestResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestResultQuery, LatestResultQueryVariables>(LatestResultDocument, options);
      }
export function useLatestResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestResultQuery, LatestResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestResultQuery, LatestResultQueryVariables>(LatestResultDocument, options);
        }
export type LatestResultQueryHookResult = ReturnType<typeof useLatestResultQuery>;
export type LatestResultLazyQueryHookResult = ReturnType<typeof useLatestResultLazyQuery>;
export type LatestResultQueryResult = Apollo.QueryResult<LatestResultQuery, LatestResultQueryVariables>;
export const OnResultUpdatedDocument = gql`
    subscription onResultUpdated($workId: ID!) {
  workResultUpdated(workId: $workId) {
    ...Result
  }
}
    ${ResultFragmentDoc}`;

/**
 * __useOnResultUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnResultUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResultUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResultUpdatedSubscription({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useOnResultUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnResultUpdatedSubscription, OnResultUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResultUpdatedSubscription, OnResultUpdatedSubscriptionVariables>(OnResultUpdatedDocument, options);
      }
export type OnResultUpdatedSubscriptionHookResult = ReturnType<typeof useOnResultUpdatedSubscription>;
export type OnResultUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnResultUpdatedSubscription>;
export const LatestSaveDocument = gql`
    query LatestSave($workId: ID!) {
  work(workId: $workId) {
    latestSave {
      ...Save
    }
  }
}
    ${SaveFragmentDoc}`;

/**
 * __useLatestSaveQuery__
 *
 * To run a query within a React component, call `useLatestSaveQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestSaveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestSaveQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useLatestSaveQuery(baseOptions: Apollo.QueryHookOptions<LatestSaveQuery, LatestSaveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LatestSaveQuery, LatestSaveQueryVariables>(LatestSaveDocument, options);
      }
export function useLatestSaveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LatestSaveQuery, LatestSaveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LatestSaveQuery, LatestSaveQueryVariables>(LatestSaveDocument, options);
        }
export type LatestSaveQueryHookResult = ReturnType<typeof useLatestSaveQuery>;
export type LatestSaveLazyQueryHookResult = ReturnType<typeof useLatestSaveLazyQuery>;
export type LatestSaveQueryResult = Apollo.QueryResult<LatestSaveQuery, LatestSaveQueryVariables>;
export const RequirementsDocument = gql`
    query Requirements($workId: ID!) {
  work(workId: $workId) {
    id
    sheet {
      id
      ...Requirements
    }
  }
}
    ${RequirementsFragmentDoc}`;

/**
 * __useRequirementsQuery__
 *
 * To run a query within a React component, call `useRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequirementsQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useRequirementsQuery(baseOptions: Apollo.QueryHookOptions<RequirementsQuery, RequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequirementsQuery, RequirementsQueryVariables>(RequirementsDocument, options);
      }
export function useRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequirementsQuery, RequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequirementsQuery, RequirementsQueryVariables>(RequirementsDocument, options);
        }
export type RequirementsQueryHookResult = ReturnType<typeof useRequirementsQuery>;
export type RequirementsLazyQueryHookResult = ReturnType<typeof useRequirementsLazyQuery>;
export type RequirementsQueryResult = Apollo.QueryResult<RequirementsQuery, RequirementsQueryVariables>;
export const SaveDocument = gql`
    mutation Save($workId: ID!, $content: String!, $dispatchedAt: DateTime!, $metadata: SaveMetadataInput, $editor: EditorType) {
  save(
    editor: $editor
    workId: $workId
    content: $content
    dispatchedAt: $dispatchedAt
    metadata: $metadata
  ) {
    id
    serverId
    version
    content
    dispatchedAt
  }
}
    `;
export type SaveMutationFn = Apollo.MutationFunction<SaveMutation, SaveMutationVariables>;

/**
 * __useSaveMutation__
 *
 * To run a mutation, you first call `useSaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMutation, { data, loading, error }] = useSaveMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *      content: // value for 'content'
 *      dispatchedAt: // value for 'dispatchedAt'
 *      metadata: // value for 'metadata'
 *      editor: // value for 'editor'
 *   },
 * });
 */
export function useSaveMutation(baseOptions?: Apollo.MutationHookOptions<SaveMutation, SaveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMutation, SaveMutationVariables>(SaveDocument, options);
      }
export type SaveMutationHookResult = ReturnType<typeof useSaveMutation>;
export type SaveMutationResult = Apollo.MutationResult<SaveMutation>;
export type SaveMutationOptions = Apollo.BaseMutationOptions<SaveMutation, SaveMutationVariables>;
export const StartDocument = gql`
    mutation Start($workId: ID!) {
  start(workId: $workId) {
    startDate
  }
}
    `;
export type StartMutationFn = Apollo.MutationFunction<StartMutation, StartMutationVariables>;

/**
 * __useStartMutation__
 *
 * To run a mutation, you first call `useStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMutation, { data, loading, error }] = useStartMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useStartMutation(baseOptions?: Apollo.MutationHookOptions<StartMutation, StartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartMutation, StartMutationVariables>(StartDocument, options);
      }
export type StartMutationHookResult = ReturnType<typeof useStartMutation>;
export type StartMutationResult = Apollo.MutationResult<StartMutation>;
export type StartMutationOptions = Apollo.BaseMutationOptions<StartMutation, StartMutationVariables>;
export const SubmitDocument = gql`
    mutation Submit($workId: ID!, $saveId: ID!, $type: SubmissionType!, $submittedAt: DateTime!, $acceptedDeclaration: Boolean!, $sessionId: String, $editor: EditorType) {
  submit(
    editor: $editor
    workId: $workId
    saveId: $saveId
    type: $type
    submittedAt: $submittedAt
    acceptedDeclaration: $acceptedDeclaration
    sessionId: $sessionId
  ) {
    ...Submission
  }
}
    ${SubmissionFragmentDoc}`;
export type SubmitMutationFn = Apollo.MutationFunction<SubmitMutation, SubmitMutationVariables>;

/**
 * __useSubmitMutation__
 *
 * To run a mutation, you first call `useSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitMutation, { data, loading, error }] = useSubmitMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *      saveId: // value for 'saveId'
 *      type: // value for 'type'
 *      submittedAt: // value for 'submittedAt'
 *      acceptedDeclaration: // value for 'acceptedDeclaration'
 *      sessionId: // value for 'sessionId'
 *      editor: // value for 'editor'
 *   },
 * });
 */
export function useSubmitMutation(baseOptions?: Apollo.MutationHookOptions<SubmitMutation, SubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitMutation, SubmitMutationVariables>(SubmitDocument, options);
      }
export type SubmitMutationHookResult = ReturnType<typeof useSubmitMutation>;
export type SubmitMutationResult = Apollo.MutationResult<SubmitMutation>;
export type SubmitMutationOptions = Apollo.BaseMutationOptions<SubmitMutation, SubmitMutationVariables>;
export const UnsubmitDocument = gql`
    mutation Unsubmit($workId: ID!, $submissionId: ID!) {
  unsubmit(workId: $workId, submissionId: $submissionId) {
    ...Submission
  }
}
    ${SubmissionFragmentDoc}`;
export type UnsubmitMutationFn = Apollo.MutationFunction<UnsubmitMutation, UnsubmitMutationVariables>;

/**
 * __useUnsubmitMutation__
 *
 * To run a mutation, you first call `useUnsubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubmitMutation, { data, loading, error }] = useUnsubmitMutation({
 *   variables: {
 *      workId: // value for 'workId'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useUnsubmitMutation(baseOptions?: Apollo.MutationHookOptions<UnsubmitMutation, UnsubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubmitMutation, UnsubmitMutationVariables>(UnsubmitDocument, options);
      }
export type UnsubmitMutationHookResult = ReturnType<typeof useUnsubmitMutation>;
export type UnsubmitMutationResult = Apollo.MutationResult<UnsubmitMutation>;
export type UnsubmitMutationOptions = Apollo.BaseMutationOptions<UnsubmitMutation, UnsubmitMutationVariables>;
export const WorkDueDateDocument = gql`
    query WorkDueDate($workId: ID!) {
  work(workId: $workId) {
    id
    dueDate
  }
}
    `;

/**
 * __useWorkDueDateQuery__
 *
 * To run a query within a React component, call `useWorkDueDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkDueDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkDueDateQuery({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useWorkDueDateQuery(baseOptions: Apollo.QueryHookOptions<WorkDueDateQuery, WorkDueDateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkDueDateQuery, WorkDueDateQueryVariables>(WorkDueDateDocument, options);
      }
export function useWorkDueDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkDueDateQuery, WorkDueDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkDueDateQuery, WorkDueDateQueryVariables>(WorkDueDateDocument, options);
        }
export type WorkDueDateQueryHookResult = ReturnType<typeof useWorkDueDateQuery>;
export type WorkDueDateLazyQueryHookResult = ReturnType<typeof useWorkDueDateLazyQuery>;
export type WorkDueDateQueryResult = Apollo.QueryResult<WorkDueDateQuery, WorkDueDateQueryVariables>;
export const WorkDocument = gql`
    query Work($assessmentId: ID!, $workId: ID!) {
  token
  sessionId
  assessment(assessmentId: $assessmentId) {
    ...Assessment
  }
  work(workId: $workId) {
    id
    student {
      ...User
    }
    latestSave {
      ...Save
    }
    draft {
      ...SubmissionMetadata
    }
    final {
      ...SubmissionMetadata
    }
    startDate
    settings {
      ...WorkSettings
    }
    sheet {
      ...Sheet
    }
  }
}
    ${AssessmentFragmentDoc}
${UserFragmentDoc}
${SaveFragmentDoc}
${SubmissionMetadataFragmentDoc}
${WorkSettingsFragmentDoc}
${SheetFragmentDoc}`;

/**
 * __useWorkQuery__
 *
 * To run a query within a React component, call `useWorkQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkQuery({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useWorkQuery(baseOptions: Apollo.QueryHookOptions<WorkQuery, WorkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkQuery, WorkQueryVariables>(WorkDocument, options);
      }
export function useWorkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkQuery, WorkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkQuery, WorkQueryVariables>(WorkDocument, options);
        }
export type WorkQueryHookResult = ReturnType<typeof useWorkQuery>;
export type WorkLazyQueryHookResult = ReturnType<typeof useWorkLazyQuery>;
export type WorkQueryResult = Apollo.QueryResult<WorkQuery, WorkQueryVariables>;
export const OnWorkSettingsUpdatedDocument = gql`
    subscription onWorkSettingsUpdated($workId: ID!) {
  workSettingsUpdated(workId: $workId) {
    ...WorkSettings
  }
}
    ${WorkSettingsFragmentDoc}`;

/**
 * __useOnWorkSettingsUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnWorkSettingsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWorkSettingsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWorkSettingsUpdatedSubscription({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useOnWorkSettingsUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWorkSettingsUpdatedSubscription, OnWorkSettingsUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWorkSettingsUpdatedSubscription, OnWorkSettingsUpdatedSubscriptionVariables>(OnWorkSettingsUpdatedDocument, options);
      }
export type OnWorkSettingsUpdatedSubscriptionHookResult = ReturnType<typeof useOnWorkSettingsUpdatedSubscription>;
export type OnWorkSettingsUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnWorkSettingsUpdatedSubscription>;
export const OnWorkStartedDocument = gql`
    subscription onWorkStarted($workId: ID!) {
  workStarted(workId: $workId) {
    id
    startDate
    sheet {
      ...Sheet
    }
  }
}
    ${SheetFragmentDoc}`;

/**
 * __useOnWorkStartedSubscription__
 *
 * To run a query within a React component, call `useOnWorkStartedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWorkStartedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWorkStartedSubscription({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useOnWorkStartedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWorkStartedSubscription, OnWorkStartedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWorkStartedSubscription, OnWorkStartedSubscriptionVariables>(OnWorkStartedDocument, options);
      }
export type OnWorkStartedSubscriptionHookResult = ReturnType<typeof useOnWorkStartedSubscription>;
export type OnWorkStartedSubscriptionResult = Apollo.SubscriptionResult<OnWorkStartedSubscription>;
export const OnWorkUpdatedDocument = gql`
    subscription onWorkUpdated($workId: ID!) {
  workUpdated(workId: $workId) {
    startDate
  }
}
    `;

/**
 * __useOnWorkUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnWorkUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnWorkUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnWorkUpdatedSubscription({
 *   variables: {
 *      workId: // value for 'workId'
 *   },
 * });
 */
export function useOnWorkUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnWorkUpdatedSubscription, OnWorkUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnWorkUpdatedSubscription, OnWorkUpdatedSubscriptionVariables>(OnWorkUpdatedDocument, options);
      }
export type OnWorkUpdatedSubscriptionHookResult = ReturnType<typeof useOnWorkUpdatedSubscription>;
export type OnWorkUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnWorkUpdatedSubscription>;