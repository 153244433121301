import { findParentNode, isNodeSelection } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { EditorState, NodeSelection } from "@tiptap/pm/state";

// Return type for `getImageSelection`
interface ImageSelection {
  node: PMNode;
  selection: NodeSelection;
  from: number;
}
/**
 * Get selected image node, its `NodeSelection` and the start position of
 * the node
 */
export function getImageSelection(state: EditorState): ImageSelection | null {
  const { selection } = state;
  const { $from, $to } = state.selection;

  let nodeSelection: NodeSelection | undefined;
  let from: number | undefined;

  const imagePredicate = (node: PMNode) =>
    node.type === state.schema.nodes.image;

  if (
    isNodeSelection(selection) &&
    selection.node.type === state.schema.nodes.image
  ) {
    nodeSelection = selection as NodeSelection;
    from = $from.start($from.depth + 1) - 1;
  }

  const parent = findParentNode(imagePredicate)(selection);
  if ($from.node() === $to.node() && parent) {
    nodeSelection = NodeSelection.create(state.doc, parent.pos);
    from = parent.pos;
  }

  if (nodeSelection && from !== undefined) {
    return {
      node: nodeSelection.node,
      selection: nodeSelection,
      from,
    };
  }

  return null;
}
