import { ReactNode, useCallback, useLayoutEffect } from "react";
import {
  ComputePositionConfig,
  FloatingNode,
  FloatingPortal,
  FloatingTree,
  autoUpdate,
  flip,
  hide,
  offset,
  shift,
  useClick,
  useFloating,
  useFloatingNodeId,
  useFocus,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { Editor, isNodeSelection, posToDOMRect } from "@tiptap/core";

import {
  UseFloatingMenuOpenStateProps,
  useFloatingMenuOpenState,
} from "./useFloatingMenuOpenState";

export interface FloatingMenuComponentProps
  extends UseFloatingMenuOpenStateProps,
    Partial<ComputePositionConfig> {
  /**
   * DOMRect getters to where the reference element is(can be `Virtual Element`) to
   * be used by the floating menu for positioning itself
   */
  getBoundingClientRect?: () => DOMRect;
  /**
   * id of the `HTMLElement` that is used for mounting point for `<FloatingPortal/>`
   */
  portalId: string;
  /**
   * Set main axis offset in pixels
   * @default 8(px)
   */
  mainAxisOffset?: number;
  /**
   * Set placement of floating menu
   * @default "bottom"
   */
  placement?: ComputePositionConfig["placement"];
  /**
   * React children as menu contents.
   */
  children: ReactNode;
  onClose?: (editor: Editor) => void;
}

/**
 * React component to display floating contents on a target position in the DOM.
 */
export function FloatingMenuComponent(props: FloatingMenuComponentProps) {
  const {
    editor,
    getBoundingClientRect,
    portalId,
    mainAxisOffset = 8,
    placement = "bottom",
    ...rest
  } = props;
  const [open, setOpen] = useFloatingMenuOpenState(props);

  const nodeId = useFloatingNodeId();
  const { x, y, reference, floating, strategy, context } = useFloating({
    whileElementsMounted: (referenceEl, floatingEl, update) =>
      autoUpdate(referenceEl, floatingEl, update, { animationFrame: true }),
    open,
    onOpenChange: setOpen,
    nodeId,
    // @ts-ignore
    // for some unknown reason the type got mixed up here but it is working
    middleware: [offset(mainAxisOffset), shift(), flip(), hide()],
    placement,
    ...rest,
  });
  const { getFloatingProps } = useInteractions([
    useFocus(context),
    useRole(context, { role: "menu" }),
    useClick(context),
  ]);
  const defaultGetBoundingClientRect = useCallback(() => {
    const { state, view } = editor;
    const { selection } = state;
    // support for CellSelections
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));
    if (isNodeSelection(selection)) {
      const node = view.nodeDOM(from) as HTMLElement;

      if (node) {
        return node.getBoundingClientRect();
      }
    }

    return posToDOMRect(view, from, to);
  }, [editor]);
  useLayoutEffect(() => {
    // Call reference with the virtual element inside an effect
    // or event handler.
    reference({
      getBoundingClientRect:
        getBoundingClientRect ?? defaultGetBoundingClientRect,
    });
  }, [reference, editor, getBoundingClientRect, defaultGetBoundingClientRect]);
  return (
    <FloatingTree>
      <FloatingNode id={nodeId}>
        <FloatingPortal id={portalId}>
          {open && (
            <div
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1000,
              }}
              ref={floating}
              {...getFloatingProps()}
            >
              {props.children}
            </div>
          )}
        </FloatingPortal>
      </FloatingNode>
    </FloatingTree>
  );
}
