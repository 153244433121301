import { useMemo } from "react";
import {
  CadmusEditorContent,
  useCadmusEditor,
} from "@vericus/cadmus-editor-prosemirror";
import styled from "styled-components";

import { EditorType } from "@/generated/graphql";
import { deserialiseEditor } from "@/stores/editors/deserialiseWorkEditors";

// Props for SheetContent
interface Props {
  /**
   * Serialised editor contents from the Instruction Sheet.
   */
  content: string;
}

/**
 * React component rendering the Sheet contents as a readonly editor.
 */
export function SheetContent(props: Props) {
  const { content } = props;

  // XXX If content failed to parse, how should we handle it?
  const editorContent = useMemo(() => {
    const { content: editorState, editor } = JSON.parse(content);
    if (editor === EditorType.Prosemirror) {
      return editorState;
    }
    return deserialiseEditor(editorState);
  }, [content]);

  const editor = useCadmusEditor({
    editorId: "instructions",
    content: editorContent,
    editable: false,
  });

  return <InstructionsEditor editor={editor} />;
}

const InstructionsEditor = styled(CadmusEditorContent)`
  &:after {
    display: none;
  }
`;
