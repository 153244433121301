import { forwardRef } from "react";
import { NodeViewProps } from "@tiptap/react";
import { ControlButton, Menu, MenuItem, Popover } from "@vericus/cadmus-ui";
import { NodeSelection } from "@tiptap/pm/state";

interface Props extends NodeViewProps {
  setCaptionInputOpen: (state: boolean) => void;
  onOpenChange: (open: boolean) => void;
}

/**
 * Component for a dropdown menu with extra math block edit options.
 */
export const OptionsMenu = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { editor, getPos, setCaptionInputOpen, onOpenChange, node } = props;
    return (
      <Popover
        render={(data) => (
          <Menu
            aria-label={data.labelId}
            style={{ zIndex: 300, position: "relative" }}
          >
            <MenuItem
              iconName="Edit"
              content="Edit equation"
              onClick={() => {
                editor
                  .chain()
                  .command(({ tr, dispatch }) => {
                    dispatch?.(
                      tr.setSelection(NodeSelection.create(tr.doc, getPos()))
                    );
                    return true;
                  })
                  .maybeOpenEquationMenu()
                  .run();
                data.close();
              }}
            />
            <MenuItem
              iconName="QuoteOpen"
              content={`${
                node.attrs.caption === "" ? "Add" : "Edit"
              } reference`}
              onClick={() => {
                setCaptionInputOpen(true);
                editor.commands.setNodeSelection(getPos());
                data.close();
              }}
            />
            <MenuItem
              iconName="Trash"
              content="Delete"
              onMouseEnter={() =>
                editor.commands.predeleteHighlightEquation(getPos(), true)
              }
              onMouseLeave={() =>
                editor.commands.predeleteHighlightEquation(getPos(), false)
              }
              onClick={() =>
                editor.chain().deleteEquation(getPos()).focus().run()
              }
            />
          </Menu>
        )}
        placement="bottom-end"
        onOpenChange={onOpenChange}
      >
        <ControlButton iconName="More" ref={ref} />
      </Popover>
    );
  }
);
