/** Open a hyperlink in a new tab. */
export function openLink(href: string) {
  const link = /https?/iu.test(href) ? href : `//${href}`;
  try {
    window.open(link, "_blank");
  } catch (e: unknown) {
    // e is SyntaxError when invalid
    // https://html.spec.whatwg.org/multipage/nav-history-apis.html#dom-open-dev
    if (e instanceof SyntaxError) {
      // fail silently when the link is invalid
      return;
    }
    throw e;
  }
}
