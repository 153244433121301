import styled from "styled-components";

/**
 * A utility component to create vertical spacing between components.
 *
 * A block component built with **padding**, so it will not overlap with margin.
 */
export const Spacer = styled.div<{ spacing: number }>`
  margin-top: ${p => p.spacing}px;
  display: block;
`;
