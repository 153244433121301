import { Command } from "@tiptap/core";
import { NodeType, NodeRange, Slice, Fragment } from "@tiptap/pm/model";
import { EditorState, Transaction } from "@tiptap/pm/state";
import { ReplaceAroundStep } from "@tiptap/pm/transform";

/**
 * Build a command to lift the content inside a list item around the selection
 * out of list
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function liftListItemOutOfList(itemType: NodeType): Command {
  return ({ tr, dispatch, state }) => {
    const { selection } = tr;
    const { $from, $to } = selection;
    const range = $from.blockRange(
      $to,
      (node) => node.firstChild?.type === itemType
    );
    if (!range) return false;
    if (!dispatch) return true;
    return liftOutOfList(state, dispatch, range);
  };
}

// Copied from `prosemirror-schema-list`
export function liftOutOfList(
  state: EditorState,
  dispatch: (tr: Transaction) => void,
  range: NodeRange
) {
  const tr = state.tr,
    list = range.parent;

  const originMappingLength = tr.mapping.maps.length;

  // Merge the list items into a single big item
  for (
    let pos = range.end, i = range.endIndex - 1, e = range.startIndex;
    i > e;
    i--
  ) {
    pos -= list.child(i).nodeSize;
    tr.delete(pos - 1, pos + 1);
  }

  const $start = tr.doc.resolve(range.start),
    item = $start.nodeAfter;

  if (!item) {
    return false;
  }

  if (
    tr.mapping.slice(originMappingLength).map(range.end) !==
    range.start + item.nodeSize
  ) {
    return false;
  }

  const atStart = range.startIndex === 0,
    atEnd = range.endIndex === list.childCount;
  const parent = $start.node(-1),
    indexBefore = $start.index(-1);

  if (
    !parent.canReplace(
      indexBefore + (atStart ? 0 : 1),
      indexBefore + 1,
      item.content.append(atEnd ? Fragment.empty : Fragment.from(list))
    )
  ) {
    return false;
  }

  const start = $start.pos,
    end = start + item.nodeSize;
  // Strip off the surrounding list. At the sides where we're not at
  // the end of the list, the existing list is closed. At sides where
  // this is the end, it is overwritten to its end.
  tr.step(
    new ReplaceAroundStep(
      start - (atStart ? 1 : 0),
      end + (atEnd ? 1 : 0),
      start + 1,
      end - 1,
      new Slice(
        (atStart
          ? Fragment.empty
          : Fragment.from(list.copy(Fragment.empty))
        ).append(
          atEnd ? Fragment.empty : Fragment.from(list.copy(Fragment.empty))
        ),
        atStart ? 0 : 1,
        atEnd ? 0 : 1
      ),
      atStart ? 0 : 1
    )
  );
  dispatch(tr.scrollIntoView());
  return true;
}
