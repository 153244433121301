import { levels } from "@vericus/cadmus-ui";
import styled from "styled-components";

export const ButtonGroups = styled.div`
  background: white;
  box-shadow: ${levels.two};
  display: inline-flex;
  align-items: center;
`;

export const GroupDivider = styled.div`
  &:not(:last-child) {
    display: inline-flex;
    width: 1px;
    height: 20px;
    background: rgba(89, 115, 166, 0.15);
    margin: 7px 2px;
  }
`;
