import { FileCard, LinkCard, getFileType } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { ResourceFragment } from "generated/graphql";

import {
  formatFileSize,
  getExtension,
  isLink,
  removeExtension,
  useOpenResource,
} from "./resource-utils";

interface Props {
  resource: ResourceFragment;
  children?: JSX.Element | false;
}

export const ResourceListItem = ({ children, resource }: Props) => {
  const open = useOpenResource(resource);
  const component = isLink(resource) ? (
    <LinkCard viewOnly linkTitle={resource.name ?? ""} onOpenInBrowser={open} />
  ) : (
    <FileCard
      viewOnly
      fileInfo={{
        name: removeExtension(resource.name ?? ""),
        type: getFileType(resource.name ?? ""),
        size: resource.fileSize ? formatFileSize(resource.fileSize) : "",
        extension: getExtension(resource.name ?? ""),
      }}
      onOpenInBrowser={open}
    />
  );
  return (
    <Container tabIndex={0}>
      {component}
      {children}
    </Container>
  );
};

const Container = styled.div`
  box-shadow: 0px 0px 1px rgba(71, 92, 133, 0.36),
    0px 1px 3px rgba(71, 92, 133, 0.15);
`;
