import { RefObject } from "react";
import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { TableMap } from "@tiptap/pm/tables";
import { colors } from "@vericus/cadmus-ui";
import styled, { css } from "styled-components";

import { useTableControl } from "./useTableControl";

export interface TableControlProps {
  editor: Editor;
  tableRef: RefObject<HTMLTableElement | null>;
  node: PMNode;
  colNum: number;
  getPos: () => number;
}

/**
 * Table control component for selecting & inserting column/rows
 */
export function TableControl(props: TableControlProps) {
  const { editor, tableRef, node } = props;
  const {
    colWidths,
    rowHeights,
    tableRect,
    isEditing,
    isResizing,
    isSelected,
    predelete,
    selectedColumns,
    selectedRows,
  } = useTableControl(props);
  if (!tableRef.current || !tableRect || isResizing || !isEditing) return null;
  const { marginLeft, marginTop } = getComputedStyle(tableRef.current);
  return (
    <TableControlWrapper
      contentEditable={false}
      style={
        {
          "--tableTop": `${tableRect.top}px`,
          "--tableWidth": `${tableRect.width}px`,
          "--tableHeight": `${tableRect.height}px`,
          "--tableMarginTop": `${marginTop}`,
          "--tableMarginLeft": `${marginLeft}`,
        } as React.CSSProperties
      }
    >
      <TableSelectAll
        selected={isSelected}
        predelete={predelete}
        onMouseDown={(e) => {
          e.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
          editor.commands.selectTable();
        }}
      />
      <TableColumnControlGroup
        selectedColumns={selectedColumns}
        predelete={predelete}
        colWidths={colWidths}
        editor={editor}
      />
      <TableRowControlGroup
        selectedRows={selectedRows}
        predelete={predelete}
        rowHeights={rowHeights}
        editor={editor}
      />
      <TableInsertRowAfter
        onMouseDown={(e) => {
          e.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
          const map = TableMap.get(node);
          editor.commands.addRowAt(map.height);
        }}
        onMouseEnter={() => {
          const map = TableMap.get(node);
          editor.commands.preinsertRow(map.height);
        }}
        onMouseLeave={() => {
          editor.commands.preinsertRow();
        }}
      />
      <TableInsertColumnAfter
        onMouseDown={(e) => {
          e.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
          const map = TableMap.get(node);
          editor.commands.addColumnAt(map.width);
        }}
        onMouseEnter={() => {
          const map = TableMap.get(node);
          editor.commands.preinsertCol(map.width);
        }}
        onMouseLeave={() => {
          editor.commands.preinsertCol();
        }}
      />
    </TableControlWrapper>
  );
}

const TableControlWrapper = styled.div`
  position: absolute;
  width: calc(var(--tableWidth) + 36px);
  height: calc(var(--tableHeight) + 36px);
  top: calc(var(--tableMarginTop) - 38px);
  left: calc(var(--tableMarginLeft) - 18px);
`;

const TableSelectAll = styled.a<GripControlProps>`
  position: absolute;
  left: 21px;
  top: 21px;
  &::after {
    content: "";
    cursor: pointer;
    width: 10px;
    height: 10px;
    border: 1px solid #c3cddf;
    box-sizing: border-box;
    border-radius: 2px;
    position: absolute;
    top: -13px;
    left: -13px;
    background: ${(p) => getHandleColor(p)};
  }

  &:hover::after {
    background: ${colors.grey200};
  }
`;

const TableInsertColumnAfter = styled.a`
  position: absolute;
  top: 8px;
  width: 16px;
  font-size: 12px;
  left: calc(var(--tableWidth) + 20px);
  height: calc(var(--tableHeight) + 28px);
  &::after {
    content: "+";
    cursor: pointer;
    position: relative;
    color: ${colors.grey500};
    font-weight: 900;
    background: #f4f5f8;
    border: 1px solid #f4f5f8;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0px 2px 2px 0px;
    width: 100%;
    height: 100%;
  }

  &:hover::after {
    background: ${colors.indigo500};
    border: 1px solid ${colors.indigo500};
    color: white;
  }
`;

const TableInsertRowAfter = styled.a`
  position: absolute;
  height: 16px;
  font-size: 12px;
  left: 8px;
  top: calc(var(--tableHeight) + 20px);
  width: calc(var(--tableWidth) + 10px);
  &::after {
    content: "+";
    cursor: pointer;
    position: relative;
    color: ${colors.grey500};
    font-weight: 900;
    background: #f4f5f8;
    border: 1px solid #f4f5f8;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 0px 2px;
    width: 100%;
    height: 100%;
  }

  &:hover::after {
    background: ${colors.indigo500};
    border: 1px solid ${colors.indigo500};
    color: white;
  }
`;

interface GripControlProps {
  predelete: boolean;
  selected: boolean;
  first?: boolean;
  last?: boolean;
}

function getHandleColor(props: GripControlProps) {
  const { predelete, selected } = props;
  if (predelete && selected) return colors.red500;
  if (selected) return colors.indigo500;
  return "#f4f5f8";
}

interface TableColumnControlGroupProps {
  editor: Editor;
  colWidths?: number[];
  selectedColumns: number[];
  predelete: boolean;
}

/**
 * Table Column selection, insert Column before handle
 */
function TableColumnControlGroup(props: TableColumnControlGroupProps) {
  const { editor, colWidths, selectedColumns, predelete, ...rest } = props;
  if (!colWidths) return null;
  return (
    <TableColumnControlGroupWrapper {...rest}>
      {colWidths.map((width, index) => (
        <TableColumnControl style={{ width }} key={index}>
          <TableColumnGripControl
            onMouseDown={(e) => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              editor.commands.selectColumn(index);
            }}
            predelete={predelete}
            selected={selectedColumns.includes(index)}
            first={index === 0}
            last={index === colWidths.length - 1}
          />
          <TableColumnInsertBeforeControl
            onMouseDown={(e) => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              editor.commands.addColumnAt(index);
            }}
            onMouseEnter={() => {
              editor.commands.preinsertCol(index);
            }}
            onMouseLeave={() => {
              editor.commands.preinsertCol();
            }}
          />
        </TableColumnControl>
      ))}
    </TableColumnControlGroupWrapper>
  );
}

const TableColumnControlGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 18px;
  position: absolute;
  border-collapse: collapse;
  left: 18px;
`;

const TableColumnControl = styled.div`
  position: relative;
`;

const TableColumnGripControl = styled.a<GripControlProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-grow: 1;
  &::after {
    content: "${(p) => (p.selected || p.predelete ? "⋯" : " ")}";
    color: ${(p) => (p.selected || p.predelete ? "white" : "inherit")};
    cursor: pointer;
    width: calc(100% - ${(p) => (p.first ? "3" : "2")}px);
    height: 8px;
    background: ${(p) => getHandleColor(p)};
    border-top: 1px solid ${(p) => getHandleColor(p)};
    border-bottom: 1px solid ${(p) => getHandleColor(p)};
    border-left: ${(p) => (p.first ? "2px" : "1px")} solid
      ${(p) => getHandleColor(p)};
    border-right: ${(p) => (p.last ? "2px" : "1px")} solid
      ${(p) => getHandleColor(p)};
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 18px;
    line-height: 8px;
    position: absolute;
    top: -10px;
    border-radius: 2px 2px 0px 0px;
  }

  ${(p) =>
    !p.selected &&
    css`
      &:hover::after {
        content: "⋯";
        background: ${colors.grey200};
        border-color: transparent;
        color: ${colors.grey500};
      }
    `}
`;

const TableColumnInsertBeforeControl = styled.a`
  &::after {
    content: " ";
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    width: 4px;
    height: 4px;
    background: ${colors.grey500};
    font-weight: 900;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
    color: white;
    position: absolute;
    left: -2px;
    z-index: 100;
  }

  &:hover::after {
    content: "+";
    background: ${colors.indigo500};
    width: 16px;
    height: 16px;
    left: -8px;
    top: -9px;
  }
`;

interface TableRowControlGroupProps {
  editor: Editor;
  rowHeights?: number[];
  selectedRows: number[];
  predelete: boolean;
}

/**
 * Table Row selection, insert Row before handle
 */
function TableRowControlGroup(props: TableRowControlGroupProps) {
  const { editor, rowHeights, selectedRows, predelete, ...rest } = props;
  if (!rowHeights) return null;
  return (
    <TableRowControlGroupWrapper {...rest}>
      {rowHeights.map((height, index) => (
        <TableRowControl style={{ height }} key={index}>
          <TableRowGripControl
            onMouseDown={(e) => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              editor.commands.selectRow(index);
            }}
            predelete={predelete}
            selected={selectedRows.includes(index)}
            first={index === 0}
            last={index === rowHeights.length - 1}
          />
          <TableRowInsertBeforeControl
            onMouseDown={(e) => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              editor.commands.addRowAt(index);
            }}
            onMouseEnter={() => {
              editor.commands.preinsertRow(index);
            }}
            onMouseLeave={() => {
              editor.commands.preinsertRow();
            }}
          />
        </TableRowControl>
      ))}
    </TableRowControlGroupWrapper>
  );
}

const TableRowControlGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 18px;
  position: absolute;
  top: 18px;
  border-collapse: collapse;
`;

const TableRowControl = styled.div`
  position: relative;
`;

const TableRowGripControl = styled.a<GripControlProps>`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  flex-grow: 1;
  &::after {
    content: "${(p) => (p.selected || p.predelete ? "⋯" : " ")}";
    color: ${(p) => (p.selected || p.predelete ? "white" : "inherit")};
    cursor: pointer;
    width: 8px;
    background: ${(p) => getHandleColor(p)};
    border-top: ${(p) => (p.first ? "2px" : "1px")} solid
      ${(p) => getHandleColor(p)};
    border-bottom: ${(p) => (p.last ? "2px" : "1px")} solid
      ${(p) => getHandleColor(p)};
    border-left: 1px solid ${(p) => getHandleColor(p)};
    border-right: 1px solid ${(p) => getHandleColor(p)};
    writing-mode: vertical-rl;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: center;
    font-size: 18px;
    height: calc(100% - ${(p) => (p.first || p.last ? "3" : "2")}px);
    border-radius: 2px 0px 0px 2px;
  }

  ${(p) =>
    !p.selected &&
    css`
      &:hover::after {
        content: "⋯";
        background: ${colors.grey200};
        border-color: transparent;
        color: ${colors.grey500};
      }
    `}
`;

const TableRowInsertBeforeControl = styled.a`
  &::after {
    content: " ";
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    width: 4px;
    height: 4px;
    background: ${colors.grey500};
    font-weight: 900;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    line-height: 16px;
    color: white;
  }

  &:hover::after {
    content: "+";
    background: ${colors.indigo500};
    width: 16px;
    height: 16px;
    left: -9px;
    top: -6px;
  }
`;
