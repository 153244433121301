export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5194 8.60632V6.59894L0 9.49412V11.0668L5.5194 13.9906V11.9688L2.18068 10.2816L5.5194 8.60632Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4806 8.60632V6.59894L16 9.49412V11.0668L10.4806 13.9906V11.9688L13.8193 10.2816L10.4806 8.60632Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.91324 17.0133L8.63436 3.56994L10.0868 3.57437L7.36565 17.0177L5.91324 17.0133Z"
    />
  </>
);
