import { Editor } from "@tiptap/core";
import crel from "crelt";

import { MAX_CUSTOM_WIDTH, MIN_CUSTOM_WIDTH } from "./constants";
import { ImageWidth } from "./types";

/** Adds resize handles (corner point divs) to the given img element that have event listeners to
 * resize the imgWrapperDiv according to mouse movement.
 */
export class ImageResizeHandles {
  imgWrapperDiv: HTMLElement;
  resizePointsParent: HTMLElement;
  originalWidth?: number;
  newWidth?: number;
  editor: Editor;
  mouseXPos = 0;
  activeResizeDirection: ResizeDirection | undefined = undefined;

  constructor(imgWrapperDiv: HTMLElement, editor: Editor) {
    this.imgWrapperDiv = imgWrapperDiv;
    this.editor = editor;

    // create the resize point divs
    const ne = crel("div", { class: "resize-point resize-point--ne" });
    const nw = crel("div", { class: "resize-point resize-point--nw" });
    const se = crel("div", { class: "resize-point resize-point--se" });
    const sw = crel("div", { class: "resize-point resize-point--sw" });

    // add mouse down listeners to the resize points
    this.addMouseDownListeners(se, ResizeDirection.BottomRight);
    this.addMouseDownListeners(sw, ResizeDirection.BottomLeft);
    this.addMouseDownListeners(ne, ResizeDirection.TopRight);
    this.addMouseDownListeners(nw, ResizeDirection.TopLeft);

    // create the parent div for resize points and add it to dom
    this.resizePointsParent = crel("div", { class: "resize-points" }, [
      ne,
      nw,
      se,
      sw,
    ]);

    // make the resizePointsParent a sibling of the img tag
    crel(this.imgWrapperDiv, this.resizePointsParent);

    // set the visibility to false on creation
    this.setVisible(false);
  }

  /**
   * Sets the visibility of the resize points by changing display property
   */
  public setVisible(flag: boolean) {
    if (flag) {
      this.resizePointsParent.style.removeProperty("display");
    } else {
      this.resizePointsParent.style.display = "none";
    }
  }

  /**
   * Adds the mouse down listener to a given `resizePoint`.
   */
  private addMouseDownListeners(
    resizePoint: HTMLElement,
    resizeDirection: ResizeDirection
  ) {
    resizePoint.addEventListener("mousedown", (e) => {
      e.preventDefault();

      // set the active resize direction
      this.activeResizeDirection = resizeDirection;

      // calculate and set the original width and the mouse position
      this.originalWidth = this.imgWrapperDiv.getBoundingClientRect().width;
      this.newWidth = this.originalWidth;
      this.mouseXPos = e.pageX;

      // add event listeners to listeners for mouse move and mouse up
      window.addEventListener("mousemove", this.handleMoouseMoveResize);
      window.addEventListener("mouseup", this.handleMouseUp);
    });
  }

  /**
   * Handle the mousemove event to resize the imgDivWrapper element
   * @param e
   */
  private handleMoouseMoveResize = (e: MouseEvent) => {
    e.preventDefault();
    const resizingSmoothnessFactor = 1.5; // factor to make the reszing smoother
    let width;

    // calculate the width based on the activeResizeDirection and scale it by smoothness factor
    if (this.originalWidth)
      switch (this.activeResizeDirection) {
        case ResizeDirection.TopRight:
          width =
            this.originalWidth -
            resizingSmoothnessFactor * (e.pageX - this.mouseXPos);
          break;
        case ResizeDirection.TopLeft:
          width =
            this.originalWidth +
            resizingSmoothnessFactor * (e.pageX - this.mouseXPos);
          break;
        case ResizeDirection.BottomLeft:
          width =
            this.originalWidth -
            resizingSmoothnessFactor * (e.pageX - this.mouseXPos);
          break;
        case ResizeDirection.BottomRight:
          width =
            this.originalWidth +
            resizingSmoothnessFactor * (e.pageX - this.mouseXPos);
          break;
        default:
          width = this.originalWidth;
      }
    else width = MAX_CUSTOM_WIDTH;

    // assign the new width if it is more than min width
    if (width < MIN_CUSTOM_WIDTH) {
      this.newWidth = MIN_CUSTOM_WIDTH;
    } else {
      this.newWidth = width;
    }

    this.imgWrapperDiv.style.width = `${this.newWidth}px`;
  };

  /**
   * Handle the `mouseup` event after resizing is done to dispatch the size update
   * command to the editor and remove the event listeners from the window.
   */
  private handleMouseUp = () => {
    // Dispatch editor commands to
    // If new width is greater than the MaxWidth, it should be set to FitToText.
    if (!this.newWidth || this.newWidth >= MAX_CUSTOM_WIDTH) {
      this.editor.chain().focus().updateImageWidth(ImageWidth.FitToText).run();
    } else {
      this.editor.chain().focus().updateCustomWidth(this.newWidth).run();
    }

    // remove the mouse listeners
    window.removeEventListener("mousemove", this.handleMoouseMoveResize);
    window.removeEventListener("mouseup", this.handleMouseUp);
  };
}

enum ResizeDirection {
  TopLeft = "nw",
  TopRight = "ne",
  BottomLeft = "sw",
  BottomRight = "se",
}
