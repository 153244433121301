import React, { forwardRef } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled, { ThemedStyledProps, css } from "styled-components";

import { Icon } from "../Icon";
import { CuiTheme, colors, defaultTheme, levels } from "../styles";
import { FocusAnimation, easeOutCirc } from "../styles/animations";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * React children
   */
  children?: React.ReactNode;

  /**
   * Kind of button.
   */
  kind?: "primary" | "error" | "dark" | "solid" | "outline";

  /**
   * Cadmus Icon to optionally render.
   */
  iconName?: IconName;

  /**
   * Positions the icon either left or right.
   * @default 'left'
   */
  iconPosition?: "left" | "right";

  /**
   * adds left margin in px
   * @default 0
   */
  marginLeft?: number;

  /**
   * adds right margin in px
   * @default 0
   */
  marginRight?: number;

  /**
   * Formats the button to be of a particular size
   * @default "md"
   */
  size?: "sm" | "md" | "lg";

  /**
   * Remove the block shadow from the button.
   * @default false
   */
  flat?: boolean;

  /**
   * Allow the button to spread the full width.
   */
  fullWidth?: boolean;
}

/**
 * Render a button with Cadmus styles and variants.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const { iconName, iconPosition = "left", ...buttonProps } = props;
    const icon = iconName && (
      <ButtonIcon kind={props.kind} iconName={iconName} />
    );
    return (
      <ButtonContainer ref={ref} {...buttonProps}>
        {iconPosition === "left" && icon}
        <ButtonText>{props.children}</ButtonText>
        {iconPosition === "right" && icon}
      </ButtonContainer>
    );
  }
);

const ButtonContainer = styled.button<ButtonProps>`
  padding: ${(p) => {
    switch (p.size ?? "md") {
      case "sm":
        return "2.5px 12px";
      case "md":
        return "8px 20px";
      case "lg":
        return "11.5px 20px";
    }
  }};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0 8px;

  margin: 0;
  margin-left: ${(p) => (p.marginLeft ? `${p.marginLeft}px` : 0)};
  margin-right: ${(p) => (p.marginRight ? `${p.marginRight}px` : 0)};

  white-space: nowrap;

  position: relative;
  flex: none;
  width: ${(p) => (p.fullWidth ? "auto" : "fit-content")};

  border: ${(p) =>
    p.kind === "outline" ? `1px solid ${colors.grey300}` : "none"};
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;

  /* A Mixin to inject button color styles */
  ${buttonCss}
  box-shadow: ${(p) => (!p.flat ? levels.one : "none")};

  outline: 0;

  &:not(:disabled) {
    /* The :before pseudo-element creates a focus and ping outline*/
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      opacity: 1;
    }

    &:focus:before {
      ${FocusAnimation}
    }

    /* The :after pseudo-element creates a performant box shadow change on hover */
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      box-shadow: ${levels.two};
      opacity: 0;
      transition: opacity 0.6s ${easeOutCirc};
      background: hsla(220, 30%, 40%, 0.27);
      mix-blend-mode: screen;
    }

    &:hover:after,
    &:focus:after {
      opacity: 1;
    }

    &:active:after {
      opacity: 0;
    }

    &:hover:focus:after {
      opacity: 0;
    }

    &:focus:not(:hover):after {
      transition: opacity 1.2s ${easeOutCirc};
    }
  }

  &:disabled {
    opacity: 0.36;
    cursor: not-allowed;
  }
`;

const ButtonText = styled.span`
  position: relative;
  font-family: inherit;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0.5px;
`;

function buttonCss(props: ThemedStyledProps<ButtonProps, CuiTheme>) {
  const { kind } = props;
  const gradient = props.theme.gradient || defaultTheme.gradient;

  let fg;
  let bg;

  switch (kind) {
    case "primary":
      bg = gradient.start;
      fg = colors.white200;
      break;
    case "error":
      bg = colors.red500;
      fg = colors.white200;
      break;
    case "dark":
      bg = colors.black200;
      fg = colors.white200;
      break;
    case "solid":
      bg = props.theme.primaryColor;
      fg = colors.white200;
      break;
    case "outline":
      bg = "transparent";
      fg = colors.grey500;
      break;
    default:
      bg = colors.white200;
      fg = colors.black200;
  }

  /* Inject the background image gradient for primary */
  const backgroundImage =
    kind === "primary"
      ? css`
          background-image: linear-gradient(
            to right,
            ${gradient.start},
            ${gradient.end}
          );
        `
      : undefined;

  return css`
    background: ${bg};
    color: ${fg};
    ${backgroundImage}
  `;
}

const ButtonIcon = styled(Icon)<{ kind: ButtonProps["kind"] }>`
  ${(p) => {
    let fontColor;

    switch (p.kind) {
      case "error":
      case "dark":
      case "primary":
      case "solid":
        fontColor = colors.white200;
        break;
      default:
        fontColor = colors.black200;
    }

    return css`
      fill: ${fontColor};
    `;
  }}
`;
