export default (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 16C3 15.4477 3.44772 15 4 15H16V17H4C3.44772 17 3 16.5523 3 16ZM7 12C7 11.4477 7.44772 11 8 11H16V13H8C7.44772 13 7 12.5523 7 12ZM7 8C7 7.44772 7.44772 7 8 7H16V9H8C7.44772 9 7 8.55228 7 8ZM3 4C3 3.44772 3.44772 3 4 3H16V5H4C3.44772 5 3 4.55228 3 4ZM1.7286 7L4 9.92866V10.0713L1.7286 13H0L1.93737 10.0038L0 7H1.7286Z"
    />
    <mask
      id="mask0_856_23608"
      maskUnits="userSpaceOnUse"
      x="7"
      y="7"
      width="9"
      height="2"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8C7 7.44772 7.44772 7 8 7H16V9H8C7.44772 9 7 8.55228 7 8Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_856_23608)"></g>
  </>
);
