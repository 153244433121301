import { useState } from "react";
import type { IconName } from "@vericus/cadmus-icons";
import { Icon } from "@vericus/cadmus-icons";
import {
  ControlButton,
  Dropdown,
  Menu,
  MenuDivider,
  MenuHeader,
  Text,
  colors,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

import { haveTableBorderStyle, isSelectionInsideTable } from "../utils";
import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table Border Menu's dropdown
 */
export function BorderMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const [open, setOpen] = useState(false);
  if (!isSelectionInsideTable(editor.state.selection)) return null;
  return (
    <>
      <Dropdown
        open={open}
        position="bottom-right"
        content={<BorderDropDownContent editor={editor} />}
        onOuterClick={() => setOpen(false)}
      >
        <ControlButton
          iconName="BorderBottom"
          aria-label="Borders"
          onClick={() => setOpen(!open)}
        />
      </Dropdown>
      <GroupDivider />
    </>
  );
}

/**
 * Table Border Menu's dropdown menu items
 */

function BorderDropDownContent(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const haveTop = haveTableBorderStyle(editor.state, "top");
  const haveBottom = haveTableBorderStyle(editor.state, "bottom");

  return (
    <StyledBorderMenu>
      <BorderItem
        onClick={() => editor.commands.setTableCellBorder("all", true)}
        active={haveTableBorderStyle(editor.state, "all")}
        aria-label="Apply border to all sides"
        iconName="BorderAll"
        text="All borders"
      />
      <StyledMenuDivider />
      <StyledMenuHeader text="APA Style" />
      <BorderItem
        onClick={() => editor.commands.setTableCellBorder("all", false)}
        aria-label="Remove borders"
        iconName="BorderNone"
        text="Remove borders"
      />
      <BorderItem
        onClick={() => editor.commands.setTableCellBorder("top", !haveTop)}
        active={haveTop}
        aria-label="Toggle border top"
        iconName="BorderTop"
        text="Top Border"
      />
      <BorderItem
        onClick={() =>
          editor.commands.setTableCellBorder("bottom", !haveBottom)
        }
        active={haveBottom}
        aria-label="Toggle border bottom"
        iconName="BorderBottom"
        text="Bottom Border"
      />
      <StyledMenuDivider />
      <BorderInsideHorizontalMenu editor={editor} />
    </StyledBorderMenu>
  );
}

const StyledBorderMenu = styled(Menu)`
  width: 230px;
`;

/**
 * Table Border Menu's dropdown menu item for inside horizontal
 * border toggle. only shows when selection allows to set an
 * insideHorizontal border
 */
function BorderInsideHorizontalMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  if (
    !editor.can().setTableCellBorder("insideHorizontal", true) &&
    !editor.can().setTableCellBorder("insideHorizontal", false)
  ) {
    return null;
  }
  const isActive = haveTableBorderStyle(editor.state, "insideHorizontal");
  return (
    <BorderItem
      onClick={() =>
        isActive
          ? editor.commands.setTableCellBorder("insideHorizontal", false)
          : editor.commands.setTableCellBorder("insideHorizontal", true)
      }
      active={isActive}
      aria-label="Toggle horizontal inside borders"
      iconName="BorderInsideHorizontal"
      text="Horizontal Inside Border"
    />
  );
}

const StyledMenuHeader = styled(MenuHeader)`
  ${Text} {
    color: ${colors.grey500};
    font-weight: 400;
  }
`;

const StyledMenuDivider = styled(MenuDivider)`
  &:last-child {
    display: none;
  }
`;

interface BorderItemProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  iconName: IconName;
  text: string;
  active?: boolean;
}

function BorderItem(props: BorderItemProps) {
  const { iconName, active, text, ...buttonProps } = props;
  return (
    <BorderMenuItem active={active} {...buttonProps}>
      <ActiveWrapper>{active && <Icon iconName="Tick" />}</ActiveWrapper>
      <Icon iconName={iconName} />
      <Text kind="system">{text}</Text>
    </BorderMenuItem>
  );
}

const ActiveWrapper = styled.span`
  width: 21px;
`;

type BorderMenuItemProps = Omit<BorderItemProps, "iconName" | "text">;

const BorderMenuItem = styled.div.attrs<BorderMenuItemProps>({
  role: "menuitem",
  tabindex: "0",
})<BorderMenuItemProps>`
  display: flex;
  height: 32px;
  flex-grow: 1;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;

  ${Text} {
    margin-left: 12px;
    color: ${(p) => (p.active ? p.theme.text.accent : colors.grey500)};
    font-weight: ${(p) => (p.active ? "bold" : "normal")};
  }

  fill: ${(p) => (p.active ? p.theme.text.accent : colors.grey500)};
`;
