import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import ErrorImg from "assets/errors/api-error.png";
import { Grid } from "styles/layout";

/**
 * Full page API error page displayed to the user. This type of error is
 * irrecoverable and needs a refresh. Also displayes links to Cadmus user
 * support.
 */
export function ApiError() {
  return (
    <Grid>
      <GridImg
        src={ErrorImg}
        alt="An illustration of a man looking into a box. We can't see what's inside the box, but it's emitting a red, ominous glow. I wonder what it could be?"
      />
      <GridContent>
        <Text kind="displayTwo">Cannot reach Cadmus</Text>
        <Text kind="lead">
          This is usually due to a drop in internet connection. Try refreshing
          the page. If that doesn’t work, Cadmus may temporarily be down.
        </Text>
        <Spacer spacing={27} />
        <Anchored
          newTab
          href="https://support.cadmus.io/students/cannot-reach-cadmus"
        >
          <Button kind="dark">Learn more about this error</Button>
        </Anchored>
        <Anchored newTab href="https://support.cadmus.io/kb-tickets/new">
          <Button marginLeft={18}>Contact us for help</Button>
        </Anchored>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
