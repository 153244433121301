export const typography = {
  displayOne: {
    fontFamily: "miller-display",
    fontWeight: 600,
    fontSize: "61px", // 3.8125rem
    paddingTop: "9px",
    paddingBottom: "9px",
    lineHeight: "1.18", // 72px
    letterSpacing: "0px",
    textTransform: "",
    element: "h1"
  },

  displayTwo: {
    fontFamily: "miller-display",
    fontWeight: 600,
    fontSize: "48.8px", // 3.05rem
    paddingTop: "9px",
    paddingBottom: "9px",
    lineHeight: "1.29", // 63px
    letterSpacing: "0px",
    textTransform: "",
    element: "h1"
  },

  headingOne: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "39px", // 2.4375rem
    paddingTop: "9px",
    paddingBottom: "9px",
    lineHeight: "1.384", // 54px
    letterSpacing: "0px",
    textTransform: "",
    element: "h1"
  },

  headingTwo: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "31.25px", //  1.953rem
    paddingTop: "6px",
    paddingBottom: "6px",
    lineHeight: "1.44", // 45px
    letterSpacing: "0px",
    textTransform: "",
    element: "h2"
  },

  headingThree: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "25px", // 1.5625rem
    paddingTop: "6px",
    paddingBottom: "6px",
    lineHeight: "1.44", // 36px
    letterSpacing: "0px",
    textTransform: "",
    element: "h3"
  },

  headingFour: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "20px", // 1.25rem
    paddingTop: "6px",
    paddingBottom: "6px",
    lineHeight: "1.35", // 27px
    letterSpacing: "0px",
    textTransform: "",
    element: "h4"
  },

  headingFive: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "17px", // 1.0625rem
    paddingTop: "6px",
    paddingBottom: "6px",
    lineHeight: "1.412", // 24px
    letterSpacing: "0.1px",
    textTransform: "",
    element: "h5"
  },

  headingSix: {
    fontFamily: "Acumin-Pro",
    fontWeight: 600,
    fontSize: "14px", // 0.875rem
    paddingTop: "3px",
    paddingBottom: "3px",
    lineHeight: "1.5", // 21px
    letterSpacing: "0.2px",
    textTransform: "",
    element: "h6"
  },

  blockquote: {
    fontFamily: "adobe-text-pro, times, serif",
    fontWeight: 400,
    fontSize: "16px", //  1rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 24px
    letterSpacing: "0.2px",
    textTransform: "",
    element: "p"
  },

  lead: {
    fontFamily: "Acumin-Pro",
    fontWeight: 400,
    fontSize: "20px", //  1.25rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.35", // 27px
    letterSpacing: "0.4px",
    textTransform: "",
    element: "p"
  },

  body: {
    fontFamily: "Acumin-Pro",
    fontWeight: 400,
    fontSize: "18px", //  1.125rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 27px
    letterSpacing: "0.2px",
    textTransform: "",
    element: "p"
  },

  paragraph: {
    fontFamily: "Acumin-Pro",
    fontWeight: 400,
    fontSize: "16px", //  1rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 24px
    letterSpacing: "0.2px",
    textTransform: "",
    element: "p"
  },

  system: {
    fontFamily: "Acumin-Pro",
    fontWeight: 400,
    fontSize: "14px", // 0.875rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 21px
    letterSpacing: "0.2px",
    textTransform: "",
    element: "p"
  },

  subtle: {
    fontFamily: "Acumin-Pro",
    fontWeight: 400,
    fontSize: "12px", // 0.75rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 18px
    letterSpacing: "0.4px",
    textTransform: "",
    element: "p"
  },

  label: {
    fontFamily: "Acumin-Pro-Semi-Condensed",
    fontWeight: 600,
    fontSize: "12px", // 0.75rem
    paddingTop: "0px",
    paddingBottom: "0px",
    lineHeight: "1.5", // 18px
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    element: "p"
  }
};

export type Typography = keyof typeof typography;
