import { ComponentProps, forwardRef } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { Icon } from "../Icon";
import { easeOutCirc } from "../styles/animations";
import { Text } from "../Text";

interface MenuItemProps extends ComponentProps<typeof MenuItemButton> {
  /**
   * Text label to display as Menu Item's text.
   * Has a higher priority than `children`.
   */
  content?: string;

  /** Icon name rendered on the left side of label */
  iconName?: IconName;

  /** Selected item state */
  selected?: boolean;
}

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (props, ref) => {
    const { content, children, iconName, selected, disabled, ...buttonProps } =
      props;

    return (
      <MenuItemButton
        ref={ref}
        selected={selected}
        disabled={disabled}
        {...buttonProps}
      >
        {iconName && <Icon iconName={iconName} />}
        {content ? <Text kind="system">{content}</Text> : children}
      </MenuItemButton>
    );
  }
);

/** A selectable and interactable child button item in a parent Menu. */
export const MenuItemButton = styled.button<{ selected?: boolean }>`
  display: flex;
  gap: 12px;
  align-items: center;

  width: 100%;
  min-height: 40px;
  padding: 0px 16px;

  border: none;
  background: transparent;

  color: ${(p) => (p.selected ? p.theme.text.accent : p.theme.text.main)};
  fill: ${(p) => (p.selected ? p.theme.text.accent : p.theme.text.shade1)};

  transition: background 0.3s ${easeOutCirc}, fill 0.3s ${easeOutCirc};

  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background: ${(p) => p.theme.background.action1};
  }

  &:not(:disabled) {
    &:active,
    &[aria-expanded="true"] {
      background: ${(p) => p.theme.background.action2};
    }
  }

  &:disabled {
    opacity: 0.36;
    cursor: not-allowed;
  }
`;
