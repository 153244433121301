import { SpecialIndentationStyle } from "../types";

export function coalesceIndentStyle(
  enabledStyles: SpecialIndentationStyle[],
  defaultStyle: SpecialIndentationStyle
) {
  return (style?: SpecialIndentationStyle) =>
    style && enabledStyles.includes(style) ? style : defaultStyle;
}

/**
 * Render data-indent-special attribute based on the indent-style node attrs
 */
export function renderSpecialIndent(
  enabledStyles: SpecialIndentationStyle[],
  defaultStyle: SpecialIndentationStyle
) {
  const getIndentStyle = coalesceIndentStyle(enabledStyles, defaultStyle);
  return (attributes: Record<string, SpecialIndentationStyle | undefined>) => {
    const style = getIndentStyle(attributes.indentSpecial);
    return {
      "data-indent-special": style,
    };
  };
}
