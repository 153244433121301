import type { AppStartListening } from "@/data/listenerMiddleware";
import type { AppDispatch, RootState } from "@/data/store";
import {
  TimelineDate,
  DateNotifierWorkerProxy,
  TimelineState,
} from "@/features/timeline";
import { ListenerEffectAPI, TaskAbortError } from "@reduxjs/toolkit";
import { differenceInMilliseconds, isAfter } from "date-fns";

import { getCurrentDate } from "utils/datetime";

import * as Comlink from "comlink";
import { onFocus } from "@/data/setup-dom-listeners";

type DateSelector = (state: TimelineState) => TimelineDate;

/**
 * Create a Redux listener which starts a timer counting down towards a date
 * from the `timeline` slice.
 *
 * The Redux state date is selected using the `selectDate` callback. If the date
 * changes and has not been marked as passed, the timer is restarted.
 *
 * The timer is run in a worker thread using the `DateNotifierWorkerProxy`.
 *
 * At the end of the timer `callback` is invoked and the effect is cancelled.
 * Only 1 timer will run for the selected date.
 *
 * To start the listener, pass the `AppStartListening` function from the Redux
 * store's listener middleware, to the function returned here.
 *
 * @param selectDate Redux selector to extract a timeline date with `date` and
 *   `passed` properties.
 * @param callback A callback invoked when the date passes.
 *
 * @return A function `(startListening) => any` which can be called to start the
 *   global listener.
 */
export const createDateListener =
  (
    selectDate: DateSelector,
    callback: (listenerAPI: ListenerEffectAPI<RootState, AppDispatch>) => void
  ) =>
  (startListening: AppStartListening) => {
    startListening({
      predicate: (action, currentState, previousState) => {
        // Re-fire the effect on window focus
        if (onFocus.match(action)) {
          return true;
        }
        if (currentState.timeline === previousState.timeline) {
          return false;
        }
        const curr = selectDate(currentState.timeline);
        const prev = selectDate(previousState.timeline);
        return curr.date !== null && !curr.passed && curr.date !== prev.date;
      },
      effect: async (_action, listenerAPI) => {
        const timelineDate = selectDate(listenerAPI.getState().timeline);
        const date = timelineDate.date;

        if (date === null || timelineDate.passed) return undefined;
        console.log("Date Listener Effect", date, selectDate);

        // Only allow one instance of this listener to run at a time
        listenerAPI.cancelActiveListeners();

        // Create a callback with proxy which dispatches the `action` on timer end
        const onNotify = () => {
          if (!listenerAPI.signal.aborted) {
            callback(listenerAPI);
          }
        };

        // Use the worker proxy to start a new timer instance in the worker.
        const timeout = intervalInMs(new Date(date));
        const onNotifyProxy = Comlink.proxy(onNotify);
        const instance = await new DateNotifierWorkerProxy(
          onNotifyProxy,
          timeout
        );

        // Start the worker timer if the listener task is active
        if (!listenerAPI.signal.aborted) {
          await instance.tick();
        }

        try {
          // Wait for the watched date to pass. Using `condition` here also lets
          // us be cancellation aware.
          await listenerAPI.condition(
            (_, state) => selectDate(state.timeline)?.passed === true
          );
        } catch (err) {
          // Task is cancelled, clear any running timers before exiting
          if (err instanceof TaskAbortError) {
            console.log("Task aborted, stopping");
            await instance.stopTimer();
          }
        }
      },
    });
  };

// Get milliseconds between now and the `endDate`. Returns -1 if the `endDate`
// is in the past.
function intervalInMs(endDate: Date) {
  const now = getCurrentDate();
  if (isAfter(now, endDate)) return -1;
  return differenceInMilliseconds(endDate, now);
}
