import { FC } from "react";
import styled, { keyframes } from "styled-components";

interface SkeletonProps {
  /**
   * Width in %
   * @default 100
   */
  width?: number;
}

const moveGradient = keyframes`
  0% {
  transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

/**
 *  Base components: `Skeleton`
 *
 *  Common components: `TitleSkeleton`, `SectionSkeleton`,
 * `RequirementsSkeleton`,`ParagraphSkeleton`, `CardSkeleton`
 */
export const Skeleton = styled.div<SkeletonProps>`
  height: 18px;
  width: ${(p) => (p.width ? `${p.width}%` : "100%")};
  margin-bottom: 9px;
  /* pill shape */
  border-radius: 10000px;
  position: relative;

  background-color: #f2f4f8;
  box-sizing: border-box;
  overflow: hidden;

  &::after {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    will-change: transform;
    content: "";
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0),
      #fafbfc,
      rgba(0, 0, 0, 0)
    );
    animation: ${moveGradient} 1.6s infinite;
  }
`;

// TITLE SKELETON
// =============================================================================

export const TitleSkeleton = styled(Skeleton)`
  background-color: ${(p) => p.theme.background.action2};
`;

// SECTION SKELETON
// =============================================================================

export const SectionSkeleton = () => (
  <SectionContainer>
    <SkeletonLabel />
    <Line />
  </SectionContainer>
);

const SectionContainer = styled.div`
  display: flex;
  align-items: baseline;
`;

const Line = styled.div`
  flex: auto;
  height: 1px;
  background: ${(p) => p.theme.background.action3};
`;

// CARD SKELETON
// =============================================================================

export const CardSkeleton = styled(Skeleton)<{ heightPx?: number }>`
  border-radius: 8px;
  height: ${(p) => p.heightPx || 150}px;
`;

// REQUIREMENTS SKELETON
// =============================================================================

export const RequirementsSkeleton: FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => (
  <div {...props}>
    {SingleRequirementSkeleton(45)}
    {SingleRequirementSkeleton(54)}
    {SingleRequirementSkeleton(45)}
    {SingleRequirementSkeleton(36)}
  </div>
);

const SingleRequirementSkeleton = (contentPercentage: number) => (
  <ReqContainer>
    <SkeletonLabel />
    <Skeleton width={contentPercentage} />
  </ReqContainer>
);

const ReqContainer = styled.div`
  display: flex;
`;

const SkeletonLabel = styled(Skeleton)`
  margin-right: 18px;
  width: 100px;
  flex: none;
  background-color: ${(p) => p.theme.background.action2};
`;

// PARAGRAPH SKELETON
// =============================================================================

export const ParagraphSkeleton: FC<React.HTMLAttributes<HTMLDivElement>> = (
  props
) => (
  <div {...props}>
    <Skeleton width={85} />
    <Skeleton width={83} />
    <Skeleton width={88} />
    <Skeleton width={95} />
    <Skeleton width={93} />
    <Skeleton width={90} />
    <Skeleton width={20} />
  </div>
);
