import { IconProps } from "./types";

export const ProductIcon = (props: IconProps) => {
  return (
    <svg width="30" height="31" viewBox="0 0 30 31" {...props}>
      <path
        d="M22 11.3333C22 11.1492 22.1492 11 22.3333 11H29.6667C29.8508 11 30 11.1492 30 11.3333V18.6667C30 18.8508 29.8508 19 29.6667 19H22.3333C22.1492 19 22 18.8508 22 18.6667V11.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12V18H29V12H23ZM22.3333 11C22.1492 11 22 11.1492 22 11.3333V18.6667C22 18.8508 22.1492 19 22.3333 19H29.6667C29.8508 19 30 18.8508 30 18.6667V11.3333C30 11.1492 29.8508 11 29.6667 11H22.3333Z"
        fill="#7070A9"
      />
      <path
        d="M15.4666 21.98C15.5946 22.524 16.064 22.8173 16.8746 22.86H17.0986V23.5H12.0106V22.86H12.2346C12.9813 22.8173 13.44 22.5667 13.6106 22.108L13.6586 21.98V8.14H7.21063V15.068L7.22663 21.98C7.35463 22.524 7.82396 22.8173 8.63463 22.86H8.85863V23.5H3.77063V22.86H3.99463C4.74129 22.8173 5.19996 22.5667 5.37063 22.108L5.41863 21.98V9.004L5.37063 8.892C5.19996 8.43333 4.74129 8.18267 3.99463 8.14H3.77063V7.5H17.0986V8.14H16.8746C16.448 8.16133 16.1226 8.24133 15.8986 8.38C15.6853 8.508 15.5413 8.716 15.4666 9.004V21.98Z"
        fill="#3A517F"
      />
      <path d="M20 6.5H22V8.5H20V6.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5.5H23V9.5H19V5.5ZM20 6.5V8.5H22V6.5H20Z"
        fill="#7070A9"
      />
      <path d="M20 21.5H22V23.5H20V21.5Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 20.5H23V24.5H19V20.5ZM20 21.5V23.5H22V21.5H20Z"
        fill="#7070A9"
      />
    </svg>
  );
};
