import styled, { ThemedStyledProps } from "styled-components";

import { CuiTheme, colors, easeOutCirc, typography } from "./styles";

interface Props {
  /** Sets tab color based on kind */
  kind?: "primary";

  /** A state to indicate the selected tab */
  selected?: boolean;
}

// helpers
function tabColor(props: ThemedStyledProps<Props, CuiTheme>) {
  if (props.selected && props.kind === "primary") {
    return props.theme.primaryColor;
  }
  if (props.selected) {
    return colors.black200;
  }

  return colors.black100;
}

/**
 * A simple tab bar to navigate between different content areas
 */
export const Tabs = styled.div`
  display: inline-flex;
  min-height: 36px;
  height: 100%;
`;

export const Tab = styled.button<Props>`
  background: transparent;
  border: none;
  padding: 7px 0 12px;
  min-width: 120px;

  color: ${tabColor};

  font-size: ${typography["system"].fontSize};
  letter-spacing: ${typography["system"].letterSpacing};
  line-height: ${typography["system"].lineHeight};
  font-weight: 600;

  opacity: ${(p) => (p.selected ? "1" : "0.81")};

  position: relative;
  outline: none;
  cursor: pointer;

  /* The after psuedo-element creates the selected underline. */
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-bottom: 2px solid ${tabColor};
    box-sizing: border-box;
    opacity: ${(p) => (p.selected ? "1" : "0")};
    transition: transform 1s ${easeOutCirc};
  }

  &:not(:disabled) {
    &:hover {
      opacity: 1;
      background-color: ${colors.grey50};
    }

    &:focus {
      opacity: 1;
      background-color: ${colors.grey50};
    }
  }

  &:disabled {
    opacity: 0.36;
    text-decoration: line-through;
  }
`;
