import { Extension } from "@tiptap/core";
import {
  WordCountPlugin,
  WordCountPluginKey,
  SetWordCountAction,
} from "./word-count-plugin";

import { TelemetryOptions } from "./types";
import { debouncedProcessDocWords, processDocWords } from "./processDocWords";
import { emitTransactionEvents } from "./emitTransactionEvents";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    telemetry: {
      /**
       * Update the latest tracked total word count for the document.
       */
      setWordCount: (total: number) => ReturnType;
    };
  }
}

/**
 * Collect and emit Telemtric data and events based on transactions in the
 * Editor.
 *
 * ## Document Tokenisation - Word count and Suggestions
 *
 * On creation and on debounced intervals the latest document state is tokenised
 * into Words. These word tokens are used to compute and set the latest Word
 * count state, and also transmit them for spelling suggestions via the provided
 * `SuggestionClient` (if provided)
 *
 * The Word count state is maintained in a plugin state and can be accessed
 * easily via the `editorWordCount` API function.
 *
 * The debounced interval is currently hardcoded to 800ms.
 *
 * ## Save Trigger
 *
 * On debounced intervals the callback `onSave` is called to signal document
 * saving opportunities.
 *
 * The debounced interval is currently hardcoded to 800ms.
 *
 * ## Events
 *
 * Some extensions/plugins set Transaction metadata payloads as event payloads,
 * expecting the telemetry extension to pick it up and emit them via the
 * provided callbacks.
 *
 * The `telemetry` plugin itself will do extra work to compute the full event
 * payload based on the transaction's document state and emit them.
 *
 * These events are:
 *
 * ### Paste Event
 *
 * Set by the `paste` plugin which processes incoming pastes. The eventual event
 * payload formed will contain the changeset of words added and deleted during
 * that transaction. This is a heavier operation and is performed
 * asynchronously.
 *
 * The Paste event payload is represented by `EditorPasteEvent`.
 *
 * ### History Event
 *
 * Set by the `history` plugin which performs `undo` and `redo` operations. The eventual event
 * payload formed will contain the changeset of words added and deleted during
 * that transaction. This is a heavier operation and is performed
 * asynchronously.
 *
 * The History event payload is represented by `EditorHistoryEvent`.
 */
export const Telemetry = Extension.create<TelemetryOptions>({
  name: "telemetry",

  addStorage() {
    return {
      pendingSave: false,
    };
  },

  /**
   * On each transaction that cause changes in document, debounce word count
   * calculation and suggestion request to the server and also send undo/redo/paste
   * event to the server
   */
  onTransaction({ transaction }) {
    if (transaction.docChanged) {
      this.storage.pendingSave = true;
      debouncedProcessDocWords(this.editor, transaction.doc, this.options);
    }
    emitTransactionEvents(transaction, this.options);
  },

  /**
   * Upon creation of the editor instance, initialised word count &
   * suggestion without debouncing it.
   */
  onCreate() {
    processDocWords(this.editor, this.editor.state.doc, {
      ...this.options,
      // Do not fire a save on creation, just update the word count
      onSave: undefined,
    });
  },

  addCommands() {
    return {
      setWordCount:
        (total: number) =>
        ({ dispatch, tr }) => {
          if (dispatch) {
            tr.setMeta(WordCountPluginKey, <SetWordCountAction>{
              type: "setWordCount",
              total,
            });
          }
          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [WordCountPlugin(this.options.editorId)];
  },
});
