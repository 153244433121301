import { ComponentProps } from "react";
import { Placement } from "@floating-ui/react-dom-interactions";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { ControlButton } from "../ControlButton";
import { TooltipPosition } from "../mixins/Tooltip";
import { Popover } from "../Popover";
import { levels } from "../styles";
import { ColorPicker } from "./ColorPicker";

export interface ColorPickerMenuProps
  extends ComponentProps<typeof ColorPicker> {
  /**
   * Button icon name.
   */
  iconName?: IconName;
  /**
   * AIIY label, also used as the  tooltip label.
   */
  "aria-label"?: string;
  /**
   * Render a selected state for the button.
   */
  selected?: boolean;
  /**
   * Disabled state for the button.
   */
  disabled?: boolean;
  /**
   * Select starting placement.
   * @default "bottom-start"
   */
  placement?: Placement;
  /**
   * Autofocus to the Color picker card on open.
   * @default true
   */
  autoFocus?: boolean;
  /**
   * Tooltip relative positioning passed to the ControlButton.
   */
  tooltipPosition?: TooltipPosition;
}

export const ColorPickerMenu = (props: ColorPickerMenuProps) => {
  const {
    iconName = "Color",
    "aria-label": ariaLabel,
    selected,
    disabled,
    placement,
    autoFocus = true,
    tooltipPosition,
    ...pickerProps
  } = props;
  return (
    <Popover
      render={({ labelId }) => (
        <ColorPickerCard>
          <ColorPicker {...pickerProps} labelId={labelId} />
        </ColorPickerCard>
      )}
      placement={placement}
      autoFocus={autoFocus}
    >
      <ControlButton
        aria-label={ariaLabel}
        iconName={iconName}
        selected={selected}
        disabled={disabled}
        tooltipPosition={tooltipPosition}
      />
    </Popover>
  );
};

const ColorPickerCard = styled.div`
  background-color: white;
  box-shadow: ${levels.three};
  padding: 8px 0px;
  border-radius: 3px;
  position: relative;
  z-index: 50;
`;
