import { ControlButton } from "@vericus/cadmus-ui";

import { isSelectionType } from "../utils";
import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table's Footnote and Caption toggle Menu Items
 */
export function BlockMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  if (!isSelectionType(editor.state.selection, "text")) return null;
  return (
    <>
      <ControlButton
        aria-label="Toggle Table Caption"
        iconName="Caption"
        selected={editor.can().hideTableCaption()}
        onClick={() => editor.chain().toggleTableCaption().focus().run()}
      />
      <ControlButton
        aria-label="Toggle Table Footnotes "
        iconName="Footer"
        selected={editor.can().hideTableFootnote()}
        onClick={() => editor.chain().toggleTableFootnote().focus().run()}
      />
      <GroupDivider />
    </>
  );
}
