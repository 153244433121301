import { Editor } from "@tiptap/core";
import {
  NodeRange,
  NodeType,
  Node as PMNode,
  Slice,
  Fragment,
} from "@tiptap/pm/model";
import { TextSelection, Transaction } from "@tiptap/pm/state";
import { calculateItemRange } from "../helpers";

/**
 * Wraps list items in `range` to a list.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
function wrapItems({
  listType,
  itemType,
  tr,
  range,
}: {
  listType: NodeType;
  itemType: NodeType;
  tr: Transaction;
  range: NodeRange;
}): boolean {
  const oldList = range.parent;

  // A slice that contianes all selected list items
  const slice: Slice = tr.doc.slice(range.start, range.end);

  if (
    oldList.type === listType &&
    slice.content.firstChild?.type === itemType
  ) {
    return false;
  }

  const newItems: PMNode[] = [];

  for (let i = 0; i < slice.content.childCount; i++) {
    const oldItem = slice.content.child(i);
    if (!itemType.validContent(oldItem.content)) {
      return false;
    }

    const newItem = itemType.createChecked(null, oldItem.content);
    newItems.push(newItem);
  }

  const newList = listType.createChecked(null, newItems);

  tr.replaceRange(
    range.start,
    range.end,
    new Slice(Fragment.from(newList), 0, 0)
  );
  return true;
}

/**
 * Wraps existed list items to a new type of list, which only containes these list items.
 *
 * @remarks
 *
 * @example
 *
 * Here is some pseudo-code to show the purpose of this function:
 *
 * before:
 *
 * ```html
 *  <ul>
 *    <li>item A</li>
 *    <li>item B<!-- cursor_start --></li>
 *    <li>item C<!-- cursor_end --></li>
 *    <li>item D</li>
 *  </ul>
 * ```
 *
 * after:
 *
 * ```html
 *  <ul>
 *    <li>item A</li>
 *  </ul>
 *  <ol>
 *    <li>item B<!-- cursor_start --></li>
 *    <li>item C<!-- cursor_end --></li>
 *  </ol>
 *  <ul>
 *    <li>item D</li>
 *  </ul>
 * ```
 *
 * @alpha
 *
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function wrapSelectedItems({
  editor,
  listType,
  itemType,
  tr,
}: {
  editor: Editor;
  listType: NodeType;
  itemType: NodeType;
  tr: Transaction;
}): boolean {
  const range = calculateItemRange(editor, tr.selection);

  if (!range) {
    return false;
  }

  const atStart = range.startIndex === 0;

  const { from, to } = tr.selection;

  if (!wrapItems({ listType, itemType, tr, range })) {
    return false;
  }

  tr.setSelection(
    new TextSelection(
      tr.doc.resolve(atStart ? from : from + 2),
      tr.doc.resolve(atStart ? to : to + 2)
    )
  );
  tr.scrollIntoView();

  return true;
}
