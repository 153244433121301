import React, { ComponentProps, ReactNode, forwardRef, useRef } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { ErrorMessage } from "../ErrorMessage";
import { Icon } from "../Icon";
import { colors, easeOutCirc } from "../styles";
import setHTMLInputValue from "../utils/setHTMLInputValue";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Displays an error message when value is invalid */
  errorMessage?: ReactNode;

  errorMessageProps?: Omit<ComponentProps<typeof ErrorMessage>, "children">;

  /** Make the input full width block */
  fullWidth?: boolean;

  /** Classname for the top-level container. */
  className?: string;

  /** Style for the top-level container. */
  style?: React.CSSProperties;

  /** Icon to go alongside with the input
   *
   * @default no icon is set and displayed
   * */
  iconName?: IconName;

  /**
   * Whether the input field can be cleared
   *
   * @default the clear button is not enabled
   *
   */
  clearable?: boolean;
}

/**
 * An input field where users can enter data.
 *
 * Inputs are **inline** by default. This means they can be used inside `Text`
 * components. They will automattically inherit parent font styles.
 *
 * _props extends `input` attributes_
 */
export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const innerRef = useRef<HTMLInputElement>(null);
  const inputElement = ref || innerRef;
  const {
    errorMessage,
    errorMessageProps,
    className,
    style,
    fullWidth,
    iconName,
    clearable,
    ...inputProps
  } = props;

  return (
    <Container className={className} style={style} fullWidth={fullWidth}>
      {iconName && <InputIcon iconName={iconName} />}
      <BaseInput
        ref={inputElement}
        fullWidth={fullWidth}
        errorMessage={errorMessage}
        iconName={iconName}
        clearable={clearable}
        {...inputProps}
      />
      {clearable && (
        <ClearIcon
          onClick={() => {
            if (typeof inputElement === "function") {
              // Do nothing for now. This bit is not needed.
            } else {
              if (inputElement != null && inputElement.current) {
                setHTMLInputValue(inputElement.current, "");
              }
            }
          }}
          iconName={"Close"}
        />
      )}
      {errorMessage && (
        <ErrorMessage fullWidth={fullWidth} {...errorMessageProps}>
          {errorMessage}
        </ErrorMessage>
      )}
    </Container>
  );
});

const InputIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 12px;
  height: auto;
  transform: translateY(-50%);
`;

const ClearIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 12px;
  height: auto;
  transform: translateY(-50%);
  fill: hsla(220, 25%, 48%, 1);

  &:hover {
    cursor: pointer;
  }
`;

const Container = styled.span<{ fullWidth?: boolean }>`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: ${(p) => (p.fullWidth ? "100%" : "180px")};
  min-width: 180px;
`;

const BaseInput = styled.input<InputProps>`
  width: ${(p) => (p.fullWidth ? "100%" : "180px")};
  min-width: 180px;
  min-height: 36px;
  padding: 0.5em ${(p) => (p.clearable ? "calc(0.75em + 36px)" : "0.75em")}
    0.5em ${(p) => (p.iconName ? "calc(0.5em + 36px)" : "0.5em")};
  margin: 0;
  box-sizing: border-box;

  font-size: 1em;
  font-weight: inherit;
  color: inherit;
  line-height: 1.5;

  border: 1px solid ${(p) => (p.errorMessage ? p.theme.fail : colors.grey400)};
  border-radius: 2px;
  background: ${colors.white100};
  text-overflow: ellipsis;

  transition: background 0.2s ${easeOutCirc};
  outline: none;

  &::placeholder {
    font-size: 1em;
    letter-spacing: 0.3px;
    color: ${colors.grey500};
  }
  &:focus {
    background: ${colors.white100};
    border: 1px solid ${colors.blue500};
    box-shadow: 0px 0px 5px rgba(0, 170, 255, 0.65);
  }

  &:hover::placeholder,
  &:focus::placeholder {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.36;
    background: ${colors.white200};
  }
`;
