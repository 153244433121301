import { Divider, Spacer, typography } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useAppSelector } from "@/data/hooks";
import { selectIsExam, selectReferencingStyle } from "@/features/assignment";
import {
  BodyEditorContent,
  BodyEditorContentReadOnly,
  ReferencesEditorContent,
  ReferencesEditorContentReadOnly,
} from "ui/editor";

import { selectCanEdit, selectSessionLock } from "../../work-slice";

interface Props {
  /** Student name for the author label */
  userName?: string;
  /** Make the editors readonly */
  isReadOnly?: boolean;
  /** Show a visual disabled state on a readonly Work. */
  disabled?: boolean;
  /** Optionally enable hanging indent in the references section */
  enableHangingIndent?: boolean;
}

/**
 * Arranges the main work space — including a main Body Editor which is stacked
 * on top of a Reference Editor.
 *
 * The main body area is wrapped in a special Container element that vertically
 * positiones it in the page when empty.
 */
export function WorkEditor(props: Props) {
  const { userName, isReadOnly, disabled, enableHangingIndent } = props;

  const sessionLock = useAppSelector(selectSessionLock);
  const canEdit = useAppSelector(selectCanEdit);
  const isLocked = sessionLock || isReadOnly || !canEdit;

  const isExam = useAppSelector(selectIsExam);
  const referencingStyle = useAppSelector(selectReferencingStyle);
  const hideReferences = isExam && referencingStyle === null;

  const body = isLocked ? <BodyEditorContentReadOnly /> : <BodyEditorContent />;
  const references = isLocked ? (
    <ReferencesEditorContentReadOnly
      enableHangingIndent={enableHangingIndent}
    />
  ) : (
    <ReferencesEditorContent />
  );

  return (
    <Container lowerOpacity={disabled || sessionLock || !canEdit}>
      <BodyContainer name={userName} stretchBody={!sessionLock && !isReadOnly}>
        {body}
        {hideReferences && <Spacer spacing={104} />}
      </BodyContainer>
      {!hideReferences && (
        <>
          <Divider />
          {references}
        </>
      )}
    </Container>
  );
}

const Container = styled.div<{ lowerOpacity?: boolean }>`
  height: 100%;
  opacity: ${(p) => (p.lowerOpacity ? 0.36 : 1)};

  /* Sets displayTwo styles first child titles */
  .ProseMirror > h1:first-child {
    font-family: ${typography["displayTwo"].fontFamily};
    font-size: ${typography["displayTwo"].fontSize};
    font-weight: ${typography["displayTwo"].fontWeight};
    padding-top: ${typography["displayTwo"].paddingTop};
    padding-bottom: ${typography["displayTwo"].paddingBottom};
    line-height: ${typography["displayTwo"].lineHeight};
    letter-spacing: ${typography["displayTwo"].letterSpacing};
  }
`;

const BodyContainer = styled.div<{ name?: string; stretchBody?: boolean }>`
  display: ${(p) => (p.stretchBody ? "flex" : "block")};
  flex-direction: column;
  min-height: ${(p) => (p.stretchBody ? "100%" : "unset")};
  box-sizing: border-box;

  /* A label for the Author positioned under the body title */
  h1:first-child::after {
    content: ${(p) => (p.name ? `"By ${p.name}"` : "")};
    display: block;
    font-family: ${typography["label"].fontFamily};
    font-size: ${typography["label"].fontSize};
    font-weight: ${typography["label"].fontWeight};
    padding-top: ${typography["label"].paddingTop};
    padding-bottom: ${typography["label"].paddingBottom};
    line-height: ${typography["label"].lineHeight};
    letter-spacing: ${typography["label"].letterSpacing};
    text-transform: ${typography["label"].textTransform};

    color: ${(p) => p.theme.text.shade1};
    margin: 9px 0;
    user-select: none;
    cursor: text;
    pointer-events: none;
  }
`;
