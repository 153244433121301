import { NodeRange, Slice, Fragment } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { ReplaceAroundStep } from "@tiptap/pm/transform";

/**
 * indent Sibling of list to become a sublist when a list item is
 * outdented
 * ported from https://github.com/remirror/remirror/blob/9014d98411459968cf5aaac8ec9cf01e8bcd1c85/packages/remirror__extension-list/src/list-command-dedent.ts
 */
export function indentSiblingsOfItems(
  tr: Transaction,
  range: NodeRange
): NodeRange {
  const selectedList = range.parent;
  const lastSelectedItem = range.parent.child(range.endIndex - 1);

  const endOfRange = range.end;
  const endOfSelectedList = range.$to.end(range.depth);

  if (endOfRange < endOfSelectedList) {
    // There are sibling items after the selected items, which must become
    // children of the last item
    tr.step(
      new ReplaceAroundStep(
        endOfRange - 1,
        endOfSelectedList,
        endOfRange,
        endOfSelectedList,
        new Slice(
          Fragment.from(
            lastSelectedItem.type.create(null, selectedList.copy())
          ),
          1,
          0
        ),
        1,
        true
      )
    );
    return new NodeRange(
      tr.doc.resolve(range.$from.pos),
      tr.doc.resolve(endOfSelectedList),
      range.depth
    );
  }

  return range;
}
