import styled from "styled-components";

interface Props extends React.HTMLAttributes<HTMLAnchorElement> {
  /** Navigate to a new tab */
  newTab?: boolean;
}

/**
 * Anchored is a simple utility that can be used to wrap a child component with
 * an `<a>` tag.
 *
 * Wrap any of the CUI buttons to use it to navigate to an external URL (e.g. a
 * website page). Tab index is managed for you, so ally is preserved.
 */
export const Anchored = styled.a.attrs<Props>(p => ({
  ...(p.newTab && {
    target: "_blank",
    rel: "noopener noreferrer"
  }),
  tabIndex: -1
}))<Props>`
  text-decoration: none;
  color: inherit;
  outline: none;
`;
