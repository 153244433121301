import { Editor } from "@tiptap/core";
import { Decoration } from "@tiptap/pm/view";

import { ImageWidth } from "../image/types";
import {
  compressImage,
  getImageWidth,
  imageMimes,
  isAllowedMimeType,
  readImageFile,
} from "./file-utils";
import {
  UploadPlaceholderAction,
  addErrorPlaceholder,
  addImagePlaceholder,
  findPlaceholdersAtPos,
  findUploadPlaceholder,
  removePlaceholder,
  setImageUploaderTransactionMeta,
} from "./image-uploader-plugin";
import { uploadImageToServer } from "./uploadImageToServer";

/**
 * Insert one of more images into the Editor.
 *
 * The images are all compressed (client-side) and uploaded to the Cadmus server
 * API. The images are inserted in the current selection position by finding the
 * correct location the image can be inserted.
 *
 * The function returns while the image uploading and insertion happens
 * asynchronously.
 *
 * @param editor Editor to insert the images into
 * @param files array of image files to upload and insert with its caption
 * @param insertAt optional override position to insert the images.
 */
export async function insertImages(
  editor: Editor,
  files: Array<[File, string | undefined]>,
  insertAt?: number
) {
  const { tr, doc } = editor.view.state;
  const $pos = insertAt ? doc.resolve(insertAt) : tr.selection.$from;
  const node = $pos.node($pos.depth);
  const isEmpty = node?.content.size === 0;
  let pos = $pos.pos;

  // If placed inside an empty node, replace the node with image node.
  // Otherwise, add the image **after** the current node.
  if (isEmpty) {
    const from = $pos.before($pos.depth);
    const to = from + node.nodeSize;
    tr.delete(from, to);
    pos = from;
  } else {
    pos = $pos.after($pos.depth + 1);
  }

  const length = files.length;
  const placeholders = findPlaceholdersAtPos(editor.view.state, pos);
  const start = lowestDecoSide(placeholders) - length;

  await Promise.all(
    files.map((file, index) => uploadImage(editor, file, pos, start + index))
  );
}

// Get the first image upload placeholder decoration's side from a list of
// decorations so that we can put a new decoration on the right spot without
// replacing other ones.
function lowestDecoSide(decos?: Decoration[] | null): number {
  let lowest = 0;
  if (decos) {
    decos.forEach((deco) => {
      if (deco.spec.side < lowest) {
        lowest = deco.spec.side;
      }
    });
  }
  return lowest;
}

async function uploadImage(
  editor: Editor,
  file: [File, string | undefined],
  pos: number,
  side?: number
) {
  // "unique" id for this upload
  const id = {};
  const mimes = await imageMimes(file[0]);

  const isAllowed = !!mimes.find((mime) => isAllowedMimeType(mime));
  const view = editor.view;

  if (!isAllowed) {
    setImageUploaderTransactionMeta(editor, [
      {
        type: "addError",
        id,
        pos,
        message: "Image file type is not supported",
      },
    ]);
    return;
  }

  try {
    const compressedFile = await compressImage(file[0]);
    const src = await readImageFile(compressedFile);
    const width = await getImageWidth(compressedFile);

    setImageUploaderTransactionMeta(editor, [
      addImagePlaceholder({
        id,
        pos,
        src,
        width,
        side,
      }),
    ]);

    const imgSrc = await uploadImageToServer(editor, compressedFile);
    const placeholderPos = findUploadPlaceholder(view.state, id);

    // Content around the placeholder has been deleted, drop image
    if (placeholderPos === null) return;
    const placeholderText = file[1] ?? "";
    // Insert image node
    editor.commands.setImage({
      src: imgSrc,
      width: width >= 800 ? ImageWidth.FitToText : ImageWidth.Original,
      position: placeholderPos,
      hasCaption: file[1] && file[1] !== "" ? true : false,
      caption: placeholderText,
    });
    /**
     * reposition placeholders on the same position,
     * either keeping it or moving it to after the inserted image node
     * to preserve the insertion order
     */
    const placeholderMetas = repositionedPlaceholders(
      editor,
      placeholderPos,
      placeholderText.length,
      id
    );
    setImageUploaderTransactionMeta(editor, placeholderMetas);
  } catch (error) {
    console.error(error);
    const placeholderPos = findUploadPlaceholder(view.state, id);
    if (placeholderPos !== null) {
      setImageUploaderTransactionMeta(editor, [
        removePlaceholder(id),
        addErrorPlaceholder({ id, pos: placeholderPos }),
      ]);
    } else {
      setImageUploaderTransactionMeta(editor, [
        addErrorPlaceholder({ id, pos }),
      ]);
    }
  }
}

/**
 * Helper function to repositioned upload placeholder widget(s).
 * As the upload happens asynchronously, the insertion order of the image
 * would be different to when it got uploaded and truely inserted to the
 * editor state. Hence the need to move the placeholder widget around if image
 * at the start or mid of the order ended up uploaded first, all the upload
 * widget that is after the uploaded image widget need to be moved to after the
 * inserted node to retain the order
 */
function repositionedPlaceholders(
  editor: Editor,
  placeholderPos: number,
  captionLength: number,
  deletedId: object
): UploadPlaceholderAction[] {
  const placeholders = findPlaceholdersAtPos(editor.view.state, placeholderPos);
  const uploadedPlaceholder = placeholders?.find(
    (deco) => deco.spec.id === deletedId
  );
  const uploadedSide = uploadedPlaceholder?.spec.side;
  const movedPlaceholders = placeholders?.filter(
    (deco) => deco.spec.side > uploadedSide
  );

  const placeholderMetas: UploadPlaceholderAction[] = [
    removePlaceholder(deletedId),
  ];

  // re organized widget to keep the insert order
  if (movedPlaceholders) {
    placeholderMetas.push(
      ...movedPlaceholders.map((deco) => removePlaceholder(deco.spec.id))
    );
    movedPlaceholders.forEach((deco) => {
      const { id, width, src } = deco.spec;
      if (id && width && src) {
        placeholderMetas.push(
          // Move other image placeholders position to inserted image's caption's
          // length + 2 to make sure that the rest of the images inserted on the
          // right position
          addImagePlaceholder({
            id,
            width,
            src,
            pos: placeholderPos + captionLength + 2,
          })
        );
      }
    });
  }
  return placeholderMetas;
}
