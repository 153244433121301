import { IconProps } from "./types";

export const Vector5X5Icon = (props: IconProps) => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" {...props}>
      <path d="M5 3.94531H7V5.94531H5V3.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2.94531H8V6.94531H4V2.94531ZM5 3.94531V5.94531H7V3.94531H5Z"
        fill="#7070A9"
      />
      <path d="M10 3.94531H12V5.94531H10V3.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.94531H13V6.94531H9V2.94531ZM10 3.94531V5.94531H12V3.94531H10Z"
        fill="#7070A9"
      />
      <path d="M15 3.94531H17V5.94531H15V3.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2.94531H18V6.94531H14V2.94531ZM15 3.94531V5.94531H17V3.94531H15Z"
        fill="#7070A9"
      />
      <path d="M20 3.94531H22V5.94531H20V3.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 2.94531H23V6.94531H19V2.94531ZM20 3.94531V5.94531H22V3.94531H20Z"
        fill="#7070A9"
      />
      <path d="M25 3.94531H27V5.94531H25V3.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 2.94531H28V6.94531H24V2.94531ZM25 3.94531V5.94531H27V3.94531H25Z"
        fill="#7070A9"
      />
      <path d="M5 8.94531H7V10.9453H5V8.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.94531H8V11.9453H4V7.94531ZM5 8.94531V10.9453H7V8.94531H5Z"
        fill="#7070A9"
      />
      <path d="M10 8.94531H12V10.9453H10V8.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.94531H13V11.9453H9V7.94531ZM10 8.94531V10.9453H12V8.94531H10Z"
        fill="#7070A9"
      />
      <path d="M15 8.94531H17V10.9453H15V8.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7.94531H18V11.9453H14V7.94531ZM15 8.94531V10.9453H17V8.94531H15Z"
        fill="#7070A9"
      />
      <path d="M20 8.94531H22V10.9453H20V8.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7.94531H23V11.9453H19V7.94531ZM20 8.94531V10.9453H22V8.94531H20Z"
        fill="#7070A9"
      />
      <path d="M25 8.94531H27V10.9453H25V8.94531Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 7.94531H28V11.9453H24V7.94531ZM25 8.94531V10.9453H27V8.94531H25Z"
        fill="#7070A9"
      />
      <path d="M5 13.9453H7V15.9453H5V13.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12.9453H8V16.9453H4V12.9453ZM5 13.9453V15.9453H7V13.9453H5Z"
        fill="#7070A9"
      />
      <path d="M10 13.9453H12V15.9453H10V13.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 12.9453H13V16.9453H9V12.9453ZM10 13.9453V15.9453H12V13.9453H10Z"
        fill="#7070A9"
      />
      <path d="M15 13.9453H17V15.9453H15V13.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 12.9453H18V16.9453H14V12.9453ZM15 13.9453V15.9453H17V13.9453H15Z"
        fill="#7070A9"
      />
      <path d="M20 13.9453H22V15.9453H20V13.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12.9453H23V16.9453H19V12.9453ZM20 13.9453V15.9453H22V13.9453H20Z"
        fill="#7070A9"
      />
      <path d="M25 13.9453H27V15.9453H25V13.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12.9453H28V16.9453H24V12.9453ZM25 13.9453V15.9453H27V13.9453H25Z"
        fill="#7070A9"
      />
      <path d="M5 18.9453H7V20.9453H5V18.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17.9453H8V21.9453H4V17.9453ZM5 18.9453V20.9453H7V18.9453H5Z"
        fill="#7070A9"
      />
      <path d="M10 18.9453H12V20.9453H10V18.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.9453H13V21.9453H9V17.9453ZM10 18.9453V20.9453H12V18.9453H10Z"
        fill="#7070A9"
      />
      <path d="M15 18.9453H17V20.9453H15V18.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 17.9453H18V21.9453H14V17.9453ZM15 18.9453V20.9453H17V18.9453H15Z"
        fill="#7070A9"
      />
      <path d="M20 18.9453H22V20.9453H20V18.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 17.9453H23V21.9453H19V17.9453ZM20 18.9453V20.9453H22V18.9453H20Z"
        fill="#7070A9"
      />
      <path d="M25 18.9453H27V20.9453H25V18.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 17.9453H28V21.9453H24V17.9453ZM25 18.9453V20.9453H27V18.9453H25Z"
        fill="#7070A9"
      />
      <path d="M5 23.9453H7V25.9453H5V23.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 22.9453H8V26.9453H4V22.9453ZM5 23.9453V25.9453H7V23.9453H5Z"
        fill="#7070A9"
      />
      <path d="M10 23.9453H12V25.9453H10V23.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 22.9453H13V26.9453H9V22.9453ZM10 23.9453V25.9453H12V23.9453H10Z"
        fill="#7070A9"
      />
      <path d="M15 23.9453H17V25.9453H15V23.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 22.9453H18V26.9453H14V22.9453ZM15 23.9453V25.9453H17V23.9453H15Z"
        fill="#7070A9"
      />
      <path d="M20 23.9453H22V25.9453H20V23.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 22.9453H23V26.9453H19V22.9453ZM20 23.9453V25.9453H22V23.9453H20Z"
        fill="#7070A9"
      />
      <path d="M25 23.9453H27V25.9453H25V23.9453Z" fill="#8C8CBA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 22.9453H28V26.9453H24V22.9453ZM25 23.9453V25.9453H27V23.9453H25Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8746 3.79893C29.8746 3.43994 29.5836 3.14893 29.2246 3.14893V2.14893C30.1359 2.14893 30.8746 2.88766 30.8746 3.79893V26.2009C30.8746 27.1122 30.1359 27.8509 29.2246 27.8509V26.8509C29.5836 26.8509 29.8746 26.5599 29.8746 26.2009V3.79893Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.12441 3.79893C2.12441 3.43994 2.41543 3.14893 2.77441 3.14893V2.14893C1.86314 2.14893 1.12441 2.88766 1.12441 3.79893V26.2009C1.12441 27.1122 1.86314 27.8509 2.77441 27.8509V26.8509C2.41543 26.8509 2.12441 26.5599 2.12441 26.2009V3.79893Z"
        fill="#3A517F"
      />
    </svg>
  );
};
