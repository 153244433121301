import client from "client/apollo";
import {
  RequirementsFragment,
  SaveFragment,
  SaveFragmentDoc,
  SubmissionMetadataFragment,
  SubmissionMetadataFragmentDoc,
  SubmissionType,
} from "generated/graphql";

/**
 * Check if the given Sheet requirements specify hanging indent style
 * references.
 */
export function selectHangingIndent(reqs: RequirementsFragment) {
  return reqs.referencingStyle === "APA" || reqs.referencingStyle === "MLA";
}

/**
 * Select the required Referencing Style from the given requirements or default
 * to `APA`.
 */
export function selectReferencingStyle(reqs: RequirementsFragment | null) {
  return reqs && reqs.referencingStyle ? reqs.referencingStyle : "APA";
}

/**
 * Read the latest `Save` value from the cache.
 */
export function readLatestSave(): SaveFragment | null {
  return client.readFragment({
    id: "Save:latest_save",
    fragment: SaveFragmentDoc,
  });
}

/**
 * Read a `Submission` value of the given type from the cache.
 */
export function readSubmissionMetadata(
  typ: SubmissionType
): SubmissionMetadataFragment | null {
  return client.readFragment({
    id: `Submission:{"type":"${typ}"}`,
    fragment: SubmissionMetadataFragmentDoc,
  });
}
