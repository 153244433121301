import { IconProps } from "./types";

export const VectorComma1X2Icon = (props: IconProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.84966 7.53254C4.84966 6.89723 5.36469 6.3822 6 6.3822V5.3822C4.8124 5.3822 3.84966 6.34494 3.84966 7.53254V18.468C3.84966 19.6556 4.8124 20.6183 6 20.6183V19.6183C5.36468 19.6183 4.84966 19.1033 4.84966 18.468V7.53254Z"
        fill="#3A517F"
      />
      <path
        d="M7 11.0502C7 11.0226 7.02239 11.0002 7.05 11.0002H10.95C10.9776 11.0002 11 11.0226 11 11.0502V14.9502C11 14.9779 10.9776 15.0002 10.95 15.0002H7.05C7.02239 15.0002 7 14.9779 7 14.9502V11.0502Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.05 10.0002H10.95C11.5299 10.0002 12 10.4703 12 11.0502V14.9502C12 15.5301 11.5299 16.0002 10.95 16.0002H7.05C6.4701 16.0002 6 15.5301 6 14.9502V11.0502C6 10.4703 6.4701 10.0002 7.05 10.0002ZM7.05 11.0002C7.02239 11.0002 7 11.0226 7 11.0502V14.9502C7 14.9779 7.02239 15.0002 7.05 15.0002H10.95C10.9776 15.0002 11 14.9779 11 14.9502V11.0502C11 11.0226 10.9776 11.0002 10.95 11.0002H7.05Z"
        fill="#7070A9"
      />
      <path
        d="M12.152 16.2962C12.008 16.1522 11.936 15.9802 11.936 15.7802C11.936 15.5802 12 15.4082 12.128 15.2642C12.264 15.1202 12.436 15.0482 12.644 15.0482C12.948 15.0482 13.168 15.1762 13.304 15.4322C13.448 15.6882 13.52 16.0122 13.52 16.4042C13.52 16.5562 13.512 16.6962 13.496 16.8242C13.384 17.5362 13.092 18.1482 12.62 18.6602C12.508 18.7722 12.436 18.8282 12.404 18.8282C12.38 18.8282 12.336 18.7962 12.272 18.7322C12.208 18.6682 12.164 18.6202 12.14 18.5882C12.132 18.5482 12.172 18.4802 12.26 18.3842C12.756 17.8802 13.028 17.2642 13.076 16.5362V16.3922C13.068 16.3922 13.044 16.4042 13.004 16.4282C12.9 16.4762 12.792 16.5002 12.68 16.5002C12.472 16.5002 12.296 16.4322 12.152 16.2962Z"
        fill="#3A517F"
      />
      <path
        d="M15 11.0502C15 11.0226 15.0224 11.0002 15.05 11.0002H18.95C18.9776 11.0002 19 11.0226 19 11.0502V14.9502C19 14.9779 18.9776 15.0002 18.95 15.0002H15.05C15.0224 15.0002 15 14.9779 15 14.9502V11.0502Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.05 10.0002H18.95C19.5299 10.0002 20 10.4703 20 11.0502V14.9502C20 15.5301 19.5299 16.0002 18.95 16.0002H15.05C14.4701 16.0002 14 15.5301 14 14.9502V11.0502C14 10.4703 14.4701 10.0002 15.05 10.0002ZM15.05 11.0002C15.0224 11.0002 15 11.0226 15 11.0502V14.9502C15 14.9779 15.0224 15.0002 15.05 15.0002H18.95C18.9776 15.0002 19 14.9779 19 14.9502V11.0502C19 11.0226 18.9776 11.0002 18.95 11.0002H15.05Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1503 7.53254C21.1503 6.89723 20.6353 6.3822 20 6.3822V5.3822C21.1876 5.3822 22.1503 6.34494 22.1503 7.53254V18.468C22.1503 19.6556 21.1876 20.6183 20 20.6183V19.6183C20.6353 19.6183 21.1503 19.1033 21.1503 18.468V7.53254Z"
        fill="#3A517F"
      />
    </svg>
  );
};
