import EventEmitter from "./EventEmitter";

/** A spelling suggestion on a phrase/word. */
export interface SpellingSuggestion {
  phrase: string;
  candidates: string[];
}

/**
 * Client wrapper for managing suggestion requests to the server and providing a
 * cache-based spelling suggestion lookup interface.
 *
 * The client also implements the `EventEmitter` interface to emit string events
 * on certain lifecycle events. Callbacks can be attached and detached on these
 * events. These events are:
 *
 * - `ignoreListUpdated` with the payload of the word that was freshly added to
 *     the ignore list.
 * - `newSuggestions` when the dictionary is updated with new spelling
 *   suggestions, with the payload of the array of `SpellingSuggestion`.
 *
 */
export class SuggestionClient extends EventEmitter {
  /** Array of words that are to be ignored for suggestions. */
  private ignoredWords: string[];
  /** Map of words to their spelling suggestions fetched from the server. */
  private dictionary: Map<string, string[]>;
  /** Set of all words that have been processed by the server. */
  private processedWords: Set<string>;
  /** API endpoint providing the spelling Suggestions service. */
  private endpoint: string;

  /**
   * Create a new instance of the SuggestionClient.
   *
   * It is recommended to share this instance as much as possible to reap the
   * maximum benefits of a spelling dictionary and minimising server requests.
   *
   * @param endpoint full HTTP endpoint to the suggest API
   */
  static create(endpoint: string) {
    return new SuggestionClient(endpoint);
  }

  constructor(endpoint: string) {
    super();
    this.ignoredWords = getIgnoreList();
    this.dictionary = new Map();
    this.processedWords = new Set();
    this.endpoint = endpoint;
  }

  /**
   * Spelling suggestions lookup for a given phrase
   *
   * The lookup is local and doesn't involve the server. To ensure the local
   * dictionary is populated use the `this.requestSuggestions` method.
   */
  public lookup(phrase: string): string[] | null {
    return this.dictionary.get(phrase) ?? null;
  }

  /**
   * Ignore a word from current and future spelling suggestions.
   *
   * This will fire an event `ignoreListUpdated` which should be used to remove
   * any existing suggestion markup on the word.
   */
  public ignoreWord(word: string) {
    this.ignoredWords.push(word);
    this.dictionary.delete(word);
    setIgnoreList(this.ignoredWords);
    this.emit("ignoreListUpdated", word);
  }

  /**
   * Make a suggestions request to the server for the given words.
   *
   * Any previously processed word is filtered out from the given `words`. If
   * this is should be skipped, provide the `force` optional argument.
   *
   * @param words Set of words to send to the server
   * @param force optionally skip filtering of previously sent words
   * @return array of spelling suggestions for the words
   * @throws Error for network errors in calling the suggest API
   */
  public async requestSuggestions(
    words: Set<string>,
    force = false
  ): Promise<SpellingSuggestion[]> {
    const unprocessedWords = force
      ? Array.from(words)
      : Array.from(words).filter((w) => !this.processedWords.has(w));
    const text = unprocessedWords.join(" ").trim();

    // Skip empty requests
    if (text === "") return [];

    const response = await fetch(this.endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
      body: JSON.stringify({
        key: "ignore",
        text,
      }),
    });

    const data: { suggestions: SpellingSuggestion[] } = await response.json();

    if (response.ok) {
      this.addProcessedWords(unprocessedWords);
    }

    if (response.ok && data.suggestions && data.suggestions.length > 0) {
      this.addSuggestions(data.suggestions);
      return data.suggestions;
    }

    if (!response.ok) {
      return Promise.reject("Error fetching spelling suggestions");
    }

    return [];
  }

  /**
   * Update the dictionary with the list of new suggestions.
   *
   * The ignored word list is consulted before performing the update to keep the
   * ignored words out of the dictionary.
   */
  private addSuggestions(suggestions: SpellingSuggestion[]) {
    const filteredSuggestions = suggestions.filter(
      ({ phrase }) => !this.ignoredWords.includes(phrase)
    );
    filteredSuggestions.forEach(({ phrase, candidates }) => {
      this.dictionary.set(phrase, candidates);
    });

    if (filteredSuggestions.length > 0) {
      this.emit("newSuggestions", filteredSuggestions);
    }
  }

  /**
   * Update the list of known processed words.
   */
  private addProcessedWords(words: string[]) {
    words.forEach((w) => this.processedWords.add(w));
  }
}

//////////////////////////////////////////////////////////////////////////////
// Ignore List management based on Local Storage                            //
//////////////////////////////////////////////////////////////////////////////

const ignoreListLocalStorage = "ignore_spellings";

/** Read the word igore list from localstorage (or fail doing nothing) */
export function getIgnoreList() {
  try {
    const ignoreList = window.localStorage.getItem(ignoreListLocalStorage);
    if (ignoreList) return JSON.parse(ignoreList);
  } catch (e) {}

  return [];
}

/** Update or set the Ignore list in localstorage */
export function setIgnoreList(list: string[]) {
  try {
    window.localStorage.setItem(ignoreListLocalStorage, JSON.stringify(list));
  } catch (e) {
    console.log(e);
  }
}
