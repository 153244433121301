import { MouseEvent } from "react";
import styled from "styled-components";

import { ControlButton } from "../ControlButton";
import { colors } from "../styles";

export interface EditableActionsProps {
  onCopyLink?: VoidFunction;
  onOpenInBrowser?: VoidFunction;
  onEdit?: VoidFunction;
}

const actionButtonSizeInPx = 28;

/** Actions shown on an editable resource. */
export const EditableActions = ({
  onCopyLink,
  onOpenInBrowser,
  onEdit,
}: EditableActionsProps) => {
  return (
    <Container>
      {onCopyLink && (
        <ControlButton
          aria-label="Copy resource link"
          iconName="Copy"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onCopyLink)}
        />
      )}
      {onOpenInBrowser && (
        <ControlButton
          aria-label="Open in browser"
          noPadding
          size={actionButtonSizeInPx}
          iconName="Open"
          onClick={stopBubbling(onOpenInBrowser)}
        />
      )}
      {onEdit && (
        <ControlButton
          aria-label="Edit"
          noPadding
          size={actionButtonSizeInPx}
          iconName="Edit"
          onClick={stopBubbling(onEdit)}
        />
      )}
    </Container>
  );
};

export interface EditingActionsProps {
  onDone?: VoidFunction;
  onDelete?: VoidFunction;
  onCancel?: VoidFunction;
}

/** Actions shown while editing a resource. */
export const EditingActions = ({
  onDone,
  onDelete,
  onCancel,
}: EditingActionsProps) => {
  return (
    <Container>
      {onDone && (
        <ControlButton
          aria-label="Done"
          iconName="Tick"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onDone)}
        />
      )}
      {onDelete && (
        <ControlButton
          aria-label="Delete resource"
          iconName="Trash"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onDelete)}
        />
      )}
      {onCancel && (
        <ControlButton
          aria-label="Cancel"
          iconName="Close"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onCancel)}
        />
      )}
    </Container>
  );
};

export interface ViewOnlyActionsProps {
  onCopyLink?: VoidFunction;
  onOpenInBrowser?: VoidFunction;
  onDownload?: VoidFunction;
}

/** Actions available on a view only resource. */
export const ViewOnlyActions = ({
  onCopyLink,
  onOpenInBrowser,
  onDownload,
}: ViewOnlyActionsProps) => {
  return (
    <Container>
      {onCopyLink && (
        <ControlButton
          aria-label="Copy resource link"
          iconName="Copy"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onCopyLink)}
        />
      )}
      {onOpenInBrowser && (
        <ControlButton
          aria-label="Open in browser"
          iconName="Open"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onOpenInBrowser)}
        />
      )}
      {onDownload && (
        <ControlButton
          aria-label="Download"
          iconName="Download"
          noPadding
          size={actionButtonSizeInPx}
          onClick={stopBubbling(onDownload)}
        />
      )}
    </Container>
  );
};

const stopBubbling = (callback: VoidFunction) => (e: MouseEvent) => {
  e.stopPropagation();
  callback();
};

const Container = styled.div`
  display: flex;
  gap: 2px;
  background-color: ${colors.white100};
  border-radius: 4px;
  padding: 4px 6px;
  box-shadow: 0px 0px 1px rgba(71, 92, 133, 0.36),
    0px 1px 3px rgba(71, 92, 133, 0.15);
`;
