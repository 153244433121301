import { ControlButton } from "@vericus/cadmus-ui";

import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table Menu item for merge/split cell(s)
 */
export function MergeSplitMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const canMergeOrSplit = editor.can().mergeOrSplit();
  if (!canMergeOrSplit) return null;
  const canMerge = editor.can().mergeCells();
  const canSplit = editor.can().splitCell();
  return (
    <>
      {canMerge && (
        <ControlButton
          aria-label="Merge cells"
          iconName="MergeCells"
          onClick={() => editor.chain().mergeCells().focus().run()}
        />
      )}
      {canSplit && (
        <ControlButton
          aria-label="Split Cell"
          iconName="SplitCells"
          onClick={() => editor.chain().splitCell().focus().run()}
        />
      )}
      <GroupDivider />
    </>
  );
}
