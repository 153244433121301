import { Node, mergeAttributes } from "@tiptap/core";

export interface ListItemOptions {
  HTMLAttributes: Record<string, any>;
}

export const ListItem = Node.create<ListItemOptions>({
  name: "listItem",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "paragraph block*",

  defining: true,

  group: "listItemGroup",

  parseHTML() {
    return [
      {
        tag: "li",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "li",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.cadmusSplitListItem("listItem"),
    };
  },
});
