import { Editor, isList } from "@tiptap/core";
import { NodeRange, ResolvedPos } from "@tiptap/pm/model";

import { isListItem } from "./isListItem";

/**
 * Find parent list and list item of node inside nodeRange if exist
 * otherwise return false
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-command-dedent.ts
 */
export function findParentItem(
  editor: Editor,
  $from: ResolvedPos,
  range: NodeRange
) {
  const parentItem = $from.node(range.depth - 1);
  const parentList = $from.node(range.depth - 2);

  if (
    !isListItem(parentItem.type.name, editor.extensionManager.extensions) ||
    !isList(parentList.type.name, editor.extensionManager.extensions)
  ) {
    return false;
  }

  return { parentItem, parentList };
}
