import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export const SpaceFixPluginKey = new PluginKey("space-fix-plugin");

/**
 * Safari and firefox have issue with justified text on a contenteditable with
 * a `white-space: normal;`` for the whole block. To get around this, the block
 * need to keep style `white-space: pre-wrap` and then decorate the group of white
 * space to have `white-space: normal` to make justified text possible
 * issue is described on https://github.com/ueberdosis/tiptap/issues/2549 and adapted solution from https://github.com/ProseMirror/prosemirror/issues/857#issuecomment-625502056
 */
export const SpaceFix = Extension.create({
  name: "space-fix",
  addProseMirrorPlugins() {
    const firefox = typeof window !== "undefined" && /Firefox/.test(window.navigator.userAgent);
    const safari = typeof window !== "undefined" && /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    return [
      new Plugin({
        key: SpaceFixPluginKey,
        props: {
          decorations(state) {
            const decorations = [] as Decoration[];
            const doc = state.doc;
            let start, match, length;
            const spaces = /\s+/g;

            doc.nodesBetween(0, doc.content.size, (node, position, parent) => {
              const fixCondition =
                firefox ||
                safari ||
                /text-align/.test((parent && parent.attrs && parent.attrs.style) || "");
              // check if it is text node with justified text applied
              if (node.type.isText && fixCondition) {
                const text = node.text || "";
                // find group of white space characted and decorate every single one of them with `white-space: normal`
                // to make the white space collapsible on justified block
                while ((match = spaces.exec(text)) !== null) {
                  start = position + match.index;
                  length = match[0].length;
                  if (match.index + length < match.input.length) {
                    for (let i = 0; i <= length - 1; i += 2) {
                      decorations.push(
                        Decoration.inline(start + i, start + i + 1, {
                          style: "white-space: normal"
                        })
                      );
                    }
                  }
                }
              }
            });

            return DecorationSet.create(doc, decorations);
          }
        }
      })
    ]
  }
})
