import { IconProps } from "./types";

export const UnderlineSegmentIcon = (props: IconProps) => {
  return (
    <svg width="22" height="24" viewBox="0 0 22 24" {...props}>
      <path
        d="M5 6.5C5 6.22386 5.22386 6 5.5 6H16.5C16.7761 6 17 6.22386 17 6.5V17.5C17 17.7761 16.7761 18 16.5 18H5.5C5.22386 18 5 17.7761 5 17.5V6.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7V17H16V7H6ZM5.5 6C5.22386 6 5 6.22386 5 6.5V17.5C5 17.7761 5.22386 18 5.5 18H16.5C16.7761 18 17 17.7761 17 17.5V6.5C17 6.22386 16.7761 6 16.5 6H5.5Z"
        fill="#7070A9"
      />
      <path
        d="M19 21L3 21"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M3 20V22"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M19 20V22"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
    </svg>
  );
};
