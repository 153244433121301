import type { AppStartListening } from "@/data/listenerMiddleware";
import {
  markExamAsOver,
  selectIsExam,
  updateSheet,
} from "@/features/assignment";
import { selectWritingDatePassed } from "@/features/timeline";
import { selectCanSubmitLateExam } from "@/features/work";

/**
 * Start exam related listeners.
 */
export function startExamListeners(startListening: AppStartListening) {
  startExamOverCheckListener(startListening);
}

/**
 * Check if the exam should be considered as over for the current state. This
 * check is only fired once at load when the `assignment/updateSheet` action
 * fires.
 */
export function startExamOverCheckListener(startListening: AppStartListening) {
  let firedOnce = false;
  startListening({
    actionCreator: updateSheet,
    effect: (_action, listenerApi) => {
      if (firedOnce) return;
      firedOnce = true;

      const state = listenerApi.getState();

      if (
        selectIsExam(state) &&
        selectWritingDatePassed(state) &&
        !selectCanSubmitLateExam(state)
      ) {
        listenerApi.dispatch(markExamAsOver());
      }
    },
  });
}
