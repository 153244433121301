import { IconProps } from "./types";

export const Vector3X1Icon = (props: IconProps) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8525 4.59565C18.8525 3.96034 18.3375 3.44531 17.7021 3.44531V2.44531C18.8897 2.44531 19.8525 3.40805 19.8525 4.59565V21.4042C19.8525 22.5918 18.8897 23.5545 17.7021 23.5545V22.5545C18.3375 22.5545 18.8525 22.0395 18.8525 21.4042V4.59565Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14751 4.59565C7.14751 3.96034 7.66254 3.44531 8.29785 3.44531V2.44531C7.11025 2.44531 6.14751 3.40805 6.14751 4.59565V21.4042C6.14751 22.5918 7.11025 23.5545 8.29785 23.5545V22.5545C7.66254 22.5545 7.14751 22.0395 7.14751 21.4042V4.59565Z"
        fill="#3A517F"
      />
      <path
        d="M11.0957 4.0998C11.0957 4.07219 11.1181 4.0498 11.1457 4.0498H15.0457C15.0733 4.0498 15.0957 4.07219 15.0957 4.0998V7.9998C15.0957 8.02742 15.0733 8.0498 15.0457 8.0498H11.1457C11.1181 8.0498 11.0957 8.02742 11.0957 7.9998V4.0998Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1457 3.0498H15.0457C15.6256 3.0498 16.0957 3.51991 16.0957 4.0998V7.9998C16.0957 8.5797 15.6256 9.0498 15.0457 9.0498H11.1457C10.5658 9.0498 10.0957 8.5797 10.0957 7.9998V4.0998C10.0957 3.5199 10.5658 3.0498 11.1457 3.0498ZM11.1457 4.0498C11.1181 4.0498 11.0957 4.07219 11.0957 4.0998V7.9998C11.0957 8.02742 11.1181 8.0498 11.1457 8.0498H15.0457C15.0733 8.0498 15.0957 8.02742 15.0957 7.9998V4.0998C15.0957 4.07219 15.0733 4.0498 15.0457 4.0498H11.1457Z"
        fill="#7070A9"
      />
      <path
        d="M11.0957 11.05C11.0957 11.0224 11.1181 11 11.1457 11H15.0457C15.0733 11 15.0957 11.0224 15.0957 11.05V14.95C15.0957 14.9776 15.0733 15 15.0457 15H11.1457C11.1181 15 11.0957 14.9776 11.0957 14.95V11.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1457 10H15.0457C15.6256 10 16.0957 10.4701 16.0957 11.05V14.95C16.0957 15.5299 15.6256 16 15.0457 16H11.1457C10.5658 16 10.0957 15.5299 10.0957 14.95V11.05C10.0957 10.4701 10.5658 10 11.1457 10ZM11.1457 11C11.1181 11 11.0957 11.0224 11.0957 11.05V14.95C11.0957 14.9776 11.1181 15 11.1457 15H15.0457C15.0733 15 15.0957 14.9776 15.0957 14.95V11.05C15.0957 11.0224 15.0733 11 15.0457 11H11.1457Z"
        fill="#7070A9"
      />
      <path
        d="M11.0957 18.0002C11.0957 17.9726 11.1181 17.9502 11.1457 17.9502H15.0457C15.0733 17.9502 15.0957 17.9726 15.0957 18.0002V21.9002C15.0957 21.9278 15.0733 21.9502 15.0457 21.9502H11.1457C11.1181 21.9502 11.0957 21.9278 11.0957 21.9002V18.0002Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1457 16.9502H15.0457C15.6256 16.9502 16.0957 17.4203 16.0957 18.0002V21.9002C16.0957 22.4801 15.6256 22.9502 15.0457 22.9502H11.1457C10.5658 22.9502 10.0957 22.4801 10.0957 21.9002V18.0002C10.0957 17.4203 10.5658 16.9502 11.1457 16.9502ZM11.1457 17.9502C11.1181 17.9502 11.0957 17.9726 11.0957 18.0002V21.9002C11.0957 21.9278 11.1181 21.9502 11.1457 21.9502H15.0457C15.0733 21.9502 15.0957 21.9278 15.0957 21.9002V18.0002C15.0957 17.9726 15.0733 17.9502 15.0457 17.9502H11.1457Z"
        fill="#7070A9"
      />
    </svg>
  );
};
