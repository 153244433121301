import * as arrows from "./arrows";
import * as functions from "./functions";
import * as greek from "./greek";
import * as matrices from "./matrices";
import * as misc from "./misc";
import * as operations from "./operations";
import * as relations from "./relations";
import { MathKeys } from "./types";

export const keys: MathKeys = {
  Popular: [
    operations.plus,
    operations.minus,
    operations.plusOrMinus,
    operations.multiply,
    operations.dotProduct,
    operations.divide,
    misc.therefore,
    relations.equal,
    relations.notEqualTo,
    relations.lessThan,
    relations.greaterThan,
    relations.lessThanOrEqual,
    relations.greaterThanOrEqual,
    greek.lowerPi,
    misc.infinity,
    misc.proportionality,
    greek.upperDelta,
    operations.niceFraction,
    operations.fraction,
    functions.square,
    functions.power,
    functions.squareRoot,
    functions.root,
    functions.subscript,
    functions.subscriptSuperscript,
    operations.derivativeFunction,
    operations.integral,
    functions.sine,
    functions.cosine,
    functions.tangent,
    greek.lowerAlpha,
    greek.lowerBeta,
    greek.lowerGamma,
    greek.lowerTheta,
    misc.roundBracket,
    misc.sufixBracket,
    misc.squareBracket,
    misc.curlyBracket,
    operations.bracketedFraction,
  ],
  Functions: [
    functions.square,
    functions.power,
    functions.squareRoot,
    functions.root,
    functions.exponential,
    functions.naturalLog,
    functions.logarithm,
    functions.log10,
    functions.subscript,
    functions.subscriptSuperscript,
    functions.sine,
    functions.cosine,
    functions.tangent,
    functions.cotangent,
    functions.secant,
    functions.cosecant,
    functions.inverseSine,
    functions.inverseCosine,
    functions.inverseTan,
    functions.inverseCotTan,
    functions.inverseSec,
    functions.inverseCosec,
    functions.sinh,
    functions.cosh,
    functions.tanh,
    // functions.sech,
    // functions.csch,
    functions.coth,
    greek.lowerTheta,
    functions.degree,
    functions.verticalBars,
    functions.ceiling,
    functions.floor,
    functions.verticalBar,
    functions.lAngle,
    functions.rAngle,
  ],
  Operations: [
    operations.plusOrMinus,
    operations.minusOrPlus,
    operations.multiply,
    operations.dotProduct,
    operations.divide,
    operations.fraction,
    operations.niceFraction,
    operations.bracketedFraction,
    operations.limitFunction,
    operations.derivativeFunction,
    operations.partialDerivativeFunction,
    operations.derivativeSymbols,
    operations.integral,
    operations.doubleIntegral,
    operations.tripleIntegral,
    operations.contourIntegral,
    operations.del,
    operations.setDifference,
    operations.dot,
    operations.ddot,
    operations.summation,
    operations.coproduct,
    operations.product,
    operations.union,
    operations.intersection,
    operations.unionSymbol,
    operations.intersectionSymbol,
    operations.subset,
    operations.superset,
    operations.subsetEq,
    operations.supersetEq,
    operations.subsetNotEq,
    operations.supersetNotEq,
    operations.notSubset,
    operations.notSuperset,
    operations.disjointUnion,
    operations.and,
    operations.or,
    operations.elementOf,
    operations.notElementOf,
    operations.symmetricDifference,
    operations.directSum,
    operations.tensorProduct,
    operations.emptySet,
  ],
  Greek: [
    greek.lowerAlpha,
    greek.lowerBeta,
    greek.lowerGamma,
    greek.lowerDelta,
    greek.lowerEpsilon,
    greek.lowerZeta,
    greek.lowerEta,
    greek.lowerTheta,
    greek.lowerIota,
    greek.lowerKappa,
    greek.lowerLambda,
    greek.lowerMu,
    greek.lowerNu,
    greek.lowerXi,
    greek.lowerOmicron,
    greek.lowerPi,
    greek.lowerRho,
    greek.lowerSigma,
    greek.lowerTau,
    greek.lowerUpsilon,
    greek.lowerPhi,
    greek.lowerChi,
    greek.lowerPsi,
    greek.lowerOmega,
    greek.upperAlpha,
    greek.upperBeta,
    greek.upperGamma,
    greek.upperDelta,
    greek.upperEpsilon,
    greek.upperZeta,
    greek.upperEta,
    greek.upperTheta,
    greek.upperIota,
    greek.upperKappa,
    greek.upperLambda,
    greek.upperMu,
    greek.upperNu,
    greek.upperXi,
    greek.upperOmicron,
    greek.upperPi,
    greek.upperRho,
    greek.upperSigma,
    greek.upperTau,
    greek.upperUpsilon,
    greek.upperPhi,
    greek.upperChi,
    greek.upperPsi,
    greek.upperOmega,
    greek.varKappa,
    greek.varTheta,
    greek.varPi,
    greek.varRho,
    greek.varSigma,
    greek.varPhi,
  ],
  Relations: [
    relations.lessThan,
    relations.greaterThan,
    relations.lessThanOrEqual,
    relations.greaterThanOrEqual,
    relations.notEqualTo,
    relations.approximately,
    relations.lessOrSimillar,
    relations.greaterOrSimillar,
    relations.lesserLesser,
    relations.greaterGreater,
    relations.notLesser,
    relations.notGreater,
    relations.notLesserOrEqual,
    relations.notGreaterOrEqual,
    relations.congruent,
    relations.simillarOrEqual,
    relations.approxeq,
    relations.definedAs,
    relations.asymptotic,
    relations.verticalBar,
    relations.notDivide,
    relations.parallel,
    relations.notParallel,
    relations.perpendicular,
    relations.turnstile,
    relations.doubleTurnstile,
    relations.downTack,
    relations.equalByDef,
  ],
  Arrows: [
    arrows.downArrow,
    arrows.upArrow,
    arrows.leftArrow,
    arrows.rightArrow,
    arrows.leftRightArrow,
    arrows.equilibrium,
    arrows.longLeftArrow,
    arrows.longRightArrow,
    arrows.longleftrightArrow,
    arrows.overleftArrow,
    arrows.overrightArrow,
    arrows.overleftrightArrow,
    arrows.underleftArrow,
    arrows.underrightArrow,
    arrows.underleftrightArrow,
    arrows.leftArrowBetween,
    arrows.rightArrowBetween,
  ],
  Matrices: [
    matrices.vectorComma1X2,
    matrices.vectorComma1X3,
    matrices.vector1X2,
    matrices.vector1X3,
    matrices.vector2X1,
    matrices.vector3X1,
    matrices.vector4X1,
    matrices.vector2X2,
    matrices.vector2X3,
    matrices.vector3X3,
    matrices.vector4X4,
    matrices.vector5X5,
    matrices.openBracketFraction,
    matrices.horizontalDots,
    matrices.diagonalDots,
    matrices.verticalDots,
  ],
  Misc: [
    misc.overbar,
    misc.underbar,
    misc.hat,
    misc.tildeAbove,
    misc.tildeUnderneath,
    misc.overlineSegment,
    misc.underlineSegment,
    misc.therefore,
    misc.because,
    operations.dotProduct,
    misc.infinity,
    misc.proportionality,
    misc.functionComposition,
    greek.lowerPhi,
    misc.forAll,
    misc.thereExist,
    misc.thereNotExist,
    misc.aleph,
    misc.imaginaryUnit,
    misc.naturalNumbers,
    misc.primeNumbers,
    misc.integers,
    misc.rationalNumbers,
    misc.complexNumbers,
    misc.realNumbers,
    misc.hamilton,
    misc.halmos,
    misc.dagger,
  ],
};
