import { useMemo, useState } from "react";

import { ControlButton } from "../ControlButton";
import { Dropdown } from "../Dropdown";
import { Menu, MenuItem } from "../Menu";

export interface Props {
  /** Current selected style name to display. */
  paragraphStyle: ParagraphStyle;
  /** Callback to change the paragraph style. */
  onChange: (style: ParagraphStyle) => void;
  /** Disable paragraph selection. */
  disabled?: boolean;
}

/** Variant of viable paragraph styles **/
export type ParagraphStyle =
  | "paragraph"
  | "blockquote"
  | "heading-one"
  | "heading-two"
  | "heading-three"
  | "heading-four";

/** Pairs of paragraph styles and their display labels. */
const Items: [ParagraphStyle, string][] = [
  ["paragraph", "Paragraph"],
  ["blockquote", "Quote"],
  ["heading-one", "Title"],
  ["heading-two", "Subtitle"],
  ["heading-three", "Heading"],
  ["heading-four", "Subheading"],
];

/** Paragraph style Dropdown selector */
export const ControlParagraph = (props: Props) => {
  const { paragraphStyle, disabled, onChange } = props;

  // Selected paragraph text label
  const selectedText = useMemo(() => {
    const selectedItem = Items.find(([key]) => key === paragraphStyle);
    return selectedItem ? selectedItem[1] : "Paragraph";
  }, [paragraphStyle]);

  // Controls it's own open state
  const [open, setOpen] = useState(false);

  // Handler for a single paragraph select in the menu
  const handleSelect = (style: ParagraphStyle) => {
    onChange(style);
    setOpen(false);
  };

  return (
    <Dropdown
      open={open}
      content={
        <ParagraphMenu selectedLabel={selectedText} onSelect={handleSelect} />
      }
      onOuterClick={() => setOpen(false)}
    >
      <ControlButton
        onClick={() => setOpen(!open)}
        disabled={disabled}
        aria-label="Paragraph Style"
        aria-expanded={open}
        aria-haspopup="true"
        tooltipPosition="top"
        withDownCaret
      >
        {selectedText}
      </ControlButton>
    </Dropdown>
  );
};

interface ParagraphMenuProps {
  selectedLabel: string;
  onSelect: (style: ParagraphStyle) => void;
}

/* Menu of interactable paragraph styles.  */
const ParagraphMenu = (props: ParagraphMenuProps) => (
  <Menu>
    {Items.map(([style, label]) => (
      <MenuItem
        key={style}
        selected={label === props.selectedLabel}
        onClick={() => props.onSelect(style)}
        aria-label={label}
      >
        {label}
      </MenuItem>
    ))}
  </Menu>
);
