import { Node, mergeAttributes, textblockTypeInputRule } from "@tiptap/core";

export interface BlockquoteOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockquote: {
      /**
       * Toggle a blockquote node
       */
      toggleBlockquote: () => ReturnType;
    };
  }
}

export const inputRegex = /^\s*>\s$/gm;

/**
 * Make non nestable blockquote as oposed to tiptap's blockquote
 * that can have any kind of blocks inside it
 */
export const Blockquote = Node.create<BlockquoteOptions>({
  name: "blockquote",
  content: "inline*",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block",

  defining: true,

  parseHTML() {
    return [{ tag: "blockquote" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "blockquote",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      ["p", 0],
    ];
  },

  addCommands() {
    return {
      toggleBlockquote:
        () =>
        ({ commands }) => {
          return commands.toggleNode("blockquote", "paragraph");
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-b": () => this.editor.commands.toggleBlockquote(),
    };
  },

  addInputRules() {
    return [textblockTypeInputRule({ find: inputRegex, type: this.type })];
  },
});
