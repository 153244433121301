import { IconProps } from "./types";

export const Vector4X1Icon = (props: IconProps) => {
  return (
    <svg width="22" height="28" viewBox="0 0 22 28" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8525 2.2375C16.8525 1.60218 16.3375 1.08716 15.7021 1.08716V0.0871582C16.8897 0.0871582 17.8525 1.0499 17.8525 2.2375V25.7624C17.8525 26.95 16.8897 27.9127 15.7021 27.9127V26.9127C16.3375 26.9127 16.8525 26.3977 16.8525 25.7624V2.2375Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.14751 2.2375C5.14751 1.60218 5.66254 1.08716 6.29785 1.08716V0.0871582C5.11025 0.0871582 4.14751 1.0499 4.14751 2.2375V25.7624C4.14751 26.95 5.11025 27.9127 6.29785 27.9127V26.9127C5.66254 26.9127 5.14751 26.3977 5.14751 25.7624V2.2375Z"
        fill="#3A517F"
      />
      <path
        d="M9.0957 8.5749C9.0957 8.54729 9.11809 8.5249 9.1457 8.5249H13.0457C13.0733 8.5249 13.0957 8.54729 13.0957 8.5749V12.4749C13.0957 12.5025 13.0733 12.5249 13.0457 12.5249H9.1457C9.11809 12.5249 9.0957 12.5025 9.0957 12.4749V8.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1457 7.5249H13.0457C13.6256 7.5249 14.0957 7.995 14.0957 8.5749V12.4749C14.0957 13.0548 13.6256 13.5249 13.0457 13.5249H9.1457C8.5658 13.5249 8.0957 13.0548 8.0957 12.4749V8.5749C8.0957 7.995 8.56581 7.5249 9.1457 7.5249ZM9.1457 8.5249C9.11809 8.5249 9.0957 8.54729 9.0957 8.5749V12.4749C9.0957 12.5025 9.11809 12.5249 9.1457 12.5249H13.0457C13.0733 12.5249 13.0957 12.5025 13.0957 12.4749V8.5749C13.0957 8.54729 13.0733 8.5249 13.0457 8.5249H9.1457Z"
        fill="#7070A9"
      />
      <path
        d="M9.0957 1.62471C9.0957 1.59709 9.11809 1.57471 9.1457 1.57471H13.0457C13.0733 1.57471 13.0957 1.59709 13.0957 1.62471V5.52471C13.0957 5.55232 13.0733 5.57471 13.0457 5.57471H9.1457C9.11809 5.57471 9.0957 5.55232 9.0957 5.52471V1.62471Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1457 0.574707H13.0457C13.6256 0.574707 14.0957 1.04481 14.0957 1.62471V5.52471C14.0957 6.10461 13.6256 6.57471 13.0457 6.57471H9.1457C8.5658 6.57471 8.0957 6.1046 8.0957 5.52471V1.62471C8.0957 1.04481 8.56581 0.574707 9.1457 0.574707ZM9.1457 1.57471C9.11809 1.57471 9.0957 1.59709 9.0957 1.62471V5.52471C9.0957 5.55232 9.11809 5.57471 9.1457 5.57471H13.0457C13.0733 5.57471 13.0957 5.55232 13.0957 5.52471V1.62471C13.0957 1.59709 13.0733 1.57471 13.0457 1.57471H9.1457Z"
        fill="#7070A9"
      />
      <path
        d="M9.0957 15.5251C9.0957 15.4975 9.11809 15.4751 9.1457 15.4751H13.0457C13.0733 15.4751 13.0957 15.4975 13.0957 15.5251V19.4251C13.0957 19.4527 13.0733 19.4751 13.0457 19.4751H9.1457C9.11809 19.4751 9.0957 19.4527 9.0957 19.4251V15.5251Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1457 14.4751H13.0457C13.6256 14.4751 14.0957 14.9452 14.0957 15.5251V19.4251C14.0957 20.005 13.6256 20.4751 13.0457 20.4751H9.1457C8.5658 20.4751 8.0957 20.005 8.0957 19.4251V15.5251C8.0957 14.9452 8.56581 14.4751 9.1457 14.4751ZM9.1457 15.4751C9.11809 15.4751 9.0957 15.4975 9.0957 15.5251V19.4251C9.0957 19.4527 9.11809 19.4751 9.1457 19.4751H13.0457C13.0733 19.4751 13.0957 19.4527 13.0957 19.4251V15.5251C13.0957 15.4975 13.0733 15.4751 13.0457 15.4751H9.1457Z"
        fill="#7070A9"
      />
      <path
        d="M9.0957 22.4753C9.0957 22.4477 9.11809 22.4253 9.1457 22.4253H13.0457C13.0733 22.4253 13.0957 22.4477 13.0957 22.4753V26.3753C13.0957 26.4029 13.0733 26.4253 13.0457 26.4253H9.1457C9.11809 26.4253 9.0957 26.4029 9.0957 26.3753V22.4753Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1457 21.4253H13.0457C13.6256 21.4253 14.0957 21.8954 14.0957 22.4753V26.3753C14.0957 26.9552 13.6256 27.4253 13.0457 27.4253H9.1457C8.5658 27.4253 8.0957 26.9552 8.0957 26.3753V22.4753C8.0957 21.8954 8.56581 21.4253 9.1457 21.4253ZM9.1457 22.4253C9.11809 22.4253 9.0957 22.4477 9.0957 22.4753V26.3753C9.0957 26.4029 9.11809 26.4253 9.1457 26.4253H13.0457C13.0733 26.4253 13.0957 26.4029 13.0957 26.3753V22.4753C13.0957 22.4477 13.0733 22.4253 13.0457 22.4253H9.1457Z"
        fill="#7070A9"
      />
    </svg>
  );
};
