import { combineReducers } from "@reduxjs/toolkit";

import { assignmentReducer } from "@/features/assignment";
import { timelineReducer } from "@/features/timeline";
import { workReducer } from "@/features/work";
import { materialsReducer } from "@/features/materials";

// Create the root reducer independently to obtain the RootState type
export const rootReducer = combineReducers({
  assignment: assignmentReducer,
  timeline: timelineReducer,
  materials: materialsReducer,
  work: workReducer,
});
