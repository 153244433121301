import { Extension } from "@tiptap/core";

import {
  decreaseIndent,
  increaseIndent,
  setIndentStyle,
  toggleIndentStyle,
  unsetIndentStyle,
} from "./commands";
import { LevelIndentation } from "./level-indentation";
import { MixIndentation } from "./mix-indentation";
import { SpecialIndentation } from "./special-indentation";
import {
  IndentationOptions,
  SpecialIndentationStyle,
  TabDirection,
} from "./types";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indentation: {
      /**
       * Set an indentation style of selected block(s) and the directional enum
       * if it comes from `Tab` or `Shift-Tab` keyboard shortcut
       */
      setIndentStyle: (
        style: SpecialIndentationStyle,
        direction: TabDirection
      ) => ReturnType;
      /**
       * Rest indent style to the default indentation style
       */
      unsetIndentStyle: () => ReturnType;
      /**
       * Increase Indentation level by 1 until MAX_INDENTATION level is reached.
       */
      increaseIndent: () => ReturnType;
      /**
       * Decrease indentation level by 1 until possible.
       */
      decreaseIndent: () => ReturnType;
      /**
       * Toggle between the specified indent style with the default indentation style.
       */
      toggleIndentStyle: (style: SpecialIndentationStyle) => ReturnType;
    };
  }
}

/**
 * Extension providing various indentation styles for blocks in the editor.
 *
 * ## Types Of Indentations
 *
 * ### Level Indentation
 *
 * The simplest form of indentation where the entire block has some multiple of
 * left margin value. The amount of left-margin to add on the block is
 * determined by the node attribute `indentLevel` (HTML attribute
 * `data-indent-level`), which is a `number` between 0 and a configured MAXIMUM
 * value.
 *
 * ### Special Indentation
 *
 * A form of textual indetation with preset styles. The supported styles are:
 *
 *   - `firstLine` where only the first line has a TAB indentation
 *   - `hanging` where all the lines following the first line have a TAB indentation.
 *
 * The current applied type for a text block is determined by the node attribute
 * `indentSpecial` (HTML attribute `data-indent-special`) with a style name.
 *
 * ### Mixed Indentation
 *
 * This type is a combination of the **Level** and **Special** indentation
 * styles. Blocks with mixed indentation will use both the `indentLevel` and
 * `indentSpecial` attributes. The `Tab` indent shortcut will first apply the
 * `indentSpecial` style and then start indenting the level of the block.
 * Similarly when moving backwards with `Shift-Tab`, any `indentationSpecial`
 * will first be removed before unindenting through the levels until 0.
 */
export const Indentation = Extension.create<IndentationOptions>({
  name: "indentation",

  addOptions() {
    return {
      specialTypes: ["paragraph"],
      levelTypes: ["paragraph", "heading", "blockquote"],
      indentationStyles: ["normal", "firstLine", "hanging"],
      defaultIndentation: "normal",
      maxIndentation: 8,
    };
  },

  addExtensions() {
    // Viable node types for mixed indentation
    const mixTypes = this.options.specialTypes.filter((t) =>
      this.options.levelTypes.includes(t)
    );

    // Viable node types for special indentation
    const specialTypes = this.options.specialTypes.filter(
      (t) => !this.options.levelTypes.includes(t)
    );

    // Viable node types for level indentation
    const levelTypes = this.options.levelTypes.filter(
      (t) => !this.options.specialTypes.includes(t)
    );
    return [
      SpecialIndentation.configure({
        types: specialTypes,
        indentationStyles: this.options.indentationStyles,
        defaultIndentation: this.options.defaultIndentation,
      }),
      MixIndentation.configure({
        types: mixTypes,
        maxIndentation: this.options.maxIndentation,
        indentationStyles: this.options.indentationStyles,
        defaultIndentation: this.options.defaultIndentation,
      }),
      LevelIndentation.configure({
        types: levelTypes,
        maxIndentation: this.options.maxIndentation,
        defaultIndentation: this.options.defaultIndentation,
      }),
    ];
  },

  addCommands() {
    const {
      specialTypes,
      levelTypes,
      maxIndentation,
      defaultIndentation,
      indentationStyles,
    } = this.options;
    return {
      increaseIndent: () => increaseIndent(levelTypes, maxIndentation),
      decreaseIndent: () => decreaseIndent(levelTypes),
      setIndentStyle: (
        style: SpecialIndentationStyle,
        direction: TabDirection = TabDirection.UNKNOWN
      ) => setIndentStyle(indentationStyles, specialTypes, style, direction),
      unsetIndentStyle: () =>
        unsetIndentStyle(specialTypes, defaultIndentation),
      toggleIndentStyle: (style: SpecialIndentationStyle) =>
        toggleIndentStyle(
          indentationStyles,
          defaultIndentation,
          specialTypes,
          style
        ),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        const { selection } = editor.state;
        if (
          selection.empty &&
          selection.$anchor.parentOffset === 0 &&
          selection.$anchor.depth === 1
        ) {
          return editor.commands.first(({ commands }) => [
            () => commands.setIndentStyle("firstLine", TabDirection.FORWARD),
            () => commands.increaseIndent(),
          ]);
        } else {
          return editor.commands.increaseIndent();
        }
      },
      "Shift-Tab": ({ editor }) => {
        const { selection } = editor.state;
        if (
          selection.empty &&
          selection.$anchor.parentOffset === 0 &&
          selection.$anchor.depth === 1
        ) {
          return editor.commands.first(({ commands }) => [
            () => commands.setIndentStyle("normal", TabDirection.BACKWARD),
            () => commands.decreaseIndent(),
          ]);
        } else {
          return editor.commands.decreaseIndent();
        }
      },
      Backspace: ({ editor }) => {
        const { selection } = editor.state;
        if (
          selection.empty &&
          selection.$anchor.parentOffset === 0 &&
          selection.$anchor.depth === 1
        ) {
          return editor.commands.first(({ commands }) => [
            () => commands.setIndentStyle("normal", TabDirection.BACKWARD),
            () => commands.decreaseIndent(),
          ]);
        } else {
          return editor.commands.decreaseIndent();
        }
      }
    };
  },
});
