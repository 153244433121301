import { DOMOutputSpec } from "@tiptap/pm/model";
import { ElementType } from "domelementtype";
import { ChildNode } from "domhandler";
import { getChildren, textContent } from "domutils";
import { parseDocument } from "htmlparser2";

import { HTMLToProsemirrorConverter } from "./types";

const convert = (nodes: ChildNode[]) => {
  const output: DOMOutputSpec[] = [];

  if (nodes.length === 0) {
    return output;
  }

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (node.type === ElementType.Text) {
      output.push(textContent(node));
    } else if (node.type === ElementType.Tag) {
      const attributes = node.attribs;
      output.push([
        node.name.toLowerCase(),
        attributes,
        ...convert(getChildren(node)),
      ]);
    }
  }
  return output;
};

export const htmlparserConverter: HTMLToProsemirrorConverter = (html) =>
  convert(getChildren(parseDocument(html)));
