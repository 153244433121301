import { IconProps } from "./types";

export const UnderLeftArrowIcon = (props: IconProps) => {
  return (
    <svg width="16" height="30" viewBox="0 0 16 30" {...props}>
      <path
        d="M14.864 22.8787C15.024 22.964 15.104 23.0707 15.104 23.1987C15.104 23.3267 15.024 23.4333 14.864 23.5187H2.64L2.688 23.5667C3.78667 24.4093 4.50667 25.4867 4.848 26.7987C4.912 27.0333 4.944 27.1667 4.944 27.1987C4.944 27.316 4.832 27.3747 4.608 27.3747C4.48 27.3747 4.39467 27.3587 4.352 27.3267C4.32 27.3053 4.29333 27.2413 4.272 27.1347C4.25067 27.06 4.22933 26.9693 4.208 26.8627C4.016 26.084 3.648 25.3907 3.104 24.7827C2.57067 24.1747 1.92533 23.7213 1.168 23.4227C1.008 23.3587 0.912 23.3 0.88 23.2467C0.88 23.108 0.976 23.0173 1.168 22.9747C1.92533 22.676 2.57067 22.2227 3.104 21.6147C3.648 21.0067 4.016 20.3133 4.208 19.5347C4.21867 19.5027 4.22933 19.4547 4.24 19.3907C4.25067 19.3267 4.256 19.284 4.256 19.2627C4.26667 19.2413 4.27733 19.2093 4.288 19.1667C4.30933 19.124 4.33067 19.0973 4.352 19.0867C4.37333 19.0653 4.40533 19.0493 4.448 19.0387C4.49067 19.028 4.544 19.0227 4.608 19.0227C4.832 19.0227 4.944 19.0813 4.944 19.1987C4.944 19.2307 4.912 19.364 4.848 19.5987C4.528 20.868 3.808 21.9453 2.688 22.8307L2.64 22.8787H14.864Z"
        fill="#3A517F"
      />
      <path
        d="M2 6.5C2 6.22386 2.22386 6 2.5 6H13.5C13.7761 6 14 6.22386 14 6.5V17.5C14 17.7761 13.7761 18 13.5 18H2.5C2.22386 18 2 17.7761 2 17.5V6.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7V17H13V7H3ZM2.5 6C2.22386 6 2 6.22386 2 6.5V17.5C2 17.7761 2.22386 18 2.5 18H13.5C13.7761 18 14 17.7761 14 17.5V6.5C14 6.22386 13.7761 6 13.5 6H2.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
