export default (
  <>
    <path d="M0.349591 9.86358L4.68839 12.1619V10.9062L1.538 9.31423L4.68839 7.73342V6.48896L0.349591 8.76487V9.86358Z" />
    <path d="M13.7407 8.78729L9.4019 6.48896V7.73342L12.5523 9.33665L9.4019 10.9175V12.1619L13.7407 9.88601V8.78729Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1019 5.99056L14.0407 8.60672V10.0674L9.1019 12.6581V10.7323L11.8871 9.33476L9.1019 7.91737V5.99056ZM4.98839 5.99283V7.91854L2.20503 9.31517L4.98839 10.7217V12.6603L0.0495911 10.0442V8.58346L4.98839 5.99283ZM0.649591 8.94627V9.68301L4.38839 11.6635V11.0908L0.870966 9.31328L4.38839 7.54831V6.98509L0.649591 8.94627ZM9.7019 6.98737V7.54948L13.2175 9.33854L9.7019 11.1026V11.6658L13.4407 9.7046V8.96787L9.7019 6.98737Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23862 15.1338L7.59078 3.51326L8.8771 3.51709L6.52496 15.1376L5.23862 15.1338Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0127 11.0262L16.014 11.0262V16.7694C16.014 17.6668 15.2864 18.3944 14.389 18.3944H3.61815C2.72069 18.3944 1.99315 17.6668 1.99315 16.7694V13.6654H3.24315V16.7694C3.24315 16.9765 3.41104 17.1444 3.61815 17.1444H14.389C14.5961 17.1444 14.764 16.9765 14.764 16.7694V12.2762L14.0127 12.2762V11.0262Z"
    />
  </>
);
