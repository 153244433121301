import { EditorState } from "@tiptap/pm/state";
import { TableMap } from "@tiptap/pm/tables";

import { findTable } from "./find";

/**
 * Check if Table have a header row
 */
export function hasHeaderRow(state: EditorState) {
  const table = findTable(state.selection);
  if (!table) return false;
  const map = TableMap.get(table.node);
  for (let i = 0; i < map.width; i++) {
    const cell = table.node.nodeAt(map.map[i]);
    if (cell && cell.type !== state.schema.nodes.tableHeader) {
      return false;
    }
  }
  return true;
}

/**
 * Check if Table have a header column
 */
export function hasHeaderColumn(state: EditorState) {
  const table = findTable(state.selection);
  if (!table) return false;
  const map = TableMap.get(table.node);
  // Get cell positions for first column.
  const cellPositions = map.cellsInRect({
    left: 0,
    top: 0,
    right: 1,
    bottom: map.height,
  });

  for (let i = 0; i < cellPositions.length; i++) {
    try {
      const cell = table.node.nodeAt(cellPositions[i]);
      if (cell && cell.type !== state.schema.nodes.tableHeader) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return true;
}
