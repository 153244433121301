import styled, { ThemedStyledProps, css } from "styled-components";

import { Color, colors } from "./styles";
import { CuiTheme } from "./styles/theme";
import { Typography, typography } from "./styles/typography";

interface Props {
  /** Applies the element mapping and the Cadmus typography styles */
  kind: Typography;

  /** Applies one of the theme's text colors */
  color?: Color | keyof CuiTheme["text"] | "inherit";

  /** Applies text alignment styles */
  textAlign?: "center" | "left" | "right" | "justify";

  /**
   * Applies CSS text transform styles.
   */
  textTransform?:
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "none"
    | "full-width"
    | "full-size-kana";

  italic?: boolean;
  underline?: boolean;
  lineThrough?: boolean;
  bold?: boolean;

  /**
   * Use as an inline component without padding.
   */
  inline?: boolean;

  /** Forward styled-components' `as` to `Text` */
  as: string;
}

// A mixin to apply a text color using theme
const textColor = (props: ThemedStyledProps<Props, CuiTheme>) => {
  if (props.color === "inherit") {
    return css`
      color: inherit;
    `;
  }

  if (props.color) {
    if (props.color in colors) {
      return css`
        color: ${colors[props.color as Color]};
      `;
    }
    return css`
      color: ${props.theme.text[props.color as keyof CuiTheme["text"]]};
    `;
  }

  switch (props.kind) {
    case "displayOne":
    case "displayTwo":
    case "headingOne":
    case "headingTwo":
    case "headingThree":
    case "headingFour":
    case "headingFive":
    case "headingSix":
      return css`
        color: ${props.theme.text.heading};
      `;
    case "lead":
    case "body":
    case "paragraph":
    case "system":
    case "subtle":
      return css`
        color: ${props.theme.text.main};
      `;
    case "blockquote":
    case "label":
      return css`
        color: ${props.theme.text.shade1};
      `;
  }
};

/**
 * A component to render text elements. Maps  Cadmus typography styles to
 * corresponding HTML elements
 */
export const Text = styled.span.attrs((p: Props) => ({
  as: p.as ?? typography[p.kind].element,
}))<Props>`
  font-family: ${(p) => typography[p.kind].fontFamily};
  font-weight: ${(p) => (p.bold ? "bold" : typography[p.kind].fontWeight)};
  font-size: ${(p) => typography[p.kind].fontSize};
  padding-top: ${(p) => (p.inline ? 0 : typography[p.kind].paddingTop)};
  padding-bottom: ${(p) => (p.inline ? 0 : typography[p.kind].paddingBottom)};
  margin-top: 0;
  margin-bottom: 0;
  line-height: ${(p) => typography[p.kind].lineHeight};
  letter-spacing: ${(p) => typography[p.kind].letterSpacing};

  ${textColor};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "inherit")};

  text-decoration: ${(p) =>
    `${p.underline ? "underline" : ""} ${p.lineThrough ? "line-through" : ""}`};
  font-style: ${(p) => (p.italic ? "italic" : "normal")};
  text-transform: ${(p) => p.textTransform ?? typography[p.kind].textTransform};
`;
