import { IconProps } from "./types";

export const OverlineSegmentIcon = (props: IconProps) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" {...props}>
      <path
        d="M5 5.5C5 5.22386 5.22386 5 5.5 5H16.5C16.7761 5 17 5.22386 17 5.5V16.5C17 16.7761 16.7761 17 16.5 17H5.5C5.22386 17 5 16.7761 5 16.5V5.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6V16H16V6H6ZM5.5 5C5.22386 5 5 5.22386 5 5.5V16.5C5 16.7761 5.22386 17 5.5 17H16.5C16.7761 17 17 16.7761 17 16.5V5.5C17 5.22386 16.7761 5 16.5 5H5.5Z"
        fill="#7070A9"
      />
      <path
        d="M3 1V3"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M19 1V3"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
      <path
        d="M3 2H19"
        stroke="#3A517F"
        strokeWidth="0.64"
        strokeLinecap="round"
      />
    </svg>
  );
};
