import { Extension } from "@tiptap/core";
import { Fragment, Slice } from "@tiptap/pm/model";
import { Selection, TextSelection } from "@tiptap/pm/state";

import { nodeWordCount, textWordCount } from "../../core/text";
import { ContentNodeWithPos } from "../../core/types";
import {
  CodeBlockPasteMeta,
  CodeBlockPastePluginKey,
} from "../code-block/plugins";
import {
  PasteOrigin,
  cleanPastedHTML,
  htmlPasteOrigin,
  parseHTMLString,
} from "./cleaner";
import { getLastClipboard } from "./global-clipboard";
import {
  PasteMeta,
  PastePlugin,
  PastePluginKey,
  PastePluginProps,
  PasteType,
} from "./paste-plugin";

export type PasteHandler<T> = (
  event: Event,
  moved: boolean,
  slice: Slice,
  html?: string,
  text?: string
) => T;

type SelectionPredicate = (
  selection: Selection
) => ContentNodeWithPos | undefined;

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    paste: {
      /** Delete paste chip */
      deletePasteChip: (event: MouseEvent) => ReturnType;
      /** Make sure that position is updated to the right position on drag and drop */
      setDropSelection: (event: MouseEvent) => ReturnType;
      /**
       * Handle drop / paste parsing & insertion including annotating with paste-chip when
       * required. This will try to go through a list of `handle<blah>Paste` to see which command
       * can handle them otherwise fallback to `handlePasteFallback`.
       * Current List of commands with the order of attempts:
       * - `handleVSCodePaste`
       * - `handleMarkDownPaste`
       * - `handleTablePaste`
       * - `handlePasteFallback`
       */
      handlePaste: PasteHandler<ReturnType>;
      /**
       * Handle common drop / paste parsing & insertion including annotating with paste-chip when
       * required
       */
      handlePasteFallback: (moved: boolean, slice: Slice) => ReturnType;
      /**
       * Chain through commands that detects's language, source, wordcount & cursor position
       * to set `CodeBlockPasteMeta` & `PasteMeta`.
       */
      handlePasteMetadata: PasteHandler<ReturnType>;
      /**
       * Add paste-chip inline node around the pasted texts if it is not of type `PasteType.Allowed`
       */
      addPasteChip: () => ReturnType;
      /**
       * Add pasteChip after a block matched through selectionPredicate
       */
      addPasteChipAfterPredicate: (
        selectionPredicate: SelectionPredicate
      ) => ReturnType;
    };
  }
}

export interface PasteStorage {
  editorId: string;
}

/** Paste extension wrapping the ProseMirror Paste plugin. */
export const Paste = Extension.create<PastePluginProps, PasteStorage>({
  name: "paste",

  addOptions() {
    return {
      editorId: "body",
      annotatePaste: true,
    };
  },

  addStorage() {
    return {
      editorId: this.options.editorId,
    };
  },

  addCommands() {
    return {
      deletePasteChip:
        (mouseEvent) =>
        ({ commands, view }) => {
          if (!this.editor?.isEditable) return false;
          const eventPos = view.posAtCoords({
            left: mouseEvent.clientX,
            top: mouseEvent.clientY,
          });
          if (eventPos) {
            commands.deleteRange({
              from: eventPos.inside,
              to: eventPos.inside + 1,
            });
          }
          return true;
        },
      setDropSelection:
        (mouseEvent) =>
        ({ view, state, tr, dispatch }) => {
          let $mouse;
          const eventPos = view.posAtCoords({
            left: mouseEvent.clientX,
            top: mouseEvent.clientY,
          });
          if (eventPos) {
            $mouse = state.doc.resolve(eventPos.pos);
          }
          if ($mouse) {
            tr.setSelection(new TextSelection($mouse));
            dispatch?.(tr);
          }
          return true;
        },
      handlePaste:
        (event, moved, slice, html, text) =>
        ({ chain }) => {
          return chain()
            .handlePasteMetadata(event, moved, slice, html, text)
            .command(({ commands }) => {
              return commands.first([
                () => commands.handleVSCodePaste(text),
                () => commands.handleMarkdownPaste(text),
                () => commands.handleTablePaste(slice),
                () => commands.handlePasteFallback(moved, slice),
              ]);
            })
            .addPasteChip()
            .run();
        },
      handlePasteFallback:
        (moved, slice) =>
        ({ chain }) => {
          if (moved) return true;
          return chain()
            .command(({ tr, commands, dispatch, view }) => {
              const meta = tr.getMeta(PastePluginKey);
              const codeBlockPasteMeta = tr.getMeta(CodeBlockPastePluginKey);
              // paste is insideCodeBlock and has text clipboard
              // insert slice from those text instead of slice
              if (codeBlockPasteMeta?.insideCodeBlock && meta?.text) {
                const newSlice = new Slice(
                  Fragment.from(
                    view.state.schema.text(meta.text.replace(/\r\n?/g, "\n"))
                  ),
                  0,
                  0
                );
                dispatch?.(tr.replaceSelection(newSlice));
                return true;
              }
              if (meta && meta.html && !meta.internal) {
                let html: string;
                let preserveWhitespace: "full" | boolean;

                // preserve whitespace fully if it's internal or prosemirror paste
                if (meta.origin === PasteOrigin.PROSEMIRROR) {
                  html = meta.html;
                  preserveWhitespace = "full";
                } else {
                  html = cleanPastedHTML(meta.html);
                  preserveWhitespace = false;
                }
                commands.insertContent(html, {
                  parseOptions: {
                    preserveWhitespace,
                  },
                });
              } else {
                dispatch?.(tr.replaceSelection(slice));
              }
              return true;
            })
            .command(({ tr, dispatch }) => {
              dispatch?.(tr.scrollIntoView());
              return true;
            })
            .run();
        },
      handlePasteMetadata:
        (event, moved, slice, html, text) =>
        ({ chain }) => {
          return chain()
            .handleVSCodePasteMetadata(event, moved, slice, html, text)
            .handleMarkdownPasteMetadata(event, moved, slice, html, text)
            .handleCodeBlockPasteMetadata(event, moved, slice, html, text)
            .command(({ tr, dispatch }) => {
              const { annotatePaste } = this.options;
              let internal = moved;
              let pasteType: PasteType = PasteType.allowed;
              const clipboard = getLastClipboard();
              let wordCount = nodeWordCount(slice.content);
              const codeBlockPasteMeta = tr.getMeta(CodeBlockPastePluginKey) as
                | CodeBlockPasteMeta
                | undefined;
              const insideCodeBlock =
                codeBlockPasteMeta?.insideCodeBlock && text;
              if (insideCodeBlock) {
                wordCount = textWordCount(text);
              }
              // default origin to unkown for text paste or paste when clipboard is null or
              // undefined
              let origin: PasteOrigin | string = PasteOrigin.UNKNOWN;
              if (((text && !html) || !clipboard) && !internal) {
                internal = false;
              }

              if (codeBlockPasteMeta?.source === "vscode") {
                origin = PasteOrigin.VSCODE;
              }

              let clipboardText;

              if (clipboard?.content) {
                clipboardText = parseHTMLString(clipboard?.content).body
                  .innerText;
              }
              if (html && !insideCodeBlock) {
                const htmlEl = parseHTMLString(html);
                const htmlBody = htmlEl.getElementsByTagName("body")[0];
                if (clipboard) {
                  const clipboardEL = parseHTMLString(clipboard.content);
                  const clipboardBody =
                    clipboardEL.getElementsByTagName("body")[0];
                  if (
                    htmlBody.innerHTML.replace(/<!--.*?-->/gi, "").trim() ===
                    clipboardBody.innerHTML.replace(/<!--.*?-->/gi, "").trim()
                  ) {
                    internal = true;
                    origin = clipboard.origin;
                  } else {
                    origin = htmlPasteOrigin(htmlEl);
                  }
                } else {
                  origin = htmlPasteOrigin(htmlEl);
                }
              } else if (text && clipboard?.content === text) {
                internal = true;
                origin = clipboard.origin;
              } else if (
                text &&
                clipboardText &&
                clipboardText === text &&
                clipboard?.origin
              ) {
                internal = true;
                origin = clipboard.origin;
              }
              if (internal || !annotatePaste) {
                pasteType = PasteType.allowed;
              } else if (wordCount >= 30) {
                pasteType = PasteType.highlighted;
              } else if (wordCount >= 90) {
                pasteType = PasteType.locked;
              }

              const pasteMeta: PasteMeta = {
                origin,
                pasteType,
                wordCount,
                internal,
                html,
                text,
              };
              tr.setMeta(PastePluginKey, pasteMeta);
              tr.setMeta("paste", true);
              dispatch?.(tr);
              return true;
            })
            .run();
        },
      addPasteChip:
        () =>
        ({ commands }) => {
          const { annotatePaste } = this.options;
          if (!annotatePaste) return true;
          return commands.first([
            () => commands.addTablePasteChip(),
            () => commands.addCodeBlockPasteChip(),
            // fallback
            () =>
              commands.command(({ tr, state, dispatch }) => {
                const pasteMeta = tr.getMeta(PastePluginKey) as PasteMeta;
                const { pasteType, wordCount } = pasteMeta;
                if (pasteType !== PasteType.allowed) {
                  const { selection } = state;
                  const { $to } = selection;
                  const pasteNode = state.schema.nodes.pasteChip.create({
                    pasted: wordCount,
                  });
                  if ($to.parent?.isTextblock) {
                    tr.insert($to.pos, pasteNode);
                  } else {
                    const paragraph = state.schema.nodes.paragraph;
                    const paragraphNode = paragraph.create(undefined, [
                      pasteNode,
                    ]);
                    tr.insert($to.after($to.depth), paragraphNode);
                  }
                }
                dispatch?.(tr);
                return true;
              }),
          ]);
        },
      addPasteChipAfterPredicate:
        (selectionPredicate: SelectionPredicate) =>
        ({ tr, dispatch, state }) => {
          const pasteMeta = tr.getMeta(PastePluginKey) as PasteMeta;
          if (pasteMeta.pasteType === PasteType.allowed) return false;
          const contentNodePos = selectionPredicate(state.selection);
          if (!contentNodePos) return false;
          const { pos, node } = contentNodePos;
          const $pos = state.doc.resolve(pos + node.nodeSize);
          const pasteChip = state.schema.nodes.pasteChip;
          const pasteNode = pasteChip.create({ pasted: pasteMeta.wordCount });
          tr.insert($pos.start($pos.depth + 1), pasteNode);
          dispatch?.(tr);
          return true;
        },
    };
  },

  addProseMirrorPlugins() {
    return [PastePlugin(this.editor, this.options)];
  },
});
