import { ClipboardCleaner } from "./clipboard-cleaner";
import { GoogleDocsClipboardCleaner } from "./google-docs-clipboard-cleaner";
import { MicrosoftWordClipboardCleaner } from "./microsoft-word-clipboard-cleaner";
import { PasteOrigin, htmlPasteOrigin } from "./paste-origin";
import { parseHTMLString } from "./parseHTMLString";

export function cleanPastedHTML(htmlString: string): string {
  const doc = parseHTMLString(htmlString);
  const origin = htmlPasteOrigin(doc);
  const cleaner = getCleaner(origin, doc);
  const output = cleaner.getOutput();
  return output;
}

function getCleaner(origin: PasteOrigin, doc: Document) {
  switch (origin) {
    case PasteOrigin.DOCS:
      return new GoogleDocsClipboardCleaner(doc);
    case PasteOrigin.WORD:
      return new MicrosoftWordClipboardCleaner(doc);
    case PasteOrigin.UNKNOWN:
    default:
      return new ClipboardCleaner(doc);
  }
}
