// See https://github.com/apollographql/apollo-client/issues/7842
// This is a work around wrapper for tracking refetch loading states in query.
// Currently it seems that the `networkStatus` result is not being updated to reflect a refetch in progress
// Therefore we have to use our own state to track the Observable.

import { useCallback, useState } from "react";
import { ObservableQuery, OperationVariables } from "@apollo/client";

type RefetchFnType<
  TData,
  TVariables extends OperationVariables
> = ObservableQuery<TData, TVariables>["refetch"];

export function useRefetch<TData, TVariables extends OperationVariables>(
  refetch: RefetchFnType<TData, TVariables>
): [RefetchFnType<TData, TVariables>, boolean] {
  const [refetching, setRefetching] = useState(false);
  const refetchWithLoading: RefetchFnType<TData, TVariables> = useCallback(
    (...args) => {
      setRefetching(true);
      return refetch(...args).finally(() => {
        setRefetching(false);
      });
    },
    [refetch]
  );

  return [refetchWithLoading, refetching];
}
