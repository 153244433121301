import {
  CurlyBracketIcon,
  HatIcon,
  NotExistsIcon,
  OverbarIcon,
  OverlineSegmentIcon,
  RoundBracketIcon,
  SquareBracketIcon,
  SufixBracketIcon,
  TildeAboveIcon,
  TildeUnderneathIcon,
  UnderbarIcon,
  UnderlineSegmentIcon,
} from "../icons";
import { MathCommand } from "./types";

export const overbar: MathCommand = {
  label: "overbar",
  renderChildren: () => <OverbarIcon />,
  mathLiveCommand: "\\overline",
};
export const underbar: MathCommand = {
  label: "underbar",
  renderChildren: () => <UnderbarIcon />,
  mathLiveCommand: "\\underline",
};
export const hat: MathCommand = {
  label: "hat",
  renderChildren: () => <HatIcon />,
  mathLiveCommand: "\\hat",
};
export const tildeAbove: MathCommand = {
  label: "tilde above",
  renderChildren: () => <TildeAboveIcon />,
  mathLiveCommand: "\\tilde",
};
export const tildeUnderneath: MathCommand = {
  label: "tilde underneath",
  renderChildren: () => <TildeUnderneathIcon />,
  mathLiveCommand: "\\utilde",
};
export const overlineSegment: MathCommand = {
  label: "overline segment",
  renderChildren: () => <OverlineSegmentIcon />,
  mathLiveCommand: "\\overlinesegment",
  tooltipLabel: "",
};
export const underlineSegment: MathCommand = {
  label: "underline segment",
  renderChildren: () => <UnderlineSegmentIcon />,
  mathLiveCommand: "\\underlinesegment",
  tooltipLabel: "",
};
export const therefore: MathCommand = {
  text: "∴",
  label: "therefore",
  mathLiveCommand: "\\therefore",
};
export const because: MathCommand = {
  text: "∵",
  label: "because",
  mathLiveCommand: "\\because",
};
export const infinity: MathCommand = {
  label: "infinity",
  text: "∞",
  mathLiveCommand: "\\infin",
};
export const proportionality: MathCommand = {
  label: "proportionality",
  text: "∝",
  mathLiveCommand: "\\propto",
};
export const functionComposition: MathCommand = {
  label: "function composition",
  text: "∘",
  mathLiveCommand: "\\circ",
};
export const forAll: MathCommand = {
  label: "for all",
  text: "∀",
  mathLiveCommand: "\\forall",
};
export const thereExist: MathCommand = {
  label: "there exist",
  text: "∃",
  mathLiveCommand: "\\exist",
};
export const thereNotExist: MathCommand = {
  label: "there does not exist",
  renderChildren: () => <NotExistsIcon />,
  mathLiveCommand: "\\nexists",
};
export const aleph: MathCommand = {
  label: "Aleph number",
  text: "ℵ",
  mathLiveCommand: "\\aleph",
};
export const imaginaryUnit: MathCommand = {
  label: "imaginary unit",
  text: "i",
  mathLiveCommand: "\\imaginaryI",
};
export const naturalNumbers: MathCommand = {
  label: "Natural numbers",
  text: "N",
  mathLiveCommand: "\\N",
  kind: "ams",
};
export const primeNumbers: MathCommand = {
  label: "Prime numbers",
  text: "P",
  mathLiveCommand: "\\P",
  kind: "ams",
};
export const integers: MathCommand = {
  label: "Integers",
  text: "Z",
  mathLiveCommand: "\\Z",
  kind: "ams",
};
export const rationalNumbers: MathCommand = {
  label: "Rational numbers",
  text: "Q",
  mathLiveCommand: "\\Q",
  kind: "ams",
};
export const realNumbers: MathCommand = {
  label: "Real numbers",
  text: "R",
  mathLiveCommand: "\\R",
  kind: "ams",
};
export const complexNumbers: MathCommand = {
  label: "Complex numbers",
  text: "C",
  mathLiveCommand: "\\C",
  kind: "ams",
};
export const hamilton: MathCommand = {
  label: "Hamilton",
  text: "H",
  mathLiveCommand: "\\mathbb{H}",
  kind: "ams",
};
export const halmos: MathCommand = {
  label: "Halmos",
  text: "■",
  mathLiveCommand: "\\blacksquare",
  kind: "ams",
};
export const dagger: MathCommand = {
  label: "dagger",
  text: "†",
  mathLiveCommand: "\\dag",
};
export const roundBracket: MathCommand = {
  label: "round brackets",
  renderChildren: () => <RoundBracketIcon />,
  mathLiveCommand: "\\left({\\placeholder{}}\\right)",
  tooltipCommand: "",
};
export const sufixBracket: MathCommand = {
  label: "sufix brackets",
  renderChildren: () => <SufixBracketIcon />,
  mathLiveCommand: "{\\placeholder{}}\\left({\\placeholder{}}\\right)",
  tooltip: false,
};
export const squareBracket: MathCommand = {
  label: "square brackets",
  renderChildren: () => <SquareBracketIcon />,
  mathLiveCommand: "\\left[{\\placeholder{}}\\right]",
  tooltipCommand: "",
};
export const curlyBracket: MathCommand = {
  label: "curly brackets",
  renderChildren: () => <CurlyBracketIcon />,
  mathLiveCommand: "\\left\\{{\\placeholder{}}\\right\\}",
  tooltipCommand: "",
};
