import { ComponentProps, forwardRef } from "react";
import { IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { Checkbox } from "../Checkbox";
import { Icon } from "../Icon";
import { Switch } from "../Switch";
import { Text } from "../Text";
import { MenuItemButton } from "./MenuItem";

interface Props extends ComponentProps<typeof MenuItemButton> {
  /**
   * Selected checkbox state
   */
  selected: boolean;
  /**
   * Text label to display besides the Checkbox.
   * Has a higher priority than `children`.
   */
  content?: string;
  /** Icon name rendered on the left side of content */
  iconName?: IconName;
  /**
   * Use a Switch toggle "checkbox" instead of a checkbox.
   */
  toggleable?: boolean;
  /**
   * Alias for indeterminate state. Set the checkbox initial state to be mixed.
   * Note: You could see this as a variant of the checked==true state.
   * If you have checked==false, mixed will be ignored
   */
  mixed?: boolean;
}

export const CheckMenuItem = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      selected,
      content,
      children,
      iconName,
      toggleable,
      mixed,
      ...buttonProps
    } = props;

    return (
      <MenuItemButton ref={ref} selected={selected} {...buttonProps}>
        {!toggleable && (
          <StyledCheckbox checked={selected} readOnly mixed={mixed} />
        )}
        {iconName && <Icon iconName={iconName} />}
        {content ? <Text kind="system">{content}</Text> : children}
        {toggleable && <SwitchControl checked={selected} readOnly />}
      </MenuItemButton>
    );
  }
);

const StyledCheckbox = styled(Checkbox)`
  margin-right: -4px;
`;

const SwitchControl = styled(Switch)`
  margin-left: auto;
`;
