import { css } from "styled-components";

import { cadmusDarkCodeBlockStyles } from "./cadmus-dark-styles";
import { cadmusLightCodeBlockStyles } from "./cadmus-light-styles";
import { codeBlockSharedStyles } from "./code-block-shared-styles";
import { codemirrorStyles } from "./codemirror-styles";

export const codeBlockStyles = css`
  ${codemirrorStyles}
  ${codeBlockSharedStyles}
  ${cadmusDarkCodeBlockStyles}
  ${cadmusLightCodeBlockStyles}
`;
