import { NotEqualIcon } from "../icons";
import { MathCommand } from "./types";

export const equal: MathCommand = {
  text: "=",
  label: "equal",
  mathLiveCommand: "=",
  latexCommand: "=",
};
export const notEqualTo: MathCommand = {
  label: "not equal to",
  renderChildren: () => <NotEqualIcon />,
  mathLiveCommand: "\\ne",
};
export const lessThan: MathCommand = {
  label: "less than",
  text: "<",
  mathLiveCommand: "<",
};
export const greaterThan: MathCommand = {
  label: "greater than",
  text: ">",
  mathLiveCommand: ">",
};
export const lessThanOrEqual: MathCommand = {
  label: "less than or equal",
  text: "≤",
  mathLiveCommand: "\\leq",
};
export const greaterThanOrEqual: MathCommand = {
  label: "greater than or equal",
  text: "≥",
  mathLiveCommand: "\\geq",
};
export const approximately: MathCommand = {
  label: "approximately",
  text: "≈",
  mathLiveCommand: "\\approx",
};
export const lessOrSimillar: MathCommand = {
  label: "less or simillar",
  text: "≲",
  mathLiveCommand: "\\lesssim",
  kind: "ams",
};
export const greaterOrSimillar: MathCommand = {
  label: "greater or simillar",
  text: "≳",
  mathLiveCommand: "\\gtrsim",
  kind: "ams",
};
export const lesserLesser: MathCommand = {
  label: "lesser lesser than",
  text: "≪",
  mathLiveCommand: "\\ll",
  kind: "ams",
};
export const greaterGreater: MathCommand = {
  label: "greater greater than",
  text: "≫",
  mathLiveCommand: "\\gg",
  kind: "ams",
};
export const notLesser: MathCommand = {
  label: "not lesser than",
  text: "≮",
  mathLiveCommand: "\\nless",
  kind: "ams",
};
export const notGreater: MathCommand = {
  label: "not greater than",
  text: "≯",
  mathLiveCommand: "\\ngtr",
  kind: "ams",
};
export const notLesserOrEqual: MathCommand = {
  label: "not lesser than or equal",
  text: "≰",
  mathLiveCommand: "\\nleq",
  kind: "ams",
};
export const notGreaterOrEqual: MathCommand = {
  label: "not greater than or equal",
  text: "≱",
  mathLiveCommand: "\\ngeq",
  kind: "ams",
};
export const congruent: MathCommand = {
  label: "congruent",
  text: "≅",
  mathLiveCommand: "\\cong",
  kind: "ams",
};
export const simillarOrEqual: MathCommand = {
  label: "similar or equal",
  text: "≃",
  mathLiveCommand: "\\backsimeq",
  kind: "ams",
};
export const approxeq: MathCommand = {
  label: "approximately equal",
  text: "≊",
  mathLiveCommand: "\\approxeq",
  kind: "ams",
};
export const definedAs: MathCommand = {
  label: "defined as",
  text: "≐",
  mathLiveCommand: "\\doteq",
};
export const asymptotic: MathCommand = {
  label: "asymptotic",
  text: "≍",
  mathLiveCommand: "\\asymp",
};
export const verticalBar: MathCommand = {
  label: "vertical bar",
  text: "∣",
  mathLiveCommand: "\\mid",
};
export const notDivide: MathCommand = {
  label: "does not divide",
  text: "∤",
  mathLiveCommand: "\\nmid",
  kind: "ams",
};
export const parallel: MathCommand = {
  label: "parallel",
  text: "∥",
  mathLiveCommand: "\\parallel",
};
export const notParallel: MathCommand = {
  label: "not parallel",
  text: "∦",
  mathLiveCommand: "\\nparallel",
  kind: "ams",
};
export const perpendicular: MathCommand = {
  label: "perpendicular",
  text: "⊥",
  mathLiveCommand: "\\perp",
};
export const turnstile: MathCommand = {
  label: "turnstile",
  text: "⊢",
  mathLiveCommand: "\\vdash",
};
export const doubleTurnstile: MathCommand = {
  label: "double turnstile",
  text: "⊨",
  mathLiveCommand: "\\vDash",
};
export const downTack: MathCommand = {
  label: "down tack",
  text: "⊤",
  mathLiveCommand: "\\top",
};
export const equalByDef: MathCommand = {
  label: "equal by definitiion",
  text: "≜",
  mathLiveCommand: "\\triangleq",
  kind: "ams",
};
