import {
    findParentNode, getNodeType, InputRule,
    inputRulesPlugin, mergeAttributes, Node, wrappingInputRule
} from "@tiptap/core";
import { isListItem, wrapSelectedItems } from "../list";

export interface TaskItemOptions {
  HTMLAttributes: Record<string, unknown>;
}

export const inputRegex = /^\s*(\[( ?|x|X)]\s)$/;

export const TaskItem = Node.create<TaskItemOptions>({
  name: "taskItem",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "paragraph block*",

  defining: true,

  group: "listItemGroup",

  parseHTML() {
    return [
      {
        tag: 'li[data-type="taskItem"]',
        priority: 51,
      },
      // parse checklist item from dropbox paper
      // this needs to be higher in priority compared to list-item parser
      // as they're using the same `<li>` tag
      // task-item is marked using `data-task-created` and the checked
      // status is marksd using `data-task-completed`
      {
        tag: "li",
        priority: 51,
        getAttrs: (node) => {
          if (typeof node === "string") return false;
          const dom = node as HTMLElement;
          if (dom.getAttribute("data-task-created")) {
            return {
              checked: dom.getAttribute("data-task-completed"),
            };
          }
          return false;
        },
      },
    ];
  },

  addAttributes() {
    return {
      checked: {
        default: false,
        parseHTML: (element) =>
          element.getAttribute("data-checked") === "true" ||
          !!element.getAttribute("data-task-completed"),
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked,
        }),
        keepOnSplit: false,
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "li",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "taskItem",
      }),
      [
        "label",
        [
          "input",
          {
            ...(node.attrs.checked ? { checked: "true" } : {}),
            disabled: "true",
            type: "checkbox",
          },
        ],
      ],
      ["div", 0],
    ];
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.cadmusSplitListItem("taskItem"),
    };
  },

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const listItem = document.createElement("li");
      const checkboxWrapper = document.createElement("label");
      const checkboxStyler = document.createElement("span");
      const checkbox = document.createElement("input");
      const content = document.createElement("div");

      checkboxWrapper.contentEditable = "false";
      checkbox.type = "checkbox";
      checkbox.addEventListener("change", (event) => {
        // if the editor isn’t editable
        // we have to undo the latest change
        if (!editor.isEditable) {
          checkbox.checked = !checkbox.checked;

          return;
        }
        const { checked } = event.target as any;

        if (typeof getPos === "function" && editor.isEditable) {
          editor
            .chain()
            .focus(undefined, { scrollIntoView: false })
            .command(({ tr }) => {
              tr.setNodeMarkup(getPos(), undefined, {
                checked,
              });
              return true;
            })
            .run();
        }
      });

      Object.entries(this.options.HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value as string);
      });

      listItem.dataset.checked = node.attrs.checked;
      if (node.attrs.checked) {
        checkbox.setAttribute("checked", "checked");
        listItem.setAttribute("data-checked", "true");
      }

      checkboxWrapper.append(checkbox, checkboxStyler);
      listItem.append(checkboxWrapper, content);

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value);
      });

      return {
        dom: listItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }

          listItem.dataset.checked = updatedNode.attrs.checked;
          if (updatedNode.attrs.checked) {
            checkbox.setAttribute("checked", "checked");
            listItem.setAttribute("data-checked", "true");
          } else {
            checkbox.removeAttribute("checked");
            listItem.setAttribute("data-checked", "false");
          }

          return true;
        },
      };
    };
  },

  addProseMirrorPlugins() {
    return [
      inputRulesPlugin({
        editor: this.editor,
        rules: [
          wrappingInputRule({
            find: inputRegex,
            type: this.type,
            getAttributes: (match) => ({
              checked: ["x", "X"].includes(match[match.length - 1]),
            }),
          }),
          wrappingInputRule({
            find: /^\s*(\[\])\s$/,
            type: this.type,
            getAttributes: (_match) => ({
              checked: false,
            }),
          }),
          new InputRule({
            find: inputRegex,
            handler: ({ state, range, match }) => {
              const { tr } = state;
              tr.deleteRange(range.from, range.to);
              const listType = getNodeType("taskList", this.editor.schema);
              const canUpdate = wrapSelectedItems({
                itemType: this.type,
                listType,
                tr,
                editor: this.editor,
              });

              if (!canUpdate) {
                const $cursor = tr.doc.resolve(range.from);
                if ($cursor.depth > 2) {
                  const maybeListItem = $cursor.node($cursor.depth - 1);
                  if (
                    !isListItem(
                      maybeListItem.type.name,
                      this.editor.extensionManager.extensions
                    )
                  ) {
                    return null;
                  }
                } else {
                  return null;
                }
              }
              const checked = ["x", "X"].includes(match[match.length - 1]);
              const found = findParentNode((node) => node.type === this.type)(
                tr.selection
              );
              if (found) {
                tr.setNodeMarkup(found.pos, undefined, { checked });
              }
            },
          }),
        ],
      }),
    ];
  },
});
