import { Command } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";

import { SpecialIndentationStyle } from "../types";

export function toggleIndentStyle(
  enabledSytles: SpecialIndentationStyle[],
  defaultIndentation: SpecialIndentationStyle,
  stylableNodes: string[],
  style: SpecialIndentationStyle
): Command {
  return ({ tr, dispatch, state }) => {
    if (!enabledSytles.includes(style)) return false;

    let can = false;
    tr.selection.ranges.forEach((range) => {
      const from = range.$from.pos;
      const to = range.$to.pos;
      state.doc.nodesBetween(from, to, (node, pos, parent) => {
        const $pos = state.doc.resolve(pos);
        if (
          parent === state.doc &&
          $pos.depth <= 1 &&
          canToggleIndentStyle(enabledSytles, stylableNodes, node)
        ) {
          can = true;
          const newAttrs = {
            ...node.attrs,
            indentSpecial:
              node.attrs["indentSpecial"] === style
                ? defaultIndentation
                : style,
          };
          tr.setNodeMarkup(pos, undefined, newAttrs);
        }
      });
    });

    if (can) {
      dispatch?.(tr);
    }

    return can;
  };
}

function canToggleIndentStyle(
  enabledSytles: SpecialIndentationStyle[],
  stylableNodes: string[],
  node: PMNode
) {
  // If the node can have no Special Indentation styles
  if (!stylableNodes.includes(node.type.name)) return false;
  if (!enabledSytles.includes(node.attrs["indentSpecial"])) return false;
  return true;
}
