import { Editor } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { NodeView } from "@tiptap/pm/view";
import crel from "crelt";

import { ImageResizeHandles } from "./image-resize-handles";
import { ImageWidth } from "./types";

/**
 * Custom Image node view with caption, custom width and resize handles, and
 * alignment.
 *
 * ## Structure
 *
 *     <figure>
 *       <div class="img-wrapper">
 *         <img />
 *         <div class="resize-points" />
 *       </div>
 *    </figure>
 *
 * The `img` element has 100% width and is resized by adjusting the width of the
 * `.img-wrapper` according to the attributes of `image` node.
 */
export class ImageNodeView implements NodeView {
  /** Top level `<figure />` element. */
  dom: Node;
  /** Editable `<figcaption />` element. */
  contentDOM?: HTMLElement;
  /** Prosemirror `image` node */
  node: PMNode;
  /** Tiptap editor reference */
  editor: Editor;
  /** Wrapping div `.img-wrapper` on the img. */
  imgWrapperDiv: HTMLElement;
  /** Resize handles on the wrapper. */
  imageResizeHandles: ImageResizeHandles;

  constructor(
    node: PMNode,
    getPos: boolean | (() => number),
    HTMLAttributes: Object,
    editor: Editor
  ) {
    this.node = node;
    this.editor = editor;

    const figcaption = crel("figcaption", {
      class: node.attrs.hasCaption ? "" : "no-caption",
    });
    // Caption gets affected by the text alignment
    figcaption.style.textAlign = node.attrs.textAlign;

    const img = crel("img", {
      ...HTMLAttributes,
      contentEditable: false,
    });

    // Fire node selection on image click
    img.addEventListener("click", () => {
      if (typeof getPos === "function" && this.editor.isEditable) {
        if (getPos()) {
          this.editor.commands.setNodeSelection(getPos());
        }
      }
    });

    // create wrapper and render with attributes
    const wrapper = crel(
      "div",
      {
        "data-image-width": node.attrs.width,
        "data-image-align": node.attrs.imageAlign,
        class: "img-wrapper",
      },
      [img]
    );
    this.imgWrapperDiv = wrapper;
    this.updateWrapperWidth(img as HTMLImageElement);

    // Create the resize handles
    const resizeHandles = new ImageResizeHandles(wrapper, editor);
    this.imageResizeHandles = resizeHandles;

    const figure = crel(
      "figure",
      {
        draggable: "true",
        contentEditable: this.editor.isEditable,
      },
      [wrapper, figcaption]
    );

    this.dom = figure;
    this.contentDOM = figcaption;
  }

  /**
   * Updates the wrapper width based on the value of the `width` and
   * `customWidth` attributes of the image node.
   *
   * @param imgElement wrapped `<img />` element
   */
  private updateWrapperWidth(imgElement: HTMLImageElement) {
    if (this.node.attrs.width == ImageWidth.Custom) {
      this.imgWrapperDiv.style.width = `${this.node.attrs.customWidth}px`;
    } else if (this.node.attrs.width == ImageWidth.Original) {
      this.imgWrapperDiv.style.width = `${imgElement.naturalWidth}px`;

      // Re-read the natural width of the image after "load". This is needed because if the naturalWidth of the image
      // is not known initially, it is set to 0. The callback should be executed only once, so the
      // resizing does not occur on every `load` event.
      imgElement.addEventListener(
        "load",
        () => {
          this.imgWrapperDiv.style.width = `${imgElement.naturalWidth}px`;
        },
        { once: true }
      );
    } else {
      // Remove the image width property to allow css to determine width
      this.imgWrapperDiv.style.removeProperty("width");
    }
  }

  public update(node: PMNode) {
    // Skip if the update reference another node type
    if (node.type !== this.node.type) return false;
    // Or update the current tracked node
    this.node = node;

    // XXX What does this do?
    if (this.dom) {
      if (this.editor.isEditable) {
        (this.dom as HTMLElement).contentEditable = "true";
      } else {
        (this.dom as HTMLElement).contentEditable = "false";
      }
    }

    // Update img element attributes
    const imgs = (this.dom as HTMLElement).getElementsByTagName("img");
    const img = imgs && imgs[0];
    if (img) {
      crel(img, {
        src: node.attrs.src,
        alt: node.textContent,
      });
    }

    // Update attributes for imgWrapperDiv
    if (this.imgWrapperDiv) {
      crel(this.imgWrapperDiv, {
        "data-image-width": node.attrs.width,
        "data-image-align": node.attrs.imageAlign,
      });

      // Update the image wrapper width
      if (img) {
        this.updateWrapperWidth(img);
      }
    }

    // Update caption element attributes
    if (this.dom && this.contentDOM) {
      if (!node.attrs.hasCaption) {
        this.contentDOM.classList.add("no-caption");
      } else {
        this.contentDOM.classList.remove("no-caption");
        this.contentDOM.style.textAlign = node.attrs.textAlign;
      }
    }

    // Update is handled
    return true;
  }

  public selectNode() {
    // If the editor is not editable, the image should not be selectable
    if (!this.editor.isEditable) return;
    (this.dom as HTMLElement).classList.add("ProseMirror-selectednode");
    this.imageResizeHandles.setVisible(true);
  }

  public deselectNode() {
    (this.dom as HTMLElement).classList.remove("ProseMirror-selectednode");
    this.imageResizeHandles.setVisible(false);
  }

  /**
   * Ignores mutation to the imgWrapperDiv Dom element in order to prevent
   * re-rendering when drag reszing changes its width property.
   */
  public ignoreMutation = (
    mutation: MutationRecord | { type: "selection"; target: Element }
  ) => {
    let returnFlag = false;

    mutation.target.childNodes.forEach((childNode) => {
      if (childNode.nodeName === "IMG") returnFlag = true;
    });

    return returnFlag;
  };
}
