// A copy of prosemirror table column resizing as we want to use a react node view but column resizing plugin
// tries to update things that we don't want them to update as it needs to be controlled by react.
// without this copied. it works on chrome but not safari
// typescript type is not correct for some of them but will be updated slowly
import { Editor } from "@tiptap/core";
import { Node as PMNode, ResolvedPos } from "@tiptap/pm/model";
import { EditorState, Plugin, PluginKey, Transaction } from "@tiptap/pm/state";
import { TableMap, cellAround } from "@tiptap/pm/tables";
import { Decoration, DecorationSet, EditorView } from "@tiptap/pm/view";

function pointsAtCell($pos: ResolvedPos) {
  return $pos.parent.type.spec.tableRole == "row" && $pos.nodeAfter;
}

function setAttr<T>(
  attrs: Record<string, T>,
  name: string,
  value: T
): Record<string, T> {
  const result: Record<string, T> = {};
  for (const prop in attrs) result[prop] = attrs[prop];
  result[name] = value;
  return result;
}

export const columnResizingPluginKey = new PluginKey("tableColumnResizing");

export function columnResizing({
  editor,
  handleWidth = 5,
  cellMinWidth = 25,
  lastColumnResizable = true,
}: {
  editor?: Editor;
  handleWidth?: number;
  cellMinWidth?: number;
  lastColumnResizable?: boolean;
} = {}) {
  return new Plugin({
    key: columnResizingPluginKey,
    state: {
      init() {
        return new ResizeState(-1, false);
      },
      apply(tr, prev) {
        return prev.apply(tr);
      },
    },
    props: {
      attributes(state) {
        const pluginState = columnResizingPluginKey.getState(state);
        return pluginState.activeHandle > -1
          ? { class: "resize-cursor" }
          : { class: "" };
      },

      handleDOMEvents: {
        mousemove(view, event) {
          if (editor?.isEditable) {
            handleMouseMove(
              view,
              event,
              handleWidth,
              cellMinWidth,
              lastColumnResizable
            );
          }
          return false;
        },
        mouseleave(view) {
          if (editor?.isEditable) {
            handleMouseLeave(view);
          }
          return false;
        },
        mousedown(view, event) {
          if (editor?.isEditable) {
            handleMouseDown(view, event, cellMinWidth);
          }
          return false;
        },
      },

      decorations(state) {
        const pluginState = columnResizingPluginKey.getState(state);
        if (pluginState.activeHandle > -1)
          return handleDecorations(state, pluginState.activeHandle);
      },

      nodeViews: {},
    },
  });
}

class ResizeState {
  activeHandle: number | null;
  dragging: boolean | null;
  constructor(activeHandle: number | null, dragging: boolean | null) {
    this.activeHandle = activeHandle;
    this.dragging = dragging;
  }

  apply(tr: Transaction) {
    let state = this as ResizeState;
    const action = tr.getMeta(columnResizingPluginKey);
    if (action && action.setHandle != null)
      return new ResizeState(action.setHandle, null);
    if (action && action.setDragging !== undefined)
      return new ResizeState(state.activeHandle, action.setDragging);
    if (state.activeHandle && state.activeHandle > -1 && tr.docChanged) {
      let handle = tr.mapping.map(state.activeHandle, -1) as number | null;
      if (handle !== null && !pointsAtCell(tr.doc.resolve(handle)))
        handle = null;
      state = new ResizeState(handle, state.dragging);
    }
    return state;
  }
}

function handleMouseMove(
  view: EditorView,
  event: MouseEvent,
  handleWidth: number,
  _cellMinWidth: number,
  lastColumnResizable: boolean
) {
  const pluginState = columnResizingPluginKey.getState(view.state);

  if (!pluginState.dragging) {
    const target = domCellAround(event.target as HTMLElement | null);
    let cell = -1;
    if (target) {
      const { left, right } = target.getBoundingClientRect();
      if (event.clientX - left <= handleWidth)
        cell = edgeCell(view, event, "left");
      else if (right - event.clientX <= handleWidth)
        cell = edgeCell(view, event, "right");
    }

    if (cell != pluginState.activeHandle) {
      if (!lastColumnResizable && cell !== -1) {
        const $cell = view.state.doc.resolve(cell);
        const table = $cell.node(-1),
          map = TableMap.get(table),
          start = $cell.start(-1);
        const col =
          map.colCount($cell.pos - start) + $cell?.nodeAfter?.attrs.colspan - 1;

        if (col == map.width - 1) {
          return;
        }
      }

      updateHandle(view, cell);
    }
  }
}

function handleMouseLeave(view: EditorView) {
  const pluginState = columnResizingPluginKey.getState(view.state);
  if (pluginState.activeHandle > -1 && !pluginState.dragging)
    updateHandle(view, -1);
}
interface CellAttrs {
  colspan: number;
  colwidth: number[];
}

function handleMouseDown(
  view: EditorView,
  event: MouseEvent,
  cellMinWidth: number
) {
  const pluginState = columnResizingPluginKey.getState(view.state);
  if (pluginState.activeHandle == -1 || pluginState.dragging) return false;

  const cell = view.state.doc.nodeAt(pluginState.activeHandle);
  if (cell) {
    const width = currentColWidth(
      view,
      pluginState.activeHandle,
      cell.attrs as CellAttrs
    );
    view.dispatch(
      view.state.tr.setMeta(columnResizingPluginKey, {
        setDragging: { startX: event.clientX, startWidth: width },
      })
    );
  }

  function finish(event: MouseEvent) {
    window.removeEventListener("mouseup", finish);
    window.removeEventListener("mousemove", move);
    const pluginState = columnResizingPluginKey.getState(view.state);
    if (pluginState.dragging) {
      updateColumnWidth(
        view,
        pluginState.activeHandle,
        draggedWidth(pluginState.dragging, event, cellMinWidth)
      );
      view.dispatch(
        view.state.tr.setMeta(columnResizingPluginKey, { setDragging: null })
      );
    }
  }
  function move(event: MouseEvent) {
    if (!event.which) return finish(event);
    const pluginState = columnResizingPluginKey.getState(view.state);
    const dragged = draggedWidth(pluginState.dragging, event, cellMinWidth);
    displayColumnWidth(view, pluginState.activeHandle, dragged, cellMinWidth);
  }

  window.addEventListener("mouseup", finish);
  window.addEventListener("mousemove", move);
  event.preventDefault();
  return true;
}

function currentColWidth(
  view: EditorView,
  cellPos: number,
  { colspan, colwidth }: { colspan: number; colwidth: number[] }
) {
  const width = colwidth && colwidth[colwidth.length - 1];
  if (width) return width;
  const dom = view.domAtPos(cellPos);
  const node = dom.node.childNodes[dom.offset] as HTMLElement;
  let domWidth = node.offsetWidth,
    parts = colspan;
  if (colwidth)
    for (let i = 0; i < colspan; i++)
      if (colwidth[i]) {
        domWidth -= colwidth[i];
        parts--;
      }
  return domWidth / parts;
}

function domCellAround(target: HTMLElement | null): HTMLElement | null {
  while (target && target.nodeName != "TD" && target.nodeName != "TH")
    target = target.classList.contains("ProseMirror")
      ? null
      : (target.parentNode as HTMLElement | null);
  return target;
}

function edgeCell(view: EditorView, event: MouseEvent, side: "left" | "right") {
  const found = view.posAtCoords({ left: event.clientX, top: event.clientY });
  if (!found) return -1;
  const { pos } = found;
  const $cell = cellAround(view.state.doc.resolve(pos));
  if (!$cell) return -1;
  if (side == "right") return $cell.pos;
  const map = TableMap.get($cell.node(-1)),
    start = $cell.start(-1);
  const index = map.map.indexOf($cell.pos - start);
  return index % map.width == 0 ? -1 : start + map.map[index - 1];
}

type Dragging = { startX: number; startWidth: number };

function draggedWidth(
  dragging: Dragging,
  event: MouseEvent,
  cellMinWidth: number
) {
  const offset = event.clientX - dragging.startX;
  return Math.max(cellMinWidth, dragging.startWidth + offset);
}

function updateHandle(view: EditorView, value: number | null) {
  view.dispatch(
    view.state.tr.setMeta(columnResizingPluginKey, { setHandle: value })
  );
}

function updateColumnWidth(view: EditorView, cell: number, width: number) {
  const $cell = view.state.doc.resolve(cell);
  if (!$cell) return;
  const table = $cell.node(-1),
    map = TableMap.get(table),
    start = $cell.start(-1);
  const col =
    map.colCount($cell.pos - start) + $cell.nodeAfter?.attrs.colspan - 1;
  const tr = view.state.tr;
  for (let row = 0; row < map.height; row++) {
    const mapIndex = row * map.width + col;
    // Rowspanning cell that has already been handled
    if (row && map.map[mapIndex] == map.map[mapIndex - map.width]) continue;
    const pos = map.map[mapIndex];
    const cell = table.nodeAt(pos);
    if (!cell) continue;
    const { attrs } = cell;
    const index = attrs.colspan == 1 ? 0 : col - map.colCount(pos);
    if (attrs.colwidth && attrs.colwidth[index] == width) continue;
    const colwidth = attrs.colwidth
      ? attrs.colwidth.slice()
      : zeroes(attrs.colspan);
    colwidth[index] = width;
    tr.setNodeMarkup(
      start + pos,
      undefined,
      setAttr(attrs, "colwidth", colwidth)
    );
  }
  if (tr.docChanged) view.dispatch(tr);
}

function displayColumnWidth(
  view: EditorView,
  cell: number,
  width: number,
  cellMinWidth: number
) {
  const $cell = view.state.doc.resolve(cell);
  const table = $cell.node(-1),
    start = $cell.start(-1);
  const col =
    TableMap.get(table).colCount($cell.pos - start) +
    $cell.nodeAfter?.attrs.colspan -
    1;
  let dom = view.domAtPos($cell.start(-1)).node;
  while (dom.nodeName != "TABLE") dom = dom.parentNode as Node;
  if (dom.firstChild) {
    updateColumns(
      table,
      dom.firstChild as Element,
      dom as Element,
      cellMinWidth,
      col,
      width
    );
  }
}

function zeroes(n: number) {
  const result = [];
  for (let i = 0; i < n; i++) result.push(0);
  return result;
}

function handleDecorations(state: EditorState, cell: number) {
  const decorations = [];
  const $cell = state.doc.resolve(cell);
  const table = $cell.node(-1),
    map = TableMap.get(table),
    start = $cell.start(-1);
  const col = map.colCount($cell.pos - start) + $cell.nodeAfter?.attrs.colspan;
  for (let row = 0; row < map.height; row++) {
    const index = col + row * map.width - 1;
    // For positions that are have either a different cell or the end
    // of the table to their right, and either the top of the table or
    // a different cell above them, add a decoration
    if (
      (col == map.width || map.map[index] != map.map[index + 1]) &&
      (row == 0 || map.map[index - 1] != map.map[index - 1 - map.width])
    ) {
      const cellPos = map.map[index];
      const cell = table.nodeAt(cellPos);
      if (cell) {
        const pos = start + cellPos + cell.nodeSize - 1;
        const dom = document.createElement("div");
        dom.className = "column-resize-handle";
        decorations.push(Decoration.widget(pos, dom));
      }
    }
  }
  return DecorationSet.create(state.doc, decorations);
}

export function updateColumns(
  node: PMNode,
  colgroup: Element,
  table: Element,
  cellMinWidth: number,
  overrideCol?: number,
  overrideValue?: any
) {
  let totalWidth = 0;
  let fixedWidth = true;
  let nextDOM = colgroup.firstChild as HTMLElement | null;
  const row = node.firstChild;
  if (!row) return;
  for (let i = 0, col = 0; i < row.childCount; i += 1) {
    const { colspan, colwidth } = row.child(i).attrs;

    for (let j = 0; j < colspan; j += 1, col += 1) {
      const hasWidth =
        overrideCol === col ? overrideValue : colwidth && colwidth[j];
      const cssWidth = hasWidth ? `${hasWidth}px` : "";
      totalWidth += hasWidth || cellMinWidth;

      if (!hasWidth) {
        fixedWidth = false;
      }

      if (!nextDOM) {
        colgroup.appendChild(document.createElement("col")).style.width =
          cssWidth;
      } else {
        if (nextDOM.style.width !== cssWidth) {
          nextDOM.style.width = cssWidth;
        }

        nextDOM = nextDOM.nextSibling as HTMLElement | null;
      }
    }
  }

  while (nextDOM) {
    const after = nextDOM.nextSibling as HTMLElement | null;
    nextDOM.parentNode?.removeChild(nextDOM);
    nextDOM = after;
  }

  if (fixedWidth) {
    (table as HTMLElement).style.width = `${
      totalWidth > 800 ? 800 : totalWidth
    }px`;
    (table as HTMLElement).style.minWidth = "";
  } else {
    (table as HTMLElement).style.width = "";
    (table as HTMLElement).style.minWidth = `${
      totalWidth > 800 ? 800 : totalWidth
    }px`;
  }
}
