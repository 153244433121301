import { ReactNode, forwardRef } from "react";
import { Placement } from "@floating-ui/react-dom-interactions";
import { Icon, IconName } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { TooltipPosition } from "../mixins/Tooltip";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";

export interface ControlButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * Optional react node to use as the left icon. Provide one-of `icon` or `iconName`.
   */
  icon?: ReactNode;
  /**
   * Optional Cadmus Icon to display on the left
   */
  iconName?: IconName;

  /**
   * Add a down caret icon on the right
   */
  withDownCaret?: boolean;

  /** A11Y friendly label rendered as a tooltip. **/
  "aria-label"?: string;

  /**
   * Tooltip relative positioning.
   * @default "bottom"
   */
  tooltipPosition?: Placement;

  /** Optionally do not show the tooltip. @default false */
  hideTooltip?: boolean;

  /**
   * Render a selected state for the button
   */
  selected?: boolean;

  /**
   * Size in pixels.
   * @default 36
   */
  size?: number;

  /**
   * Whether to remove padding in the button.
   */
  noPadding?: boolean;

  /**
   * Disabled state
   */
  disabled?: boolean;
}

/**
 * A special type of `IconButton`.
 */
export const ControlButton = forwardRef<HTMLButtonElement, ControlButtonProps>(
  (props, ref) => {
    const {
      icon,
      iconName,
      children,
      withDownCaret,
      disabled,
      tooltipPosition = "bottom",
      hideTooltip = false,
      ...buttonProps
    } = props;

    const button = (
      <ButtonContainer
        disabled={disabled}
        role="button"
        aria-disabled={disabled ? "true" : "false"}
        ref={ref}
        {...buttonProps}
      >
        {icon && !iconName && <ControlIcon as="div">{icon}</ControlIcon>}
        {iconName && <ControlIcon iconName={iconName} />}
        {children ? <ControlButtonText>{children}</ControlButtonText> : ""}
        {withDownCaret && (
          <Icon
            iconName="DownCaret"
            style={{ marginRight: children ? 4 : undefined }}
          />
        )}
      </ButtonContainer>
    );

    if (props["aria-label"] && !hideTooltip) {
      return (
        <Tooltip
          size="sm"
          width="auto"
          placement={tooltipPosition}
          label={
            <PaddedTooltipText kind="subtle">
              {props["aria-label"]}
            </PaddedTooltipText>
          }
        >
          {button}
        </Tooltip>
      );
    }
    return button;
  }
);

// Underlying `button` element props
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  "aria-label"?: string;
  selected?: boolean;
  /**
   * Tooltip relative positioning.
   * @default "bottom"
   */
  tooltipPosition?: TooltipPosition;
  /**
   * Size in pixels.
   * @default 36
   */
  size?: number;

  /** Whether to remove padding in the button.  */
  noPadding?: boolean;
}

const PaddedTooltipText = styled(Text)`
  padding: 2px 4px;
  white-space: nowrap;
`;

export const ControlButtonText = styled.span`
  min-width: 126px;
  display: inline;
  text-align: left;
  padding: 0px 4px;
  font-size: 14px;
`;

const ControlIcon = styled(Icon)`
  margin: 0px 4px 0px 4px;
`;

const ButtonContainer = styled.button<ButtonProps>`
  min-width: ${(p) => p.size ?? 36}px;
  height: ${(p) => p.size ?? 36}px;
  padding: ${(p) => (p.noPadding ? "0px" : "4px")};

  border-radius: 6px;
  border: none;
  outline: 0;
  position: relative;
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  background-clip: content-box;
  background-color: ${(p) =>
    p.selected && !p.disabled ? p.theme.background.action1 : "transparent"};
  fill: ${(p) =>
    p.selected && !p.disabled ? p.theme.text.accent : p.theme.text.shade1};
  color: ${(p) =>
    p.selected && !p.disabled ? p.theme.text.accent : p.theme.text.main};
  transition: background 0.1s ease-out, fill 0.1s ease-out;

  &:not(:disabled) {
    &:hover {
      background-color: ${(p) => p.theme.background.action1};
    }

    &:focus,
    &:active,
    &[aria-expanded="true"] {
      background-color: ${(p) => p.theme.background.action2};
    }
  }

  &:disabled {
    opacity: 0.36;
  }
`;
