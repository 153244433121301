import { FocusAnimation, Spacer, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import Excellent from "assets/submission/xp-excellent.png";
import Good from "assets/submission/xp-good.png";
import Poor from "assets/submission/xp-poor.png";
import VeryGood from "assets/submission/xp-verygood.png";
import VeryPoor from "assets/submission/xp-verypoor.png";

export interface RatingScaleProps {
  onUpdateScore: (score: number) => void;
  /** Optionally adds background boxes to the scale emojis */
  withBackgroundBoxes?: boolean;
  /** Optionally add a left margin to the rating scale */
  scaleLeftMargin?: number;
}

export const RatingScale = ({
  onUpdateScore,
  withBackgroundBoxes,
  scaleLeftMargin,
}: RatingScaleProps) => {
  return (
    <>
      <EmojiGrid leftMargin={scaleLeftMargin}>
        <EmojiBackground show={withBackgroundBoxes}>
          <Emoji
            src={VeryPoor}
            title="Very Poor"
            onClick={() => onUpdateScore(1)}
          />
        </EmojiBackground>

        <EmojiBackground show={withBackgroundBoxes}>
          <Emoji src={Poor} title="Poor" onClick={() => onUpdateScore(2)} />
        </EmojiBackground>

        <EmojiBackground show={withBackgroundBoxes}>
          <Emoji src={Good} title="Good" onClick={() => onUpdateScore(3)} />
        </EmojiBackground>

        <EmojiBackground show={withBackgroundBoxes}>
          <Emoji
            src={VeryGood}
            title="Very Good"
            onClick={() => onUpdateScore(4)}
          />
        </EmojiBackground>

        <EmojiBackground show={withBackgroundBoxes}>
          <Emoji
            src={Excellent}
            title="Excellent"
            onClick={() => onUpdateScore(5)}
          />
        </EmojiBackground>
      </EmojiGrid>
      <Spacer spacing={18} />
      <EmojiLabel />
    </>
  );
};

const EmojiBackground = styled.div<{ show?: boolean }>`
  background-color: ${(p) => p.show && colors.grey100};
  border-radius: 6px;
  padding: 6px;
`;

// The 5 point emoji scale
const EmojiGrid = styled.div<{ leftMargin?: number }>`
  display: grid;
  grid-template-columns: repeat(5, minmax(36px, 1fr));
  grid-column-gap: 9px;
  justify-items: center;
  margin-left: ${(p) => `${p.leftMargin ?? 0}px`};
`;

const Emoji = styled.button<{ src: string; withBackground?: boolean }>`
  width: 36px;
  height: 36px;
  background: url(${(p) => p.src});
  background-size: 100%;
  border: none;
  border-radius: 50%;

  position: relative;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-position-y: bottom;
  }

  /* The :after pseudo-element creates the focus and ping outline*/
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 0 0px;
    opacity: 0;
  }
  &:focus:after {
    ${FocusAnimation}
  }
`;

const EmojiLabel = () => (
  <EmojiLabelContainer>
    <Text kind="label">Very poor</Text>
    <EmojiLabelBar />
    <Text kind="label">Excellent</Text>
  </EmojiLabelContainer>
);

const EmojiLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const EmojiLabelBar = styled.div`
  flex-grow: 1;

  height: 1px;
  margin: 0 9px;

  background: ${(p) => p.theme.divider};
`;
