import { Node as PMNode } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";
import { Rect, TableMap } from "@tiptap/pm/tables";

import type { ContentNodeWithPos } from "../../../core/types";
import type { BorderSide, BorderSideOption } from "../constants";
import { findTable } from "./find";
import { getCellsMap } from "./getCells";

/**
 * Check if selected cells have border on given side
 */
export const haveTableBorderStyle = (
  state: EditorState,
  side: BorderSideOption
) => {
  const table = findTable(state.selection);
  const cellsMap = getCellsMap(state.schema, state.selection);
  if (table && cellsMap) {
    const { cells, rect, height, map } = cellsMap;
    if (side === "top") {
      return cells.every((cell) => {
        if (!isTopCell(cell, table, map, rect)) return true;
        return cellHasBorder(cell, table, map, "top");
      });
    } else if (side === "bottom") {
      return cells.every((cell) => {
        if (!isBottomCell(cell, table, map, rect)) return true;
        return cellHasBorder(cell, table, map, "bottom");
      });
    } else if (side === "insideHorizontal") {
      if (height <= 1) return false;
      return cells.every((cell) => {
        if (!isBottomCell(cell, table, map, rect)) {
          return cellHasBorder(cell, table, map, "bottom");
        }
        return cellHasBorder(cell, table, map, "top");
      });
    } else if (side === "all") {
      return cells.every(
        (cell) =>
          cellHasBorder(cell, table, map, "bottom") &&
          cellHasBorder(cell, table, map, "top") &&
          cellHasBorder(cell, table, map, "right") &&
          cellHasBorder(cell, table, map, "left")
      );
    } else {
      return false;
    }
  }
  return false;
};

/**
 * Check if cell is at the top row of selected rect
 */
export const isTopCell = (
  cell: ContentNodeWithPos,
  table: ContentNodeWithPos,
  map: TableMap,
  rect?: Rect
) => {
  return !rect || rect.top === map.findCell(cell.pos - table.start).top;
};

/**
 * Check if cell is at the bottom row of selected rect
 */
export const isBottomCell = (
  cell: ContentNodeWithPos,
  table: ContentNodeWithPos,
  map: TableMap,
  rect?: Rect
) => {
  return !rect || rect.bottom === map.findCell(cell.pos - table.start).bottom;
};

/**
 * Check if a given cell have border on given side by checking
 * cell's border and it's neighbouring cell that intersect at border side
 */
export const cellHasBorder = (
  cell: ContentNodeWithPos,
  table: ContentNodeWithPos,
  map: TableMap,
  side: BorderSide
): boolean => {
  let neighbourCellPos;
  // Check if the cell have left border and/or the left side neighbour cell have right border
  if (side === "left") {
    neighbourCellPos = map.nextCell(cell.pos - table.start, "horiz", -1);
    if (neighbourCellPos !== null) {
      const neighbourCell = table.node.nodeAt(neighbourCellPos) as PMNode;
      return cell.node.attrs.border.left || neighbourCell.attrs.border.right;
    }
    return cell.node.attrs.border.left;
    // Check if the cell have top border and/or the top side neighbour cell have bottom border
  } else if (side === "top") {
    neighbourCellPos = map.nextCell(cell.pos - table.start, "vert", -1);
    if (neighbourCellPos !== null) {
      const neighbourCell = table.node.nodeAt(neighbourCellPos) as PMNode;
      return cell.node.attrs.border.top || neighbourCell.attrs.border.bottom;
    }
    return cell.node.attrs.border.top;
    // Check if the cell have right border and/or the right side neighbour cell have left border
  } else if (side === "right") {
    neighbourCellPos = map.nextCell(cell.pos - table.start, "horiz", 1);
    if (neighbourCellPos !== null) {
      const neighbourCell = table.node.nodeAt(neighbourCellPos) as PMNode;
      return cell.node.attrs.border.right || neighbourCell.attrs.border.left;
    }
    return cell.node.attrs.border.right;
    // Check if the cell have bottom border and/or the bottom side neighbour cell have top border
  } else {
    neighbourCellPos = map.nextCell(cell.pos - table.start, "vert", 1);
    if (neighbourCellPos !== null) {
      const neighbourCell = table.node.nodeAt(neighbourCellPos) as PMNode;
      return cell.node.attrs.border.bottom || neighbourCell.attrs.border.top;
    }
    return cell.node.attrs.border.bottom;
  }
};
