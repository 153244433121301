import { AppStartListening } from "@/data/listenerMiddleware";
import { cloudSave } from "@/features/work";

/**
 * Start redux listeners for re-trying the save request.
 */
export function startSaveListeners(startListening: AppStartListening) {
  startListening({
    actionCreator: cloudSave.rejected,
    effect: async (_action, listenerApi) => {
      const retry = () => listenerApi.dispatch(cloudSave({ isAutoSave: true }));
      // Artificially wait a bit inside the child
      await listenerApi.delay(3000);
      await retry();
    },
  });
}

// Simple exponential backoff with jitter delay calculation for an attempt.
// THANK YOU CHAT GPT
export function calculateDelayWithExponentialBackoffAndJitter(
  attemptNumber: number,
  baseDelay = 300,
  maxDelay = 5_000,
  factor = 1.5
) {
  const delay = baseDelay * Math.pow(factor, attemptNumber);
  const jitter = (Math.random() * delay) / 2;
  return Math.min(delay + jitter, maxDelay) + Math.random() * 250;
}
