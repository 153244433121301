import { mergeAttributes } from "@tiptap/core";

import {
  htmlToDOMOutputSpec,
  htmlparserConverter,
} from "../../../core/utilities/htmlToProsemirror";
import { InlineMathBase } from "./inline-math-base";

/**
 * Server side inline level atomic math nodes to display equation
 */
export const InlineMathServer = InlineMathBase.extend({
  renderHTML({ HTMLAttributes, node }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "inline-math-node",
      }),
      ...htmlToDOMOutputSpec(
        this.options.convertLatexToMarkup(node.attrs.equation),
        htmlparserConverter
      ),
    ];
  },
});
