export default (
  <>
    <path
      d="M0.466073 0.669765V0.672443C0.202715 0.723335 -0.00468088 0.983456 8.03613e-05 1.25576V18.7517C8.03613e-05 19.0558 0.274892 19.3331 0.575429 19.3331H15.424C15.7253 19.3331 16 19.0558 16 18.7504V5.22661C15.9946 5.13747 15.9693 5.04938 15.9266 4.9714L11.5113 0.705502C11.454 0.683627 11.3927 0.671574 11.3312 0.669044H0.574995C0.539286 0.665621 0.501782 0.666342 0.466073 0.669765Z"
      fill="#0F172E"
    />
    <path
      d="M4.66663 9.36104C4.66663 7.52009 6.15901 6.02771 7.99996 6.02771C9.84091 6.02771 11.3333 7.52009 11.3333 9.36104C11.3333 10.9142 10.271 12.2193 8.83329 12.5894V13.1215H7.16663V11.0277H7.99996C8.92044 11.0277 9.66663 10.2815 9.66663 9.36104C9.66663 8.44057 8.92044 7.69438 7.99996 7.69438C7.07949 7.69438 6.33329 8.44057 6.33329 9.36104H4.66663Z"
      fill="#F2F5FC"
    />
    <path d="M8.83329 16.0277V14.361H7.16663V16.0277H8.83329Z" fill="#F2F5FC" />
  </>
);
