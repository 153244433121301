import { Extension } from "@tiptap/core";
import { history, undo, redo } from "@tiptap/pm/history";
import { PluginKey } from "@tiptap/pm/state";

export interface HistoryOptions {
  /**
   * The amount of history events that are collected before the oldest events
   * are discarded. Defaults to 100.
   */
  depth: number;
  /**
   * The delay between changes after which a new group should be started. Defaults
   * to 500 (milliseconds). Note that when changes aren't adjacent, a new group is
   * always started.
   */
  newGroupDelay: number;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    history: {
      /**
       * Undo recent changes
       */
      undo: () => ReturnType;
      /**
       * Reapply reverted changes
       */
      redo: () => ReturnType;
    };
  }
}

/** Enum differenciating between UNDO and REDO history events. */
export type HistoryType = "undo" | "redo";

/** Transaction metadata payload that this plugin emits. */
export interface HistoryMeta {
  type: HistoryType;
}

export const HistoryPluginKey = new PluginKey("history");

/**
 * TipTap extension wrapper on the prosemirror-history plugin that emits Cadmus
 * Editor history events via Transaction metadata.
 *
 * These events will be collected by the `telemetry` Extension.
 */
export const History = Extension.create<HistoryOptions>({
  name: "history",
  addOptions() {
    return {
      depth: 100,
      newGroupDelay: 500,
    };
  },

  addCommands() {
    return {
      undo:
        () =>
        ({ state, dispatch }) => {
          return undo(state, (tr) => {
            const meta: HistoryMeta = { type: "undo" };
            tr.setMeta(HistoryPluginKey, meta);
            dispatch?.(tr);
          });
        },
      redo:
        () =>
        ({ state, dispatch }) => {
          return redo(state, (tr) => {
            const meta: HistoryMeta = { type: "redo" };
            tr.setMeta(HistoryPluginKey, meta);
            dispatch?.(tr);
          });
        },
    };
  },

  addProseMirrorPlugins() {
    return [history(this.options)];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-z": () => this.editor.commands.undo(),
      "Mod-y": () => this.editor.commands.redo(),
      "Shift-Mod-z": () => this.editor.commands.redo(),
    };
  },
});
