import { Node, mergeAttributes } from "@tiptap/core";

export interface TableGroupOptions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>;
}

/**
 * Wrapper Node for table with its caption and footnote
 */
export const TableGroup = Node.create<TableGroupOptions>({
  name: "tableGroup",

  addOptions() {
    return {
      HTMLAttributes: {
        "data-table-group": true,
      },
    };
  },
  group: "tableBlock",
  tableRole: "table_group",
  content: "tableCaption table tableFootnote",
  defining: true,

  renderHTML({ HTMLAttributes }) {
    return [
      "figure",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs(node) {
          if (typeof node === "string") return false;
          const dom = node as HTMLElement;
          if (
            dom.getAttribute("data-table-group") === "true" ||
            dom.querySelector(":scope > table")
          )
            return {};
          return false;
        },
      },
    ];
  },
});
