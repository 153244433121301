import { HTMLAttributes, useEffect } from "react";
import { Transaction } from "@tiptap/pm/state";
import { Editor, EditorContent } from "@tiptap/react";

import { CadmusEditorWrapper } from "./CadmusEditorWrapper";

// Props for `CadmusEditorContent`
interface Props extends HTMLAttributes<HTMLDivElement> {
  /** Main tiptap editor instance */
  editor: Editor | null;
  /**
   * Callback for opening manual preset articles when certain transactions contain a flag to do so.
   */
  onOpenManualPreset?: (preset: string) => void;
}

// Handler for the EvenEmitter transaction events
type TransactionHandler = ({
  transaction,
}: {
  transaction: Transaction;
}) => void;

/**
 * Cadmus styled wrapper of the `EditorContent` component with additional React
 * components and menus needed by the editor extensions.
 */
export function CadmusEditorContent(props: Props) {
  const { editor, onOpenManualPreset, ...divProps } = props;

  // Attach handler on Transactions for the `openManualPreset` metadata key. The
  // value for this metadata will be the preset article to open via the
  // `props.onOpenManualPreset` callback
  useEffect(() => {
    if (editor && onOpenManualPreset) {
      const handleManualPreset: TransactionHandler = ({ transaction }) => {
        const preset = transaction.getMeta("openManualPreset");
        if (preset) {
          onOpenManualPreset(preset);
        }
      };
      editor.on("transaction", handleManualPreset);
      return () => {
        editor.off("transaction", handleManualPreset);
      };
    }
    return undefined;
  }, [editor, onOpenManualPreset]);

  return (
    <CadmusEditorWrapper {...divProps}>
      <EditorContent editor={editor} />
    </CadmusEditorWrapper>
  );
}
