import { Extension } from "@tiptap/core";
import { Node as PMNode } from "@tiptap/pm/model";
import { Plugin, PluginKey, Selection } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

interface PlaceholderSpec {
  /**
   * check if a placeholder should be displayed at pos given the node and parent node
   */
  check: (
    selection: Selection,
    node: PMNode,
    pos: number,
    parent: PMNode
  ) => boolean;
  /**
   * decoration used as a placeholder to be displayed if it passed the check
   */
  decoration: (node: PMNode, pos: number) => Decoration | null;
}

export type PlaceholderSpecs = Map<string, PlaceholderSpec>;

export interface PlaceholderStorage {
  placeholders: Map<string, PlaceholderSpec>;
}

export const PlaceholderPluginKey = new PluginKey("placeholder");
/**
 * Define a standard way to add placeholder for a block
 */
export const Placeholder = Extension.create<
  PlaceholderSpec,
  PlaceholderStorage
>({
  name: "placeholder",
  addStorage() {
    return {
      placeholders: new Map<string, PlaceholderSpec>(),
    };
  },
  addProseMirrorPlugins() {
    const { storage, editor } = this;
    return [
      new Plugin({
        key: PlaceholderPluginKey,
        props: {
          decorations: (state) => {
            const { doc } = state;
            const decorations = [] as Decoration[];
            doc.descendants((node, pos, parent) => {
              storage.placeholders.forEach(({ check, decoration }) => {
                if (
                  editor.isEditable &&
                  parent &&
                  check(state.selection, node, pos, parent)
                ) {
                  const deco = decoration(node, pos);
                  if (deco) {
                    decorations.push(deco);
                  }
                }
              });
              return true;
            });
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
