import { isNodeSelection, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { Decoration } from "@tiptap/pm/view";

import { htmlToDOMOutputSpec } from "../../../core/utilities/htmlToProsemirror";
import { findCodeBlockSelection } from "../../code-block/utils";
import { PlaceholderStorage } from "../../placeholder";
import { findTableGroup } from "../../table/utils";
import { MathBlockNodeView } from "../components/MathBlockNodeView";
import { getMathNodeTypes } from "../utils";
import { BlockMathBase } from "./block-math-base";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockMath: {
      /**
       * Insert `blockMath` node wrapped with `mathFigure`
       */
      insertBlockMath: () => ReturnType;
      updateEquationCaption: (newCaption: string) => ReturnType;
    };
  }
}

/**
 * Block level atomic math nodes to display equation
 */
export const BlockMath = BlockMathBase.extend({
  onCreate() {
    const { type, editor } = this;
    const { storage } = editor;
    (storage.placeholder as PlaceholderStorage).placeholders.set(this.name, {
      check: (_selection, node, _pos) => {
        if (node.type.name !== type.name) return false;
        if (node.attrs.equation === "") return true;
        return false;
      },
      decoration: (node, pos) => {
        return Decoration.node(pos, pos + node.nodeSize, {
          class: "empty-math-node",
        });
      },
    });
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "math-block-wrapper math-node",
      }),
      ...htmlToDOMOutputSpec(
        this.options.convertLatexToMarkup(node.attrs.equation)
      ),
      [
        "div",
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
          class: "math-block-caption",
        }),
      ],
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(MathBlockNodeView);
  },
  // addPasteRules() {
  //   return [
  //     // Paste rule for markdown block equation `$$<equation>$$`
  //     new PasteRule({
  //       find: buildRegex(BLOCK_MATH_REGEX, undefined, undefined, "gui"),
  //       handler({ match, chain, range }) {
  //         if (match.input) {
  //           // replace match range with mathFigure > blockMath with the captured equation
  //           chain()
  //             .deleteRange(range)
  //             .insertContent(
  //               `<figure data-math-group="true"><math-field>${match[0].trim()}</math-field></figure>`
  //             );
  //         }
  //       },
  //     }),
  //   ];
  // },
  // addInputRules() {
  //   return [
  //     // Input rule for markdown block equation `$$<equation>$$`
  //     new InputRule({
  //       find: BLOCK_MATH_REGEX,
  //       handler({ match, chain, range }) {
  //         if (match.input) {
  //           // replace match range with mathFigure > blockMath with the captured equation
  //           chain()
  //             .deleteRange(range)
  //             .insertContent(
  //               `<figure data-math-group="true"><math-field>${match[1].trim()}</math-field></figure>`
  //             );
  //         }
  //       },
  //     }),
  //   ];
  // },
  addCommands() {
    return {
      insertBlockMath:
        () =>
        ({ state, chain, dispatch }) => {
          if (
            findTableGroup(state.selection) ||
            findCodeBlockSelection(state.selection)
          )
            return false;
          if (dispatch) {
            return chain()
              .insertContent(
                `<figure data-math-group="true"><math-field/></figure>`
              )
              .focus()
              .maybeOpenEquationMenu()
              .run();
          }
          return true;
        },
      updateEquationCaption:
        (newCaption) =>
        ({ commands, state, editor }) => {
          if (!isNodeSelection(state.selection)) {
            return false;
          }
          const mathTypes = getMathNodeTypes(editor.schema);
          const node = state.selection.node;
          if (mathTypes.block.name !== node.type.name) return false;
          commands.updateAttributes(this.type, {
            caption: newCaption,
          });
          return true;
        },
    };
  },
});
