import { Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { TableMap } from "@tiptap/pm/tables";
import { Decoration, DecorationSet } from "@tiptap/pm/view";
import crelt from "crelt";

import { findTable } from "../utils";
import { columnResizingPluginKey } from "./table-column-resizing-plugin";

export interface TableControlPluginOptions {
  editor: Editor;
}

export interface TableControlState {
  /**
   * Row index where user might insert a row to
   */
  row: number | null;
  /**
   * column index where user might insert a column to
   */
  col: number | null;
}

export const TableControlPluginKey = new PluginKey("tableControl");

/**
 * Plugin that shows control to select table and also visual indicator
 * on row/column insertion
 */
export const TableControlPlugin = (opts: TableControlPluginOptions) =>
  new Plugin<TableControlState>({
    key: TableControlPluginKey,
    state: {
      init() {
        return {
          row: null,
          col: null,
        };
      },
      apply(tr, oldState) {
        const payload = tr.getMeta(TableControlPluginKey);
        if (payload) {
          return payload;
        }
        return oldState;
      },
    },
    props: {
      decorations: (state) => {
        if (!opts.editor.isEditable) return DecorationSet.empty;
        const { doc, selection } = state;
        const decorations: Decoration[] = [];
        const table = findTable(selection);
        const isResizing = !!columnResizingPluginKey.getState(state)?.dragging;
        const pluginState = TableControlPluginKey.getState(state);

        if (table && !isResizing) {
          const map = TableMap.get(table.node);

          // Add visual guide on where a new row will be inserted if user
          // click the row insert button
          if (pluginState.row !== null && pluginState.row !== map.height) {
            map
              .cellsInRect({
                left: 0,
                right: map.width,
                top: pluginState.row,
                bottom: pluginState.row + 1,
              })
              .map((cellPos) => {
                decorations.push(
                  Decoration.widget(
                    table.start + cellPos + 1,
                    crelt("div", {
                      class: "row-insert-handle",
                    })
                  )
                );
              });
          }
          // Add visual guide on where a new column will be inserted if user
          // click the column insert button
          if (pluginState.col !== null && pluginState.col !== map.width) {
            map
              .cellsInRect({
                left: pluginState.col,
                right: pluginState.col + 1,
                top: 0,
                bottom: map.height,
              })
              .map((cellPos) => {
                decorations.push(
                  Decoration.widget(
                    table.start + cellPos + 1,
                    crelt("div", {
                      class: "col-insert-handle",
                    })
                  )
                );
              });
          }
        }
        return DecorationSet.create(doc, decorations);
      },
    },
  });
