import { Node } from "@tiptap/core";

export interface InlineMathOptions {
  HTMLAttributes: Record<string, unknown>;
  /**
   * Render a Latex math equation as HTML markup.
   */
  convertLatexToMarkup: (latex: string) => string;
}

/**
 * Inline level atomic math nodes to display equation
 */
export const InlineMathBase = Node.create<InlineMathOptions>({
  name: "inlineMath",
  inline: true,
  atom: true,
  selectable: true,
  draggable: true,
  group: "inline",
  mathRole: "inline",

  addOptions() {
    return {
      HTMLAttributes: {
        class: `node-${this.name}`,
      },
      convertLatexToMarkup: (latex: string) => `<code>${latex}</code>`,
    };
  },

  addAttributes() {
    return {
      equation: {
        default: "",
        parseHTML: (element) => {
          if (element.hasAttribute("data-math-latex"))
            return element.getAttribute("data-math-latex");
          return element.textContent;
        },
        renderHTML: ({ equation }) => ({
          "data-math-latex": equation,
        }),
      },
    };
  },

  renderText({ node }) {
    return `$${node.attrs.equation}$`;
  },
  parseHTML() {
    return [
      {
        tag: "math-field",
      },
      {
        tag: "span",
        getAttrs(node) {
          if (typeof node === "string") return false;
          const element = node as HTMLElement;
          if (element.hasAttribute("data-math-latex"))
            return {
              equation: element.getAttribute("data-math-latex"),
            };
          return false;
        },
      },
    ];
  },
});
