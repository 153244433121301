import { toast } from "react-hot-toast";
import NiceModal from "@ebay/nice-modal-react";
import {
  isAsyncThunkAction,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import type { AppStartListening } from "@/data/listenerMiddleware";
import {
  selectHasAutoSubmission,
  selectIsExam,
  selectIsTimedAssignment,
} from "@/features/assignment";
import { SubmitStatusModal } from "@/features/submission";
import {
  examLateSubmissionDatePassed,
  writingDatePassed,
} from "@/features/timeline";
import {
  acceptSubmissionDeclaration,
  openSubmitPrompt,
  selectHasAcceptedSubmissionDeclaration,
  selectHasFinal,
  selectSubmitNetworkError,
  submit,
  unsubmit,
} from "@/features/work";
import { ConnectedAcademicIntegrityModal } from "@/ui/shared/AcademicIntegrityPledge";
import { getCurrentDate } from "@/utils/datetime";
import { calculateDelayWithExponentialBackoffAndJitter } from "./save-listeners";

NiceModal.register("submit-status-modal", SubmitStatusModal);

/**
 * Start redux listeners related to the submission slice.
 */
export function startSubmitListeners(startListening: AppStartListening) {
  startTimedSubmissionListener(startListening);
  startSubmitActionListener(startListening);
}

/**
 * Listen for writing date to pass so that Auto Submissions or a Timed Submit
 * Modal can be displayed.
 */
function startTimedSubmissionListener(startListening: AppStartListening) {
  startListening({
    actionCreator: writingDatePassed,
    effect: async (_action, listenerApi) => {
      // Dismiss all toasts
      toast.dismiss();

      // Submissions should take the date NOW
      const now = getCurrentDate().toISOString();

      const state = listenerApi.getState();
      const isExam = selectIsExam(state);
      const autoSubmission = selectHasAutoSubmission(state);

      // Exam Auto submissions
      if (isExam && autoSubmission) {
        if (selectHasAcceptedSubmissionDeclaration(state)) {
          await listenerApi.dispatch(
            submit({ submissionDate: now, autoSubmission: true })
          );
        } else {
          // Open modal so student can agree to submission declaration
          await NiceModal.show(ConnectedAcademicIntegrityModal, {
            onAgree: () => {
              listenerApi.dispatch(acceptSubmissionDeclaration());
              listenerApi.dispatch(submit({ submissionDate: now }));
              NiceModal.hide(ConnectedAcademicIntegrityModal);
            },
          });
        }
        return;
      }

      // Timed submit modal for an exam without auto submission.
      if (isExam && !autoSubmission) {
        listenerApi.dispatch(openSubmitPrompt(now));
        return;
      }

      // Timed submit modal for timed assignments.
      if (selectIsTimedAssignment(state)) {
        listenerApi.dispatch(openSubmitPrompt(now));
        return;
      }
    },
  });

  startListening({
    actionCreator: examLateSubmissionDatePassed,
    effect: async (_action, listenerApi) => {
      if (!selectHasFinal(listenerApi.getState())) {
        const now = getCurrentDate().toISOString();
        listenerApi.dispatch(openSubmitPrompt(now));
      }
    },
  });
}

const isSubmitAction = isAsyncThunkAction(submit);

/**
 * Listen for the `submit` async action lifecycle.
 *
 * While the `submit` action is in in-flight, the Submit Status Modal tracks
 * it's loading state.
 *
 * On a submission error, the Submit Status Modal will display a error, while in
 * the background, we will retry the submission.
 *
 * Similarly, the `unsubmit` async action is tracked with the same modal.
 */
function startSubmitActionListener(startListening: AppStartListening) {
  startListening({
    matcher: isSubmitAction,
    effect: async (action, listenerApi) => {
      // Total number of submit request retries
      const MAX_ATTEMPTS = 100;
      const attempt = action.meta.arg?.attempt ?? 1;

      if (isPending(action)) {
        const freshRetry = () => {
          listenerApi.dispatch(submit({ ...action.meta.arg, attempt: 1 }));
        };
        return await NiceModal.show("submit-status-modal", {
          onRetry: attempt > MAX_ATTEMPTS ? freshRetry : undefined,
          autoSubmission: action.meta.arg?.autoSubmission ?? false,
        });
      }

      // Hide the modal if everything is good.
      if (isFulfilled(action)) {
        return await NiceModal.hide("submit-status-modal");
      }

      // Failed submission network errors should be retried
      if (
        isRejected(action) &&
        selectSubmitNetworkError(listenerApi.getState()) !== null
      ) {
        if (attempt <= MAX_ATTEMPTS) {
          // Retry the exact same submission request by copying the args.
          const retrySubmit = () => {
            return listenerApi.dispatch(
              submit({
                ...action.meta.arg,
                attempt: attempt + 1,
              })
            );
          };

          // Artificially wait a bit inside the child
          const delayMs =
            calculateDelayWithExponentialBackoffAndJitter(attempt);
          console.log("Retry after", delayMs);
          await listenerApi.delay(delayMs);
          await retrySubmit();
        }
      }
    },
  });

  startListening({
    matcher: isAsyncThunkAction(unsubmit),
    effect: async (action) => {
      if (isPending(action)) {
        NiceModal.show("submit-status-modal", { isUnsubmit: true });
      } else {
        NiceModal.hide("submit-status-modal");
      }
    },
  });
}
