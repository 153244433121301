import { ComponentProps, forwardRef } from "react";

import { Text } from "../Text";
import { MenuItemButton } from "./MenuItem";

interface Props extends ComponentProps<typeof MenuItemButton> {
  /** Text label to display as Link Menu Item's text. */
  text: string;

  /** Disabled item state */
  disabled?: boolean;
}

export const LinkMenuItem = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { text, disabled, ...buttonProps } = props;
    return (
      <MenuItemButton ref={ref} disabled={disabled} {...buttonProps}>
        <Text color="accent" kind="system">
          {text}
        </Text>
      </MenuItemButton>
    );
  }
);
