import { Editor } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import { textBetween } from "../../core/text";
import { canSpellCheckWithCadmus } from "./helpers";
import { SuggestionDecorationPluginKey } from "./suggestion-decoration-plugin";

/** Plugin creation options for the `SuggestionMenuPlugin`. */
export interface SuggestionMenuPluginProps {
  editor: Editor;
}
/** Reference plugin key for `SuggestionMenuPlugin`. */
export const SuggestionMenuPluginKey =
  new PluginKey<SuggestionMenuTarget | null>("suggestionMenu");

/** Suggestion decorated word target to render a suggestion menu for. */
export interface SuggestionMenuTarget {
  // Document start postition for the word
  from: number;
  // Document end position for the word
  to: number;
  // The text of the decorated word
  phrase: string;
  // Spelling canidates for the word
  candidates: string[];
}

/** Transaction metadata payload to trigger a suggestion menu. */
export interface TriggerMenuAction {
  type: "triggerMenu";
  target: SuggestionMenuTarget;
}

/** Transaction metadata payload to dismiss any existing suggestion menu. */
export interface DismissMenuAction {
  type: "dismissMenu";
}

/**
 * Initialise the SuggestionMenuPlugin which manages state and DOM events for
 * the right-click context menu providing spelling suggestions for any
 * right-clicked word (if its decorated with suggestions).
 *
 * ## Plugin State
 *
 * The plugin state will contain the information on any suggestion decorated
 * word under the cursor. If the word is not decorated or the selection is not
 * viable, the state will be `null`.
 *
 * The state information will contain the positions of the decoration and the
 * suggestion candidates. This data is pulled from the decoration spec data set
 * in the `SuggestionDecorationPlugin`.
 *
 * ## Transaction Meta
 *
 * The state updates are triggered using the transaction metadata payloads for
 * this plugin. These meta payloads are attached using the DOM event handlers
 * mentioned below. The following meta payloads are handled:
 *
 * - `TriggerMenuAction` to trigger the menu on a valid suggestion decorated
 *     word. The payload will contain all the position and spelling candidates
 *     for the menu to render.

 * - `DismissMenuAction` to dismiss the menu by re-setting the state to `null`.
 *
 */
export const SuggestionMenuPlugin = (props: SuggestionMenuPluginProps) => {
  return new Plugin<SuggestionMenuTarget | null>({
    key: SuggestionMenuPluginKey,
    state: {
      init() {
        return null;
      },
      apply(tr, state) {
        const payload = tr.getMeta(SuggestionMenuPluginKey) as
          | TriggerMenuAction
          | DismissMenuAction
          | undefined;

        switch (payload?.type) {
          case "triggerMenu":
            return payload.target;
          case "dismissMenu":
            return null;
          default:
            return state;
        }
      },
    },
    props: {
      // All non contextmenu clicks should be considered as dismissal of any
      // existing menu
      handleClick(view, _, event) {
        const state = this.getState(view.state);
        // only handle click if it is not context menu click
        if (event.button !== 2) {
          // set content of suggestion menu to null to hide it
          // when user do a click(not context menu click)
          if (state) {
            view.dispatch(
              view.state.tr.setMeta(this, <DismissMenuAction>{
                type: "dismissMenu",
              })
            );
          }
        }
        // need to return false so selection can work properly
        // especially after select all
        return false;
      },

      handleDOMEvents: {
        // Right-click context menu on a decorated word should render the
        // suggestion menu.
        contextmenu(view, event) {
          // Don't show menu if Cadmus spell check is disabled
          if (!canSpellCheckWithCadmus(props.editor)) return true;

          const pos = view.posAtCoords({
            left: event.clientX,
            top: event.clientY,
          });
          if (!pos) {
            return true;
          }
          // Check if there is a suggestion under the context menu click
          const from = pos.pos;
          const decoSet = SuggestionDecorationPluginKey.getState(view.state);
          const deco = decoSet?.find(from, from).pop();
          if (deco) {
            const spec = deco.spec as {
              phrase: string;
              candidates: string[];
            };
            if (
              textBetween(
                view.state.doc,
                "\n",
                deco.from,
                deco.to
              ).toLowerCase() === spec.phrase.toLowerCase()
            ) {
              view.dispatch(
                view.state.tr.setMeta(this, <TriggerMenuAction>{
                  type: "triggerMenu",
                  target: {
                    ...spec,
                    from: deco.from,
                    to: deco.to,
                  },
                })
              );
              event.preventDefault();
              event.stopPropagation();
              return true;
            }
          }
          // set content of suggestion menu to null to hide it
          view.dispatch(
            view.state.tr.setMeta(this, <DismissMenuAction>{
              type: "dismissMenu",
            })
          );
          return true;
        },
      },
    },
  });
};
