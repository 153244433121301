export default (
  <>
    <path d="M7.88109 13.9379L12.0117 9.81905L11.0234 8.82788L7.88109 11.9612L6.33143 10.416L5.34308 11.4071L7.88109 13.9379Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.67871 6.95086C1.67871 6.39858 2.12643 5.95086 2.67871 5.95086H3.77832V3.85126H5.17805V5.95086H12.1767V3.85126H13.5765V5.95086H14.6761C15.2284 5.95086 15.6761 6.39858 15.6761 6.95086V17.1488H1.67871V6.95086ZM3.07845 15.749V7.3506H14.2764V15.749H3.07845Z"
    />
  </>
);
