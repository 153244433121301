import { Spacer, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { formatDateFull } from "@/utils/datetime";

import lockScreenAccessIllustration from "../../assets/lock-screenno-access-illustration.svg";

type Reason = "deferred-withdrawn" | "past-due-not-started";

interface Props {
  reason: Reason;
  examStartDate: string | null;
  examEndDate: string | null;
}

export const ExamNoAccessPage = ({
  reason,
  examStartDate,
  examEndDate,
}: Props) => {
  return (
    <Container>
      <Illustration src={lockScreenAccessIllustration} />
      <Spacer spacing={36} />
      <Text kind="headingThree">
        {reason === "deferred-withdrawn" ? (
          <>It looks like you don't have access to this assessment</>
        ) : (
          <>It looks like this exam is now closed</>
        )}
      </Text>
      <Spacer spacing={24} />
      <Text kind="paragraph" color="grey500">
        {reason === "deferred-withdrawn" ? (
          <>
            Contact your teacher to ensure you are still enrolled in this
            assessment
          </>
        ) : (
          <PastDueMessage
            examStartDate={examStartDate}
            examEndDate={examEndDate}
          />
        )}
      </Text>
    </Container>
  );
};

const PastDueMessage = ({
  examStartDate,
  examEndDate,
}: Pick<Props, "examStartDate" | "examEndDate">) => {
  return (
    <>
      {!!examStartDate && (
        <>
          This exam was open from {formatDateFull(new Date(examStartDate))}
          {examEndDate ? <> - {formatDateFull(new Date(examEndDate))}</> : null}
          .
        </>
      )}
      <br />
      <br />
      If you need to gain access, please contact your teacher for assistance.
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey50};
  padding: 32px;
  width: 100%;
  height: 100%;
  text-align: center;
`;

const Illustration = styled.img`
  width: 254px;
`;
