import { useState } from "react";
import { ControlButton, Dropdown, levels } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { cellsColor, isSelectionType } from "../utils";
import { FormatRowColor } from "./FormatRowColor";
import { GroupDivider } from "./styles";
import { TableMenuItemComponentProps } from "./types";

/**
 * Table Menu component for applying background color to selected cell(s)
 */
export function CellColorMenu(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const [open, setOpen] = useState(false);
  if (!isSelectionType(editor.state.selection, "cell")) return null;
  return (
    <>
      <Dropdown
        open={open}
        position="bottom-right"
        content={<CellColorDropDownContent editor={editor} />}
        onOuterClick={() => setOpen(false)}
      >
        <ControlButton
          iconName="Paint"
          aria-label="Colour"
          onClick={() => setOpen(!open)}
        />
      </Dropdown>
      <GroupDivider />
    </>
  );
}

function CellColorDropDownContent(props: TableMenuItemComponentProps) {
  const { editor } = props;
  const activeColor = cellsColor(editor.state);
  return (
    <Menu>
      <FormatRowColor isCell activeColor={activeColor} editor={editor} />
    </Menu>
  );
}

const Menu = styled.div`
  width: 200px;
  background: white;
  padding: 9px 0px;
  margin: 0;
  max-height: 360px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.background.default};
  box-sizing: border-box;

  box-shadow: ${levels.three};
`;
