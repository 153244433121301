import { Plugin, PluginKey } from "@tiptap/pm/state";

export const CodeBlockPastePluginKey = new PluginKey("codeBlockPastePlugin");

export interface CodeBlockPasteMeta {
  source?: string;
  language?: string;
  insideCodeBlock?: boolean;
}

/**
 * Plugin that is used to track paste metadata relating to codeblock
 * whether its source is from `vscode` or `markdown` or `undefined`
 * when deduction can't be made on where it's coming from or even a
 * code block at all. In addition to that, it is also try to parse
 * the codeblock's language if the source is from `vscode` or `markdown`.
 * This plugin is also used to track whether selection is inside a codeblock
 * or not when the paste happen as if it is inside `codeBlock` then text only
 * paste is probably desired rather than a prosemirror's slice that comes from
 * most likely parsing `html` if the paste event have html clipboard.
 */
export const CodeBlockPastePlugin = () => {
  return new Plugin({
    key: CodeBlockPastePluginKey,
  });
};
