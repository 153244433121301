import { Editor } from "@tiptap/core";
import { NodeRange, Node as PMNode } from "@tiptap/pm/model";
import { Transaction } from "@tiptap/pm/state";
import { wrapSelectedItems } from "./wrapSelectedItems";

/**
 * Change list items type
 * ported from https://github.com/remirror/remirror/blob/9014d98411459968cf5aaac8ec9cf01e8bcd1c85/packages/remirror__extension-list/src/list-command-dedent.ts
 */
export function changeItemsType(
  editor: Editor,
  tr: Transaction,
  range: NodeRange,
  parentList: PMNode,
  parentItem: PMNode
) {
  const wrapped = wrapSelectedItems({
    editor: editor,
    listType: parentList.type,
    itemType: parentItem.type,
    tr,
  });

  if (wrapped) {
    return new NodeRange(tr.selection.$from, tr.selection.$to, range.depth);
  }
  return range;
}
