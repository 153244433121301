import { IconProps } from "./types";

export const CurlyBracketIcon = (props: IconProps) => {
  return (
    <svg width="28" height="29" viewBox="0 0 28 29" {...props}>
      <path
        d="M7.812 23.158C7.812 23.314 7.776 23.428 7.704 23.5H7.38C6.468 23.5 5.682 23.344 5.022 23.032C4.374 22.732 3.972 22.258 3.816 21.61C3.792 21.526 3.774 20.656 3.762 19C3.762 18.748 3.762 18.43 3.762 18.046C3.75 16.978 3.72 16.378 3.672 16.246C3.66 16.234 3.654 16.222 3.654 16.21C3.51 15.838 3.234 15.52 2.826 15.256C2.418 14.98 1.968 14.842 1.476 14.842C1.344 14.842 1.26 14.824 1.224 14.788C1.188 14.752 1.17 14.656 1.17 14.5C1.17 14.344 1.188 14.248 1.224 14.212C1.26 14.176 1.344 14.158 1.476 14.158C1.968 14.158 2.418 14.026 2.826 13.762C3.234 13.486 3.51 13.162 3.654 12.79C3.702 12.646 3.726 12.496 3.726 12.34C3.738 12.184 3.75 11.398 3.762 9.982C3.774 8.338 3.792 7.474 3.816 7.39C3.912 7.006 4.104 6.688 4.392 6.436C4.92 5.908 5.718 5.602 6.786 5.518C6.81 5.518 6.888 5.518 7.02 5.518C7.164 5.506 7.272 5.5 7.344 5.5H7.704C7.776 5.572 7.812 5.68 7.812 5.824C7.812 5.98 7.794 6.076 7.758 6.112C7.734 6.148 7.638 6.166 7.47 6.166C6.798 6.202 6.24 6.394 5.796 6.742C5.556 6.922 5.4 7.126 5.328 7.354C5.268 7.51 5.238 8.398 5.238 10.018C5.238 11.578 5.232 12.394 5.22 12.466C5.1 13.402 4.38 14.08 3.06 14.5C4.392 14.956 5.112 15.634 5.22 16.534C5.232 16.606 5.238 17.422 5.238 18.982C5.238 20.602 5.268 21.49 5.328 21.646C5.448 21.982 5.712 22.258 6.12 22.474C6.528 22.69 6.978 22.81 7.47 22.834C7.638 22.834 7.734 22.852 7.758 22.888C7.794 22.924 7.812 23.014 7.812 23.158Z"
        fill="#3A517F"
      />
      <path
        d="M10 10.8333C10 10.6492 10.1492 10.5 10.3333 10.5H17.6667C17.8508 10.5 18 10.6492 18 10.8333V18.1667C18 18.3508 17.8508 18.5 17.6667 18.5H10.3333C10.1492 18.5 10 18.3508 10 18.1667V10.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 11.5V17.5H17V11.5H11ZM10.3333 10.5C10.1492 10.5 10 10.6492 10 10.8333V18.1667C10 18.3508 10.1492 18.5 10.3333 18.5H17.6667C17.8508 18.5 18 18.3508 18 18.1667V10.8333C18 10.6492 17.8508 10.5 17.6667 10.5H10.3333Z"
        fill="#7070A9"
      />
      <path
        d="M20.17 5.842C20.17 5.686 20.182 5.59 20.206 5.554C20.23 5.518 20.302 5.5 20.422 5.5H20.584C21.544 5.5 22.342 5.662 22.978 5.986C23.614 6.31 24.016 6.778 24.184 7.39C24.22 7.498 24.238 8.368 24.238 10C24.25 11.62 24.262 12.478 24.274 12.574C24.394 13.006 24.664 13.378 25.084 13.69C25.504 14.002 25.978 14.158 26.506 14.158C26.638 14.158 26.722 14.176 26.758 14.212C26.794 14.248 26.812 14.344 26.812 14.5C26.812 14.656 26.794 14.752 26.758 14.788C26.722 14.824 26.638 14.842 26.506 14.842C26.038 14.842 25.594 14.974 25.174 15.238C24.766 15.502 24.496 15.814 24.364 16.174C24.304 16.33 24.268 16.492 24.256 16.66C24.256 16.816 24.25 17.596 24.238 19C24.226 20.656 24.208 21.526 24.184 21.61C24.088 21.994 23.896 22.312 23.608 22.564C22.96 23.188 21.958 23.5 20.602 23.5H20.404C20.308 23.5 20.242 23.476 20.206 23.428C20.182 23.392 20.17 23.296 20.17 23.14C20.17 23.008 20.182 22.924 20.206 22.888C20.23 22.852 20.29 22.834 20.386 22.834C20.974 22.834 21.478 22.708 21.898 22.456C22.33 22.204 22.606 21.886 22.726 21.502C22.738 21.442 22.75 20.59 22.762 18.946L22.78 16.498C22.996 15.502 23.716 14.836 24.94 14.5C24.544 14.368 24.244 14.248 24.04 14.14C23.38 13.744 22.972 13.252 22.816 12.664L22.78 12.484L22.762 10.036C22.75 8.404 22.738 7.552 22.726 7.48C22.606 7.096 22.33 6.784 21.898 6.544C21.478 6.292 20.974 6.166 20.386 6.166C20.29 6.166 20.23 6.148 20.206 6.112C20.182 6.076 20.17 5.986 20.17 5.842Z"
        fill="#3A517F"
      />
    </svg>
  );
};
