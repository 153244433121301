import { Desk, Icon, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useAppSelector } from "@/data/hooks";

import { selectActiveReadingDate } from "../../timeline-slice";

export function ConnectedReadingBar() {
  const showReadingBar = useAppSelector(selectActiveReadingDate) !== null;

  if (showReadingBar) {
    return <ReadingBar />;
  }
  return null;
}

export function ReadingBar() {
  return (
    <Container>
      <Icon iconName={"Eye"} />
      <Text kind="system" color="inherit">
        Reading time
      </Text>
    </Container>
  );
}

const Container = styled(Desk.Shelf)`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.orange200};
  color: ${colors.black100};
  box-shadow: inset 0px -1px 0px #ec6d13;
  gap: 8px;

  position: relative;
  z-index: 10;

  background: ${colors.blue200};
  color: ${colors.blue800};
  fill: #5b6f98;
  box-shadow: inset 0px -1px 0px rgba(89, 115, 166, 0.27);
  border: none;

  ${Icon} {
    margin-top: 2px;
  }
`;
