import { Editor, Command } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";
import { isListItem } from "../helpers";
import { wrapListBackward } from "./wrapListBackward";

/**
 * Command to handle backspace keymap
 * it does list wrapping, joining previous list or lifting list item
 * to parent list
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function listBackspace(editor: Editor): Command {
  return ({ chain }) => {
    return chain()
      .command(({ view }) => {
        if (!view) return false;
        return true;
      })
      .command(({ state }) => {
        const $cursor = (state.selection as TextSelection).$cursor;

        if (!$cursor || $cursor.parentOffset > 0) {
          return false;
        }

        const range = $cursor.blockRange();

        if (
          !range ||
          !isListItem(
            range.parent.type.name,
            editor.extensionManager.extensions
          ) ||
          range.startIndex !== 0
        ) {
          return false;
        }
        return true;
      })
      .command(({ tr }) => {
        if (!tr.steps.length) return false;
        wrapListBackward(editor, tr);
        return true;
      })
      .command(({ tr, state, commands }) => {
        if (!tr.steps.length) return false;
        const $cursor = (state.selection as TextSelection).$cursor;

        if (!$cursor || $cursor.parentOffset > 0) {
          return false;
        }

        const range = $cursor.blockRange();

        if (
          !range ||
          !isListItem(
            range.parent.type.name,
            editor.extensionManager.extensions
          ) ||
          range.startIndex !== 0
        ) {
          return false;
        }
        const itemIndex = $cursor.index(range.depth); // current node is the n-th node in item
        const listIndex = $cursor.index(range.depth - 1); // current item is the n-th item in list
        const rootIndex = $cursor.index(range.depth - 2); // current list is the n-th list in root

        if (itemIndex === 0 && listIndex === 0 && rootIndex <= 1) {
          commands.liftListItem(range.parent.type);
        }
        return true;
      })
      .command(({ commands, tr }) => {
        if (!tr.steps.length) return false;
        return commands.joinBackward();
      })
      .run();
  };
}
