import { Extension } from "@tiptap/core";
import { convertLatexToMarkup } from "mathlive";

import { BlockMathServer } from "./nodes/block-math-server";
import { InlineMathServer } from "./nodes/inline-math-server";
import { MathFigure } from "./nodes/math-figure";

export const MathServerExtension = Extension.create({
  name: "math",
  addExtensions: () => [
    MathFigure,
    BlockMathServer.configure({
      convertLatexToMarkup: (latex) => {
        return convertLatexToMarkup(latex, { mathstyle: "displaystyle" });
      },
    }),
    InlineMathServer.configure({
      convertLatexToMarkup: (latex) => {
        return convertLatexToMarkup(latex, { mathstyle: "textstyle" });
      },
    }),
  ],
});
