import { isAfter } from "date-fns";
import { createAction } from "@reduxjs/toolkit";
import {
  RequirementsFragment,
  WorkSettingsFragment,
} from "@/generated/graphql";

// Action payload for the updateSheet action.
export interface HydrateSheetPayload {
  workStartDate: string | null;
  settings: WorkSettingsFragment | null;
  requirements: RequirementsFragment | null;
}

export const updateSheet = createAction<HydrateSheetPayload>(
  "assignment/updateSheet"
);

export function getTimeLimit(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetTimeLimit = payload.requirements?.timeLimit ?? null;
  const workTimeLimit = payload.settings?.timeLimit ?? null;
  return selectTimeOrExtension(sheetTimeLimit, workTimeLimit);
}

export function getFinalDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetFinalDate = payload.requirements?.dueDate ?? null;
  const workFinalDate = payload.settings?.dueDate ?? null;
  return selectDateOrExtension(sheetFinalDate, workFinalDate);
}

export function getExamStartDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetStartDate = payload.requirements?.examStartDate ?? null;
  const workStartDate = payload.settings?.examStartDate ?? null;
  return selectDateOrExtension(sheetStartDate, workStartDate);
}

export function getExamEndDate(
  payload: HydrateSheetPayload
): [string | null, boolean] {
  const sheetEndDate = payload.requirements?.examEndDate ?? null;
  const workEndDate = payload.settings?.examEndDate ?? null;
  return selectDateOrExtension(sheetEndDate, workEndDate);
}

export function getExamReadingTime(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetReadingTime = payload.requirements?.examReadingTime ?? null;
  const workReadingTime = payload.settings?.examReadingTime ?? null;
  return selectTimeOrExtension(sheetReadingTime, workReadingTime);
}

export function getExamWritingTime(
  payload: HydrateSheetPayload
): [number | null, boolean] {
  const sheetWritingTime = payload.requirements?.examWritingTime ?? null;
  const workWritingTime = payload.settings?.examWritingTime ?? null;
  return selectTimeOrExtension(sheetWritingTime, workWritingTime);
}

function selectDateOrExtension(
  sheetDateStr: string | null,
  workDateStr: string | null
): [string | null, boolean] {
  if (sheetDateStr !== null && workDateStr !== null) {
    const validExtension = isAfter(
      new Date(workDateStr),
      new Date(sheetDateStr)
    );
    const date = validExtension ? workDateStr : sheetDateStr;
    return [date, validExtension];
  }

  if (sheetDateStr !== null) {
    return [sheetDateStr, false];
  }

  return [null, false];
}

function selectTimeOrExtension(
  sheetMinutes: number | null,
  workMinutes: number | null
): [number | null, boolean] {
  const validExtension =
    sheetMinutes !== null
      ? workMinutes !== null && workMinutes > sheetMinutes
      : false;
  const time = validExtension ? workMinutes : sheetMinutes;
  return [time, validExtension];
}
