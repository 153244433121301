import { EditorState } from "@tiptap/pm/state";
import { selectedRect } from "@tiptap/pm/tables";

import { findTable } from "./find";

/**
 * Check if table inside selection has an alternate background
 */
export const hasAlternateColor = (state: EditorState) => {
  const table = findTable(state.selection);
  if (table) {
    return !!table.node.attrs.alternateBackground;
  }
  return false;
};

/**
 * Check selected table's table level background color
 */
export const tableColor = (state: EditorState): string | null | undefined => {
  const table = findTable(state.selection);
  if (table) {
    return table.node.attrs.backgroundColor;
  }
  return undefined;
};

/**
 * Check selected table's cells level background color
 */
export const cellsColor = (state: EditorState): string | null | undefined => {
  const table = findTable(state.selection);
  let color: string | undefined;
  if (table) {
    const rect = selectedRect(state);
    if (!rect) return color;
    const { map, table } = rect;
    const cellsPos = map.cellsInRect(rect);
    cellsPos.some((cellPos, index) => {
      const cell = table.nodeAt(cellPos);
      if (cell) {
        if (!color && index === 0) {
          color = cell.attrs.backgroundColor;
        }
        if (color !== cell.attrs.backgroundColor && index !== 0) {
          color = undefined;
          return true;
        }
      }
    });
  }
  return color;
};
