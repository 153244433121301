import { IconProps } from "./types";

export const Vector1X3Icon = (props: IconProps) => {
  return (
    <svg width="30" height="22" viewBox="0 0 30 22" {...props}>
      <path
        d="M6 9.5749C6 9.54729 6.02239 9.5249 6.05 9.5249H9.95C9.97761 9.5249 10 9.54729 10 9.5749V13.4749C10 13.5025 9.97761 13.5249 9.95 13.5249H6.05C6.02239 13.5249 6 13.5025 6 13.4749V9.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05 8.5249H9.95C10.5299 8.5249 11 8.995 11 9.5749V13.4749C11 14.0548 10.5299 14.5249 9.95 14.5249H6.05C5.4701 14.5249 5 14.0548 5 13.4749V9.5749C5 8.995 5.4701 8.5249 6.05 8.5249ZM6.05 9.5249C6.02239 9.5249 6 9.54729 6 9.5749V13.4749C6 13.5025 6.02239 13.5249 6.05 13.5249H9.95C9.97761 13.5249 10 13.5025 10 13.4749V9.5749C10 9.54729 9.97761 9.5249 9.95 9.5249H6.05Z"
        fill="#7070A9"
      />
      <path
        d="M13 9.5749C13 9.54729 13.0224 9.5249 13.05 9.5249H16.95C16.9776 9.5249 17 9.54729 17 9.5749V13.4749C17 13.5025 16.9776 13.5249 16.95 13.5249H13.05C13.0224 13.5249 13 13.5025 13 13.4749V9.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.05 8.5249H16.95C17.5299 8.5249 18 8.995 18 9.5749V13.4749C18 14.0548 17.5299 14.5249 16.95 14.5249H13.05C12.4701 14.5249 12 14.0548 12 13.4749V9.5749C12 8.995 12.4701 8.5249 13.05 8.5249ZM13.05 9.5249C13.0224 9.5249 13 9.54729 13 9.5749V13.4749C13 13.5025 13.0224 13.5249 13.05 13.5249H16.95C16.9776 13.5249 17 13.5025 17 13.4749V9.5749C17 9.54729 16.9776 9.5249 16.95 9.5249H13.05Z"
        fill="#7070A9"
      />
      <path
        d="M20 9.5749C20 9.54729 20.0224 9.5249 20.05 9.5249H23.95C23.9776 9.5249 24 9.54729 24 9.5749V13.4749C24 13.5025 23.9776 13.5249 23.95 13.5249H20.05C20.0224 13.5249 20 13.5025 20 13.4749V9.5749Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.05 8.5249H23.95C24.5299 8.5249 25 8.995 25 9.5749V13.4749C25 14.0548 24.5299 14.5249 23.95 14.5249H20.05C19.4701 14.5249 19 14.0548 19 13.4749V9.5749C19 8.995 19.4701 8.5249 20.05 8.5249ZM20.05 9.5249C20.0224 9.5249 20 9.54729 20 9.5749V13.4749C20 13.5025 20.0224 13.5249 20.05 13.5249H23.95C23.9776 13.5249 24 13.5025 24 13.4749V9.5749C24 9.54729 23.9776 9.5249 23.95 9.5249H20.05Z"
        fill="#7070A9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5 10C27.5 9.17157 26.8284 8.5 26 8.5V7.5C27.3807 7.5 28.5 8.61929 28.5 10V13C28.5 14.3807 27.3807 15.5 26 15.5V14.5C26.8284 14.5 27.5 13.8284 27.5 13V10Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10C2.5 9.17157 3.17157 8.5 4 8.5V7.5C2.61929 7.5 1.5 8.61929 1.5 10V13C1.5 14.3807 2.61929 15.5 4 15.5V14.5C3.17157 14.5 2.5 13.8284 2.5 13V10Z"
        fill="#3A517F"
      />
    </svg>
  );
};
