import { Node, findParentNode, mergeAttributes } from "@tiptap/core";
import { Fragment, Node as PMNode } from "@tiptap/pm/model";
import { Plugin } from "@tiptap/pm/state";

import { DEFAULT_CUSTOM_WIDTH } from "./constants";
import { getImageSelection } from "./getImageSelection";
import { ImageNodeView } from "./image-node-view";
import { ImagePlugin } from "./image-plugin";
import { ImageAlignment, ImageWidth, TextAlignment } from "./types";

export interface ImageOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    image: {
      /** Add an image */
      setImage: (options: {
        src: string;
        width?: ImageWidth;
        customWidth?: number;
        imageAlign?: ImageAlignment;
        hasCaption?: boolean;
        position?: number;
        caption?: string;
      }) => ReturnType;

      /** Update the image width attribute on selected images. */
      updateImageWidth: (width: ImageWidth) => ReturnType;

      /** Update the customWidth attribute on the selected image */
      updateCustomWidth: (customWidth: number) => ReturnType;

      /** Update the imageAlign attribute on the selected image  */
      updateImageAlignment: (alignment: ImageAlignment) => ReturnType;

      /** Delete the image node */
      deleteImage: () => ReturnType;

      /** Toggle caption display */
      toggleImageCaption: () => ReturnType;
    };
  }
}

export const Image = Node.create<ImageOptions>({
  name: "image",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block",
  content: "inline*",
  selectable: true,
  draggable: true,
  defining: true,
  allowGapCursor: true,

  addAttributes() {
    return {
      src: {
        default: null,
        parseHTML: (element) => element.getAttribute("src"),
      },
      width: {
        default: ImageWidth.FitToText,
        parseHTML: (element) => {
          const width = element.getAttribute("data-image-width");
          switch (width) {
            case ImageWidth.FitToText:
            case ImageWidth.Full:
            case ImageWidth.Original:
            case ImageWidth.Custom:
              return width;
            default:
              return null;
          }
        },
        renderHTML: (attributes) => ({
          "data-image-width": attributes.width,
        }),
      },

      customWidth: {
        default: DEFAULT_CUSTOM_WIDTH,
        parseHTML: (element) => {
          const customWidth = element.getAttribute("data-image-custom-width");

          if (!customWidth) return null;

          try {
            const customWidthVal = parseInt(customWidth);
            return customWidthVal;
          } catch (e) {
            return null;
          }
        },
        renderHTML: (attributes) => ({
          "data-image-custom-width": attributes.customWidth,
        }),
      },

      imageAlign: {
        default: ImageAlignment.Center,
        parseHTML: (element) => {
          const alignment = element.getAttribute("data-image-align");
          switch (alignment) {
            case ImageAlignment.Right:
            case ImageAlignment.Center:
            case ImageAlignment.Left:
              return alignment;
            default:
              return null;
          }
        },
        renderHTML: (attributes) => ({
          "data-image-align": attributes.imageAlign,
        }),
      },

      textAlign: {
        default: TextAlignment.Center,
        parseHTML: (element) => {
          const alignment = element.style.textAlign;
          switch (alignment) {
            case TextAlignment.Center:
            case TextAlignment.Left:
            case TextAlignment.Right:
              return alignment;
            default:
              return TextAlignment.Center;
          }
        },
      },

      hasCaption: {
        default: false,
        parseHTML: (element) => {
          const captionNode =
            element.parentElement?.querySelector("figcaption") ||
            element.parentElement?.parentElement?.parentElement?.querySelector(
              "figcaption"
            );
          return element.hasAttribute("alt") || !!captionNode;
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "figcaption",
        ignore: true,
      },
      {
        tag: "img[src]",
        // The contents of this Node goes into the caption
        getContent: (element, schema) => {
          // In a straightforwad representation the element being parsed should
          // look like: <figure><img ... /><figcaption></figure>, with `img`
          // being the matched element here. Therefore we just parse the
          // contents of the sibling `figcaption` OR child of parent `figure`.
          let figureNode = element.parentElement;

          // But unfortunately in our old SlateJS editor HTML, we have the
          // following representation (due to React components):
          //    <figure>
          //      <div data-slate-void>
          //        <div contenteditable="false">
          //          <img .../>
          //        </div>
          //      </div>
          //      <figcaption>...</figcaption>
          //    <figure>
          // Therefore if we find that the `img` parent is a div, we go 3 levels
          // higher just to be sure we find the `figure` parent.
          if (figureNode && figureNode.tagName.toLowerCase() === "div") {
            figureNode =
              element.parentElement?.parentElement?.parentElement ?? null;
          }

          if (figureNode && figureNode.tagName.toLowerCase() === "figure") {
            const captionNode = figureNode.querySelector("figcaption");

            if (captionNode?.textContent) {
              const text = schema.text(captionNode.textContent);
              return Fragment.from(text);
            }
          }

          // Otherwise parse the caption contents from the `alt` attribute on the image
          const alt = (element as HTMLElement).getAttribute("alt") ?? "";
          if (alt) {
            const text = schema.text(alt);
            return Fragment.from(text);
          }

          // Empty caption
          return Fragment.empty;
        },
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    const { hasCaption } = node.attrs;
    // The rendered html needs to contain a `style.width` attribute set
    // similarly to how it's determined in nodeview so the pdf render of the
    // image can be styled properly
    let customWidthAttributes = {};
    switch (node.attrs.width) {
      case ImageWidth.Custom:
        customWidthAttributes = {
          style: `width:${node.attrs.customWidth}px`,
        };
        break;
      case ImageWidth.Original:
        customWidthAttributes = {
          style: `width:auto`,
        };
        break;
      case ImageWidth.FitToText:
        customWidthAttributes = {
          style: `width:710px`,
        };
        break;
      default:
        customWidthAttributes = {};
    }

    // Similar to Image Width the image alignment styles also need to be set for
    // pdf rendering
    let imageAlignAttributes = {};

    switch (node.attrs.imageAlign) {
      case ImageAlignment.Left:
        imageAlignAttributes = {
          style: "margin: 0px auto 0px 45px;",
        };
        break;
      case ImageAlignment.Right:
        imageAlignAttributes = {
          style: "margin: 0px 45px 0px auto;",
        };
        break;
      case ImageAlignment.Center:
      default:
        imageAlignAttributes = {
          style: "margin: 0px auto;",
        };
    }
    // Simlarly for caption alignment, the styles need to be set
    const captionAttribs = { style: `text-align: ${node.attrs.textAlign}` };

    // attributes to be attached to the `img` DOMnode
    const imgAttrs = mergeAttributes(
      this.options.HTMLAttributes,
      HTMLAttributes,
      {
        contentEditable: "false",
        style: "max-width: 100%; position: relative",
      },
      customWidthAttributes,
      imageAlignAttributes
    );

    return [
      "figure",
      {},
      ["img", imgAttrs],
      hasCaption ? ["figcaption", captionAttribs, 0] : "",
    ];
  },

  addKeyboardShortcuts() {
    return {
      // When pressing enter while typing in caption, a new paragraph node
      // should be created with default alignment. Without intercepting the
      // enter key, the new paragraph has the same alignment as caption and if
      // enter is pressed in middle of caption, the image node gets duplicated
      // which is not the desired behaviour.
      Enter: ({ editor }) => {
        const sel = editor.state.selection;
        const parentImageNode = findParentNode((node) => {
          return node.type == editor.schema.nodes.image;
        })(sel);

        // Enter key press not handled
        if (!parentImageNode) return false;

        // create paragraph node after image node
        const endPos = parentImageNode.pos + parentImageNode.node.nodeSize;
        editor.chain().insertContentAt(endPos, { type: "paragraph" }).run();

        // Keypress handled
        return true;
      },
    };
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ tr, dispatch, state }) => {
          const { caption } = options;
          let node: PMNode;
          // If image has caption then insert the caption as image's node's
          // content.
          if (caption?.length) {
            node = this.type.create(options, state.schema.text(caption));
          } else {
            node = this.type.create(options);
          }

          if (options.position !== undefined) {
            if (dispatch) tr.insert(options.position, node);
          } else {
            const { selection } = tr;
            if (dispatch)
              tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },

      updateImageWidth:
        (width) =>
        ({ chain, state }) => {
          const sel = getImageSelection(state);

          if (!sel) return false;

          const { from } = sel;
          return chain()
            .updateAttributes(this.name, { width })
            .setNodeSelection(from)
            .run();
        },

      updateCustomWidth:
        (customWidth) =>
        ({ chain, state }) => {
          const sel = getImageSelection(state);

          if (!sel) return false;

          const { from } = sel;
          return chain()
            .updateAttributes(this.name, {
              customWidth: customWidth,
              width: ImageWidth.Custom,
            })
            .setNodeSelection(from)
            .run();
        },

      updateImageAlignment:
        (alignment) =>
        ({ chain, state }) => {
          const sel = getImageSelection(state);

          if (!sel) return false;
          const { from } = sel;
          return chain()
            .updateAttributes(this.name, {
              imageAlign: alignment,
              textAlign: alignment, // changing image alignment should also change text alignment
            })
            .setNodeSelection(from)
            .run();
        },

      toggleImageCaption:
        () =>
        ({ chain, state }) => {
          const sel = getImageSelection(state);

          if (!sel) return false;

          const { node, from } = sel;
          const { hasCaption } = node.attrs;

          if (hasCaption === true) {
            return chain()
              .setNodeSelection(from)
              .updateAttributes(this.name, { hasCaption: false })
              .deleteRange({ from: from + 1, to: from + node.nodeSize })
              .run();
          }

          if (hasCaption === false) {
            return chain()
              .setNodeSelection(from)
              .updateAttributes(this.name, { hasCaption: true })
              .run();
          }

          return true;
        },

      deleteImage:
        () =>
        ({ chain, state }) => {
          const sel = getImageSelection(state);

          if (sel) {
            const { node, from } = sel;
            return chain()
              .deleteRange({ from, to: from + node.nodeSize })
              .run();
          }

          return false;
        },
    };
  },

  addNodeView() {
    return ({ editor, node, getPos, HTMLAttributes }) => {
      return new ImageNodeView(node, getPos, HTMLAttributes, editor);
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (_, __, state) => {
          const { doc, tr, schema } = state;
          if (doc.nodeAt(0)?.type.name === "image") {
            const type = schema.nodes["paragraph"];
            return tr.insert(0, type.create());
          }
          return null;
        },
      }),
      ImagePlugin(this.editor),
    ];
  },
});
