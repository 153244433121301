import styled from "styled-components";

/**
 * A base 9 column css grid — to build custom component layouts on top of.
 *
 * _Note: The Grid is designed to be **extended**._
 *
 * By default, the base Grid provides you with:
 *
 *   - 9 equally sized and equally spaced columns,
 *   - 27px of gutter spacing between each column,
 *   - A total maximum width of 1270px,
 *   - In-built horizontal padding (45px on the left and right),
 *   - Horizonal center positioning
 *
 * **Extending the grid:**
 *
 * You can use any CSS grid property to extend the base grid. A common pattern
 * involves by adding `grid-template-areas` to an extended styled-component.
 *
 */

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 27px;
  width: 100%;
  max-width: 1360px;
  padding-left: 45px;
  padding-right: 45px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;
