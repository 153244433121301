import styled from "styled-components";

import { Divider } from "./Divider";
import { Text } from "./Text";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  /** Text to display   */
  title: string;
}

/**
 * A label to introduce and define UI sections
 *
 */
export const SectionLabel: React.FC<Props> = ({
  title,
  children,
  ...divProps
}) => (
  <Container {...divProps}>
    <Label kind="label">{title || children}</Label>
    <Divider />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: baseline;
`;

const Label = styled(Text)`
  flex: none;
  padding-right: 9px;
  box-sizing: border-box;
  text-transform: uppercase;
`;
