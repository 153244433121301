import { Button, LinkButton, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { useResultLaunch } from "@/features/submission";
import similarityImg from "assets/submission/similarity.svg";
import { ResultFragment, SubmissionType } from "generated/graphql";
import { formatDay, isBeforeDate } from "utils/datetime";

import { DataBlock } from "./DataBlock";

interface Props {
  /** Pantheon gradebook Result with integration data. */
  result: ResultFragment;
  /** Submission type for which this result is displayed. */
  submissionType: SubmissionType;
  /** Feedback & Grade return date for the submission type. */
  returnDate: Date;
  /** Is the student allowed to view the similarity score? */
  canViewReport: boolean;
  /** Is the student allowed to make re-submissions? */
  canResubmit: boolean;
}

/**
 * Gradebook row view when SpeedGrader is the grading service, but Turnitin is
 * still used for Similarity reports.
 */
export function SpeedGraderView(props: Props) {
  const { result, canViewReport, submissionType, canResubmit, returnDate } =
    props;
  const onGradingLaunch = useResultLaunch(result, "speedgrader");
  const isBeforeReturnDate = returnDate ? isBeforeDate(returnDate) : false;
  const prettyReturnDate = formatDay(returnDate);

  const isDraft = submissionType === SubmissionType.Draft;
  const isFinal = submissionType === SubmissionType.Final;

  return (
    <Container>
      {canViewReport && (
        <SimilarityLink result={result} canResubmit={canResubmit} />
      )}
      <Spacer spacing={36} />
      <Button
        kind="dark"
        onClick={onGradingLaunch}
        disabled={onGradingLaunch === undefined}
      >
        View Feedback in Canvas
      </Button>
      <Spacer spacing={18} />
      {isBeforeReturnDate && isDraft && (
        <Text kind="subtle" textAlign="center" title="Message">
          If your teacher provides feedback on this draft, you can access it
          above.
        </Text>
      )}
      {isBeforeReturnDate && isFinal && (
        <Text kind="subtle" textAlign="center" title="Message">
          {`Feedback will be available after ${prettyReturnDate}.`}
        </Text>
      )}
    </Container>
  );
}

interface SimilarityLinkProps {
  result: ResultFragment;
  canResubmit: boolean;
}

const SimilarityLink = (props: SimilarityLinkProps) => {
  const { result, canResubmit } = props;
  const { similarity } = result;
  const similarityScore = similarity && similarity.display;
  const onSimilarityLaunch = useResultLaunch(result, "turnitin");

  let message = "";
  if (similarityScore === null) {
    message =
      "A Turnitin Similarity Report is being prepared for this submission...";
    if (canResubmit) {
      message +=
        " After 3 resubmissions, you'll need to wait 24 hrs to see your Similarity Score.";
    }
  }

  return (
    <>
      <Values>
        <DataBlock
          imgSrc={similarityImg}
          imgAlt="A doodle illustration of a document being scanned"
          data={similarityScore !== null ? `${similarityScore}%` : "—"}
          info="similarity score"
        />
      </Values>
      <Spacer spacing={18} />
      {message !== "" ? (
        <Text kind="subtle" textAlign="center" title="Message">
          {message}
        </Text>
      ) : (
        <Text kind="system" textAlign="center" title="Message">
          <LinkButton
            inline
            onClick={onSimilarityLaunch}
            disabled={onSimilarityLaunch === undefined}
          >
            View Similarity Report
          </LinkButton>
        </Text>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  animation-delay: 0.9s;
  animation-fill-mode: backwards;
`;

const Values = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;
