import {
  Extensions,
  callOrReturn,
  getExtensionField,
  NodeConfig,
  Node,
} from "@tiptap/core";
/**
 * Helper function to check if a node with type name is of type list item
 */
export function isListItem(name: string, extensions: Extensions): boolean {
  const extension = extensions
    .filter((extension) => extension.type === "node")
    .find((item) => item.name === name) as Node | undefined;
  if (!extension) return false;

  const context = {
    name: extension.name,
    options: extension.options,
    storage: extension.storage,
  };
  const group = callOrReturn(
    getExtensionField<NodeConfig["group"]>(extension, "group", context)
  );

  if (typeof group !== "string") {
    return false;
  }

  return group.split(" ").includes("listItemGroup");
}
