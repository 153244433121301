import { typography } from "@vericus/cadmus-ui";
import { css } from "styled-components";

const config = {
  fontFamily: "ibm-plex-mono, ui-monospace, monospace",
  fontFamilyLanguageCaption: typography.headingOne.fontFamily,

  fontSizeText: "14px",
  fontSizeLineNumber: "14px",
  fontSizeLanguageCaption: "12px",

  fontWeight: "400",
};

export const codeBlockSharedStyles = css`
  .CodeMirror {
    border-radius: 3px;
    width: 710px;
    max-width: 90%; /* allow for some margin*/
    height: auto;
    margin: 24px auto;
    line-height: 24px;

    font-family: ${config.fontFamily};
    font-weight: ${config.fontWeight};
    font-size: ${config.fontSizeText};

    user-select: text;
  }

  .CodeMirror .language-display {
    font-size: ${config.fontSizeLanguageCaption} !important;

    position: absolute;
    top: 12px;
    right: 12px;
    line-height: 12px !important;
    font-family: ${config.fontFamilyLanguageCaption} !important;
  }

  .CodeMirror-sizer {
    min-height: 64px !important ;
  }

  .CodeMirror-lines {
    padding: 28px 0px 20px 0px;
  }

  .language-display-off .CodeMirror-lines {
    padding: 20px 0px !important;
  }

  .CodeMirror-line {
    font-size: ${config.fontSizeText} !important ;
    padding: 0 16px !important;
  }

  .CodeMirror-linenumber {
    font-size: ${config.fontSizeLineNumber} !important;
    display: inline-flex;
    justify-content: right;
  }
`;
