import { KeyboardEvent, useCallback, useLayoutEffect, useState } from "react";
import {
  autoUpdate,
  offset,
  useDismiss,
  useFloating,
  useFocus,
  useInteractions,
  useRole,
} from "@floating-ui/react-dom-interactions";
import { NodeViewProps } from "@tiptap/react";
import { Input, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

interface CaptionInputProps extends Pick<NodeViewProps, "editor" | "getPos"> {
  caption: string;
  onConfirm: (caption: string) => void;
  onInputOpenChange: (open: boolean) => void;
}

export const CaptionInput = (props: CaptionInputProps) => {
  const { caption, onInputOpenChange, onConfirm, editor, getPos } = props;
  const [value, setValue] = useState(caption);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onConfirm(e.currentTarget.value);
      }
    },
    [onConfirm]
  );

  const { x, y, reference, floating, strategy, context } = useFloating({
    placement: "right",
    middleware: [offset(-80)],
    open: true,
    onOpenChange: (newOpen) => {
      if (value !== caption) {
        onConfirm(value);
      }
      onInputOpenChange(newOpen);
    },
    whileElementsMounted: autoUpdate,
  });

  const { getFloatingProps } = useInteractions([
    useRole(context, { role: "dialog" }),
    useFocus(context),
    useDismiss(context),
  ]);

  useLayoutEffect(() => {
    reference({
      getBoundingClientRect: () => {
        const pos = getPos();
        const dom = editor.view.nodeDOM(pos) as HTMLElement;
        return dom.getBoundingClientRect();
      },
    });
  }, [reference, editor, getPos]);

  return (
    <div
      {...getFloatingProps({
        ref: floating,
        style: {
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
          zIndex: 500,
        },
      })}
    >
      <StyledInput
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        onKeyDown={onKeyDown}
        maxLength={10}
        placeholder="(ref)"
      />
    </div>
  );
};
const StyledInput = styled(Input)`
  display: inline-block;
  background: white;
  input {
    text-align: right;
    width: 80px;
    min-width: unset;
    background: ${colors.grey50};
    font-size: 14px;
    line-height: 21px;
  }
`;
