import { Divider, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

export const ContentCol = styled.div`
  text-align: left;
  flex-grow: 2;
`;

export const ContentDivider = styled(Divider)`
  margin: 24px 0px;
`;

export const Illustration = styled.img`
  width: 325px;
  height: fit-content;
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-basis: 40%;
  flex-grow: 1;
`;

export const ActionContainer = styled.div`
  background: ${colors.white200};
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 436px;
`;
