import { useEffect, useRef, useState } from "react";
import { NodeViewContent, NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import styled from "styled-components";

import { cellDefaultWidth, maxWidth } from "../constants";
import { TableControl } from "./TableControl";

/**
 * Table React Nodeview component.
 */
export function TableComponent(props: NodeViewProps) {
  const { node, editor, getPos } = props;
  const [colWidths, setColWidths] = useState([] as number[]);
  const [totalWidth, setTotalWidth] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const row = node.firstChild;
    if (row) {
      const newColWidths = [] as number[];
      let newTotalWidths = 0;
      for (let i = 0, col = 0; i < row.childCount; i += 1) {
        const { colspan, colwidth } = row.child(i).attrs;
        for (let j = 0; j < colspan; j += 1, col += 1) {
          const colWidth = (colwidth && colwidth[j]) ?? cellDefaultWidth;
          newTotalWidths += colWidth;
          newColWidths.push(colWidth);
        }
      }
      setColWidths(newColWidths);
      if (newTotalWidths < maxWidth) {
        setTotalWidth(newTotalWidths);
      } else {
        setTotalWidth(maxWidth);
      }
    }
  }, [node]);

  return (
    <NodeViewWrapper style={{ position: "relative" }}>
      {editor.isEditable && (
        <TableControl
          editor={editor}
          tableRef={tableRef}
          node={node}
          colNum={colWidths.length}
          getPos={getPos}
        />
      )}
      <StyledTable
        backgroundColor={props.node.attrs.backgroundColor}
        alternateBackground={props.node.attrs.alternateBackground}
        ref={tableRef}
        style={{
          width: totalWidth,
        }}
      >
        <colgroup>
          {colWidths.map((colWidth, index) => (
            <col key={index} style={{ width: colWidth }} />
          ))}
        </colgroup>
        <NodeViewContent as="tbody" />
      </StyledTable>
    </NodeViewWrapper>
  );
}

interface StyledTableProps {
  backgroundColor: string;
  alternateBackground: string;
}

const StyledTable = styled.table.attrs<StyledTableProps>((p) => ({
  "data-cadmus-background-color": p.backgroundColor,
  "data-table-alternate-background": p.alternateBackground,
}))<StyledTableProps>`
  --background-color: ${(p) => p.backgroundColor};
  tbody > div {
    display: contents;
  }
`;
