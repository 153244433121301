import { IconProps } from "./types";

export const HatIcon = (props: IconProps) => {
  return (
    <svg width="18" height="22" viewBox="0 0 18 22" {...props}>
      <path
        d="M6.36319 5.09148L6.36319 5.09147L6.3627 5.09211C6.3498 5.10889 6.34479 5.12724 6.34244 5.13947C6.33988 5.15279 6.33906 5.1667 6.33906 5.17999C6.33906 5.25466 6.37672 5.3137 6.43799 5.35357C6.50198 5.3952 6.57947 5.41315 6.66528 5.41315C6.74493 5.41315 6.8173 5.39727 6.87636 5.35884C6.8902 5.34984 6.90547 5.33452 6.91791 5.32157C6.93253 5.30635 6.95092 5.28608 6.97285 5.26111L6.97288 5.26114L6.9745 5.25917C7.01852 5.20546 7.09474 5.10804 7.20261 4.96769C7.31592 4.82376 7.44268 4.66233 7.58289 4.48339C7.72905 4.30377 7.93714 4.04355 8.20705 3.70287C8.45925 3.38456 8.72323 3.05245 8.99899 2.70654C9.68039 3.56609 10.1971 4.21597 10.5492 4.65611C10.7302 4.88242 10.8678 5.05342 10.9619 5.169C11.0089 5.22676 11.0452 5.27092 11.0708 5.30131C11.0835 5.31645 11.0938 5.32856 11.1017 5.33735C11.1055 5.3417 11.1092 5.34574 11.1125 5.34911L11.1127 5.34935C11.1143 5.35102 11.1213 5.35844 11.1301 5.36411L11.1299 5.36441L11.1363 5.36778C11.1997 5.40074 11.2655 5.41843 11.333 5.41843C11.4665 5.41843 11.584 5.38061 11.6355 5.27082C11.6611 5.22027 11.6771 5.15472 11.6388 5.09655L11.639 5.09641L11.6352 5.0916C10.8599 4.10038 10.2723 3.35285 9.87233 2.84913C9.67238 2.59731 9.51916 2.40619 9.41281 2.27596C9.35967 2.21089 9.31794 2.16066 9.2878 2.12553C9.27278 2.10803 9.26021 2.09377 9.25034 2.08313C9.24543 2.07784 9.24071 2.07293 9.2364 2.06875C9.23322 2.06567 9.22648 2.05921 9.21835 2.05392C9.16638 2.02011 9.09892 2.00232 9.02284 1.99471L9.02288 1.99434H9.01537C8.92535 1.99434 8.84471 2.01181 8.77998 2.05392L8.77998 2.05392C8.77347 2.05815 8.7688 2.06272 8.76808 2.06344L8.768 2.06351L8.76406 2.06753L8.75805 2.07423L8.74222 2.09294C8.72925 2.10852 8.71069 2.1312 8.68667 2.16081C8.63856 2.2201 8.56786 2.30802 8.47464 2.42448C8.28817 2.65744 8.01123 3.00506 7.64385 3.46728L7.64368 3.46749C6.91437 4.39186 6.48751 4.93323 6.36319 5.09148Z"
        fill="#3A517F"
        stroke="#3A517F"
        strokeWidth="0.15"
      />
      <path
        d="M3 7.49707C3 7.22093 3.22386 6.99707 3.5 6.99707H14.5C14.7761 6.99707 15 7.22093 15 7.49707V18.4971C15 18.7732 14.7761 18.9971 14.5 18.9971H3.5C3.22386 18.9971 3 18.7732 3 18.4971V7.49707Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 7.99707V17.9971H14V7.99707H4ZM3.5 6.99707C3.22386 6.99707 3 7.22093 3 7.49707V18.4971C3 18.7732 3.22386 18.9971 3.5 18.9971H14.5C14.7761 18.9971 15 18.7732 15 18.4971V7.49707C15 7.22093 14.7761 6.99707 14.5 6.99707H3.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
