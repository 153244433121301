import { useCallback } from "react";
import { Editor, isNodeSelection } from "@tiptap/core";
import { ControlButton, levels } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { FloatingMenuComponent, ShouldShow } from "../floating-menu";
import { ImageAlignment, ImageWidth } from "../image/types";

interface Props {
  editor: Editor;
  portalId: string;
}

/**
 * Bubble menu component for single image management.
 */
export function ImageMenuComponent(props: Props) {
  const { editor, portalId } = props;
  const shouldShow: ShouldShow = useCallback(({ state }) => {
    const { selection } = state;
    const { empty, $anchor } = selection;
    if (empty || !$anchor.parent) return false;
    if (
      isNodeSelection(selection) &&
      selection.node.type == state.schema.nodes.image
    ) {
      return true;
    }
    return false;
  }, []);

  return (
    <FloatingMenuComponent
      pluginKey="imageFloatingMenu"
      editor={editor}
      shouldShow={shouldShow}
      portalId={portalId}
    >
      <ImageMenuItems editor={editor} />
    </FloatingMenuComponent>
  );
}

function ImageMenuItems({ editor }: { editor: Editor }) {
  const onCenterAlign = () =>
    editor.chain().focus().updateImageAlignment(ImageAlignment.Center).run();

  const onLeftAlign = () =>
    editor.chain().focus().updateImageAlignment(ImageAlignment.Left).run();

  const onRightAlign = () =>
    editor.chain().focus().updateImageAlignment(ImageAlignment.Right).run();

  const onFitToTextWidth = () =>
    editor.chain().focus().updateImageWidth(ImageWidth.FitToText).run();

  const onFullWidth = () =>
    editor
      .chain()
      .focus()
      .updateImageWidth(ImageWidth.Full)
      .updateImageAlignment(ImageAlignment.Center)
      .run();

  const onOriginalWidth = () =>
    editor.chain().focus().updateImageWidth(ImageWidth.Original).run();

  const onCaptionClick = () =>
    editor.chain().focus().toggleImageCaption().run();

  const onDeleteImage = () => editor.chain().focus().deleteImage().run();

  const isCenterAlign = editor.isActive("image", {
    imageAlign: ImageAlignment.Center,
  });

  const isLeftAlign = editor.isActive("image", {
    imageAlign: ImageAlignment.Left,
  });

  const isRightAlign = editor.isActive("image", {
    imageAlign: ImageAlignment.Right,
  });

  const isFitToText = editor.isActive("image", {
    width: ImageWidth.FitToText,
  });

  const isFullWidth = editor.isActive("image", {
    width: ImageWidth.Full,
  });

  const isOriginalWidth = editor.isActive("image", {
    width: ImageWidth.Original,
  });

  const hasCaption = editor.isActive("image", {
    hasCaption: true,
  });
  return (
    <ButtonGroups>
      <ControlButton
        aria-label="Left Align Image"
        selected={isLeftAlign}
        disabled={isFullWidth}
        onClick={onLeftAlign}
        iconName="ImageAlignLeft"
      />
      <ControlButton
        aria-label="Center Align Image"
        selected={isCenterAlign}
        onClick={onCenterAlign}
        iconName="ImageAlignCenter"
      />
      <ControlButton
        aria-label="Right Align Image"
        selected={isRightAlign}
        disabled={isFullWidth}
        onClick={onRightAlign}
        iconName="ImageAlignRight"
      />
      <GroupDivider />
      <ControlButton
        aria-label="Fit To Text"
        selected={isFitToText}
        onClick={onFitToTextWidth}
        iconName="ImageFit"
      />
      <ControlButton
        aria-label="Banner Size"
        selected={isFullWidth}
        onClick={onFullWidth}
        iconName="ImageCover"
      />
      <ControlButton
        aria-label="Original Image Size"
        selected={isOriginalWidth}
        onClick={onOriginalWidth}
        iconName="ImageOriginal"
      />
      <GroupDivider />
      <ControlButton
        aria-label="Toggle Image Caption"
        selected={hasCaption}
        onClick={onCaptionClick}
        iconName="Caption"
      />
      <GroupDivider />
      <ControlButton
        aria-label="Delete Image"
        onClick={onDeleteImage}
        iconName="Trash"
      />
    </ButtonGroups>
  );
}

const ButtonGroups = styled.div`
  background: white;
  box-shadow: ${levels.two};
`;

const GroupDivider = styled.div`
  display: inline-flex;
  width: 1px;
  height: 20px;
  background: rgba(89, 115, 166, 0.15);
  margin: 7px 2px;
`;
