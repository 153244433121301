import { IconProps } from "./types";

export const Vector1X2Icon = (props: IconProps) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" {...props}>
      <rect x="6" y="8.0249" width="5" height="5" rx="0.55" fill="#8C8CBA" />
      <rect
        x="6"
        y="8.0249"
        width="5"
        height="5"
        rx="0.55"
        stroke="#7070A9"
        fill="none"
      />
      <rect x="13" y="8.0249" width="5" height="5" rx="0.55" fill="#8C8CBA" />
      <rect
        x="13"
        y="8.0249"
        width="5"
        height="5"
        rx="0.55"
        stroke="#7070A9"
        fill="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 8C20.5 7.72386 20.2761 7.5 20 7.5V6.5C20.8284 6.5 21.5 7.17157 21.5 8V13C21.5 13.8284 20.8284 14.5 20 14.5V13.5C20.2761 13.5 20.5 13.2761 20.5 13V8Z"
        fill="#3A517F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 8C3.5 7.72386 3.72386 7.5 4 7.5V6.5C3.17157 6.5 2.5 7.17157 2.5 8V13C2.5 13.8284 3.17157 14.5 4 14.5V13.5C3.72386 13.5 3.5 13.2761 3.5 13V8Z"
        fill="#3A517F"
      />
    </svg>
  );
};
