import { Icon as CadmusIcon } from "@vericus/cadmus-icons";
import styled from "styled-components";
import { CuiTheme } from "./styles/theme";

interface CuiProps {
  /** Applies one of the theme's text colors */
  textColor?: keyof CuiTheme["text"];
}

export const Icon = styled(CadmusIcon)<CuiProps>`
  fill: ${p => (p.textColor ? p.theme.text[p.textColor] : "inherit")};
`;
