export interface CadmusClipboard {
  origin: string;
  content: string;
}

/**
 * Global Cadmus Editor library specific shared clipboard.
 */
let __CADMUS_EDITOR_CLIPBOARD: CadmusClipboard | null = null;

export function getLastClipboard(): CadmusClipboard | null {
  return __CADMUS_EDITOR_CLIPBOARD;
}

export function saveToClipboard(clipboard: CadmusClipboard) {
  __CADMUS_EDITOR_CLIPBOARD = clipboard;
}
