import { Node, mergeAttributes, getNodeType } from "@tiptap/core";

export interface TaskListOptions {
  HTMLAttributes: Record<string, any>;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    taskList: {
      /**
       * Toggle a task list
       */
      toggleTaskList: () => ReturnType;
    };
  }
}

export const TaskList = Node.create<TaskListOptions>({
  name: "taskList",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "block list",

  content: "taskItem+",

  parseHTML() {
    return [
      {
        tag: 'ul[data-type="taskList"]',
        priority: 51,
      },
      // parse checklist from dropbox paper
      // this needs to be higher in priority compared to unordered list parser
      // as they're using the same `<ul>` tag
      {
        tag: "ul",
        priority: 51,
        getAttrs: (node) => {
          if (typeof node === "string") return false;
          const dom = node as HTMLElement;
          const classNames = dom.className.split(" ");
          const isCheckList = classNames.some((className) =>
            /(checkList|list-task)/i.test(className)
          );
          if (!isCheckList) return false;
          return {};
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "ul",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": "taskList",
      }),
      0,
    ];
  },

  addCommands() {
    return {
      toggleTaskList:
        () =>
        ({ commands }) => {
          return commands.toggleCadmusList(
            this.type,
            getNodeType("taskItem", this.editor.schema)
          );
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-Shift-9": () => this.editor.commands.toggleTaskList(),
    };
  },
});
