import { IconProps } from "./types";

export const SubscriptSuperscriptIcon = (props: IconProps) => {
  return (
    <svg width="30" height="26" viewBox="0 0 30 26" {...props}>
      <path
        d="M20 18.05C20 18.0224 20.0224 18 20.05 18H23.95C23.9776 18 24 18.0224 24 18.05V21.95C24 21.9776 23.9776 22 23.95 22H20.05C20.0224 22 20 21.9776 20 21.95V18.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.05 17H23.95C24.5299 17 25 17.4701 25 18.05V21.95C25 22.5299 24.5299 23 23.95 23H20.05C19.4701 23 19 22.5299 19 21.95V18.05C19 17.4701 19.4701 17 20.05 17ZM20.05 18C20.0224 18 20 18.0224 20 18.05V21.95C20 21.9776 20.0224 22 20.05 22H23.95C23.9776 22 24 21.9776 24 21.95V18.05C24 18.0224 23.9776 18 23.95 18H20.05Z"
        fill="#7070A9"
      />
      <path
        d="M20 4.05C20 4.02239 20.0224 4 20.05 4H23.95C23.9776 4 24 4.02239 24 4.05V7.95C24 7.97761 23.9776 8 23.95 8H20.05C20.0224 8 20 7.97761 20 7.95V4.05Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.05 3H23.95C24.5299 3 25 3.4701 25 4.05V7.95C25 8.5299 24.5299 9 23.95 9H20.05C19.4701 9 19 8.5299 19 7.95V4.05C19 3.4701 19.4701 3 20.05 3ZM20.05 4C20.0224 4 20 4.02239 20 4.05V7.95C20 7.97761 20.0224 8 20.05 8H23.95C23.9776 8 24 7.97761 24 7.95V4.05C24 4.02239 23.9776 4 23.95 4H20.05Z"
        fill="#7070A9"
      />
      <path
        d="M6 7.5C6 7.22386 6.22386 7 6.5 7H17.5C17.7761 7 18 7.22386 18 7.5V18.5C18 18.7761 17.7761 19 17.5 19H6.5C6.22386 19 6 18.7761 6 18.5V7.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 8V18H17V8H7ZM6.5 7C6.22386 7 6 7.22386 6 7.5V18.5C6 18.7761 6.22386 19 6.5 19H17.5C17.7761 19 18 18.7761 18 18.5V7.5C18 7.22386 17.7761 7 17.5 7H6.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
