import { Color, Desk, Divider, Icon, Text, colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

import {
  selectDraftDate,
  selectFeedbackDate,
  selectFinalDate,
} from "@/features/timeline";
import { selectSessionLock } from "@/features/work";
import { selectHangingIndent } from "@/graphql/selectors";
import EmptyImg from "assets/submission/empty.png";
import { useAppSelector } from "data/hooks";
import {
  AssessmentFragment,
  SubmissionMetadataFragment,
  SubmissionType,
  UserFragment,
  useRequirementsQuery,
} from "generated/graphql";
import { NotesEditorContent, NotesEditorContentReadOnly } from "ui/editor";
import { Result } from "ui/work/Result";
import { StudentExperience } from "ui/work/StudentExperience";
import { isBeforeDate } from "utils/datetime";

import { MaterialsTab } from "../materials-slice";
import { MaterialsControl } from "./MaterialsControl";
import { Sheet } from "./sheet";
import { DraftSubmissionPane, FinalSubmissionPane } from "./SubmissionPane";

// Props for Materials
interface Props {
  workId: string;
  /** Student owner of the work */
  student: UserFragment;
  /** Assessment for the work */
  assessment: AssessmentFragment;
  /** Metadata on any existing draft submission */
  draft: SubmissionMetadataFragment | null;
  /** Metadata on any existing final submission */
  final: SubmissionMetadataFragment | null;
  /** The editors should be readonly */
  isReadOnly?: boolean;
  /**
   * Toggle between a single pane (false) and split (true) layout.
   * {@see Materials} for more information.
   */
  splitLayout?: boolean;
}

/**
 * Materials consist of all Aphrodite table contents beside the main Student
 * Work.
 *
 * These are the Instruction sheet, the Notes editor, and the Draft and Final
 * submissions. Each corresponding to an enum value of {@see MaterialsTab}.
 *
 * The contents of the Materials are to be laid down under the `Desk.Table`
 * layout component. It uses the `Desk.Left` and `Desk.Right` layout components
 * to render the selected Pane for the current `MaterialsTab`. The
 * `MaterialsTab` value is pulled from the global redux `materials` state.
 *
 * ## Rendered Layouts
 *
 * The rendered layout of the `Desk.Left` and `Desk.Right` components come in two forms:
 *
 *   1. Single pane using a resizable `Desk.Left` (default)
 *   2. Split pane using fixed sized `Desk.Left` and an adjacent `Desk.Right`
 *      (toggled with `splitLayout` prop).
 *
 * In each of the above options there is a primary `primaryPane` React element and
 * a secondary `secondaryPane` element. The contents of these elements are
 * determined by the current materials tab:
 *
 * - `MaterialsTab.Instructions` - `primaryPane` renders the full
 *     Instruction Sheet. The `secondaryPane` is null.
 *
 * - `MaterialsTab.Notes` - `primaryPane` renders the Notes editor. The
 *   `secondaryPane` is null.
 *
 * - `MaterialsTab.Draft` - `primaryPane` renders the Draft submission
 *   editor contents, `secondaryPane` renders the Turnitin `<Result />`
 *   component for that submission.
 *
 * - `MaterialsTab.Final` - primary `secondaryPane` renders the Final submission
 *   editor contents, and the the Student experience survey (on an /unsubmitted/
 *   submission only) above it. The `secondaryPane` renders the Turnitin
 *   `<Result />` component for that submission below it.
 *
 * The `splitLayout` prop will then decide how these primary and secondary panes
 * are laid out.
 *
 * In the default case, the non-split layout or the single pane layout, the
 * panes are rendered in a single resizable `Desk.Left`. Here the secondary
 * contents (if any) are rendered as a header over the primary contents:
 *
 *     <Desk.Left track={..controls to resize the pane..} width={..}>
 *       {secondaryPane || null}
 *       <Divider />
 *       {primaryPane}
 *     </Desk.Left>
 *
 * In the split case, the secondary contents are rendered in the `Desk.Right`
 * layout component and nothing is resizable:
 *
 *     <Desk.Left width="inverseGolden">
 *       {primaryPane}
 *     </Desk.Left>
 *     <Desk.Right>
 *       {secondaryPane}
 *     </Desk.Right>
 *
 */
export function Materials(props: Props) {
  const { workId, assessment, student, draft, final, splitLayout, isReadOnly } =
    props;

  const sessionLock = useAppSelector(selectSessionLock);

  const { tab, width, open } = useAppSelector((state) => state.materials);
  const requirements = useReleasedRequirements(workId);

  const draftDate = useAppSelector(selectDraftDate);
  const finalDate = useAppSelector(selectFinalDate);
  const feedbackDate = useAppSelector(selectFeedbackDate);

  // Current hangingIndent requirement to pass to the rendered submissions
  const hangingIndent = !!requirements && selectHangingIndent(requirements);

  // Primary pane elements
  let primaryPane = null;
  // Secondary pane elements
  let secondaryPane = null;
  // Optional spectial pane background color for the secondary elements
  let paneBackground: Color | undefined;

  switch (tab) {
    // Instructions
    case MaterialsTab.Instructions:
      primaryPane = <Sheet assessment={assessment} workId={workId} />;
      break;

    // Notes
    case MaterialsTab.Notes:
      if (sessionLock || isReadOnly) {
        primaryPane = <NotesEditorContentReadOnly />;
      } else {
        primaryPane = <NotesEditorContent />;
      }
      break;

    // Draft
    case MaterialsTab.Draft:
      // Only render the SubmissionPane if a submission exists
      if (draft) {
        // Can the draft similarity score be viewed by the student?
        const canViewReport = requirements
          ? requirements.draftSViewReports !== 0
          : false;

        /**
         * This is to cover the scenario where a student has made a draft submission
         * and then the teacher set draft to off.
         * Note that `when` is only used for SubmissionType.Final
         */
        const forcedDraftDate = draftDate ? new Date(draftDate) : new Date();
        const canResubmit = isBeforeDate(forcedDraftDate);

        primaryPane = (
          <DraftSubmissionPane workId={workId} hangingIndent={hangingIndent} />
        );

        secondaryPane = (
          <>
            <Result
              workId={workId}
              email={student.email}
              submissionType={SubmissionType.Draft}
              submittedAt={new Date(draft.submittedAt)}
              canViewReport={canViewReport}
              canResubmit={canResubmit}
              returnDate={forcedDraftDate}
              gradingService={requirements?.gradingService}
            />
            <ResultFooterMessage>
              <Text kind="subtle">
                View your submission below{"   "}
                <Icon iconName="Down" />
              </Text>
            </ResultFooterMessage>
          </>
        );
        paneBackground = "yellow100";
        break;
      }
      break;

    case MaterialsTab.Final:
      // Only render the SubmissionPane if the final submission exists and final
      // submissions are turned on
      if (final && feedbackDate && finalDate) {
        // Can the final similarity score be viewed by the student?
        const canViewReport = requirements
          ? requirements.sViewReports !== 0
          : false;

        // Resubmissions are allowed if the final submission is in editing state,
        // AND the current server time is before the assessment due date or any
        // extended due date
        const canResubmit =
          final.unsubmittedAt !== null && isBeforeDate(new Date(finalDate));

        // Show the student experience survey for final submissions in submitted
        // state
        const showExperience = final.unsubmittedAt === null;

        primaryPane = (
          <>
            {showExperience && <StudentExperience />}
            <FinalSubmissionPane
              workId={workId}
              hangingIndent={hangingIndent}
            />
          </>
        );
        secondaryPane = (
          <>
            <Result
              workId={workId}
              email={student.email}
              submissionType={SubmissionType.Final}
              submittedAt={new Date(final.submittedAt)}
              canViewReport={canViewReport}
              canResubmit={canResubmit}
              returnDate={new Date(feedbackDate)}
              gradingService={requirements?.gradingService}
            />
            {!splitLayout && (
              <ResultFooterMessage>
                <Text kind="subtle">
                  View your submission below{"   "}
                  <Icon iconName="Down" />
                </Text>
              </ResultFooterMessage>
            )}
          </>
        );
        paneBackground = "red100";
        break;
      }
      break;
  }

  if (splitLayout) {
    return (
      <>
        <Desk.Left width="inverseGolden">{primaryPane}</Desk.Left>
        <Desk.Right background={paneBackground ? paneBackground : "wine100"}>
          {secondaryPane || <EmptyPane />}
        </Desk.Right>
      </>
    );
  }

  return (
    <DeskLeftWithOpacity
      track={<MaterialsControl />}
      width={open ? width : "closed"}
      lowerOpacity={sessionLock}
    >
      {secondaryPane && (
        <>
          <SinglePaneFullHeightContainer background={paneBackground}>
            {secondaryPane}
          </SinglePaneFullHeightContainer>
          <Divider />
        </>
      )}
      <PaneRoot open={open}>{primaryPane}</PaneRoot>
    </DeskLeftWithOpacity>
  );
}

// Requirements cache query and requirement field selections
function useReleasedRequirements(workId: string) {
  const result = useRequirementsQuery({ variables: { workId } });
  return result?.data?.work.sheet ?? null;
}

const DeskLeftWithOpacity = styled(Desk.Left)<{ lowerOpacity?: boolean }>`
  opacity: ${(p) => (p.lowerOpacity ? 0.54 : 1)};
  /* Floating menu bar in the editor needs to be visible on small panes */
  overflow: visible;
`;

// Full-height countainer that calculates 100% height from parent Desk.Table
const SinglePaneFullHeightContainer = styled.div<{
  background?: Color;
}>`
  position: relative;
  background: ${(p) => p.background && colors[p.background]};
  min-height: 90%; /* Show a little bit of the contents below */
`;

const EmptyPane = () => (
  <Container>
    <Img src={EmptyImg} />
  </Container>
);

const Container = styled.div`
  min-height: 100%;

  width: 100%;
  min-width: 400px;
  max-width: 800px;
  padding: 72px 45px;
  margin: auto;
  box-sizing: border-box;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  width: 100%;
`;

const ResultFooterMessage = styled.div`
  position: absolute;
  bottom: 9px;
  width: 100%;
  text-align: center;
`;

const PaneRoot = styled.div<{ open: boolean }>`
  visibility: ${(p) => (p.open ? "visible" : "hidden")};
  height: 100%;
`;
