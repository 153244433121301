import styled from "styled-components";

import { MathView } from "../MathView";

export const InlineMathView = styled(MathView)`
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;

  --selection-background-color: transparent;
  cursor: pointer;
  &:hover,
  &.ProseMirror-selectednode {
    background: rgba(244, 245, 248, 0.5);
  }

  /* mathlive is not responsiveness, so we need to restrict the width */
  max-width: 710px;
  overflow: hidden;
`;
