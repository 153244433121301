import { Node, mergeAttributes } from "@tiptap/core";

/**
 * Figure element that wraps blockMath
 */
export const MathFigure = Node.create({
  name: "mathFigure",
  content: "blockMath",
  group: "mathBlock",
  mathRole: "figure",
  defining: true,
  addOptions() {
    return {
      HTMLAttributes: {
        "data-math-group": true,
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "figure",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "figure",
        getAttrs(node) {
          if (typeof node === "string") return false;
          const dom = node as HTMLElement;
          if (dom.getAttribute("data-math-group") === "true") return {};
          return false;
        },
      },
    ];
  },
});
