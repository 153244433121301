import { isList, Editor } from "@tiptap/core";
import { NodeRange, Node as PMNode } from "@tiptap/pm/model";
import { Selection } from "@tiptap/pm/state";

/**
 * Returns a range that include all selected list items.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function calculateItemRange(
  editor: Editor,
  selection: Selection
): NodeRange | null | undefined {
  const { $from, $to } = selection;
  const isListNode = (node: PMNode) =>
    isList(node.type.name, editor.extensionManager.extensions);
  return $from.blockRange($to, isListNode);
}
