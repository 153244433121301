import { findChildren } from "@tiptap/core";
import { EditorState, Selection } from "@tiptap/pm/state";
import { Rect, TableMap } from "@tiptap/pm/tables";

import { findTable, findTableGroup } from "./find";
import { getTableNodeTypes } from "./getTableNodeTypes";
import { isSelectionType } from "./isSelectionType";

/** Checks if a given CellSelection `rect` is selected */
export const isRectSelected =
  (rect: Rect) =>
  (selection: Selection): boolean => {
    if (!isSelectionType(selection, "cell")) {
      return false;
    }

    const map = TableMap.get(selection.$anchorCell.node(-1));
    const start = selection.$anchorCell.start(-1);
    const cells = map.cellsInRect(rect);
    const selectedCells = map.cellsInRect(
      map.rectBetween(
        selection.$anchorCell.pos - start,
        selection.$headCell.pos - start
      )
    );

    for (let i = 0, count = cells.length; i < count; i++) {
      if (selectedCells.indexOf(cells[i]) === -1) {
        return false;
      }
    }

    return true;
  };

/** Checks if entire column at index `columnIndex` is selected. */
export const isColumnSelected =
  (columnIndex: number) =>
  (selection: Selection): boolean => {
    if (isSelectionType(selection, "cell")) {
      const map = TableMap.get(selection.$anchorCell.node(-1));
      return isRectSelected({
        left: columnIndex,
        right: columnIndex + 1,
        top: 0,
        bottom: map.height,
      })(selection);
    }

    return false;
  };

/** Checks if entire row at index `rowIndex` is selected. */
export const isRowSelected =
  (rowIndex: number) =>
  (selection: Selection): boolean => {
    if (isSelectionType(selection, "cell")) {
      const map = TableMap.get(selection.$anchorCell.node(-1));
      return isRectSelected({
        left: 0,
        right: map.width,
        top: rowIndex,
        bottom: rowIndex + 1,
      })(selection);
    }

    return false;
  };

/** Checks if entire table is selected */
export const isTableSelected = (selection: Selection): boolean => {
  if (isSelectionType(selection, "cell")) {
    const map = TableMap.get(selection.$anchorCell.node(-1));
    return isRectSelected({
      left: 0,
      right: map.width,
      top: 0,
      bottom: map.height,
    })(selection);
  }

  return false;
};

/** Check if selection inside table group and select the whole table */
export const isSelectionIncludeTable = (state: EditorState): boolean => {
  if (isTableSelected(state.selection)) return true;
  const tableGroup = findTableGroup(state.selection);
  if (!tableGroup?.node) return false;
  const tableType = getTableNodeTypes(state.schema).table;
  const table = findChildren(
    tableGroup.node,
    (node) => node.type === tableType
  )[0];
  if (!table?.node) return false;
  const { from, to } = state.selection;
  const tableStart = tableGroup.start + table.pos;
  const tableEnd = tableStart + table.node.nodeSize;

  return from <= tableStart && to >= tableEnd;
};

/** Check if selection is inside table wrapper node */
export const isSelectionInsideTableGroup = (selection: Selection): boolean => {
  if (isSelectionType(selection, "cell")) {
    return true;
  } else if (
    isSelectionType(selection, "text") ||
    isSelectionType(selection, "node")
  ) {
    const tableGroup = findTableGroup(selection);
    return !!tableGroup;
  }
  return false;
};

/** Check if selection is inside table node */
export const isSelectionInsideTable = (selection: Selection): boolean => {
  if (isSelectionType(selection, "cell")) {
    return true;
  } else if (
    isSelectionType(selection, "text") ||
    isSelectionType(selection, "node")
  ) {
    const table = findTable(selection);
    return !!table;
  }
  return false;
};
