import { useId } from "react";
import {
  Checkbox,
  ErrorMessage,
  LinkButton,
  Text,
  colors,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

const SUBMISSION_DECLARATION_URL =
  "https://support.cadmus.io/students/submission-declaration";

export interface AcademicIntegrityAgreementProps {
  /**
   * Whether the student has agreed to the pledge.
   */
  isChecked?: boolean;
  /**
   * The assessment is an exam.
   */
  isExam?: boolean;
  /**
   * Show an error if the pledge is not checked.
   */
  showError?: boolean;
  /**
   * Show a header.
   */
  withHeader?: boolean;
  /**
   * Use the short version of the pledge.
   */
  withShortenedPledge?: boolean;
  /**
   * Callback to update the checked value of the agreement.
   */
  onUpdateAgreement: (hasAgreed: boolean) => void;
}

/**
 * Allow students to access and agree, or disagree, to the academic integrity
 * pledge.
 */
export const AcademicIntegrityAgreement = ({
  withHeader,
  withShortenedPledge,
  isChecked,
  isExam,
  showError,
  onUpdateAgreement,
}: AcademicIntegrityAgreementProps) => {
  const pledgeId = useId(); // for a11y
  return (
    <Container data-testid="academic-integrity-agreement">
      <PledgeContainer
        data-testid="acadmic-integrity-agreement"
        showError={showError}
        role="checkbox"
        tabIndex={0}
        onClick={() => {
          onUpdateAgreement(!isChecked);
        }}
      >
        {withHeader && (
          <Text kind="label" textAlign="center">
            Academic integrity agreement
          </Text>
        )}
        <BodyContainer>
          <CheckboxWithNoMargin
            aria-labelledby={pledgeId}
            checked={isChecked}
            onChange={(e) => {
              onUpdateAgreement(e.target.checked);
            }}
          />
          <Text
            id={pledgeId}
            kind="system"
            style={{ marginTop: "-2px", userSelect: "none" }}
          >
            {withShortenedPledge ? (
              <>I declare this work is my own, as per the </>
            ) : (
              <>
                By completing and submitting this{" "}
                {isExam ? "exam" : "assignment"}, I confirm that all work here
                is my own, in adherence to the{" "}
              </>
            )}
            <LinkButton
              inline
              onClick={() => {
                const win = window.open(SUBMISSION_DECLARATION_URL, "_blank");
                if (win) {
                  win.focus();
                }
              }}
            >
              submission declaration
            </LinkButton>
          </Text>
        </BodyContainer>
      </PledgeContainer>
      {showError && (
        <ErrorMessage fullWidth position="relative">
          <Text kind="subtle" color="inherit" style={{ marginTop: -2 }}>
            You must review and accept the submission declaration in order to
            submit
          </Text>
        </ErrorMessage>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const BodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const PledgeContainer = styled.div<
  Pick<AcademicIntegrityAgreementProps, "showError">
>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  background-color: ${colors.grey50};
  padding: 24px;
  border: 1px solid transparent;
  border-color: ${(p) => (p.showError ? colors.red500 : "transparent")};
  border-radius: 3px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
`;

const CheckboxWithNoMargin = styled(Checkbox)`
  & > span {
    margin: 0;
  }
`;
