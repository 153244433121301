import { FileType } from "./shared";

/**
 * Categorises a file given its name by using the file's extension.
 * @param filename
 */
export function getFileType(filename: string): FileType {
  if (!filename.includes(".")) {
    return "unknown";
  }
  const splitName = filename.split(".");
  const extension = splitName[splitName.length - 1].toLowerCase();
  if (!extension) {
    return "unknown";
  }

  const fileType: FileType | undefined = extensionToFileTypeMap[extension];
  if (!fileType) {
    return "unknown";
  }
  return fileType;
}

const fileTypeToSupportedExtensionsMap: Record<FileType, string[]> = {
  pdf: ["pdf"],
  document: ["doc", "docx", "pages"],
  spreadsheet: ["xls", "xlsx", "numbers", "csv"],
  presentation: ["ppt", "pptx", "pps", "keynote"],
  image: ["jpg", "jpeg", "gif", "raw", "png", "svg"],
  media: [
    "mp4",
    "mpg",
    "mpeg",
    "mov",
    "m4v",
    "asf",
    "avi",
    "ra",
    "ram",
    "rm",
    "swf",
  ],
  audio: ["mp3", "wav", "m4a"],
  text: ["rtf", "txt", "wpd", "xml"],
  webpage: ["html", "htm", "mht", "mhtml"],
  archive: ["zip", "rar", "7zip"],
  unknown: [],
};

const extensionToFileTypeMap: Record<string, FileType> = flipArrayRecord(
  fileTypeToSupportedExtensionsMap
);

/**
 * Turns { "key": [10, 20, 40] } to { "10": "key", "20": "key", "40": "key" }
 * @returns the flipped record
 */
export function flipArrayRecord(
  record: Record<FileType, string[]>
): Record<string, FileType> {
  const flippedEntries = Object.entries(record).reduce(
    (newObject, [key, array]) => {
      array.forEach((entry) => {
        newObject[entry] = key as FileType;
      });
      return newObject;
    },
    {} as Record<string, FileType>
  );
  return flippedEntries;
}
