import { Command, Editor, findParentNode, isList } from "@tiptap/core";
import { NodeType } from "@tiptap/pm/model";
import { deepChangeListType } from "./deepChangeListType";

/**
 * Toggles a list.
 *
 * @remarks
 *
 * When the provided list wrapper is inactive (e.g. ul) then wrap the list with
 * this type. When it is active then remove the selected line from the list.
 *
 * @param listType - the list node type
 * @param itemType - the list item node type
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 **/
export function toggleList(
  editor: Editor,
  listType: NodeType,
  itemType: NodeType
): Command {
  return (props) => {
    const { tr, dispatch, commands } = props;
    const { selection } = tr;
    const { $from, $to } = selection;
    const range = $from.blockRange($to);
    if (!range) return false;

    const parentList = findParentNode((node) =>
      isList(node.type.name, editor.extensionManager.extensions)
    )(selection);

    if (
      // the selection range is right inside the list
      parentList &&
      range.depth - parentList.depth <= 1 &&
      // the selectron range is the first child of the list
      range.startIndex === 0
    ) {
      if (parentList.node.type === listType) {
        return commands.liftListItemOutOfList(itemType);
      }

      if (
        isList(parentList.node.type.name, editor.extensionManager.extensions)
      ) {
        if (listType.validContent(parentList.node.content)) {
          dispatch?.(tr.setNodeMarkup(parentList.pos, listType));
          return true;
        }

        // When you try to toggle a bullet list into a task list or vice versa, since these two lists
        // use different type of list items, you can't directly change the list type.
        if (deepChangeListType(tr, parentList, listType, itemType)) {
          dispatch?.(tr.scrollIntoView());
          return true;
        }

        return false;
      }
    }

    commands.wrapInList(listType);
    return true;
  };
}
