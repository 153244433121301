import { Fragment, Node as PMNode, Slice } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";

/**
 * Go through each node inside fragment and apply paste node transformation
 * (Adding mark or node) if the parent node allow the transformed node as its
 * content
 */
export default function NodePasteRule(
  regexp: RegExp,
  transformFn: (n: PMNode, match: RegExpMatchArray) => PMNode | null
): Plugin {
  const handler = (fragment: Fragment) => {
    const nodes: any[] = [];
    fragment.forEach((child) => {
      if (child.isText && child.text) {
        const matches = Array.from(child.text.matchAll(regexp));

        const pos = matches.reduce(
          (pos, match) => {
            const start = match.index ?? 0;
            const end = start + match[0].length;

            // simply copy across the text from before the match
            if (start > 0) nodes.push(child.cut(pos, start));

            const transformedNode = transformFn(child.cut(start, end), match);
            nodes.push(transformedNode ?? child.cut(start, end));

            return end;
          },
          // keep track of the end of a match
          0
        );
        // adding rest of text to nodes
        if (pos < child.text.length) {
          nodes.push(child.cut(pos));
        }
      } else {
        const transformedNode = handler(child.content);
        // Check if node can have transformed fragment, if it can then
        // use the transforme fragment otherwise don't do anything with it
        if (child.type.validContent(Fragment.from(transformedNode))) {
          nodes.push(child.copy(transformedNode));
        } else {
          nodes.push(child);
        }
      }
    });

    return Fragment.fromArray(nodes);
  };

  return new Plugin({
    key: new PluginKey("nodePasteRule"),
    props: {
      transformPasted: (slice) => {
        return new Slice(
          handler(slice.content),
          slice.openStart,
          slice.openEnd
        );
      },
    },
  });
}
