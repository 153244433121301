import { Icon } from "@vericus/cadmus-icons";
import styled from "styled-components";

import { Color, colors } from "../styles";
import { FileIconName, FileType } from "./shared";

export type Props = {
  fileType: FileType;
};

export const IconWithBackground = ({ fileType }: Props) => {
  const backgroundColor = colors[fileTypeToBackgroundColorMap[fileType]];
  const borderColor = colors[fileTypeToBorderColorMap[fileType]];
  const iconName = fileTypeToIconMap[fileType];
  return (
    <Container backgroundColor={backgroundColor} borderColor={borderColor}>
      <Icon iconName={iconName} />
    </Container>
  );
};

type ContainerProps = {
  backgroundColor: string;
  borderColor: string;
};
const Container = styled.div<ContainerProps>`
  width: 36px;
  height: 36px;
  border: 1px solid ${(p) => p.borderColor};
  border-radius: 4px;
  background-color: ${(p) => p.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const fileTypeToBackgroundColorMap: Record<FileType, Color> = {
  archive: "white100",
  audio: "indigo200",
  document: "blue200",
  image: "white100",
  media: "indigo200",
  pdf: "orange200",
  presentation: "orange200",
  spreadsheet: "green200",
  text: "white100",
  unknown: "grey100",
  webpage: "white100",
};

const fileTypeToBorderColorMap: Record<FileType, Color> = {
  archive: "grey100",
  audio: "grey100",
  document: "grey100",
  image: "grey200",
  media: "grey100",
  pdf: "grey100",
  presentation: "grey100",
  spreadsheet: "grey100",
  text: "grey100",
  unknown: "grey100",
  webpage: "grey100",
};

const fileTypeToIconMap: Record<FileType, FileIconName> = {
  archive: "ArchiveFile",
  audio: "AudioFile",
  document: "DocumentFile",
  image: "ImageFile",
  media: "MediaFile",
  pdf: "PdfFile",
  presentation: "PresentationFile",
  spreadsheet: "SpreadsheetFile",
  text: "TextFile",
  unknown: "UnknownFile",
  webpage: "WebpageFile",
};
