import {
  OpenBracketFractionIcon,
  Vector1X2Icon,
  Vector1X3Icon,
  Vector2X1Icon,
  Vector2X2Icon,
  Vector2X3Icon,
  Vector3X1Icon,
  Vector3X3Icon,
  Vector4X1Icon,
  Vector4X4Icon,
  Vector5X5Icon,
  VectorComma1X2Icon,
  VectorComma1X3Icon,
} from "../icons";
import { MathCommand } from "./types";

export const vectorComma1X2: MathCommand = {
  label: "1x2 comma vector",
  renderChildren: () => <VectorComma1X2Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}},{\\placeholder{}}\\end{bmatrix}",
  latexCommand:
    "\\begin{bmatrix}{\\placeholder{}},{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "\\begin{bmatrix}x,y\\end{bmatrix}",
  tooltipLabel: "1x2 vector",
};
export const vectorComma1X3: MathCommand = {
  label: "1x3 comma vector",
  renderChildren: () => <VectorComma1X3Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}},{\\placeholder{}},{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
  tooltipLabel: "1x3 vector",
};
export const vector1X2: MathCommand = {
  label: "1x2 vector",
  renderChildren: () => <Vector1X2Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "\\begin{bmatrix}x&y\\end{bmatrix}",
};
export const vector1X3: MathCommand = {
  label: "1x3 vector",
  renderChildren: () => <Vector1X3Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector2X1: MathCommand = {
  label: "2x1 vector",
  renderChildren: () => <Vector2X1Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}} \\\\ {\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector3X1: MathCommand = {
  label: "3x1 vector",
  renderChildren: () => <Vector3X1Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}} \\\\ {\\placeholder{}} \\\\ {\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector4X1: MathCommand = {
  label: "4x1 vector",
  renderChildren: () => <Vector4X1Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}} \\\\ {\\placeholder{}} \\\\ {\\placeholder{}} \\\\ {\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector2X2: MathCommand = {
  label: "2x2 vector",
  renderChildren: () => <Vector2X2Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "\\begin{bmatrix}x&y\\\\a&b\\end{bmatrix}",
};
export const vector2X3: MathCommand = {
  label: "2x3 vector",
  renderChildren: () => <Vector2X3Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector3X3: MathCommand = {
  label: "3x3 vector",
  renderChildren: () => <Vector3X3Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector4X4: MathCommand = {
  label: "4x4 vector",
  renderChildren: () => <Vector4X4Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const vector5X5: MathCommand = {
  label: "5x5 vector",
  renderChildren: () => <Vector5X5Icon />,
  mathLiveCommand:
    "\\begin{bmatrix}{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}} \\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\\\ {\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}&{\\placeholder{}}\\end{bmatrix}",
  tooltipCommand: "",
};
export const openBracketFraction: MathCommand = {
  label: "open bracket fraction",
  renderChildren: () => <OpenBracketFractionIcon />,
  mathLiveCommand: "\\lbrace\\frac{\\placeholder{}}{\\placeholder{}}",
  tooltip: false,
};
export const horizontalDots: MathCommand = {
  label: "horizontal dots",
  mathLiveCommand: "\\ldots",
  text: "…",
  kind: "script",
};
export const verticalDots: MathCommand = {
  label: "vertical dots",
  mathLiveCommand: "\\vdots",
  text: "⋮",
};
export const diagonalDots: MathCommand = {
  label: "diagonal dots",
  mathLiveCommand: "\\ddots",
  text: "⋱",
};
