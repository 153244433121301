import { Extension } from "@tiptap/core";
import "@tiptap/extension-text-style";
import tinycolor from "tinycolor2";
import { TEXT_COLORS, DEFAULT_TEXT_COLOR } from "@vericus/cadmus-ui";

type TextColorOptions = {
  types: string[];
  defaultTextColor: string;
  colors: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    textColor: {
      /**
       * Set the text color
       */
      setTextColor: (color: string) => ReturnType;
      /**
       * Unset the text color
       */
      unsetTextColor: () => ReturnType;
    };
  }
}

export const TextColor = Extension.create<TextColorOptions>({
  name: "textColor",

  addOptions() {
    return {
      types: ["textStyle"],
      defaultTextColor: DEFAULT_TEXT_COLOR,
      colors: TEXT_COLORS,
    };
  },

  addGlobalAttributes() {
    const colors = this.options.colors.map((c) => tinycolor(c).toHexString());
    const defaultTextColor = tinycolor(
      this.options.defaultTextColor
    ).toHexString();
    colors.push(defaultTextColor);

    const getColor = (color?: string) => {
      return color && colors.includes(tinycolor(color).toHexString())
        ? color
        : defaultTextColor;
    };
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }
              return {
                style: `color: ${getColor(attributes.color)}`,
              };
            },
            parseHTML: (element) => getColor(element.style.color),
          },
        },
      },
    ];
  },

  addCommands() {
    const colors = this.options.colors.map((c) => tinycolor(c).toHexString());
    const defaultTextColor = tinycolor(
      this.options.defaultTextColor
    ).toHexString();
    return {
      setTextColor:
        (color: string) =>
        ({ commands, chain }) => {
          const hexColor = tinycolor(color).toHexString();
          if (colors.includes(hexColor)) {
            return commands.setMark("textStyle", { color: hexColor });
          } else if (hexColor === defaultTextColor) {
            return chain()
              .setMark("textStyle", { color: null })
              .removeEmptyTextStyle()
              .run();
          }
          return false;
        },
      unsetTextColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { color: null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
