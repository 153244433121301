import { Extension } from "@tiptap/core";
import crel from "crelt";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export const EditorPlaceholderPluginKey = new PluginKey("editorPlaceholder");

interface EditorPlaceholderOptions {
  /** Plain text placeholder for the first heading. */
  titlePlaceholder?: string;
  /** Plain text placeholder in the content area. */
  contentPlaceholder?: string;
  /** Rich custom referencing help placeholder in the content area. */
  referencesPlaceholder?: boolean;
}

/**
 * Extension to create simple and rich placeholders inside empty nodes.
 *
 * The placeholders can be a simple textual Title and Content placeholder text
 * combination or the custom Cadmus Referencing placeholder which displays a
 * button to open Cadmus Manual.
 */
export const EditorPlaceholder = Extension.create<EditorPlaceholderOptions>({
  name: "editorPlaceholder",

  addProseMirrorPlugins() {
    const openManualPreset = (preset: string) => {
      this.editor.commands.setMeta("openManualPreset", preset);
    };

    return [
      new Plugin<DecorationSet>({
        key: EditorPlaceholderPluginKey,
        state: {
          init: (_, editorState) => {
            return createPlaceholders(
              editorState,
              this.options,
              openManualPreset
            );
          },
          apply: (_tr, _pluginState, _oldState, newState) => {
            return createPlaceholders(newState, this.options, openManualPreset);
          },
        },
        props: {
          decorations: (state) =>
            this.editor.isEditable
              ? EditorPlaceholderPluginKey.getState(state)
              : undefined,
        },
      }),
    ];
  },
});

/**
 * check if placeholder needs to be displayed in the first two blocks
 * of the document
 */
function createPlaceholders(
  editorState: EditorState,
  options: EditorPlaceholderOptions,
  openManualPreset: (preset: string) => void
): DecorationSet {
  let state = DecorationSet.empty;
  const { doc } = editorState;
  const { childCount } = doc;

  // Skip if placeholders are not needed
  if (childCount > 2) return state;

  let shouldDisplaySecondPlaceholder = true;
  const firstNode = doc.child(0);
  const secondNode = doc.maybeChild(1);

  if (
    firstNode.isBlock &&
    firstNode.textContent === "" &&
    firstNode.childCount === 0
  ) {
    // Add Title placeholder
    if (options.titlePlaceholder && firstNode.type.name === "heading") {
      state = state.add(doc, [
        TextPlaceholderWidget(1, options.titlePlaceholder),
      ]);
    } else if (
      options.contentPlaceholder &&
      firstNode.type.name === "paragraph"
    ) {
      shouldDisplaySecondPlaceholder = false;
      state = state.add(doc, [
        TextPlaceholderWidget(1, options.contentPlaceholder),
      ]);
    } else if (
      options.referencesPlaceholder &&
      firstNode.type.name === "paragraph"
    ) {
      shouldDisplaySecondPlaceholder = false;
      state = state.add(doc, [
        ReferencesPlaceholderWidget(1, openManualPreset),
      ]);
    }
  }

  // don't display second block placeholder if first block is not heading
  if (firstNode.isBlock && firstNode.type.name !== "heading") {
    shouldDisplaySecondPlaceholder = false;
  }

  if (
    secondNode?.isBlock &&
    secondNode.textContent === "" &&
    secondNode.childCount === 0 &&
    shouldDisplaySecondPlaceholder &&
    secondNode.type.name === "paragraph"
  ) {
    if (options.referencesPlaceholder) {
      state = state.add(doc, [
        ReferencesPlaceholderWidget(firstNode.nodeSize + 1, openManualPreset),
      ]);
    } else if (options.contentPlaceholder) {
      state = state.add(doc, [
        TextPlaceholderWidget(
          firstNode.nodeSize + 1,
          options.contentPlaceholder
        ),
      ]);
    }
  }

  return state;
}

// Plain text placeholder widget
const TextPlaceholderWidget = (pos: number, text: string): Decoration => {
  const content = crel("span", { class: "inner-placeholder" }, text);
  const wrapper = crel("span", { class: "outer-placeholder" }, content);
  return Decoration.widget(pos, () => wrapper);
};

// Custom Cadmus referencing style help placeholder widget
const ReferencesPlaceholderWidget = (
  pos: number,
  openManualPreset?: (preset: string) => void
): Decoration => {
  const onClick = () => {
    openManualPreset?.("referencing_style");
  };

  const button = crel(
    "button",
    { class: "cui-LinkButton", onclick: onClick },
    crel("span", "some common examples")
  );

  const content = crel(
    "span",
    { class: "inner-placeholder" },
    "Add a Reference. Check out ",
    button
  );

  const wrapper = crel("span", { class: "outer-placeholder" }, content);

  return Decoration.widget(pos, () => wrapper, {
    stopEvent: (e) => {
      if (e.target && button.contains(e.target as Element)) {
        e.preventDefault();
      }
      return true;
    },
  });
};
