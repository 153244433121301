import { Colored, Text } from "@vericus/cadmus-ui";

import { formatDateSimple } from "@/utils/datetime";

import draftImg from "../../assets/submission-draft.png";
import { Illustration } from "./styles";

interface Props {
  /** User email if available */
  email: string | null;

  /** Submission time */
  submittedAt: Date;

  /** Submission where submission time is after due date */
  late: boolean;
}

export function DraftConfirmation(props: Props) {
  const { submittedAt, late, email } = props;
  const submissionText = formatDateSimple(submittedAt);

  return (
    <>
      <Illustration
        src={draftImg}
        alt="An illustration of a girl sitting on a bean bag with a laptop on her lap."
      />
      <Text kind="displayTwo" textAlign="center">
        You successfully <br /> submitted a draft
      </Text>
      <Text kind="paragraph" textAlign="center">
        You submitted a draft on{" "}
        <strong>
          {submissionText}
          {late ? " (late)" : ""}
        </strong>
        . We emailed confirmation of your submission to:{" "}
        <Colored color="shade1">
          <em>{email}</em>
        </Colored>
      </Text>
    </>
  );
}
