import { Editor } from "@tiptap/core";
import { WordCountState, WordCountPluginKey } from "./word-count-plugin";

/**
 * Read the latest tracked total and selected word count in the `editor`.
 *
 * @param editor which contains the `word-count` extension.
 * @return the object with `total` and `selected` word counts, or `null` if the
 *   plugin is not initialised or ready yet.
 */
export function editorWordCount(editor: Editor): WordCountState | null {
  return WordCountPluginKey.getState(editor.state) ?? null;
}
