import { Extension, findParentNode } from "@tiptap/core";
import { NodeType } from "@tiptap/pm/model";
import { Plugin } from "@tiptap/pm/state";

import {
  indentList,
  liftListItemOutOfList,
  listBackspace,
  maybeJoinList,
  outdentList,
  splitListItem,
  toggleList,
} from "./commands";
import { isListItem } from "./helpers";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    list: {
      /**
       * split list item. renamed with `cadmus` prefix
       * as tiptap have splitListItem as a core command
       */
      cadmusSplitListItem: (
        listItemTypeOrName: string | NodeType
      ) => ReturnType;
      /**
       * indent selected list item(s) to move it to a sub list
       */
      indentList: () => ReturnType;
      /**
       * outdent selected list item(s) to move it to the parent of the list
       */
      outdentList: () => ReturnType;
      /**
       * toggle List. renamed with `cadmus` infix
       * as tiptap have toggleList as a core command
       */
      toggleCadmusList: (
        listType: NodeType,
        listItemType: NodeType
      ) => ReturnType;
      /**
       * lift the content inside a list item around the selection
       * out of list
       */
      liftListItemOutOfList: (listItemType: NodeType) => ReturnType;
      /**
       * command to handle backspace keymap inside list
       * it does list wrapping, joining previous list or lifting list item
       * to parent list
       */
      listBackspace: () => ReturnType;
    };
  }
}

export const List = Extension.create({
  name: "list",

  addCommands() {
    return {
      cadmusSplitListItem: (listItemTypeOrName: string | NodeType) =>
        splitListItem(listItemTypeOrName),
      indentList:
        () =>
        ({ tr, dispatch }) => {
          if (indentList(this.editor, tr)) {
            dispatch?.(tr.scrollIntoView());
            return true;
          }
          return false;
        },
      outdentList:
        () =>
        ({ tr, dispatch, commands }) => {
          if (outdentList(this.editor, tr)) {
            dispatch?.(tr.scrollIntoView());
            return true;
          } else {
            const parentListItem = findParentNode((node) =>
              isListItem(
                node.type.name,
                this.editor.extensionManager.extensions
              )
            )(tr.selection);
            if (parentListItem) {
              commands.liftListItemOutOfList(parentListItem.node.type);
              return true;
            }
          }
          return false;
        },
      toggleCadmusList: (listType: NodeType, listItemType: NodeType) =>
        toggleList(this.editor, listType, listItemType),
      liftListItemOutOfList: (listItemType) =>
        liftListItemOutOfList(listItemType),
      listBackspace: () => listBackspace(this.editor),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.indentList(),
      "Shift-Tab": () => this.editor.commands.outdentList(),
      Backspace: () => this.editor.commands.listBackspace(),
      "Mod-Backspace": () => this.editor.commands.listBackspace(),
      "ctrl-h": () => this.editor.commands.listBackspace(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        appendTransaction: (_transaction, _oldState, newState) => {
          const { tr } = newState;
          const updated = maybeJoinList(this.editor, tr);
          return updated ? tr : null;
        },
      }),
    ];
  },
});
