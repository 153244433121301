import {
  Colored,
  LinkButton,
  SectionLabel,
  Spacer,
  Text,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

import { AssessmentType, ExamTiming } from "@/generated/graphql";
import { formatDate, formatDateFull } from "utils/datetime";

interface Props {
  /**
   * Assessment can be either an Assignment or an Exam.
   */
  assessmentType: AssessmentType;
  /**
   * Callback to open manual preset articles.
   * @default undefined
   */
  onOpenManualPreset?: (preset: string) => void;
  /**
   * Exam open date. @default undefined
   */
  examOpenDate?: string;
  /**
   * Exam close date. @default undefined
   */
  examCloseDate?: string;
  /**
   * Timed assignment or exam end writing date. @default undefined
   */
  writingDate?: string;
  /**
   * Draft submission due date. @default undefined
   */
  draftDate?: string;
  /**
   * Final submission due date. @default undefined
   */
  finalDate?: string;
  /**
   * Number of minutes allotted for reading. @default undefined
   */
  readingTime?: number;
  /**
   * Number of minutes allotted for writing. @default undefined
   */
  writingTime?: number;
  /**
   * Whether the exam is a windowed or live exam. @default LIVE.
   */
  examTiming?: ExamTiming;
  /**
   * Whether the final due date is an extension. @default false
   */
  finalExtended?: boolean;
  /**
   * Whether the student has a time limit extension. @default false
   */
  timeLimitExtended?: boolean;
  /**
   * Preferred referencing style. @default undefined
   */
  referencingStyle?: string;
  /**
   * Preferred word limit. @default undefined
   */
  wordLimit?: number;
  /**
   * Whether the references will be counted in word count. @default undefined
   */
  countReferences?: boolean;
  /**
   * Assignment weighting. @default undefined
   */
  weight?: number;
  /**
   * Are auto-submissions enabled in the exam? @default false
   */
  autoSubmission?: boolean;
}

/**
 * React component rendering the latest requirements within the Instruction
 * Sheet pane.
 */
export function Requirements(props: Props) {
  const {
    assessmentType,
    onOpenManualPreset,
    examOpenDate,
    examCloseDate,
    writingDate,
    draftDate,
    finalDate,
    readingTime,
    writingTime,
    examTiming,
    finalExtended,
    timeLimitExtended,
    wordLimit,
    autoSubmission,
    countReferences,
    referencingStyle,
    weight,
  } = props;

  const isExam = assessmentType === AssessmentType.Exam;
  const isLiveExam = isExam && examTiming === ExamTiming.Live;
  const isWindowExam = isExam && examTiming === ExamTiming.Window;

  return (
    <Root>
      {isExam ? (
        <SectionLabel title="Exam details" />
      ) : (
        <SectionLabel title="Requirements" />
      )}
      <Spacer spacing={27} />

      {isExam && examOpenDate && (
        <RequirementRow
          title={isLiveExam ? "Exam start" : "Exam open"}
          text={formatDateFull(new Date(examOpenDate))}
        />
      )}

      {!isExam && finalDate && (
        <RequirementRow
          title="Final Due"
          content={
            <DueDateRequirement
              finalDueDate={finalDate}
              timedDueDate={writingDate}
              hasTimeLimitExtension={timeLimitExtended}
              hasDueDateExtension={finalExtended}
            />
          }
        />
      )}

      {!isExam && draftDate && (
        <RequirementRow
          title="Draft Due"
          text={`You may submit a draft before ${formatDate(
            new Date(draftDate)
          )}`}
        />
      )}

      {isExam && readingTime !== undefined && (
        <RequirementRow title="Reading time" text={`${readingTime} minutes`} />
      )}

      {isExam && writingTime !== undefined && (
        <RequirementRow
          title="Writing time"
          text={`${writingTime} minutes ${
            examTiming === ExamTiming.Live ? "in one sitting" : ""
          }`}
        />
      )}

      {isWindowExam && examCloseDate && (
        <RequirementRow
          title="Exam close"
          text={formatDateFull(new Date(examCloseDate))}
        />
      )}

      {isLiveExam && writingDate && (
        <RequirementRow
          title="Exam end"
          text={formatDateFull(new Date(writingDate))}
        />
      )}

      {isExam && autoSubmission && (
        <RequirementRow
          title="Auto submission"
          text="Your work will be auto submitted at the end of the exam"
        />
      )}

      {weight !== undefined && (
        <RequirementRow title="Weighting" text={`${weight}% of the unit`} />
      )}

      {wordLimit !== undefined && (
        <RequirementRow
          title="Word limit"
          text={`${wordLimit} words; ${
            !countReferences ? "not " : ""
          }including references`}
        />
      )}

      {referencingStyle !== undefined && (
        <RequirementRow
          title="Referencing"
          content={
            <>
              You're required to follow{" "}
              <LinkButton
                kind="primary"
                inline
                onClick={() => onOpenManualPreset?.("referencing_overview")}
              >
                {referencingStyle}
              </LinkButton>
            </>
          }
        />
      )}
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 360px;
  margin: auto;
  padding: 0 45px;
  box-sizing: border-box;
`;

interface RequirementRowProps {
  title: string;
  text?: string;
  // In cases where you need more complex children, including LinkButtons, etc.
  content?: React.ReactNode;
}

// Generic display of a single Requirement row.
export function RequirementRow(props: RequirementRowProps) {
  return (
    <RowRoot>
      <Label>
        <Text kind="system">
          <strong>{props.title}</strong>
        </Text>
      </Label>
      <RequirementText kind="system">
        {props.content ? props.content : props.text}
      </RequirementText>
    </RowRoot>
  );
}

const RowRoot = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 6px;
  flex-wrap: wrap;
`;

const Label = styled.div`
  flex: none;
  width: 120px;
`;

const RequirementText = styled(Text)`
  flex-basis: 226px;
  flex-grow: 1;
`;

interface DueDateRequirementProps {
  finalDueDate: string;
  timedDueDate?: string;
  hasTimeLimitExtension?: boolean;
  hasDueDateExtension?: boolean;
}

/**
 * Content for the Due Requirement Row. This updates based on whether a time
 *  limit is set, or if a student has extension dates/times
 */
export function DueDateRequirement(props: DueDateRequirementProps) {
  const {
    finalDueDate,
    timedDueDate,
    hasTimeLimitExtension,
    hasDueDateExtension,
  } = props;

  // Time Limit due date text
  if (timedDueDate) {
    // Check if there is a TIME extension and update accordingly
    const timeSentence = hasTimeLimitExtension ? (
      <>
        You have been given more time.{" "}
        <Colored color="red500">
          Your time limit now ends on{" "}
          <u>{formatDate(new Date(timedDueDate))}</u>
        </Colored>
      </>
    ) : (
      <>
        Your time limit ends on <u>{formatDate(new Date(timedDueDate))}</u>
      </>
    );

    // Check if there is a due date extension and update accordingly
    const dueSentence = hasDueDateExtension ? (
      <>
        . You have been granted an extension on your due date.{" "}
        <Colored color="red500">
          You should complete this assessment before{" "}
          <u>{formatDate(new Date(finalDueDate))}</u>
        </Colored>
      </>
    ) : null;

    // Combining the time and due sentences
    return (
      <>
        {timeSentence}
        {dueSentence}
      </>
    );
  }

  // No time limit, but has extension
  if (hasDueDateExtension)
    return (
      <>
        You have been granted an extension.{" "}
        <Colored color="red500">
          Your final is now due on <u>{formatDate(new Date(finalDueDate))}</u>.
        </Colored>
      </>
    );

  return (
    <>
      Your final is due on <u>{formatDate(new Date(finalDueDate))}</u>.
    </>
  );
}
