import { Table as TiptapTable } from "@tiptap/extension-table";
import { tableEditing } from "@tiptap/pm/tables";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";

import { TableComponent } from "../../components";
import { maxWidth } from "../../constants";
import { TableControlPlugin, columnResizing } from "../../plugins";

/**
 * Table Node extension that extend @tiptap/extension-table
 * With the addition of using Cadmus' own NodeView using react
 * and also Row(s) & Column(s) selection, deletion and insertion control
 */
export const Table = TiptapTable.extend({
  selectable: false,
  group: "tableBlock",
  renderHTML({ node, HTMLAttributes }) {
    const row = node.firstChild;
    let newTotalWidths = 0;
    if (row) {
      const newColWidths = [] as number[];
      for (let i = 0, col = 0; i < row.childCount; i += 1) {
        const { colspan, colwidth } = row.child(i).attrs;
        for (let j = 0; j < colspan; j += 1, col += 1) {
          const colWidth =
            (colwidth && colwidth[j]) ?? this.options.cellMinWidth;
          newTotalWidths += colWidth;
          newColWidths.push(colWidth);
        }
      }
    }
    if (newTotalWidths > maxWidth) newTotalWidths = maxWidth;
    const { style = "" } = HTMLAttributes;
    return [
      "table",
      mergeAttributes(this.options.HTMLAttributes, {
        ...HTMLAttributes,
        style: `${style};width: ${newTotalWidths}px;`,
      }),
      ["tbody", 0],
    ];
  },

  addProseMirrorPlugins() {
    return [
      columnResizing({
        editor: this.editor,
        handleWidth: this.options.handleWidth,
        cellMinWidth: this.options.cellMinWidth,
        lastColumnResizable: this.options.lastColumnResizable,
      }),
      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection,
      }),
      TableControlPlugin({ editor: this.editor }),
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(TableComponent);
  },
});
