import {
  Node as PMNode,
  ResolvedPos,
  NodeRange,
  Slice,
} from "@tiptap/pm/model";

/**
 * Separate selected list item into two slices: `selectedSlice` and `unselectedSlice`.
 *
 * If `unselectedSlice` exists, we don't want to change its indentation.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-command-indent.ts
 */
export function sliceSelectedItems(
  doc: PMNode,
  $to: ResolvedPos,
  range: NodeRange
) {
  let selectedSlice: Slice;
  let unselectedSlice: Slice | null;

  const start = range.start;
  // `range.depth` is the depth of the list node. We +2 here because we want to
  // get the depth of item children (e.g. paragraph).
  const mid =
    $to.depth >= range.depth + 2 ? $to.end(range.depth + 2) : range.end - 1;
  const end = range.end;

  if (mid + 1 >= end) {
    selectedSlice = doc.slice(start, end);
    unselectedSlice = null;
  } else {
    selectedSlice = doc.slice(start, mid);
    unselectedSlice = doc.slice(mid + 1, end - 1);
  }

  return { selectedSlice, unselectedSlice };
}
