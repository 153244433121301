import { IconProps } from "./types";

export const UnderLeftRightArrowIcon = (props: IconProps) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" {...props}>
      <path
        d="M2.765 23.68H27.229C26.4717 23.1147 25.885 22.432 25.469 21.632C25.2023 21.12 25.0263 20.6133 24.941 20.112C24.941 20.0907 24.9357 20.0693 24.925 20.048C24.925 20.0267 24.925 20.0107 24.925 20C24.925 19.8827 25.0317 19.824 25.245 19.824C25.373 19.824 25.453 19.84 25.485 19.872C25.5277 19.8933 25.5597 19.952 25.581 20.048C25.613 20.2613 25.6717 20.48 25.757 20.704C25.9917 21.4187 26.381 22.0587 26.925 22.624C27.469 23.1787 28.1037 23.5787 28.829 23.824C28.925 23.856 28.973 23.9147 28.973 24C28.973 24.0853 28.925 24.144 28.829 24.176C28.1037 24.4213 27.469 24.8267 26.925 25.392C26.381 25.9467 25.9917 26.5813 25.757 27.296C25.6823 27.52 25.6237 27.7333 25.581 27.936C25.5597 28.0427 25.5277 28.1067 25.485 28.128C25.453 28.16 25.373 28.176 25.245 28.176C25.021 28.176 24.909 28.1173 24.909 28C24.909 27.968 24.941 27.8347 25.005 27.6C25.3357 26.2667 26.077 25.1733 27.229 24.32H2.765L2.813 24.368C3.91167 25.2107 4.63167 26.288 4.973 27.6C5.037 27.8347 5.069 27.968 5.069 28C5.069 28.1173 4.957 28.176 4.733 28.176C4.605 28.176 4.51967 28.16 4.477 28.128C4.445 28.1067 4.41833 28.0427 4.397 27.936C4.37567 27.8613 4.35433 27.7707 4.333 27.664C4.141 26.8853 3.773 26.192 3.229 25.584C2.69567 24.976 2.05033 24.5227 1.293 24.224C1.133 24.16 1.037 24.1013 1.005 24.048C1.005 23.9093 1.101 23.8187 1.293 23.776C2.05033 23.4773 2.69567 23.024 3.229 22.416C3.773 21.808 4.141 21.1147 4.333 20.336C4.34367 20.304 4.35433 20.256 4.365 20.192C4.37567 20.128 4.381 20.0853 4.381 20.064C4.39167 20.0427 4.40233 20.0107 4.413 19.968C4.43433 19.9253 4.45567 19.8987 4.477 19.888C4.49833 19.8667 4.53033 19.8507 4.573 19.84C4.61567 19.8293 4.669 19.824 4.733 19.824C4.957 19.824 5.069 19.8827 5.069 20C5.069 20.032 5.037 20.1653 4.973 20.4C4.653 21.6693 3.933 22.7467 2.813 23.632L2.765 23.68Z"
        fill="#3A517F"
      />
      <path
        d="M9 8.49707C9 8.22093 9.22386 7.99707 9.5 7.99707H20.5C20.7761 7.99707 21 8.22093 21 8.49707V19.4971C21 19.7732 20.7761 19.9971 20.5 19.9971H9.5C9.22386 19.9971 9 19.7732 9 19.4971V8.49707Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 8.99707V18.9971H20V8.99707H10ZM9.5 7.99707C9.22386 7.99707 9 8.22093 9 8.49707V19.4971C9 19.7732 9.22386 19.9971 9.5 19.9971H20.5C20.7761 19.9971 21 19.7732 21 19.4971V8.49707C21 8.22093 20.7761 7.99707 20.5 7.99707H9.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
