import { colors } from "@vericus/cadmus-ui";
import styled from "styled-components";

export const Padding = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 450px;
  padding: 45px;
  box-sizing: border-box;
  margin: auto;
`;

export const XPFlex = styled.div`
  width: 100%;
  padding: 36px;
  box-sizing: border-box;
  background: ${colors.yellow100};

  display: flex;
  flex-wrap: wrap-reverse;
`;

export const FlexText = styled.div`
  flex: auto;
  width: 40%;
  padding-right: 27px;
`;

export const FlexImg = styled.img`
  display: block;
  flex-grow: 1;
  flex-basis: 226px;
  max-width: 300px;
  object-fit: contain;
  margin: 0 auto 18px;
`;
