import { Editor, isList } from "@tiptap/core";
import { Transaction } from "@tiptap/pm/state";
import { isListItem } from "../helpers";
import { wrapSelectedItems } from "./wrapSelectedItems";
/**
 * Wraps selected list items to fit the list type and list item type in the
 * previous list.
 * ported from https://github.com/remirror/remirror/blob/main/packages/remirror__extension-list/src/list-commands.ts
 */
export function wrapListBackward(editor: Editor, tr: Transaction): boolean {
  const $cursor = tr.selection.$from;
  const range = $cursor.blockRange();

  if (
    !range ||
    !isListItem(range.parent.type.name, editor.extensionManager.extensions) ||
    range.startIndex !== 0
  ) {
    return false;
  }

  const root = $cursor.node(range.depth - 2); // the node that contains the list
  const itemIndex = $cursor.index(range.depth); // current node is the n-th node in item
  const listIndex = $cursor.index(range.depth - 1); // current item is the n-th item in list
  const rootIndex = $cursor.index(range.depth - 2); // current list is the n-th node in root
  const previousList = root.maybeChild(rootIndex - 1);
  const previousListItem = previousList?.lastChild;

  if (
    // current node must be the first node in its parent list item;
    itemIndex !== 0 ||
    // current list item must be the first list item in its parent list;
    listIndex !== 0
  ) {
    return false;
  }

  if (
    // there is a list before current list;
    previousList &&
    isList(previousList.type.name, editor.extensionManager.extensions) &&
    // we can find the list item type for previousList;
    previousListItem &&
    isListItem(previousListItem.type.name, editor.extensionManager.extensions)
  ) {
    return wrapSelectedItems({
      editor,
      listType: previousList.type,
      itemType: previousListItem.type,
      tr: tr,
    });
  }

  if (isListItem(root.type.name, editor.extensionManager.extensions)) {
    const parentListItem = root;
    const parentList = $cursor.node(range.depth - 3);

    if (isList(parentList.type.name, editor.extensionManager.extensions)) {
      return wrapSelectedItems({
        editor,
        listType: parentList.type,
        itemType: parentListItem.type,
        tr: tr,
      });
    }
  }

  return false;
}
