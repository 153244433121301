import { useCallback, useEffect, useState } from "react";
import { Editor, posToDOMRect } from "@tiptap/core";
import { Icon } from "@vericus/cadmus-icons";
import {
  ControlButton,
  Dropdown,
  Menu,
  MenuDivider,
  MenuItem,
  Switch,
  levels,
  typography,
} from "@vericus/cadmus-ui";
import styled from "styled-components";

import { FloatingMenuComponent, ShouldShow } from "../floating-menu";
import { CodeBlockLanguage, CodeBlockTheme } from "./types";
import { findCodeBlockSelection, getLanguageDisplay } from "./utils";

interface Props {
  /** TipTap Editor instance */
  editor: Editor;
  portalId: string;
}

/**
 * Bubble Menu Component for CodeBlock controls.
 */
export function CodeBlockMenuComponent(props: Props) {
  const { editor, portalId } = props;
  const shouldShow: ShouldShow = useCallback(({ state }) => {
    const { selection } = state;
    return findCodeBlockSelection(selection) !== undefined;
  }, []);

  const getBoundingClientRect = useCallback(() => {
    const { view, state } = editor;
    const { selection } = state;
    const codeBlockNode = findCodeBlockSelection(selection);

    // support for CellSelections
    const { ranges } = selection;
    const from = Math.min(...ranges.map((range) => range.$from.pos));
    const to = Math.max(...ranges.map((range) => range.$to.pos));
    if (codeBlockNode) {
      return posToDOMRect(
        view,
        codeBlockNode.pos,
        codeBlockNode.pos + codeBlockNode.node.nodeSize
      );
    }

    return posToDOMRect(view, from, to);
  }, [editor]);

  // Theme state
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // Language dropdown selection state
  const [isLangugageDropdownOpen, setLanguageDropdownOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(
    CodeBlockLanguage.PlainText
  );

  // More options menu state
  const [optionsDropdownOpen, setOptionsDropdownOpen] = useState(false);
  const [isLineNumbersVisible, setIsLineNumbersVisible] = useState(false);
  const [isLanguageVisible, setIsLanguageVisible] = useState(false);

  // Update component state when a node is selected
  useEffect(() => {
    const sel = findCodeBlockSelection(editor.state.selection);
    if (sel) {
      setIsDarkTheme(sel.node.attrs.theme === CodeBlockTheme.Dark);
      setActiveLanguage(sel.node.attrs.language);
      setIsLanguageVisible(sel.node.attrs.languageVisible);
      setIsLineNumbersVisible(sel.node.attrs.lineNumbersVisible);
    }
  }, [editor.state.selection]);

  // Callback to toggle between themes
  const switchTheme = () =>
    editor.commands.setCodeBlockTheme(
      isDarkTheme ? CodeBlockTheme.Light : CodeBlockTheme.Dark
    );

  // Language selection dropdown menu
  const languageSelectionDropdown = (
    <Menu>
      <MenuItem
        selected={activeLanguage === CodeBlockLanguage.PlainText}
        onClick={() => {
          setLanguageDropdownOpen(!isLangugageDropdownOpen);
          editor.commands.setCodeBlockLanguage(CodeBlockLanguage.PlainText);
        }}
      >
        Plain Text
      </MenuItem>
      <MenuDivider />
      {LANGUAGES.map((lang) => (
        <MenuItem
          key={lang}
          selected={activeLanguage === lang}
          onClick={() => {
            setLanguageDropdownOpen(!isLangugageDropdownOpen);
            editor.commands.setCodeBlockLanguage(lang);
          }}
        >
          {getLanguageDisplay(lang)}
        </MenuItem>
      ))}
    </Menu>
  );

  // More options dropdown menu
  const optionsDropdown = (
    <OptionsMenu>
      <OptionsMenuItem>
        <div>
          <Icon
            iconName="CodeBlockLineNumbers"
            style={{ marginRight: "12px" }}
          />
          Line Numbers
        </div>
        <Switch
          checked={isLineNumbersVisible}
          onChange={() =>
            editor.commands.setLineNumbersVisibility(!isLineNumbersVisible)
          }
        />
      </OptionsMenuItem>
      <OptionsMenuItem>
        <div>
          <Icon
            iconName="CodeBlockLanguageCaption"
            style={{ marginRight: "12px" }}
          />
          Language Caption
        </div>
        <Switch
          checked={isLanguageVisible}
          onChange={() =>
            editor.commands.setActiveLanguageVisibility(!isLanguageVisible)
          }
        />
      </OptionsMenuItem>
    </OptionsMenu>
  );

  return (
    <FloatingMenuComponent
      portalId={portalId}
      editor={editor}
      pluginKey="codeblockFloatingMenu"
      shouldShow={shouldShow}
      getBoundingClientRect={getBoundingClientRect}
      isAsync
    >
      <FloatingMenuDiv>
        <Dropdown
          open={isLangugageDropdownOpen}
          content={languageSelectionDropdown}
          onOuterClick={() => setLanguageDropdownOpen(false)}
        >
          <ControlButton
            onClick={() => {
              setLanguageDropdownOpen(!isLangugageDropdownOpen);
              setOptionsDropdownOpen(false);
            }}
            aria-label={getLanguageDisplay(activeLanguage)}
            withDownCaret
          >
            {getLanguageDisplay(activeLanguage)}
          </ControlButton>
        </Dropdown>
        <FloatingMenuDivider />
        <ControlButton
          aria-label={!isDarkTheme ? "Light Theme" : "Dark Theme"}
          onClick={switchTheme}
          iconName={!isDarkTheme ? "SunLightDark" : "SunDarkLight"}
        />
        <FloatingMenuDivider />
        <ControlButton
          aria-label="Delete Codeblock"
          onMouseEnter={() => editor.commands.preDeleteHighlightCodeBlock(true)}
          onMouseLeave={() =>
            editor.commands.preDeleteHighlightCodeBlock(false)
          }
          onClick={() => editor.commands.deleteCodeBlock()}
          iconName="Trash"
        />
        <FloatingMenuDivider />
        <Dropdown
          open={optionsDropdownOpen}
          content={optionsDropdown}
          onOuterClick={() => setOptionsDropdownOpen(false)}
        >
          <ControlButton
            aria-label="More"
            onClick={() => {
              setLanguageDropdownOpen(false);
              setOptionsDropdownOpen(!optionsDropdownOpen);
            }}
            iconName="More"
          />
        </Dropdown>
      </FloatingMenuDiv>
    </FloatingMenuComponent>
  );
}

// List of supported langauges in order.
const LANGUAGES = [
  CodeBlockLanguage.C,
  CodeBlockLanguage.CPP,
  CodeBlockLanguage.CSharp,
  CodeBlockLanguage.CSS,
  CodeBlockLanguage.Elixir,
  CodeBlockLanguage.Go,
  CodeBlockLanguage.Haskell,
  CodeBlockLanguage.HTML,
  CodeBlockLanguage.Json,
  CodeBlockLanguage.Java,
  CodeBlockLanguage.Javascript,
  CodeBlockLanguage.Kotlin,
  CodeBlockLanguage.Markdown,
  CodeBlockLanguage.Matlab,
  CodeBlockLanguage.ObjectiveC,
  CodeBlockLanguage.Python,
  CodeBlockLanguage.R,
  CodeBlockLanguage.Scala,
  CodeBlockLanguage.Shell,
  CodeBlockLanguage.SQL,
  CodeBlockLanguage.Typescript,
  CodeBlockLanguage.Yaml,
];

const FloatingMenuDiv = styled.div`
  background: white;
  box-shadow: ${levels.two};
  display: flex;
  height: 36px;
`;

const FloatingMenuDivider = styled.div`
  display: inline-flex;
  width: 1px;
  height: 20px;
  background: rgba(89, 115, 166, 0.15);
  margin: 7px 2px;
`;

const OptionsMenu = styled(Menu)`
  width: 210px;
`;

const OptionsMenuItem = styled.div.attrs({
  role: "menuitem",
  tabindex: "0",
})`
  font-family: ${typography.system.fontFamily};
  font-size: ${typography.system.fontSize};
  color: ${(p) => p.theme.text.shade1};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 27px;
  gap: 6px;
  margin: 0 9px;
`;
