import { IconProps } from "./types";

export const TildeUnderneathIcon = (props: IconProps) => {
  return (
    <svg width="28" height="26" viewBox="0 0 28 26" {...props}>
      <path
        d="M19.2397 20.394C19.4077 20.394 19.4917 20.592 19.4917 20.988C19.4917 21.204 19.4677 21.432 19.4197 21.672C19.3237 22.272 19.1137 22.812 18.7897 23.292C18.4777 23.76 18.0997 24.102 17.6557 24.318C17.2957 24.498 16.9477 24.588 16.6117 24.588H16.4677C16.1317 24.588 15.8317 24.54 15.5677 24.444C15.0157 24.252 14.2837 23.754 13.3717 22.95C12.7237 22.386 12.2557 22.02 11.9677 21.852C11.4637 21.552 10.9777 21.402 10.5097 21.402C9.96966 21.402 9.49566 21.558 9.08766 21.87C8.69166 22.182 8.39766 22.566 8.20566 23.022C8.09766 23.322 8.02566 23.664 7.98966 24.048C7.96566 24.42 7.88166 24.606 7.73766 24.606C7.56966 24.606 7.48566 24.408 7.48566 24.012C7.48566 23.88 7.49166 23.772 7.50366 23.688C7.59966 22.8 7.88166 22.062 8.34966 21.474C8.81766 20.886 9.40566 20.538 10.1137 20.43C10.2337 20.406 10.3597 20.394 10.4917 20.394C10.7197 20.394 10.9057 20.412 11.0497 20.448C11.3617 20.52 11.6797 20.646 12.0037 20.826C12.3277 21.006 12.5857 21.174 12.7777 21.33C12.9697 21.486 13.2457 21.726 13.6057 22.05C14.2537 22.614 14.7217 22.98 15.0097 23.148C15.5137 23.448 15.9997 23.598 16.4677 23.598C16.9957 23.598 17.4637 23.448 17.8717 23.148C18.1957 22.92 18.4597 22.602 18.6637 22.194C18.8797 21.774 18.9877 21.348 18.9877 20.916C18.9877 20.772 19.0117 20.652 19.0597 20.556C19.1197 20.448 19.1797 20.394 19.2397 20.394Z"
        fill="#3A517F"
      />
      <path
        d="M8 7.5C8 7.22386 8.22386 7 8.5 7H19.5C19.7761 7 20 7.22386 20 7.5V18.5C20 18.7761 19.7761 19 19.5 19H8.5C8.22386 19 8 18.7761 8 18.5V7.5Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8V18H19V8H9ZM8.5 7C8.22386 7 8 7.22386 8 7.5V18.5C8 18.7761 8.22386 19 8.5 19H19.5C19.7761 19 20 18.7761 20 18.5V7.5C20 7.22386 19.7761 7 19.5 7H8.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
