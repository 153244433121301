import { findChildren, findParentNodeClosestToPos } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import { getTableNodeTypes } from "../utils";

/**
 * Plugin to ensure that table is wrapped inside table group
 * when it got pasted over without it which also includes the
 * insertion of table caption and footnote
 */
export const TableEnsureGroupPlugin = () =>
  new Plugin({
    key: new PluginKey("tableEnsureGroup"),
    appendTransaction: (_tr, oldState, editorState) => {
      if (oldState.doc === editorState.doc) return;
      const { doc, schema, tr } = editorState;
      const tableNodeTypes = getTableNodeTypes(schema);
      const tables = findChildren(doc, (node) => {
        return node.type === getTableNodeTypes(schema).table;
      });
      let fixing = false;
      tables
        .sort((a, b) => {
          if (a.pos > b.pos) {
            return -1;
          } else if (a.pos < b.pos) {
            return 1;
          }
          return 0;
        })
        .map(({ node, pos }) => {
          const $pos = doc.resolve(pos);
          if (
            !findParentNodeClosestToPos(
              $pos,
              (node) => node.type === tableNodeTypes.table_group
            )
          ) {
            const emptyCaption = tableNodeTypes.table_caption.createAndFill();
            const emptyFootnote = tableNodeTypes.table_footnote.createAndFill();
            if (emptyCaption && emptyFootnote) {
              const newNode = tableNodeTypes.table_group.createChecked(
                undefined,
                [emptyCaption, node, emptyFootnote]
              );
              tr.replaceWith(pos, pos + node.nodeSize, newNode);
              fixing = true;
            }
          }
        });
      if (fixing) return tr;
      return;
    },
  });
