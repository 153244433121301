import { makeVar, useReactiveVar } from "@apollo/client";
import {
  Editor,
  editorWordCount,
  useControlledEditor,
} from "@vericus/cadmus-editor-prosemirror";

export const bodyVar = makeVar<Editor | null>(null);
export const notesVar = makeVar<Editor | null>(null);
export const referencesVar = makeVar<Editor | null>(null);
export const activeEditorVar = makeVar<Editor | null>(null);

export const useBodyEditor = () => useReactiveVar(bodyVar);
export const useNotesEditor = () => useReactiveVar(notesVar);
export const useReferencesEditor = () => useReactiveVar(referencesVar);

/** Word count for the current focused editor. */
export const useTotalWordCount = (): number => {
  const body = useBodyEditor();
  const references = useReferencesEditor();
  return sumEditorWordCounts([body, references]);
};

/** Hook to get the current focused editor. */
export const useActiveEditor = () => {
  const editor = useReactiveVar(activeEditorVar);
  useControlledEditor(editor);
  return editor;
};

/** Check if all editors are initialised. */
export const useEditorsLoaded = () => {
  const body = useBodyEditor();
  const notes = useNotesEditor();
  const references = useReferencesEditor();

  if (body && notes && references) return true;

  return false;
};

/** Run a function on all editors and ignore the result. */
export const forAllEditors = (f: (editor: Editor) => any) => {
  const body = bodyVar();
  const notes = notesVar();
  const references = referencesVar();

  body && f(body);
  notes && f(notes);
  references && f(references);
};

/** Predicate to check if any editor has a pending save debounced. */
export function hasPendingSave(): boolean {
  let hasPendingSave = false;
  forAllEditors((editor) => {
    if (editor.storage.telemetry?.pendingSave ?? false) {
      hasPendingSave = true;
    }
  });
  return hasPendingSave;
}

export function getTotalWordCount(): number {
  const body = bodyVar();
  const references = referencesVar();
  return sumEditorWordCounts([body, references]);
}

function sumEditorWordCounts(editors: (Editor | null)[]): number {
  return editors.reduce((acc, editor) => {
    if (editor) {
      const count = editorWordCount(editor);
      return (count?.total ?? 0) + acc;
    }
    return acc;
  }, 0);
}
