import { Extension, getNodeType } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

const emptyNodeKey = new PluginKey("emptyyImageNode");

export const EmptyImageNode = Extension.create({
  name: "emptyyImageNode",
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: emptyNodeKey,
        state: {
          init: (_, state) => {
            const { doc, schema } = state;
            const decorations: Decoration[] = [];
            const imageType = getNodeType("image", schema);

            doc.descendants((node, pos) => {
              if (node.type === imageType && node.textContent === "") {
                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  "data-empty-node": "true",
                });

                decorations.push(decoration);
              }
            });
            return DecorationSet.create(doc, decorations);
          },
          apply: (tr, decoSet, _, newState) => {
            if (tr.docChanged) {
              let updatedDecoSet = decoSet.map(tr.mapping, tr.doc);
              const { doc, schema } = newState;
              const imageType = getNodeType("image", schema);
              const newDecorations: Decoration[] = [];
              //invalidated placeholder that now has content on the block
              updatedDecoSet = updatedDecoSet.remove(
                updatedDecoSet.find().filter((deco: Decoration) => {
                  const node = doc.nodeAt(deco.from);
                  return (
                    !node ||
                    node?.type !== imageType ||
                    node?.textContent !== ""
                  );
                })
              );

              // add new placeholder
              let from, to;
              for (let i = 0; i < tr.steps.length; i++) {
                const step = tr.steps[i] as any;

                const stepMapping = step.getMap();

                //new position after step
                const stepFrom = stepMapping.map(step.from || step.pos, -1);
                const stepTo = stepMapping.map(step.to || step.pos, 1);

                if (from) {
                  from = Math.min(stepMapping.map(from, -1), stepFrom);
                } else {
                  from = stepFrom;
                }

                if (to) {
                  to = Math.max(stepMapping.map(to, 1), stepTo);
                } else {
                  to = stepTo;
                }
              }
              doc.nodesBetween(from, to, (node, pos) => {
                if (node.type === imageType && node.textContent === "") {
                  const decoration = Decoration.node(pos, pos + node.nodeSize, {
                    "data-empty-node": "true",
                  });

                  newDecorations.push(decoration);
                }
              });
              return updatedDecoSet.add(doc, newDecorations);
            }
            return decoSet;
          },
        },
        props: {
          decorations: (state) => emptyNodeKey.getState(state),
        },
      }),
    ];
  },
});
