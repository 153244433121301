import { Extension } from "@tiptap/core";

import { MixIndentationOption } from "./types";
import {
  parseLevelIndent,
  parseSpecialIndent,
  renderLevelIndent,
  renderSpecialIndent,
} from "./helpers";

/**
 * Extension for block(s) that can have a mix of the level & special indentation
 */
export const MixIndentation = Extension.create<MixIndentationOption>({
  name: "mixIndentation",

  addOptions() {
    return {
      types: ["paragraph"],
      indentationStyles: ["normal", "firstLine"],
      renderStyles: ["normal", "firstLine", "hanging"],
      defaultIndentation: "normal",
      maxIndentation: 8,
    };
  },

  addGlobalAttributes() {
    if (this.options.types.length) {
      return [
        {
          types: this.options.types,
          attributes: {
            indentSpecial: {
              default: this.options.defaultIndentation,
              renderHTML: (attributes) =>
                renderSpecialIndent(
                  this.options.renderStyles,
                  this.options.defaultIndentation
                )(attributes),
              parseHTML: parseSpecialIndent(
                this.options.indentationStyles,
                this.options.defaultIndentation
              ),
            },
            indentLevel: {
              default: 0,
              renderHTML: (attributes) =>
                renderLevelIndent(this.options.maxIndentation)(attributes),
              parseHTML: (element) =>
                parseLevelIndent(
                  this.options.maxIndentation,
                  this.options.defaultIndentation
                )(element),
            },
          },
        },
      ];
    }
    return [];
  },
});
