import { ParagraphSkeleton, Spacer, TitleSkeleton } from "@vericus/cadmus-ui";
import styled from "styled-components";

import { deserialiseWorkEditors } from "@/stores/editors";
import {
  useDraftSubmissionQuery,
  useFinalSubmissionQuery,
} from "generated/graphql";
import { SubmittedWork } from "ui/work/SubmittedWork";

// Props for SubmissionPane
interface Props {
  /** Work ID to fetch the latest submission for */
  workId: string;
  /** Hanging indent for rendering references in the submission */
  hangingIndent: boolean;
}

// Draft submission query wrapper for the <Submission /> component
export function DraftSubmissionPane(props: Props) {
  const { workId, hangingIndent } = props;

  const { data } = useDraftSubmissionQuery({
    variables: { workId },
  });
  const submission = data?.work?.submission;
  const save = submission?.save;
  const editors = save && deserialiseWorkEditors(save);

  // Render a loading skeleton when the submission is being fetched and
  // initialised into editors
  if (!editors) return <SubmissionSkeleton />;

  return (
    <SubmittedWork
      editorIdPrefix="draft"
      bodyContent={editors.body}
      referencesContent={editors.references}
      enableHangingIndent={hangingIndent}
    />
  );
}

// Final submission query wrapper for the <Submission /> component
export function FinalSubmissionPane(props: Props) {
  const { workId, hangingIndent } = props;
  const { data } = useFinalSubmissionQuery({
    variables: { workId },
  });
  const submission = data?.work?.submission;
  const save = submission?.save;
  const editors = save && deserialiseWorkEditors(save);

  // Render a loading skeleton when the submission is being fetched and
  // initialised into editors
  if (!editors) return <SubmissionSkeleton />;

  return (
    <SubmittedWork
      editorIdPrefix="final"
      bodyContent={editors.body}
      referencesContent={editors.references}
      enableHangingIndent={hangingIndent}
    />
  );
}

// Submission loading skeleton with title and paragraphs
const SubmissionSkeleton = () => (
  <Padded>
    <TitleSkeleton />
    <Spacer spacing={36} />
    <ParagraphSkeleton />
    <Spacer spacing={36} />
    <ParagraphSkeleton />
  </Padded>
);

const Padded = styled.div`
  padding: 63px 45px;
`;
