import { IconProps } from "./types";

export const DotIcon = (props: IconProps) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" {...props}>
      <path
        d="M14.7408 7.58342C14.4288 7.58342 14.1648 7.47542 13.9488 7.25942C13.7448 7.04342 13.6428 6.79142 13.6428 6.50342C13.6428 6.23942 13.7208 6.01142 13.8768 5.81942C14.0448 5.62742 14.2488 5.50142 14.4888 5.44142C14.6448 5.39342 14.8068 5.39342 14.9748 5.44142C15.2148 5.48942 15.4128 5.61542 15.5688 5.81942C15.7368 6.02342 15.8208 6.24542 15.8208 6.48542C15.8208 6.79742 15.7128 7.06142 15.4968 7.27742C15.2808 7.48142 15.0288 7.58342 14.7408 7.58342Z"
        fill="#3A517F"
      />
      <path
        d="M8 10.4971C8 10.2209 8.22386 9.99707 8.5 9.99707H19.5C19.7761 9.99707 20 10.2209 20 10.4971V21.4971C20 21.7732 19.7761 21.9971 19.5 21.9971H8.5C8.22386 21.9971 8 21.7732 8 21.4971V10.4971Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.9971V20.9971H19V10.9971H9ZM8.5 9.99707C8.22386 9.99707 8 10.2209 8 10.4971V21.4971C8 21.7732 8.22386 21.9971 8.5 21.9971H19.5C19.7761 21.9971 20 21.7732 20 21.4971V10.4971C20 10.2209 19.7761 9.99707 19.5 9.99707H8.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
