import { Extension } from "@tiptap/core";

import { LevelIndentationOption } from "./types";
import { parseLevelIndent, renderLevelIndent } from "./helpers";

/**
 * Extension to setup indentation attributes for blocks that can have multiple
 * levels of indentation.
 */
export const LevelIndentation = Extension.create<LevelIndentationOption>({
  name: "levelIndentation",
  addGlobalAttributes() {
    if (this.options.types.length) {
      return [
        {
          types: this.options.types,
          attributes: {
            indentLevel: {
              default: 0,
              renderHTML: (attributes) =>
                renderLevelIndent(this.options.maxIndentation)(attributes),
              parseHTML: (element) =>
                parseLevelIndent(this.options.maxIndentation)(element),
            },
          },
        },
      ];
    }
    return [];
  },
});
