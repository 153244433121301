import { colors, typography } from "@vericus/cadmus-ui";
import { css } from "styled-components";

export const codeStyles = css`
  code {
    font-family: ibm-plex-mono, ui-monospace, monospace;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid ${colors.grey50};
    line-height: 24px;
    color: ${colors.indigo800};
    background: #eff2f6;
    border-radius: 3px;
    padding: 1px 4px 1.5px 4px;
  }

  h1 > code {
    font-size: calc(${typography.headingOne.fontSize} - 1px);
    font-weight: 600;
  }

  h2 > code {
    font-size: calc(${typography.headingTwo.fontSize} - 1px);
    font-weight: 600;
  }

  h3 > code {
    font-size: calc(${typography.headingThree.fontSize} - 1px);
    font-weight: 600;
  }

  h4 > code {
    font-size: calc(${typography.headingFour.fontSize} - 1px);
    font-weight: 600;
  }

  h5 > code {
    font-size: calc(${typography.headingFive.fontSize} - 1px);
    font-weight: 600;
  }

  h6 > code {
    font-size: calc(${typography.headingSix.fontSize} - 1px);
    font-weight: 600;
  }
`;
