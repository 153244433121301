import {
  Editor,
  getMarkRange,
  getMarkType,
  getMarksBetween,
} from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

/** Plugin creation options for the `HyperlinkMenuPlugin`. */
export interface HyperlinkMenuPluginProps {
  /** Callback to open a link */
  onOpenLink: (href: string) => void;
  editor: Editor;
}

/** Plugin key for `HyperlinkMenuPlugin` */
export const HyperlinkMenuPluginKey = new PluginKey<HyperlinkMenuTarget | null>(
  "hyperlinkMenu"
);

/**
 * Describes the range in the document which is the target of hyperlink creation.
 * This also acts as the Plugin state for `HyperlinkMenuPlugin`.
 */
export interface HyperlinkMenuTarget {
  from: number;
  to: number;
  href: string;
}

/** Transaction metadata action to trigger a hyperlink menu on a target. */
export interface TriggerMenuAction {
  type: "triggerMenu";
  target: HyperlinkMenuTarget;
}

/** Transaction metadata action to dismiss any existing hyperlink menu. */
export interface DismissMenuAction {
  type: "dismissMenu";
  invalidLink?: boolean;
}

export type HyperlinkMenuAction = TriggerMenuAction | DismissMenuAction;

// Check for macos platforms
const mac =
  typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false;

/**
 * Plugin to render a floating menu for hyperlink creation and edit on hyperlink marks.
 *
 * This plugin store the state of the clicked hyperlink mark which HyperlinkMenu used
 * to control whether it needs to be opened or closed through the Transaction metadata
 * actions under the `HyperlinkMenuPluginKey`:
 *
 *   - `TriggerMenuAction` will decorate the provided range with a `.selected-link`
 *     class and open the menu with the given `href` as the input contents.
 *
 *   - `DismissMenuAction` will unset the plugin state removing any menu.
 */
export const HyperlinkMenuPlugin = (props: HyperlinkMenuPluginProps) => {
  return new Plugin<HyperlinkMenuTarget | null>({
    key: HyperlinkMenuPluginKey,
    state: {
      init() {
        return null;
      },
      apply(tr, state) {
        const payload = tr.getMeta(HyperlinkMenuPluginKey) as
          | HyperlinkMenuAction
          | undefined;
        switch (payload?.type) {
          case "triggerMenu":
            return payload.target;
          case "dismissMenu":
            return null;
          default: {
            return state;
          }
        }
      },
    },
    props: {
      // Clicking existing link marks should bring up the menu. Cmd+clicking the
      // link marks directly opens the `href` by calling `props.onOpenLink`.
      handleClick(view, pos, event) {
        const { state } = view;
        const { schema, doc } = state;
        const pluginState = this.getState(state);
        const markType = getMarkType("link", schema);
        const $from = doc.resolve(pos);
        const range = getMarkRange($from, markType);
        const marks = range ? getMarksBetween(range.from, range.to, doc) : [];
        const linkMark = marks.find(({ mark }) => mark.type === markType);
        const { href } = linkMark?.mark?.attrs ?? {};
        const link = (event.target as HTMLElement)?.closest("a");

        const validLinkClicked = link && href !== undefined;
        if (
          validLinkClicked &&
          ((mac && event.metaKey) || (!mac && event.ctrlKey))
        ) {
          props.onOpenLink(href);
          return true;
        }

        if (validLinkClicked && range) {
          const { from, to } = range;
          const target = { href, from, to };
          props.editor
            .chain()
            .focus()
            .dismissMenu()
            .setTextSelection({ from, to })
            .setHyperlinkMenuTarget(target)
            .run();
        } else if (pluginState) {
          props.editor.commands.setHyperlinkMenuTarget();
        }

        // need to return false so selection can work properly
        // especially after select all
        return false;
      },

      // When a menu is rendered, the range should be decorated. This is useful
      // if the range is currently not a link but would be made one if the user
      // inputted link is valid. During that process, the range will be
      // decorated as a temporary link.
      decorations(editorState) {
        const menuState = this.getState(editorState);
        if (menuState) {
          const { from, to } = menuState;
          return DecorationSet.create(editorState.doc, [
            Decoration.inline(
              from,
              to,
              { class: "selected-link" },
              { inclusiveStart: true, inclusiveEnd: true }
            ),
          ]);
        }

        return DecorationSet.empty;
      },
    },
  });
};
