import { Anchored, Button, Spacer, Text } from "@vericus/cadmus-ui";
import styled from "styled-components";

import NotFoundImg from "assets/errors/not-found.png";
import { Grid } from "styles/layout";

export function NoMatchError() {
  return (
    <Grid>
      <GridImg
        src={NotFoundImg}
        alt="An illustration of a man trying to read a map. He looks lost."
      />
      <GridContent>
        <Text kind="displayTwo">404: Page not found</Text>
        <Text kind="lead">
          The page you’re looking for doesn’t exist. Try accessing Cadmus again
          from your Learning Management System.
        </Text>
        <Spacer spacing={27} />
        <Anchored
          newTab
          href="https://support.cadmus.io/students/404-page-not-found"
        >
          <Button kind="dark">Learn more about this error</Button>
        </Anchored>
        <Anchored
          newTab
          href="https://support.cadmus.io/kb-tickets/new"
        >
          <Button marginLeft={18}>Contact us for help</Button>
        </Anchored>
        <Spacer spacing={72} />
      </GridContent>
    </Grid>
  );
}

const GridImg = styled.img`
  grid-column: 3 / span 5;
  width: 100%;
  margin-top: 72px;

  @media screen and (max-width: 900px) {
    grid-column: 2 / span 7;
  }

  @media screen and (max-width: 630px) {
    grid-column: 1 / span 9;
  }
`;

const GridContent = styled.div`
  grid-column: 3 / span 5;
  text-align: center;
  margin-top: 18px;

  @media screen and (max-width: 900px) {
    grid-column: 1 / span 9;
  }
`;
