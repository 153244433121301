import { Node, mergeAttributes } from "@tiptap/core";
import crel from "crelt";

export interface PasteChipOptions {
  HTMLAttributes: Record<string, any>;
}

/** Inline atom Node for displaying the paste chip */
export const PasteChip = Node.create<PasteChipOptions>({
  name: "pasteChip",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: "inline",

  inline: true,

  draggable: false,

  atom: true,
  allowGapCursor: true,

  addAttributes() {
    return {
      // Track number of words pasted in the dom attribute `data-cadmus-pasted`
      pasted: {
        default: 0,
        parseHTML: (element) =>
          parseInt(element.getAttribute("data-cadmus-pasted") ?? "0") || 0,
        renderHTML: (attributes) => {
          return {
            "data-cadmus-pasted": attributes.pasted || 0,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[data-cadmus-pasted]",
      },
    ];
  },

  // Render span container for the paste chip NodeView
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addNodeView() {
    return ({ node, editor }) => {
      const { pasted } = node.attrs;

      // `paraphrasing` manual preset open button
      const paraphrasingLink = crel(
        "button",
        {
          class: "cui-LinkButton",
          onclick: () =>
            editor.commands.setMeta("openManualPreset", "paraphrasing"),
        },
        crel("span", "paraphrasing")
      );
      // `quoting` manual preset open button
      const quotingLink = crel(
        "button",
        {
          class: "cui-LinkButton",
          onclick: () => editor.commands.setMeta("openManualPreset", "quoting"),
        },
        crel("span", "quoting")
      );

      const closeIcon = createCloseIcon();

      const closeButton = crel(
        "button",
        {
          onclick: (event: MouseEvent) =>
            editor.commands.deletePasteChip(event),
          class: "icon-button",
          "aria-label": "close",
        },
        closeIcon
      );

      // The full paste chip
      const pasteChip = crel(
        "span",
        { "data-cadmus-pasted": `${pasted}` },
        `${pasted} words pasted. Consider `,
        paraphrasingLink,
        " or ",
        quotingLink,
        closeButton
      );

      return {
        dom: pasteChip,
      };
    };
  },
});

function createCloseIcon() {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttributeNS(null, "viewBox", "0 0 16 21");
  svg.setAttributeNS(null, "width", "1em");
  svg.setAttributeNS(null, "height", "100%");

  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttributeNS(
    null,
    "d",
    "M8,9.57142857 L10.8643218,6.70710678 C11.2548461,6.31658249 11.8880111,6.31658249 12.2785354,6.70710678 L12.2928932,6.72146465 C12.6834175,7.11198894 12.6834175,7.74515392 12.2928932,8.13567821 L9.42857143,11 L9.42857143,11 L12.2928932,13.8643218 C12.6834175,14.2548461 12.6834175,14.8880111 12.2928932,15.2785354 L12.2785354,15.2928932 C11.8880111,15.6834175 11.2548461,15.6834175 10.8643218,15.2928932 L8,12.4285714 L8,12.4285714 L5.13567821,15.2928932 C4.74515392,15.6834175 4.11198894,15.6834175 3.72146465,15.2928932 L3.70710678,15.2785354 C3.31658249,14.8880111 3.31658249,14.2548461 3.70710678,13.8643218 L6.57142857,11 L6.57142857,11 L3.70710678,8.13567821 C3.31658249,7.74515392 3.31658249,7.11198894 3.70710678,6.72146465 L3.72146465,6.70710678 C4.11198894,6.31658249 4.74515392,6.31658249 5.13567821,6.70710678 L8,9.57142857 L8,9.57142857 Z"
  );
  svg.appendChild(path);

  return svg;
}
