export default (
  <>
    <path d="M9.95789 13.02H6.33789C5.92789 13.02 5.58789 13.36 5.58789 13.77C5.58789 14.18 5.92789 14.52 6.33789 14.52H10.8379C11.2479 14.52 11.5879 14.18 11.5879 13.77C11.5879 13.75 11.5879 13.73 11.5779 13.71H11.5579C10.9279 13.71 10.3579 13.44 9.95789 13.02Z" />
    <path d="M13.7479 9.93002V11.53C13.7479 12.15 13.4879 12.7 13.0879 13.1V16.53H4.08789V5.52002H9.35789V4.02002H2.58789V18.02H14.5879V9.93002H13.7479Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4979 6.76002V3.91002C12.4979 3.39002 12.0779 2.96002 11.5479 2.96002C11.0179 2.96002 10.5979 3.38002 10.5979 3.91002V6.76002H7.74785C7.22785 6.76002 6.79785 7.18002 6.79785 7.71002C6.79785 8.24002 7.21785 8.66002 7.74785 8.66002H10.5979V11.51C10.5979 12.03 11.0179 12.46 11.5479 12.46C12.0779 12.46 12.4979 12.04 12.4979 11.51V8.66002H15.3479C15.8679 8.66002 16.2979 8.24002 16.2979 7.71002C16.2979 7.18002 15.8779 6.76002 15.3479 6.76002H12.4979Z"
    />
  </>
);
