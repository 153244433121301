import { IconProps } from "./types";

export const DDotIcon = (props: IconProps) => {
  return (
    <svg width="30" height="28" viewBox="0 0 30 28" {...props}>
      <path
        d="M16.6212 7.58342C16.3092 7.58342 16.0452 7.47542 15.8292 7.25942C15.6252 7.04342 15.5232 6.79142 15.5232 6.50342C15.5232 6.23942 15.6012 6.01142 15.7572 5.81942C15.9252 5.62742 16.1292 5.50142 16.3692 5.44142C16.5252 5.39342 16.6872 5.39342 16.8552 5.44142C17.0952 5.48942 17.2932 5.61542 17.4492 5.81942C17.6172 6.02342 17.7012 6.24542 17.7012 6.48542C17.7012 6.79742 17.5932 7.06142 17.3772 7.27742C17.1612 7.48142 16.9092 7.58342 16.6212 7.58342Z"
        fill="#3A517F"
      />
      <path
        d="M13.3731 7.58342C13.0611 7.58342 12.7971 7.47542 12.5811 7.25942C12.3771 7.04342 12.2751 6.79142 12.2751 6.50342C12.2751 6.23942 12.3531 6.01142 12.5091 5.81942C12.6771 5.62742 12.8811 5.50142 13.1211 5.44142C13.2771 5.39342 13.4391 5.39342 13.6071 5.44142C13.8471 5.48942 14.0451 5.61542 14.2011 5.81942C14.3691 6.02342 14.4531 6.24542 14.4531 6.48542C14.4531 6.79742 14.3451 7.06142 14.1291 7.27742C13.9131 7.48142 13.6611 7.58342 13.3731 7.58342Z"
        fill="#3A517F"
      />
      <path
        d="M9 10.4971C9 10.2209 9.22386 9.99707 9.5 9.99707H20.5C20.7761 9.99707 21 10.2209 21 10.4971V21.4971C21 21.7732 20.7761 21.9971 20.5 21.9971H9.5C9.22386 21.9971 9 21.7732 9 21.4971V10.4971Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.9971V20.9971H20V10.9971H10ZM9.5 9.99707C9.22386 9.99707 9 10.2209 9 10.4971V21.4971C9 21.7732 9.22386 21.9971 9.5 21.9971H20.5C20.7761 21.9971 21 21.7732 21 21.4971V10.4971C21 10.2209 20.7761 9.99707 20.5 9.99707H9.5Z"
        fill="#7070A9"
      />
    </svg>
  );
};
