import { Children, ComponentProps } from "react";
import styled from "styled-components";

import { ControlButton } from "../ControlButton";
import { Popover } from "../Popover";
import { levels } from "../styles/levels";

interface Props extends ComponentProps<typeof ControlButton> {
  /** Wrap children (ControlButtons) in a dropdown  */
  wrap?: boolean;
  /** Array of one or more ControlButtons. */
  children: React.ReactNode;
}

/**
 * Group of ControlButtons which can be collapsed into a single ControlDropdown.
 */
export function ControlGroup(props: Props) {
  const { wrap, children, ...buttonProps } = props;
  const childrenCount = Children.count(children);

  // never turn a single child into drop down
  if (childrenCount === 1) {
    return <UnwrappedContainer>{children}</UnwrappedContainer>;
  }

  // render wrapped group as Dropdown if wrap is true
  if (wrap) {
    return (
      <Popover
        mainAxisOffset={2}
        render={() => <ControlMenu>{children}</ControlMenu>}
        autoFocus={false}
      >
        <ControlButton withDownCaret tooltipPosition="top" {...buttonProps} />
      </Popover>
    );
  }

  return <UnwrappedContainer>{children}</UnwrappedContainer>;
}

export const ControlMenu = styled.span`
  border-radius: 3px;
  padding: 2px;
  margin: 0;

  display: flex;

  background: white;
  background-color: ${(p) => p.theme.background.default};
  z-index: 4;
  position: relative;
  box-shadow: ${levels.three};
`;

const UnwrappedContainer = styled.span`
  display: flex;
`;
