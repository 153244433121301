import { IconProps } from "./types";

export const SquareBracketIcon = (props: IconProps) => {
  return (
    <svg width="24" height="29" viewBox="0 0 24 29" {...props}>
      <path
        d="M4.11423 23.5V5.5H6.58023V6.22H4.83423V22.78H6.58023V23.5H4.11423Z"
        fill="#3A517F"
      />
      <path
        d="M8 10.8333C8 10.6492 8.14924 10.5 8.33333 10.5H15.6667C15.8508 10.5 16 10.6492 16 10.8333V18.1667C16 18.3508 15.8508 18.5 15.6667 18.5H8.33333C8.14924 18.5 8 18.3508 8 18.1667V10.8333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.5V17.5H15V11.5H9ZM8.33333 10.5C8.14924 10.5 8 10.6492 8 10.8333V18.1667C8 18.3508 8.14924 18.5 8.33333 18.5H15.6667C15.8508 18.5 16 18.3508 16 18.1667V10.8333C16 10.6492 15.8508 10.5 15.6667 10.5H8.33333Z"
        fill="#7070A9"
      />
      <path
        d="M17.396 6.22V5.5H19.862V23.5H17.396V22.78H19.142V6.22H17.396Z"
        fill="#3A517F"
      />
    </svg>
  );
};
