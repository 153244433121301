import React, { ReactNode, forwardRef } from "react";
import styled, { css } from "styled-components";

import { Icon } from "../Icon";
import { colors, typography } from "../styles";

export interface PillProps extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * Create a colored pill. Modifies the background color, it may also affect
   * text color if it's not explicitly overriden.
   */
  color?: string;
  /**
   * Callback fired when the delete icon is clicked. If set, the delete icon
   * will be shown.
   */
  onDelete?: () => void;
  /**
   * Basic pill with no button like styling.
   */
  basic?: boolean;
  /**
   * Optionally override the default colors.
   */
  textColor?: string;
  /**
   * Change the underlying root HTML element.
   * @default "div"
   */
  as?: React.ElementType;
}

/**
 * Button-like pill with various colours.
 */
export const Pill = forwardRef<HTMLButtonElement, PillProps>((props, ref) => {
  const {
    as,
    color,
    textColor,
    onDelete,
    children,
    basic = false,
    ...buttonProps
  } = props;

  let rightSection: ReactNode = null;
  if (onDelete) {
    rightSection = (
      <>
        <PillSeparator />
        <PillActionIcon
          onClick={(event) => {
            event.stopPropagation();
            onDelete();
          }}
        >
          <PillIcon iconName="Trash" />
        </PillActionIcon>
      </>
    );
  }

  return (
    <PillRoot
      ref={ref}
      as={as}
      color={color}
      textColor={textColor}
      role={basic ? undefined : "button"}
      tabIndex={basic ? undefined : 0}
      basic={basic}
      {...buttonProps}
    >
      {children}
      {rightSection}
    </PillRoot>
  );
});

const PillRoot = styled.div<{
  color?: string;
  textColor?: string;
  basic: boolean;
}>`
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;

  font-family: ${typography["label"].fontFamily};
  font-size: ${typography["label"].fontSize};
  font-weight: ${typography["label"].fontWeight};
  text-transform: ${typography["label"].textTransform};
  letter-spacing: 0.5px;
  line-height: 18px;

  color: ${(p) =>
    p.textColor
      ? p.textColor
      : p.color && p.color !== colors.white200
      ? p.theme.text.main
      : colors.grey500};
  background-color: ${(p) => (p.color ? p.color : colors.white100)};
  border: ${(p) =>
    p.color && p.color !== colors.white200
      ? "none"
      : `1px solid ${colors.grey100}`};
  outline: none;

  padding: 2px 8px;
  margin: 0 2px;
  min-width: 28px;
  box-sizing: border-box;
  border-radius: 8px;

  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${(p) => (p.basic ? "inherit" : "pointer")};

  ${(p) =>
    !p.basic &&
    css`
      :hover,
      :focus {
        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(89, 115, 166, 0.06);
          pointer-events: none;
        }
      }
    `}
`;

const PillSeparator = styled.span`
  border-right: 1px solid ${colors.grey300};
  margin-left: 8px;
  height: 10px;
`;

const PillActionIcon = styled.div`
  margin-right: -2px;
  margin-left: 2px;
  display: grid;
  cursor: pointer;

  :hover {
    border-radius: 100%;
    background-color: ${colors.grey200};
  }
`;

const PillIcon = styled(Icon)`
  height: 18px;
  width: 18px;
  fill: ${colors.grey500};
`;
