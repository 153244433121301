import { EditorEvent } from "@vericus/cadmus-editor-prosemirror";

import { __GLOBAL_SESSION_ID } from "client/globals";
import { connection } from "client/phoenix";
import { ResourceFragment } from "generated/graphql";
import { time } from "utils/serverTime";

/**
 * All Aphrodite client events emitted over the websocket channel.
 *
 * Use the various exported handlers to create and emit these events on the Work
 * channel.
 */
export type AphroditeEvent = ResourceViewEvent | PMHistoryEvent | PMPasteEvent;

enum EventType {
  PMPaste = "PM_PASTE",
  PMHistory = "PM_HISTORY",
  ResourceView = "RESOURCE_VIEW",
}

interface PMPasteEvent {
  type: EventType.PMPaste;
  editor_id: string;
  internal: boolean | null;
  paste_type: string | null;
  word_count: number | null;
  words_added: number | null;
  words_deleted: number | null;
  paste_origin: string | null;
  s3_payload: string | null;
}

interface PMHistoryEvent {
  type: EventType.PMHistory;
  editor_id: string;
  history_type: "undo" | "redo";
  words_added: number | null;
  words_deleted: number | null;
}

interface ResourceViewEvent {
  type: EventType;
  resource_id: string;
  resource_url: string | null;
  resource_name: string | null;
}

/**
 * Create and emit Aphrodite Editor events. Maps the existing `EditorEvent` type
 * into its Aphrodite variants.
 */
export function handleEditorEvent(editorEvent: EditorEvent) {
  switch (editorEvent.type) {
    case "paste": {
      const editorPayload = editorEvent.payload;
      const event: PMPasteEvent = {
        type: EventType.PMPaste,
        editor_id: editorPayload.editorId,
        s3_payload: JSON.stringify(editorPayload),
        words_added: editorPayload.addedWords,
        words_deleted: editorPayload.deletedWords,
        word_count: editorPayload.wordCount ?? null,
        paste_type: editorPayload.pasteType ?? null,
        internal: editorPayload.internal ?? null,
        paste_origin: editorPayload.origin ?? null,
      };
      channelLogger(event);
      break;
    }

    case "history": {
      const editorPayload = editorEvent.payload;
      const event: PMHistoryEvent = {
        type: EventType.PMHistory,
        words_added: editorPayload.addedWords,
        words_deleted: editorPayload.deletedWords,
        history_type: editorPayload.historyType,
        editor_id: editorPayload.editorId,
      };
      channelLogger(event);
      break;
    }
  }
}

/**
 * Create and emit a Resource view event.
 */
export function handleResourceViewEvent(resource: ResourceFragment) {
  const json: ResourceViewEvent = {
    type: EventType.ResourceView,
    resource_id: resource.id,
    resource_url: resource.url,
    resource_name: resource.name,
  };
  channelLogger(json);
}

/**
 * Serialise and emit arbitrary JSON objects over the connected Work channel as
 * Cadmus telemetry events.
 *
 * Adds extra metadata fields to the JSON object:
 *
 *   - `work_id` Current Work ID
 *   - `assessment_id` Current Assessment ID
 *   - `session_id` latest recorded client Session ID
 *
 * The serialised payload is sent with a client-side but server-synced
 * timestamp.
 */
export const channelLogger = (json: Record<string, any>) => {
  const { channel, workId, assessmentId } = connection;
  if (channel && workId && assessmentId) {
    const timestamp = new Date(time()).toISOString();
    channel.push("event", {
      payload: {
        ...json,
        work_id: workId,
        assessment_id: assessmentId,
        session_id: __GLOBAL_SESSION_ID.current,
      },
      timestamp,
    });
  }
};
