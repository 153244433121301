import { IconProps } from "./types";

export const SquareRootIcon = (props: IconProps) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" {...props}>
      <path
        d="M14 13.4167C14 13.1865 14.1865 13 14.4167 13H23.5833C23.8135 13 24 13.1865 24 13.4167V22.5833C24 22.8135 23.8135 23 23.5833 23H14.4167C14.1865 23 14 22.8135 14 22.5833V13.4167Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 14V22H23V14H15ZM14.4167 13C14.1865 13 14 13.1865 14 13.4167V22.5833C14 22.8135 14.1865 23 14.4167 23H23.5833C23.8135 23 24 22.8135 24 22.5833V13.4167C24 13.1865 23.8135 13 23.5833 13H14.4167Z"
        fill="#7070A9"
      />
      <path
        d="M15.2937 4.6C15.3897 4.6 15.4677 4.636 15.5277 4.708C15.5877 4.768 15.6237 4.84 15.6357 4.924V5.032L11.4417 13.726L7.21174 22.474C7.16374 22.558 7.04974 22.6 6.86974 22.6C6.76174 22.6 6.68974 22.582 6.65374 22.546L3.16174 14.95L1.99174 15.796C1.93174 15.796 1.84774 15.748 1.73974 15.652C1.63174 15.544 1.57774 15.454 1.57774 15.382C1.57774 15.334 1.76974 15.154 2.15374 14.842C2.54974 14.53 2.95174 14.224 3.35974 13.924C3.77974 13.612 3.99574 13.45 4.00774 13.438C4.03174 13.414 4.06174 13.402 4.09774 13.402C4.18174 13.402 4.25374 13.462 4.31374 13.582L7.42774 20.386C7.43974 20.386 8.06374 19.108 9.29974 16.552C10.5357 13.996 11.7717 11.422 13.0077 8.83C14.2557 6.238 14.8977 4.912 14.9337 4.852C15.0177 4.684 15.1377 4.6 15.2937 4.6Z"
        fill="#3A517F"
      />
      <path
        d="M15.2939 4.94775H23.7061"
        stroke="#3A517F"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
    </svg>
  );
};
