/**
 * Default color selection palette.
 */
export const DEFAULT_PALETTE = [
  "#ff003c",
  "#ffc200",
  "#ffee00",
  "#ffa969",
  "#ff9df9",
  "#b2e35a",
  "#2ee689",
  "#29cccc",
  "#57d3ff",
  "#b68cff",
  "#6565ff",
  "#4ca2ff",
  "#fafafa",
  "#dfeaf0",
  "#b0c5c7",
];
