import { IconProps } from "./types";

export const OpenBracketFractionIcon = (props: IconProps) => {
  return (
    <svg width="24" height="29" viewBox="0 0 24 29" {...props}>
      <path
        d="M10.812 23.158C10.812 23.314 10.776 23.428 10.704 23.5H10.38C9.468 23.5 8.682 23.344 8.022 23.032C7.374 22.732 6.972 22.258 6.816 21.61C6.792 21.526 6.774 20.656 6.762 19C6.762 18.748 6.762 18.43 6.762 18.046C6.75 16.978 6.72 16.378 6.672 16.246C6.66 16.234 6.654 16.222 6.654 16.21C6.51 15.838 6.234 15.52 5.826 15.256C5.418 14.98 4.968 14.842 4.476 14.842C4.344 14.842 4.26 14.824 4.224 14.788C4.188 14.752 4.17 14.656 4.17 14.5C4.17 14.344 4.188 14.248 4.224 14.212C4.26 14.176 4.344 14.158 4.476 14.158C4.968 14.158 5.418 14.026 5.826 13.762C6.234 13.486 6.51 13.162 6.654 12.79C6.702 12.646 6.726 12.496 6.726 12.34C6.738 12.184 6.75 11.398 6.762 9.982C6.774 8.338 6.792 7.474 6.816 7.39C6.912 7.006 7.104 6.688 7.392 6.436C7.92 5.908 8.718 5.602 9.786 5.518C9.81 5.518 9.888 5.518 10.02 5.518C10.164 5.506 10.272 5.5 10.344 5.5H10.704C10.776 5.572 10.812 5.68 10.812 5.824C10.812 5.98 10.794 6.076 10.758 6.112C10.734 6.148 10.638 6.166 10.47 6.166C9.798 6.202 9.24 6.394 8.796 6.742C8.556 6.922 8.4 7.126 8.328 7.354C8.268 7.51 8.238 8.398 8.238 10.018C8.238 11.578 8.232 12.394 8.22 12.466C8.1 13.402 7.38 14.08 6.06 14.5C7.392 14.956 8.112 15.634 8.22 16.534C8.232 16.606 8.238 17.422 8.238 18.982C8.238 20.602 8.268 21.49 8.328 21.646C8.448 21.982 8.712 22.258 9.12 22.474C9.528 22.69 9.978 22.81 10.47 22.834C10.638 22.834 10.734 22.852 10.758 22.888C10.794 22.924 10.812 23.014 10.812 23.158Z"
        fill="#3A517F"
      />
      <path
        d="M13 6.33333C13 6.14924 13.1492 6 13.3333 6H20.6667C20.8508 6 21 6.14924 21 6.33333V13.6667C21 13.8508 20.8508 14 20.6667 14H13.3333C13.1492 14 13 13.8508 13 13.6667V6.33333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7V13H20V7H14ZM13.3333 6C13.1492 6 13 6.14924 13 6.33333V13.6667C13 13.8508 13.1492 14 13.3333 14H20.6667C20.8508 14 21 13.8508 21 13.6667V6.33333C21 6.14924 20.8508 6 20.6667 6H13.3333Z"
        fill="#7070A9"
      />
      <path
        d="M13 15.3333C13 15.1492 13.1492 15 13.3333 15H20.6667C20.8508 15 21 15.1492 21 15.3333V22.6667C21 22.8508 20.8508 23 20.6667 23H13.3333C13.1492 23 13 22.8508 13 22.6667V15.3333Z"
        fill="#8C8CBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 16V22H20V16H14ZM13.3333 15C13.1492 15 13 15.1492 13 15.3333V22.6667C13 22.8508 13.1492 23 13.3333 23H20.6667C20.8508 23 21 22.8508 21 22.6667V15.3333C21 15.1492 20.8508 15 20.6667 15H13.3333Z"
        fill="#7070A9"
      />
    </svg>
  );
};
