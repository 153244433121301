import React, { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";

interface Props {
  /** Control opening and closing of the Content */
  open: boolean;

  /** Toggleable Content  */
  content: React.ReactNode;

  /** Becomes the Target */
  children?: React.ReactNode;

  /**
   * Content's position relative to Target
   * @default bottom-right
   */
  position?: PositionType;

  /** Outer clicks handler. Can be used to close `content` */
  onOuterClick?: () => void;

  inline?: boolean;

  className?: string;
}

/**
 * A Dropdown renders toggleable **Content** around a **Target** element.
 *
 * **Target** element is the direct children of a Dropdown component.
 *
 * **Content** is defined by `content` prop and shown based on `open` prop.
 */
export const Dropdown: React.FC<Props> = (props) => {
  const { onOuterClick, open, inline } = props;
  const targetEl = useRef<HTMLSpanElement>(null);
  const contentEl = useRef<HTMLSpanElement>(null);

  // Check if the click event is inside target or content
  // Which would trigger the 'props.onOuterClick' callback

  const handleOuterClick = useCallback(
    (event: Event) => {
      if (onOuterClick) {
        if (
          targetEl.current &&
          !targetEl.current.contains(event.target as Node) &&
          contentEl.current &&
          !contentEl.current.contains(event.target as Node)
        ) {
          onOuterClick();
        }
      }
    },
    [onOuterClick]
  );

  // Bind the 'handleOuterClick' callback to document click events
  useEffect(() => {
    if (open) {
      document.addEventListener("click", handleOuterClick);
      return () => {
        document.removeEventListener("click", handleOuterClick);
      };
    }
    return undefined;
  }, [open, handleOuterClick]);

  return (
    <Container inline={inline} className={props.className}>
      <Target ref={targetEl} aria-haspopup="true" aria-expanded={props.open}>
        {props.children}
      </Target>
      {open && (
        <Content ref={contentEl} position={props.position || "bottom-right"}>
          {props.content}
        </Content>
      )}
    </Container>
  );
};

/** Wrapper on the full component. */
const Container = styled.span<{ inline?: boolean }>`
  position: relative;
  display: ${(p) => (p.inline ? "inline" : "inline-block")};
`;

/** Wrapper on the Target element */
const Target = styled.span`
  white-space: pre-wrap;
`;

/** Wrapper on the Contents */
const Content = styled.span<{ position: PositionType }>`
  position: absolute;

  /** 
   * Within a stacking context, dropdown contents are 2nd 
   * in the stacking order  
  */
  z-index: 2;

  display: inline-block;
  margin: 6px 0;
  user-select: none;
  ${(p) => contentPosition(p.position)}
`;

/* Positioning of the content menu on the target element. */
type PositionType =
  | "bottom-left"
  | "bottom-middle"
  | "bottom-right"
  | "top-left"
  | "top-middle"
  | "top-right";

/* Generate css positioning properties based on the given 'position'. */
function contentPosition(position: PositionType) {
  if (position == "top-left") {
    return css`
      bottom: 100%;
      right: 0;
    `;
  }
  if (position == "top-middle") {
    return css`
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    `;
  }
  if (position == "top-right") {
    return css`
      bottom: 100%;
      left: 0;
    `;
  }
  if (position == "bottom-left") {
    return css`
      top: 100%;
      right: 0;
    `;
  }
  if (position == "bottom-middle") {
    return css`
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    `;
  }
  return css`
    top: 100%;
    left: 0;
  `;
}
