import { Editor } from "@tiptap/core";
import { ImageUploaderOptions } from "./image-uploader";

/**
 * Upload an image file to the Cadmus media API.
 *
 * The upload is a 3 step process:
 *
 *   1. Get an S3 presigned url for the file which reserves an entry in the DB under a `mediaId`
 *   2. Upload image to the S3 presigned URL
 *   3. Record successful upload using the `mediaId` from step 1 and receive the
 *      final image `src` URL
 *
 * The final URL that we receive points to an endpoint on the Cadmus API.
 * Setting that as the Image `src` will make the browser make a `GET` request
 * which gets redirected to another S3 presigned URL to read that image.
 * Therefore, almost no file transfer happens with the Cadmus servers.
 *
 * The `editor` instance is used to read the `image-uploader` extension options
 * which includes the Cadmus API specific parameters needed to make the `fetch`
 * requests. Please ensure that extension is configured on the passed `editor`.
 *
 * @param editor Editor instance containing the `image-uploader` extension
 * @param file Image file to upload
 * @return Promise of the uploaded image `src`
 * @throws {Error} On network failures or missing extension options
 */
export async function uploadImageToServer(
  editor: Editor,
  file: File
): Promise<string> {
  try {
    const { endpoint, id } = await getImageUploadEndpoint(editor, file);
    await uploadImageToEndpoint(endpoint, file);
    const src = await getUploadedImageSrc(editor, id);
    return src;
  } catch (_error) {
    return Promise.reject(new Error("failed uploading image"));
  }
}

// GET S3 presigned upload URL and Media ID for an image
async function getImageUploadEndpoint(
  editor: Editor,
  image: File
): Promise<{ endpoint: string; id: string }> {
  const { workId, assessmentId, tenant, imageAPIBase, userRole, userId } =
    await getImageUploaderOpts(editor);

  const body = buildData({
    filename: image.name,
    type: image.type,
    workId,
    assessmentId,
    userId,
  });

  const endpoint = `${imageAPIBase}/upload_url`;

  const res = await request(
    body,
    endpoint,
    "post",
    cadmusHeaders(tenant, userRole, userId)
  );
  const data = await res.json();

  if (data?.endpoint && data?.id) {
    return data;
  }

  return Promise.reject(new Error("failed uploading image"));
}

// PUT image (upload) to the given endpoint using `cors` mode.
async function uploadImageToEndpoint(endpoint: string, file: File) {
  const res = await fetch(endpoint, {
    method: "put",
    body: file,
    mode: "cors",
  });
  return statusOk(res);
}

// Inform Pantheon of a successful image upload and receive the final image
// `src`.
async function getUploadedImageSrc(
  editor: Editor,
  mediaId: string
): Promise<string> {
  const { tenant, imageAPIBase, userRole, userId } = await getImageUploaderOpts(
    editor
  );
  const body = buildData({ mediaId });
  const endpoint = `${imageAPIBase}/upload`;
  const response = await request(
    body,
    endpoint,
    "post",
    cadmusHeaders(tenant, userRole, userId)
  );
  const data = await response.json();
  if (data?.src) {
    return data.src;
  }
  return Promise.reject(new Error("failed uploading image"));
}

// Read latest extension options for the `image-uploader` extension. Throws if
// the extension is not found.
async function getImageUploaderOpts(editor: Editor) {
  const ImageUploaderExt = editor.extensionManager.extensions.find(
    (ext) => ext.name === "imageUploader"
  );
  if (!ImageUploaderExt) {
    return Promise.reject(new Error("Image API is not set"));
  }
  return ImageUploaderExt.options as ImageUploaderOptions;
}

// Create common Cadmus API specific headers
export const cadmusHeaders = (
  tenant?: string,
  userRole?: string,
  userId?: string
) => ({
  "x-cadmus-tenant": tenant ?? "",
  "x-cadmus-role": userRole ?? "",
  "x-cadmus-user": userId ?? "",
});

// Allow Responses with 2xx status codes. Fails otherwise.
async function statusOk(response: Response): Promise<Response> {
  if (response.ok) {
    return Promise.resolve(response);
  }
  return Promise.reject(new Error("failed uploading image"));
}

// Wrapper for the common fetch calls.
async function request(
  body: BodyInit,
  endpoint: string,
  method: string,
  headers = {}
) {
  const res = await fetch(endpoint, {
    method,
    body,
    headers: {
      Accept: "application/json",
      ...headers,
    },
    credentials: "include",
  });
  return statusOk(res);
}

// Create a new FormData object out of a string argument object.
function buildData(args: Record<string, string | Blob | null | undefined>) {
  const body = new FormData();
  Object.entries(args).forEach(
    ([key, value]) => value && body.append(key, value)
  );
  return body;
}
